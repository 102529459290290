import { useNavigate } from 'react-router-dom'
import TextField from '../../../components/inputs/CustomTextField'
import SelectCountry2 from '../../../components/reusables/SelectCountry2'

export const RightCol = (props) => {
    const {
        setShowModal,
        setLoginDetails,
        loginDetails,
        signupWithGoogle,
        setCompanyDetails,
        companyDetails,
        errors,
        subscribeToEmail,
        setSubscribeToEmail,
        handleGoogleSignup,
        handleRegisterViaEmail,
        inviteCode,
        isValidCode,
        agreetoTerms,
        setAgreeToTerms,
        User
    } = props
    const navigate = useNavigate()
    return (
        <>
            <div
                className="card w-100 p-3 p-md-4 mb-4 mb-md-0"
                style={{ boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.25)' }}
            >
                <div className="card-body my-5">
                    <h4 className="mb-4">Employer Registration</h4>
                    <div className="">
                        <p className="mb-4 text-primary">
                            <strong>Company Information</strong>
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlhtmlFor="">First Name</label>
                                <TextField
                                    type="text"
                                    className=" mb-4"
                                    placeholder="First Name"
                                    onChange={(e) =>
                                        setLoginDetails({
                                            ...loginDetails,
                                            firstName: e.target.value,
                                        })
                                    }
                                    defaultValue={
                                        signupWithGoogle
                                            ? signupWithGoogle?.profileObj
                                                  ?.givenName
                                            : loginDetails?.firstName
                                    }
                                    error={errors?.firstName}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlhtmlFor="">Last Name</label>
                                <TextField
                                    type="text"
                                    className=" mb-4"
                                    placeholder="Last Name"
                                    onChange={(e) =>
                                        setLoginDetails({
                                            ...loginDetails,
                                            lastName: e.target.value,
                                        })
                                    }
                                    error={errors?.lastName}
                                    defaultValue={
                                        signupWithGoogle
                                            ? signupWithGoogle?.profileObj
                                                  ?.familyName
                                            : loginDetails?.lastName
                                    }
                                />
                            </div>
                            <div className="col-md-12">
                                <label htmlhtmlFor="">Company Name</label>
                                <TextField
                                    type="text"
                                    className=" mb-4"
                                    placeholder="Company name"
                                    onChange={(e) =>
                                        setCompanyDetails({
                                            ...companyDetails,
                                            companyName: e.target.value,
                                        })
                                    }
                                    error={errors?.companyName}
                                    defaultValue={companyDetails?.companyName}
                                />
                            </div>
                            <div className="col-md-12">
                                <label htmlhtmlFor="">Location</label>
                                <SelectCountry2
                                    value={companyDetails?.country}
                                    onChange={(val) => {
                                        setCompanyDetails({
                                            ...companyDetails,
                                            country: val,
                                        })
                                    }}
                                    placeholder="Country"
                                    isSignup
                                    noCellCode
                                    error={errors?.country}
                                    className="mb-4"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="mb-4 text-primary mt-4">
                            <strong>Login Information</strong>
                        </p>
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlhtmlFor="">Email Address</label>
                                <TextField
                                    type="type"
                                    className=" mb-4"
                                    placeholder="Email Address"
                                    onChange={(e) =>
                                        setLoginDetails({
                                            ...loginDetails,
                                            email: e.target.value,
                                        })
                                    }
                                    error={errors?.email}
                                    defaultValue={
                                        signupWithGoogle
                                            ? signupWithGoogle?.profileObj
                                                  ?.email
                                            : loginDetails?.email
                                    }
                                    disabled={!!signupWithGoogle}
                                />
                            </div>
                            {!signupWithGoogle && (
                                <>
                                    <div className="col-md-6">
                                        <TextField
                                            type="password"
                                            className=" mb-4"
                                            placeholder="Password"
                                            onChange={(e) =>
                                                setLoginDetails({
                                                    ...loginDetails,
                                                    password: e.target.value,
                                                })
                                            }
                                            defaultValue={
                                                loginDetails?.password
                                            }
                                            error={errors?.password}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            type="password"
                                            className=" mb-4"
                                            placeholder="Confirm Password"
                                            onChange={(e) =>
                                                setLoginDetails({
                                                    ...loginDetails,
                                                    confirmPassword:
                                                        e.target.value,
                                                })
                                            }
                                            defaultValue={
                                                loginDetails?.confirmPassword
                                            }
                                            error={errors?.confirmPassword}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div>
                        <div class="form-check mb-2">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="cb1"
                                onClick={() => setAgreeToTerms(!agreetoTerms)}
                            />
                            <label class="form-check-label" htmlFor="cb1">
                                I agree to the Joblu{' '}
                                <a
                                    href="https://joblu.io/terms-of-use/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms of Use
                                </a>{' '}
                                and{' '}
                                <a
                                    href="https://joblu.io/privacy-policy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </label>
                        </div>
                        <div class="form-check mb-2">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="cb2"
                                defaultChecked={subscribeToEmail}
                                onClick={() =>
                                    setSubscribeToEmail(!subscribeToEmail)
                                }
                            />
                            <label class="form-check-label" htmlFor="cb2">
                                Subscribe to our newsletter and receive the
                                latest news and trends delivered right to your
                                inbox!
                            </label>
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-column flex-md-row">
                        <div>
                            <button
                                className="btn btn-primary mt-4 mt-md-0 mb-2 mb-md-0"
                                onClick={
                                    signupWithGoogle
                                        ? handleGoogleSignup
                                        : handleRegisterViaEmail
                                }
                                disabled={
                                    User?.isRegistering ||
                                    (inviteCode.length > 0 && !isValidCode) ||
                                    !agreetoTerms
                                }
                            >
                                Create Employer Account
                            </button>
                        </div>
                        <div className="ms-0 ms-md-4">
                            <a
                                href=""
                                onClick={() => {
                                    navigate('/login')
                                }}
                            >
                                <span style={{ color: '#009CDE!important' }}>
                                    Cancel account creation
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
