import React, { useEffect, useRef } from 'react';

const VignetteBanner = () => {
    const bannerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        const script2 = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//gloaphoo.net/400/7350107';
        script.onload = script.onerror = () => {
            // Handle load or error event
        };

        script2.type = 'text/javascript';
        script2.src = '../../../public/monetag-vignette.js'
        script2.onload = () => {
            console.log('loaded vignette')
        }
        script2.onerror = () => {
            console.log('error on vignette')
        }

        try {
            // Append script to the DOM
            if (bannerRef.current) {
                bannerRef.current.appendChild(script2)
                bannerRef.current.appendChild(script);
            }
        } catch (e) {
            // Handle exceptions, such as permissions issues
            console.error('Error appending script:', e);
        }

        // Cleanup function to remove script when component unmounts
        return () => {
            if (bannerRef.current && bannerRef.current.contains(script)) {
                bannerRef.current.removeChild(script);
            }
            if (bannerRef.current && bannerRef.current.contains(script2)) {
                bannerRef.current.removeChild(script2);
            }
        };
    }, []); // Empty dependency array means this effect runs once on mount and once on unmount

    return (
        <div ref={bannerRef} className="ad-container">
        </div>
    );
};

export default VignetteBanner;
