import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultImg from "../../assets/img/defaultImg.png";
import DeleteIcon from "../../assets/img/settings/deleteIcon.png";
import DocumentIcon from "../../assets/img/settings/documentIcon.png";
import EditIcon from "../../assets/img/settings/editIcon.png";
import HelpIcon from "../../assets/img/settings/helpIcon.png";
import LogoutIcon from "../../assets/img/settings/logoutIcon.png";
import TextField from "../../components/inputs/CustomTextField";
import PageTitle from "../../components/page-title";
import JobluTypography from "../../components/Typography";
import appActions from "../../redux/app/actions";
import { app_styles } from "../../general.styles";
import { userRequest } from "../../service/requests";
import rootAction from "../../redux/action";
import { settings_styles } from "./styles";
import ContactUs from "../contact-us";
import EditProfile from "../edit-profile";

const Settings = (props) => {
  const app_classes = app_styles(props);
  const settings_classes = settings_styles(props);
  const { user, signOut, token, setShowAlert } = props;
  const navigate = useNavigate();

  const [currencyOpenModal, setCurrencyOpenModal] = useState(false);
  const [passwordOpenModal, setPasswordOpenModal] = useState(false);
  const [accountOpenModal, setAccountOpenModal] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState("PHP");

  const closeModal = (type) => {
    if (type == "currency") {
      setCurrencyOpenModal(false);
    }
    if (type == "password") {
      setPasswordOpenModal(false);
    }
    if (type == "account") {
      setAccountOpenModal(false);
    }
  };

  const openModal = (type) => {
    if (type == "currency") {
      console.log(type);
      setCurrencyOpenModal(true);
    }
    if (type == "password") {
      console.log(type);
      setPasswordOpenModal(true);
    }
    if (type == "account") {
      setAccountOpenModal(true);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [show_edit_password, setShowEditPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [new_password_details, setNewPasswordDetails] = useState({
    current_password: null,
    new_password: null,
    confirm_password: null,
  });
  const [field_errors, setFieldErrors] = useState({});

  const handleLogout = () => {
    // window.location.reload();
    try {
      userRequest
        .signOut({ token: token })
        .then((res) => {
          console.log("reload");

          // window.location.reload();
          setShowAlert({ message: "Password changed", type: "success" });
          setTimeout(() => {
            signOut();
          }, 1000);
        })
        .finally(() => {
          //   setTimeout(() => {
          //   }, 500);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // const [showAlert, setShowAlert] = useState(false);
  const handleUpdatePassword = () => {
    let valids = [];
    let errors = {};
    Object.keys(new_password_details).forEach((key) => {
      if (!new_password_details[key]) {
        errors[key] = "Required";
        valids.push(false);
      } else {
        errors[key] = null;
        valids.push(true);
      }
    });
    console.log("errros after loop", errors);
    if (
      new_password_details.new_password &&
      new_password_details.confirm_password
    ) {
      if (
        new_password_details.new_password !==
        new_password_details.confirm_password
      ) {
        valids.push(false);
        errors["confirm_password"] = "Password did not matched!";
      } else {
        valids.push(true);
        errors["confirm_password"] = null;
      }
    }
    console.log("errs => ", errors, new_password_details.confirm_password);
    setFieldErrors({ ...errors });
    if (!valids.includes(false)) {
      setLoading(true);
      userRequest
        .changePassword({
          oldPassword: new_password_details.current_password,
          newPassword: new_password_details.new_password,
        })
        .then((res) => {
          //show response message here
          setShowAlert({ message: "Password changed", type: "success" });
          // setShowEditPassword(false);
          // handleLogout();
          // console.log("logout");
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setFieldErrors({
              ...field_errors,
              current_password: "Current password incorrect!",
              response: null,
            });
          }
          if (e.response.status === 400) {
            setFieldErrors({
              ...field_errors,
              response: "Password must be longer than or equal to 8 characters",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
    }
  };

  useEffect(() => {
    console.log("final errors", field_errors);
  }, [field_errors]);

  const [profle_completeness, setProfileCompleteness] = useState(0);

  useEffect(() => {
    userRequest.getApplicantCompletion().then((res) => {
      setProfileCompleteness(res.data?.completion);
    });
  }, []);

  const [currPage, setCurrPage] = useState("editProfile");

  return (
    <>
      <PageTitle pageTitle="Settings" />
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} sm={3} className={`${app_classes.padding.a3}`}>
              <Box>
                <Box
                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.margin.b2}`}
                >
                  <img
                    src={user?.profileUrl || DefaultImg}
                    className={`${settings_classes.default_img}`}
                    alt=""
                  />
                </Box>
                <Box
                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center}`}
                >
                  <JobluTypography.H5 primary semiBold>
                    {`${user?.firstName} ${user?.lastName}`}
                  </JobluTypography.H5>
                </Box>
                <Box
                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.margin.b2}`}
                >
                  <JobluTypography.Body>
                    {profle_completeness}% Complete
                  </JobluTypography.Body>
                </Box>
                <Box
                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.margin.b3}`}
                >
                  <Button
                    variant="contained"
                    className={`${app_classes.button.primary}`}
                    onClick={() => {
                      // navigate("/edit-profile");
                      setCurrPage("editProfile");
                    }}
                  >
                    Edit Profile
                  </Button>
                </Box>
                <Box
                  className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.backgroundColor.bg_light}`}
                >
                  <JobluTypography.Caption semiBold primary>
                    Settings
                  </JobluTypography.Caption>
                </Box>
                {/* <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
            
          >
            <JobluTypography.Caption primary link onClick={() => {
              openModal("currency");
            }}>
              <img src={CurrencyIcon} className={`${settings_classes.icons}`} />{" "}
              Currency
            </JobluTypography.Caption>
          </Box> */}
                <Box
                  className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
                  // onClick={() => {
                  //   openModal("password");
                  // }}
                >
                  <JobluTypography.Caption
                    primary
                    link
                    onClick={() => {
                      setCurrPage("changePassword");
                    }}
                  >
                    <img
                      src={EditIcon}
                      className={`${settings_classes.icons}`}
                    />{" "}
                    Change Password
                  </JobluTypography.Caption>
                </Box>
                {/* <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
           
          >
            <JobluTypography.Caption primary link  onClick={() => {
              openModal("account");
            }}>
              <img src={CogIcon} className={`${settings_classes.icons}`} />{" "}
              Account Management
            </JobluTypography.Caption>
          </Box> */}
                <Box
                  className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.backgroundColor.bg_light}`}
                >
                  <JobluTypography.Caption semiBold primary>
                    Help
                  </JobluTypography.Caption>
                </Box>
                <Box
                  className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
                  onClick={() => {
                    setCurrPage("contactUs");
                  }}
                >
                  <JobluTypography.Caption primary link>
                    <img
                      src={HelpIcon}
                      className={`${settings_classes.icons}`}
                    />{" "}
                    Contact Us
                  </JobluTypography.Caption>
                </Box>
                <Box
                  className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
                  onClick={() => {
                    window.open("https://joblu.io/terms-of-use/", "_blank");
                  }}
                >
                  <JobluTypography.Caption primary link>
                    <img
                      src={DocumentIcon}
                      className={`${settings_classes.icons}`}
                    />{" "}
                    Terms and Conditions
                  </JobluTypography.Caption>
                </Box>
                <Box
                  className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
                  onClick={() => {
                    window.open("https://joblu.io/privacy-policy/", "_blank");
                  }}
                >
                  <JobluTypography.Caption primary link>
                    <img
                      src={DocumentIcon}
                      className={`${settings_classes.icons}`}
                    />
                    Privacy Policy
                  </JobluTypography.Caption>
                </Box>
                {/* <Box
                  className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
                  onClick={() => {
                    console.log("logout");
                  }}
                >
                  <JobluTypography.Caption primary link>
                    <img
                      src={LogoutIcon}
                      className={`${settings_classes.icons}`}
                    />{" "}
                    Logout
                  </JobluTypography.Caption>
                </Box> */}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              className={`${app_classes.padding.a3}`}
              style={{ borderLeft: "1px solid #8080804D" }}
            >
              {currPage == "editProfile" && (
                <>
                  {" "}
                  <Box className={`${app_classes.margin.b3}`}>
                    <JobluTypography.H4
                      primary
                      onClick={() => {
                        console.log(user);
                      }}
                    >
                      Edit Profile
                    </JobluTypography.H4>
                  </Box>
                  <EditProfile />
                </>
              )}

              {currPage == "changePassword" && (
                <>
                  <Box className={`${app_classes.margin.b3}`}>
                    <JobluTypography.H4
                      primary
                      onClick={() => {
                        console.log(user);
                      }}
                    >
                      Change Password
                    </JobluTypography.H4>
                  </Box>
                  <Grid container justifyContent="center">
                    <Grid item xs={11} sm={6}>
                      <Grid container className={app_classes.padding.a2}>
                        <Grid item xs={12}>
                          <Box>
                            <JobluTypography.Caption>
                              Current Password
                            </JobluTypography.Caption>
                            <TextField
                              type="password"
                              onChange={(e) => {
                                console.log("click");
                                setNewPasswordDetails({
                                  ...new_password_details,
                                  current_password: e.target.value,
                                });
                              }}
                              error={field_errors?.current_password}
                              value={new_password_details?.current_password}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <JobluTypography.Caption>
                              New Password
                            </JobluTypography.Caption>
                            <TextField
                              type="password"
                              error={field_errors?.new_password}
                              onChange={(e) => {
                                console.log("click");
                                setNewPasswordDetails({
                                  ...new_password_details,
                                  new_password: e.target.value,
                                });
                              }}
                              value={new_password_details?.new_password}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <JobluTypography.Caption>
                              Confirm Password
                            </JobluTypography.Caption>
                            <TextField
                              type="password"
                              error={field_errors?.confirm_password}
                              onChange={(e) => {
                                console.log("click");
                                setNewPasswordDetails({
                                  ...new_password_details,
                                  confirm_password: e.target.value,
                                });
                              }}
                              value={new_password_details?.confirm_password}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className={app_classes.margin.y2}>
                            <Button
                              variant="contained"
                              fullWidth
                              style={{ textTransform: "none", fontSize: 14 }}
                              color="primary"
                              onClick={() => {
                                handleUpdatePassword();
                              }}
                              className={`${app_classes.button.primary}`}
                              disabled={loading}
                              startIcon={
                                loading && <CircularProgress size={14} />
                              }
                            >
                              Confirm
                            </Button>
                            {field_errors?.response && (
                              <JobluTypography.Caption style={{ color: "red" }}>
                                {field_errors?.response}
                              </JobluTypography.Caption>
                            )}
                          </Box>
                          <Box>
                            <Button
                              variant="outlined"
                              className={`${app_classes.button.primary_outline}`}
                              fullWidth
                              onClick={() => {
                                setShowEditPassword(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {currPage == "contactUs" && (
                <>
                  <ContactUs />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        style={{ display: "none" }}
        container
        justifyContent="center"
        className={`${app_classes.margin.b5}`}
      >
        <Grid
          item
          xs={11}
          sm={8}
          lg={6}
          className={`${app_classes.display.display_flex} ${app_classes.flexBox.flex_direction_column}`}
        >
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.margin.b2}`}
          >
            <img
              src={user?.profileUrl || DefaultImg}
              className={`${settings_classes.default_img}`}
              alt=""
              style={{
                background: "#FFFFFF",
              }}
            />
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center}`}
          >
            <JobluTypography.H5 primary semiBold>
              {`${user?.firstName} ${user?.lastName}`}
            </JobluTypography.H5>
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.margin.b2}`}
          >
            <JobluTypography.Body>
              {profle_completeness}% Complete
            </JobluTypography.Body>
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.margin.b3}`}
          >
            <Button
              variant="contained"
              className={`${app_classes.button.primary}`}
              onClick={() => {
                // navigate("/edit-profile");
                setCurrPage("editProfile");
              }}
            >
              Edit Profile
            </Button>
          </Box>
          <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.backgroundColor.bg_light}`}
          >
            <JobluTypography.Caption semiBold primary>
              Settings
            </JobluTypography.Caption>
          </Box>
          {/* <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
            
          >
            <JobluTypography.Caption primary link onClick={() => {
              openModal("currency");
            }}>
              <img src={CurrencyIcon} className={`${settings_classes.icons}`} />{" "}
              Currency
            </JobluTypography.Caption>
          </Box> */}
          <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
            // onClick={() => {
            //   openModal("password");
            // }}
          >
            <JobluTypography.Caption
              primary
              link
              onClick={() => {
                setShowEditPassword(true);
              }}
            >
              <img src={EditIcon} className={`${settings_classes.icons}`} />{" "}
              Change Password
            </JobluTypography.Caption>
          </Box>
          {/* <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
           
          >
            <JobluTypography.Caption primary link  onClick={() => {
              openModal("account");
            }}>
              <img src={CogIcon} className={`${settings_classes.icons}`} />{" "}
              Account Management
            </JobluTypography.Caption>
          </Box> */}
          <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.backgroundColor.bg_light}`}
          >
            <JobluTypography.Caption semiBold primary>
              Help
            </JobluTypography.Caption>
          </Box>
          <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
            onClick={() => {
              setCurrPage("contactUs");
            }}
          >
            <JobluTypography.Caption primary link>
              <img src={HelpIcon} className={`${settings_classes.icons}`} />{" "}
              Contact Us
            </JobluTypography.Caption>
          </Box>
          <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
            onClick={() => {
              window.open("https://joblu.io/terms-of-use/", "_blank");
            }}
          >
            <JobluTypography.Caption primary link>
              <img src={DocumentIcon} className={`${settings_classes.icons}`} />{" "}
              Terms and Conditions
            </JobluTypography.Caption>
          </Box>
          <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
            onClick={() => {
              window.open("https://joblu.io/privacy-policy/", "_blank");
            }}
          >
            <JobluTypography.Caption primary link>
              <img src={DocumentIcon} className={`${settings_classes.icons}`} />
              Privacy Policy
            </JobluTypography.Caption>
          </Box>
          <Box
            className={`${app_classes.padding.y1} ${app_classes.padding.x3} ${app_classes.cursor.pointer}`}
            onClick={() => {
              console.log("logout");
            }}
          >
            <JobluTypography.Caption primary link>
              <img src={LogoutIcon} className={`${settings_classes.icons}`} />{" "}
              Logout
            </JobluTypography.Caption>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={currencyOpenModal}
        onClose={() => {
          closeModal("currency");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`${app_classes.modal.box}`}
          style={{ padding: 0, border: 0 }}
        >
          <Box
            className={`${app_classes.backgroundColor.bg_primary} ${app_classes.text.white} ${app_classes.text.center}  ${app_classes.padding.a2}`}
          >
            <JobluTypography.H5>Choose Currency</JobluTypography.H5>
          </Box>
          <Box className={`${app_classes.padding.a5}`}>
            <Box
              onClick={() => {
                setSelectedCurrency("INR");
              }}
              className={`${app_classes.cursor.pointer} ${
                selectedCurrency == "INR" && settings_classes.selected_currency
              } ${app_classes.border.border_all} ${app_classes.padding.a2} ${
                app_classes.margin.b2
              }`}
            >
              INR
            </Box>
            <Box
              onClick={() => {
                setSelectedCurrency("PHP");
              }}
              className={`${app_classes.cursor.pointer} ${
                selectedCurrency == "PHP" && settings_classes.selected_currency
              } ${app_classes.border.border_all} ${app_classes.padding.a2} ${
                app_classes.margin.b2
              }`}
            >
              PHP
            </Box>
            <Box
              onClick={() => {
                setSelectedCurrency("MYR");
              }}
              className={`${app_classes.cursor.pointer} ${
                selectedCurrency == "MYR" && settings_classes.selected_currency
              } ${app_classes.border.border_all} ${app_classes.padding.a2} ${
                app_classes.margin.b2
              }`}
            >
              MYR
            </Box>
            <Box
              onClick={() => {
                setSelectedCurrency("SGD");
              }}
              className={`${app_classes.cursor.pointer} ${
                selectedCurrency == "SGD" && settings_classes.selected_currency
              } ${app_classes.border.border_all} ${app_classes.padding.a2} ${
                app_classes.margin.b2
              }`}
            >
              SGD
            </Box>
            <Box
              onClick={() => {
                setSelectedCurrency("USD");
              }}
              className={`${app_classes.cursor.pointer} ${
                selectedCurrency == "USD" && settings_classes.selected_currency
              } ${app_classes.border.border_all} ${app_classes.padding.a2} ${
                app_classes.margin.b2
              }`}
            >
              USD
            </Box>
            <Box>
              <Button
                onClick={() => {
                  closeModal("currency");
                }}
                variant="contained"
                className={`${app_classes.button.primary}`}
                fullWidth
              >
                Okay
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={passwordOpenModal}
        onClose={() => {
          closeModal("password");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`${app_classes.modal.box}`}
          style={{ padding: 0, border: 0 }}
        >
          <Box
            className={`${app_classes.backgroundColor.bg_primary} ${app_classes.text.white} ${app_classes.text.center}  ${app_classes.padding.a2}`}
          >
            <JobluTypography.H5>Change Password</JobluTypography.H5>
          </Box>
          <Box className={`${app_classes.padding.a4}  `}>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.Body semiBold>Password</JobluTypography.Body>
              <FormControl fullWidth sx={{}} variant="outlined" size="small">
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.Body semiBold>New Password</JobluTypography.Body>
              <FormControl fullWidth sx={{}} variant="outlined" size="small">
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showNewPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.Body semiBold>Password</JobluTypography.Body>
              <FormControl fullWidth sx={{}} variant="outlined" size="small">
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showConfirmPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <Box>
              <Button
                variant="contained"
                className={`${app_classes.button.primary}`}
                fullWidth
                onClick={() => {
                  closeModal("password");
                }}
              >
                Confirm
              </Button>
              <Button
                variant="outlined"
                className={`${app_classes.button.primary_outline}`}
                fullWidth
                onClick={() => {
                  closeModal("password");
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={accountOpenModal}
        onClose={() => {
          closeModal("account");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`${app_classes.modal.box}`}
          style={{ padding: 0, border: 0 }}
        >
          <Box
            className={`${app_classes.backgroundColor.bg_primary} ${app_classes.text.white} ${app_classes.text.center}  ${app_classes.padding.a2}`}
          >
            <JobluTypography.H5>Account Management</JobluTypography.H5>
          </Box>
          <Box className={`${app_classes.padding.a4}  `}>
            <Box
              className={`${app_classes.border.border_all} ${app_classes.padding.a2} ${app_classes.margin.b2}`}
            >
              <img src={DeleteIcon} className={`${settings_classes.icons}`} />{" "}
              Delete Account
            </Box>
            <Box>
              <Button
                onClick={() => {
                  closeModal("account");
                }}
                variant="outlined"
                className={`${app_classes.button.primary_outline}`}
                fullWidth
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={show_edit_password}
        onClose={() => {
          setShowEditPassword(false);
          setNewPasswordDetails({
            current_password: null,
            new_password: null,
            confirm_password: null,
          });
        }}
        maxWidth="xs"
      >
        <Box
          className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          style={{ minWidth: 400 }}
        >
          <JobluTypography.H6 align="center">
            Change Password
          </JobluTypography.H6>
        </Box>
        <Grid container className={app_classes.padding.a2}>
          <Grid item xs={12}>
            <Box>
              <JobluTypography.Caption>
                Current Password
              </JobluTypography.Caption>
              <TextField
                type="password"
                onChange={(e) => {
                  console.log("click");
                  setNewPasswordDetails({
                    ...new_password_details,
                    current_password: e.target.value,
                  });
                }}
                error={field_errors?.current_password}
                value={new_password_details?.current_password}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <JobluTypography.Caption>New Password</JobluTypography.Caption>
              <TextField
                type="password"
                error={field_errors?.new_password}
                onChange={(e) => {
                  console.log("click");
                  setNewPasswordDetails({
                    ...new_password_details,
                    new_password: e.target.value,
                  });
                }}
                value={new_password_details?.new_password}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <JobluTypography.Caption>
                Confirm Password
              </JobluTypography.Caption>
              <TextField
                type="password"
                error={field_errors?.confirm_password}
                onChange={(e) => {
                  console.log("click");
                  setNewPasswordDetails({
                    ...new_password_details,
                    confirm_password: e.target.value,
                  });
                }}
                value={new_password_details?.confirm_password}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={app_classes.margin.y2}>
              <Button
                variant="contained"
                fullWidth
                style={{ textTransform: "none", fontSize: 14 }}
                color="primary"
                onClick={() => {
                  handleUpdatePassword();
                }}
                className={`${app_classes.button.primary}`}
                disabled={loading}
                startIcon={loading && <CircularProgress size={14} />}
              >
                Confirm
              </Button>
              {field_errors?.response && (
                <JobluTypography.Caption style={{ color: "red" }}>
                  {field_errors?.response}
                </JobluTypography.Caption>
              )}
            </Box>
            <Box>
              <Button
                variant="outlined"
                className={`${app_classes.button.primary_outline}`}
                fullWidth
                onClick={() => {
                  setShowEditPassword(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.User?.user, token: state?.User?.token };
};
const mapDispatchToProps = {
  signOut: rootAction.signOut,
  setShowAlert: appActions?.showAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
