import {
  Box,
  Button,
  Dialog,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Search } from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultImg from "../../../../assets/img/defaultImg.png";
import NoJobs from "../../../../assets/img/matches/NoJobs.png";
import EmailNotVerifiedModal from "../../../../components/modals/EmailNotVerifiedModal";
import SwipeLimitModal from "../../../../components/modals/SwipeLimitsModal";
import JobluTypography from "../../../../components/Typography";
import { app_styles } from "../../../../general.styles";
import { applicantRequests } from "../../../../service/requests";
import { errorSwipeActions } from "../../../../utils/constants";
import ListLoader from "../../../explore/jobseeker-explore/components/loaders/ListLoader";
import JobCardList from "../card";
export const SWIPE_ACTIONS = {
  LIKE: "like",
  DISMISS: "not_interested",
  SUPERLIKE: "superLike",
};

const ApplicantShortlistedJobs = (props) => {
  const { User } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  //const [orig_list, setOrigList] = useState([]);

  const [showSwipeLimit, setShowSwipeLimit] = useState(false);
  const [swipeLimitTitle, setSwipeLimitTitle] = useState(null);
  const [swipeLimitText, setSwipeLimitText] = useState(null);

  const [empImg, setEmpImg] = useState(null);
  const [appImg, setAppImg] = useState(null);
  const [matchOpen, setMatchOpen] = useState(false);
  const [current_page, setCurrentPage] = useState(1);

  const [limitOpen, setLimitOpen] = useState(false);
  const [limitMainText, setLimitMainText] = useState(null);
  const [limitSubText, setLimitSubText] = useState(null);

  const app_classes = app_styles();
  const [searchText, setSearchText] = useState("");
  const [jobActionData, setJobActionData] = useState({
    action: null,
    job: null,
  });

  const getApplicantShortlistedJobs = (options, append) => {
    let page = options?.offset || 1;
    if (!append && !options?.ref) {
      setLoading(true);
    }

    let payload = {
      search: searchText,
      limit: 20,
      offset: page || 1,
    };

    applicantRequests.jobs
      .shortlisted(payload)
      .then((res) => {
        console.log("applicant shortlisted jobs", res.data);
        setLoading(false);
        if (page > 1) {
          setJobs({
            data: [...jobs?.data, ...res.data?.data],
            limit: res.data.limit,
            page: res.data.page,
            totalCount: res.data.totalCount,
          });
        } else {
          setJobs(res.data);
        }
        // if(!ref){
        //   setLoading(false);
        // }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        if (searchText) {
          setCurrentPage(1);
        }
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getApplicantShortlistedJobs();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    if (current_page > 1) {
      getApplicantShortlistedJobs();
    }
  }, [current_page]);

  useState(() => {
    console.log("this is me,", User);
  }, []);

  const [jobData, setJobData] = useState(null);
  const [empData, setEmpData] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const onSwipeEnd = (
    job,
    card_index,
    new_class,
    matchData,
    emp_img,
    employer
  ) => {
    //perform card removal
    setJobData(matchData);
    setEmpData(employer);
    setJobDetails(job);
    if (matchData && matchData.matchStatus === "match") {
      setEmpImg(emp_img);
      setAppImg(matchData.profileUrl_applicant);
      setTimeout(() => {
        console.log(matchData);
        setMatchOpen(true);
      }, 500);
    }

    const card = document.getElementById(`card-matches-class-${card_index}`);
    if (card) {
      card.classList.add(new_class);
    }

    if (!!new_class) {
      let copy = jobs;
      copy.splice(card_index, 1);
      setTimeout(() => {
        setJobs(copy);
        if (card) {
          card.classList.add("d-none");
        }
        getApplicantShortlistedJobs();
      }, 1000);
    } else {
      setTimeout(() => {
        const ref = true;
        getApplicantShortlistedJobs({ ref });
      }, 1000);
    }
  };

  const [showVerifyEmail, setShowVerifyEmail] = useState(false);

  const handleSwipe = (
    job,
    action,
    card_index,
    new_class,
    emp_img,
    employer
  ) => {
    console.log(new_class);
    const jobId = job._id;
    const employerId = job.employerId;

    if (
      (action === SWIPE_ACTIONS.SUPERLIKE || action === SWIPE_ACTIONS.LIKE) &&
      User?.user?.isEmailVerified === false
    ) {
      setShowVerifyEmail(true);
      return false;
    } else {
      let swipeActionRequest;
      if (action === SWIPE_ACTIONS.SUPERLIKE) {
        swipeActionRequest = applicantRequests.jobs.actions.superlike(
          jobId,
          employerId
        );
      }
      if (action === SWIPE_ACTIONS.LIKE) {
        swipeActionRequest = applicantRequests.jobs.actions.like(
          jobId,
          employerId
        );
      }
      if (action === SWIPE_ACTIONS.DISMISS) {
        swipeActionRequest = applicantRequests.jobs.actions.dismiss(
          jobId,
          employerId
        );
      }
      swipeActionRequest
        .then((res) => {
          console.log(res);
          if (res.data?.firstTimeSwipe) {
            if (action === SWIPE_ACTIONS.LIKE) {
              setJobActionData({
                action: "liked",
                job,
                message: (
                  <>
                    Now let’s just wait for the employer’s <br /> response.
                  </>
                ),
              });
            } else if (action === SWIPE_ACTIONS.DISMISS) {
              setJobActionData({
                action: "dismissed",
                job,
                message: (
                  <>
                    This job will be dismissed and it won’t appear in your feed
                    anymore.
                  </>
                ),
              });
            }
          }

          console.log(
            "HASHDHASHD",
            res.data.message === errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
          );

          if (
            res.data.message === errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
          ) {
            setLimitMainText("You have run out of likes today!");
            setLimitSubText(
              "That was fast! But don't worry, you can like again tomorrow."
            );
            setLimitOpen(true);
            return;
          } else if (
            res.data.message === errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
          ) {
            setLimitMainText("You have run out of likes for this month!");
            setLimitSubText("The great news is you can like again next month!");
            setLimitOpen(true);
            return;
          } else if (
            res.data.message === errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
          ) {
            setLimitMainText("You have run out of Super Likes!");
            setLimitSubText(
              "That was fast! But don't worry, you can Super Like again tomorrow."
            );
            setLimitOpen(true);
            return;
          } else if (res.data.message === errorSwipeActions.LIKE_NOT_ALLOWED) {
            setLimitMainText("Please upgrade account to start swiping!");
            setLimitSubText(
              "You are currently using a FREE account. Upgrade now to like this applicant."
            );
            setLimitOpen(true);
            return;
          } else {
            if (res.data.matchStatus == "match") {
              console.log("matched");
            }
            // //FIRST ACTION FOR SUPERLIKE note: jobseeker has no superlike action
            // if (action === "superLike" && (message === "" || !message)) {
            //   setJobInfo(data);
            //   console.log("ACTIONSUPERLIKE");
            //   setMessageForSuperlike("");
            //   setIsVisibleSuperLikeMessage(true);
            //   resolve(true);
            //   return;
            // }
            // if (action === "superLike") {
            //   console.log("swipe action successful");
            //   setIsVisibleSuperLikeMessage(false);
            //   setMessageForSuperlike("");
            //   setTimeout(() => {
            //     setIsVisibleSuperLikeSucess(true);
            //   }, 100);
            //   return;
            // }
            // getJobMatches();
            // navigation.goBack();

            // const images = {
            //   employer: res.data.profileUrl_employer,
            //   applicant: res.data.profileUrl_applicant,
            // };
            // setTimeout(() => {
            //   navigation.navigate("MatchScreen", {
            //     type: "applicant",
            //     applicant_data: user,
            //     job_data: job,
            //     images: images,
            //   });
            // }, 500);
            if (action === SWIPE_ACTIONS.LIKE) {
              onSwipeEnd(job, card_index, null, res.data, emp_img, employer);
            } else {
              onSwipeEnd(
                job,
                card_index,
                new_class,
                res.data,
                emp_img,
                employer
              );
            }
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      return true;
    }
  };

  const handleGoToMessage = (data) => {
    navigate("/messages", {
      state: { data },
    });
  };
  return (
    <>
      <SwipeLimitModal
        limitOpen={limitOpen}
        setLimitOpen={setLimitOpen}
        limitMainText={limitMainText}
        limitSubText={limitSubText}
      />
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={matchOpen}
        onClose={() => {
          setMatchOpen(false);
        }}
        style={{ backgroundColor: "#002E5D73" }}
      >
        <Box
          className={`${app_classes.padding.a4} ${app_classes.text.center} ${app_classes.backgroundColor.bg_white}`}
        >
          <Box className={`${app_classes.text.right} ${app_classes}`}>
            <Close
              className={`${app_classes.cursor.pointer}`}
              onClick={() => {
                setMatchOpen(false);
              }}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.H3 primary>It's A Match!</JobluTypography.H3>
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center}`}
          >
            <Box className={`${app_classes.margin.r1}`}>
              <img
                src={User?.user?.profileUrl || DefaultImg}
                width={90}
                height={90}
                style={{ objectFit: "cover" }}
              />
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              {" "}
              <img
                src={appImg || DefaultImg}
                width={90}
                height={90}
                style={{ objectFit: "cover" }}
              />
            </Box>
          </Box>
          <Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.Body style={{ fontSize: 14 }}>
                <strong>{empData?.companyName}</strong> has shortlisted you for
                the position of <strong>{jobDetails?.jobTitle}</strong>. You can
                now message them and start your application process!
              </JobluTypography.Body>
            </Box>
            <Box>
              <Button
                variant="contained"
                style={{ textTransform: "none" }}
                color="primary"
                onclick={() => {
                  handleGoToMessage(jobData);
                }}
              >
                Start Chatting
              </Button>
            </Box>
            <Box>
              <Button
                variant="text"
                onClick={() => {
                  setMatchOpen(false);
                }}
              >
                <JobluTypography.Body
                  primary
                  className={`${app_classes.text.capitalize}`}
                >
                  Keep Swiping
                </JobluTypography.Body>
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Grid container className={app_classes.width.w100}>
        <Grid
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          className={`${app_classes.margin.b2}`}
        >
          <Grid xs={12} sm={4}>
            <TextField
              size="small"
              // Autofocus on textfield

              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {!loading ? (
            <>
              <Box style={{ height: "60vh", overflowY: "auto" }}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={(page) => {
                    console.log("page", page);
                    getApplicantShortlistedJobs({ offset: page + 1 }, true);
                  }}
                  // hasMore={jobs?.totalCount === 20}
                  hasMore={Math.ceil(jobs?.totalCount / jobs?.data?.length) > 1}
                  loader={
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="flex-start"
                      alignContent="flex-start"
                      spacing={2}
                    >
                      <ListLoader count={1} />
                    </Grid>
                  }
                  useWindow={false}
                >
                  {jobs?.data?.length > 0 ? (
                    jobs?.data?.map((job, index) => {
                      return (
                        <JobCardList
                          job={job}
                          key={job._id}
                          showEmployerAction
                          isShortlisted
                          getApplicantShortlistedJobs={
                            getApplicantShortlistedJobs
                          }
                          handleSwipe={handleSwipe}
                          card_index={index}
                          setCurrentPage={setCurrentPage}
                        />
                      );
                    })
                  ) : (
                    <Grid
                      className={`${app_classes.margin.t5} ${app_classes.margin.b5}`}
                    >
                      <Box
                        className={`${app_classes.text.center} ${app_classes.margin.b2}`}
                      >
                        <img src={NoJobs} width={200} />
                      </Box>
                      <Box className={`${app_classes.margin.b2}`}>
                        <JobluTypography.H6
                          className={`${app_classes.text.center} `}
                        >
                          Find your next match job!
                        </JobluTypography.H6>
                      </Box>
                      <Box>
                        <JobluTypography.Body
                          className={`${app_classes.text.center} `}
                        >
                          One swipe could lead to your dream job.
                        </JobluTypography.Body>
                      </Box>
                      <Box className={`${app_classes.margin.b1}`}>
                        <JobluTypography.Body
                          className={`${app_classes.text.center} `}
                        >
                          Click explore and keep swiping.
                        </JobluTypography.Body>
                      </Box>
                      <Box className={app_classes.text.center}>
                        <Button
                          variant="outlined"
                          className={`${app_classes.button.primary_outline}`}
                          onClick={() => {
                            navigate("/dashboard");
                          }}
                        >
                          Explore
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </InfiniteScroll>
              </Box>
            </>
          ) : (
            <>
              <JobluTypography.H6 style={{ textAlign: "center" }}>
                <CircularProgress />
              </JobluTypography.H6>
            </>
          )}
        </Grid>
        {/* <Grid item xs={12} container justifyContent="center">
          <PaginationV1
            current_page={current_page}
            setCurrentPage={setCurrentPage}
            totalCount={jobs?.totalCount}
          />
        </Grid> */}
      </Grid>
      <EmailNotVerifiedModal
        open={showVerifyEmail}
        setOpen={setShowVerifyEmail}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
  };
};
export default connect(mapStateToProps, null)(ApplicantShortlistedJobs);
