import { makeStyles } from "@material-ui/core/styles";

export const company_showcase_styles = makeStyles((theme) => ({
  bg_primary_blue: {
    backgroundColor: "#002e5d!important",
  },
  profile_icon: {
    filter: "drop-shadow(0px 30px 30px rgba(0, 156, 222, 0.25))",
  },
}));
