import { default as FlatList2 } from 'flatlist-react'
import React from 'react'
import { default as FlatList_ } from 'flatlist-react'
import EmptyState from '../reusables/EmptyState'
import NoLikesImg from '../../assets/img/noLikesImg.png'

const FlatList = (props) => {
    const {
        data,
        renderItem,
        renderWhenEmpty,
        groupBy,
        sortBy,
        loadMoreItems,
        hasMoreItems,
        list,
    } = props
    return (
        <FlatList2
            list={data}
            renderItem={renderItem}
            renderWhenEmpty={() => (
                <div>
                    {list === 'likedMyJobs' && (
                        <EmptyState
                            emptyImg={NoLikesImg}
                            title={'Give it some time!'}
                            body={'Tap on Explore to find new candidates'}
                            link={'/dashboard'}
                            linkType={'button'}
                            linkTitle={'Explore'}
                        />
                    )}
                    {list === 'likes' && (
                        <EmptyState
                            emptyImg={NoLikesImg}
                            title={'Like someone first!'}
                            body={'Tap on Explore to find new candidates'}
                            link={'/dashboard'}
                            linkType={'button'}
                            linkTitle={'Explore'}
                        />
                    )}
                    {list === 'message' && (
                        <EmptyState body={'No conversation yet'} />
                    )}

                     {list === 'recommendations' && (
                        <EmptyState
                            emptyImg={NoLikesImg}
                            title={'There are no recommendations yet'}
                            body={'Tap on Explore to find new candidates'}
                            link={'/dashboard'}
                            linkType={'button'}
                            linkTitle={'Explore'}
                        />
                    )}
                </div>
            )}
            // sortBy={["firstName", {key: "lastName", descending: true}]}
            // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
            loadMoreItems={loadMoreItems}
            hasMoreItems={hasMoreItems}
            paginationLoadingIndicator={
                <div style={{ alignSelf: 'center' }}>Getting more items...</div>
            }
            paginationLoadingIndicatorPosition={'center'}
        />
    )
}
export default FlatList
