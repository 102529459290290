import { makeStyles } from "@material-ui/core/styles";
import EditProfileBg from "../../assets/img/editProfile/editProfileBg.png";
export const edit_profile_styles = makeStyles((theme) => ({
  default_img: {
    borderRadius: "50%",
    border: "1px solid #8080804D",
    objectFit: "contain",
    height: 162,
    width: 162,
  },
  bg_light: {
    backgroundColor: "#F6F9FB",
  },
  chip_active: {
    backgroundColor: "#009CDE!important",
    color: "#FFFFFF!important",
    marginRight: "0.5rem!important",
    fontSize: 12,
    marginBottom: "0.5rem!important",
    fontFamily: "Open Sans!important",
  },
  chip_inactive: {
    backgroundColor: "#E9EBEC!important",
    color: "#5D656D!important",
    marginRight: "0.5rem!important",
    fontSize: 12,
    marginBottom: "0.5rem!important",
    fontFamily: "Open Sans!important",
  },
  edit_profile_bg: {
    background: `url('${EditProfileBg}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  jobreel_button: {
    color: "#FFFFFF!important",
    backgroundColor: "#002E5D!important",
  },
  see_more: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 60,
    background: "rgb(255,255,255)",
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7511598389355743) 66%, rgba(255,255,255,0.4990589985994398) 81%, rgba(255,255,255,0.24695815826330536) 92%, rgba(255,255,255,0) 100%)",
  },
}));
