import { Box, Drawer } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import profilePlaceholder from "../../assets/img/defaultImg.png";
import salaryIcon from "../../assets/img/salaryIcon.svg";
import { JobluIconsV2 } from "../../assets/index";
import JobluBranding from "../../assets/joblu-2.0/img/new-branding/joblu.svg";
import jbHorizontal from "../../assets/joblu-logos/jb-horizontal.png";
import NotificationItem from "../../components/notification";
import { db, messaging } from "../../firebase";
import { app_styles } from "../../general.styles";
import rootAction from "../../redux/action";
import appActions from "../../redux/app/actions";
import notificationActions from "../../redux/notifications/action";
import userAction from "../../redux/user/actions";
import { userRequest } from "../../service/requests";
import Announcement from "../announcement";
import CustomToast from "../reusables/Toast/Toast";
import JobluTypography from "../Typography";
import "./styles.css";

const currencyList = ["INR", "PHP", "MYR", "SGD", "USD"];

function Header(props) {
  const {
    User,
    signOut,
    AppState,
    setCurrency,
    setChatRooms,
    getNotifications,
    Notifications,
    JobFairs,
    getSubscription,
  } = props;
  const { notificationsList, isFetching, isFetched } = Notifications;
  const { currency } = AppState;
  const { chatRooms } = User;
  const location = useLocation();
  const navigate = useNavigate();
  const app_classes = app_styles();
  const { pathname } = location;
  const is_create_profile =
    pathname === "/create-profile" || location?.state?.fromCreate;
  const [sideNav, setSideNav] = useState(false);
  const [notifStack, setNotifStack] = useState([]);

  const handleLogout = () => {
    try {
      userRequest
        .signOut({ token: User?.token })
        .then((res) => {
          signOut();
        })
        .finally(() => {
          setTimeout(() => {
            navigate("/login");
          }, 500);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const chatSound = new Audio(
    require("../../assets/sounds/joblu-chat-sound.mp3")
  );

  const [unReadMessages, setUnReadMessages] = useState(
    chatRooms?.filter((item) => {
      return (
        item.latestMessage.lastSender === "employer" &&
        item.latestMessage.isRead === false
      );
    }).length
  );

  const [currentReadMessages, setCurrentReadMessages] = useState(
    chatRooms?.filter((item) => {
      return (
        item.latestMessage.lastSender === "employer" &&
        item.latestMessage.isRead === false
      );
    }).length
  );

  useEffect(() => {
    setCurrentReadMessages(unReadMessages);
    const delayDebounceFn = setTimeout(() => {
      setUnReadMessages(
        chatRooms.filter((item) => {
          return (
            item.latestMessage.lastSender === "employer" &&
            item.latestMessage.isRead === false
          );
        }).length
      );
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [chatRooms]);

  useEffect(() => {
    console.log("CURRENT:::", currentReadMessages);
    console.log("INCOMING:::", unReadMessages);
    if (unReadMessages > 0) {
      if (currentReadMessages < unReadMessages) chatSound.play();
    }
  }, [unReadMessages]);

  useEffect(() => {
    getNotifications();
    getSubscription();
  }, []);

  const goToNotification = (data) => {
    console.log(data);
    userRequest
      .readNotification({
        notificationId: data._id,
        isRead: true,
      })
      .then((response) => {
        getNotifications();
      })
      .catch((err) => {
        console.log(err);
      });

    if (data.type === "jobprocess") {
      //go to jobs page
      navigate("/jobs", {
        state: { data },
      });
    } else if (data.type === "match") {
      // jobsRequest.getSpecificJob({jobId: data?.data?.jobId})
      // .then(response => {
      //   console.log(response?.data)
      // // go to job match or go to specific job view
      // })
      // .catch(e => {
      //     // go to job match or go to specific job view
      // })
      navigate("/jobs", {
        state: { index: 1, data },
      });
    } else if (data.type === "superlike" || data.type === "like") {
      // go to likes
      navigate("/jobs", {
        state: { index: 1, data },
      });
    } else if (data.type === "recommendation") {
      navigate("/recommendations");
    }
  };

  useEffect(() => {
    console.log("chatrooms:::", chatRooms);
  }, [chatRooms]);

  const fetchApplicantRoomList = async () => {
    var jobIdsList = [];
    const jobIds = await db
      .collection("userChatRooms")
      .doc(User.user.userId)
      .collection("rooms")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          jobIdsList.push(doc.data().jobId);
        });
      });

    console.log("JOBIDS LIST::", jobIdsList);

    const roomListMessage = [];

    const allPromise = await Promise.all(
      jobIdsList.map(async (jobinfo, index) => {
        const roomInfo = await db
          .collection("rooms")
          .doc(jobinfo)
          .collection("applicants")
          .get()
          .then(async (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // console.log(doc.data())
              if (doc.id === User.user.userId) {
                const info = {
                  _id: doc.id,
                  ...doc.data(),
                };
                roomListMessage.push(info);
              }
            });
          })
          .catch((e) => {
            console.log("applicants query snapshot catch:");
            console.log(e);
          });
      })
    ).catch((e) => {
      console.log("All Promise catch");
      console.log(e);
    });
    if (roomListMessage.length > 0) {
      setChatRooms(
        roomListMessage.sort((a, b) =>
          a.latestMessage.createdAt < b.latestMessage.createdAt ? 1 : -1
        )
      );
    } else {
      setChatRooms([]);
    }
  };

  useEffect(() => {
    if (User?.user?.userId) {
      const roomListener = db
        .collectionGroup("applicants")
        ?.where("applicantId", "==", User.user.userId)
        .orderBy("latestMessage.createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          fetchApplicantRoomList();
        });

      const roomListMessage = [];
      const roomInfoListener = db
        ?.collectionGroup("applicants")
        ?.where("employerId", "==", User.user.userId)
        ?.orderBy("latestMessage.createdAt", "desc")
        ?.get()
        ?.then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().roomName !== undefined) {
              const info = {
                _id: doc.id,
                ...doc.data(),
                profileUrl: User.user.profileUrl,
              };
              roomListMessage.push(info);
            }
          });
          if (roomListMessage.length > 0) {
            setChatRooms(roomListMessage);
          }
        })
        .catch((e) => {
          console.log(e);
        });

      return () => roomListener();
    }
  }, []);

  messaging &&
    messaging.onMessage((payload) => {
      console.log("Message received. ", payload);

      if (payload?.data?.type !== "message") {
        setNotifStack([...notifStack, payload]);
        getNotifications();
        //handle notif alert (toastr boxes??) in lower right side for notifications received
      }
    });

  const handleNavigate = (url) => {
    navigate(url);
    setSideNav(false);
  };

  const [sideNavOpen, setSideNavOpen] = useState(false);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={() => {
        // setSideNavOpen(false);
      }}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box className={`${app_classes.padding.a2}`}>
        <div className="d-flex">
          <div>
            <img
              className="img-fluid br5 account-img-2 img-fit border"
              src={User?.user?.profileUrl || profilePlaceholder}
              alt="Login Logo"
              style={{
                width: 50,
                height: 50,
              }}
            />
          </div>
          <div className="px-1">
            <p className="fs14 text-body fw600">
              {User?.user?.firstName} {User?.user?.lastName}
            </p>
            {!is_create_profile && (
              <a
                onClick={() => {
                  setSideNavOpen(false);
                  handleNavigate("/profile");
                }}
                style={{
                  textDecoration: "none",
                }}
              >
                <p className="small text-primary text-capitalize">
                  Edit Profile
                </p>
              </a>
            )}
          </div>
        </div>
        <hr />

        {!is_create_profile && (
          <>
            <Box
              className={`${app_classes.display.display_flex} ${app_classes.margin.b2} ${app_classes.flexBox.align_items_center}`}
              onClick={() => {
                if (!!JobFairs.signedInJobFair) {
                  setSideNavOpen(false);
                  navigate(`/job-fair/dashboard/${JobFairs.signedInJobFair}`);
                } else {
                  setSideNavOpen(false);
                  navigate("/dashboard");
                }
              }}
            >
              <Box className={`${app_classes.margin.r2} `}>
                <img
                  src={
                    pathname === "/dashboard"
                      ? JobluIconsV2.dashboardFilled
                      : JobluIconsV2.cards
                  }
                  alt=""
                  style={{ width: 24 }}
                />
              </Box>

              <Box>
                <JobluTypography.H6>Explore</JobluTypography.H6>
              </Box>
            </Box>
          </>
        )}
        {!is_create_profile && (
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.margin.b2} ${app_classes.flexBox.align_items_center}`}
            onClick={() => {
              setSideNavOpen(false);
              handleNavigate("/jobs");
            }}
          >
            <Box className={`${app_classes.margin.r2} `}>
              <img
                src={
                  pathname === "/jobs"
                    ? JobluIconsV2.jobFilled
                    : JobluIconsV2.jobs
                }
                alt=""
                style={{ width: 24 }}
              />
            </Box>

            <Box>
              <JobluTypography.H6>My Jobs</JobluTypography.H6>
            </Box>
          </Box>
        )}
        {!is_create_profile && (
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.margin.b2} ${app_classes.flexBox.align_items_center}`}
            onClick={() => {
              setSideNavOpen(false);
              handleNavigate("/messages");
            }}
          >
            <Box className={`${app_classes.margin.r2} `}>
              <img
                src={
                  pathname === "/messages"
                    ? JobluIconsV2.messagesFilled
                    : JobluIconsV2.message
                }
                alt=""
                style={{ width: 24 }}
              />
            </Box>

            <Box>
              <JobluTypography.H6>Messages</JobluTypography.H6>
            </Box>
          </Box>
        )}
        {!is_create_profile && (
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.margin.b2} ${app_classes.flexBox.align_items_center}`}
            onClick={() => {
              setSideNavOpen(false);
              handleNavigate("/notifications");
            }}
          >
            <Box className={`${app_classes.margin.r2} `}>
              <img
                src={
                  pathname === "/notifications"
                    ? JobluIconsV2.notificationsFilled
                    : JobluIconsV2.notifications
                }
                alt=""
                style={{ width: 24 }}
              />
            </Box>

            <Box>
              <JobluTypography.H6>
                {" "}
                Notifications
                <span className="text-dark ms-2 mt-0 badge rounded-pill bg-warning border ">
                  {notificationsList.totalUnread
                    ? notificationsList.totalUnread
                    : null}
                </span>
              </JobluTypography.H6>
            </Box>
          </Box>
        )}
        {!is_create_profile && <hr />}
        {!is_create_profile && (
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.margin.b2} ${app_classes.flexBox.align_items_center}`}
          >
            <Box className={`${app_classes.margin.r2} `}>
              <img src={salaryIcon} alt="" style={{ width: 24 }} />
            </Box>

            <Box>
              <JobluTypography.H6>
                Currency:
                <div class="btn-group dropdown ms-2">
                  <span
                    class="dropdown-toggle text-dark cpointer"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {currency}
                  </span>
                  <ul class="dropdown-menu p-2">
                    {currencyList.map((cur, index) => {
                      // console.log(cur);
                      return (
                        <li
                          key={cur}
                          className="dropdown-item text-center"
                          role="button"
                          onClick={() => setCurrency(cur)}
                          style={{
                            width: "100%",
                          }}
                        >
                          {cur}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </JobluTypography.H6>
            </Box>
          </Box>
        )}
        {!is_create_profile && (
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.margin.b2} ${app_classes.flexBox.align_items_center}`}
            onClick={() => {
              setSideNavOpen(false);
              handleNavigate("/settings");
            }}
          >
            <Box className={`${app_classes.margin.r2} `}>
              <img src={JobluIconsV2.settings2} alt="" style={{ width: 24 }} />
            </Box>

            <Box>
              <JobluTypography.H6>Settings</JobluTypography.H6>
            </Box>
          </Box>
        )}
        <Box
          className={`${app_classes.display.display_flex} ${app_classes.margin.b2} ${app_classes.flexBox.align_items_center}`}
          onClick={() => {
            setSideNavOpen(false);
            handleLogout();
          }}
        >
          <Box className={`${app_classes.margin.r2} `}>
            <img src={JobluIconsV2.logout} alt="" style={{ width: 24 }} />
          </Box>

          <Box>
            <JobluTypography.H6>Logout</JobluTypography.H6>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  useEffect(() => {
    if (User?.isFetched) {
      fetchApplicantRoomList();
    }
  }, [User?.isFetching]);

  return (
    <>
      <CustomToast data={notifStack} />
      {/* {!is_create_profile && (
        <>
          <Announcement />
        </>
      )} */}
      <nav
        className="navbar navbar-expand-lg nav-box-shadow px-2 sticky-top"
        style={{ background: "#fff" }}
      >
        <div className="container-fluid">
          <a
            className="navbar-brand text-primary px-0 px-lg-5"
            href="/dashboard"
          >
            <img
              // className="img-fluid"
              src={jbHorizontal}
              alt="img"
              style={{ width: "150px" }}
            />
          </a>
          <button className="navbar-toggler" type="button">
            <span className="navbar-toggler-icon d-none d-lg-block">
              <img src={JobluIconsV2.toggleMenu} alt="" className="" />
            </span>
            <span
              className="navbar-toggler-icon d-block d-lg-none"
              onClick={() => {
                // setSideNav(true);
                setSideNavOpen(true);
              }}
            >
              <img src={JobluIconsV2.toggleMenu} alt="" className="" />
            </span>
          </button>
          {/* <!--toggle btn END-->
                
                <!--nav bar--> */}
          <div
            className="collapse navbar-collapse d-flex justify-content-end d-none d-lg-block"
            id="navbarSupportedContent"
          >
            {/* <!--search bar-->
                    {{-- <input className="form-control mr-sm-2 br-3 w-25" type="text" placeholder="Search for Candidates" id="quickSearchBox" aria-label="Search"> --}}
                    <!--search bar END--> */}

            <ul className="navbar-nav right-0" id="navbar-links">
              <li
                className="nav-item text-center w-100 nav-item-active"
                style={{ display: is_create_profile && "none" }}
              >
                <div
                  className="me-2  nav-link d-flex flex-column align-items-center"
                  href="/dashboard"
                  role="button"
                  onClick={() => {
                    if (!!JobFairs.signedInJobFair) {
                      navigate(
                        `/job-fair/dashboard/${JobFairs.signedInJobFair}`
                      );
                    } else {
                      navigate("/dashboard");
                    }
                  }}
                >
                  <img
                    src={
                      pathname === "/dashboard"
                        ? JobluIconsV2.dashboardFilled
                        : JobluIconsV2.cards
                    }
                    alt=""
                    style={{ height: 24 }}
                  />

                  <p>Explore</p>
                </div>
              </li>
              <li
                className="nav-item text-center w-100 nav-item-active"
                style={{ display: is_create_profile && "none" }}
              >
                <div
                  className="me-2 nav-link d-flex flex-column align-items-center justify-content-between"
                  href="/dashboard"
                  role="button"
                  onClick={() => navigate("/jobs")}
                >
                  <img
                    src={
                      pathname === "/jobs" || pathname === "/matches"
                        ? JobluIconsV2.jobFilled
                        : JobluIconsV2.jobs
                    }
                    alt=""
                    style={{ height: 24 }}
                  />

                  <p className="text-nowrap">My Jobs</p>
                </div>
              </li>
              {/* <li className="nav-item text-center w-100 nav-item-active">
                                <div
                                    className="me-2  nav-link d-flex flex-column align-items-center"
                                    onClick={() => navigate('/likes')}
                                    role="button"
                                >
                                    <img
                                        src={
                                            pathname === '/likes'
                                                ? JobluIconsV2.likesFilled
                                                : JobluIconsV2.likesicon
                                        }
                                        alt=""
                                        style={{ height: 24 }}
                                    />
                                    <p className="text-nowrap">Likes</p>
                                </div>
                            </li> */}
              <li
                style={{ display: is_create_profile && "none" }}
                className="nav-item dropdown dropdown2 text-center w-100 position-relative nav-item-active d-none"
              >
                <div
                  className="me-2  nav-link d-flex flex-column align-items-center no-arrow  my-dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="notif-link"
                  onClick={() => handleNavigate("/matches")}
                >
                  <img
                    src={
                      pathname === "/matches"
                        ? JobluIconsV2.resumeFilled
                        : JobluIconsV2.resume
                    }
                    alt=""
                    style={{ height: 24 }}
                  />
                  <p className="text-nowrap">Matches</p>
                </div>
                <div
                  className="dropdown-menu dropdown-center"
                  aria-labelledby="navbarDropdown"
                >
                  <p
                    className="dropdown-item "
                    onClick={() =>
                      navigate("/matches", {
                        state: { tab: "Active" },
                      })
                    }
                    role="button"
                  >
                    Active
                  </p>
                  <p
                    className="dropdown-item "
                    onClick={() =>
                      navigate("/matches", {
                        state: { tab: "Shortlisted" },
                      })
                    }
                    role="button"
                  >
                    Shortlisted
                  </p>
                  <p
                    className="dropdown-item "
                    onClick={() =>
                      navigate("/matches", {
                        state: { tab: "Archived" },
                      })
                    }
                    role="button"
                  >
                    Archived
                  </p>
                </div>
              </li>
              <li
                className="nav-item text-center w-100 nav-item-active"
                style={{ display: is_create_profile && "none" }}
              >
                <div
                  className="me-2  nav-link d-flex flex-column align-items-center"
                  onClick={() => navigate("/messages")}
                  role="button"
                >
                  <div className="position-relative">
                    <img
                      src={
                        pathname === "/messages"
                          ? JobluIconsV2.messagesFilled
                          : JobluIconsV2.message
                      }
                      alt=""
                      style={{ height: 24 }}
                    />
                    <span className="position-absolute top-0 text-dark start-100 translate-middle badge rounded-pill bg-warning border ">
                      {unReadMessages ? unReadMessages : null}
                    </span>
                  </div>
                  <p>Messages</p>
                </div>
              </li>
              <li
                className="nav-item dropdown text-center w-100 position-relative nav-item-active"
                style={{ display: is_create_profile && "none" }}
              >
                <div
                  className="me-2 nav-link d-flex flex-column align-items-center no-arrow  my-dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="notif-link"
                >
                  <div className="position-relative">
                    <img
                      src={
                        pathname === "/notifications"
                          ? JobluIconsV2.notificationsFilled
                          : JobluIconsV2.notifications
                      }
                      alt=""
                      style={{ height: 24 }}
                    />
                    <span className="position-absolute top-0 text-dark start-100 translate-middle badge rounded-pill bg-warning border ">
                      {notificationsList.totalUnread
                        ? notificationsList.totalUnread
                        : null}
                    </span>
                  </div>
                  <p>Notifications</p>
                </div>

                {/* <!--notification dropdown--> */}
                <div
                  className="dropdown-menu dropdown-menu-right mx-auto mx-md-0 py-0 notif-dropdown"
                  id="notif-dropdown"
                  aria-labelledby="navbarDropdown"
                  style={{ width: 318, left: -100 }}
                >
                  {/* <div
                                        className="text-right mr-2 mark-all-as-read"
                                        style={{ display: 'none' }}
                                    >
                                        <a
                                            className="nav-link small text-decoration-none"
                                            href="/notifications/readAll"
                                        >
                                            Mark All as Read
                                        </a>
                                    </div> */}
                  <div className="dropdown-divider top-divider mb-1 mt-0"></div>
                  {isFetching && (
                    <div className="loading">
                      <p className="small pt-5 pb-5 text-center">Loading...</p>
                    </div>
                  )}
                  {notificationsList?.data?.length === 0 && (
                    <div
                      className="no-notifications"
                      style={{ display: "none" }}
                    >
                      <p className="small pt-5 pb-5 text-center">
                        No notifications yet.
                      </p>
                    </div>
                  )}
                  {notificationsList?.data?.length > 0 &&
                    notificationsList?.data
                      ?.filter((filt, index) => index <= 5)
                      ?.map((item) => {
                        //todo: @ced fix the styling of notif item, make it smaller here on header
                        return (
                          <>
                            <NotificationItem
                              data={item}
                              size="small"
                              small
                              action={() => {
                                goToNotification(item);
                              }}
                            />
                          </>
                        );
                      })}
                  {notificationsList?.data?.length > 0 ? (
                    <>
                      <div className="view-all-divider dropdown-divider m-0"></div>
                      <div
                        className="view-all dropdown-item small text-center open-sans"
                        onClick={() => navigate("/notifications")}
                        role="button"
                      >
                        View All
                      </div>
                    </>
                  ) : (
                    <div className="text-center p-2">
                      <p>No notifications yet.</p>
                    </div>
                  )}
                </div>
                {/* <!--notification dropdown END--> */}
              </li>

              <li
                style={{ display: is_create_profile && "none" }}
                className="nav-item dropdown text-center  w-100 position-relative nav-item-active text-nowrap"
              >
                <div
                  className="me-2 nav-link d-flex align-items-center dropdown-toggle h-100 justify-content-center"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="notif-link"
                >
                  <p style={{ verticalAlign: "middle" }} className="m-0">
                    {currency}
                  </p>
                </div>
                <div
                  className="dropdown-menu dropdown-center currency-dropdown-container"
                  aria-labelledby="navbarDropdown"
                  style={{
                    width: 50,
                    left: -50,
                    maxHeight: 300,
                    overflowY: "auto",
                  }}
                >
                  {currencyList.map((cur, index) => {
                    return (
                      <div
                        key={cur}
                        className="dropdown-item text-center"
                        // role="button"
                        onClick={() => setCurrency(cur)}
                        style={{
                          width: "100%",
                        }}
                      >
                        {cur}
                      </div>
                    );
                  })}
                </div>
              </li>

              {/* <!--Sub Menu--> */}
              <li className="nav-item dropdown mb-0 text-center w-100 d-flex">
                <a
                  className="nav-link py-auto dropdown-toggle d-flex align-items-center justify-content-center"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    className="account-img-1 img-fit border"
                    src={User?.user?.profileUrl || profilePlaceholder}
                    alt="img"
                    style={{ width: 35, height: 35 }}
                  />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdown"
                  style={{ width: 280, left: -200 }}
                >
                  <div
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <div className="d-flex mx-4">
                      <div>
                        <img
                          className="img-fluid br5 account-img-2 img-fit border"
                          src={User?.user?.profileUrl || profilePlaceholder}
                          alt="Login Logo"
                          style={{
                            width: 50,
                            height: 50,
                          }}
                        />
                      </div>
                      <div className="px-1    ">
                        <p className="fs14 text-body fw600 hand">
                          {User?.user?.firstName} {User?.user?.lastName}
                        </p>
                        <span
                          // href="/profile"
                          onClick={() => {
                            // navigate("edit-profile");
                            navigate("settings");
                          }}
                          style={{
                            textDecoration: "none",
                            display: is_create_profile && "none",
                          }}
                        >
                          <p className="small text-primary text-capitalize hand">
                            Edit Profile
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-divider mx-3"></div>
                  <div
                    className="dropdown-item fs14 py-3"
                    style={{
                      textDecoration: "none",
                      display: is_create_profile && "none",
                    }}
                    onClick={() => navigate("/settings")}
                    role="button"
                  >
                    <img
                      src={JobluIconsV2.settings2}
                      className="me-2 h-24"
                      alt=""
                    />
                    Settings
                  </div>
                  <div
                    className="dropdown-item fs14 py-3"
                    style={{ textDecoration: "none" }}
                    onClick={() => handleLogout()}
                    role="button"
                  >
                    <img
                      src={JobluIconsV2.logout}
                      className="me-2 h-24"
                      alt=""
                    />{" "}
                    Logout
                  </div>
                </div>
              </li>
              {/* <!--Sub Menu END--> */}
            </ul>
          </div>
          {/* <!--nav bar END--> */}
        </div>
        {/* <!--nav bar END--> */}
        {sideNav && (
          <div
            className="position-fixed"
            style={{
              backgroundColor: "#00122580",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1100,
            }}
          >
            <div id="sideNav" className="bg-white p-3">
              <div className="float-end">
                <span
                  className="cpointer"
                  onClick={() => {
                    setSideNav(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </span>
              </div>
              <div className="d-flex">
                <div>
                  <img
                    className="img-fluid br5 account-img-2 img-fit border"
                    src={User?.user?.profileUrl || profilePlaceholder}
                    alt="Login Logo"
                    style={{
                      width: 50,
                      height: 50,
                    }}
                  />
                </div>
                <div className="px-1">
                  <p className="fs14 text-body fw600">
                    {User?.user?.firstName} qwe
                  </p>
                  <a
                    onClick={() => handleNavigate("/profile")}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <p className="small text-primary text-capitalize">
                      {User?.user?.employerType}
                    </p>
                  </a>
                </div>
              </div>
              <hr />
              <div
                className="row mb-3"
                onClick={() => {
                  setSideNav(false);
                  if (!!JobFairs.signedInJobFair) {
                    navigate(`/job-fair/dashboard/${JobFairs.signedInJobFair}`);
                  } else {
                    navigate("/dashboard");
                  }
                }}
              >
                <div className="col-1">
                  <img
                    src={
                      pathname === "/dashboard"
                        ? JobluIconsV2.dashboardFilled
                        : JobluIconsV2.cards
                    }
                    alt=""
                    style={{ width: 24 }}
                  />
                </div>
                <div className="col-11">Explore</div>
              </div>
              <div className="row mb-3" onClick={() => handleNavigate("/jobs")}>
                <div className="col-1">
                  <img
                    src={
                      pathname === "/jobs"
                        ? JobluIconsV2.jobFilled
                        : JobluIconsV2.jobs
                    }
                    alt=""
                    style={{ width: 24 }}
                  />
                </div>
                <div className="col-11">My Jobs</div>
              </div>

              <div
                className="row mb-3"
                onClick={() => handleNavigate("/matches")}
              >
                <div className="col-1">
                  <img
                    src={
                      pathname === "/matches"
                        ? JobluIconsV2.resumeFilled
                        : JobluIconsV2.resume
                    }
                    alt=""
                    style={{ width: 24 }}
                  />
                </div>
                <div className="col-11">Matches</div>
              </div>
              <div
                className="row mb-3"
                onClick={() => handleNavigate("/messages")}
              >
                <div className="col-1">
                  <img
                    src={
                      pathname === "/messages"
                        ? JobluIconsV2.messagesFilled
                        : JobluIconsV2.message
                    }
                    alt=""
                    style={{ width: 24 }}
                  />
                </div>
                <div className="col-11">
                  Messages
                  <span className="text-dark ms-2 badge rounded-pill bg-warning border ">
                    {unReadMessages ? unReadMessages : null}
                  </span>
                </div>
              </div>
              <div
                className="row mb-3"
                onClick={() => handleNavigate("/notifications")}
              >
                <div className="col-1">
                  <img
                    src={
                      pathname === "/notifications"
                        ? JobluIconsV2.notificationsFilled
                        : JobluIconsV2.notifications
                    }
                    alt=""
                    style={{ width: 24 }}
                  />
                </div>
                <div className="col-11">
                  Notifications
                  <span className="text-dark ms-2 mt-0 badge rounded-pill bg-warning border ">
                    {notificationsList.totalUnread
                      ? notificationsList.totalUnread
                      : null}
                  </span>
                </div>
              </div>
              <hr />
              <div className="row mb-3">
                <div className="col-1">
                  <img src={salaryIcon} className="me-2 h-24" alt="" />
                </div>
                <div className="col-11">
                  Currency:
                  <div class="btn-group dropdown ms-2">
                    <span
                      class="dropdown-toggle text-dark cpointer"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {currency}
                    </span>
                    <ul class="dropdown-menu p-2">
                      {currencyList.map((cur, index) => {
                        // console.log(cur);
                        return (
                          <li
                            key={cur}
                            className="dropdown-item text-center"
                            role="button"
                            onClick={() => setCurrency(cur)}
                            style={{
                              width: "100%",
                            }}
                          >
                            {cur}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="row mb-3"
                onClick={() => handleNavigate("/settings")}
              >
                <div className="col-1">
                  <img
                    src={JobluIconsV2.settings2}
                    className="me-2 h-24"
                    alt=""
                  />
                </div>
                <div className="col-11">Settings</div>
              </div>
              <div className="row mb-3" onClick={() => handleLogout()}>
                <div className="col-1">
                  <img src={JobluIconsV2.logout} className="me-2 h-24" alt="" />
                </div>
                <div className="col-11">Logout</div>
              </div>
            </div>
          </div>
        )}

        <Drawer
          anchor="left"
          open={sideNavOpen}
          onClose={() => {
            setSideNavOpen(false);
          }}
        >
          {list("left")}
        </Drawer>
      </nav>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    User: state.User,
    AppState: state.Generals,
    Notifications: state.Notifications,
    JobFairs: state.JobFairs,
  };
};

const mapDispatchToProps = {
  signOut: rootAction.signOut,
  setCurrency: appActions.setCurrency,
  setChatRooms: userAction.setChatRooms,
  getNotifications: notificationActions.getNotifications,
  getSubscription: userAction.getActiveSubscription,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
