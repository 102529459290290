import CompanyReview from "../views/company-review/CompanyReview";
import CompanyShowcasePublic from "../views/company-showcase-public";
import DownloadProfileApplicant from "../views/download-profile/DownloadProfileApplicant";
import Promote from "../views/employee-review/Promote";
import JobSeekerExplorePublic from "../views/explore-public/jobseeker-explore/";
import Home from "../views/home/Home";
import JobFairRegister from "../views/job-fair/Register";
import Login from "../views/login/Login";
import PasswordReset from "../views/password-reset/PasswordReset";
import RegisterCode from "../views/register-code/RegisterCode";
import Signup from "../views/signup/Signup";
import SignupHook from "../views/signup/SignupHook";
import SignUpWithCode from "../views/signup/SignupWithCode";
import VerifyEmail from "../views/verify/VerifyEmail";
import ViewJobPublic from "../views/view-job-public";
const publicRoutes = [
  {
    name: "Home",
    path: "/",
    component: <Home />,
    excludeFromNav: true,
    index: true,
  },
  { name: "Login", path: "/login", component: <Login /> },
  {
    name: "Signup",
    path: "/signup/employer",
    component: <Signup />,
  },
  {
    name: "invite Code",
    path: "/signup/invite",
    component: <SignUpWithCode />,
    excludeFromNav: true,
  },
  {
    name: "Password Reset",
    path: "/password-reset",
    component: <PasswordReset />,
    excludeFromNav: true,
  },
  {
    name: "Download Profile Applicant",
    path: "/downloadprofilepdf",
    component: <DownloadProfileApplicant />,
    excludeFromNav: true,
  },
  {
    name: "Download Profile Applicant",
    path: "/registercode/:code",
    component: <RegisterCode />,
    excludeFromNav: true,
  },
  {
    name: "Verify Email",
    path: "/verify",
    component: <VerifyEmail />,
    excludeFromNav: true,
  },
  {
    name: "Job Fair",
    path: "/job-fair",
    component: <JobFairRegister />,
    excludeFromNav: true,
  },
  {
    name: "Company Review",
    path: "/showcase/employee-review/:id",
    component: <CompanyReview />,
    excludeFromNav: true,
  },
  {
    name: "Promote",
    path: "/showcase/video-from-heart/:id",
    component: <Promote />,
    excludeFromNav: true,
  },
  {
    name: "Applicant Signup",
    path: "/signup",
    component: <SignupHook />,
    excludeFromNav: true,
  },
  {
    name: "Public Job",
    path: "/job/:refId/",
    component: <ViewJobPublic />,
    excludeFromNav: true,
  },
  {
    name: "Public Job",
    path: "/job/:refId/:jobTItle",
    component: <ViewJobPublic />,
    excludeFromNav: true,
  },
  {
    name: "Public Company View",
    path: "/company-showcase/:empId",
    component: <CompanyShowcasePublic />,
    excludeFromNav: true,
  },
  {
    name: "ExplorePublic",
    path: "/board",
    component: <JobSeekerExplorePublic />,
  },
];

export default publicRoutes;
