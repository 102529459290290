import { Box, Button, Chip, Dialog, Grid, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
// import IndustryIcon from "../../assets/img/matches/industryIcon.png";
import { Link } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import SendIcon from "@material-ui/icons/Send";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultImg from "../../assets/img/defaultImg.png";
import DismissIcon from "../../assets/img/jobs/DismissIcon.png";
import EducationIcon from "../../assets/img/jobs/EducationIcon.png";
import EmploymentTypeIcon from "../../assets/img/jobs/EmploymentTypeIcon.png";
import ExpandIcon from "../../assets/img/jobs/ExpandIcon.png";
import ExperienceIcon from "../../assets/img/jobs/ExperienceIcon.png";
import IndustryIcon from "../../assets/img/jobs/IndustryIcon.png";
import LikeIcon from "../../assets/img/jobs/LikeIcon.png";
import LocationIcon from "../../assets/img/jobs/LocationIcon.png";
import SalaryIcon from "../../assets/img/jobs/SalaryIcon.png";
import ShareIcon from "../../assets/img/jobs/ShareIcon.png";
import WorkSetupIcon from "../../assets/img/jobs/WorkSetupIcon.png";
import { app_styles } from "../../general.styles";
import { salaryRanges } from "../../utils/constants";
import { formatMoney, openNewConnect, replaceSpaces } from "../../utils/helpers";
import useWindowDimensions from "../../utils/hooks";
import { SWIPE_ACTIONS } from "../../views/my-jobs/components/card";
import JobluTypography from "../Typography";
import { modal_styles } from "./styles";

export const SLIDE_CLASSES = {
  SLIDE_LEFT: "slide-out-left",
  SLIDE_RIGHT: "slide-out-right",
  SLIDE_TOP: "slide-out-top",
};

// import ExpandIcon from "../../assets/img/jobs/ExpandIcon.png";
const JobDetailsModal = ({
  open,
  setOpen,
  job,
  isFromExplore,
  isShortlisted,
  AppState,
  handleSwipe,
  card_index,
  onSwipe,
  publicJob,
  isFromProfile,
  companyView,
  ...props
}) => {
  const app_classes = app_styles(props);
  const modal_classes = modal_styles(props);

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const isFromMatches = true;
  

  const { currencyList, currency } = AppState;
  const currencyOptions = {
    currencyList,
    currency,
  };

  const swipeAction = (job, action) => {
    if (action == "like") {
      const success = onSwipe(
        job,
        SWIPE_ACTIONS.LIKE,
        card_index,
        SLIDE_CLASSES.SLIDE_RIGHT
      );
      if (success) {
        setTimeout(() => {}, 1);
        setOpen(false);
      }
    }
    if (action == "dismiss") {
      const success = onSwipe(
        job,
        SWIPE_ACTIONS.DISMISS,
        card_index,
        SLIDE_CLASSES.SLIDE_LEFT
      );
      if (success) {
        setOpen(false);
      }
    }
  };

  const [jobCopied, setJobCopied] = useState(false);
  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    setJobCopied(true);
    setTimeout(() => {
      setJobCopied(false);
    }, 2000);
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      maxWidth="md"
      scroll={"body"}
      fullWidth
      style={{ zIndex: 9999 }}
    >
      <Box
        className={`${app_classes.text.right} ${app_classes.margin.r1}  ${app_classes.margin.t1}`}
      >
        <CloseIcon
          className={`${app_classes.cursor.pointer}`}
          onClick={() => {
            setOpen(null);
          }}
        />
      </Box>
      <Box
        className={` ${app_classes.width.w100} ${app_classes.general.job_details_modal} ${app_classes.padding.a0}`}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={7}>
            <Box className={`${app_classes.padding.a3}`}>
              <Box className={`${app_classes.text.center}`}>
                <Box className={`${app_classes.margin.b2}`}>
                  <img
                    alt=""
                    src={
                      job?.companyLogo
                        ? job?.companyLogo
                        : job?.employer?.profileUrl
                        ? job?.employer?.profileUrl
                        : DefaultImg
                    }
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      border: "10px solid #C9CCCE",
                      objectFit: "cover",
                      backgroundColor: "#ffffff",
                    }}
                  />
                </Box>
                <Box className={`${app_classes.margin.b1}`}>
                  <JobluTypography.H2
                    semiBold
                    primary
                    className={`${app_classes.text.capitalize}`}
                  >
                    {job?.jobTitle}
                  </JobluTypography.H2>
                </Box>
                <Box className={`${app_classes.margin.b1}`}>
                  {!job?.companyName ? (
                    <>
                      {" "}
                      <Chip
                        color="primary"
                        label={`${
                          job?.companyName
                            ? job?.companyName
                            : job?.employer?.companyName
                        }`}
                        // className={`${modal_classes.chip_main}`}
                        onClick={() => {
                          if (!job?.companyName) {
                            window.open(
                              `/company-showcase/${
                                job?.employer?.userName || job?.employer?.userId
                              }`
                            );
                          }
                        }}
                        deleteIcon={
                          <KeyboardArrowRightIcon
                            style={{ color: "#FFFFFF" }}
                          />
                        }
                        onDelete={() => {
                          window.open(
                            `/company-showcase/${
                              job?.employer?.userName || job?.employer?.userId
                            }`
                          );
                        }}
                      ></Chip>
                    </>
                  ) : (
                    <>
                      <Chip
                        color="primary"
                        label={`${
                          job?.companyName
                            ? job?.companyName
                            : job?.employer?.companyName
                        }`}
                        onClick={() => {
                          if(job?.isNewConnect && job?.directCompanyLink){
                            window.open(job?.directCompanyLink)
                            return;
                          }
                        }}
                        deleteIcon={
                          <KeyboardArrowRightIcon
                            style={{ color: "#FFFFFF" }}
                          />
                        }
                        onDelete={() => {
                          if(job?.isNewConnect && job?.directCompanyLink){
                            window.open(job?.directCompanyLink)
                            return;
                          }
                        }}
                        // className={`${modal_classes.chip_main}`}
                      ></Chip>
                    </>
                  )}
                </Box>
                <Box className={`${app_classes.margin.b1}`}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.uppercase}`}
                  >
                    REFERENCE ID: {job?.referenceId}
                  </JobluTypography.Body>
                </Box>
                {job?.employer?.website && (
                  <>
                    <Box className={`${app_classes.margin.b2}`}>
                      <Link
                        target="_blank"
                        to={job?.employer?.website}
                        className={app_classes.margin.r1}
                      >
                        <JobluTypography.Body secondary semiBold>
                          Visit their website
                        </JobluTypography.Body>
                      </Link>
                    </Box>
                  </>
                )}
              </Box>
              <Box className={`${!job?.companyName && app_classes.margin.b3}`}>
                <Box>
                  <JobluTypography.H5 semiBold primary>
                    Responsibilities
                  </JobluTypography.H5>
                </Box>
                <Box>
                  <JobluTypography.Body
                    primary
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {job?.responsibilities}
                  </JobluTypography.Body>
                </Box>
              </Box>
              {!job?.companyName && job?.employer?.companyOverview && (
                <>
                  <Box>
                    <Box>
                      <JobluTypography.H5
                        semiBold
                        primary
                        onClick={() => {
                          console.log(job?.employer);
                        }}
                      >
                        About {job?.employer?.companyName}
                      </JobluTypography.H5>
                    </Box>
                    <Box>
                      <JobluTypography.Body
                        primary
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {job?.employer?.companyOverview}
                      </JobluTypography.Body>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            style={{ borderLeft: width > 640 && "1px solid #8080804D" }}
          >
            <Box className={`${app_classes.padding.a4}`}>
              <Box className={`${app_classes.margin.b3}`}>
                <Grid
                  container
                  spacing={1}
                  justifyContent={`${width < 640 ? "center" : "flex-end"}`}
                >
                  {!publicJob ? (
                    <>
                      {((isShortlisted && job?.matchStatus == "waiting") ||
                        isFromExplore) && (
                        <>
                          <Grid item>
                            <img
                              src={DismissIcon}
                              className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                              onClick={() => {
                                swipeAction(job, "dismiss");
                              }}
                            />
                          </Grid>

                          <Grid item>
                            <img
                              src={LikeIcon}
                              className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                              onClick={() => {
                                swipeAction(job, "like");
                              }}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item>
                        <img
                          onClick={() => {
                            window.open(
                              `/job/${job?.referenceId}/${replaceSpaces(
                                job?.jobTitle
                              )}`
                            );
                          }}
                          alt=""
                          src={ExpandIcon}
                          className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        size="large"
                        style={{ borderRadius: 100, height: "auto" }}
                        className={`${app_classes.button.primary}`}
                        endIcon={
                          <SendIcon
                            style={{ width: 16 }}
                            className={`${app_classes.margin.l1}`}
                          />
                        }
                        onClick={() => {
                          const newConnect = openNewConnect(job);
                          if(newConnect) return;

                          if (isFromProfile || companyView) {
                            window.open(
                              `/job/${job?.referenceId}/${replaceSpaces(
                                job?.jobTitle
                              )}`
                            );
                          } else {
                            setOpen(false);
                            onSwipe(job);
                          }
                        }}
                      >
                        {companyView ? "View Job " : "Apply Now"}
                      </Button>
                    </>
                  )}
                  {publicJob && (
                    <>
                      <Grid item>
                        <img
                          src={ExpandIcon}
                          className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                          onClick={() => {
                            window.open(
                              `/job/${job?.referenceId}/${replaceSpaces(
                                job?.jobTitle
                              )}`
                            );
                          }}
                          alt=""
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <Box>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setJobCopied(false)}
                        open={jobCopied}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Link Copied"
                      >
                        <img
                          src={ShareIcon}
                          className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                          alt=""
                          onClick={() => {
                            copyLink(
                              `https://jobs.joblu.io/job/${
                                job?.referenceId
                              }/${replaceSpaces(job?.jobTitle)}`
                            );
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Box className={`${app_classes.margin.b3}`}>
                  <Box className={`${app_classes.margin.b2}`}>
                    <JobluTypography.H5 semiBold primary>
                      Job Summary
                    </JobluTypography.H5>
                  </Box>
                  {job?.location && (
                    <>
                      <Box className={`${app_classes.margin.b3}`}>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r2}`}>
                            <img
                              src={LocationIcon}
                              className={`${modal_classes.icons}`}
                            />
                          </Box>
                          <Box>
                            <Box>
                              <JobluTypography.H6 primary>
                                {job?.location}
                              </JobluTypography.H6>
                            </Box>
                            <Box>
                              <JobluTypography.Body primary>
                                Location
                              </JobluTypography.Body>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}

                  {job?.jobFunction && (
                    <>
                      <Box className={`${app_classes.margin.b3}`}>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r2}`}>
                            <img
                              src={IndustryIcon}
                              className={`${modal_classes.icons}`}
                            />
                          </Box>
                          <Box>
                            <Box>
                              <JobluTypography.H6 primary>
                                {job?.jobFunction}
                              </JobluTypography.H6>
                            </Box>
                            <Box>
                              <JobluTypography.Body primary>
                                Industry
                              </JobluTypography.Body>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
                <Box className={`${app_classes.margin.b3}`}>
                  <Box className={`${app_classes.margin.b2}`}>
                    <JobluTypography.H5 semiBold primary>
                      What We Offer
                    </JobluTypography.H5>
                  </Box>
                  {!!job?.salaryMin && job?.salaryMax && (
                    <>
                      <Box className={`${app_classes.margin.b3}`}>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r2}`}>
                            <img
                              src={SalaryIcon}
                              className={`${modal_classes.icons}`}
                            />
                          </Box>
                          <Box>
                            <Box>
                              <JobluTypography.H6 primary>
                                {job?.salaryMin === 1 && job?.salaryMax === 1
                                  ? salaryRanges.COMPETIIVE_SALARY
                                  : job?.salaryMin === 2 && job?.salaryMax === 2
                                  ? salaryRanges.INDUSTRY_STANDARD
                                  : `${formatMoney(
                                      job?.salaryMin,
                                      currencyOptions,
                                      job?.salaryCurrency || "SGD"
                                    )} - ${formatMoney(
                                      job?.salaryMax,
                                      currencyOptions,
                                      job?.salaryCurrency || "SGD"
                                    )}`}
                              </JobluTypography.H6>
                            </Box>
                            <Box>
                              <JobluTypography.Body primary>
                                Compensation - Salary
                              </JobluTypography.Body>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}

                  {job?.employmentType?.length > 0 &&
                    job?.employmentType != "TBD" && (
                      <>
                        <Box className={`${app_classes.margin.b3}`}>
                          <Box
                            className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                          >
                            <Box className={`${app_classes.margin.r2}`}>
                              <img
                                src={EmploymentTypeIcon}
                                className={`${modal_classes.icons}`}
                              />
                            </Box>
                            <Box>
                              <Box>
                                <JobluTypography.H6 primary>
                                  {job?.employmentType?.join(", ")}
                                </JobluTypography.H6>
                              </Box>
                              <Box>
                                <JobluTypography.Body primary>
                                  Employment Type
                                </JobluTypography.Body>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  {job?.workSetup?.length > 0 && job?.workSetup != "TBD" && (
                    <>
                      <Box>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r2}`}>
                            <img
                              src={WorkSetupIcon}
                              className={`${modal_classes.icons}`}
                            />
                          </Box>
                          <Box>
                            <Box>
                              <JobluTypography.H6 primary>
                                {job?.workSetup?.join(", ")}
                              </JobluTypography.H6>
                            </Box>
                            <Box>
                              <JobluTypography.Body primary>
                                Work Setup
                              </JobluTypography.Body>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
                {job?.education?.length > 0 ||
                  (job?.totalExperienceMin ||
                  job?.totalExperienceMax &&
                  job?.totalExperienceMax !== 0) ? (
                    <>
                      <Box className={`${app_classes.margin.b3}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H5 semiBold primary>
                            Requirements
                          </JobluTypography.H5>
                        </Box>
                        {job?.education?.length > 0 &&
                          job?.education != "TBD" && (
                            <>
                              <Box className={`${app_classes.margin.b3}`}>
                                <Box
                                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                >
                                  <Box className={`${app_classes.margin.r2}`}>
                                    <img
                                      src={EducationIcon}
                                      className={`${modal_classes.icons}`}
                                    />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <JobluTypography.H6 primary>
                                        {job?.education?.join(", ")}
                                      </JobluTypography.H6>
                                    </Box>
                                    <Box>
                                      <JobluTypography.Body primary>
                                        Education
                                      </JobluTypography.Body>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </>
                          )}

                        {job?.totalExperienceMin ||
                        job?.totalExperienceMax &&
                        job?.totalExperienceMax !== 0 ? (
                          <>
                            <Box className={`${app_classes.margin.b2}`}>
                              <Box
                                className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                              >
                                <Box className={`${app_classes.margin.r2}`}>
                                  <img
                                    src={ExperienceIcon}
                                    className={`${modal_classes.icons}`}
                                  />
                                </Box>
                                <Box>
                                  <Box>
                                    <JobluTypography.H6 semiBold primary>
                                      {job?.totalExperienceMin &&
                                        `${job?.totalExperienceMin}`}
                                      {job?.totalExperienceMax == "100"
                                        ? "+ Years"
                                        : `- ${job?.totalExperienceMax} Years`}
                                    </JobluTypography.H6>
                                  </Box>
                                  <Box>
                                    <JobluTypography.Body primary>
                                      Work Experience
                                    </JobluTypography.Body>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        ) : null}
                      </Box>
                    </>
                  ) : null}

                {job?.technicalSkills?.length > 0 && (
                  <>
                    <Box className={`${app_classes.margin.b3}`}>
                      <Box className={`${app_classes.margin.b2}`}>
                        <JobluTypography.H5 semiBold primary>
                          Required Skills & Expertise
                        </JobluTypography.H5>
                      </Box>
                      <Box>
                        {job?.technicalSkills?.map((perks) => {
                          return (
                            <Chip
                              label={perks}
                              color="secondary"
                              className={`${modal_classes.chip}`}
                              // deleteIcon={<DoneIcon />}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  </>
                )}
                {job?.perksAndBenefits?.length > 0 && (
                  <>
                    <Box className={`${app_classes.margin.b3}`}>
                      <Box className={`${app_classes.margin.b2}`}>
                        <JobluTypography.H5 semiBold primary>
                          Perks and Benefits
                        </JobluTypography.H5>
                      </Box>
                      <Box>
                        {" "}
                        {job?.perksAndBenefits?.map((perks) => {
                          return (
                            <Chip
                              label={perks}
                              color="secondary"
                              className={`${modal_classes.chip}`}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

const mapDispatchToProps = (state) => {
  return { AppState: state.Generals };
};

export default connect(mapDispatchToProps)(JobDetailsModal);
