import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import emptyImg from '../../../assets/img/explore/emptyImg.svg'
import gridActiveIcon from '../../../assets/img/explore/gridActiveIcon.png'
import gridIcon from '../../../assets/img/explore/gridIcon.png'
import listActiveIcon from '../../../assets/img/explore/listActiveIcon.png'
import listIcon from '../../../assets/img/explore/listIcon.png'
import TextField from '../../../components/inputs/CustomTextField'
import SelectCountry2 from '../../../components/reusables/SelectCountry2'
import SelectJobFunction from '../../../components/reusables/SelectJobFunction'
import SelectJobPost from '../../../components/reusables/SelectJobPost'
import Filter from './components/Filter/Filter'
import ExploreGridViewCard2 from './components/GridView/ExploreGridViewV2'
import ExploreListviewCard from './components/ListView/ExploreListView'
import Pagination from './components/Pagination/Pagination'
import { ProfileModal } from './components/ViewProfile/ProfileModal'
import { SlidingProfile } from './components/ViewProfile/SlidingProfile'

const SkillsPill = ({ onDelete, index, label }) => {
    return (
        <span
            style={{ background: '#E9EBEC' }}
            className="pill rounded-pill fs-14 px-2 py-1 open-sans"
        >
            {label}
            {onDelete && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className=" ms-2 bi bi-x-circle"
                    viewBox="0 0 16 16"
                    role="button"
                    onClick={() => onDelete(index)}
                >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            )}
        </span>
    )
}
const ExploreV2 = ({
    jobSeekers,
    setFilters,
    filters,
    handleFilter,
    onSwipeFulfilled,
    handleSetWorkPref,
    handleWorkSetup,
    setJobTitleQuery,
    perPage,
    totalData,
    paginate,
    setPage,
    postsPerPage,
    currentList,
    currentPage,
    setCurrentPage,
    loadMore,
    currencyList,
    setYearsOfExp,
    setSalaryMin,
    setSalaryMax,
    setSalaryCurrency,
    isLoading,
    currency,
    AppState,
    handleSalaryMin,
    handleSalaryMax,
    salaryMin,
    salaryMax,
}) => {
    const navigate = useNavigate()
    const [isViewProfile, setIsViewProfile] = useState(false)
    const [jobseeker, setJobseeker] = useState(null)
    const [viewType, setViewType] = useState('grid')
    const [currentLists, setCurrentLists] = useState(currencyList)
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [showProfileModal, setShowProfileModal] = useState(false)

    const handleCloseProfileModal = () => setShowProfileModal(false)
    const handleShowProfileModal = () => setShowProfileModal(true)

    useEffect(() => {
        setCurrentLists(currentList[currentPage - 1])
    }, [currentList, currentPage])

    const checkbox_filters = [
        {
            title: 'Work Preference',
            key: 'employementType',
            options: [
                {
                    title: 'Full-Time',
                    value: 'FullTime',
                },
                {
                    title: 'Part-Time',
                    value: 'PartTime',
                },
                {
                    title: 'Project-Based',
                    value: 'Project Based',
                },
                {
                    title: 'Internship',
                    value: 'Internship',
                },
            ],
        },
        {
            title: 'Work Setup',
            key: 'workSetup',
            options: [
                {
                    title: 'On-Site',
                    value: 'On-Site',
                },
                {
                    title: 'Remote',
                    value: 'Remote',
                },
                {
                    title: 'Hybrid',
                    value: 'Hybrid',
                },
            ],
        },
    ]
    return (
        <>
            <ProfileModal
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                handleCloseProfileModal={handleCloseProfileModal}
                handleShowProfileModal={handleShowProfileModal}
            />
            <div className="row px-0">
                <div
                    className="col-sm-12 row justify-content-center py-2 shadow-sm p-0 mx-0"
                    style={{ background: '#fff' }}
                >
                    <div className="col-sm-12 col-md-10 row mx-0 align-items-end">
                        <div className="col-sm-12 col-md-12 d-flex justify-content-center justify-content-md-end align-self-end text-center my-2">
                            <div className="w-100 text-end">
                                <button
                                    className="position-relative btn btn-primary w-136"
                                    style={{
                                        height: 36,
                                    }}
                                    onClick={() => navigate('/create-job')}
                                >
                                    Create Job
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 row justify-content-center mx-0 px-0 ">
                    <div className="col-12 col-sm-10 row ">
                        <div className="d-none d-md-block col-sm-3 pt-3">
                            <div className="card p-3 shadow-sm">
                                <div className="mb-3">
                                    <p>Choose an Industry*</p>
                                    <SelectJobFunction
                                        value={filters?.jobFunction}
                                        onChange={(val) => {
                                            setFilters({
                                                ...filters,
                                                jobFunction: val,
                                            })
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <p>I’m looking for a/an*</p>
                                    <TextField
                                        placeholder={`E.g. "Front-End Developer"`}
                                        onChange={(e) => {
                                            setJobTitleQuery(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <p className="text-primary">
                                        <strong>Work Preference</strong>
                                    </p>
                                    {/* WORK PREFERENCE */}
                                    <div className="">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value="Full-Time"
                                                name="workPreference"
                                                id="Full-Time"
                                                onChange={(e) => {
                                                    handleSetWorkPref(e)
                                                }}
                                            />
                                            <label
                                                className="form-check-label open-sans ms-3"
                                                htmlFor="Full-Time"
                                            >
                                                Full-Time
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value="Part-Time"
                                                name="workPreference"
                                                id="Part-Time"
                                                onChange={(e) => {
                                                    handleSetWorkPref(e)
                                                }}
                                            />
                                            <label
                                                className="form-check-label open-sans ms-3"
                                                htmlFor="Part-Time"
                                            >
                                                Part-Time
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value="Project-Based"
                                                name="workPreference"
                                                id="Project-Based"
                                                onChange={(e) => {
                                                    handleSetWorkPref(e)
                                                }}
                                            />
                                            <label
                                                className="form-check-label open-sans ms-3"
                                                htmlFor="Project-Based"
                                            >
                                                Project-Based
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value="Internship"
                                                name="workPreference"
                                                id="Internship"
                                                onChange={(e) => {
                                                    handleSetWorkPref(e)
                                                }}
                                            />
                                            <label
                                                className="form-check-label open-sans ms-3"
                                                htmlFor="Internship"
                                            >
                                                Internship
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <p className="text-primary">
                                        <strong>Work Setup</strong>
                                    </p>
                                    {/* WORK SETUP */}
                                    <div className="">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value="On-Site"
                                                name="workSetup"
                                                id="On-Site"
                                                onChange={(e) => {
                                                    handleWorkSetup(e)
                                                }}
                                            />
                                            <label
                                                className="form-check-label open-sans ms-3"
                                                htmlFor="On-Site"
                                            >
                                                On-Site
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value="Remote"
                                                name="workSetup"
                                                id="Remote"
                                                onChange={(e) => {
                                                    handleWorkSetup(e)
                                                }}
                                            />
                                            <label
                                                className="form-check-label open-sans ms-3"
                                                htmlFor="Remote"
                                            >
                                                Remote
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value="Hybrid"
                                                name="workSetup"
                                                id="Hybrid"
                                                onChange={(e) => {
                                                    handleWorkSetup(e)
                                                }}
                                            />
                                            <label
                                                className="form-check-label open-sans ms-3"
                                                htmlFor="Hybrid"
                                            >
                                                Hybrid
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="text-primary">
                                                <strong>Salary Range</strong>
                                            </p>
                                        </div>
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <select
                                                name=""
                                                id=""
                                                className="form-select me-2"
                                                onChange={(e) =>
                                                    setSalaryCurrency(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option
                                                    value="PHP"
                                                    selected={
                                                        currency == 'PHP'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    PHP
                                                </option>
                                                <option
                                                    value="SGD"
                                                    selected={
                                                        currency == 'SGD'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    SGD
                                                </option>
                                                <option
                                                    value="USD"
                                                    selected={
                                                        currency == 'USD'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    USD
                                                </option>
                                            </select>
                                            <input
                                                type="text"
                                                placeholder="Min"
                                                className="form-control"
                                                onChange={handleSalaryMin}
                                                value={salaryMin}
                                            />
                                            <span className="mx-1">-</span>
                                            <input
                                                type="text"
                                                placeholder="Max"
                                                className="form-control"
                                                onChange={handleSalaryMax}
                                                value={salaryMax}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <p className="text-primary">
                                        <strong>Experience Required</strong>
                                    </p>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="yearsOfExp"
                                            id="0-1"
                                            value="0-1"
                                            onChange={(e) => {
                                                setYearsOfExp(e.target.value)
                                            }}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="0-1"
                                        >
                                            0-1 Year
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="yearsOfExp"
                                            id="1-3"
                                            value="1-3"
                                            onChange={(e) => {
                                                setYearsOfExp(e.target.value)
                                            }}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="1-3"
                                        >
                                            1-3 Years
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="yearsOfExp"
                                            id="3-5"
                                            value="3-5"
                                            onChange={(e) => {
                                                setYearsOfExp(e.target.value)
                                            }}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="3-5"
                                        >
                                            3-5 Years
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="yearsOfExp"
                                            id="5-7"
                                            value="5-7"
                                            onChange={(e) => {
                                                setYearsOfExp(e.target.value)
                                            }}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="5-7"
                                        >
                                            5-7 Years
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="yearsOfExp"
                                            id="7"
                                            value="7-99"
                                            onChange={(e) => {
                                                setYearsOfExp(e.target.value)
                                            }}
                                        />
                                        <label class="form-check-label" for="7">
                                            7+ Years
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <p className="text-primary">
                                        <strong>Country or Location</strong>
                                    </p>
                                    <SelectCountry2
                                        onChange={(value) => {
                                            setFilters({
                                                ...filters,
                                                location: value,
                                            })
                                        }}
                                        value={filters?.location}
                                        noCellCode
                                        grouped
                                    />
                                </div>
                                <div className="d-grid">
                                    <button
                                        className="position-relative btn btn-primary w-136"
                                        style={{
                                            height: 36,
                                        }}
                                        onClick={() =>
                                            handleFilter(null, false)
                                        }
                                    >
                                        Explore
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-9 pt-3">
                            <div className="card shadow-sm py-4 px-2 px-md-3">
                                <div className="d-flex w-100 justify-content-center justify-content-md-between">
                                    <div className="me-md-2 ms-0 me-0">
                                        <p>
                                            Shortlist candidates to this job
                                            post
                                        </p>
                                        <SelectJobPost
                                            onChange={(val) => {
                                                setFilters({
                                                    ...filters,
                                                    jobId: val,
                                                })
                                            }}
                                            value={filters?.jobId}
                                            className="w-100"
                                        />
                                        <center>
                                            <button
                                                className=" btn btn-primary mt-3 text-center d-block d-md-none"
                                                style={{
                                                    height: 36,
                                                    width: 168,
                                                }}
                                                onClick={() => {
                                                    handleShow()
                                                }}
                                            >
                                                Filters
                                            </button>
                                        </center>
                                    </div>
                                    <div className="d-md-flex d-none">
                                        <div>
                                            <img
                                                src={
                                                    viewType === 'grid'
                                                        ? gridActiveIcon
                                                        : gridIcon
                                                }
                                                alt=""
                                                style={{ width: 16 }}
                                                onClick={() =>
                                                    setViewType('grid')
                                                }
                                                className="hand"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <img
                                                src={
                                                    viewType === 'list'
                                                        ? listActiveIcon
                                                        : listIcon
                                                }
                                                alt=""
                                                style={{ width: 16 }}
                                                onClick={() =>
                                                    setViewType('list')
                                                }
                                                className="hand"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* VIEWS */}

                            <div className="row">
                                {currentLists?.length > 1 ? (
                                    <>
                                        {currentLists?.map((seeker, index) => {
                                            return (
                                                <>
                                                    {viewType === 'list' ? (
                                                        <div
                                                            className="col-12"
                                                            id={`card-list-${seeker.id}`}
                                                            key={`card-list-${seeker.id}`}
                                                        >
                                                            <ExploreListviewCard
                                                                seeker={seeker}
                                                                setIsViewProfile={
                                                                    setIsViewProfile
                                                                }
                                                                setJobseeker={
                                                                    setJobseeker
                                                                }
                                                                onSwipeFulfilled={
                                                                    onSwipeFulfilled
                                                                }
                                                                cardIndex={
                                                                    seeker.id
                                                                }
                                                                explore={true}
                                                                itemIndex={
                                                                    index
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <ExploreGridViewCard2
                                                            itemIndex={index}
                                                            seeker={seeker}
                                                            setIsViewProfile={
                                                                setIsViewProfile
                                                            }
                                                            setJobseeker={
                                                                setJobseeker
                                                            }
                                                            onSwipeFulfilled={
                                                                onSwipeFulfilled
                                                            }
                                                            cardID={seeker?.id}
                                                            currencyList={
                                                                currencyList
                                                            }
                                                            currency={currency}
                                                            className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 "
                                                            id={`card-grid-${seeker?.id}`}
                                                            key={`card-grid-${seeker?.id}`}
                                                        />
                                                    )}
                                                </>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        {!isLoading && (
                                            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                                <div className="mt-5 pt-5 text-center">
                                                    <img
                                                        src={emptyImg}
                                                        alt=""
                                                        className="mb-3 text-center"
                                                    />
                                                    <h4 className="text-center">
                                                        You ran out of <br />
                                                        candidates to shortlist.
                                                    </h4>
                                                    <p>
                                                        Don’t worry! We still
                                                        have more candidate for
                                                        you. <br />
                                                        Try adjusting your
                                                        filters to see more
                                                        candidates
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                {jobSeekers.length > 20 && (
                                    <div className="col-md-12 text-center">
                                        <Pagination
                                            perPage={perPage}
                                            totalData={totalData}
                                            paginate={paginate}
                                            setPage={setPage}
                                            postsPerPage={postsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            loadMore={loadMore}
                                        />
                                        {currentPage ===
                                            Math.ceil(
                                                totalData / postsPerPage
                                            ) && (
                                            <>
                                                <button
                                                    className="btn btn-primary text-center"
                                                    onClick={() => {
                                                        loadMore()
                                                    }}
                                                >
                                                    Load More
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                            {/* VIEWS */}
                        </div>
                    </div>
                </div>
            </div>
            <Filter
                handleShow={handleShow}
                handleClose={handleClose}
                show={show}
                setShow={setShow}
                checkbox_filters={checkbox_filters}
                filters={filters}
                setFilters={setFilters}
                handleFilter={handleFilter}
                handleSetWorkPref={handleSetWorkPref}
                handleWorkSetup={handleWorkSetup}
                setJobTitleQuery={setJobTitleQuery}
                setSalaryMin={setSalaryMin}
                setSalaryMax={setSalaryMax}
                setYearsOfExp={setYearsOfExp}
                setSalaryCurrency={setSalaryCurrency}
                currency={currency}
                handleSalaryMin={handleSalaryMin}
                handleSalaryMax={handleSalaryMax}
                salaryMin={salaryMin}
                salaryMax={salaryMax}
            />
            {isViewProfile && (
                <>
                    <SlidingProfile
                        jobseeker={jobseeker}
                        setIsViewProfile={setIsViewProfile}
                        setJobseeker={setJobseeker}
                    />
                </>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return { AppState: state.Generals }
}

export default connect(mapStateToProps, null)(ExploreV2)
