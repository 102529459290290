import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useMemo, useState } from "react";
import SelectSearch from "react-select-search";
import { configRequest } from "../../service/requests";
import { myFuzzySearch } from "./SelectJobPost";

function SelectJobFunction(props) {
  const { onChange, value, defaultValue, name, placeholder, noAll, error } =
    props;

  const [jobFunctions, setJobFunctions] = useState([]);
  const [displayOptions, setDisplayOptions] = useState(false);
  const showHide = (onBlur) => {
    if (onBlur) {
      setDisplayOptions(false);
    } else {
      setDisplayOptions(!displayOptions);
    }
  };

  useEffect(() => {
    configRequest.getJobFunctions().then((response) => {
      setJobFunctions(response?.data?.data || []);
    });
  }, []);
  let options = jobFunctions.map((job) => ({
    name: job?.name,
    value: job?.name,
  }));
  if (!noAll)
    options = [
      {
        name: "All Job Functions",
        value: "All Job Functions",
      },
      ...options,
    ];

  return (
    <div onClick={() => showHide()} onBlur={() => showHide(true)}>
      <SelectSearch
        printOptions={displayOptions ? "always" : "never"}
        options={options}
        search
        filterOptions={myFuzzySearch}
        emptyMessage={() => (
          <div style={{ textAlign: "center", fontSize: "0.8em" }}>
            {!!options ? "Not found" : "No job Function"}
          </div>
        )}
        placeholder={placeholder || "Select Job Function"}
        onChange={onChange}
        value={value}
        style={{
          width: 100,
        }}
        renderValue={(props, sna, className) => {
          //       console.log(props)
          return (
            <input
              type="text"
              {...props}
              className={`${className} ${
                !!error && "border error border-danger"
              }`}
            />
          );
        }}
      />
      {error && <span className="error-helper mt-0">{error}</span>}
    </div>
  );
}

const containsText = (text, searchText) =>
  text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export function MuiSelectJobFunction({
  noAll,
  onChange,
  value,
  error,
  helperText,
}) {
  const [jobFunctions, setJobFunctions] = useState([]);
  let options = jobFunctions.map((job) => ({
    name: job?.name,
    value: job?.name,
  }));
  const [selectedOption, setSelectedOption] = useState(
    value ? value : noAll ? null : "All Job Functions"
  );

  useEffect(() => {
    setSelectedOption(value ? value : noAll ? null : "All Job Functions");
  }, [value]);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    configRequest.getJobFunctions().then((response) => {
      setJobFunctions(response?.data?.data || []);
    });
  }, []);

  if (!noAll)
    options = [
      {
        name: "All Job Functions",
        value: "All Job Functions",
      },
      ...options,
    ];

  const displayedOptions = useMemo(
    () => options.filter((option) => containsText(option?.name, searchText)),
    [searchText, jobFunctions]
  );
  useEffect(() => {
    onChange && onChange(selectedOption);
  }, [selectedOption]);

  return (
    <Box component={Grid}>
      <FormControl fullWidth size="small" variant="outlined">
        <Select
          // Disables auto focus on MenuItems and allows TextField to be in focus
          MenuProps={{
            autoFocus: false,
            style: { height: 300, width: "100%" },
          }}
          labelId="search-select-label"
          id="search-select"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          onClose={() => setSearchText("")}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          renderValue={() => selectedOption}
          style={{ height: 35, fontFamily: "Open sans" }}
          variant="outlined"
          error={error}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              style={{ height: 40, marginTop: 5 }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions.map((option, i) => (
            <MenuItem
              key={i}
              value={option?.value}
              style={{ fontSize: 14, fontFamily: "Open sans" }}
            >
              {option?.name}
            </MenuItem>
          ))}
        </Select>
        {helperText && error && (
          <span
            style={{
              color: "#d32f2f",
              fontSize: ".75rem",
              fontFamily: `Roboto , Helvetica , Arial , sans-serif`,
              marginLeft: 14,
              fontWeight: 400,
            }}
          >
            {helperText}
          </span>
        )}
      </FormControl>
    </Box>
  );
}

export default SelectJobFunction;
