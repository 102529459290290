import { Box, Card, Chip } from "@material-ui/core";
import React, { useState } from "react";
import JobDetailsModal from "../../../../components/modals/JobDetailsModal";
// import DismissIcon from "../../../../assets/img/editProfile/dismissIcon.png";
// import LikeIcon from "../../../../assets/img/editProfile/likeIcon.png";
//import SuperLikeIcon from "../../../../assets/img/editProfile/superLikeIcon.png";
import workShiftIcon from "../../../../assets/icons/EmploymentTypeIcon.png";
import workHistoryIcon from "../../../../assets/icons/ExperienceIcon.png";
import locationIcon from "../../../../assets/icons/LocationIcon.png";
import salaryIcon from "../../../../assets/icons/SalaryIcon.png";
import workSetupIcon from "../../../../assets/icons/WorkSetupIcon.png";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultImg from "../../../../assets/img/defaultImg.png";
import TechnicalSkillsIcon from "../../../../assets/img/editProfile/technicalSkillsIcon.png";
import JobluTypography from "../../../../components/Typography";
import { app_styles } from "../../../../general.styles";
import { salaryRanges } from "../../../../utils/constants";
import { formatMoney, processTotalExperience } from "../../../../utils/helpers";
import { edit_profile_styles } from "../../../edit-profile/styles";
import styles from "../styles";
import JobseekerSwipeActions from "./JobseekerSwipeActions";

export const SLIDE_CLASSES = {
  SLIDE_LEFT: "slide-out-left",
  SLIDE_RIGHT: "slide-out-right",
  SLIDE_TOP: "slide-out-top",
};

const JobCard = ({ job, card_index, onSwipe, AppState, ...props }) => {
  const [view_job, setViewJob] = useState(null);
  const navigate = useNavigate();
  const edit_profile_classes = edit_profile_styles(props);
  const app_classes = app_styles(props);

  const { currencyList, currency } = AppState;
  const currencyOptions = {
    currencyList,
    currency,
  };

  return (
    <>
      <JobDetailsModal
        job={view_job}
        open={Boolean(view_job)}
        setOpen={setViewJob}
        isFromExplore
        onSwipe={onSwipe}
        card_index={card_index}
      />
      <Card
        style={styles.grid_card}
        elevation={7}
        onClick={(e) => {
          e.stopPropagation();
          setViewJob(job);
        }}
      >
        <Box
          style={{ padding: "20px" }}
          className={`${app_classes.cursor.pointer}`}
        >
          <Box>
            <Box style={{ display: "flex" }}>
              <img
                src={
                  job?.companyLogo
                    ? job?.companyLogo
                    : job?.employer?.profileUrl
                    ? job?.employer?.profileUrl
                    : DefaultImg
                }
                alt=""
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  border: "1px solid #C9CCCE",
                  padding: 5,
                  objectFit: "cover",
                  background: "#FFFFFF",
                }}
                onClick={(e) => {
                  {
                    if (!job?.companyLogo) {
                      e.stopPropagation();
                      console.log(job?.employer?.userName);
                      window.open(
                        `/company-showcase/${
                          job?.employer?.userName || job?.employer?.userId
                        }`
                      );
                      // navigate(`/company-showcase/${job?.employer?.userId}`)
                    }
                  }
                }}
              />
            </Box>

            <JobluTypography.H6 primary semiBold style={{ marginTop: "20px" }}>
              {job?.jobTitle}
            </JobluTypography.H6>
            <JobluTypography.Body>
              {job?.companyName || job?.employer?.companyName}
            </JobluTypography.Body>
          </Box>
          <Box
            style={{ marginTop: 5 }}
            className={app_classes.display.display_flex}
          >
            {/* location */}
            {job?.location ? (
              <img
                src={locationIcon}
                alt=""
                style={{ width: "20px", height: "20px", marginRight: 5 }}
                className={app_classes.general.color_secondary}
              />
            ) : null}

            <JobluTypography.Body>{job?.location}</JobluTypography.Body>
          </Box>
          {/* work setup */}
          {job?.workSetup?.length > 0 && job?.workSetup != "TBD" && (
            <>
              <Box className={app_classes.display.display_flex}>
                <img
                  src={workSetupIcon}
                  alt=""
                  style={{ width: "20px", height: "20px", marginRight: 5 }}
                  className={app_classes.general.color_secondary}
                />
                <JobluTypography.Body>
                  {job?.workSetup?.join(", ") || "N/A"}
                </JobluTypography.Body>
              </Box>
            </>
          )}

          {job?.employmentType?.length > 0 && job?.employmentType != "TBD" && (
            <>
              {/* work shift  */}
              <Box className={app_classes.display.display_flex}>
                <img
                  src={workShiftIcon}
                  alt=""
                  style={{ width: "20px", height: "20px", marginRight: 5 }}
                  className={app_classes.general.color_secondary}
                />
                <JobluTypography.Body>
                  {job?.employmentType?.join(", ") || "N/A"}
                </JobluTypography.Body>
              </Box>
            </>
          )}

          {/* work history  */}
          {processTotalExperience(
            job?.totalExperienceMin,
            job?.totalExperienceMax
          ) && (
            <Box className={app_classes.display.display_flex}>
              <img
                src={workHistoryIcon}
                alt=""
                style={{ width: "20px", height: "20px", marginRight: 5 }}
                className={app_classes.general.color_secondary}
              />
              <JobluTypography.Body>
                {processTotalExperience(
                  job?.totalExperienceMin,
                  job?.totalExperienceMax
                )}
              </JobluTypography.Body>
            </Box>
          )}
          {job?.salaryMin && job?.salaryMax && (
            <>
              <Box className={app_classes.display.display_flex}>
                <img
                  src={salaryIcon}
                  alt=""
                  style={{ width: "20px", height: "20px", marginRight: 5 }}
                  className={app_classes.general.color_secondary}
                />
                <JobluTypography.Body>
                  {job?.salaryMin === 1 && job?.salaryMax === 1
                    ? salaryRanges.COMPETIIVE_SALARY
                    : job?.salaryMin === 2 && job?.salaryMax === 2
                    ? salaryRanges.INDUSTRY_STANDARD
                    : `${formatMoney(
                        job?.salaryMin,
                        currencyOptions,
                        job?.salaryCurrency || "SGD"
                      )} - ${formatMoney(
                        job?.salaryMax,
                        currencyOptions,
                        job?.salaryCurrency || "SGD"
                      )}`}
                </JobluTypography.Body>
              </Box>{" "}
            </>
          )}
          {job?.technicalSkills?.length > 0 && (
            <>
              <Box>
                <Box
                  className={`${app_classes.margin.b1} ${app_classes.margin.t1}`}
                >
                  <JobluTypography.Body semiBold primary>
                    <img src={TechnicalSkillsIcon} width={16} /> Technical
                    Skills
                  </JobluTypography.Body>
                </Box>
                <Box>
                  {job?.technicalSkills?.map((item) => {
                    return (
                      <Chip
                        label={item}
                        className={`${edit_profile_classes.chip_active}`}
                      />
                    );
                  })}
                </Box>
              </Box>
            </>
          )}
          <Box>
            <Box className={`${app_classes.margin.t1}`}>
              <JobluTypography.Body semiBold primary>
                Responsibilities
              </JobluTypography.Body>
            </Box>
            <Box>
              <JobluTypography.Caption>
                {job?.responsibilities}
              </JobluTypography.Caption>
            </Box>
          </Box>
        </Box>

        <Box
          style={{
            bottom: 75,
            padding: "15px",
            position: "absolute",
            width: "100%",
            textAlign: "center",
            // border: "1px solid red",
            background:
              "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 54%, rgba(255,255,255,0.25253851540616246) 100%)",
          }}
        >
          <JobluTypography.Body semiBold style={{ fontWeight: 600 }}>
            <span
              onClick={() => {
                setViewJob(job);
              }}
              role="button"
            >
              See more
            </span>
          </JobluTypography.Body>
        </Box>
        <Box
          style={{
            bottom: 0,
            background: "#fff",
            borderTop: "1px solid #f2f2f2",
            padding: "10px",
            position: "absolute",
            width: "100%",
          }}
        >
          <JobseekerSwipeActions
            job={job}
            onSwipe={onSwipe}
            card_index={card_index}
          />
        </Box>
      </Card>
    </>
  );
};

const JobCardGrid = React.memo(JobCard);

const mapStateToProps = (state) => {
  return {
    AppState: state.Generals,
  };
};
export default connect(mapStateToProps, null)(JobCardGrid);
