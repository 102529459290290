import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import editIcon from '../../../assets/img/editIcon.svg'
import employmentTypeIcon from '../../../assets/img/employmentTypeIcon.svg'
import foodAllowanceIcon from '../../../assets/img/foodAllowanceIcon.svg'
import housingIcon from '../../../assets/img/housingIcon.svg'
import { default as JobIcon } from '../../../assets/img/jobIcon.svg'
import locationIcon from '../../../assets/img/locationIcon.svg'
import startDateIcon from '../../../assets/img/startDateIcon.svg'
import transportAllowanceIcon from '../../../assets/img/transportAllowanceIcon.svg'
import workSetupIcon from '../../../assets/img/workSetupIcon.svg'
import workShiftIcon, {
    default as skillsIcon,
} from '../../../assets/img/workShiftIcon.svg'
import CustomAlert from '../../../components/reusables/Alert/Alert'
import CustomChip from '../../../components/reusables/Chip'
import CreateJobButton from '../../../components/reusables/CreateJobButton'
import { jobsRequest } from '../../../service/requests'
import {
    profilePlaceholder_Employer,
    salaryRanges,
} from '../../../utils/constants'
import { formatMoney, titleCase } from '../../../utils/helpers'
import '../styles.css'
import { getContent, JobModal } from './DataTable'

function MyJobsinfo(props) {
    const { User, AppState } = props
    const location = useLocation()
    const [selectedJob, setSelectJob] = useState({})
    const navigate = useNavigate()
    useEffect(() => {
        if (location.state?.job) {
            setSelectJob(location.state.job)
        }
    }, [location])

    useEffect(() => {
        console.log(selectedJob)
        console.log(User)
    }, [selectedJob, User])

    const [showModal, setShowModal] = useState(false)
    const [showAlert, setShowAlert] = useState(null)

    const handleProceedModal = () => {
        try {
            if (!!showModal) {
                let payload = showModal?.data
                let status = showModal?.status
                delete payload.updatedAt
                console.log(status)
                console.log(getContent(showModal)?.alert)
                if (status === 'repost') {
                    delete payload.createdAt
                    delete payload.status
                    jobsRequest
                        .createJob(payload)
                        .then((res) => {
                            setShowAlert(`${getContent(showModal)?.alert}`)
                            setTimeout(() => {
                                setShowModal(null)
                            }, 1000)
                            setTimeout(() => {
                                setShowAlert(null)
                                navigate(`/jobs/${res?.data?.id}`, {
                                    state: {
                                        job: res.data,
                                    },
                                })
                            }, 3000)
                        })
                        .catch((err) => {
                            console.log('ERROR when reposting job', err)
                        })
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const { currencyList, currency } = AppState
    const currencyOptions = {
        currencyList,
        currency,
    }

    return (
        <div className="row d-flex justify-content-center">
            <CustomAlert
                show={!!showAlert}
                body={showAlert}
                position="bottom-end"
            />
            <div className=" col-12 row shadow-sm d-flex justify-content-center px-0 py-3">
                <div className="col-12 col-sm-9 row ">
                    <div className="row p-0 m-0 p-0">
                        <div className="col-12 col-sm-6 d-flex flex-column">
                            <nav
                                aria-label="breadcrumb"
                                className="breadcrumb-con p-0"
                            >
                                <ol className="breadcrumb m-0 d-flex p-auto ">
                                    <li className="breadcrumb-item my-auto">
                                        <Link
                                            to="/jobs"
                                            className=" py-auto text-decoration-none text-secondary breadcrumb-text"
                                        >
                                            My Jobs
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item my-auto">
                                        <Link
                                            to="/jobs"
                                            className="text-decoration-none text-dark   breadcrumb-text breadcrumb-text"
                                        >
                                            View
                                        </Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-12 col-sm-6 d-flex justify-content-end p-0">
                            <CreateJobButton />
                        </div>
                    </div>
                </div>
            </div>
            <div className=" col-12 col-sm-9 row mt-5">
                <div className="col-md-8">
                    <div className="card mb-3">
                        <div className="card-body row">
                            <div className="col-md-12 d-flex flex-column flex-md-row align-items-center w-100">
                                <div
                                    // style="text-align: center"
                                    className="me-2 text-center"
                                >
                                    {User?.user?.email ===
                                    'connect@joblu.io' ? (
                                        <img
                                            src={
                                                selectedJob?.companyLogo ||
                                                profilePlaceholder_Employer
                                            }
                                            alt=""
                                            style={{ width: 90, height: 90 }}
                                        />
                                    ) : (
                                        <img
                                            src={
                                                User?.user?.profileUrl ||
                                                profilePlaceholder_Employer
                                            }
                                            alt=""
                                            style={{ width: 90, height: 90 }}
                                        />
                                    )}
                                </div>
                                <div className="ml-1 w-100">
                                    <div className="float-none float-md-end text-center text-md-end">
                                        {selectedJob?.salaryMin && (
                                            <h5 className="fs20 fw600">
                                                {selectedJob?.salaryMin &&
                                                    selectedJob?.salaryMin !==
                                                        null && (
                                                        <>
                                                            {selectedJob?.salaryMin ===
                                                                1 &&
                                                            selectedJob?.salaryMax ===
                                                                1
                                                                ? salaryRanges.COMPETIIVE_SALARY
                                                                : selectedJob?.salaryMin ===
                                                                      2 &&
                                                                  selectedJob?.salaryMax ===
                                                                      2
                                                                ? salaryRanges.INDUSTRY_STANDARD
                                                                : `${formatMoney(
                                                                      selectedJob?.salaryMin,
                                                                      currencyOptions,
                                                                      selectedJob?.salaryCurrency ||
                                                                          'SGD'
                                                                  )} - ${formatMoney(
                                                                      selectedJob?.salaryMax,
                                                                      currencyOptions,
                                                                      selectedJob?.salaryCurrency ||
                                                                          'SGD'
                                                                  )}`}
                                                        </>
                                                    )}
                                                {/* {formatMoney(
                                                    selectedJob?.salaryMin
                                                )}{' '}
                                                -{' '}
                                                {formatMoney(
                                                    selectedJob.salaryMax
                                                )} */}
                                                {/* OLD */}
                                                {/* {selectedJob.salaryMin === 1 &&
                                                selectedJob?.salaryMax === 1
                                                    ? salaryRanges.COMPETIIVE_SALARY
                                                    : selectedJob.salaryMin ===
                                                          2 &&
                                                      selectedJob?.salaryMax ===
                                                          2
                                                    ? salaryRanges.INDUSTRY_STANDARD
                                                    : selectedJob.salaryMin ===
                                                      1
                                                    ? `Less than ${formatMoney(
                                                          selectedJob?.salaryMax
                                                      )}`
                                                    : selectedJob.salaryMin <
                                                      3000
                                                    ? `${formatMoney(
                                                          selectedJob.salaryMin
                                                      )} - ${formatMoney(
                                                          selectedJob.salaryMax
                                                      )}`
                                                    : `More than ${formatMoney(
                                                          selectedJob.salaryMin
                                                      )}`} */}
                                            </h5>
                                        )}
                                        <p className="text-grey-50 text-center text-md-end">
                                            Salary Range
                                        </p>
                                    </div>
                                    <h4 className="text-primary text-center text-md-start mb-0 fw500">
                                        {titleCase(selectedJob?.jobTitle)}
                                    </h4>
                                    <p className="text-primary text-center text-md-start mb-0 fw600">
                                        <strong>
                                            {titleCase(
                                                selectedJob?.jobFunction
                                            )}
                                        </strong>
                                    </p>

                                    {selectedJob.companyName && (
                                        <p className="text-grey-50 text-center text-md-start">
                                            Company: {selectedJob?.companyName}
                                        </p>
                                    )}

                                    <p className="text-grey-50 text-center text-md-start mb-3">
                                        JOB ID:{' '}
                                        {selectedJob?.referenceId?.toUpperCase()}
                                    </p>
                                    <div className="d-flex flex-column flex-md-row">
                                        <div className="d-flex align-items-center mb-2 mb-md-0 justify-content-center justify-content-md-start">
                                            <div>
                                                <img
                                                    src={locationIcon}
                                                    style={{
                                                        height: 24,
                                                    }}
                                                    className="h-24 me-2"
                                                    alt=""
                                                />
                                            </div>
                                            <div>
                                                <p className="fs14 fw400">
                                                    <p>
                                                        {selectedJob?.location ||
                                                            User?.user?.country}
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3 d-block d-md-none">
                        <div className="card-body">
                            <div className="d-flex justify-content-around">
                                {selectedJob.status === 'closed' && (
                                    <div>
                                        <div className="text-center">
                                            <a
                                                href="#repost"
                                                className="view-anchor text-decoration-none"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    class="bi bi-view-list"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z" />
                                                </svg>
                                                <p className="small">
                                                    Repost this job
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {selectedJob.status === 'active' && (
                                    <div>
                                        <div className="text-center">
                                            <a
                                                href=""
                                                className="view-anchor text-decoration-none"
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard?job=${selectedJob?.id}`
                                                    )
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    fill="currentColor"
                                                    className="bi bi-view-list"
                                                    viewBox="0 0 16 16"
                                                    role="link"
                                                >
                                                    <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z" />
                                                </svg>
                                                <p className="small">
                                                    Explore candidates
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {selectedJob.status !== 'archived' && (
                                    <div>
                                        <div className="text-center">
                                            <a
                                                href=""
                                                className="view-anchor text-decoration-none"
                                                onClick={() => {
                                                    navigate(
                                                        `/matches?job=${selectedJob?.id}`
                                                    )
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    fill="currentColor"
                                                    className="bi bi-people mx-auto"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                                </svg>
                                                <p className="small text-nowrap">
                                                    View applicants
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="mt-3">
                                <button
                                    className="btn btn-primary w-100 d-flex justify-content-center"
                                    onClick={() => {
                                        navigate(`/jobs/edit`, {
                                            state: {
                                                job: selectedJob,
                                            },
                                        })
                                    }}
                                >
                                    <img
                                        src={editIcon}
                                        alt=""
                                        style={{ width: 20, height: 20 }}
                                        className="my-auto"
                                    />
                                    <span className="m-0 mx-1 my-auto">
                                        Edit
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="mb-3">
                                <h6 className="fs18">Job Description</h6>
                            </div>
                            <div className="mb-3">
                                <p className="fwb">Responsibilities</p>
                                <p style={{ whiteSpace: 'pre-line' }}>
                                    {selectedJob?.responsibilities}
                                </p>
                            </div>
                            <div className="mb-3">
                                <p className="fwb">
                                    Requirements and Educational Qualifications
                                </p>
                                <p
                                    style={{ whiteSpace: 'pre-line' }}
                                    className="text-capitalize"
                                >
                                    {selectedJob?.education?.join(', ')}
                                </p>
                            </div>
                            <div className="mb-3">
                                <p className="fwb mb-3">What We Offer</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex mb-3 align-items-center">
                                            <div>
                                                <img
                                                    src={employmentTypeIcon}
                                                    className="h-24 me-3"
                                                    alt=""
                                                />
                                            </div>
                                            <div>
                                                <p className="fwn fs14">
                                                    Employment Type
                                                </p>
                                                <p className="text-capitalize">
                                                    {selectedJob?.employmentType?.join(
                                                        ', '
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex mb-3 align-items-center">
                                            <div>
                                                <img
                                                    src={workSetupIcon}
                                                    className="h-24 me-3"
                                                    alt=""
                                                />
                                            </div>
                                            <div>
                                                <p className="fwn fs14">
                                                    Work Setup
                                                </p>
                                                <p className="text-capitalize">
                                                    {selectedJob?.workSetup &&
                                                        selectedJob?.workSetup?.join(
                                                            ', '
                                                        )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedJob?.workShift && (
                                        <div className="col-md-6">
                                            <div className="d-flex mb-3 align-items-center">
                                                <div>
                                                    <img
                                                        src={workShiftIcon}
                                                        className="h-24 me-3"
                                                        alt=""
                                                    />
                                                </div>
                                                <div>
                                                    <p className="fwn fs14">
                                                        Work Shift
                                                    </p>
                                                    <p>
                                                        {selectedJob?.workShift ||
                                                            'None'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {selectedJob?.workWeek && (
                                        <div className="col-md-6">
                                            <div className="d-flex mb-3 align-items-center">
                                                <div>
                                                    <img
                                                        src={workShiftIcon}
                                                        className="h-24 me-3"
                                                        alt=""
                                                    />
                                                </div>
                                                <div>
                                                    <p className="fwn fs14">
                                                        Work Week
                                                    </p>

                                                    <p>
                                                        {selectedJob?.workWeek ||
                                                            'None'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {selectedJob?.expectedStartDate && (
                                        <div className="col-md-6">
                                            <div className="d-flex mb-3 align-items-center">
                                                <div>
                                                    <img
                                                        src={startDateIcon}
                                                        className="h-24 me-3"
                                                        alt=""
                                                    />
                                                </div>
                                                <div>
                                                    <p className="fwn fs14">
                                                        Expected Start Date
                                                    </p>
                                                    <p>
                                                        {selectedJob?.expectedStartDate
                                                            ? moment(
                                                                  selectedJob?.expectedStartDate
                                                              ).format(
                                                                  'yyyy-MM-DD'
                                                              )
                                                            : 'None'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3">
                                <p className="fwb mb-3">Other Benefits</p>
                                <CustomChip
                                    data={selectedJob?.perksAndBenefits}
                                />
                                {/* <p className='text-capitalize'>{selectedJob?.perksAndBenefits?.join(', ')}</p> */}
                            </div>
                        </div>
                    </div>
                    {selectedJob?.food ||
                        selectedJob?.housing ||
                        (selectedJob?.transport && (
                            <div className="card mb-3">
                                <div className="card-body">
                                    <p className="fw600 mb-3">
                                        Additional Information
                                    </p>
                                    <div className="row">
                                        {selectedJob?.food && (
                                            <div className="col-md-3">
                                                <div className="d-flex mb-3 align-items-center">
                                                    <div>
                                                        <img
                                                            src={
                                                                foodAllowanceIcon
                                                            }
                                                            alt=" "
                                                            style={{
                                                                height: 24,
                                                            }}
                                                            className="my-auto me-3"
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="fwn fs14">
                                                            Food Allowance
                                                        </p>

                                                        <p className=" ">
                                                            {' '}
                                                            {selectedJob?.food
                                                                ? 'Yes'
                                                                : 'No'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selectedJob?.transport && (
                                            <div className="col-md-3">
                                                <div className="d-flex mb-3 align-items-center">
                                                    <div>
                                                        <img
                                                            src={
                                                                transportAllowanceIcon
                                                            }
                                                            alt=" "
                                                            style={{
                                                                height: 24,
                                                            }}
                                                            className="my-auto me-3"
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="fwn fs14">
                                                            Transport
                                                        </p>
                                                        <p className="">
                                                            {selectedJob?.transport
                                                                ? 'Yes'
                                                                : 'No'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selectedJob?.housing && (
                                            <div className="col-md-3">
                                                <div className="d-flex mb-3 align-items-center">
                                                    <div>
                                                        <img
                                                            src={housingIcon}
                                                            alt=" "
                                                            style={{
                                                                height: 24,
                                                            }}
                                                            className="my-auto me-3"
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="fwn fs14">
                                                            Housing
                                                        </p>
                                                        <p className="">
                                                            {selectedJob?.housing
                                                                ? 'Yes'
                                                                : 'No'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="col-md-4">
                    <div className="card mb-3 d-none d-md-block">
                        <div className="card-body">
                            <div className=" d-flex justify-content-around">
                                {selectedJob.status === 'closed' && (
                                    <div>
                                        <div className="text-center">
                                            <a
                                                href="#repost"
                                                className="view-anchor text-decoration-none"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setShowModal({
                                                        data: selectedJob,
                                                        status: 'repost',
                                                    })
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    class="bi bi-view-list"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z" />
                                                </svg>
                                                <p
                                                    className="small"
                                                    style={{ marginTop: 1 }}
                                                >
                                                    Repost this job
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {selectedJob.status === 'active' && (
                                    <div>
                                        <div className="text-center">
                                            <a
                                                href=""
                                                className="view-anchor text-decoration-none"
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard?job=${selectedJob?.id}`
                                                    )
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    fill="currentColor"
                                                    className="bi bi-view-list"
                                                    viewBox="0 0 16 16"
                                                    role="link"
                                                >
                                                    <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z" />
                                                </svg>
                                                <p className="small">
                                                    Explore candidates
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {selectedJob.status !== 'archived' && (
                                    <div>
                                        <div className="text-center">
                                            <a
                                                href=""
                                                className="view-anchor text-decoration-none"
                                                onClick={() => {
                                                    navigate(
                                                        `/matches?job=${selectedJob?.id}`
                                                    )
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    fill="currentColor"
                                                    className="bi bi-people mx-auto"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                                </svg>
                                                <p className="small text-nowrap">
                                                    View applicants
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="mt-3">
                                <button
                                    className="btn btn-primary w-100 d-flex justify-content-center"
                                    onClick={() => {
                                        navigate(`/jobs/edit`, {
                                            state: {
                                                job: selectedJob,
                                            },
                                        })
                                    }}
                                >
                                    <img
                                        src={editIcon}
                                        alt=""
                                        style={{ width: 20, height: 20 }}
                                        className="my-auto"
                                    />
                                    <span className="m-0 mx-1 my-auto">
                                        Edit
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className="fs18 mb-3">Preferences</h6>
                                </div>
                                <div className="col-md-12">
                                    <div className="d-flex mb-3 align-items-center">
                                        <div>
                                            <img
                                                style={{
                                                    height: 24,
                                                }}
                                                alt=""
                                                src={JobIcon}
                                                className="my-auto me-3"
                                            />
                                        </div>
                                        <div>
                                            <p className="fwn fs14">
                                                Experience
                                            </p>
                                            <p>
                                                {selectedJob?.totalExperienceMin ===
                                                    0 &&
                                                selectedJob?.totalExperienceMax ===
                                                    1 ? (
                                                    selectedJob?.totalExperienceMin +
                                                    ' - ' +
                                                    selectedJob?.totalExperienceMax +
                                                    ' Year of Experience'
                                                ) : (
                                                    <>
                                                        {
                                                            selectedJob?.totalExperienceMin
                                                        }{' '}
                                                        -{' '}
                                                        {
                                                            selectedJob?.totalExperienceMax
                                                        }{' '}
                                                        Years of Experience
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="d-flex">
                                        <div>
                                            <img
                                                alt=""
                                                src={skillsIcon}
                                                style={{ height: 24 }}
                                                className="me-3"
                                            />
                                        </div>
                                        <div>
                                            <p className="fs14">
                                                Skills and Expertise
                                            </p>
                                            <CustomChip
                                                data={
                                                    selectedJob?.technicalSkills
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!!showModal && (
                <JobModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    handleProceedModal={handleProceedModal}
                />
            )}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
    }
}
export default connect(mapStateToProps, null)(MyJobsinfo)
