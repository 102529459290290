import { makeStyles } from "@material-ui/core/styles";

export const modal_styles = makeStyles((theme) => ({
  chip: {
    backgroundColor: "#009CDE!important",
    color: "#FFFFFF!important",
    marginRight: "0.5rem!important",
    fontSize: 12,
    marginBottom: "0.5rem!important",
    fontFamily: "Open Sans!important",
  },
  chip_main: {
    backgroundColor: "#009CDE!important",
    color: "#FFFFFF!important",
    fontSize: 12,
    marginBottom: "0.5rem!important",
    fontFamily: "Open Sans!important",
  },
  color_warning: { color: "#D7A048!important" },
  action_button: {
    height: 56,
    width: 56,
  },
  icons: {
    width: 33,
  },
}));
