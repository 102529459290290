import { Card, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const ListLoader = ({ count }) => {
  return Array.from({ length: count || 12 }).map((_c, index) => {
    return (
      <Grid
        item
        xs={12}
        key={`${_c}-${index}`}
        style={
          {
            // minWidth: "260px",
          }
        }
      >
        <Card
          elevation={7}
          style={{
            padding: 20,
            borderRadius: 8,
            minHeight: 170,
          }}
        >
          <Grid container>
            <Grid item xs={1}>
              <Skeleton variant="circle" height={80} width={80} />
            </Grid>
            <Grid item xs={9}>
              <Skeleton height={50} width={150} />
              <Skeleton height={40} />
              <Skeleton height={20} />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Skeleton
                variant="circle"
                width={50}
                height={50}
                style={{ marginRight: 10 }}
              />
              <Skeleton variant="circle" width={50} height={50} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  });
};

export default ListLoader;
