import { Box, Card, CircularProgress, MenuItem } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { MUICustomTextField } from "../../inputs/CustomTextField";
const CustomGooglePlaces = ({
  onChange,
  alwaysGetFirst,
  value,
  error,
  helperText,
  onTextChange,
  inputComponent,
  onClear
}) => {
  const handlePlaceSelect = (data, closeAfter) => {
    if (data) {
      const { description, terms } = data;

      const country = terms?.reduce((prev, current) =>
        prev.offset > current.offset ? prev : current
      )?.value;
      let city, region;

      if (terms?.length > 2) {
        city = terms[terms.length - 3]?.value || "";
        region = terms[terms.length - 2]?.value || "";
      } else if (terms?.length === 2) {
        region = terms[terms.length - 2]?.value || "";
      }

      const place = {
        data,
        city,
        region,
        location: description,
        countryValue: country,
      };

      onChange && onChange(place, val);
      setVal(place.location);
      if (closeAfter) setOptions([]);
    }
  };
  const placeoptions = { types: ['locality', 'country'] }
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_PLACE_KEY,
    options: onClear ? placeoptions : undefined
  });

  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(placePredictions);
    //  etch place details for the first element in placePredictions array
    if (alwaysGetFirst && placePredictions.length) {
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails) => handlePlaceSelect(placeDetails)
      );
    }
  }, [isPlacePredictionsLoading]);

  const renderItem = (item) => {
    return (
      <MenuItem
        onClick={() => {
          handlePlaceSelect(item, true);
        }}
      >
        {item?.description}
      </MenuItem>
    );
  };

  const inpRef = useRef();
  const [val, setVal] = useState(value);

  useEffect(() => {
   console.log('cleared')
    console.log("value has updated", value);
    setVal(value);
    if(value === '' || value === null || value === 'All Countries'){
      onClear && onClear()
      setVal('')
    }
  }, [value]);

  const CustomInputComponent = inputComponent
    ? inputComponent
    : MUICustomTextField;

  return (
    <Box style={{ position: "relative" }}>
      <CustomInputComponent
        value={val}
        size="small"
        placeholder="Enter location"
        fullWidth
        onChange={(evt) => {
          onTextChange && onTextChange(evt);
          setVal(evt.target.value);
          getPlacePredictions({ input: evt.target.value });
          if(evt?.target?.value === '' || evt?.target?.value === null){
            onClear && onClear()
          }
        }}
        loading={isPlacePredictionsLoading}
        ref={inpRef}
        error={!!error}
        variant="outlined"
        // helperText={error}
      />
      {error && (
        <span
          className="mt-0"
          style={{
            fontFamily: "Open sans",
            color: "#f44336",
            marginLeft: "14px",
            fontSize: '0.75rem'
          }}
        >
          {helperText}
        </span>
      )}

      <Card
        elevation={3}
        style={{ position: "absolute", zIndex: 1000, width: "100%" }}
      >
        {isPlacePredictionsLoading && (
          <Box
            style={{ display: "flex", justifyContent: "center", padding: 10 }}
          >
            <CircularProgress size={18} />
          </Box>
        )}
        {options?.length > 0 && (
          <>
            {options?.map((item) => renderItem(item))}
            <Box
              style={{ display: "flex", justifyContent: "end", padding: 10 }}
            >
              <img
                src={
                  "https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/pwrdby_google.png"
                }
                alt=""
                style={{ height: 20 }}
              />
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};

export default CustomGooglePlaces;
