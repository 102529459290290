import { Box } from "@material-ui/core";
import DefaultImg from "../../assets/img/defaultImg.png";
import { app_styles } from "../../general.styles";
import { fromNow } from "../../utils/helpers";
import JobluTypography from "../Typography";
import { notification_styles } from "./style";

const NotificationItem = (props) => {
  const { action, data, small } = props;
  const app_classes = app_styles(props);
  const notification_classes = notification_styles(props);

  //todo: @ced add unread style using the isRead value

  return (
    <Box
      onClick={action || console.log("im pressed")}
      className={`${app_classes.cursor.pointer} ${
        app_classes.border.border_bottom
      } ${app_classes.padding.x2} ${app_classes.border.border_top} ${
        app_classes.padding.a1
      } ${!small && app_classes.margin.b2} ${!small && app_classes.margin.t2} ${
        app_classes.border.border_all
      } ${!data?.isRead && notification_classes.isRead} ${
        notification_classes.notif_item
      }`}
    >
      <Box
        className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
      >
        <Box>
          <img
            src={data?.data?.profileUrl || DefaultImg}
            className={`${notification_classes.default_img} ${app_classes.margin.r2}`}
          />
        </Box>
        <Box>
          <Box>
            <JobluTypography.Body semiBold>
              {data?.content}
            </JobluTypography.Body>
          </Box>
          <Box>
            <JobluTypography.Caption>
              {data?.createdAt ? fromNow(data?.createdAt) : null}
            </JobluTypography.Caption>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationItem;
