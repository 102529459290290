import { Box, Card, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import educationIcon from "../../../../assets/icons/EducationIcon.png";
import workShiftIcon from "../../../../assets/icons/EmploymentTypeIcon.png";
import workHistoryIcon from "../../../../assets/icons/ExperienceIcon.png";
import locationIcon from "../../../../assets/icons/LocationIcon.png";
import salaryIcon from "../../../../assets/icons/SalaryIcon.png";
import workSetupIcon from "../../../../assets/icons/WorkSetupIcon.png";
//interviews icon imports
import TechnicalSkillsIcon from "../../../../assets/img/editProfile/technicalSkillsIcon.png";
//end-interviews
import { useState } from "react";

import { Chip } from "@material-ui/core";
import DefaultImg from "../../../../assets/img/defaultImg.png";
import { card_styles } from "../../../../components/card/job-card/styles";
import JobDetailsModal from "../../../../components/modals/JobDetailsModal";
import JobluTypography from "../../../../components/Typography";
import { app_styles } from "../../../../general.styles";
import { salaryRanges } from "../../../../utils/constants";
import { formatMoney, processTotalExperience } from "../../../../utils/helpers";
import { edit_profile_styles } from "../../../edit-profile/styles";
import JobseekerSwipeActions from "./JobseekerSwipeActions";

const ListCard = ({ job, onSwipe, card_index, AppState, ...props }) => {
  const edit_profile_classes = edit_profile_styles(props);
  const styles = card_styles(props);
  const app_classes = app_styles(props);
  const [view_job, setViewJob] = useState(null);

  const { currencyList, currency } = AppState;
  const currencyOptions = {
    currencyList,
    currency,
  };

  return (
    <Card
      style={{
        position: "relative",
        backgroundColor: "#FFFFFF",
        border: "1px solid #C9CCCE",
        borderRadius: "5px",
        height: 250,
        // minHeight: 200px;
      }}
      elevation={5}
    >
      <Grid
        // component={Card}
        container
        //className={styles.card_container}
        spacing={1}
        id={`card-class-${card_index}`}
        style={{
          width: "100%",
          padding: 20,
          cursor: 'pointer'
        }}
        onClick={() => {
          if (job?.matchJob) {
            setViewJob({
              ...job?.matchJob,
              employer: job?.matchEmployer,
            });
          } else {
            setViewJob(job);
          }
        }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <Box sx={{ display: "flex" }}>
            <img
              src={
                job?.companyLogo
                  ? job?.companyLogo
                  : job?.employer?.profileUrl
                  ? job?.employer?.profileUrl
                  : DefaultImg
              }
              className={`${styles.job_image} ${app_classes.general.centered_y} ${app_classes.margin.r2}`}
              style={{ objectFit: "cover",
              background: '#FFFFFF' }}
              alt=""
              onClick={(e) => {
                {
                  if(!job?.companyLogo) {
                    e.stopPropagation()
                    window.open(`/company-showcase/${job?.employer?.userName || job?.employer?.userId}`)
                    // navigate(`/company-showcase/${job?.employer?.userId}`)
                  }
                
                }
              }}
            />
            <Box>
              <JobluTypography.H5 semiBold primary>
                {job?.jobTitle}
              </JobluTypography.H5>
              <Box className={`${app_classes.margin.b1}`}>
                <JobluTypography.Body>
                  {job?.companyName || job?.employer?.companyName}
                </JobluTypography.Body>
              </Box>
              {job?.location && (
                <>
                  <Box className={`${app_classes.display.display_flex} `}>
                    {/* location */}
                    <img
                      src={locationIcon}
                      alt=""
                      style={{ width: "20px", height: "20px", marginRight: 5 }}
                      className={app_classes.general.color_secondary}
                    />
                    <JobluTypography.Body>{job?.location}</JobluTypography.Body>
                  </Box>
                </>
              )}
              {/* work setup */}
              {job?.workSetup?.length > 0 && job?.workSetup != "TBD" && (
                <>
                  <Box className={app_classes.display.display_flex}>
                    <img
                      src={workSetupIcon}
                      alt=""
                      style={{ width: "20px", height: "20px", marginRight: 5 }}
                      className={app_classes.general.color_secondary}
                    />
                    <JobluTypography.Body>
                      {job?.workSetup?.join(", ") || "N/A"}
                    </JobluTypography.Body>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          {/* work shift  */}
          {job?.employmentType?.length > 0 && job?.employmentType != "TBD" && (
            <>
              <Box className={app_classes.display.display_flex}>
                <img
                  src={workShiftIcon}
                  alt=""
                  style={{ width: "20px", height: "20px", marginRight: 5 }}
                  className={app_classes.general.color_secondary}
                />
                <JobluTypography.Body>
                  {job?.employmentType?.join(", ") || "N/A"}
                </JobluTypography.Body>
              </Box>
            </>
          )}

          {/* education */}
          {job?.educationAndExperience?.length > 0 &&
            job?.educationAndExperience != "TBD" && (
              <>
                <Box className={app_classes.display.display_flex}>
                  <img
                    src={educationIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", marginRight: 5 }}
                    className={app_classes.general.color_secondary}
                  />
                  <JobluTypography.Body>
                    {job?.educationAndExperience || "N/A"}
                  </JobluTypography.Body>
                </Box>
              </>
            )}

          {/* work history  */}
          {processTotalExperience(
            job?.totalExperienceMin,
            job?.totalExperienceMax
          ) && (
            <>
              <Box className={app_classes.display.display_flex}>
                <img
                  src={workHistoryIcon}
                  alt=""
                  style={{ width: "20px", height: "20px", marginRight: 5 }}
                  className={app_classes.general.color_secondary}
                />
                <JobluTypography.Body>
                  {processTotalExperience(
                    job?.totalExperienceMin,
                    job?.totalExperienceMax
                  )}
                </JobluTypography.Body>
              </Box>
            </>
          )}
          {job?.salaryMin && job?.salaryMax && (
            <>
              {/* salary */}
              <Box className={app_classes.display.display_flex}>
                <img
                  src={salaryIcon}
                  alt=""
                  style={{ width: "20px", height: "20px", marginRight: 5 }}
                  className={app_classes.general.color_secondary}
                />
                <JobluTypography.Body>
                  {job?.salaryMin === 1 && job?.salaryMax === 1
                    ? salaryRanges.COMPETIIVE_SALARY
                    : job?.salaryMin === 2 && job?.salaryMax === 2
                    ? salaryRanges.INDUSTRY_STANDARD
                    : `${formatMoney(
                        job?.salaryMin,
                        currencyOptions,
                        job?.salaryCurrency || "SGD"
                      )} - ${formatMoney(
                        job?.salaryMax,
                        currencyOptions,
                        job?.salaryCurrency || "SGD"
                      )}`}
                </JobluTypography.Body>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {job?.technicalSkills?.length > 0 && (
            <>
              <Box>
                <Box
                  className={`${app_classes.margin.b1} ${app_classes.margin.t1}`}
                >
                  <JobluTypography.Body semiBold primary>
                    <img src={TechnicalSkillsIcon} width={16} /> Technical
                    Skills
                  </JobluTypography.Body>
                </Box>
                <Box>
                  {job?.technicalSkills?.map((item) => {
                    return (
                      <Chip
                        label={
                          item?.length > 20 ? `${item?.slice(0, 20)}...` : item
                        }
                        className={`${edit_profile_classes.chip_active}`}
                        style={{ fontSize: 12 }}
                      />
                    );
                  })}
                </Box>
              </Box>
            </>
          )}
          <JobluTypography.Body primary semiBold>
            Responsibilities:
          </JobluTypography.Body>
          <Box style={{ width: "90%" }}>
            <JobluTypography.Caption>
              {job?.responsibilities?.substring(0, 100)}
              {job?.responsibilities?.length > 150 && "..."}
            </JobluTypography.Caption>
          </Box>
        </Grid>
        <Grid
          xs={12}
          sm={2}
          container
          alignContent="flex-start"
          justifyContent="flex-end"
        >
          <JobseekerSwipeActions
            job={job}
            onSwipe={onSwipe}
            card_index={card_index}
          />
        </Grid>
      </Grid>

      <Box
        style={{
          bottom: 0,
          padding: "15px",
          position: "absolute",
          width: "100%",
          textAlign: "center",
          //border: "1px solid red",

          background:
            "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 54%, rgba(255,255,255,0.25253851540616246) 100%)",
        }}
      >
        <Grid item xs={12} container justifyContent="flex-end">
          <Grid item xs={12} md={6}>
            <JobluTypography.Body semiBold style={{ fontWeight: 600 }}>
              <span
                role="button"
                onClick={() => {
                  if (job?.matchJob) {
                    setViewJob({
                      ...job?.matchJob,
                      employer: job?.matchEmployer,
                    });
                  } else {
                    setViewJob(job);
                  }
                }}
              >
                See more{" "}
              </span>
            </JobluTypography.Body>
          </Grid>
        </Grid>
      </Box>
      <JobDetailsModal
        job={view_job}
        open={Boolean(view_job)}
        setOpen={setViewJob}
        isFromExplore
        card_index={card_index}
        onSwipe={onSwipe}
      />
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    AppState: state.Generals,
  };
};
export default connect(mapStateToProps, null)(ListCard);
