import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userRequest } from "../../service/requests";
import Loading from "../../components/reusables/Loading";
import VerifyImg from "../../assets/img/verify/verifyImg.png";
import JobluTypography from "../../components/Typography";
import { Box } from "@material-ui/core";

const VerifyEmail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [verifySuccess, setVerifySuccess] = useState(true);
  useEffect(() => {
      const token = searchParams.get("token")
      if(token)
          userRequest.verifyEmail({
              token: token
          })
          .then(res => {
              if(res?.data?.accessToken){
                  if(res?.data?.role === 'EMPLOYER')
                      navigate('/login', { state: { verify: true }})
                  else
                      setVerifySuccess(true)
              }
          })
          .catch(e => {
              navigate('/login')
          })
      else{

      }
  }, [])

  return (
    <div className="d-flex" style={{ height: "100vh" }}>
      <div className="m-auto">
        {verifySuccess ? (
          <div className="card login-card">
            <div className="card-body p-0 text-center">
              <img src={VerifyImg} alt="" />
              <div className="p-5" style={{ backgroundColor: "#E4ECF3" }}>
                <Box className="mb-3">
                  <JobluTypography.H4 primary>
                    You have successfully verified
                    <br />
                    your account!
                  </JobluTypography.H4>
                </Box>
                <Box>
                  <span
                    className="hand"
                    onClick={() => {
                      navigate("/login");
                    }}
                    style={{ color: "#009CDE" }}
                  >
                    Go back to login
                  </span>
                </Box>
              </div>
            </div>
          </div>
        ) : (
          <Loading show />
        )}
      </div>
    </div>
  );
};
export default VerifyEmail;
