import React, { useState } from 'react'
import { useEffect } from 'react'
import './style.css'

function Pagination(props) {
    const {
        postsPerPage,
        totalData,
        paginate,
        page,
        setPage,
        currentPage,
        setCurrentPage,
        loadMore
    } = props

    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(totalData / postsPerPage); i++) {
        pageNumbers.push(i)
    }

    const setPageNumber = (number) => {
        paginate(number)
    }

    const prev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const next = () => {
        if (currentPage < Math.ceil(totalData / postsPerPage)) {
            setCurrentPage(currentPage + 1)
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-center flex-column flex-md-row">
            <nav aria-label="Page navigation example" className="text-end">
                <ul className="pagination mb-1 custom-scroll">
                    {currentPage != 1 && (
                        <li className={`page-item mb-1`}>
                            <a
                                className="page-link text-primary hand "
                                onClick={() => prev()}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-left"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                    />
                                </svg>
                            </a>
                        </li>
                    )}
                    <li className={`page-item mb-1`}>
                        <a className="page-link text-primary hand">
                            {currentPage} of{' '}
                            {Math.ceil(totalData / postsPerPage)}
                        </a>
                    </li>
                    {currentPage != Math.ceil(totalData / postsPerPage) && (
                        <li className={`page-item mb-1`}>
                            <a
                                className="page-link text-primary hand"
                                onClick={() => next()}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    classname="bi bi-arrow-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                    />
                                </svg>
                            </a>
                        </li>
                    )}
                    {/* {currentPage == Math.ceil(totalData / postsPerPage) && (
                        <li className={`page-item mb-1`}>
                            <a
                                className="page-link text-primary hand"
                                onClick={() => loadMore()}
                            >
                               Load More
                            </a>
                        </li>
                    )} */}
                </ul>
            </nav>
        </div>
    )
}

export default Pagination
