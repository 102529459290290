import axios from "axios";
import disableScroll from "disable-scroll";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import moment from "moment";
import ReactDOM from "react-dom";
import ageIcon from "../assets/img/ageIcon.svg";
import educationIcon from "../assets/img/educationIcon.svg";
import jobIcon from "../assets/img/jobIcon.svg";
import languageIcon from "../assets/img/languageIcon.svg";
import locationIcon from "../assets/img/locationIcon.svg";
import skillsIcon from "../assets/img/skillsIcon.svg";
import valuesIcon from "../assets/img/valuesIcon.svg";
import nationalityIcon from "../assets/joblu-2.0/img/new-icons/nationality.svg";
import overseasExpIcon from "../assets/joblu-2.0/img/new-icons/overseas-experience.svg";
import employmentHistoryIcon from "../assets/joblu-2.0/img/new-icons/work-experience.svg";
import resumeBy from "../assets/joblu-logos/resume-by.png";
import api from "../service/api";
import { userRequest } from "../service/requests";
import { baseURL, profilePlaceholder_Applicant } from "./constants";
import "./dl-styles.css";
function ProfessionalDetailsCard({ professional_details, jobseeker }) {
  return (
    <div className="card-body">
      <h5>Professional Details</h5>
      <p>
        <strong>About Me</strong>
      </p>
      <p>{jobseeker?.bio}</p>

      <div className="row mt-3">
        {professional_details.map((item) => {
          return (
            <div className="col-md-6 col-sm-12 d-flex align-items-center mb-3">
              <img
                style={{
                  width: 26,
                  height: 26,
                }}
                alt=""
                src={item.logo}
                className="me-3"
              />
              <div>
                <p>
                  <span className="fw-bold" style={{ fontSize: 13 }}>
                    {item.label}
                  </span>
                </p>
                <p>
                  {" "}
                  <span className="" style={{ fontSize: 12 }}>
                    {item.value}
                  </span>
                </p>
                {item.subvalue && (
                  <p>
                    <span className="" style={{ fontSize: 12 }}>
                      {item.subvalue}
                    </span>
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
function PreferencesCard({ preferences_details }) {
  return (
    <div className="card-body">
      <h5>Preferences</h5>
      {preferences_details.map((item) => {
        return (
          <div className=" d-flex align-items-center mb-3">
            <img
              style={{
                width: 24,
                height: 24,
              }}
              alt=""
              src={item.logo}
              className="me-3"
            />
            <div>
              <p>{item.label}</p>
              <div>
                {item.value?.map((val) => {
                  return (
                    <span className="badge rounded-pill bg-primary fs13 me-2">
                      {val}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export const ProfileBody = ({ jobseeker }) => {
  const professional_details = [
    {
      label: "Written/Spoken Language",
      value:
        !jobseeker?.languagesSpoken || jobseeker?.languagesSpoken.length === 0
          ? "NA"
          : jobseeker.languagesSpoken.map((item, index) =>
              index === jobseeker.languagesSpoken.length - 1
                ? `${item}`
                : `${item}, `
            ),
      logo: languageIcon,
    },
    {
      label: "Employment History",
      value: jobseeker?.formerCompanyName,
      subvalue: jobseeker?.formerJobTitle,
      logo: employmentHistoryIcon,
    },
    {
      label: "Educational Background",
      value: jobseeker?.education,
      logo: educationIcon,
    },
    {
      label: "Overseas Work Experience",
      value: !jobseeker?.overseasExp
        ? "NA"
        : jobseeker?.overseasExp
        ? "Yes"
        : "None",
      logo: overseasExpIcon,
    },
    {
      label: "Interested to Work In",
      value: !jobseeker?.countriesInterested
        ? "NA"
        : jobseeker.countriesInterested.map((item, index) =>
            index === jobseeker.countriesInterested.length - 1
              ? `${item}`
              : `${item}, `
          ),
      logo: nationalityIcon,
    },
    { label: "Job Function", value: jobseeker?.jobFunction, logo: jobIcon },
  ];

  const preferences_details = [
    {
      label: "Skills and Expertise",
      value: jobseeker?.skills,
      logo: skillsIcon,
    },
    {
      label: "What I Value",
      value: jobseeker?.importantForYou,
      logo: valuesIcon,
    },
  ];
  return (
    <div
      className="py-3"
      id="profileDownloadable"
      style={{ maxWidth: "720px" }}
    >
      <div className="mt-4">
        <div className="d-flex border-bottom p-3">
          <img
            src={jobseeker?.profileUrl || profilePlaceholder_Applicant}
            className="br5 me-3"
            width="90"
            height="90"
            alt=""
            id="profile"
            //crossOrigin="anonymous"
          />

          <div className="ms-0 row w-100">
            <div className="col-7 ms-0 ps-0">
              <h6 className="my-0">
                {jobseeker?.firstName} {jobseeker?.lastName}
              </h6>
              <p>{jobseeker?.preferredTitle}</p>
              <p>{jobseeker?.selfDescription}</p>
            </div>
            <div className="col-5 d-flex flex-column">
              <div className="d-flex align-items-center mb-2 mb-lg-0 ms-0">
                <img
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  alt=""
                  src={locationIcon}
                  className="ms-0 me-2"
                />
                <p>{jobseeker?.currentLocation}</p>
              </div>

              <div className="d-flex align-items-center mb-2 mb-lg-0 mt-2">
                <img
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  alt=""
                  src={ageIcon}
                  className="me-2"
                />
                <p>
                  {" "}
                  {moment().diff(moment(jobseeker?.dob), "years")} Years Old
                </p>
              </div>

              <div className="d-flex align-items-center mt-2  ">
                <img
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  alt=""
                  src={jobIcon}
                  className="me-2"
                />
                <p>
                  {jobseeker?.totalExperienceMin === undefined ||
                  jobseeker?.totalExperienceMin === null
                    ? "No Work Experience"
                    : jobseeker?.totalExperienceMin ===
                      jobseeker?.totalExperienceMax
                    ? `${jobseeker?.totalExperienceMin} Years of Experience`
                    : jobseeker?.totalExperienceMin === 20
                    ? `More than ${jobseeker?.totalExperienceMin} Years of Experience`
                    : `${jobseeker?.totalExperienceMin} - ${jobseeker?.totalExperienceMax} Years of Experience`}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className=" p-3 mt-3 col-8">
            <ProfessionalDetailsCard
              jobseeker={jobseeker}
              professional_details={professional_details}
            />
          </div>
          <div className=" p-3 mt-3 col-4">
            <div className="card-body">
              <h5>Preferences</h5>
              {preferences_details.map((item) => {
                return (
                  <div className=" align-items-center mb-3">
                    <div className="d-flex">
                      <img
                        style={{
                          width: 20,
                          height: 20,
                        }}
                        alt=""
                        src={item.logo}
                        className="me-3"
                      />
                      <p>{item.label}</p>
                    </div>
                    <div>
                      <div>
                        {item.value?.map((val) => {
                          return (
                            <span className="badge rounded-pill bg-primary fs13 me-2">
                              {val}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 border-top py-3 d-flex justify-content-end">
        <img src={resumeBy} alt="" width="300" className="me-5" />
      </div>
    </div>
  );
};
export const download_profile = (id, callback) => {
  try {
    disableScroll.on();
    callback("downloading");
    userRequest.getApplicantDetails(id).then((res) => {
      const jobseeker = res?.data?.applicantProfiles;
      const elem = ProfileBody({ jobseeker });
      let div = document.createElement("div");
      div.id = "profile-dl";
      const container = ReactDOM.createRoot(div);
      container.render(elem);
      document.getElementById("dl-container").appendChild(div);

      const node = document.getElementById("profile-dl");
      setTimeout(() => {
        domtoimage
          .toJpeg(node, {
            cacheBust: true,
            bgcolor: "#fff",
            width: 700,
            quality: 1,
          })
          .then(function (dataUrl) {
            const doc = new jsPDF();
            doc.addImage(dataUrl, "PNG", 12, 5, 0, 0);
            doc.save(`${jobseeker.firstName} ${jobseeker.lastName}.pdf`);
            callback("finished");
            setTimeout(() => {
              node.remove();
              disableScroll.off();
            }, 500);
          });
      }, 2000);
    });
  } catch (err) {
    console.log(err);
    callback("error");
  }
};

export const download_profile_mobile = (callback, token) => {
  const axiosInstance = axios.create({
    baseURL: baseURL,
  });

  try {
    disableScroll.on();
    callback("downloading");
    axiosInstance
      .get(api.GET_USER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const jobseeker = res?.data?.applicantProfiles;
        const elem = ProfileBody({ jobseeker });
        let div = document.createElement("div");
        div.id = "profile-dl";
        const container = ReactDOM.createRoot(div);
        container.render(elem);
        document.getElementById("dl-container").appendChild(div);
        const node = document.getElementById("profile-dl");
        setTimeout(() => {
          domtoimage
            .toJpeg(node, {
              cacheBust: true,
              bgcolor: "#fff",
              width: 700,
              quality: 1,
            })
            .then(function (dataUrl) {
              const doc = new jsPDF();
              doc.addImage(dataUrl, "PNG", 12, 5, 0, 0);
              // doc.save(
              //     `${jobseeker.firstName} ${jobseeker.lastName}.pdf`
              // )
              window.open(
                doc.output("bloburl"),
                `${jobseeker.firstName} ${jobseeker.lastName}.pdf`
              );
              callback("finished");
              setTimeout(() => {
                node.remove();
                disableScroll.off();
              }, 500);
            });
        }, 2000);
      });
  } catch (err) {
    console.log(err);
    callback("error");
  }
};

export const download_profile_backend = (id) => {
  return new Promise((resolve, reject) => {
    userRequest
      .downloadProfile({ userId: id })
      .then((response) => {
        // console.log(response.data)
        if (response?.data?.documents) resolve(response.data.documents);
        else reject();
      })
      .catch((e) => {
        reject(e);
      });
  });
};
