import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { STRIPE_PK } from "../../utils/constants";
import { thousandSeparator } from "../../utils/helpers";
import CheckoutForm from "./CheckoutForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PK);

const StripeCheckout = () => {
  const location = useLocation();
  const [options, setOptions] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.intent?.paymentIntent) {
      setOptions({
        clientSecret: location.state.intent.paymentIntent,
      });
    }
    console.log(location);
  }, [location.state]);
  return (
    <>
      {options && (
        <Elements stripe={stripePromise} options={options}>
          <div className="p-4">
            <button
              className="btn text-primary ms-3 ms-md-5 mt-4 mb-3"
              onClick={() => {
                navigate(-1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>{" "}
              Go back
            </button>
            <div className="open-sans">
              <CheckoutForm
                amount={thousandSeparator(
                  location.state?.intent?.plan.unit_amount / 100
                )}
                plan={location.state?.intent?.plan}
              />
            </div>
          </div>
        </Elements>
      )}
    </>
  );
};

export default StripeCheckout;
