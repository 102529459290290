import { Box } from "@material-ui/core";
import DismissIcon from "../../../../assets/img/matches/DismissIcon.png";
import LikeIcon from "../../../../assets/img/matches/LikeIcon.png";
//import SuperLikeIcon from "../../../../assets/img/editProfile/superLikeIcon.png";
import { app_styles } from "../../../../general.styles";
import { SWIPE_ACTIONS } from "../../../my-jobs/components/card";
export const SLIDE_CLASSES = {
  SLIDE_LEFT: "slide-out-left",
  SLIDE_RIGHT: "slide-out-right",
  SLIDE_TOP: "slide-out-top",
};

const JobseekerSwipeActions = ({ job, onSwipe, card_index, ...rest }) => {
  const app_classes = app_styles(rest);
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box className={app_classes.margin.x1}>
        <img
          src={DismissIcon}
          alt="icon"
          role="button"
          style={{ width: "56px", height: "56px" }}
          onClick={(e) => {
            e.stopPropagation();
            onSwipe(
              job,
              SWIPE_ACTIONS.DISMISS,
              card_index,
              SLIDE_CLASSES.SLIDE_LEFT
            );
          }}
        />
      </Box>
      {/* <Box>
              <img
                src={SuperLikeIcon}
                alt="icon"
                role="button"
                onClick={() => onSwipe(job, SWIPE_ACTIONS.SUPERLIKE)}
              />
            </Box> */}
      <Box className={app_classes.margin.x1}>
        <img
          src={LikeIcon}
          alt="icon"
          role="button"
          style={{ width: "56px", height: "56px" }}
          onClick={(e) => {
            e.stopPropagation();
            onSwipe(
              job,
              SWIPE_ACTIONS.LIKE,
              card_index,
              SLIDE_CLASSES.SLIDE_RIGHT,
              job?.employer
            )
          }}
        />
      </Box>
    </Box>
  );
};

export default JobseekerSwipeActions;
