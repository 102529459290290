import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import ArchiveIcon from "../../assets/img/matches/archiveIcon.png";
import PageTitle from "../../components/page-title";
import { a11yProps, CustomTabPanel } from "../../components/tab/index";
import JobluTypography from "../../components/Typography";
import { app_styles } from "../../general.styles";
import ApplicantAppliedJobs from "./components/applied_jobs";
import ApplicantHiredJobs from "./components/hired";
import ApplicantInterviewJobs from "./components/interviews";
import ApplicantShortlistedJobs from "./components/shortlisted_jobs";
import { landing_styles } from "./styles";
function MyJobslanding(props) {
  const navigate = useNavigate();
  const [jobDrafts, setJobDrafts] = useState([]);
  const [draftsCount, setDraftsCount] = useState(0);
  const [draftsPage, setDraftspage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [jobsPage, setJobsPage] = useState(1);
  const [totalJobsPage, setTotalJobsPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [activeJobsSettings, setActiveJobsSettings] = useState({
    totalCount: null,
    limit: 20,
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const handleChangeTab = (event, val) => {
    setSelectedTab(val);
  };

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.index) {
      setSelectedTab(location?.state?.index);
    }
  }, [location]);

  const landing_classes = landing_styles(props);
  const app_classes = app_styles(props);
  return (
    <>
      <PageTitle pageTitle="Job Matches" />
      <Grid container justifyContent="center">
        <Grid item xs={11} md={8}>
          <Box className={`${app_classes.text.right} ${app_classes.margin.b1}`}>
            <img
              src={ArchiveIcon}
              width={22}
              className={`${app_classes.cursor.pointer}`}
              onClick={() => {
                navigate("/archived");
              }}
            />
          </Box>
          <Box className={`${app_classes.border.border_all}`}>
            <Grid container justifyContent="center">
              <Grid item md={12}>
                <Box sx={{ width: "100%" }} style={{ marginTop: 20 }}>
                  <Box
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    className={app_classes.padding.x1}
                  >
                    <Tabs
                      value={selectedTab}
                      onChange={handleChangeTab}
                      centered
                      indicatorColor="primary"
                      className={landing_classes.tabs}
                      variant="fullWidth"
                    >
                      <Tab
                        className={landing_classes.tab}
                        label={
                          <JobluTypography.Body
                            style={{ fontSize: 14 }}
                            semiBold
                            primary
                            className={`${
                              selectedTab == "0" && app_classes.text.white
                            }`}
                          >
                            Applied
                          </JobluTypography.Body>
                        }
                        {...a11yProps(0)}
                      />
                      <Tab
                        className={landing_classes.tab}
                        label={
                          <JobluTypography.Body
                            style={{ fontSize: 14 }}
                            semiBold
                            primary
                            className={`${
                              selectedTab == "1" && app_classes.text.white
                            }`}
                          >
                            Shortlisted
                          </JobluTypography.Body>
                        }
                        {...a11yProps(1)}
                      />
                      <Tab
                        className={landing_classes.tab}
                        label={
                          <JobluTypography.Body
                            style={{ fontSize: 14 }}
                            semiBold
                            primary
                            className={`${
                              selectedTab == "2" && app_classes.text.white
                            }`}
                          >
                            Interviews
                          </JobluTypography.Body>
                        }
                        {...a11yProps(2)}
                      />
                      <Tab
                        className={landing_classes.tab}
                        label={
                          <JobluTypography.Body
                            style={{ fontSize: 14 }}
                            semiBold
                            primary
                            className={`${
                              selectedTab == "3" && app_classes.text.white
                            }`}
                          >
                            Hired
                          </JobluTypography.Body>
                        }
                        {...a11yProps(3)}
                      />
                    </Tabs>
                  </Box>
                  <Box>
                    <Grid container justifyContent="center">
                      <Grid item xs={11}>
                        <Box>
                          <hr style={{ marginBottom: 0 }} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <CustomTabPanel
                    value={selectedTab}
                    index={0}
                    className={app_classes.width.w100}
                  >
                    <ApplicantAppliedJobs />
                  </CustomTabPanel>
                  <CustomTabPanel value={selectedTab} index={1}>
                    <ApplicantShortlistedJobs />
                  </CustomTabPanel>
                  <CustomTabPanel value={selectedTab} index={2}>
                    <ApplicantInterviewJobs />
                  </CustomTabPanel>
                  <CustomTabPanel value={selectedTab} index={3}>
                    <ApplicantHiredJobs />
                  </CustomTabPanel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default MyJobslanding;
