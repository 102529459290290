import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { connect } from "react-redux";
import rootActions from "../../redux/action";
import appActions from "../../redux/app/actions";
import { userRequest } from "../../service/requests";
import JobluTypography from "../Typography";
const EmailNotVerifiedModal = ({
  User,
  open,
  showAlert,
  setOpen,
  handleLogout,
}) => {
  return (
    <Dialog open={open} maxWidth="xs">
      <Box
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <Paper style={{ padding: 20, paddingTop: 0 }}>
        <JobluTypography.H6 align="center">
          Your email is not yet verified
        </JobluTypography.H6>
        <JobluTypography.Body
          align="center"
          style={{ fontSize: 14, marginTop: 20 }}
        >
          Click on the link in your email. If you don't see the email in your
          inbox, please check your promotions or spam folder.
        </JobluTypography.Body>
        <JobluTypography.Body
          align="center"
          style={{ fontSize: 14, marginTop: 20 }}
        >
          Once verified, login to your account and enjoy swiping.
        </JobluTypography.Body>
        <Grid container style={{ marginTop: 20 }} spacing={1}>
          <Grid xs={12} sm={6} item>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              style={{ textTransform: "none", margin: 5 }}
              onClick={() => {
                setOpen(false);
                handleLogout();
              }}
            >
              I have verified my email
            </Button>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              style={{ textTransform: "none", margin: 5 }}
              onClick={() => {
                userRequest
                  .resendVerificationEmail({ email: User?.user?.email })
                  .then((res) => {
                    console.log("resend response", res.data);
                    if (res.data?.queued === true) {
                      showAlert({
                        message:
                          "We have sent the verification link to your email.",
                      });
                      setTimeout(() => {
                        setOpen(false);
                      }, 1000);
                    }
                  })
                  .catch(() => {
                    showAlert({
                      message: "An error occured please try again later",
                      type: "error",
                    });
                    setOpen(false);
                  });
              }}
            >
              Resend verification link
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return { User: state.User };
};

const mapDispatchToProps = {
  handleLogout: rootActions.signOut,
  showAlert: appActions.showAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailNotVerifiedModal);
