import React from 'react'
import { useNavigate } from 'react-router-dom'

function CreateJobButton() {
    const navigate = useNavigate()
    return (
        <div className="d-grid d-md-block w-100 text-end">
            <button
                data-tut="explore-step-1"
                className="btn btn-primary"
                onClick={() => navigate('/create-job')}
            >
                + Create a Job
            </button>
        </div>
    )
}

export default CreateJobButton
