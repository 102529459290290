import { useState } from 'react'
import DismissIcon from '../../../../../assets/img/editProfile/dismissIcon.png'
import LikeIcon from '../../../../../assets/img/editProfile/likeIcon.png'
import SuperLikeIcon from '../../../../../assets/img/editProfile/superLikeIcon.png'
import educationIcon from '../../../../../assets/img/explore/educationIcon.svg'
import experienceIcon from '../../../../../assets/img/explore/experienceIcon.svg'
import locationIcon from '../../../../../assets/img/explore/locationIcon.svg'
import playIcon from '../../../../../assets/img/explore/playIcon.svg'
import skillsIcon from '../../../../../assets/img/explore/skillsIcon.png'
import { profilePlaceholder_Applicant } from '../../../../../utils/constants'
import {
    getYearDifference,
    getYearsOfExpCategory,
} from '../../../../../utils/helpers'
import JobluVideoPlayer from '../../../components/VideoPlayer'
import { defaultActions, SLIDE_CLASSES } from '../../../Explore'
import { ProfileModal } from '../ViewProfile/ProfileModal'

const ExploreListviewCard = ({
    seeker,
    setIsViewProfile,
    setJobseeker,
    onSwipeFulfilled,
    cardIndex,
    explore,
    currencyList,
    currency,
    itemIndex,
}) => {
    const handleViewProfile = () => {
        setJobseeker(seeker)
        setIsViewProfile(true)
    }
    const handleAction = (data, dir, new_class, type) => {
        onSwipeFulfilled(data, cardIndex, dir, new_class, 'list', itemIndex)
    }
    const [playVideo, setPlayVideo] = useState(false)
    const [showPitch, setShowPitch] = useState(true)
    const handlePlayVideo = () => {
        setPlayVideo(!playVideo)
    }
    const [showProfileModal, setShowProfileModal] = useState(false)

    const handleCloseProfileModal = () => setShowProfileModal(false)
    const handleShowProfileModal = () => setShowProfileModal(true)

    const currencyOptions = {
        currencyList,
        currency,
    }

    const getYearsOfExp = (item) => {
        if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
            const sorted =
                item?.experienceHistory &&
                item?.experienceHistory?.sort(function (a, b) {
                    return new Date(a.startDate) - new Date(b.startDate)
                })
            const startDate = new Date(sorted[0]?.startDate)
            const diff = getYearDifference(startDate)
            return getYearsOfExpCategory(diff)
        } else {
            return null
        }
    }

    return (
        <>
            <ProfileModal
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                handleCloseProfileModal={handleCloseProfileModal}
                handleShowProfileModal={handleShowProfileModal}
                seeker={seeker}
                handleAction={handleAction}
                explore={true}
                hasSwipeActions={true}
            />
            <div className="w-100 card shadow-sm mb-3 p-3 mt-3">
                <div className="row">
                    <div className="col-md-8 d-flex">
                        <div className="me-4" style={{ flexBasis: '15%' }}>
                            <div
                                className="position-relative"
                                style={{ width: 80, height: 80 }}
                            >
                                <img
                                    src={
                                        seeker?.profileUrl ||
                                        profilePlaceholder_Applicant
                                    }
                                    style={{
                                        width: 80,
                                        height: 80,
                                    }}
                                    alt="profile"
                                    className="rounded-circle mb-3"
                                />
                                {seeker?.elevatorPitch && (
                                    <>
                                        <div
                                            className="position-absolute"
                                            style={{ bottom: -6, right: -6 }}
                                        >
                                            <img
                                                src={playIcon}
                                                alt=""
                                                className="hand"
                                                onClick={handlePlayVideo}
                                            />
                                        </div>
                                        <JobluVideoPlayer
                                            src={seeker?.elevatorPitch}
                                            play={playVideo}
                                            setPlayVideo={setPlayVideo}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="me-4" style={{ flexBasis: '35%' }}>
                            <h6 className="text-primary text-capitalize">
                                <strong>{seeker?.firstName}</strong>
                            </h6>
                            <small className="text-primary">
                                {seeker.preferredTitle}
                            </small>
                            {seeker.educationHistory.length > 0 && (
                                <>
                                    <p className="small">
                                        <img
                                            src={educationIcon}
                                            alt=""
                                            className="me-2"
                                            style={{ width: 22 }}
                                        />{' '}
                                         {
                                                seeker?.educationHistory?.sort(function(a,b)
                                                {
                                                    return new Date(b.educStartDate) - new Date(a.educStartDate);
                                                })[0]?.educName
        
                                            }
                                    </p>
                                </>
                            )}
                            {seeker?.currentLocation && (
                                <>
                                    <p className="small">
                                        <img
                                            src={locationIcon}
                                            alt=""
                                            className="me-2"
                                            style={{ width: 22 }}
                                        />{' '}
                                        {seeker?.currentLocation}
                                    </p>
                                </>
                            )}
                            {getYearsOfExp(seeker) &&
                                getYearsOfExp(seeker) !== null && (
                                    <p className="small">
                                        <img
                                            src={experienceIcon}
                                            alt=""
                                            className="me-2"
                                            style={{ width: 22 }}
                                        />{' '}
                                        {`${getYearsOfExp(
                                            seeker
                                        )} Years of Experience`}
                                    </p>
                                )}
                            {/* {seeker?.salaryMin &&
                                    seeker?.salaryMin !== null && (
                                        <p className="small">
                                            <img
                                                src={salaryIcon}
                                                alt=""
                                                className="me-2"
                                                style={{ width: 22 }}
                                            />{' '}
                                            {seeker?.salaryMin === 1 &&
                                            seeker?.salaryMax === 1
                                                ? salaryRanges.COMPETIIVE_SALARY
                                                : seeker.salaryMin === 2 &&
                                                  seeker?.salaryMax === 2
                                                ? salaryRanges.INDUSTRY_STANDARD
                                                : `${formatMoney(
                                                      seeker?.salaryMin,
                                                      currencyOptions,
                                                      seeker?.salaryCurrency
                                                          ? seeker?.salaryCurrency
                                                          : 'SGD'
                                                  )} - ${formatMoney(
                                                      seeker?.salaryMax,
                                                      currencyOptions,
                                                      seeker?.salaryCurrency
                                                          ? seeker?.salaryCurrency
                                                          : 'SGD'
                                                  )}`}
                                        </p>
                                    )} */}
                        </div>
                        <div style={{ flexBasis: '60%' }}>
                            {seeker?.skills?.length > 0 && (
                                <>
                                    <div className="mb-3">
                                        <div className="d-flex">
                                            <div className="me-3">
                                                <img
                                                    src={skillsIcon}
                                                    alt=""
                                                    style={{ width: '24px' }}
                                                />
                                            </div>
                                            <div>
                                                <h6 className="mb-2 text-primary">
                                                    Technical Skills
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {seeker?.skills
                                                ?.slice(0, 5)
                                                .map((skill) => {
                                                    return (
                                                        <span
                                                            style={{
                                                                backgroundColor:
                                                                    '#009CDE',
                                                                borderRadius:
                                                                    '12px',
                                                                padding:
                                                                    '0 16px',
                                                                color: '#ffffff',
                                                                fontSize: 12,
                                                            }}
                                                            className="me-2 mb-1 text-nowrap"
                                                        >
                                                            {skill}
                                                        </span>
                                                    )
                                                })}
                                            {seeker?.skills.length > 5 && (
                                                <>
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                '#009CDE',
                                                            borderRadius:
                                                                '12px',
                                                            padding: '0 16px',
                                                            color: '#ffffff',
                                                            fontSize: 12,
                                                        }}
                                                        className="me-2 mb-1 text-nowrap"
                                                    >
                                                        +{' '}
                                                        {seeker?.skills.length -
                                                            5}{' '}
                                                        more
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                        <span
                                            className="hand"
                                            onClick={() => {
                                                handleShowProfileModal()
                                            }}
                                            style={{
                                                color: '#009CDE!important',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            <small>See More</small>
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex  h-100">
                            <div className="d-flex my-auto w-100  px-3 justify-content-evenly">
                                <div>
                                    <img
                                        src={DismissIcon}
                                        alt="icon"
                                        role="button"
                                        onClick={() =>
                                            handleAction(
                                                seeker,
                                                defaultActions.left,
                                                SLIDE_CLASSES.SLIDE_LEFT
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <img
                                        src={SuperLikeIcon}
                                        alt="icon"
                                        role="button"
                                        onClick={() =>
                                            handleAction(
                                                seeker,
                                                defaultActions.top,
                                                SLIDE_CLASSES.SLIDE_TOP
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <img
                                        src={LikeIcon}
                                        alt="icon"
                                        role="button"
                                        onClick={() =>
                                            handleAction(
                                                seeker,
                                                defaultActions.right,
                                                SLIDE_CLASSES.SLIDE_RIGHT
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExploreListviewCard
