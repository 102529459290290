import React from "react";
import { Link } from "react-router-dom";
import LogoWTag from "../../assets/img/logo-w-tag.svg";
import img1 from '../../assets/img/jobseeker-register/img1.png'

function SignUpWithCode() {
  return (
    <div className="row vh-100  w-100">
      <div className="col-md-4 login-bg p-0 h-100 d-flex align-items-center">
      <div className="d-flex justify-content-center h-100 mt-0 mt-md-5">
                                <div className="text-center mt-5 ">
                                    <img
                                        src={LogoWTag}
                                        alt=""
                                        className="mb-4 mt-md-0"
                                    />
                                    <div className="d-none d-md-block">
                                        <h1>
                                            Find your next great adventure here
                                        </h1>
                                    </div>
                                    <h5>Find your next great adventure here</h5>
                                    <div className="d-none d-md-block text-center">
                                        <img
                                            src={img1}
                                            alt=""
                                            className="mt-3"
                                            style={{
                                                maxWidth: '496px',
                                                maxHeight: '496px',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
      </div>
      <div className="col-md-8 p-0 d-flex flex-column align-items-center justify-content-center">
      <img src={LogoWTag} alt="" className="mb-3 d-md-none d-block" />
        <div className="row w-100">
          <div className="col-md-7 col-sm-12 offset-md-3">
            <div className="card login-card">
              <div className="card-body p-5">
                <h4 className="text-center mb-5">Sign Up</h4>
                <div className="d-flex justify-content-between mb-1">
                  <label>Input Invite Code</label>
                  <label>
                    <Link to="/signup">I don't have an invite Code</Link>
                  </label>
                </div>
                <input
                  type="text"
                  required
                  className="form-control mb-5"
                  id="invite_code"
                  name="invite_code"
                  placeholder="000-000"
                />
                <h5>Login Details</h5>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      required
                      className="form-control mb-4"
                      type="text"
                      id="first_name"
                      name="first_name"
                      placeholder="Enter First Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      required
                      className="form-control mb-4"
                      id="last_name"
                      name="last_name"
                      placeholder="Enter Last Name"
                    />
                  </div>
                  <div className="col-sm-12">
                    <input
                      type="email"
                      required
                      className="form-control mb-4"
                      id="email"
                      name="email"
                      placeholder="Enter Email Address"
                    />
                  </div>
                  <div className="col-sm-12">
                    <input
                      type="password"
                      required
                      className="form-control mb-4"
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div className="col-sm-12">
                    <input
                      type="password"
                      required
                      className="form-control mb-4"
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>
                <div className="text-center">
                  <div className="text-center mb-2">
                    <button type="submit" className="btn btn-primary w-136">
                      Enter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignUpWithCode;
