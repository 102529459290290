import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import jbHorizontal from "../../assets/joblu-logos/jb-horizontal.png";
import userAction from "../../redux/user/actions";

const PublicHeader = (props) => {
  const {
    verifyGoogleToken,
    isViewJob,
    isLogin,
    setModalOpen,
    setModalVariant,
    job,
    showForm,
  } = props;
  const navigate = useNavigate();
  const _location = useLocation();

  const source = new URLSearchParams(_location.search).get("source");
  const influencerCode = new URLSearchParams(_location.search).get(
    "influencerCode"
  );
  const refId = new URLSearchParams(_location.search).get("refId");

  const headerlinks = [
    {
      label: "For Job Seekers",
      url: "https://joblu.io/jobseekers/",
    },
    {
      label: "For Companies",
      url: "https://joblu.io/companies/",
    },
    {
      label: "Partnerships",
      url: "https://joblu.io/partnerships/",
    },
    {
      label: "Resources",
      url: "https://joblu.io/resources/",
    },
  ];
  return (
    <>
      <div
        className="col-12 border py-3 mx-0 sticky-top"
        style={{ background: "#fff", maxHeight: "80px" }}
      >
        <div className="w-100 d-flex justify-content-center justify-content-md-between">
          <div>
            <a href="/">
              <img
                src={jbHorizontal}
                alt=""
                style={{ width: "150px" }}
                className="ms-0 ms-md-5"
              />
            </a>
          </div>
          <div className="d-none d-sm-flex pe-5">
            {headerlinks.map((nav) => {
              return (
                <div className="d-flex">
                  <button
                    className="btn btn-text my-auto mx-3"
                    style={{
                      color: "#27323C",
                      fontWeight: 400,
                      fontFamily: "poppins",
                      lineHeight: "24px",
                      fontSize: 16,
                    }}
                    onClick={() => {
                      window.location.href = nav.url;
                    }}
                  >
                    {nav.label}
                  </button>
                </div>
              );
            })}
            <button
              className="btn btn-outline-primary my-auto px-4 mx-3"
              onClick={(e) => {
                navigate("/login");
              }}
            >
              SIGN IN
            </button>
            <button
              className="btn btn-primary my-auto px-4 ms-3"
              onClick={(e) => {
                if (showForm !== "signup") {
                  let url = "/signup";
                  console.log(refId, influencerCode, source);
                  if (refId) {
                    url = url + "?refId=" + refId;
                  }
                  if (influencerCode) {
                    url =
                      url +
                      `${refId ? "&" : "?"}influencerCode=` +
                      influencerCode;
                  }
                  if (source) {
                    url =
                      url +
                      `${refId || influencerCode ? "&" : "?"}source=` +
                      source;
                  }
                  navigate(url);
                } else {
                  return;
                }
              }}
            >
              SIGN UP
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
  };
};
const mapDispatchToProps = {
  signIn: userAction.signIn,
  verifyGoogleToken: userAction.verifyGoogleToken,
  resetUserError: userAction.resetUserError,
};
export default connect(mapStateToProps, mapDispatchToProps)(PublicHeader);
