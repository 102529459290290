import { useState } from "react";
import { Modal } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";
import CompanyDefault from "../../../assets/img/companyDefault.png";
import SignUpSuccess from "../../../assets/img/signUpSuccess.png";
import CreateJobButton from "../../../components/reusables/CreateJobButton";
import SelectCountry2 from "../../../components/reusables/SelectCountry2";
import SelectJobFunction from "../../../components/reusables/SelectJobFunction";
import SelectJobPost from "../../../components/reusables/SelectJobPost";
import { creds } from "../../../utils/constants";

function ExploreModal(props) {
  const {
    showModal,
    setShowModal,
    variant,
    setModalVariant,
    headerTitle,
    filters,
    setFilters,
    matchImages,
    handleGoogleLogin,
    handleConfirmResetPassword,
    applicantName,
    jobTitle,
    handleFilter,
    referrers,
  } = props;

  const [selectedjob, setSelectedJob] = useState("");
  let showCloseButton = true;
  const navigate = useNavigate();
  if (variant === "noJobPosts" || variant === "noJobsSelected") {
    showCloseButton = false;
  }
  if (!variant) return null;

  const handleClose = () => {
    setShowModal(false);
    setModalVariant(null);
  };
  const handleSelectJob = () => {
    setFilters({
      ...filters,
      jobId: selectedjob,
    });
    handleClose();
  };

  const handleGoToMessage = (data) => {
    console.log("DATA::::", data);
    navigate("/messages", {
      state: { data },
    });
  };

  return (
    <Modal
      show={showModal}
      centered
      className={`${variant === "matchModal" && "bg-primary2"}`}
      style={{ zIndex: 100000 }}
    >
      {!!headerTitle && (
        <Modal.Header className="bg-primary">
          <p className="text-center text-light w-100">{headerTitle}</p>
        </Modal.Header>
      )}
      <div className="modal-body">
        {!headerTitle && showCloseButton && (
          <div className="text-end">
            <button
              type="button"
              className="btn-close text-end btn-sm"
              onClick={handleClose}
            ></button>
          </div>
        )}

        {/* RAN OUT OF LIKES */}
        {variant === "outOfLikes" && (
          <div className="text-center ">
            <h5>You're out of daily Likes!</h5>
            <p>That was fast! But don't worry, you can Like again tomorrow.</p>
            <div className="text-center mt-4">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClose}
              >
                OK
              </button>
            </div>
          </div>
        )}

        {/* RAN OUT OF MONTHLY LIKES */}
        {variant === "outOfMonthlyLikes" && (
          <div className="text-center ">
            <h5>Oops, you're out of Likes for this month!</h5>
            <p>The great news is, you can like again next month!</p>
            <div className="text-center mt-4">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClose}
              >
                OK
              </button>
            </div>
          </div>
        )}

        {/* RAN OUT OF SUPER LIKES */}
        {variant === "outOfSuperLikes" && (
          <div className="text-center ">
            <h5>You've run out of Super Likes!</h5>
            <p>
              That was fast! But don't worry, you can Super Like again tomorrow.
            </p>
            <div className="text-center mt-4">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClose}
              >
                OK
              </button>
            </div>
          </div>
        )}

        {/* MATCH MODAL */}
        {variant === "matchModal" && (
          <div className="text-center ">
            <h2 className="text-primary">It’s a Match!</h2>
            <p>
              {applicantName} has expressed interest
              <br />
              in your {jobTitle} job post.
            </p>
            <div className="my-4">
              <img
                src={matchImages?.applicant || CompanyDefault}
                alt=""
                className="w-120 me-3"
              />
              <img
                src={matchImages?.employer || CompanyDefault}
                alt=""
                className="w-120"
              />
            </div>
            <div className="btn-group-vertical">
              <button
                type="button"
                className="btn btn-primary mb-2"
                onClick={() => {
                  handleGoToMessage();
                }}
              >
                Start Chatting
              </button>
              <button
                type="button"
                className="btn btn-link"
                onClick={handleClose}
              >
                Keep Swiping
              </button>
            </div>
          </div>
        )}

        {/* REGISTER */}
        {variant === "signUpModal" && (
          <div className="text-center">
            <h6>Sign-up Successful!</h6>
            <img src={SignUpSuccess} alt="" className="my-4" />
            <p>
              Please check the link we sent to your
              <br /> email to verify your registration.
            </p>
            <p>
              If it isn't there, make sure it's not in
              <br /> your spam folder.
            </p>
            <button className="btn btn-primary">OK</button>
          </div>
        )}

        {/* PASSWORD RESET */}
        {variant === "passwordResetModal" && (
          <div className="text-center">
            <h6>Reset Password link sent!</h6>
            <img src={SignUpSuccess} alt="" className="my-4" />
            <p>
              We have sent you an email to reset your password.
              <br />
              Kindly check your email.
            </p>
            <p>
              If it isn't there, make sure it's not in
              <br /> your spam folder.
            </p>
            <br></br>
            <button
              onClick={() => handleConfirmResetPassword()}
              className="btn btn-primary"
            >
              OK
            </button>
          </div>
        )}

        {variant === "noJobPosts" && (
          <div className="text-center">
            <h6 className="text-primary">
              Post a job to view complete profiles and <br /> shortlist
              candidates
            </h6>
            <p className="text-primary">It only takes a couple of minutes</p>
            <div className="d-flex justify-content-center mt-4">
              <button
                className="btn btn-outline-primary me-2"
                onClick={handleClose}
              >
                Continue swiping
              </button>
              <div>
                <CreateJobButton />
              </div>
            </div>
          </div>
        )}
        {variant === "noJobsSelected" && (
          <div className="text-center">
            <h6 className="mb-3">
              Choose the job you want to hire candidates for.
            </h6>
            <SelectJobPost
              onChange={(value) => {
                console.log(value);
                setSelectedJob(value);
              }}
              value={selectedjob}
            />
            <div className="d-flex justify-content-center mt-3">
              <button
                className="btn btn-outline-primary text-center me-2"
                onClick={() => setModalVariant("")}
              >
                Close
              </button>
              <button
                className="btn btn-primary text-center"
                onClick={handleSelectJob}
              >
                Select job
              </button>
            </div>
          </div>
        )}

        {variant === "exploreFilter" && (
          <div className="">
            <h6 className="mb-3 text-center">
              To see more relevant profiles, select the job function and
              location
            </h6>
            <label htmlFor="" className="text-start">
              Job Function
            </label>
            <SelectJobFunction
              onChange={(value) => {
                setFilters({
                  ...filters,
                  jobFunction: value,
                });
              }}
              value={filters?.jobFunction}
            />
            <div className="mb-3"></div>
            <label htmlFor="" className="text-start">
              Location
            </label>
            <SelectCountry2
              grouped
              onChange={(value) => {
                setFilters({
                  ...filters,
                  location: value,
                });
              }}
              value={filters?.location}
              noCellCode
            />
            <div className="text-center mt-3">
              <button
                className="btn btn-primary text-center"
                onClick={() => {
                  handleFilter();
                  setShowModal(false);
                }}
              >
                Search
              </button>
            </div>
          </div>
        )}

        {/* SIGN UP */}
        {variant === "signUpModal2" && (
          <div className="text-center">
            <h6 className="mb-3">
              How do you want to
              <br />
              sign up?
            </h6>
            <div className=" mb-3">
              <button
                className="btn btn-primary mx-0"
                onClick={() => {
                  let url = "/signup";
                  if (referrers?.refId){
                    url = url + "?refId=" + referrers?.refId;
                  }
                  if (referrers?.influencerCode){
                    url = url + `${referrers?.refId ? '&' : '?'}influencerCode=` + referrers?.influencerCode;
                  }   
                  if (referrers?.source){
                    url = url + `${referrers?.refId || referrers?.influencerCode ? '&' : '?'}source=` + referrers?.source;
                  }
                  navigate(url, {
                    state: { isFromLogin: true, refId: referrers?.refId },
                  });
                }}
                style={{ width: 200 }}
              >
                Sign up with email
              </button>
            </div>
            <div className="mb-2">
              <GoogleLogin
                clientId={creds.GOOGLE_CLIENT_ID}
                buttonText="Sign up with Google"
                onSuccess={(res) => {
                  handleGoogleLogin(res);
                }}
                onFailure={(e) => {
                  console.log(e);
                }}
                className="shadow-none border w-200"
              />
            </div>
            <small>
              By signing up, you agree to the Joblu
              <br />{" "}
              <a
                href="https://joblu.io/terms-of-use/"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://joblu.io/terms-of-use/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </small>
            <hr />
            <p>Already have an account?</p>
            <a href="#" onClick={handleClose}>
              Sign in here
            </a>
          </div>
        )}

        {variant === "jobseekerSignupModal" && (
          <div className="text-center">
            <h6 className="mb-3">
              How do you want to
              <br />
              sign up?
            </h6>
            <div className=" mb-3">
              <button
                className="btn btn-primary mx-0"
                onClick={handleClose}
                style={{ width: 200 }}
              >
                Sign up with email
              </button>
            </div>
            <div className="mb-2">
              <GoogleLogin
                clientId={creds.GOOGLE_CLIENT_ID}
                buttonText="Sign up with Google"
                onSuccess={(res) => {
                  handleGoogleLogin(res);
                }}
                onFailure={(e) => {
                  console.log(e);
                }}
                className="shadow-none border w-200"
              />
            </div>
            <small>
              By signing up, you agree to the Joblu
              <br />{" "}
              <a
                href="https://joblu.io/terms-of-use/"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://joblu.io/terms-of-use/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </small>
            <hr />
            <p>Already have an account?</p>
            <a href="#" onClick={handleClose}>
              Sign in here
            </a>
          </div>
        )}
      </div>
    </Modal>
  );
}
export default ExploreModal;
