import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import TextField from '../../../components/inputs/CustomTextField'
import CustomTooltip from '../../../components/reusables/CustomTooltip'
import { configRequest, jobsRequest } from '../../../service/requests'
import useWindowDimensions from '../../../utils/hooks'
import SelectJobFunction from '../../../components/reusables/SelectJobFunction'
import { formatMoney, formatDateOnlyv2 } from '../../../utils/helpers'
import SelectCountry2 from '../../../components/reusables/SelectCountry2'
import '../styles.css'
import TextArea from '../../../components/inputs/CustomTextArea'

function CreateJob(props) {
    const { User, selectedJobId } = props

    const [tempSkill, setTempSkill] = useState('')
    const navigate = useNavigate()

    const location_ = useLocation()
    const [showAlert, setShowAlert] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [jobFunction, setJobFunction] = useState(
        location_?.state?.job?.jobFunction || null
    )
    const [error_JobFunction, setError_JobFunction] = useState(null)
    const [jobTitle, setJobTitle] = useState(
        location_?.state?.job?.jobTitle || null
    )
    const [error_JobTitle, setError_JobTitle] = useState(null)
    const [skills, setSkills] = useState(
        location_?.state?.job?.technicalSkills || []
    )

    const jT = location_?.state?.job?.jobTitle
    const [error_Skills, setError_Skills] = useState(null)
    const [responsibilities, setResponsibilities] = useState(
        location_?.state?.job?.responsibilities || null
    )
    const [error_Responsibilities, setError_Responsibilities] = useState(false)

    // START DATE
    const [startDate, setStartDate] = useState(
        location_?.state?.job?.expectedStartDate
            ? moment(location_?.state?.job?.expectedStartDate).format(
                  'YYYY-MM-DD'
              )
            : null
    )
    // SALARY RANGE
    const [currency, setCurrency] = useState(
        location_?.state?.job?.salaryCurrency || null
    )

    const [salaryMin, setSalaryMin] = useState(
        location_?.state?.job?.salaryMin || null
    )
    const [salaryMax, setSalaryMax] = useState(
        location_?.state?.job?.salaryMax || null
    )

    const [location, setLocation] = useState(
        location_?.state?.job?.location || null
    )
    const [error_Location, setError_Location] = useState(null)

    const [experience, setExperience] = useState(
        location_?.state?.job?.totalExperienceMin +
            '-' +
            location_?.state?.job?.totalExperienceMax || null
    )
    const [education, setEducation] = useState(
        location_?.state?.job?.education || []
    )
    const [workSetup, setWorkSetup] = useState(
        location_?.state?.job?.workSetup || []
    )
    const [employmentType, setEmploymentType] = useState(
        location_?.state?.job?.employmentType || []
    )
    const [benefits, setBenefits] = useState(
        location_?.state?.job?.perksAndBenefits || []
    )

    // OLD
    const [companyName, setCompanyName] = useState(
        location_?.state?.job?.companyName || null
    )
    const [error_CompanyName, setError_CompanyName] = useState(null)
    const [experienceRequired, setExperienceRequired] = useState(
        `${location_?.state?.job?.totalExperienceMin}-${location_?.state?.job?.totalExperienceMax}` ||
            null
    )
    const [error_ExperienceRequired, setError_ExperienceRequired] =
        useState(null)
    const [salaryRange, setSalaryRange] = useState(
        `${location_?.state?.job?.salaryMin}-${location_?.state?.job?.salaryMax}` ||
            null
    )
    const [error_SalaryRange, setError_SalaryRange] = useState(null)
    const [food, setFood] = useState(location_?.state?.job?.food || false)
    const [transport, setTransport] = useState(
        location_?.state?.job?.transport || false
    )
    const [housing, setHousing] = useState(
        location_?.state?.job?.housing || false
    )
    const [expectedStartDate, setExpectedStartDate] = useState(
        location_?.state?.job?.expectedStartDate
            ? formatDateOnlyv2(location_?.state?.job?.expectedStartDate)
            : null
    )
    const [error_ExpectedStartDate, setError_ExpectedStartDate] = useState(null)
    const [workShift, setWorkShift] = useState(
        location_?.state?.job?.workShift || null
    )
    const [workWeek, setWorkWeek] = useState(
        location_?.state?.job?.workWeek || null
    )
    const [educationAndExperience, setEducationAndExperience] = useState(
        location_?.state?.job?.educationAndExperience || null
    )
    // END OF OLD

    useEffect(() => {
        console.log(location_?.state?.job)
    }, [])

    const [currentIndex, setCurrentIndex] = useState(0)
    const [jobFunctions, setJobFunctions] = useState([])

    const [currencyError, setCurrencyError] = useState(false)
    const [salaryMinerror, setSalaryMinError] = useState(false)
    const [salaryMaxerror, setSalaryMaxError] = useState(false)
    const [workSetupError, setWorkSetupError] = useState(false)
    const [startDateError, setStartDateError] = useState(false)
    const [employmentTypeError, setEmploymentTypeError] = useState(false)

    // ERRORS
    const [errors, setErrors] = useState({
        jobTitle: null,
    })

    const [jobFunctionError, setJobFunctionError] = useState(false)
    const [skillsError, setSkillsError] = useState(false)
    const [responsibilitiesError, setResponsibilitiesError] = useState(false)

    useEffect(() => {
        // setSelectedJob(location_?.state?.job)
        const divId = new URLSearchParams(location_.search).get('ref')
        document.getElementById(divId)?.scrollIntoView()
    }, [])

    const validate = () => {
        const isConnect = User?.user?.email === 'connect@joblu.io'

        setError_JobFunction(!jobFunction ? 'Job Function is required' : null)
        setError_JobTitle(!jobTitle ? 'Job Title is required' : null)
        setError_Responsibilities(
            !responsibilities ? 'Responsibilities are required' : null
        )

        if (isConnect)
            // setError_CompanyName(
            //     !companyName ? 'Company name is required' : null
            // )

            setError_Skills(
                skills?.length < 1 ? 'Please enter at least one' : null
            )

        // setError_WorkSetup(!workSetup ? 'Work setup is required' : null)
        // setError_EmploymentType(
        //     !employmentType ? 'Employment type is required' : null
        // )

        // setError_ExperienceRequired(
        //     !experienceRequired ? 'Amount of experience is required' : null
        // )
        // setError_SalaryRange(!salaryRange ? 'Salary range is required' : null)

        if (isConnect) {
            if (
                !jobFunction ||
                skills?.length < 1 ||
                !jobTitle ||
                !responsibilities ||
                !workSetup ||
                !employmentType ||
                !experienceRequired ||
                !salaryRange ||
                error_ExpectedStartDate ||
                !companyName
            ) {
                return false
            } else return true
        } else {
            if (
                !jobFunction ||
                skills?.length < 1 ||
                !jobTitle ||
                !responsibilities ||
                !workSetup ||
                !employmentType ||
                !experienceRequired ||
                !salaryRange ||
                error_ExpectedStartDate
            ) {
                return false
            } else return true
        }
    }

    const validateFields = () => {
        let isValid = []
        if (!jobTitle) {
            setErrors({ ...errors, jobTitle: 'Required' })
            isValid.push(false)
        } else {
            setErrors({ ...errors, jobTitle: false })
            isValid.push(true)
        }
        if (startDate < moment().format('YYYY-MM-DD')) {
            setStartDateError(true)
            isValid.push(false)
        } else {
            setStartDateError(false)
            isValid.push(true)
        }
        if (!jobFunction) {
            setJobFunctionError(true)
            isValid.push(false)
        } else {
            setJobFunctionError(false)
            isValid.push(true)
        }
        if (skills.length < 1) {
            setSkillsError(true)
            isValid.push(false)
        } else {
            setSkillsError(false)
            isValid.push(true)
        }
        if (!responsibilities) {
            setResponsibilitiesError(true)
            isValid.push(false)
        } else {
            setResponsibilitiesError(false)
            isValid.push(true)
        }
        if (!currency) {
            setCurrencyError(true)
            isValid.push(false)
        } else {
            setCurrencyError(false)
            isValid.push(true)
        }
        if (!salaryMin) {
            setSalaryMinError(true)
            isValid.push(false)
        } else {
            setSalaryMinError(false)
            isValid.push(true)
        }
        if (!salaryMax) {
            setSalaryMaxError(true)

            isValid.push(false)
        } else {
            setSalaryMaxError(false)
            isValid.push(true)
        }
        if (workSetup.length < 1) {
            setWorkSetupError(true)
            isValid.push(false)
        } else {
            setWorkSetupError(false)
            isValid.push(true)
        }
        if (employmentType.length < 1) {
            setEmploymentTypeError(true)
            isValid.push(false)
        } else {
            setEmploymentTypeError(false)
            isValid.push(true)
        }
        return isValid.includes(false) ? false : true
    }

    const addCommas = (num) =>
        num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, '')

    const removeComma = (num) => {
        let copy = num?.toString()
        let num1
        if (copy?.indexOf(',') != -1) {
            num1 = parseFloat(num?.replace(/,/g, ''))
            return num1
        } else {
            return parseFloat(copy)
        }
    }

    const handleSalaryMin = (event) =>
        setSalaryMin(addCommas(removeNonNumeric(event.target.value)))

    const handleSalaryMax = (event) =>
        setSalaryMax(addCommas(removeNonNumeric(event.target.value)))

    const [salaryMinTemp, setSalaryMinTemp] = useState(null)
    const [salaryMaxTemp, setSalaryMaxTemp] = useState(null)

    useEffect(() => {
        setSalaryMinTemp(addCommas(salaryMin))
        setSalaryMaxTemp(addCommas(salaryMax))
    }, [salaryMin, salaryMax])

    const saveChanges = (val) => {
        const isConnect = User?.user?.email === 'connect@joblu.io'
        const draft = location_?.state?.job?.isDraft;

        let sMin = removeComma(salaryMin)
        let sMax = removeComma(salaryMax)

        const valid = !val && !!draft ? true : validateFields()

        if (!valid) {
            console.log('error')
            return
        }
        
        if (val && !validate() && !draft) {
            console.log('error')
            return
        }

        setIsLoading(true)

        let salary = salaryRange.split('-')
        let experience = experienceRequired.split('-')
        let newStartDate = new Date(startDate)

        let payload = {
            // companyName: User?.user?.companyName,
            totalExperienceMin: parseInt(experience[0]),
            totalExperienceMax: parseInt(experience[1]),
            salaryMin: sMin,
            salaryMax: sMax,
            isDraft: !!val ? false : location_?.state?.job?.isDraft,
            isPublished: !!val ? true : location_?.state?.job?.isPublished,
            status: 'active',
            jobTitle,
            technicalSkills: skills.filter(
                (item) => item !== '' && item !== null
            ),
            salaryCurrency: currency,
            employmentType,
            jobFunction,
            expectedStartDate: newStartDate,
            responsibilities: responsibilities,
            workSetup,
            education,
            perksAndBenefits: benefits,
        }

        if (isConnect) {
            payload['companyName'] = companyName
        }

        jobsRequest
            .updateJob(location_?.state?.job?.id, payload)
            .then((res) => {
                setTimeout(() => {
                    setShowAlert(true)
                }, 1000)
                setTimeout(() => {
                    // setShowAlert(false)
                    setIsLoading(false)
                    navigate('/jobs')
                }, 2000)
            })
            .catch((e) => {
                console.log(e.response.data.message)
            })
            .finally(() => {
                // setTimeout(() => {
                // setIsLoading(false)
                // }, 1000)
            })
    }

    const publishNow = () => {
        const isConnect = User?.user?.email === 'connect@joblu.io'

        if (!validate()) {
            return
        }

        setIsLoading(true)

        let salary = salaryRange.split('-')
        let experience = experienceRequired.split('-')

        let payload = {
            totalExperienceMin: parseInt(experience[0]),
            totalExperienceMax: parseInt(experience[1]),
            salaryMin: parseInt(salary[0]),
            salaryMax: parseInt(salary[1]),
            isDraft: false,
            isPublished: true,
            status: 'active',
            jobTitle: jobTitle,
            skills: skills.filter((item) => item !== '' && item !== null),
            employmentType: employmentType,
            jobFunction: jobFunction,
            responsibilities: responsibilities,
            workSetup: workSetup,
            food: food,
            housing: housing,
            transport: transport,
            workWeek: workWeek,
            workShift: workShift,
            expectedStartDate: expectedStartDate,
            educationAndExperience: educationAndExperience,
            location: location,
        }

        if (isConnect) {
            payload['companyName'] = companyName
        }

        jobsRequest
            .updateJob(location_?.state?.job?.id, payload)
            .then((res) => {
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    navigate('/jobs')
                }, 2000)
            })
            .catch((e) => {
                console.log(e.response.data.message)
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000)
            })
    }

    const addSkill = () => {
        if (!!tempSkill) {
            setSkills([...skills, tempSkill])

            setTempSkill('')
        } else {
            console.log('no skill entered')
            setTempSkill('')
        }
    }

    useEffect(() => {
        if (tempSkill.length === 20) {
            addSkill()
        }
        var input = document.getElementById('skillInputEditjob')

        if (tempSkill.length <= 20 && tempSkill.length >= 2) {
            // Execute a function when the user presses a key on the keyboard
            input.addEventListener('keyup', function (event) {
                // If the user presses the "Enter" key on the keyboard
                if (event.key === 'Enter') {
                    // Cancel the default action, if needed
                    event.preventDefault()
                    // Trigger the button element with a click
                    addSkill()
                }
            })
        } else {
            return
        }
    }, [tempSkill])

    const removeSkill = (idx) => {
        setSkills(skills.filter((item, index) => index !== idx))
    }

    const handleEducationCheck = (e) => {
        var educationCopy = [...education]
        if (e.target.checked) {
            educationCopy = [...education, e.target.value]
        } else {
            educationCopy.splice(education.indexOf(e.target.value), 1)
        }
        setEducation(educationCopy)
    }

    const handleWorkSetupCheck = (e) => {
        var workSetupCopy = [...workSetup]
        if (e.target.checked) {
            workSetupCopy = [...workSetup, e.target.value]
        } else {
            workSetupCopy.splice(workSetup.indexOf(e.target.value), 1)
        }
        setWorkSetup(workSetupCopy)
    }

    const handleEmploymentTypeCheck = (e) => {
        var employmentTypeCopy = [...employmentType]
        if (e.target.checked) {
            employmentTypeCopy = [...employmentType, e.target.value]
        } else {
            employmentTypeCopy.splice(employmentType.indexOf(e.target.value), 1)
        }
        setEmploymentType(employmentTypeCopy)
    }

    const handleBenefitsCheck = (e) => {
        var benefitsCopy = [...benefits]
        if (e.target.checked) {
            benefitsCopy = [...benefits, e.target.value]
        } else {
            benefitsCopy.splice(benefits.indexOf(e.target.value), 1)
        }
        setBenefits(benefitsCopy)
    }
    return (
        <div className="create-job-bg" style={{ overFlowY: 'scroll' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="card mt-5 create-job-card">
                            <div className="card-body p-3 mb-md-5">
                                <div className="mb-3">
                                    <p
                                        className="hand"
                                        onClick={() => {
                                            navigate(
                                                `/jobs/${location_?.state?.job?.id}`,
                                                {
                                                    state: {
                                                        job: location_?.state
                                                            ?.job,
                                                    },
                                                }
                                            )
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-arrow-left me-2"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                            />
                                        </svg>
                                        {jT}
                                    </p>
                                </div>
                                <h4 className="text-muted mb-3">
                                    <strong
                                        onClick={() => {
                                            console.log(location_?.state?.job)
                                        }}
                                    >
                                        Edit Job Post
                                    </strong>
                                </h4>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Job Title</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>
                                    <TextField
                                        type="text"
                                        required
                                        id="jobTitle"
                                        name="jobTitle"
                                        limit={30}
                                        placeholder="E.g “Graphic Designer for Social Media Marketing”"
                                        onChange={(e) =>
                                            setJobTitle(e.target.value)
                                        }
                                        className="mb-2"
                                        error={errors.jobTitle}
                                        defaultValue={jobTitle}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Job Function</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>
                                    <SelectJobFunction
                                        onChange={(value) => {
                                            setJobFunction(value)
                                        }}
                                        name="jobFunction"
                                        defaultValue={jobFunction}
                                        value={jobFunction}
                                        error={error_JobFunction}
                                        noAll
                                    />
                                    {jobFunctionError && (
                                        <span className="error-helper">
                                            Required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>
                                            What technical skills do you
                                            require?{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </strong>
                                        <small className="text-muted d-block">
                                            Here are the skills related to your
                                            selected industry. You can remove or
                                            add more if the skills you are
                                            looking for are not in the
                                            suggestions.
                                        </small>
                                    </label>
                                    <div
                                        className={`form-control d-flex flex-wrap ${
                                            error_Skills
                                                ? 'text-danger border error border-danger'
                                                : null
                                        } ${skillsError && 'border-danger'}`}
                                        id=""
                                    >
                                        {skills?.map((skill, index) => {
                                            return (
                                                <div className="skills-in-box d-flex p-1">
                                                    <span
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                        className="open-sans-body ps-1"
                                                    >
                                                        {skill}
                                                    </span>
                                                    <div
                                                        className="ms-2 me-1 pe-1 open-sans-body"
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                        role="button"
                                                        onClick={() =>
                                                            removeSkill(index)
                                                        }
                                                    >
                                                        x
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <textarea
                                            type="text"
                                            className={`flex-grow-1 skills-input `}
                                            onChange={(e) => {
                                                setTempSkill(e.target.value)
                                            }}
                                            value={tempSkill}
                                            id="skillInputEditjob"
                                            rows={5}
                                        ></textarea>
                                    </div>
                                    {skillsError && (
                                        <span className="error-helper">
                                            Required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Responsibilities</strong>{' '}
                                        <span className="required-tag">*</span>
                                        <small className="text-muted d-block">
                                            What does the day-to-day of this job
                                            looks like?
                                        </small>
                                    </label>
                                    <textarea
                                        name=""
                                        className={`form-control br-3 ${
                                            responsibilitiesError &&
                                            'border-danger'
                                        }`}
                                        id=""
                                        cols="30"
                                        rows="5"
                                        onChange={(e) => {
                                            setResponsibilities(e.target.value)
                                        }}
                                        defaultValue={responsibilities}
                                    ></textarea>
                                    {responsibilitiesError && (
                                        <span className="error-helper">
                                            Required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Estimated Start Date</strong>
                                    </label>
                                    <input
                                        type="date"
                                        value={startDate ? startDate : null}
                                        className={`form-control disabled ${
                                            startDateError && 'border-danger'
                                        }`}
                                        onChange={(e) => {
                                            setStartDate(e.target.value)
                                        }}
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                    {startDateError && (
                                        <span className="error-helper">
                                            Invalid date
                                        </span>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Salary (Range)</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-2 mb-4 mb-md-0">
                                            <select
                                                name=""
                                                id=""
                                                className={`form-select br-3 ${
                                                    currencyError &&
                                                    'border-danger'
                                                }`}
                                                onChange={(e) => {
                                                    setCurrency(e.target.value)
                                                }}
                                                defaultValue={currency}
                                            >
                                                <option value="">
                                                    Currency
                                                </option>
                                                <option value="PHP">PHP</option>
                                                <option value="SGD">SGD</option>
                                                <option value="USD">USD</option>
                                            </select>
                                            {currencyError && (
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-md-5 mb-4 mb-md-0">
                                            <input
                                                type="text"
                                                className={`form-control br-3 ${
                                                    salaryMinerror &&
                                                    'border-danger'
                                                }`}
                                                placeholder="Minimum"
                                                onChange={handleSalaryMin}
                                                value={salaryMinTemp}
                                            />
                                            {salaryMinerror && (
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-md-5">
                                            <input
                                                type="text"
                                                className={`form-control br-3 ${
                                                    salaryMaxerror &&
                                                    'border-danger'
                                                }`}
                                                placeholder="Maximum"
                                                onChange={handleSalaryMax}
                                                value={salaryMaxTemp}
                                            />
                                            {salaryMaxerror && (
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Country or Location</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control br-3"
                                        placeholder="E.g. “Makati, Philippines”"
                                        onChange={(e) => {
                                            setCountry(e.target.value)
                                        }}
                                    />
                                </div> */}
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Years of Experience</strong>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="noExp"
                                                    value="0-1"
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                    defaultChecked={
                                                        experience == '0-0'
                                                    }
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="noExp"
                                                >
                                                    0 - 1 year
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="1-3"
                                                    value="1-3"
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                    defaultChecked={
                                                        experience == '1-3'
                                                    }
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="1-3"
                                                >
                                                    1 - 3 years
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="3-5"
                                                    value="3-5"
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                    defaultChecked={
                                                        experience == '3-5'
                                                    }
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="3-5"
                                                >
                                                    3 - 5 years
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="5-7"
                                                    value="5-7"
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                    defaultChecked={
                                                        experience == '5-7'
                                                    }
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="5-7"
                                                >
                                                    5 - 7 years
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="7above"
                                                    value="7-100 "
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                    defaultChecked={
                                                        experience == '7-100'
                                                    }
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="7above"
                                                >
                                                    7 years and above
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Education</strong>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Doctorate (PhD)"
                                                    id="phd"
                                                    defaultChecked={education.includes(
                                                        'Doctorate (PhD)'
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="phd"
                                                >
                                                    Doctorate (PhD)
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Master's Degree"
                                                    id="masters"
                                                    defaultChecked={education.includes(
                                                        "Master's Degree"
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="masters"
                                                >
                                                    Master’s Degree
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Bachelor's Degree"
                                                    id="bachelors"
                                                    defaultChecked={education.includes(
                                                        "Bachelor's Degree"
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="bachelors"
                                                >
                                                    Bachelor’s Degree
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Diploma"
                                                    id="diploma"
                                                    defaultChecked={education.includes(
                                                        'Diploma'
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="diploma"
                                                >
                                                    Diploma
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="High School"
                                                    id="highschool"
                                                    defaultChecked={education.includes(
                                                        'High School'
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="highschool"
                                                >
                                                    High School
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Work Setup</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>

                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="On-site"
                                                    id="on-site"
                                                    defaultChecked={workSetup.includes(
                                                        'On-site'
                                                    )}
                                                    onChange={(e) => {
                                                        handleWorkSetupCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="on-site"
                                                >
                                                    On-Site
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Remote"
                                                    id="remote"
                                                    defaultChecked={workSetup.includes(
                                                        'Remote'
                                                    )}
                                                    onChange={(e) => {
                                                        handleWorkSetupCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="remote"
                                                >
                                                    Remote
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Hybrid"
                                                    id="hybrid"
                                                    defaultChecked={workSetup.includes(
                                                        'Hybrid'
                                                    )}
                                                    onChange={(e) => {
                                                        handleWorkSetupCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="hybrid"
                                                >
                                                    Hybrid
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {workSetupError && (
                                        <div>
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Employment Type</strong>
                                        <span className="required-tag">*</span>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Full-Time"
                                                    id="full-time"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                    defaultChecked={employmentType?.includes(
                                                        'Full-Time'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="full-time"
                                                >
                                                    Full-Time
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Part-Time"
                                                    id="part-time"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                    defaultChecked={employmentType?.includes(
                                                        'Part-Time'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="part-time"
                                                >
                                                    Part-Time
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Project-Based"
                                                    id="project-based"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                    defaultChecked={employmentType?.includes(
                                                        'Project-Based'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="project-based"
                                                >
                                                    Project-Based
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Freelance"
                                                    id="freelance"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                    defaultChecked={employmentType?.includes(
                                                        'Freelance'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="freelance"
                                                >
                                                    Freelance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Internship"
                                                    id="intern"
                                                    defaultChecked={employmentType?.includes(
                                                        'Internship'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="intern"
                                                >
                                                    Internship
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Highschool Internship"
                                                    id="highschool-intern"
                                                    defaultChecked={workSetup?.includes(
                                                        'Highschool Internship'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="highschool-intern"
                                                >
                                                    Highschool Internship
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {employmentTypeError && (
                                        <div>
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Perks and Benefits</strong>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Meal Allowance"
                                                    id="meal"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Meal Allowance'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="meal"
                                                >
                                                    Meal Allowance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Trainings & Seminars"
                                                    id="trainings"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Trainings & Seminars'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="trainings"
                                                >
                                                    Trainings & Seminars
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Employee Stock Options"
                                                    id="stocks"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Employee Stock Options'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="stocks"
                                                >
                                                    Employee Stock Options
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Flexible Schedule"
                                                    id="flexible"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Flexible Schedule'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="flexible"
                                                >
                                                    Flexible Schedule
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Fuel Discount"
                                                    id="fuel"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Fuel Discount'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="fuel"
                                                >
                                                    Fuel Discount
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Reimbursed Parking Fee"
                                                    id="reimburseParking"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Reimbursed Parking Fee'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="reimburseParking"
                                                >
                                                    Reimbursed Parking Fee
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Medical Insurance"
                                                    id="medical"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Reimbursed Parking Fee'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="medical"
                                                >
                                                    Medical Insurance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Gym Membership"
                                                    id="gym"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Gym Membership'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="gym"
                                                >
                                                    Gym Membership
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Life Insurance"
                                                    id="lifeInsurance"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Life Insurance'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="lifeInsurance"
                                                >
                                                    Life Insurance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=" On-site Parking"
                                                    id="parking"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'On-site Parking'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="parking"
                                                >
                                                    On-site Parking
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Opportunities for Promotion"
                                                    id="promotion"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Opportunities for Promotion'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="promotion"
                                                >
                                                    Opportunities for Promotion
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Paid Time-Out"
                                                    id="paidOut"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Paid Time-Out'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="paidOut"
                                                >
                                                    Paid Time-Out
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Internet Allowance"
                                                    id="internet"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Internet Allowance'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="internet"
                                                >
                                                    Internet Allowance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Government Mandated Benefits"
                                                    id="govertmentBenefits"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Government Mandated Benefits'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="govertmentBenefits"
                                                >
                                                    Government Mandated Benefits
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Transportation Allowance"
                                                    id="transport"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Transportation Allowance'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="transport"
                                                >
                                                    Transportation Allowance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Promotion to Permanent Employee"
                                                    id="permanent"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Promotion to Permanent Employee'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="permanent"
                                                >
                                                    Promotion to Permanent
                                                    Employee
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Company Car"
                                                    id="companyCar"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'Company Car'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="companyCar"
                                                >
                                                    Company Car
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="More"
                                                    id="more"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                    defaultChecked={benefits.includes(
                                                        'More'
                                                    )}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="more"
                                                >
                                                    And more!
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="d-flex flex-column flex-md-row">
                                        {location_?.state?.job?.isDraft && (
                                            <button
                                                className="btn btn-primary w-100 mb-2 mb-md-0 me-0 me-md-2 text-center"
                                                onClick={() => {
                                                    saveChanges('publish')
                                                }}
                                            >
                                                Publish Now
                                            </button>
                                        )}
                                        <button
                                            className="btn btn-primary w-100 mb-2 mb-md-0 me-0 me-md-2 text-center"
                                            onClick={() => {
                                                saveChanges()
                                            }}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className="btn btn-outline-primary w-100 text-center"
                                            onClick={() => {
                                                navigate('/jobs')
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {/* <div>
                                    <a className="text-info hand">
                                        Discard this job post.
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
export default connect(mapStateToProps, null)(CreateJob)
