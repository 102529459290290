import { Box, Button, Chip, Grid } from "@material-ui/core";
import moment from "moment/moment";
import { connect } from "react-redux";
import educationIcon from "../../../../assets/icons/EducationIcon.png";
import workShiftIcon from "../../../../assets/icons/EmploymentTypeIcon.png";
import workHistoryIcon from "../../../../assets/icons/ExperienceIcon.png";
import appliedDateIcon from "../../../../assets/icons/icon-v2_applied date.svg";
import locationIcon from "../../../../assets/icons/LocationIcon.png";
import salaryIcon from "../../../../assets/icons/SalaryIcon.png";
import workSetupIcon from "../../../../assets/icons/WorkSetupIcon.png";
//interviews icon imports
import startDateIcon from "../../../../assets/icons/icon-v2_start date.svg";
import workHoursIcon from "../../../../assets/icons/icon-v2_work hours.svg";
//end-interviews
import { Dialog, Menu, MenuItem } from "@material-ui/core";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DefaultImg from "../../../../assets/img/defaultImg.png";
import ActionIcon from "../../../../assets/img/matches/ActionIcon.png";
import DismissIcon from "../../../../assets/img/matches/DismissIcon.png";
import JoinIcon from "../../../../assets/img/matches/JoinIcon.png";
import LikeIcon from "../../../../assets/img/matches/LikeIcon.png";
import LinkIcon2 from "../../../../assets/img/matches/LinkIcon2.png";
import MessageIcon2 from "../../../../assets/img/matches/MessageIcon2.png";
import TechnicalSkillsIcon from "../../../../assets/img/matches/TechnicalSkillsIcon.png";
import UnmatchIcon from "../../../../assets/img/matches/UnmatchIcon.png";
import JobDetailsModal from "../../../../components/modals/JobDetailsModal";
import JobluTypography from "../../../../components/Typography";
import { app_styles } from "../../../../general.styles";
import { jobsRequest } from "../../../../service/requests";
import { salaryRanges } from "../../../../utils/constants";
import {
  formatDateOnlyv3,
  formatMoney,
  getInterviewTime,
  getMeetingProviderDetails,
  getSwipeActionLabel,
  getVideoCallLink,
  titleCase,
} from "../../../../utils/helpers";
import useWindowDimensions from "../../../../utils/hooks";
import { card_styles } from "./styles";
export const SLIDE_CLASSES = {
  SLIDE_LEFT: "slide-out-left",
  SLIDE_RIGHT: "slide-out-right",
  SLIDE_TOP: "slide-out-top",
};

export const SWIPE_ACTIONS = {
  LIKE: "like",
  DISMISS: "not_interested",
  SUPERLIKE: "superLike",
};
const JobCardList = ({
  job,
  showAppliedDate = true,
  showEmployerAction,
  isInterviews,
  isApplied,
  AppState,
  isShortlisted,
  getApplicantAppliedJobs,
  getApplicantShortlistedJobs,
  isHired,
  handleSwipe,
  card_index,
  ...props
}) => {
  const navigate = useNavigate();
  const { currencyList, currency } = AppState;
  const currencyOptions = {
    currencyList,
    currency,
  };

  const { current_page, searchText, setCurrentPage } = props;

  const { width } = useWindowDimensions();
  const styles = card_styles(props);
  const app_classes = app_styles(props);
  const [view_job, setViewJob] = useState(null);

  const copyLink = (data) => {
    navigator.clipboard.writeText(data);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const unmatchJob = () => {
    const payload = {
      jobMatchId: job?._id,
    };

    console.log(payload);
    jobsRequest.unmatchJob(payload).then(() => {
      if (isApplied) {
        getApplicantAppliedJobs();
        setCurrentPage(1);
        setShowConfirmModal(false);
      }
      if (isShortlisted) {
        getApplicantShortlistedJobs();
        setCurrentPage(1);
        setShowConfirmModal(false);
      }
    });
  };

  const handleGoToMessage = (data) => {
    navigate("/messages", {
      state: { data },
    });
  };
  return (
    <>
      <Box
        id={`card-matches-class-${card_index}`}
        style={{ position: "relative", padding: 3, cursor: "pointer" }}
        onClick={(e) => {
          if (job?.matchJob) {
            setViewJob({
              ...job?.matchJob,
              employer: job?.matchEmployer,
              matchStatus: job?.matchStatus,
            });
          } else {
            setViewJob(job);
          }
        }}
      >
        <Dialog
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          maxWidth="xs"
          fullWidth
        >
          <Box
            className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          >
            <JobluTypography.H5 align="center">Unmatch Job</JobluTypography.H5>
          </Box>
          <Box
            className={`${app_classes.text.center} ${app_classes.padding.a4}`}
          >
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.H6>
                You are about to{" "}
                <span className={`${app_classes.text.danger}`}>unmatch</span>,
              </JobluTypography.H6>
            </Box>
            <Box className={`${app_classes.text.center}`}>
              <img
                src={
                  job?.matchJob?.companyLogo
                    ? job?.matchJob?.companyLogo
                    : job?.matchEmployer?.profileUrl
                    ? job?.matchEmployer?.profileUrl
                    : DefaultImg
                }
                className={`${styles.job_image} ${app_classes.margin.b1} ${app_classes.general.centered_x}`}
                alt=""
                style={{ objectFit: "cover", background: "#FFFFFF" }}
              />
            </Box>
            <Box className={`${app_classes.text.secondary}`}>
              <JobluTypography.H5
                semiBold
                className={`${app_classes.text.capitalize}`}
              >
                {job?.matchJob?.jobTitle}
              </JobluTypography.H5>
            </Box>
            <Box className={`${app_classes.margin.b2} `}>
              <JobluTypography.Body
                semiBold
                className={`${app_classes.text.capitalize}`}
              >
                {job?.matchJob?.companyName || job?.matchEmployer?.companyName}
              </JobluTypography.Body>
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.H6>
                would you like to proceed?
              </JobluTypography.H6>
            </Box>
            <Box>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    className={`${app_classes.button.primary_outline}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowConfirmModal(false);
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Box className={`${app_classes.width}`}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={`${app_classes.button.primary} ${app_classes.width.w100}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        unmatchJob();
                      }}
                    >
                      Yes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Dialog>
        <Box
          style={{
            // height: width > 640 ? 200 : 300,
            // overflowY: "hidden",
            position: "relative",
          }}
          className={`${app_classes.border.border_all} `}
        >
          <Box
            className={`${app_classes.padding.a3} ${app_classes.margin.b2}${app_classes.border.border_radius_15}`}
          >
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box
                      width={120}
                      className={`${app_classes.display.display_flex} ${
                        width < 640
                          ? app_classes.flexBox.flex_direction_column
                          : app_classes.flexBox.flex_direction_row
                      } ${app_classes.width.w100}`}
                    >
                      <Box
                        className={`${width > 640 && app_classes.margin.r2} ${
                          app_classes.text.center
                        }  ${width < 640 && app_classes.margin.b2}`}
                        style={{
                          position: "relative",
                          minWidth: width > 640 && "113px",
                        }}
                      >
                        {width < 640 && (
                          <img
                            src={ActionIcon}
                            style={{
                              width: 40,
                              position: "absolute",
                              right: 0,
                            }}
                            id="basic-button"
                            aria-controls={
                              openDropdown ? "basic-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openDropdown ? "true" : undefined}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClick();
                            }}
                            className={`${app_classes.cursor.pointer} ${app_classes.margin.b1}`}
                          />
                        )}
                        <img
                          src={
                            job?.matchJob?.companyLogo
                              ? job?.matchJob?.companyLogo
                              : job?.matchEmployer?.profileUrl
                              ? job?.matchEmployer?.profileUrl
                              : DefaultImg
                          }
                          className={`${styles.job_image} ${app_classes.margin.b1} ${app_classes.general.centered_x}`}
                          alt=""
                          style={{ objectFit: "cover", background: "#FFFFFF" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(
                              `/company-showcase/${
                                job?.matchEmployer?.userName ||
                                job?.matchEmployer?.userId
                              }`
                            );
                          }}
                        />
                        {isShortlisted && job?.matchStatus == "waiting" && (
                          <>
                            <Box>
                              <Chip
                                label={
                                  <JobluTypography.Caption>
                                    Awaiting Match
                                  </JobluTypography.Caption>
                                }
                                variant="outlined"
                                className={`${styles.chip_outline}`}
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box className={`${app_classes.width.w100}`}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={6}>
                            <Box>
                              <JobluTypography.H6
                                semiBold
                                primary
                                className={`${app_classes.text.capitalize}`}
                                onClick={() => {
                                  console.log(job);
                                }}
                              >
                                {job.matchJob.jobTitle}
                              </JobluTypography.H6>
                            </Box>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.Body semiBold primary>
                                {job.matchJob.companyName
                                  ? job.matchJob.companyName
                                  : job.matchEmployer.companyName}
                              </JobluTypography.Body>
                            </Box>
                            <Box
                              className={`${app_classes.display.display_flex} ${app_classes.margin.b1}`}
                            >
                              <img
                                src={locationIcon}
                                alt=""
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: 5,
                                }}
                                className={app_classes.general.color_secondary}
                              />
                              <JobluTypography.Body>
                                {job?.matchJob?.location || "NA"}
                              </JobluTypography.Body>
                            </Box>
                            {job?.matchJob?.workSetup?.length > 0 &&
                              job?.matchJob?.workSetup != "TBD" && (
                                <>
                                  <Box
                                    className={`${app_classes.display.display_flex} ${app_classes.width.w100}`}
                                  >
                                    <img
                                      src={workSetupIcon}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: 5,
                                      }}
                                      className={
                                        app_classes.general.color_secondary
                                      }
                                    />
                                    <JobluTypography.Body>
                                      {job?.matchJob?.workSetup?.join(", ") ||
                                        "N/A"}
                                    </JobluTypography.Body>
                                  </Box>
                                </>
                              )}
                          </Grid>
                          <Grid item xs={6}>
                            {job?.matchJob?.employmentType?.length > 0 &&
                              job?.matchJob?.employmentType != "TBD" && (
                                <>
                                  <Box
                                    className={`${app_classes.display.display_flex} ${app_classes.margin.b1}`}
                                  >
                                    <img
                                      src={workShiftIcon}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: 5,
                                      }}
                                      className={
                                        app_classes.general.color_secondary
                                      }
                                    />
                                    <JobluTypography.Body>
                                      {job?.matchJob?.employmentType?.join(
                                        ", "
                                      ) || "N/A"}
                                    </JobluTypography.Body>
                                  </Box>
                                </>
                              )}
                            {job?.matchJob?.education?.length > 0 &&
                              job?.matchJob?.education != "TBD" && (
                                <>
                                  <Box
                                    className={`${app_classes.display.display_flex} ${app_classes.margin.b1}`}
                                  >
                                    <img
                                      src={educationIcon}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: 5,
                                      }}
                                      className={
                                        app_classes.general.color_secondary
                                      }
                                    />
                                    <JobluTypography.Body>
                                      {job?.matchJob?.education?.join(", ") ||
                                        "N/A"}
                                    </JobluTypography.Body>
                                  </Box>
                                </>
                              )}
                            {job?.matchJob?.totalExperienceMin != null &&
                              job?.matchJob?.totalExperienceMin !== 0 && (
                                <>
                                  <Box
                                    className={`${app_classes.display.display_flex} ${app_classes.margin.b1}`}
                                  >
                                    <img
                                      src={workHistoryIcon}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: 5,
                                      }}
                                      className={
                                        app_classes.general.color_secondary
                                      }
                                    />
                                    <JobluTypography.Body>
                                      {job?.matchJob?.totalExperienceMin &&
                                        job?.matchJob?.totalExperienceMin !==
                                          0 &&
                                        `${job?.matchJob?.totalExperienceMin}`}
                                      {job?.matchJob?.totalExperienceMax ==
                                      "100"
                                        ? "+ Years"
                                        : `- ${job?.matchJob?.totalExperienceMax} Years`}
                                    </JobluTypography.Body>
                                  </Box>
                                </>
                              )}

                            {job?.matchJob?.salaryMin &&
                              job?.matchJob?.salaryMax && (
                                <>
                                  {/* salary */}
                                  <Box
                                    className={`${app_classes.display.display_flex} ${app_classes.margin.b1}`}
                                  >
                                    <img
                                      src={salaryIcon}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: 5,
                                      }}
                                      className={
                                        app_classes.general.color_secondary
                                      }
                                    />
                                    <JobluTypography.Body>
                                      {job?.matchJob?.salaryMin === 1 &&
                                      job?.matchJob?.salaryMax === 1
                                        ? salaryRanges.COMPETIIVE_SALARY
                                        : job?.matchJob?.salaryMin === 2 &&
                                          job?.matchJob?.salaryMax === 2
                                        ? salaryRanges.INDUSTRY_STANDARD
                                        : `${formatMoney(
                                            job?.matchJob?.salaryMin,
                                            currencyOptions,
                                            job?.matchJob?.salaryCurrency ||
                                              "SGD"
                                          )} - ${formatMoney(
                                            job?.matchJob?.salaryMax,
                                            currencyOptions,
                                            job?.matchJob?.salaryCurrency ||
                                              "SGD"
                                          )}`}
                                    </JobluTypography.Body>
                                  </Box>
                                </>
                              )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                {job.matchJob?.technicalSkills?.length > 0 && (
                  <>
                    <Box
                      sx={{ display: "flex" }}
                      className={`${app_classes.margin.b1}`}
                    >
                      <img
                        src={TechnicalSkillsIcon}
                        alt=""
                        style={{ width: 16, height: 16, marginRight: 5 }}
                        className={app_classes.general.color_secondary}
                      />
                      <JobluTypography.Body semiBold primary>
                        Technical Skills
                      </JobluTypography.Body>
                    </Box>
                    <Box>
                      {job.matchJob?.technicalSkills?.map((skill) => {
                        return (
                          <Chip
                            label={skill}
                            className={`${styles.action_chip}`}
                          />
                        );
                      })}
                    </Box>
                  </>
                )}

                {/* <JobluTypography.Body
              semiBold
              className={`${app_classes.cursor.pointer} ${app_classes.text.underline}`}
              onClick={() => {
                if (job?.matchJob) {
                  setViewJob({
                    ...job?.matchJob,
                    employer: job?.matchEmployer,
                  });
                } else {
                  setViewJob(job);
                }
              }}
            >
              See more
            </JobluTypography.Body> */}
              </Grid>
              <Grid item xs={12} md={2}>
                <Box
                  className={`${app_classes.text.right} ${app_classes.display.display_flex} ${app_classes.flexBox.flex_direction_column} ${app_classes.height.h100} ${app_classes.flexBox.justify_content_between}`}
                >
                  <Box>
                    <Box
                      className={`${app_classes.display.display_flex} ${
                        app_classes.flexBox.flex_direction_row
                      } ${
                        width < 640
                          ? app_classes.flexBox.justify_content_center
                          : app_classes.flexBox.justify_content_right
                      }`}
                    >
                      {isShortlisted && job?.matchStatus == "waiting" && (
                        <>
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              setViewJob(false);
                              handleSwipe(
                                job?.matchJob,
                                SWIPE_ACTIONS.DISMISS,
                                card_index,
                                SLIDE_CLASSES.SLIDE_LEFT
                              );
                            }}
                            src={DismissIcon}
                            className={`${app_classes.margin.r1} ${app_classes.cursor.pointer} ${app_classes.margin.b1}`}
                            style={{ width: 40 }}
                          />
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              console.log("LIKE");
                              console.log(job?.matchJob);
                              setViewJob(false);
                              handleSwipe(
                                job?.matchJob,
                                SWIPE_ACTIONS.LIKE,
                                card_index,
                                null,
                                job?.companyLogo
                                  ? job?.companyLogo
                                  : job?.employer?.profileUrl
                                  ? job?.employer?.profileUrl
                                  : DefaultImg,
                                job?.matchEmployer
                              );
                            }}
                            src={LikeIcon}
                            className={`${
                              width > 640 && app_classes.margin.r1
                            } ${app_classes.cursor.pointer} ${
                              app_classes.margin.b1
                            }`}
                            style={{ width: 40, background: "#FFFFFF" }}
                          />
                        </>
                      )}

                      <div
                        className="dropdown d-none d-md-block"
                        style={{ zIndex: 999 }}
                      >
                        <div className="text-end">
                          <span
                            className="hand text-end"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={ActionIcon}
                              width={40}
                              className="mb-2"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </span>
                          <ul
                            class="dropdown-menu dropdown-menu-end"
                            style={{ width: 250 }}
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li className="text-center">
                              <span class="dropdown-header">
                                <JobluTypography.Body primary semiBold>
                                  Actions
                                </JobluTypography.Body>
                              </span>
                            </li>
                            <>
                              {!isApplied && job?.matchStatus != "waiting" && (
                                <li>
                                  <span
                                    class={`dropdown-item hand`}
                                    href="#"
                                    style={{
                                      fontSize: 12,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleGoToMessage(job);
                                    }}
                                  >
                                    <img
                                      src={MessageIcon2}
                                      className={`me-2`}
                                      style={{
                                        width: 40,
                                        height: 40,
                                      }}
                                      alt=""
                                    />
                                    Send Message
                                  </span>
                                </li>
                              )}

                              {isInterviews && job?.matchInterview && (
                                <>
                                  <li>
                                    <span
                                      class={`dropdown-item hand`}
                                      href="#"
                                      style={{
                                        fontSize: 12,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        copyLink(
                                          getVideoCallLink(job.matchInterview)
                                        );
                                        handleClose();
                                      }}
                                    >
                                      <img
                                        src={LinkIcon2}
                                        className={`me-2`}
                                        style={{
                                          width: 40,
                                          height: 40,
                                        }}
                                        alt=""
                                      />
                                      Copy Link
                                    </span>
                                  </li>
                                  <li>
                                    <Link
                                      target="_blank"
                                      to={getVideoCallLink(job.matchInterview)}
                                      style={{
                                        textDecoration: "none",
                                        color: "#27323c!important",
                                      }}
                                    >
                                      <span
                                        class={`dropdown-item hand`}
                                        href="#"
                                        style={{
                                          fontSize: 12,
                                        }}
                                      >
                                        <img
                                          src={JoinIcon}
                                          className={`me-2`}
                                          style={{
                                            width: 40,
                                            height: 40,
                                          }}
                                          alt=""
                                        />
                                        Join Meeting
                                      </span>
                                    </Link>
                                  </li>
                                </>
                              )}
                              {!isHired && (
                                <li>
                                  <span
                                    class={`dropdown-item hand`}
                                    href="#"
                                    style={{
                                      fontSize: 12,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowConfirmModal(true);
                                      handleClose();
                                    }}
                                  >
                                    <img
                                      src={UnmatchIcon}
                                      className={`me-2`}
                                      style={{
                                        width: 40,
                                        height: 40,
                                      }}
                                      alt=""
                                    />
                                    Unmatch
                                  </span>
                                </li>
                              )}
                            </>
                          </ul>
                        </div>
                      </div>
                      {/* {width > 640 && (
                    <>
                      <img
                        src={ActionIcon}
                        style={{ width: 40 }}
                        id="basic-button"
                        aria-controls={openDropdown ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openDropdown ? "true" : undefined}
                        onClick={handleClick}
                        className={`${app_classes.cursor.pointer} ${app_classes.margin.b1}`}
                      />
                    </>
                  )} */}

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openDropdown}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        PaperProps={{
                          elevation: 0,
                          width: 380,
                          sx: {
                            overflow: "visible",
                            width: "250px",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          className={`${app_classes.backgroundColor.bg_white}`}
                        >
                          <Box
                            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.width.w100}`}
                          >
                            <JobluTypography.Body
                              className={`${app_classes.text.center}`}
                              primary
                              semiBold
                            >
                              Actions
                            </JobluTypography.Body>
                          </Box>
                        </MenuItem>
                        {!isApplied && job?.matchStatus != "waiting" && (
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              handleGoToMessage(job?.matchApplicant);
                            }}
                          >
                            <Box
                              className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                            >
                              <Box className={app_classes.margin.r2}>
                                <img src={MessageIcon2} width={40} />
                              </Box>
                              <Box>
                                <JobluTypography.Body>
                                  Send Message
                                </JobluTypography.Body>
                              </Box>
                            </Box>
                          </MenuItem>
                        )}

                        {!isHired && (
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowConfirmModal(true);
                              handleClose();
                            }}
                          >
                            <Box
                              className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                            >
                              <Box className={app_classes.margin.r2}>
                                <img src={UnmatchIcon} width={40} />
                              </Box>
                              <Box>
                                <JobluTypography.Body>
                                  Unmatch
                                </JobluTypography.Body>
                              </Box>
                            </Box>
                          </MenuItem>
                        )}
                        {isInterviews && (
                          <>
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                copyLink(getVideoCallLink(job.matchInterview));
                                handleClose();
                              }}
                            >
                              <Box
                                className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                              >
                                <Box className={app_classes.margin.r2}>
                                  <img src={LinkIcon2} width={40} />
                                </Box>
                                <Box>
                                  <JobluTypography.Body>
                                    Copy Link
                                  </JobluTypography.Body>
                                </Box>
                              </Box>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              <Link
                                target="_blank"
                                to={getVideoCallLink(job.matchInterview)}
                                className={app_classes.margin.r1}
                                style={{
                                  textDecoration: "none",
                                  color: "#27323c!important",
                                }}
                              >
                                <Box
                                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                >
                                  <Box className={app_classes.margin.r2}>
                                    <img src={JoinIcon} width={40} />
                                  </Box>
                                  <Box>
                                    <JobluTypography.Body
                                      style={{ color: "#27323c!important" }}
                                    >
                                      Join Meeting
                                    </JobluTypography.Body>
                                  </Box>
                                </Box>
                              </Link>
                            </MenuItem>
                            {/* <MenuItem onClick={handleClose}>
                          <Box
                            className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                          >
                            <Box className={app_classes.margin.r2}>
                              <img src={CancelInterviewIcon} width={40} />
                            </Box>
                            <Box>
                              <JobluTypography.Body>
                                Cancel Interview
                              </JobluTypography.Body>
                            </Box>
                          </Box>
                        </MenuItem> */}
                          </>
                        )}
                      </Menu>
                    </Box>
                    {!isInterviews &&
                      showEmployerAction &&
                      job?.employer?.action === "superLike" && (
                        <Box className={`${app_classes.text.right}`}>
                          <Chip
                            className={styles.action_chip_warning}
                            label={getSwipeActionLabel(job?.employer?.action)}
                          />
                        </Box>
                      )}
                  </Box>
                  <Box
                    className={`${width < 640 && app_classes.text.center} ${
                      width < 640 && app_classes.margin.b1
                    }`}
                  >
                    {width > 640 && (
                      <Chip
                        label="See More"
                        variant="outlined"
                        style={{ zIndex: 998 }}
                        className={`${styles.chip_outline_seemore}  `}
                        onClick={() => {
                          if (job?.matchJob) {
                            setViewJob({
                              ...job?.matchJob,
                              employer: job?.matchEmployer,
                              matchStatus: job?.matchStatus,
                            });
                          } else {
                            setViewJob(job);
                          }
                        }}
                      />
                    )}
                    {showAppliedDate && !isInterviews && (
                      <Box
                        className={`${width < 640 && app_classes.margin.t2} ${
                          width < 640 && app_classes.padding.b2
                        } ${app_classes.display.display_flex} ${
                          app_classes.width.w100
                        } ${app_classes.height.h100} ${
                          app_classes.flexBox.align_items_bottom
                        } ${
                          width < 640
                            ? app_classes.flexBox.justify_content_center
                            : app_classes.flexBox.justify_content_right
                        }`}
                      >
                        <img
                          src={appliedDateIcon}
                          alt=""
                          style={{
                            width: "23px",
                            height: "23px",
                            marginRight: 5,
                          }}
                          className={app_classes.general.color_secondary}
                        />
                        <JobluTypography.Body>
                          Applied:{" "}
                          {moment(job?.createdAt).format("MMM D, YYYY")}
                        </JobluTypography.Body>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              {isInterviews && job?.matchInterview && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  justifyContent={width < 640 && "center"}
                  className={` ${app_classes.margin.b2}`}
                >
                  <Grid
                    item
                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                  >
                    <Box
                      className={`${app_classes.display.display_flex} ${app_classes.margin.r1}`}
                    >
                      <img
                        src={startDateIcon}
                        alt=""
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: 5,
                        }}
                        className={`${app_classes.general.color_secondary} ${app_classes.general.centered_y}`}
                      />
                      <JobluTypography.Body>
                        {formatDateOnlyv3(
                          job?.matchInterview?.data?.start?.dateTime
                        )}
                      </JobluTypography.Body>
                    </Box>
                    <Box
                      className={`${app_classes.display.display_flex} ${app_classes.margin.r1}`}
                    >
                      <img
                        src={workHoursIcon}
                        alt=""
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: 5,
                        }}
                        className={`${app_classes.general.color_secondary} ${app_classes.general.centered_y}`}
                      />
                      <Box className={app_classes.display.display_flex}>
                        <JobluTypography.Body
                          className={app_classes.general.centered_y}
                        >
                          {getInterviewTime(
                            job?.matchInterview?.data?.start,
                            job?.matchInterview?.data?.end
                          )}
                        </JobluTypography.Body>
                      </Box>
                    </Box>
                    <Box
                      className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                    >
                      <img
                        src={
                          getMeetingProviderDetails(
                            job?.matchInterview?.provider
                          ).img
                        }
                        alt=""
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: 5,
                        }}
                        className={app_classes.general.color_secondary}
                      />
                      <JobluTypography.Body>
                        <Link
                          target="_blank"
                          to={getVideoCallLink(job.matchInterview)}
                        >
                          {titleCase(
                            getMeetingProviderDetails(
                              job?.matchInterview?.provider
                            ).name,
                            true
                          )}
                        </Link>
                      </JobluTypography.Body>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box
            style={{
              bottom: 10,
              paddingTop: "30px",
              position: "absolute",
              width: "100%",
              textAlign: "center",
              //border: "1px solid red",
              borderTop: "none",
              background:
                "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
            }}
          >
            {width < 640 && (
              <Chip
                label="See More"
                variant="outlined"
                style={{ zIndex: 998 }}
                className={`${styles.chip_outline_seemore}  `}
              />
            )}
          </Box>
        </Box>
      </Box>
      <JobDetailsModal
        job={view_job}
        open={Boolean(view_job)}
        setOpen={setViewJob}
        onSwipe={handleSwipe}
        isShortlisted={isShortlisted}
        card_index={card_index}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    AppState: state.Generals,
  };
};
export default connect(mapStateToProps, null)(JobCardList);
