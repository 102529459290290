import { Box, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/page-title";
import JobluTypography from "../../components/Typography";
import { app_styles } from "../../general.styles";
import ApplicantArchivedJobs from "../my-jobs/components/archived";

function ArchivedApplicants(props) {
  const navigate = useNavigate();
  const [jobDrafts, setJobDrafts] = useState([]);
  const [draftsCount, setDraftsCount] = useState(0);
  const [draftsPage, setDraftspage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [jobsPage, setJobsPage] = useState(1);
  const [totalJobsPage, setTotalJobsPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [activeJobsSettings, setActiveJobsSettings] = useState({
    totalCount: null,
    limit: 20,
  });

  const app_classes = app_styles(props);
  return (
    <>
      <PageTitle pageTitle="Archived" />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Box className={`${app_classes.margin.b2}`}>
            <JobluTypography.H6
              className={`${app_classes.cursor.pointer}`}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon /> Back
            </JobluTypography.H6>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            className={`${app_classes.border.border_all} ${app_classes.padding.a3}`}
          >
            <Grid container justifyContent="center">
              <Grid item md={12}>
                <Box sx={{ width: "100%" }} style={{ marginTop: 20 }}>
                  <ApplicantArchivedJobs />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default ArchivedApplicants;
