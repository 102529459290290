import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";
import NoJobs from "../../../../assets/img/matches/NoJobs.png";
import JobluTypography from "../../../../components/Typography";
import { app_styles } from "../../../../general.styles";
import { applicantRequests } from "../../../../service/requests";
import ListLoader from "../../../explore/jobseeker-explore/components/loaders/ListLoader";
import JobCardList from "../card";
const ApplicantAppliedJobs = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [orig_list, setOrigList] = useState([]);
  const [applied_jobs, setAppliedJobs] = useState([]);
  const [current_page, setCurrentPage] = useState(1);

  const app_classes = app_styles();
  const [searchText, setSearchText] = useState("");

  const getApplicantAppliedJobs = (data, append = false) => {
    if (!append) setLoading(true);
    let payload = {
      search: searchText,
      limit: 20,
      offset: data?.offset,
    };
    applicantRequests.jobs
      .applied(payload)
      .then((res) => {
        console.log("applicant applied jobs", res.data);
        if (current_page > 1) {
          if (append) {
            setAppliedJobs({
              ...res.data,
              data: [...applied_jobs?.data, ...res.data?.data],
            });
          } else {
            setAppliedJobs(res.data);
          }
        } else {
          if (append) {
            setAppliedJobs({
              ...res.data,
              data: [...applied_jobs?.data, ...res.data?.data],
            });
          } else {
            setAppliedJobs(res.data);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      })
      .finally(() => {
        if (searchText) {
          setCurrentPage(1);
        }
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getApplicantAppliedJobs(searchText);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    if (current_page > 1) {
      getApplicantAppliedJobs();
    }
  }, [current_page]);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          className={`${app_classes.margin.b2}`}
        >
          <Grid xs={10} sm={4}>
            <TextField
              size="small"
              // Autofocus on textfield
              variant="outlined"
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              height: "60vh",
              overflowY: "auto",
              paddingRight: 10,
            }}
          >
            {!loading ? (
              <InfiniteScroll
                pageStart={0}
                loadMore={(page) => {
                  getApplicantAppliedJobs({ offset: page + 1 }, true);
                }}
                hasMore={
                  Math.ceil(
                    applied_jobs?.totalCount / applied_jobs?.data?.length
                  ) > 1
                }
                loader={
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    spacing={2}
                  >
                    <ListLoader count={1} />
                  </Grid>
                }
                useWindow={false}
              >
                {applied_jobs?.data?.length > 0 && !loading ? (
                  applied_jobs?.data?.map((job, index) => {
                    return (
                      // <Grid item xs={12} key={job._id}>
                      <JobCardList
                        key={job._id}
                        job={job}
                        isApplied
                        getApplicantAppliedJobs={getApplicantAppliedJobs}
                        current_page={current_page}
                        searchText={searchText}
                        setCurrentPage={setCurrentPage}
                      />
                      // </Grid>
                    );
                  })
                ) : (
                  <Grid
                    item
                    xs={12}
                    className={`${app_classes.margin.t5} ${app_classes.margin.b5}`}
                  >
                    <Box
                      className={`${app_classes.text.center} ${app_classes.margin.b2}`}
                    >
                      <img src={NoJobs} width={200} />
                    </Box>
                    <Box className={`${app_classes.margin.b2}`}>
                      <JobluTypography.H6
                        className={`${app_classes.text.center} `}
                      >
                        Find your next match job!
                      </JobluTypography.H6>
                    </Box>
                    <Box>
                      <JobluTypography.Body
                        className={`${app_classes.text.center} `}
                      >
                        One swipe could lead to your dream job.
                      </JobluTypography.Body>
                    </Box>
                    <Box className={`${app_classes.margin.b1}`}>
                      <JobluTypography.Body
                        className={`${app_classes.text.center} `}
                      >
                        Click explore and keep swiping.
                      </JobluTypography.Body>
                    </Box>
                    <Box className={app_classes.text.center}>
                      <Button
                        variant="outlined"
                        className={`${app_classes.button.primary_outline}`}
                        onClick={() => {
                          navigate("/dashboard");
                        }}
                      >
                        Explore
                      </Button>
                    </Box>
                  </Grid>
                )}
              </InfiniteScroll>
            ) : (
              <>
                <JobluTypography.H6 style={{ textAlign: "center" }}>
                  <CircularProgress />
                </JobluTypography.H6>
              </>
            )}
          </Box>
        </Grid>
        {/* <Grid item xs={12} container justifyContent="center">
          <PaginationV1
            current_page={current_page}
            setCurrentPage={setCurrentPage}
            totalCount={applied_jobs?.totalCount}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default ApplicantAppliedJobs;
