import React from 'react'
import companyDefault from "../../../assets/img/defaultImg.png";

function WizardStep1(props) {
    const { profile, logoFile, setLogoFile } = props
    const uploadFile = () => {
        const fileBtn = document.getElementById('uploadLogo')
        fileBtn.click()
    }
    return (
        <div id='wizard-steps'>
            <h2>Company Logo</h2>
            <p className="mb-3">
                Show off your company identity! Upload your company logo.
            </p>
            <div className="d-flex">
                <div className="me-2">
                    <img
                        src={
                            logoFile
                                ? URL.createObjectURL(logoFile)
                                : (profile?.profileUrl ? profile?.profileUrl : companyDefault)
                        }
                        alt=""
                        className='img-fit'
                        style={{ width: '100px', height: '100px' }}
                    />
                </div>
                <div className="align-self-end">
                    <button
                        type="input"
                        in
                        className="btn btn-primary"
                        onClick={uploadFile}
                    >
                        Upload logo
                    </button>
                    <p className="text-muted">
                        Recommended: 80 x 80 pixels; PNG or JPEG
                    </p>
                </div>
            </div>
            <input
                type="file"
                id="uploadLogo"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                    setLogoFile(e.target.files[0])
                }}
                hidden
            />
        </div>
    )
}

export default WizardStep1
