import { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import backIcon from '../../../assets/img/backIcon.svg'
import educationIcon from '../../../assets/img/educationIcon.svg'
import jobIcon from '../../../assets/img/jobIcon.svg'
import languageIcon from '../../../assets/img/languageIcon.svg'
import newTabIcon from '../../../assets/img/newTabIcon.svg'
import skillsIcon from '../../../assets/img/skillsIcon.svg'
import valuesIcon from '../../../assets/img/valuesIcon.svg'
import nationalityIcon from '../../../assets/joblu-2.0/img/new-icons/nationality.svg'
import overseasExpIcon from '../../../assets/joblu-2.0/img/new-icons/overseas-experience.svg'
import employmentHistoryIcon from '../../../assets/joblu-2.0/img/new-icons/work-experience.svg'
import { jobApplicantRequest } from '../../../service/requests'
import { profilePlaceholder_Applicant, SECRET } from '../../../utils/constants'
import useWindowDimensions from '../../../utils/hooks'
import { NameAndMore } from '../../../views/explore/components/JobSeekerCard'
import { CustomVideoPlayer } from '../../../views/explore/components/VideoPlayer'
import CustomAlert from '../Alert/Alert'
import DownloadProfileButton from '../DownloadProfileButton'
import './styles.css'
import UserFiles from './UserFiles'

const application = {
    ADD_TO_SHORT_LIST: 'ADD_TO_SHORT_LIST',
    REMOVE_FROM_SHORT_LIST: 'REMOVE_FROM_SHORT_LIST',
    DID_NOT_QQUALIFY: 'DID_NOT_QQUALIFY',
    REMOVE_FROM_ARCHIVE: 'REMOVE_FROM_ARCHIVE',
}
export function ProfessionalDetailsCard({ professional_details, jobseeker }) {
    return (
        <div className="card-body">
            <h5>Professional Details</h5>
            <p>
                <strong>About Me</strong>
            </p>
            <p>{jobseeker?.bio}</p>

            <div className="row mt-3">
                {professional_details.map((item) => {
                    return (
                        <>
                            {item?.value && (
                                <div className="col-md-6 col-sm-12 d-flex align-items-center mb-3">
                                    <img
                                        style={{
                                            width: 24,
                                            height: 24,
                                        }}
                                        alt=""
                                        src={item.logo}
                                        className="me-3"
                                    />
                                    <div>
                                        <p>{item.label}</p>
                                        <p>{item.value}</p>
                                        {item.subvalue && (
                                            <p>{item.subvalue}</p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )
                })}
            </div>
        </div>
    )
}
export function PreferencesCard({ preferences_details }) {
    return (
        <div className="card-body">
            <h5>Preferences</h5>
            {preferences_details.map((item) => {
                return (
                    <div className=" d-flex align-items-center mb-3">
                        <img
                            style={{
                                width: 24,
                                height: 24,
                            }}
                            alt=""
                            src={item.logo}
                            className="me-3"
                        />
                        <div>
                            <p>{item.label}</p>
                            <div>
                                {item.value?.map((val) => {
                                    return (
                                        <span className="badge rounded-pill bg-primary fs13 me-2">
                                            {val}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
function Profile(props) {
    const {
        showProfile,
        setShowProfile,
        isFromMatches,
        selectedProfile,
        tab,
        getApplicants,
        setShowInfo,
        currentTab,
    } = props
    const navigate = useNavigate()
    const [jobseeker, setJobSeeker] = useState({})
    const [modalData, setModaldata] = useState(null)
    const [alertMessage, setAlertMessage] = useState(null)
    const { width } = useWindowDimensions()
    useEffect(() => {
        if (!!selectedProfile) {
            setJobSeeker(selectedProfile)
        }
        console.log(selectedProfile)
    }, [selectedProfile])

    const closeProfile = () => {
        setShowInfo && setShowInfo(false)
        setShowProfile && setShowProfile(false)
        document.getElementsByTagName('BODY')[0].style.overflowY = 'scroll'
    }

    const addToShortlist = () => {
        if (!!jobseeker) {
            let payload = {
                status: 'assessment',
                processingType: 'applicant_shortlisted',
            }
            try {
                jobApplicantRequest
                    .jobProcessing(jobseeker.jobId, jobseeker.userId, payload)
                    .then((res) => {
                        if (res) {
                            setAlertMessage('Applicant added to Shortlist')
                            clearAlert()
                        }
                    })
            } catch (err) {
                console.log(err)
            }
        }
    }

    const professional_details = [
        {
            label: 'Written/Spoken Language',
            value:
                !jobseeker?.languagesSpoken ||
                jobseeker?.languagesSpoken.length === 0
                    ? null
                    : jobseeker.languagesSpoken.map((item, index) =>
                          index === jobseeker.languagesSpoken.length - 1
                              ? `${item}`
                              : `${item}, `
                      ),
            logo: languageIcon,
        },
        {
            label: 'Employment History',
            value: jobseeker?.formerCompanyName,
            subvalue: jobseeker?.formerJobTitle,
            logo: employmentHistoryIcon,
        },
        {
            label: 'Educational Background',
            value: jobseeker?.education,
            logo: educationIcon,
        },
        {
            label: 'Overseas Work Experience',
            value: jobseeker?.singaporeExp
                ? 'Singapore'
                : jobseeker?.overseasExp
                ? 'Other Countries'
                : null,
            logo: overseasExpIcon,
        },
        {
            label: 'Interested to Work In',
            value: !jobseeker?.countriesInterested
                ? null
                : jobseeker.countriesInterested.map((item, index) =>
                      index === jobseeker.countriesInterested.length - 1
                          ? `${item}`
                          : `${item}, `
                  ),
            logo: nationalityIcon,
        },
        { label: 'Job Function', value: jobseeker?.jobFunction, logo: jobIcon },
    ]

    const preferences_details = [
        {
            label: 'Skills and Expertise',
            value: jobseeker?.skills,
            logo: skillsIcon,
        },
        {
            label: 'What I Value',
            value: jobseeker?.importantForYou,
            logo: valuesIcon,
        },
    ]

    const clearAlert = () => {
        setModaldata(null)
        setTimeout(() => {
            setAlertMessage(null)
            getApplicants()
        }, 3000)
    }
    const removeFromShortlist = () => {
        if (!!jobseeker) {
            let payload = {
                status: 'active',
                processingType: 'acknowledge_application',
            }
            try {
                jobApplicantRequest
                    .jobProcessing(jobseeker.jobId, jobseeker.userId, payload)
                    .then((res) => {
                        if (res) {
                            setAlertMessage('Applicant removed from Shortlist')

                            clearAlert()
                        }
                    })
            } catch (err) {
                console.log(err)
            }
        }
    }
    const didNotQualify = () => {
        if (!!jobseeker) {
            let payload = {
                status: 'assessment',
                processingType: 'applicant_did_not_qualify',
            }
            try {
                jobApplicantRequest
                    .jobProcessing(jobseeker.jobId, jobseeker.userId, payload)
                    .then((res) => {
                        if (res) {
                            setAlertMessage('Applicant moved to Archive')

                            clearAlert()
                        }
                    })
            } catch (err) {
                console.log(err)
            }
        }
    }
    const removeFromArchive = () => {
        console.log(jobseeker)
        if (!!jobseeker) {
            let payload = {
                status: 'active',
                processingType: 'acknowledge_application',
            }
            try {
                jobApplicantRequest
                    .jobProcessing(jobseeker.jobId, jobseeker.userId, payload)
                    .then((res) => {
                        if (res) {
                            setAlertMessage('Applicant removed from Archive')

                            clearAlert()
                        }
                    })
            } catch (err) {
                console.log(err)
            }
        }
    }
    if (!showProfile) return null

    const handleShowModal = (type) => {
        if (type === application.ADD_TO_SHORT_LIST) {
            setModaldata({
                title: 'Application Update',
                message: `You are going to update the application status for ${jobseeker?.firstName} ${jobseeker?.lastName} `,
                buttons: (
                    <div className="w-100 d-flex justify-content-center">
                        <button
                            className="btn btn-outline-primary text-center me-1"
                            onClick={() => setModaldata(null)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary text-center"
                            onClick={() => addToShortlist()}
                        >
                            Proceed
                        </button>
                    </div>
                ),
            })
        }
        if (type === application.REMOVE_FROM_SHORT_LIST) {
            setModaldata({
                title: 'Application Update',
                message: `You are going to update the application status for ${jobseeker?.firstName} ${jobseeker?.lastName} `,
                buttons: (
                    <div className="w-100 d-flex justify-content-center">
                        <button
                            className="btn btn-outline-primary text-center me-1"
                            onClick={() => setModaldata(null)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary text-center"
                            onClick={() => removeFromShortlist()}
                        >
                            Proceed
                        </button>
                    </div>
                ),
            })
        }
        if (type === application.DID_NOT_QQUALIFY) {
            setModaldata({
                title: 'Application Update',
                message: `You are going to update the application status for ${jobseeker?.firstName} ${jobseeker?.lastName} `,
                buttons: (
                    <div className="w-100 d-flex justify-content-center">
                        <button
                            className="btn btn-outline-primary text-center me-1"
                            onClick={() => setModaldata(null)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary text-center"
                            onClick={() => didNotQualify()}
                        >
                            Proceed
                        </button>
                    </div>
                ),
            })
        }
        if (type === application.REMOVE_FROM_ARCHIVE) {
            setModaldata({
                title: 'Application Update',
                message: `You are going to update the application status for ${jobseeker?.firstName} ${jobseeker?.lastName} `,
                buttons: (
                    <div className="w-100 d-flex justify-content-center">
                        <button
                            className="btn btn-outline-primary text-center me-1"
                            onClick={() => setModaldata(null)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary text-center"
                            onClick={() => removeFromArchive()}
                        >
                            Proceed
                        </button>
                    </div>
                ),
            })
        }
    }
    return (
        <div className="Profile">
            <CustomAlert
                variant="success"
                show={!!alertMessage}
                body={alertMessage}
                position="bottom-end"
            />
            <div
                id="backdrop"
                className="profile-backdrop nsb"
                onClick={() => {
                    closeProfile()
                }}
            ></div>
            <div
                id="view-profile"
                className={`profile-card-main nsb ${
                    width < 640 && 'col-sm-12 col'
                }`}
            >
                <div className="p-3 pb-5">
                    <div className="row pb-3 border-bottom mb-4">
                        <div className="col-md-6">
                            {jobseeker?.anonymous &&
                            currentTab &&
                            currentTab === 'likes' ? null : (
                                <a
                                    target="_blank"
                                    href={`/applicant/${btoa(
                                        jobseeker.userId
                                    )}?an=${btoa(SECRET)}`}
                                    rel="noreferrer"
                                    className="float-end d-block d-md-none"
                                >
                                    <img
                                        src={newTabIcon}
                                        alt=""
                                        className="me-2"
                                    />
                                    Open in new Tab
                                </a>
                            )}
                            <p
                                className="c-pointer d-flex align-items-center hand"
                                onClick={() => {
                                    closeProfile()
                                }}
                            >
                                <img src={backIcon} alt="" className="me-2" />
                                Back
                            </p>
                        </div>

                        <div className="col-md-6 cl-sm-12 d-block d-md-flex justify-content-end align-items-center">
                            {!!isFromMatches && (
                                <DownloadProfileButton
                                    userId={jobseeker.userId}
                                />
                            )}
                            {jobseeker?.anonymous &&
                            currentTab &&
                            currentTab === 'likes' ? null : (
                                <div className="ms-3 d-none d-md-block">
                                    <a
                                        target="_blank"
                                        href={`/applicant/${btoa(
                                            jobseeker.userId
                                        )}?an=${btoa(SECRET)}`}
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={newTabIcon}
                                            alt=""
                                            className="me-2"
                                        />
                                        Open in new Tab
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8 d-flex">
                                    <img
                                        src={
                                            jobseeker?.anonymous &&
                                            currentTab &&
                                            currentTab === 'likes'
                                                ? profilePlaceholder_Applicant
                                                : jobseeker?.profileUrl ||
                                                  profilePlaceholder_Applicant
                                        }
                                        alt=""
                                        width="90"
                                        height="90"
                                    />
                                    <NameAndMore
                                        jobseeker={jobseeker}
                                        currentTab={currentTab}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 px-0 mb-3">
                        {jobseeker?.elevatorPitch && (
                            <div className="col-md-3 d-flex">
                                <CustomVideoPlayer
                                    src={jobseeker.elevatorPitch}
                                    //put resizeMode cover like style
                                    width="100%"
                                    height="100%"
                                    play
                                />
                            </div>
                        )}
                        <div className="col card">
                            <ProfessionalDetailsCard
                                jobseeker={jobseeker}
                                professional_details={professional_details}
                            />
                        </div>
                    </div>
                    <div className="card mb-3">
                        <PreferencesCard
                            preferences_details={preferences_details}
                        />
                    </div>
                    {jobseeker?.anonymous &&
                    currentTab &&
                    currentTab === 'likes' ? null : (
                        <div>
                            <UserFiles jobseeker={jobseeker} />
                        </div>
                    )}
                </div>
                {!!isFromMatches && (
                    <div className="profile-card-footer">
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-center h-100 mx-2 mx-md-0">
                            {tab === 'Archived' && (
                                <button
                                    className="btn btn-outline-primary mx-2 my-1 my-md-0 btn-block btn-md-auto text-nowrap"
                                    id="remove_from_archive"
                                    onClick={() =>
                                        handleShowModal(
                                            application.REMOVE_FROM_ARCHIVE
                                        )
                                    }
                                >
                                    <span>Remove from Archive</span>
                                </button>
                            )}
                            {(tab === 'Active' || tab === 'Shortlisted') && (
                                <button
                                    className="btn btn-outline-primary mx-2  my-1 my-md-0 btn-block btn-md-auto text-nowrap"
                                    id="applicant_did_not_qualify"
                                    onClick={() =>
                                        handleShowModal(
                                            application.DID_NOT_QQUALIFY
                                        )
                                    }
                                >
                                    <span>Applicant Did Not Qualify</span>
                                </button>
                            )}
                            {tab === 'Shortlisted' && (
                                <button
                                    className="btn btn-primary mx-2 my-1 my-md-0 btn-block btn-md-auto text-nowrap"
                                    id="remove_from_shortlist"
                                    onClick={() =>
                                        handleShowModal(
                                            application.REMOVE_FROM_SHORT_LIST
                                        )
                                    }
                                >
                                    <span>
                                        Remove from Application Shortlist
                                    </span>
                                </button>
                            )}
                            {(tab === 'Active' || tab === 'Archived') && (
                                <button
                                    className="btn btn-primary mx-2  my-1 my-md-0 btn-block btn-md-auto text-nowrap"
                                    id="applicant_shortlisted"
                                    onClick={() =>
                                        handleShowModal(
                                            application.ADD_TO_SHORT_LIST
                                        )
                                    }
                                >
                                    <span>Add to Interview Shortlist</span>
                                </button>
                            )}
                        </div>
                    </div>
                )}
                <Modal show={!!modalData} centered>
                    <ModalHeader className="bg-primary">
                        <h6 className="text-center text-light w-100">
                            {modalData?.title}
                        </h6>
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">
                            <h6 className="open-sans text-center">
                                {modalData?.message}
                            </h6>
                        </div>
                    </ModalBody>
                    <div className="w-100 py-2 my-1">{modalData?.buttons}</div>
                </Modal>
            </div>
        </div>
    )
}

export default Profile
