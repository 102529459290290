import React from 'react'
import { useNavigate } from 'react-router-dom'

function EmptyState(props) {
    const { emptyImg, title, body, link, linkType, linkTitle } = props
    const navigate = useNavigate()
    return (
        <div className="h-100 w-100 my-5">
            <div className="d-flex flex-column h-100 align-items-center justify-content-center">
                {emptyImg && <img src={emptyImg} alt="" className="mb-3" />}
                {title && <h5>{title}</h5>}
                {body && <p className="text-muted">{body}</p>}
                {linkType === 'button' ? (
                    <div className="mt-1">
                        <a
                            onClick={() => {
                                navigate(link)
                            }}
                            className="btn btn-primary text-white"
                        >
                            {linkTitle || ''}
                        </a>
                    </div>
                ) : (
                    <div className="mt-1">
                        <a
                            onClick={() => {
                                navigate(`${link}`)
                            }}
                            className="btn btn-link"
                        >
                            <u>{linkTitle || ''}</u>
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}

export default EmptyState
