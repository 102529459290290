import { makeStyles } from "@material-ui/core/styles";

export const likes_styles = makeStyles((theme) => ({
  tab: {
    textDecoration: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    // minWidth: 260,
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
      height: 0,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
      height: 36,
      borderRadius: 3,
    },
    "& .MuiTab-root": {
      minHeight: 20,
      [theme.breakpoints.up("sm")]: {
        minWidth: 260,
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: 120,
      },
    },
    marginTop: 20,
  },
}));
