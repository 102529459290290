import { makeStyles } from "@material-ui/core/styles";

export const settings_styles = makeStyles((theme) => ({
  default_img: {
    borderRadius: "50%",
    border: "1px solid #8080804D",
    objectFit: "cover",
    height: 92,
    width: 92,
  },
  icons: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
  selected_currency: {
    backgroundColor: "#CCEBF8",
    "&:hover": {
      backgroundColor: "#CCEBF8",
    },
  },
}));
