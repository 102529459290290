import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import jobluBranding from "../../assets/joblu-logos/jobluHorizontal.png";
import { premium_features } from "../../utils/constants";
import { thousandSeparator } from "../../utils/helpers";
import Loading from "../reusables/Loading";
const CheckoutForm = (props) => {
  const {
    amount,
    Generals: { currency },
    plan,
  } = props;
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const [amountPerMonth, setAmountPerMonth] = useState(0);
  const [totalMonths, setMonths] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/profile`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      alert(result.error.message);
    } else {
      setLoading(false);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  useEffect(() => {
    if (plan) {
      const { recurring } = plan;
      if (recurring) {
        const { interval, interval_count } = recurring;
        let months = interval_count;
        if (interval === "year") {
          months = interval_count * 12;
        }
        setMonths(months);
        let per_month = plan.unit_amount / 100 / months;
        setAmountPerMonth(thousandSeparator(per_month.toFixed()));
      }
    }
  }, [plan]);
  const navigate = useNavigate();
  return (
    <>
      <div className="row mx-0 justify-content-center">
        <div className="col-12 col-md-8 row">
          <div className="col-12 col-md-5">
            <h4 className="poppins text-primary ">
              <strong>Joblu Premium</strong>
            </h4>

            <div className=" p-3 mt-5  border-end">
              <img src={jobluBranding} alt="" className="mb-2" />
              <h6 className="mt-2" style={{ marginBottom: 0 }}>
                <strong>
                  {plan?.currency.toUpperCase()}
                  {amountPerMonth}/mo
                </strong>
              </h6>
              <strong>Special Offer - Get 1 month FREE</strong>
              <p className="">
                *Cancel anytime, pay regular monthly price after promotion
                period
              </p>
              <p className="mt-0">
                Next Billing:{" "}
                {moment().add(totalMonths, "months").format("MMM DD, YYYY ")}
                <br />
              </p>
              <hr />
              <strong>Premium Features:</strong>

              <ul className="open-sans">
                {premium_features.map((feat) => (
                  <li>{feat}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-7 ">
            <div className="bg-light h-100 p-5 rounded">
              {/* <button
                                className="btn btn-primary w-100 mb-3"
                                style={{
                                    background:
                                        'linear-gradient(90deg, #4F4073 27.92%, #32335A 75.13%)',
                                    borderRadius: 5,
                                }}
                            >
                                Pay now
                            </button> */}
              {/* <PaymentRequestButtonElement /> */}
              <form onSubmit={handleSubmit}>
                <PaymentElement />
                <div className="d-flex">
                  <button
                    className="btn btn-primary mt-3 mx-auto"
                    disabled={!stripe}
                    style={{ width: "100%" }}
                  >
                    Pay {amount && ` ${plan?.currency.toUpperCase()}${amount}`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={loading} centered contentClassName="bg-transparent border-0">
        <Loading show color="primary" />
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, null)(CheckoutForm);
