import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useGeoLocation from "react-ipgeolocation";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "../../assets/img/emailIcon.svg";
import LocationIcon from "../../assets/img/locationIcon.svg";
import numberOfApplicants from "../../assets/img/numberOfApplicants.svg";
import passIcon from "../../assets/img/passIcon.svg";
import cancel_avatar from "../../assets/img/pricing-page/cancel_avatar.png";
import pricing_poster from "../../assets/img/pricing-page/pricing-poster-2.png";
import TelIcon from "../../assets/img/telIcon.svg";
import webIcon from "../../assets/joblu-2.0/img/new-icons/nationality.svg";
import userActions from "../../redux/user/actions";
import { paymentRequests } from "../../service/requests";
import {
  premium_features,
  profilePlaceholder_Employer,
  STRIPE_BASE_URL,
  STRIPE_SECRET,
} from "../../utils/constants";
import { thousandSeparator } from "../../utils/helpers";
import "./styles.css";
function MyAccount(props) {
  const { User, subscription, getSubscription } = props;

  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [amountPerMonth, setAmountPerMonth] = useState(0);
  const [totalMonths, setMonths] = useState(null);
  const [showCancel, setShowCancel] = useState(false);
  const [successModal, setShowSuccessModal] = useState(false);
  const location = useLocation();
  const features = _.chunk(premium_features, 6);
  const [monthly6Price, setMonthly6Price] = useState(0);
  const geolocation = useGeoLocation();
  let curr = "php";
  if (geolocation === "PH") curr = "php";
  if (geolocation === "SG") curr = "sgd";
  if (geolocation === "US") curr = "usd";

  useEffect(() => {
    const intent = new URLSearchParams(location.search).get("payment_intent");

    if (intent) {
      setShowSuccessModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (successModal) {
      setTimeout(() => {
        setShowSuccessModal(false);
        getSubscription();
      }, 3000);

      navigate("/profile");
    }
  }, [successModal]);

  useEffect(() => {
    if (!!subscription && Object.keys(subscription).length > 0) {
      axios
        .get(`${STRIPE_BASE_URL}subscriptions/${subscription?.transactionId}`, {
          headers: {
            Authorization: "Bearer " + STRIPE_SECRET, //the token is a variable which holds the token
          },
        })
        .then((res) => {
          setSubscriptionDetails(res?.data);
          const { plan } = res.data;
          if (plan) {
            const { interval, interval_count } = res.data?.plan;
            let months = interval_count;
            if (interval === "year") {
              months = interval_count * 12;
            }
            setMonths(months);
            let per_month = plan.amount / 100 / months;
            setAmountPerMonth(thousandSeparator(per_month.toFixed()));
          }
        });
    }
  }, [subscription]);

  const handleCancelSub = () => {
    const id = subscription.transactionId;

    try {
      paymentRequests
        .cancelSubscription({
          subscriptionId: id,
        })
        .then((res) => {
          if (res) {
            getSubscription();
          }
        })
        .then(() => {
          setShowCancel(false);
          navigate("/subscription/cancelled");
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSubscription();

    paymentRequests.getPlans().then((res) => {
      if (res.data?.monthly6) {
        axios
          .get(`${STRIPE_BASE_URL}prices/${res.data?.monthly6}`, {
            headers: {
              Authorization: "Bearer " + STRIPE_SECRET, //the token is a variable which holds the token
            },
          })
          .then((res) => {
            console.log("monthly6 => ", res.data);
            if (res.data?.unit_amount) {
              let monthly6 = res.data?.unit_amount;
              monthly6 = monthly6 / 100 / 6;
              monthly6 = monthly6.toFixed();
              setMonthly6Price(monthly6);
            }
          });
      }
    });
  }, []);

  const navigate = useNavigate();
  return (
    <div className="row justify-content-center mx-0">
      <div className="col-12 col-md-10 row mt-5">
        <div className="col-12 col-md-8 offset-md-2">
          <h6>Profile</h6>
        </div>
        <div className="col-12 col-md-8 offset-md-2  p-0">
          <div className="card mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-flex">
                  <div>
                    <img
                      src={
                        User?.user?.profileUrl || profilePlaceholder_Employer
                      }
                      width="90"
                      height="90"
                      alt=""
                      className="me-3 img-fit"
                    />
                  </div>
                  <div>
                    <h5>{User.user.companyName}</h5>
                    <p>
                      {" "}
                      {User.user.firstName} {User.user.lastName}
                    </p>
                    {User?.user?.email && (
                      <p className="text-muted">
                        <small> {User?.user?.email}</small>
                      </p>
                    )}
                    {User?.user?.address && (
                      <p className="text-muted">
                        <small> {User?.user?.address}</small>
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 text-end">
                  <button
                    className="btn btn-primary mt-3 mt-md-0"
                    onClick={() => navigate("/edit-profile")}
                  >
                    Edit profile
                  </button>
                </div>
                <div className="col-md-12 col-sm-12 mt-3">
                  <div className="d-flex flex-column flex-md-row">
                    <div className=" d-flex p-0 me-3 mb-3 mb-md-0">
                      <img
                        style={{
                          height: 24,
                          marginRight: 5,
                        }}
                        alt=""
                        src={LocationIcon}
                        className="my-auto"
                      />
                      <p className="m-0 my-auto open-sans-body">
                        {User?.user?.country}
                      </p>
                    </div>
                    {User?.user?.phoneNo && (
                      <div className=" d-flex p-0  me-3 mb-3 mb-md-0">
                        <img
                          style={{
                            height: 24,
                            marginRight: 5,
                          }}
                          alt=""
                          src={TelIcon}
                          className="my-auto"
                        />
                        <p className="m-0 my-auto open-sans-body">
                          {User?.user?.phoneNo}
                        </p>
                      </div>
                    )}
                    {User?.user?.email && (
                      <div className=" d-flex p-0  mb-3 mb-md-0">
                        <img
                          style={{
                            height: 24,
                            marginRight: 5,
                          }}
                          alt=""
                          src={EmailIcon}
                          className="my-auto"
                        />
                        <p className="m-0 my-auto open-sans-body me-2">
                          {User?.user?.email}
                        </p>
                      </div>
                    )}
                    {User?.user?.companyWebsite && (
                      <div className=" d-flex p-0 mb-3 mb-md-0">
                        <img
                          style={{
                            height: 24,
                            marginRight: 5,
                          }}
                          alt=""
                          src={webIcon}
                          className="my-auto"
                        />
                        <p className="m-0 my-auto open-sans-body">
                          <a
                            href={`${User?.user?.companyWebsite}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {User?.user?.companyWebsite}
                          </a>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5>Company Profile</h5>
              <p>
                <strong>About the company</strong>
              </p>
              <p>{User.user.companyOverview}</p>
              <div className="row mt-3">
                {User.user.companyRegistrationNo && (
                  <div className="col-md-4">
                    <div className=" d-flex">
                      <img
                        src={passIcon}
                        alt=" "
                        style={{
                          width: 19,
                          height: 19,
                        }}
                        className="my-auto"
                      />
                      <div className="d-flex flex-column  mx-2 my-auto">
                        <p className="open-sans-body m-0">Registration No.</p>
                        <p className="open-sans-body m-0 fw-bold">
                          {User.user.companyRegistrationNo}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-4">
                  <div className="d-flex">
                    <img
                      src={numberOfApplicants}
                      alt=" "
                      style={{
                        width: 19,
                        height: 19,
                      }}
                      className="my-auto"
                    />
                    <div className="d-flex flex-column  mx-2 my-auto">
                      <p className="open-sans-body m-0">Company Size</p>
                      <p className="open-sans-body m-0 fw-bold">
                        {User?.user?.companySize
                          ? User?.user?.companySize
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!!subscription &&
          typeof subscription === "object" &&
          Object.keys(subscription).length === 0 ? (
            <div className="card mt-3 rounded d-none">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-2">
                    <img src={pricing_poster} alt="" style={{ width: 182 }} />
                  </div>
                  <div className="col-auto">
                    <h5>Joblu Premium</h5>
                    <div className="mt-3">
                      <strong>Pay for success.</strong>
                      <p>
                        Save thousands in recruitment cost and source the right
                        candidate the fast, fun, and fabulous way!{" "}
                      </p>
                      <strong>
                        {curr.toUpperCase()} {thousandSeparator(monthly6Price)}
                        /mo for 6 months billed monthly (Save 50%)
                      </strong>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        navigate("/pricing");
                      }}
                    >
                      View Plans
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="card mt-3 rounded d-none">
              <div className="card-body pb-4">
                <h5 className="text-primary mb-3">Joblu Premium</h5>
                <div className="row">
                  <div className="col-12 col-md-3">
                    {/* <h5 className="">
                                            {' '}
                                            {subscriptionDetails?.plan?.currency.toUpperCase()}
                                            {amountPerMonth}/mo
                                        </h5>{' '} */}
                    <p className="mt-0">
                      {subscription?.type
                        ? subscription?.type === "monthly"
                          ? "Monthly Plan"
                          : subscription?.type === "monthly6"
                          ? "6 Months Plan"
                          : subscription?.type === "monthly12"
                          ? "Yearly Plan"
                          : null
                        : null}
                    </p>
                    <p>
                      Next Billing:
                      {moment(subscription?.expiryDate).format(
                        " MMM DD, YYYY "
                      )}
                    </p>
                    <br />
                    <button
                      className="btn btn-outline-primary open-sans"
                      onClick={() => {
                        setShowCancel(true);
                      }}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                  <div className="col-12 col-md-3">
                    <strong>Premium Features:</strong>

                    <ul className="open-sans">
                      {features[0].map((feat) => (
                        <li>{feat}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-12 col-md-3">
                    <ul className="mt-4 open-sans">
                      {features[1].map((feat) => (
                        <li>{feat}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="bg-light d-none">
            <div className="">
              <div className="p-3">
                <div className="d-flex">
                  <img
                    className=""
                    src={User?.user?.profileUrl || profilePlaceholder_Employer}
                    style={{ width: 90, height: 90 }}
                    alt=""
                  />

                  <div className=" py-2 w-100">
                    <div className="w-100 d-flex justify-content-between px-2">
                      <div className="">
                        <h5 className="poppins mb-0">
                          {User.user.companyName}
                        </h5>
                        <p className="m-0 open-sans fw-bold">
                          {User.user.firstName} {User.user.lastName}
                        </p>
                        <p className="m-0 open-sans-body text-secondary">
                          {User?.user?.email}
                        </p>
                        <p className="m-0 open-sans-body text-secondary">
                          {User?.user?.address}
                        </p>
                      </div>
                      <div className="">
                        <button
                          className="btn btn-primary me-2 mt-0"
                          onClick={() => navigate("/edit-profile")}
                        >
                          Edit Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className=" d-flex p-0">
                    <img
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 5,
                      }}
                      alt=""
                      src={LocationIcon}
                      className="my-auto"
                    />
                    <p className="m-0 my-auto open-sans-body">
                      {User.user.country}
                    </p>
                  </div>
                  <div className=" d-flex p-0 mx-2">
                    <img
                      style={{
                        width: 24,
                        height: 22,
                        marginRight: 5,
                      }}
                      alt=""
                      src={TelIcon}
                      className="my-auto"
                    />
                    <p className="m-0 my-auto open-sans-body">
                      {User.user.phoneNo}
                    </p>
                  </div>
                  <div className=" d-flex p-0 ">
                    <img
                      style={{
                        width: 24,
                        marginRight: 5,
                      }}
                      alt=""
                      src={EmailIcon}
                      className="my-auto"
                    />
                    <p className="m-0 my-auto open-sans-body me-2">
                      {User.user.email}
                    </p>
                  </div>
                  {User?.user?.companyWebsite && (
                    <div className=" d-flex p-0 mx-2">
                      <img
                        style={{
                          width: 24,
                          marginRight: 5,
                        }}
                        alt=""
                        src={webIcon}
                        className="my-auto"
                      />
                      <p className="m-0 my-auto open-sans-body">
                        {User.user.companyWebsite}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showCancel} size="sm">
        <div className="card rounded p-3" style={{ width: "339px" }}>
          <div className="d-flex">
            <img
              src={cancel_avatar}
              alt=""
              style={{ width: "183.21px", height: "240px" }}
              className="mx-auto"
            />
          </div>
          <h5 className="text-center fw-bold mt-2">
            Are you sure you want to <br />
            cancel your Joblu Premium <br />
            Subscription?
          </h5>
          <p className="text-center">
            You can still use Joblu but with very limited features.
          </p>
          <button
            className="btn btn-primary open-sans mt-4"
            onClick={() => {
              handleCancelSub();
            }}
          >
            Yes, cancel my subscription
          </button>
          <button
            className="btn btn-outline-primary text-center open-sans mt-2"
            onClick={() => {
              setShowCancel(false);
            }}
          >
            Keep my subscription
          </button>
        </div>
      </Modal>
      <Modal
        size="sm"
        centered
        show={successModal}
        onHide={() => {
          setShowSuccessModal(false);
        }}
      >
        <div className="card p-5">
          <div className="d-flex">
            <div className="m-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                fill="green"
                class="bi bi-cart-check"
                viewBox="0 0 16 16"
              >
                <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </div>
          </div>
          <h6 className="text-center text-primary mt-3">
            Payment Success <br />
            Thank You! <br />
          </h6>
          {/* <p>
                        You can now use <br />
                        your Premium account
                    </p> */}
        </div>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return { User: state.User, subscription: state.User.subscription };
};
const mapDispatchToProps = {
  getSubscription: userActions.getActiveSubscription,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
