export const skills_masterlist =[
  {
    "label": ".NET Assemblies",
    "value": ".NET Assemblies"
  },
  {
    "label": "2D Animation",
    "value": "2D Animation"
  },
  {
    "label": "2D Gel Analysis Software",
    "value": "2D Gel Analysis Software"
  },
  {
    "label": "3D Animation",
    "value": "3D Animation"
  },
  {
    "label": "3D Art",
    "value": "3D Art"
  },
  {
    "label": "401K Plan Administration",
    "value": "401K Plan Administration"
  },
  {
    "label": "A/B Testing",
    "value": "A/B Testing"
  },
  {
    "label": "A3 Problem Solving Techniques",
    "value": "A3 Problem Solving Techniques"
  },
  {
    "label": "AAA Video Games",
    "value": "AAA Video Games"
  },
  {
    "label": "ABA Intervention",
    "value": "ABA Intervention"
  },
  {
    "label": "ABC FlowCharter",
    "value": "ABC FlowCharter"
  },
  {
    "label": "ABI Solid Sequencing",
    "value": "ABI Solid Sequencing"
  },
  {
    "label": "ABL Radiometers",
    "value": "ABL Radiometers"
  },
  {
    "label": "ABR Routers",
    "value": "ABR Routers"
  },
  {
    "label": "AC Motors",
    "value": "AC Motors"
  },
]