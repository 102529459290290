import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Box, Button, Chip, Grid } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultImg from "../../assets/img/defaultImg.png";
import BirthdayIcon from "../../assets/img/jobs/BirthdayIcon.png";
import DismissIcon from "../../assets/img/jobs/DismissIcon.png";
import EducationIcon from "../../assets/img/jobs/EducationIcon.png";
import EmailIcon from "../../assets/img/jobs/EmailIcon.png";
import ExperienceIcon from "../../assets/img/jobs/ExperienceIcon.png";
import LikeIcon from "../../assets/img/jobs/LikeIcon.png";
import LocationIcon from "../../assets/img/jobs/LocationIcon.png";
import NoDocument from "../../assets/img/jobs/NoDocument.png";
import PhoneIcon from "../../assets/img/jobs/PhoneIcon.png";
import SalaryIcon from "../../assets/img/jobs/SalaryIcon.png";
import SuperLikeIcon from "../../assets/img/jobs/SuperLikeIcon.png";
import { app_styles } from "../../general.styles";
import ElevatorPitchV2 from "../reusables/elevator-pitch/ElevatorPitchV2";
import {
  formatDateRange2,
  formatMoney,
  getYearDifference,
  getYearsOfExpCategory,
} from "../../utils/helpers";
import JobluTypography from "../Typography";
import { profile_preview_styles } from "./styles";
import useWindowDimensions from "../../utils/hooks";

function ProfilePreview(props) {
  const {
    AppState,
    current_user,
    nextStep,
    isFromCreateProfile,
    borderRadius,
    addMoreDetails,
  } = props;
  const { width } = useWindowDimensions();
  const app_classes = app_styles();
  const classes = profile_preview_styles();
  const navigate = useNavigate();

  // const current_user = User?.user;

  const { currencyList, currency } = AppState;
  const currencyOptions = {
    currencyList,
    currency,
  };

  const getYearsOfExp = (item) => {
    if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
      const sorted =
        item?.experienceHistory &&
        item?.experienceHistory?.sort(function (a, b) {
          return new Date(a.startDate) - new Date(b.startDate);
        });
      const startDate = new Date(sorted[0]?.startDate);
      const diff = getYearDifference(startDate);
      return getYearsOfExpCategory(diff);
    } else {
      return null;
    }
  };

  const [activeTab, setActiveTab] = useState(
    current_user?.portfolioAndFiles?.length > 0 ? "docs" : "profile"
  );
  return (
    <>
      <Box>
        {isFromCreateProfile && (
          <>
            <Box
              className={`${app_classes.text.center} ${app_classes.margin.y4}`}
            >
              <Box className={`${app_classes.margin.b1}`}>
                <JobluTypography.H4
                  onClick={() => {
                    // console.log(User?.user);
                  }}
                  className={`${app_classes.text.warning}`}
                >
                  Congratulations!
                </JobluTypography.H4>
              </Box>
              <Box className={`${app_classes.margin.b3}`}>
                <JobluTypography.H4 primary>
                  This is how potential employers will see your profile.
                </JobluTypography.H4>
              </Box>
              <Box>
                <JobluTypography.H5 primary>
                  <span className={`${app_classes.text.warning}`}>Tip:</span>{" "}
                  Employers are 10x most likely to shortlist you if your <br />
                  profile is complete.
                </JobluTypography.H5>
              </Box>
            </Box>
          </>
        )}
        <Box
          style={{
            backgroundColor: "#F6F9FB",
            borderRadius: borderRadius && "10px",
          }}
          className={`${app_classes.padding.a2} ${app_classes.margin.b4}`}
        >
          <Box
            style={{ backgroundColor: "#FFFFFF" }}
            className={`${app_classes.padding.a2} ${app_classes.margin.b3}`}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={7}
                justifyContent=""
                className={`${app_classes.display.display_flex} ${
                  width < 640
                    ? app_classes.flexBox.flex_direction_column
                    : app_classes.flexBox.flex_direction_row
                }`}
              >
                <Box
                  className={`${app_classes.margin.r2} ${app_classes.text.center}`}
                >
                  <img
                    src={current_user?.profileUrl || DefaultImg}
                    style={{
                      borderRadius: "100px",
                      border: "5px solid #E4ECF3",
                      objectFit: "cover",
                      backgroundColor: '#ffffff'
                    }}
                    width={120}
                    height={120}
                    className={`${app_classes.margin.b1}`}
                  />
                  {current_user?.elevatorPitch ? (
                    <>
                      <Box
                        className={` ${app_classes.margin.b1} ${app_classes.cursor.pointer}`}
                      >
                        <ElevatorPitchV2 jobseeker={current_user} />
                      </Box>
                    </>
                  ) : null}
                </Box>
                <Box className={`${width < 640 && app_classes.text.center}`}>
                  <JobluTypography.H4 primary>
                    {current_user?.firstName} {current_user?.lastName}
                  </JobluTypography.H4>
                  {current_user?.preferedTitle && (
                    <>
                      <JobluTypography.H6 secondary>
                        {current_user?.preferedTitle}
                      </JobluTypography.H6>
                    </>
                  )}
                  {current_user?.jobFunction && (
                    <>
                      <Box className={`${app_classes.margin.b1}`}>
                        <JobluTypography.Body>
                          {current_user?.jobFunction}
                        </JobluTypography.Body>
                      </Box>
                    </>
                  )}
                  {current_user?.employmentStatus && (
                    <>
                      <Chip
                        style={{
                          backgroundColor: "#009CDE4D",
                          width: 188,
                        }}
                        className={`${app_classes.margin.b2}`}
                        label={
                          <JobluTypography.Caption
                            className={`${app_classes.text.secondary}`}
                          >
                            {current_user?.employmentStatus}
                          </JobluTypography.Caption>
                        }
                      />
                    </>
                  )}
                  <Box
                    className={`${app_classes.display.display_flex} ${
                      width < 640 && app_classes.flexBox.justify_content_center
                    }`}
                  >
                    <Box className={`${app_classes.margin.r1}`}>
                      <img src={DismissIcon} width={56} />
                    </Box>
                    <Box className={`${app_classes.margin.r1}`}>
                      <img src={SuperLikeIcon} width={56} />
                    </Box>
                    <Box>
                      <img src={LikeIcon} width={56} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    {current_user?.educationHistory?.length > 0 && (
                      <>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r1}`}>
                            <img src={EducationIcon} width={16} />
                          </Box>
                          <Box>
                            <JobluTypography.Body primary>
                              {
                                current_user?.educationHistory?.sort(function (
                                  a,
                                  b
                                ) {
                                  return (
                                    new Date(b.educStartDate) -
                                    new Date(a.educStartDate)
                                  );
                                })[0]?.educName
                              }
                            </JobluTypography.Body>
                          </Box>
                        </Box>
                      </>
                    )}
                    {current_user?.currentLocation && (
                      <>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r1}`}>
                            <img src={LocationIcon} width={16} />
                          </Box>
                          <Box>
                            <JobluTypography.Body primary>
                              {current_user?.currentLocation}
                            </JobluTypography.Body>
                          </Box>
                        </Box>
                      </>
                    )}
                    {current_user?.experienceHistory?.length > 0 && (
                      <>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r1}`}>
                            <img src={ExperienceIcon} width={16} />
                          </Box>
                          <Box>
                            <JobluTypography.Body primary>
                              {getYearsOfExp(current_user) &&
                                getYearsOfExp(current_user) !== null &&
                                `${getYearsOfExp(
                                  current_user
                                )} Years of Experience`}
                            </JobluTypography.Body>
                          </Box>
                        </Box>
                      </>
                    )}
                    {current_user?.salaryMin && current_user?.salaryMax ? (
                      <>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r1}`}>
                            <img src={SalaryIcon} width={16} />
                          </Box>
                          <Box>
                            <JobluTypography.Body primary>
                              {`${formatMoney(
                                current_user?.salaryMin,
                                currencyOptions,
                                current_user?.salaryCurrency || "SGD"
                              )} - ${formatMoney(
                                current_user?.salaryMax,
                                currencyOptions,
                                current_user?.salaryCurrency || "SGD"
                              )}`}
                            </JobluTypography.Body>
                          </Box>
                        </Box>
                      </>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {current_user?.email && (
                      <>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r1}`}>
                            <img src={EmailIcon} width={16} />
                          </Box>
                          <Box>
                            <JobluTypography.Body primary>
                              {current_user?.email}
                            </JobluTypography.Body>
                          </Box>
                        </Box>
                      </>
                    )}
                    {current_user?.phoneNumber && (
                      <>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r1}`}>
                            <img
                              src={PhoneIcon}
                              width={16}
                              style={{ objectFit: "contain" }}
                            />
                          </Box>
                          <Box>
                            <JobluTypography.Body primary>
                              {current_user?.phoneNumber}
                            </JobluTypography.Body>
                          </Box>
                        </Box>
                      </>
                    )}
                    {current_user?.dob && (
                      <>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                        >
                          <Box className={`${app_classes.margin.r1}`}>
                            <img src={BirthdayIcon} width={16} />
                          </Box>
                          <Box>
                            <JobluTypography.Body primary>
                              {moment(current_user?.dob).format("LL")}
                            </JobluTypography.Body>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box className={`${app_classes.display.display_flex}`}>
            <Box
              className={`${app_classes.padding.a2} ${
                app_classes.cursor.pointer
              } ${classes.tabs} ${
                activeTab == "docs" && app_classes.backgroundColor.bg_white
              }`}
              style={{
                borderBottom: activeTab == "docs" && "3px solid #002E5D",
              }}
              onClick={() => {
                setActiveTab("docs");
              }}
            >
              <JobluTypography.Body primary>
                <strong>Documents</strong>
              </JobluTypography.Body>
            </Box>
            <Box
              className={`${app_classes.padding.a2} ${
                app_classes.cursor.pointer
              } ${classes.tabs} ${
                activeTab == "profile" && app_classes.backgroundColor.bg_white
              }`}
              style={{
                borderBottom: activeTab == "profile" && "3px solid #002E5D",
              }}
              onClick={() => {
                setActiveTab("profile");
              }}
            >
              <JobluTypography.Body primary>
                <strong>Profile</strong>
              </JobluTypography.Body>
            </Box>
          </Box>
          <Box style={{ backgroundColor: "#FFFFFF" }}>
            {activeTab == "docs" && (
              <>
                <Box className={`${app_classes.padding.a1}`}>
                  {current_user?.portfolioAndFiles?.length < 1 ? (
                    <Box
                      style={{ minHeight: "50vh" }}
                      className={`${app_classes.display.display_flex} ${app_classes.flexBox.flex_direction_column} ${app_classes.flexBox.align_items_center} ${app_classes.flexBox.justify_content_center}`}
                    >
                      <Box className={`${app_classes.margin.b2}`}>
                        <JobluTypography.Caption style={{ fontSize: 30 }}>
                          <img src={NoDocument} width={90} />
                        </JobluTypography.Caption>
                      </Box>
                      <Box className={`${app_classes.margin.b2} ${app_classes.text.center}`}>
                        <JobluTypography.Caption
                          style={{ fontSize: width < 640 ? 20 : 30 }}
                        >
                          You haven't added any <br/> <br/>  documents to your profile yet.
                        </JobluTypography.Caption>
                      </Box>
                      {/* <Box className={`${app_classes.text.center}`}>
                        <JobluTypography.Caption
                          style={{ fontSize: width < 640 ? 20 : 30 }}
                        >
                          documents to your profile yet.
                        </JobluTypography.Caption>
                      </Box> */}
                    </Box>
                  ) : (
                    <DocViewer
                      documents={current_user?.portfolioAndFiles?.map(
                        (item) => {
                          return { uri: item?.link };
                        }
                      )}
                      pluginRenderers={DocViewerRenderers}
                      prefetchMethod="GET"
                      config={{
                        header: {
                          disableHeader: false,
                          disableFileName: true,
                          retainURLParams: true,
                        },
                        csvDelimiter: ",", // "," as default,
                        pdfZoom: {
                          defaultZoom: 1, // 1 as default,
                          zoomJump: 0.1, // 0.1 as default,
                        },
                        // pdfVerticalScrollByDefault: true,
                      }}
                      style={{ minHeight: 1152 }}
                    />
                  )}
                </Box>
              </>
            )}
            {activeTab == "profile" && (
              <>
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <Box
                      className={`${
                        width < 640
                          ? app_classes.padding.a4
                          : app_classes.padding.a5
                      }`}
                    >
                      <Box className={`${app_classes.margin.b5}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H4 primary>
                            About you
                          </JobluTypography.H4>
                        </Box>
                        <Box>
                          <JobluTypography.H6 primary>
                            {" "}
                            {current_user?.bio ? (
                              <>{current_user?.bio} </>
                            ) : (
                              "You haven't told us anything about yourself yet"
                            )}
                          </JobluTypography.H6>
                        </Box>
                      </Box>

                      <Box className={`${app_classes.margin.b5}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H4 primary>
                            Education
                          </JobluTypography.H4>
                        </Box>
                        <Box>
                          {current_user?.educationHistory?.length > 0 ? (
                            <>
                              {current_user?.educationHistory?.length > 0 &&
                                current_user?.educationHistory
                                  ?.sort(function (a, b) {
                                    return (
                                      new Date(b.educStartDate) -
                                      new Date(a.educStartDate)
                                    );
                                  })
                                  ?.map((educ, educ_index) => {
                                    return (
                                      <>
                                        <Box
                                          style={{
                                            backgroundColor: "#E4ECF3",
                                            borderRadius: "10px",
                                          }}
                                          className={`${
                                            app_classes.padding.a3
                                          } ${
                                            current_user?.educationHistory
                                              ?.length > 1 &&
                                            app_classes.margin.b2
                                          }`}
                                        >
                                          <Box>
                                            <JobluTypography.H6 secondary>
                                              {educ?.educField}
                                            </JobluTypography.H6>
                                          </Box>
                                          <Box>
                                            <JobluTypography.Body>
                                              {educ?.educationType}
                                            </JobluTypography.Body>
                                          </Box>
                                          <Box>
                                            <JobluTypography.Body>
                                              {educ?.educName}
                                            </JobluTypography.Body>
                                          </Box>
                                          <Box>
                                            <JobluTypography.Body>
                                              {formatDateRange2(
                                                educ?.educStartDate,
                                                educ?.educEndDate
                                              )}
                                            </JobluTypography.Body>
                                          </Box>
                                        </Box>
                                      </>
                                    );
                                  })}
                            </>
                          ) : (
                            <>
                              <Box width={"100%"}>
                                <Box
                                  width={"100%"}
                                  style={{
                                    backgroundColor: "#E4ECF3",
                                    borderRadius: "10px",
                                  }}
                                  className={`${app_classes.padding.a3}`}
                                >
                                  <JobluTypography.Caption>
                                    Your profile is incomplete without your
                                    educational background.
                                  </JobluTypography.Caption>
                                </Box>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>

                      {current_user?.experienceHistory?.length > 0 ? (
                        <>
                          <Box>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.H4 primary>
                                Experience
                              </JobluTypography.H4>
                            </Box>
                            {current_user?.experienceHistory
                              ?.sort(function (a, b) {
                                return (
                                  new Date(b.startDate) - new Date(a.startDate)
                                );
                              })
                              .map((exp, exp_index) => {
                                return (
                                  <>
                                    <Box width={"100%"}>
                                      <Box
                                        width={"100%"}
                                        style={{
                                          backgroundColor: "#E4ECF3",
                                          borderRadius: "10px",
                                        }}
                                        className={`${app_classes.padding.a3} ${
                                          current_user?.experienceHistory
                                            ?.length > 1 &&
                                          app_classes.margin.b2
                                        }`}
                                      >
                                        <Box>
                                          <JobluTypography.H6 secondary>
                                            {exp?.companyName}
                                          </JobluTypography.H6>
                                        </Box>
                                        <Box>
                                          <JobluTypography.Body>
                                            {exp?.jobTitle}
                                          </JobluTypography.Body>
                                        </Box>
                                        <Box>
                                          <JobluTypography.Body>
                                            {exp?.location}
                                          </JobluTypography.Body>
                                        </Box>
                                        <Box
                                          className={`${app_classes.margin.b2}`}
                                        >
                                          <JobluTypography.Body>
                                            {formatDateRange2(
                                              exp?.startDate,
                                              exp?.endDate
                                            )}
                                          </JobluTypography.Body>
                                        </Box>
                                        {exp?.responsibilities && (
                                          <>
                                            <Box>
                                              <JobluTypography.Body>
                                                Responsibilities:
                                              </JobluTypography.Body>
                                            </Box>
                                            <Box
                                              className={`${
                                                exp?.achievements &&
                                                app_classes.margin.b2
                                              }`}
                                            >
                                              <JobluTypography.Body
                                                style={{
                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                {exp?.responsibilities}
                                              </JobluTypography.Body>
                                            </Box>
                                          </>
                                        )}
                                        {exp?.achievements && (
                                          <>
                                            <Box>
                                              <JobluTypography.Body>
                                                Achievements:
                                              </JobluTypography.Body>
                                            </Box>
                                            <Box
                                              className={`${app_classes.margin.b2}`}
                                            >
                                              <JobluTypography.Body
                                                style={{
                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                {exp?.achievements}
                                              </JobluTypography.Body>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box className={`${app_classes.margin.b2}`}>
                            <JobluTypography.H4 primary>
                              Experience
                            </JobluTypography.H4>
                          </Box>
                          <Box width={"100%"}>
                            <Box
                              width={"100%"}
                              style={{
                                backgroundColor: "#E4ECF3",
                                borderRadius: "10px",
                              }}
                              className={`${app_classes.padding.a3}`}
                            >
                              <JobluTypography.Caption>
                                Your profile doesn't show your work history yet.
                              </JobluTypography.Caption>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    style={{
                      borderLeft: width > 640 ? "1px solid #8080804D" : "none",
                    }}
                  >
                    <Box
                      className={`${
                        width < 640
                          ? app_classes.padding.a4
                          : app_classes.padding.a5
                      }`}
                    >
                      <Box className={`${app_classes.margin.b5}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H4 primary>
                            Preference
                          </JobluTypography.H4>
                        </Box>
                        {current_user?.employmentType?.length > 0 ||
                        current_user?.workSetup?.length > 0 ? (
                          <>
                            {current_user?.workSetup?.length > 0 && (
                              <>
                                <Box className={`${app_classes.margin.b3}`}>
                                  <Box className={`${app_classes.margin.b1}`}>
                                    <JobluTypography.H5>
                                      Work Setup
                                    </JobluTypography.H5>
                                  </Box>
                                  <Box>
                                    <JobluTypography.H6
                                      style={{
                                        color: "#6E88A3",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {current_user?.workSetup?.join(", ")}
                                    </JobluTypography.H6>
                                  </Box>
                                </Box>
                              </>
                            )}
                            {current_user?.employmentType?.length > 0 && (
                              <>
                                <Box className={`${app_classes.margin.b1}`}>
                                  <JobluTypography.H5>
                                    Employment Type
                                  </JobluTypography.H5>
                                </Box>
                                <Box>
                                  <JobluTypography.H6
                                    style={{
                                      color: "#6E88A3",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {current_user?.employmentType?.join(", ")}
                                  </JobluTypography.H6>
                                </Box>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Box className={`${app_classes.text.center}`}>
                              <JobluTypography.H6
                                style={{
                                  color: "#6E88A3",
                                  fontSize: "18px",
                                }}
                              >
                                We're still in the dark about your work
                                preferences
                              </JobluTypography.H6>
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box className={`${app_classes.margin.b5}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H4 primary>
                            Technical Skills
                          </JobluTypography.H4>
                        </Box>
                        <Box>
                          {current_user?.skills?.length > 0 ? (
                            <>
                              {" "}
                              {current_user?.skills?.map((skill) => {
                                return (
                                  <Chip
                                    className={`${classes.chip_active}`}
                                    label={
                                      <JobluTypography.Caption
                                        className={`${app_classes.text.white}`}
                                      >
                                        {skill}
                                      </JobluTypography.Caption>
                                    }
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <Box className={`${app_classes.text.center}`}>
                                <JobluTypography.H6
                                  style={{
                                    color: "#6E88A3",
                                    fontSize: "18px",
                                  }}
                                >
                                  You haven't highlighted your technical
                                  competencies yet
                                </JobluTypography.H6>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                      <Box className={`${app_classes.margin.b5}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H4 primary>
                            Soft Skills
                          </JobluTypography.H4>
                        </Box>
                        {current_user?.softSkills?.length > 0 ? (
                          <>
                            {current_user?.softSkills?.map((skill) => {
                              return (
                                <Chip
                                  className={`${classes.chip_active}`}
                                  label={
                                    <JobluTypography.Caption
                                      className={`${app_classes.text.white}`}
                                    >
                                      {skill}
                                    </JobluTypography.Caption>
                                  }
                                />
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <Box className={`${app_classes.text.center}`}>
                              <JobluTypography.H6
                                style={{
                                  color: "#6E88A3",
                                  fontSize: "18px",
                                }}
                              >
                                Your soft skills section is currently empty
                              </JobluTypography.H6>
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box className={`${app_classes.margin.b5}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H4 primary>
                            What I value most
                          </JobluTypography.H4>
                        </Box>
                        {current_user?.importantForYou?.length > 0 ? (
                          <>
                            {current_user?.importantForYou?.map((value) => {
                              return (
                                <Chip
                                  className={`${classes.chip_active}`}
                                  label={
                                    <JobluTypography.Caption
                                      className={`${app_classes.text.white}`}
                                    >
                                      {value}
                                    </JobluTypography.Caption>
                                  }
                                />
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <Box className={`${app_classes.text.center}`}>
                              <JobluTypography.H6
                                style={{
                                  color: "#6E88A3",
                                  fontSize: "18px",
                                }}
                              >
                                We'd love to know what matters most to you in
                                this role so we can match you with the company
                                that aligns with your values
                              </JobluTypography.H6>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
        {nextStep && (
          <>
            <Box className={`${app_classes.margin.b3}`}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    style={{ textTransform: "none" }}
                  >
                    Take me to Jobs
                  </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={addMoreDetails}
                    style={{ textTransform: "none" }}
                  >
                    Add more details
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

const mapDispatchToProps = (state) => {
  return { AppState: state.Generals };
};

export default connect(mapDispatchToProps)(ProfilePreview);
