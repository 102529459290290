import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import googleMeetLogo from "../assets/icons/logos_google-meet.svg";
import {
  CURRENT_VERSION_DATE,
  profilePlaceholder_Applicant,
} from "./constants";



export const generateCode = () => {
  return Math.floor(Math.random() * 1000000);
};
export const formatDate = (date) => {
  return moment(date).format("DD MMMM YYYY, h:mm:ss a");
};
export const formatDateRange = (date1, date2) => {
  const startDate = moment(date1).format("MMMM D");
  let endDate;
  if (moment(date2).format("MM") === moment(date1).format("MM"))
    endDate = moment(date2).format("D, YYYY");
  else endDate = moment(date2).format("MMMM D, YYYY");
  return `${startDate} - ${endDate}`;
};
export const formatDateOnly = (date) => {
  return moment(date).format("DD MMM YYYY");
};

export const trimPhone = (phone_number = 0, digit = 0) => {
  let number = parseInt(phone_number).toString();

  while (number.charAt(0) === digit?.toString()) {
    number = number.substring(1);
  }
  return number;
};

export const getCountryCodeFromNumber = (countryCodesList, value) => {
  let countryCodeValue;

  if (!value) return null;

  let selectedCountry = countryCodesList?.filter((item) =>
    item?.value?.includes(value)
  );

  if (selectedCountry) {
    countryCodeValue = selectedCountry[0]?.value;
  }
  return countryCodeValue;
};

export const readableDate = (date) => {
  return moment(date).format("MMMM DD, YYYY");
};

export const formatDateOnlyv2 = (date) => {
  return moment(date).format("MM/DD/YYYY");
};
export const fromNow = (date) => {
  return moment(date).fromNow();
};

export const checkIfJobFairIsOngoing = (date) => {
  const dateToday = moment();
  return dateToday.isBefore(date);
};

export const checkIfNew = (date) => {
  const dateToday = moment();
  return dateToday.diff(date, "weeks");
};

export const getYear = (date) => {
  return moment(date).year();
};

export const getAge = (date) => {
  return moment().diff(date, "years");
};

export const randomizeNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isBeforeDate = (date) => {
  var currentVersionDate = moment(CURRENT_VERSION_DATE);
  var appStoreVersionDate = moment(date);
  return currentVersionDate.isBefore(appStoreVersionDate);
};

export const formatter = (num, fixed) => {
  if (num) {
    var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return num?.toString()?.match(re)[0];
  }
};

export const isEmailPatternValid = (email) => {
  // const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = emailPattern.test(email.replace(/\s/g, ""));

  return isValid;
};

export const isPhoneNumberValid = (number) => {
  // const numberPattern = /^\+?[1-9]\d{1,14}$/;
  const numberPattern = /^\+?[0-9]\d{4,14}$/;
  return numberPattern.test(number);
};

export const formatMoney = (x, options, base) => {
  let value = x;
  if (value) {
    let symbol = `S$`;
    if (options) {
      // if(options?.currency !== 'SGD'){
      //   value = convertCurrency(options?.currencyList, options?.currency, x, base)
      //   symbol = getCurrencySymbol(options?.currency)
      // }
      value = convertCurrency(
        options?.currencyList,
        options?.currency,
        x,
        base
      );
      symbol = getCurrencySymbol(options?.currency);
    }
    var parts = formatter(value, 2)?.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (value < 0) {
      var temp = `${symbol}${parts.join(".")}`;
      return temp[1] + temp[0] + temp.slice(2);
    } else {
      return `${symbol}${parts.join(".")}`;
    }
  }
};

// export const formatMoney = (x, options) => {
//     let value = x
//     let symbol = `S$`
//     const appState = store.getState().Generals
//     const { currency, currencyList } = appState
//     if (options) {
//         if (options?.currency !== 'SGD') {
//             value = convertCurrency(options?.currencyList, options?.currency, x)
//             symbol = getCurrencySymbol(options?.currency)
//         }
//     } else if (currency && currencyList) {
//         if (currency !== 'SGD') {
//             value = convertCurrency(currencyList, currency, x)
//             symbol = getCurrencySymbol(currency)
//         }
//     }
//     var parts = formatter(value, 2).split('.')
//     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
//     if (value < 0) {
//         var temp = `${symbol}${parts.join('.')}`
//         return temp[1] + temp[0] + temp.slice(2)
//     } else {
//         return `${symbol}${parts.join('.')}`
//     }
// }

const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "SGD":
      return `S$`;
    case "USD":
      return `$`;
    case "PHP":
      return `₱`;
    case "INR":
      return `₹`;
    case "MYR":
      return `RM `;
    default:
      return `S$`;
  }
};

export const formatCode = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx"
    if (cvLength < 3) return currentValue;

    // returns: "xx/", "xx/x", "xxx/xx"
    if (cvLength < 5)
      return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    // return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

export const formatCardNumber = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx", "xxxx"
    if (cvLength < 5) return currentValue;

    // returns: "xxxx ", "xxxx x", "xxxx xx", "xxxx xxx", "xxxx xxxx"
    if (cvLength < 9)
      return `${currentValue.slice(0, 4)} ${currentValue.slice(4)}`;

    // returns: "xxxx xxxx ", "xxxx xxxx x", "xxxx xxxx xx", "xxxx xxxx xxx", "xxxx xxxx xxxx"
    if (cvLength < 13)
      return `${currentValue.slice(0, 4)} ${currentValue.slice(
        4,
        8
      )} ${currentValue.slice(8, 12)}`;

    // returns: "xxxx xxxx xxxx ", "xxxx xxxx xxxx x", "xxxx xxxx xxxx xx", "xxxx xxxx xxxx xxx", "xxxx xxxx xxxx xxxx"
    return `${currentValue.slice(0, 4)} ${currentValue.slice(
      4,
      8
    )} ${currentValue.slice(8, 12)} ${currentValue.slice(12, 16)}`;
  }
};

export const formatString = (str) => {
  if (str !== "" && str !== undefined && str !== null)
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  else return "";
};

export const computeProfileCompletion = (user) => {
  const importantFields = [
    "companyWebsite",
    "companyOverview",
    "profileUrl",
    "coverUrl",
    "companySize",
    "companyMission",
    "productsAndServices",
  ];

  let percent = 30;
  importantFields.forEach((field) => {
    if (field in user && !!user[field]) {
      percent += 10;
    }
  });

  // const total_profile = Object.values(user).length - 9
  // const completed_profile =
  //     Object.values(user).filter((item) => item != null).length - 6
  // alert(completed_profile/total_profile*100)
  return percent;
};

// export const convertCurrency = (currencyList, newCurrency, value) => {
//     return Number(value * currencyList.currencies[newCurrency].toPrecision(1))
// }

export const convertCurrency = (currencyList, newCurrency, value, base) => {
  var currencies = currencyList[base].currencies;
  let newVal = 0;
  if (currencies) {
    newVal = Number(value * currencies[newCurrency].toPrecision(1));
  } else {
    newVal = 0;
  }
  return newVal;
};

export const convertCurrencyRange = (currencyList, newCurrency, value) => {
  // console.log(currencies)
};

export const convertFirebaseDate = (myDate) => {
  return new Date(myDate.seconds * 1000 + myDate.nanoseconds / 1000000);
};

export const get_url_extension = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

export const handle_file_download = (url, filename) => {
  try {
    axios.get(url, { responseType: "blob" }).then((res) => {
      fileDownload(res.data, filename);
    });
  } catch (err) {
    console.log("error downloading file", err);
  }
};

export const titleCase = (str, first_letter_only) => {
  const conjunction_words = [
    "for",
    "and",
    "nor",
    "but",
    "of",
    "yet",
    "so",
    "as",
    "that",
  ];
  if (str) {
    var splitStr = str.split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      if (conjunction_words.includes(splitStr[i].toLowerCase())) {
        //do nothing with conjunction words
      } else {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
    }
    // Directly return the joined string

    return splitStr.join(" ");
  } else return "";
};

export function thousandSeparator(x = 0) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function generateRandomChars(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const createURL = (url) => {
  return new URL(url);
};

export const getYearDifference = (date) => {
  const dateToday = moment();
  return dateToday.diff(date, "years");
};

export const getYearsOfExpCategory = (x) => {
  if (x > 7) {
    return "7+";
  } else if (x <= 7 && x >= 5) {
    return "5-7";
  } else if (x <= 5 && x >= 3) {
    return "3-5";
  } else if (x <= 1 && x >= 3) {
    return "1-5";
  } else if (x <= 1) {
    return "0-1";
  } else return "0-1";
};

export const getSwipeActionLabel = (action) => {
  switch (action) {
    case "superLike":
      return "Superliked";
    case "like":
      return "Liked";
    default:
      return "";
  }
};
export const getInterviewProviderLogo = (provider) => {
  switch (provider) {
    case "SKYPE":
      return "";
    case "GOOGLE":
      return googleMeetLogo;
    default:
      return "";
  }
};

export const getVideoCallLink = (matchInterview) => {
  let meetUrl;
  if (matchInterview?.provider === "GOOGLE") {
    const { data } = matchInterview;
    meetUrl = data.hangoutLink;
  } else if (
    matchInterview?.provider === "MICROSOFT" ||
    matchInterview?.provider === "SKYPE"
  ) {
    const { data } = matchInterview;
    meetUrl = data?.onlineMeeting?.joinUrl;
  } else if (matchInterview?.provider === "ZOOM") {
    const { data } = matchInterview;
    meetUrl = data?.join_url;
  }
  return meetUrl;
};

export const getMeetingProviderDetails = (provider) => {
  switch (provider) {
    case "GOOGLE":
      return {
        img: require("../assets/third-party-logos/meetLogo.png"),
        name: "Google Meet",
      };
    case "SKYPE":
      return {
        img: require("../assets/third-party-logos/skype.png"),
        name: "Skype",
      };
    case "ZOOM":
      return {
        img: require("../assets/third-party-logos/zoomLogo.png"),
        name: "Zoom",
      };
    case "MICROSOFT" || "TEAMS":
      return {
        img: require("../assets/third-party-logos/teams.png"),
        name: "Teams",
      };
    default:
      return {
        img: null,
        name: null,
      };
  }
};

export const formatDateOnlyv3 = (date) => {
  return moment(date).format("MMM DD, YYYY");
};

export const formatDateRange2 = (date1, date2) => {
  const startDate = moment(date1).format("MMMM YYYY");
  const endDate = moment(date2).format("MMMM YYYY");
  return `${startDate} - ${date2 ? endDate : "Present"}`;
};

export const getInterviewTime = (start, end) => {
  let isSameMeridian = false;
  let start_dt =
    start?.timeZone === "UTC"
      ? moment.utc(start?.dateTime).local()
      : moment(start?.dateTime);
  let end_dt =
    end?.timeZone === "UTC"
      ? moment.utc(end?.dateTime).local()
      : moment(start?.dateTime);

  if (start_dt.format("A") === end_dt.format("A")) {
    isSameMeridian = true;
  }

  if (isSameMeridian) {
    return `${start_dt.format("hh:mm")} - ${end_dt.format("hh:mm A")}`;
  } else {
    return `${start_dt.format("hh:mm A")} - ${end_dt.format("hh:mm A")}`;
  }
};

export const getJobCompanyProfileUrl = (job) => {
  return job?.companyLogo
    ? job.companyLogo
    : job.employer?.profileUrl
    ? job.employer?.profileUrl
    : profilePlaceholder_Applicant;
};

export const getJobCompanyName = (job) => {
  return job?.companyName ? job?.companyName : job?.employer?.companyName;
};

export const processTotalExperience = (exp_min = 0, exp_max = 0) => {
  let exp = [exp_min, exp_max];
  const min = Math.min(...exp);
  const max = Math.max(...exp);
  if (min === 0 && max === 0) {
    return null;
  } else {
    let suffix = "years";
    let range;
    if (min === 0 && max <= 1) {
      range = "0 - 1";
      suffix = "year";
    }
    if (min >= 1 && max <= 3) {
      range = "1 - 3";
    }
    if (min >= 1 && max <= 3) {
      range = "1 - 3";
    }
    if (min >= 3 && max <= 5) {
      range = "3 - 5";
    }
    if (min >= 5 && max <= 7) {
      range = "5 - 7";
    }
    if (min >= 7 && max >= 7) {
      range = "7";
      suffix = "years and above";
    }

    return `${range} ${suffix}`;
  }
};

export const searchMatchJob = (orig_list, searchText) => {
  return orig_list.filter((data) => {
    const job = data.matchJob;
    console.log("job", data);
    const regex = new RegExp(searchText.toLowerCase());
    if (
      job?.jobTitle?.toLowerCase().match(regex) ||
      job?.location?.toLowerCase().match(regex) ||
      job?.companyName?.toLowerCase().match(regex) ||
      data?.matchEmployer?.companyName?.toLowerCase().match(regex)
    ) {
      console.log("matched", job?.jobTitle);
      return true;
    }
    return false;
  });
};

export const isNullOrEmptySpaces = (value) => {
  const isNullEmpty = value == null || value === undefined || value === "";

  if (isNullEmpty === true) return true;
  if (value?.trim() === "") return true;

  return false;
};

export const removeCommas = (x) => {
  return parseFloat(x.toString().replace(/,/g, ""));
};

export const replaceSpaces = (string = "", replaceTo = "-") => {
  const lowerCaseString = string?.toLowerCase();
  const noSpecialChars = lowerCaseString?.replace(/[^a-zA-Z0-9 ]/g, "");
  return noSpecialChars?.replace(/ /g, replaceTo);
};

export const openNewConnect = (job, open = false) => {
  if(job?.isNewConnect && job?.jobDirectLink){
    if(open){
      window.open(job?.jobDirectLink)
    }
    else{
      window.location.href = job?.jobDirectLink
    }
    return true;
  }
  else{
    return false;
  }
}
