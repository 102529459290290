import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Paper,
  Tooltip,
} from "@material-ui/core";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultProfileBg from "../../assets/img/companyShowcase/profileBg.png";
import DefaultImg from "../../assets/img/defaultImg.png";
import EducationIcon from "../../assets/img/jobs/EducationIcon.png";
import ShareArrow from "../../assets/img/jobs/shareArrow.png";
import {
  applicantRequests,
  jobsRequest,
  userRequest,
} from "../../service/requests";

import { Button } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import DismissIcon from "../../assets/img/jobs/DismissIcon.png";
import EmploymentTypeIcon from "../../assets/img/jobs/EmploymentTypeIcon.png";
import ExperienceIcon from "../../assets/img/jobs/ExperienceIcon.png";
import IndustryIcon from "../../assets/img/jobs/IndustryIcon.png";
import LikeIcon from "../../assets/img/jobs/LikeIcon.png";
import LocationIcon from "../../assets/img/jobs/LocationIcon.png";
import SalaryIcon from "../../assets/img/jobs/SalaryIcon.png";
import WorkSetupIcon from "../../assets/img/jobs/WorkSetupIcon.png";
import JobluTypography from "../../components/Typography";
import { modal_styles } from "../../components/modals/styles";
import { app_styles } from "../../general.styles";
import appActions from "../../redux/app/actions";
import { errorSwipeActions, salaryRanges } from "../../utils/constants";
import { formatMoney, formatString, openNewConnect, replaceSpaces } from "../../utils/helpers";
import useWindowDimensions from "../../utils/hooks";

import MetaTags from "react-meta-tags";
import TextFieldV1 from "../../components/inputs/CustomTextField";
import SwipeLimitModal from "../../components/modals/SwipeLimitsModal";
import rootAction from "../../redux/action";
import NotFound from "../not-found";
import axios from 'axios';
import moment from 'moment';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { InpageBanner, MediumBanner, MultiBanner, SquareBanner, VignetteBanner } from "../../components/adbanner";

export const SWIPE_ACTIONS = {
  LIKE: "like",
  DISMISS: "not_interested",
  SUPERLIKE: "superLike",
};
const ViewJob = (props) => {
  const { AppState, User, showAlert, signOut } = props;
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const params = useParams();
  const refId = params.refId;
  const _location = useLocation();

  const actions = new URLSearchParams(_location.search).get("actions");

  const app_classes = app_styles(props);
  const modal_classes = modal_styles();
  const [job, setJob] = useState(null);
  const [employerDetails, setEmployerDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currencyList, currency } = AppState;
  const currencyOptions = {
    currencyList,
    currency,
  };
  useEffect(() => {
    console.log(refId);
    jobsRequest
      .getJobDetailsByRefId(refId)
      .then((res) => {
        setJob(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [refId]);

  useEffect(() => {
    if (job) {
      userRequest.getEmployerDetailsP(job?.employerId).then((res) => {
        console.log("employer", res?.data);
        setEmployerDetails(res?.data?.employerProfiles);
      });
    }
  }, [job]);

  const [existing, setExisting] = useState(false);
  const [matchStatus, setMatchStatus] = useState(null);
  const [applicationStatus, setApplicationStatus] = useState(null);
  const [liked, setLiked] = useState(false);

  const getStatus = () => {
    if(job?._id){
      const payload = {
        jobId: job?._id,
      };
      jobsRequest.getMatchStatus(payload).then((res) => {
        setExisting(res?.data?.existing);
        setMatchStatus(res?.data?.matchStatus);
        setApplicationStatus(res?.data?.applicationStatus);
        setLiked(res?.data?.liked);
      });
    }
  };
  useEffect(() => {
    getStatus();
    console.log("getStatus");
  }, [job]);

  const addView = (ip) => {
    userRequest.addView({
      logData: {
        id: ip,
        link: `https://jobs.joblu.io/job/${job?.referenceId}`
      },
      id: job?.referenceId,
      type: "JOB"
    })
    .then(res => console.log(res))
    .catch(err => console.log(err))
  }

  useEffect(() => {
    if(job){
      axios.get("https://geolocation-db.com/json/")
      .then(res => {
        addView(res?.data?.IPv4)
      })
      .catch(e => {
        addView(Math.floor(Math.random() * 900000) + 100000)
      })
    }
  }, [job])

  const [jobData, setJobData] = useState(null);
  const [empImg, setEmpImg] = useState(null);
  const [appImg, setAppImg] = useState(null);
  const [matchOpen, setMatchOpen] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const [showEmailVerifiedModal, setShowVerifyEmailModal] = useState(false);

  const [limitOpen, setLimitOpen] = useState(false);
  const [limitMainText, setLimitMainText] = useState(null);
  const [limitSubText, setLimitSubText] = useState(null);

  const [showJobAction, setShowJobAction] = useState(false);
  const [jobActionData, setJobActionData] = useState({
    action: null,
    job: null,
  });

  const [isMatched, setIsMatched] = useState(null);
  const onSwipeEnd = (matchData) => {
    //perform card removal
    if (matchData && matchData.matchStatus === "match") {
      setIsMatched(matchData.matchStatus);
    }
    // setShowActions(false);
    getStatus();
  };

  const [showVerifyEmail, setShowVerifyEmail] = useState(false);

  const handleCloseJobAction = () => {
    setShowJobAction(false);
    if (isMatched == "match") {
      setTimeout(() => {
        setMatchOpen(true);
      }, 500);
    }
  };

  const handleLogout = () => {
    try {
      userRequest
        .signOut({ token: User?.token })
        .then((res) => {
          signOut();
        })
        .finally(() => {
          setTimeout(() => {
            navigate("/login");
          }, 500);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSwipe = (job, action) => {
    const newConnect = openNewConnect(job, true);
    if(newConnect) return;
    const jobId = job?._id;
    const employerId = job.employerId;
    console.log(jobId, employerId);
    if (
      (action === SWIPE_ACTIONS.SUPERLIKE || action === SWIPE_ACTIONS.LIKE) &&
      User?.user?.isEmailVerified === false
    ) {
      setShowVerifyEmailModal(true);
    } else {
      let swipeActionRequest;
      if (action === SWIPE_ACTIONS.SUPERLIKE) {
        swipeActionRequest = applicantRequests.jobs.actions.superlike(
          jobId,
          employerId
        );
      }

      if (action === SWIPE_ACTIONS.LIKE) {
        swipeActionRequest = applicantRequests.jobs.actions.like(
          jobId,
          employerId
        );
      }
      if (action === SWIPE_ACTIONS.DISMISS) {
        swipeActionRequest = applicantRequests.jobs.actions.dismiss(
          jobId,
          employerId
        );
      }

      swipeActionRequest
        .then((res) => {
          console.log(res);
          if (action === SWIPE_ACTIONS.LIKE) {
            setJobActionData({
              action: "liked",
              job,
              message: (
                <>
                  Now let’s just wait for the employer’s <br /> response.
                </>
              ),
            });
            setShowJobAction(true);
          }
          if (action === SWIPE_ACTIONS.DISMISS) {
            setJobActionData({
              action: "disliked",
              job,
              message: (
                <>
                  This job will be dismissed and it won’t appear in your feed
                  anymore.
                </>
              ),
            });
            setShowJobAction(true);
          }

          if (
            res.data.message === errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
          ) {
            setLimitMainText("You have run out of likes today!");
            setLimitSubText(
              "That was fast! But don't worry, you can like again tomorrow."
            );
            setLimitOpen(true);
          } else if (
            res.data.message === errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
          ) {
            setLimitMainText("You have run out of likes for this month!");
            setLimitSubText("The great news is you can like again next month!");
            setLimitOpen(true);
          } else if (
            res.data.message === errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
          ) {
            setLimitMainText("You have run out of Super Likes!");
            setLimitSubText(
              "That was fast! But don't worry, you can Super Like again tomorrow."
            );
            setLimitOpen(true);
          } else if (res.data.message === errorSwipeActions.LIKE_NOT_ALLOWED) {
            setLimitMainText("Please upgrade account to start swiping!");
            setLimitSubText(
              "You are currently using a FREE account. Upgrade now to like this applicant."
            );
            setLimitOpen(true);
          } else {
            if (res.data.matchStatus == "match") {
              console.log("matched");
            }
          }
          onSwipeEnd(res.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  };

  const handleGoToMessage = (data) => {
    console.log("a");
    navigate("/messages", {
      state: { data },
    });
  };

  const [showShareModal, setShowShareModal] = useState(false);
  const [jobLink, setJobLink] = useState(null);
  const [jobCopied, setJobCopied] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(jobLink);
    setJobCopied(true);
    setTimeout(() => {
      setJobCopied(false);
    }, 2000);
  };

  const MetaTag = () => {
    const jobTitle = formatString(job?.jobTitle);
    const companyName = job?.companyName
      ? job?.companyName
      : job?.employer?.companyName;
    const dateNow = moment().format("MMMM YYYY");

    if(job){
      return (
        <MetaTags>
          <title>{`${jobTitle} Job Opening at ${companyName} | Joblu`}</title>
          <meta
            name="description"
            content={`Apply for the ${jobTitle} in ${companyName} through Joblu. ${employerDetails?.companyOverview?.substring(
              0,
              50
            )}`}
          />
          <meta
            property="og:title"
            content={`${jobTitle} Job Opening at ${companyName} | Joblu`}
          />
        </MetaTags>
      );
    }
    else{
      return (
        <MetaTags>
          <title>{`Job Openings at Joblu | ${dateNow}`}</title>
          <meta
            name="description"
            content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
          />
          <meta
            property="og:title"
            content={`IT, Customer Service, Accounting Job Openings at Joblu | ${dateNow}`}
          />
          <meta
            property="og:description"
            content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
          />
          <meta property="og:image"
            content="https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/joblu-meta-img.jpeg" />
      </MetaTags>
      );
    }
  };

  const MetaTag_ = () => {
    const dateNow = moment().format("MMMM YYYY");
    return (
      <MetaTags>
        <title>{`Job Openings at Joblu | ${dateNow}`}</title>
        <meta
          name="description"
          content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
        />
        <meta
          property="og:title"
          content={`IT, Customer Service, Accounting Job Openings at Joblu | ${dateNow}`}
        />
        <meta
          property="og:description"
          content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
        />
        <meta property="og:image"
          content="https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/joblu-meta-img.jpeg" />
    </MetaTags>
    )
  }

  const [coverHeight, setCoverHeight] = useState(null)
  const [coverWidth, setCoverWidth] = useState(null)

  if (loading) {
    return (
      <>
        <MetaTag_/>
        <Box
          className={`${app_classes.height.vh100} ${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center} ${app_classes.flexBox.justify_content_center}`}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      </>
    );
  } else {
    if (!job) {
      return (
        <>
        <MetaTag_/>
        <NotFound/>
        </>
      )
    }

    if(job?.status !== 'active'){
      return (
        <>
        <MetaTag_/>
        <NotFound/>
        </>
      )
    }

    if(job?.isVerified !== true){
      return (
        <>
        <MetaTag_/>
        <NotFound/>
        </>
      )
    }
    
    return (
      <>
        <MetaTag />
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
        >
          <Box
            className={`${
              width < 640 ? app_classes.padding.a3 : app_classes.padding.a5
            }`}
          >
            <Box
              className={`${app_classes.text.center} ${app_classes.margin.b3}`}
            >
              <JobluTypography.H4 primary>
                Sharing {job?.jobTitle} Job
              </JobluTypography.H4>
            </Box>
            <Grid
              container
              spacing={2}
              style={{
                alignContent: "center",
                justifyContent: "center",
                marginBottom: 16,
              }}
            >
              <Grid item xs={2} sm={2} className={`${app_classes.text.center}`}>
                <FacebookShareButton url={jobLink}>
                  <FacebookIcon size={width < 640 ? 36 : 48} round />
                </FacebookShareButton>
              </Grid>
              <Grid item xs={2} sm={2} className={`${app_classes.text.center}`}>
                <LinkedinShareButton url={jobLink}>
                  <LinkedinIcon size={width < 640 ? 36 : 48} round />
                </LinkedinShareButton>
              </Grid>
              <Grid item xs={2} sm={2} className={`${app_classes.text.center}`}>
                <EmailShareButton url={jobLink}>
                  <EmailIcon size={width < 640 ? 36 : 48} round />
                </EmailShareButton>
              </Grid>
              <Grid item xs={2} sm={2} className={`${app_classes.text.center}`}>
                <WhatsappShareButton url={jobLink}>
                  <WhatsappIcon size={width < 640 ? 36 : 48} round />
                </WhatsappShareButton>
              </Grid>

              <Grid item xs={2} sm={2} className={`${app_classes.text.center}`}>
                <TwitterShareButton url={jobLink}>
                  <TwitterIcon size={width < 640 ? 36 : 48} round />
                </TwitterShareButton>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <TextFieldV1 value={jobLink} disabled />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setJobCopied(false)}
                  open={jobCopied}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Link Copied"
                  placement="bottom"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      copyLink();
                    }}
                  >
                    Copy Link
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
        <SwipeLimitModal
          limitOpen={limitOpen}
          setLimitOpen={setLimitOpen}
          limitMainText={limitMainText}
          limitSubText={limitSubText}
        />
        <Dialog
          fullWidth
          maxWidth={"xs"}
          open={matchOpen}
          onClose={() => {
            setMatchOpen(false);
          }}
          style={{ backgroundColor: "#002E5D73" }}
        >
          <Box
            className={`${app_classes.padding.a4} ${app_classes.text.center} ${app_classes.backgroundColor.bg_white}`}
          >
            <Box className={`${app_classes.text.right} ${app_classes}`}>
              <Close
                className={`${app_classes.cursor.pointer}`}
                onClick={() => {
                  setMatchOpen(false);
                }}
              />
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.H3 primary>It's A Match!</JobluTypography.H3>
            </Box>
            <Box
              className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center}`}
            >
              <Box className={`${app_classes.margin.r1}`}>
                <img
                  src={User?.user?.profileUrl || DefaultImg}
                  width={90}
                  height={90}
                  style={{ objectFit: "cover", background: "#FFFFFF" }}
                />
              </Box>
              <Box className={`${app_classes.margin.b3}`}>
                {" "}
                <img
                  src={employerDetails?.profileUrl || DefaultImg}
                  width={90}
                  height={90}
                  style={{ objectFit: "cover", background: "#FFFFFF" }}
                />
              </Box>
            </Box>
            <Box>
              <Box className={`${app_classes.margin.b3}`}>
                <JobluTypography.Body style={{ fontSize: 14 }}>
                  <strong>{employerDetails?.companyName}</strong> has
                  shortlisted you for the position of{" "}
                  <strong>{job?.jobTitle}</strong>. You can now message them and
                  start your application process!
                </JobluTypography.Body>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  style={{ textTransform: "none" }}
                  color="primary"
                  onClick={() => {
                    handleGoToMessage(job);
                  }}
                >
                  Start Chatting
                </Button>
              </Box>
              <Box>
                <Button
                  variant="text"
                  onClick={() => {
                    setMatchOpen(false);
                  }}
                >
                  <JobluTypography.Body
                    primary
                    className={`${app_classes.text.capitalize}`}
                  >
                    Close
                  </JobluTypography.Body>
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
        <Dialog open={showEmailVerifiedModal} maxWidth="xs">
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <IconButton onClick={() => setShowVerifyEmailModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <Paper style={{ padding: 20, paddingTop: 0 }}>
            <JobluTypography.H6 align="center">
              Your email is not yet verified
            </JobluTypography.H6>
            <JobluTypography.Body
              align="center"
              style={{ fontSize: 14, marginTop: 20 }}
            >
              Click on the link in your email. If you don't see the email in
              your inbox, please check your promotions or spam folder.
            </JobluTypography.Body>
            <JobluTypography.Body
              align="center"
              style={{ fontSize: 14, marginTop: 20 }}
            >
              Once verified, login to your account and enjoy swiping.
            </JobluTypography.Body>

            <Grid container style={{ marginTop: 20 }} spacing={1}>
              <Grid xs={12} sm={6} item>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  style={{ textTransform: "none", margin: 5 }}
                  onClick={() => {
                    setShowVerifyEmailModal(false);
                    handleLogout();
                  }}
                >
                  I have verified my email
                </Button>
              </Grid>
              <Grid xs={12} sm={6} item>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  style={{ textTransform: "none", margin: 5 }}
                  onClick={() => {
                    userRequest
                      .resendVerificationEmail({ email: User?.user?.email })
                      .then((res) => {
                        console.log("resend response", res.data);
                        if (res.data?.queued === true) {
                          showAlert({
                            message:
                              "We have sent the verification link to your email.",
                          });
                          setTimeout(() => {
                            setShowVerifyEmailModal(false);
                          }, 1000);
                        }
                      })
                      .catch(() => {
                        showAlert({
                          message: "An error occured please try again later",
                          type: "error",
                        });
                        setShowVerifyEmailModal(false);
                      });
                  }}
                >
                  Resend verification link
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Dialog>
        <Dialog open={showJobAction} maxWidth="xs" fullWidth>
          <Box
            className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          >
            <JobluTypography.H6 align="center">
              You {jobActionData?.action} a Job
            </JobluTypography.H6>
          </Box>
          <DialogContent>
            <Box className={app_classes.padding.a3}>
              <Box
                className={app_classes.display.display_flex}
                style={{ justifyContent: "center" }}
              >
                <img
                  style={{
                    width: "90px",
                    height: "90px",
                    objectFit: "cover",
                    background: "#FFFFFF",
                  }}
                  alt=""
                  src={
                    jobActionData?.job?.companyLogo
                      ? jobActionData?.job?.companyLogo
                      : jobActionData?.job?.employer?.profileUrl
                      ? jobActionData?.job?.employer?.profileUrl
                      : DefaultImg
                  }
                />
              </Box>
              <JobluTypography.H6
                align="center"
                style={{ marginTop: 20 }}
                semiBold
                primary
              >
                {jobActionData?.job?.jobTitle}
              </JobluTypography.H6>

              <JobluTypography.Body
                style={{ width: "100%", textAlign: "center" }}
              >
                {jobActionData?.job?.companyName ||
                  jobActionData?.job?.employer?.companyName}
              </JobluTypography.Body>
              <JobluTypography.Body
                style={{ textAlign: "center", marginTop: 20 }}
              >
                {jobActionData?.message}
              </JobluTypography.Body>
              <Box
                className={`${app_classes.display.display_flex} ${app_classes.margin.t3}`}
              >
                <Box className={app_classes.general.centered_x}>
                  <Button
                    style={{ textTransform: "none", minWidth: 200 }}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      handleCloseJobAction();
                    }}
                  >
                    Okay
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
        <Grid
          container
          justifyContent={"center"}
          className={`${app_classes.margin.t6} ${app_classes.margin.b6}`}
        >
          {/* <Grid item xs={8} justifySelf="start">
          <Box className={app_classes.margin.b3}>
            <JobluTypography.H6
              className={`${app_classes.cursor.pointer}`}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon /> Back
            </JobluTypography.H6>
          </Box>
        </Grid> */}
          <Grid item xs={11} sm={8}>
            <Card>
              <Grid container justifyContent="center">
              <Grid
                  xs={12}
                  style={{
                    // height: 300,
                    borderRadius: 15,
                    // backgroundImage: `url(${
                    //   employerProfiles?.coverUrl || DefaultProfileBg
                    // })`,
                  }}
                >
                    <img
                      src={employerDetails?.coverUrl || DefaultProfileBg}
                      style={{
                        width: "100%",
                        maxHeight: coverHeight > 500 ? 400 : 'auto',
                        minHeight: 150,
                        objectFit: `${coverHeight > 350 || coverWidth > 900 ?  width <640 ? 'contain' : 'cover'  : 'contain'}`,
                      }}
                      onLoad={(e)=>{
                        setCoverHeight(e.target.naturalHeight)
                        setCoverWidth(e.target.naturalWidth)
                      }}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box
                    className={`${app_classes.padding.a4}`}
                    style={{ marginTop: -120 }}
                  >
                    <Box className={`${app_classes.text.center}`}>
                      <Box className={`${app_classes.margin.b2}`}>
                        <img
                          alt=""
                          src={
                            job?.companyLogo
                              ? job?.companyLogo
                              : job?.employer?.profileUrl
                              ? job?.employer?.profileUrl
                              : DefaultImg
                          }
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                            border: "10px solid #C9CCCE",
                            objectFit: "cover",

                            background: "#FFFFFF",
                          }}
                        />
                      </Box>
                      <Box className={`${app_classes.margin.b1}`}>
                        <JobluTypography.H2
                          semiBold
                          primary
                          className={`${app_classes.text.capitalize}`}
                        >
                          {job?.jobTitle}
                        </JobluTypography.H2>
                      </Box>
                      <>
                        <Box className={`${app_classes.margin.b1}`}>
                          {!job?.companyName ? (
                            <>
                              {" "}
                              <Chip
                                color="primary"
                                label={`${
                                  job?.companyName
                                    ? job?.companyName
                                    : job?.employer?.companyName
                                }`}
                                // className={`${modal_classes.chip_main}`}
                                onClick={() => {
                                  if (!job?.companyName) {
                                    window.open(
                                      `/company-showcase/${
                                        job?.employer?.userName ||
                                        job?.employer?.userId
                                      }`
                                    );
                                  }
                                }}
                                deleteIcon={
                                  <KeyboardArrowRightIcon
                                    style={{ color: "#FFFFFF" }}
                                  />
                                }
                                onDelete={() => {
                                  window.open(
                                    `/company-showcase/${
                                      job?.employer?.userName ||
                                      job?.employer?.userId
                                    }`
                                  );
                                }}
                              ></Chip>
                            </>
                          ) : (
                            <>
                              <Chip
                                color="primary"
                                label={`${
                                  job?.companyName
                                    ? job?.companyName
                                    : job?.employer?.companyName
                                }`}
                                onClick={() => {
                                  if(job?.isNewConnect && job?.directCompanyLink){
                                    window.open(job?.directCompanyLink)
                                    return;
                                  }
                                }}
                                deleteIcon={
                                  <KeyboardArrowRightIcon
                                    style={{ color: "#FFFFFF" }}
                                  />
                                }
                                onDelete={() => {
                                  if(job?.isNewConnect && job?.directCompanyLink){
                                    window.open(job?.directCompanyLink)
                                    return;
                                  }
                                }}
                                // className={`${modal_classes.chip_main}`}
                              ></Chip>
                            </>
                          )}
                        </Box>
                      </>
                      <Box className={`${app_classes.margin.b1}`}>
                        <JobluTypography.Body
                          semiBold
                          className={`${app_classes.text.uppercase}`}
                        >
                          REFERENCE ID: {job?.referenceId}
                        </JobluTypography.Body>
                      </Box>
                      {job?.employer?.website && (
                        <>
                          <Box className={`${app_classes.margin.b2}`}>
                            <Link
                              target="_blank"
                              to={job?.employer?.website}
                              className={app_classes.margin.r1}
                            >
                              <JobluTypography.Body secondary semiBold>
                                Visit their website
                              </JobluTypography.Body>
                            </Link>
                          </Box>
                        </>
                      )}
                    </Box>

                    <Box
                      className={`${
                        !job?.companyName && app_classes.margin.b2
                      }`}
                    >
                      <Box>
                        <JobluTypography.H5 semiBold primary>
                          Responsibilities
                        </JobluTypography.H5>
                      </Box>
                      <Box>
                        <JobluTypography.Body
                          primary
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {job?.responsibilities}
                        </JobluTypography.Body>
                      </Box>
                    </Box>
                    {!job?.companyName && (
                      <>
                        <Box>
                          <Box>
                            <JobluTypography.H5
                              semiBold
                              primary
                              onClick={() => {
                                console.log(job?.employer);
                              }}
                            >
                              About {employerDetails?.companyName}
                            </JobluTypography.H5>
                          </Box>
                          <Box>
                            <JobluTypography.Body
                              primary
                              style={{ whiteSpace: "pre-wrap" }}
                            >
                              {employerDetails.companyOverview}
                            </JobluTypography.Body>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                  {/* <SquareBanner style={{marginTop: 20}}/> */}
                  {/* <MultiBanner/> */}
                  <VignetteBanner/>
                  <InpageBanner/>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={11}
                  md={5}
                  style={{ borderLeft: width > 640 && "1px solid #8080804D" }}
                >
                  <Box className={`${app_classes.padding.a4}`}>
                    <Box className={`${app_classes.margin.b3}`}>
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        justifyContent={`${
                          width < 640 ? "center" : "flex-end"
                        }`}
                      >
                        <>
                          <>
                            {existing ? (
                              matchStatus == "match" ? (
                                <>
                                  <Box
                                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                  >
                                    <Box className={app_classes.margin.r1}>
                                      <JobluTypography.H6
                                        style={{ fontFamily: "Open Sans" }}
                                      >
                                        Application Status:
                                      </JobluTypography.H6>
                                    </Box>
                                    <Box>
                                      <Chip
                                        // color="primary"
                                        style={{
                                          backgroundColor:
                                            applicationStatus === "archived"
                                              ? "#EB5757"
                                              : "#002E5D",
                                          color:
                                            applicationStatus === "archived"
                                              ? "#FFF"
                                              : "#FFF",
                                        }}
                                        label={
                                          <JobluTypography.H6
                                            style={{ fontFamily: "Open Sans" }}
                                            className={`${app_classes.padding.a2}`}
                                          >
                                            {applicationStatus ===
                                              "shortlisted" && "Shortlisted"}
                                            {applicationStatus ===
                                              "scheduled_interview" &&
                                              "For Interview"}
                                            {applicationStatus === "archived" &&
                                              "Unsuccessful"}
                                            {applicationStatus === "hired" &&
                                              "Hired"}
                                          </JobluTypography.H6>
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </>
                              ) : (
                                <Box
                                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                >
                                  {/* {matchStatus}÷ */}
                                  {liked && (
                                    <>
                                      {matchStatus !== "waiting" && (
                                        <Box className={app_classes.margin.r1}>
                                          <JobluTypography.H6
                                            style={{ fontFamily: "Open Sans" }}
                                          >
                                            Application Status:
                                          </JobluTypography.H6>
                                        </Box>
                                      )}
                                    </>
                                  )}

                                  {liked && (
                                    <>
                                      <Box>
                                        <Chip
                                          // color={`${!matchStatus === 'waiting' ? '#555': '#555'}`}
                                          style={{
                                            backgroundColor:
                                              matchStatus === "waiting"
                                                ? "#e0e0e0"
                                                : "#EB5757",
                                            color:
                                              matchStatus === "waiting"
                                                ? "#27323c"
                                                : "#FFF",
                                          }}
                                          label={
                                            <JobluTypography.H6
                                              style={{
                                                fontFamily: "Open Sans",
                                              }}
                                              className={`${app_classes.padding.a2}`}
                                            >
                                              {matchStatus === "waiting" &&
                                                "Applied"}
                                              {matchStatus === "no-match" &&
                                                "Unsuccessful"}
                                            </JobluTypography.H6>
                                          }
                                        />
                                      </Box>
                                    </>
                                  )}
                                </Box>
                              )
                            ) : (
                              <>
                                {" "}
                                {
                                  !job?.isNewConnect
                                  ?
                                  <Grid item>
                                  <img
                                    src={DismissIcon}
                                    onClick={() => {
                                      handleSwipe(job, SWIPE_ACTIONS.DISMISS);
                                    }}
                                    className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                                  />
                                </Grid>
                                : null
                                  
                                }
                               
                                <Grid item>
                                  <img
                                    src={LikeIcon}
                                    onClick={() => {
                                      const newConnect = openNewConnect(job);
                                      if(newConnect) return;
                                      handleSwipe(job, SWIPE_ACTIONS.LIKE);
                                    }}
                                    className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                                  />
                                </Grid>
                              </>
                            )}
                          </>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="large"
                              height={"100%"}
                              onClick={() => {
                                setJobLink(
                                  `https://jobs.joblu.io/job/${
                                    job?.referenceId
                                  }/${replaceSpaces(job?.jobTitle)}`
                                );
                                setShowShareModal(true);
                              }}
                              style={{ borderRadius: 100 }}
                              className={` ${app_classes.margin.r1} `}
                            >
                              Share
                              <img
                                src={ShareArrow}
                                width={16}
                                className={`${app_classes.margin.l1}`}
                              />
                            </Button>
                          </Grid>
                          {/* <Grid item>
                          <Box>
                            <Button
                              variant="contained"
                              size="large"
                              className={`${app_classes.button.primary} ${app_classes.border.border_radius_16}`}
                              onClick={() => {
                                navigate("/login");
                              }}
                            >
                              Apply Now{" "}
                              <SendIcon
                                className={`${app_classes.margin.l1}`}
                              />
                            </Button>
                          </Box>
                        </Grid> */}

                          {/* <Grid item>
                          <img
                            src={DismissIcon}
                            className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                          />
                        </Grid>

                        <Grid item>
                          <img
                            src={LikeIcon}
                            className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                          />
                        </Grid> */}
                        </>
                        {/* <Grid item>
                      <img
                        src={ShareIcon}
                        className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={ExpandIcon}
                        className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                      />
                    </Grid> */}
                      </Grid>
                    </Box>
                    <Box>
                      <Box className={`${app_classes.margin.b3}`}>
                      {/* <MediumBanner fit={true}/> */}
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H5 semiBold primary>
                            Job Summary
                          </JobluTypography.H5>
                        </Box>
                        <>
                          <Box className={`${app_classes.margin.b3}`}>
                            <Box
                              className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                            >
                              <Box className={`${app_classes.margin.r2}`}>
                                <img
                                  src={LocationIcon}
                                  className={`${modal_classes.icons}`}
                                />
                              </Box>
                              <Box>
                                <Box>
                                  <JobluTypography.H6 semiBold primary>
                                    {job?.location}
                                  </JobluTypography.H6>
                                </Box>
                                <Box>
                                  <JobluTypography.Body primary>
                                    Location
                                  </JobluTypography.Body>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>

                        {job?.jobFunction && (
                          <>
                            <Box className={`${app_classes.margin.b3}`}>
                              <Box
                                className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                              >
                                <Box className={`${app_classes.margin.r2}`}>
                                  <img
                                    src={IndustryIcon}
                                    className={`${modal_classes.icons}`}
                                  />
                                </Box>
                                <Box>
                                  <Box>
                                    <JobluTypography.H6 semiBold primary>
                                      {job?.jobFunction}
                                    </JobluTypography.H6>
                                  </Box>
                                  <Box>
                                    <JobluTypography.Body primary>
                                      Industry
                                    </JobluTypography.Body>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box className={`${app_classes.margin.b3}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H5 semiBold primary>
                            What We Offer
                          </JobluTypography.H5>
                        </Box>
                        {!!job?.salaryMin && job?.salaryMax && (
                          <>
                            <Box className={`${app_classes.margin.b3}`}>
                              <Box
                                className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                              >
                                <Box className={`${app_classes.margin.r2}`}>
                                  <img
                                    src={SalaryIcon}
                                    className={`${modal_classes.icons}`}
                                  />
                                </Box>
                                <Box>
                                  <Box>
                                    <JobluTypography.H6 semiBold primary>
                                      {job?.salaryMin === 1 &&
                                      job?.salaryMax === 1
                                        ? salaryRanges.COMPETIIVE_SALARY
                                        : job?.salaryMin === 2 &&
                                          job?.salaryMax === 2
                                        ? salaryRanges.INDUSTRY_STANDARD
                                        : `${formatMoney(
                                            job?.salaryMin,
                                            currencyOptions,
                                            job?.salaryCurrency || "SGD"
                                          )} - ${formatMoney(
                                            job?.salaryMax,
                                            currencyOptions,
                                            job?.salaryCurrency || "SGD"
                                          )}`}
                                    </JobluTypography.H6>
                                  </Box>
                                  <Box>
                                    <JobluTypography.Body primary>
                                      Compensation - Salary
                                    </JobluTypography.Body>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}

                        {job?.employmentType?.length > 0 &&
                          job?.employmentType != "TBD" && (
                            <>
                              <Box className={`${app_classes.margin.b3}`}>
                                <Box
                                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                >
                                  <Box className={`${app_classes.margin.r2}`}>
                                    <img
                                      src={EmploymentTypeIcon}
                                      className={`${modal_classes.icons}`}
                                    />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <JobluTypography.H6 semiBold primary>
                                        {job?.employmentType?.join(", ")}
                                      </JobluTypography.H6>
                                    </Box>
                                    <Box>
                                      <JobluTypography.Body primary>
                                        Employment Type
                                      </JobluTypography.Body>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </>
                          )}
                        {job?.workSetup?.length > 0 &&
                          job?.workSetup != "TBD" && (
                            <>
                              <Box>
                                <Box
                                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                >
                                  <Box className={`${app_classes.margin.r2}`}>
                                    <img
                                      src={WorkSetupIcon}
                                      className={`${modal_classes.icons}`}
                                    />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <JobluTypography.H6 semiBold primary>
                                        {job?.workSetup?.join(", ")}
                                      </JobluTypography.H6>
                                    </Box>
                                    <Box>
                                      <JobluTypography.Body primary>
                                        Work Setup
                                      </JobluTypography.Body>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </>
                          )}
                      </Box>
                      {job?.education?.length > 0 ||
                       (job?.totalExperienceMin || job?.totalExperienceMax &&
                        job?.totalExperienceMax !== 0) && (
                          <>
                            <Box className={`${app_classes.margin.b3}`}>
                              <Box className={`${app_classes.margin.b2}`}>
                                <JobluTypography.H5 semiBold primary>
                                  Requirements
                                </JobluTypography.H5>
                              </Box>
                              {job?.education?.length > 0 &&
                                job?.education != "TBD" ? (
                                  <>
                                    <Box className={`${app_classes.margin.b3}`}>
                                      <Box
                                        className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                      >
                                        <Box
                                          className={`${app_classes.margin.r2}`}
                                        >
                                          <img
                                            src={EducationIcon}
                                            className={`${modal_classes.icons}`}
                                          />
                                        </Box>
                                        <Box>
                                          <Box>
                                            <JobluTypography.H6
                                              semiBold
                                              primary
                                            >
                                              {job?.education?.join(", ")}
                                            </JobluTypography.H6>
                                          </Box>
                                          <Box>
                                            <JobluTypography.Body primary>
                                              Education
                                            </JobluTypography.Body>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                ) 
                                : null}

                              {job?.totalExperienceMin ||
                              job?.totalExperienceMax &&
                              job?.totalExperienceMax !== 0 ? (
                                <>
                                  <Box className={`${app_classes.margin.b2}`}>
                                    <Box
                                      className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                    >
                                      <Box
                                        className={`${app_classes.margin.r2}`}
                                      >
                                        <img
                                          src={ExperienceIcon}
                                          className={`${modal_classes.icons}`}
                                        />
                                      </Box>
                                      <Box>
                                        <Box>
                                          <JobluTypography.H6 semiBold primary>
                                            {job?.totalExperienceMin &&
                                              `${job?.totalExperienceMin}`}
                                            {job?.totalExperienceMax == "100"
                                              ? "+ Years"
                                              : `- ${job?.totalExperienceMax} Years`}
                                          </JobluTypography.H6>
                                        </Box>
                                        <Box>
                                          <JobluTypography.Body primary>
                                            Work Experience
                                          </JobluTypography.Body>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              ) : null}
                            </Box>
                          </>
                        )}

                      {job?.technicalSkills?.length > 0 && (
                        <>
                          <Box className={`${app_classes.margin.b3}`}>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.H5 semiBold primary>
                                Required Skills & Expertise
                              </JobluTypography.H5>
                            </Box>
                            <Box>
                              {job?.technicalSkills?.map((perks) => {
                                return (
                                  <Chip
                                    label={perks}
                                    color="secondary"
                                    className={`${modal_classes.chip}`}
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                        </>
                      )}
                      {job?.perksAndBenefits?.length > 0 && (
                        <>
                          <Box className={`${app_classes.margin.b3}`}>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.H5 semiBold primary>
                                Perks and Benefits
                              </JobluTypography.H5>
                            </Box>
                            <Box>
                              {" "}
                              {job?.perksAndBenefits?.map((perks) => {
                                return (
                                  <Chip
                                    label={perks}
                                    color="secondary"
                                    className={`${modal_classes.chip}`}
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    AppState: state.Generals,
    User: state.User,
  };
};
const mapDispatchToProps = {
  showAlert: appActions.showAlert,
  signOut: rootAction.signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewJob);
