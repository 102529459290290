import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import TextField from '../../components/inputs/CustomTextField'
import CustomTooltip from '../../components/reusables/CustomTooltip'
import { configRequest, jobsRequest } from '../../service/requests'
import useWindowDimensions from '../../utils/hooks'
import './style.css'

function CreateJob(props) {
    const { width } = useWindowDimensions()
    const { User, AppState } = props
    const navigate = useNavigate()
    const location = useLocation()
    const { currency } = AppState

    const [currentIndex, setCurrentIndex] = useState(0)
    const [jobFunctions, setJobFunctions] = useState([])

    const [jobTitle, setJobTitle] = useState(null)
    const [jobFunction, setJobFunction] = useState(null)
    const [skills, setSkills] = useState([])
    const [skillsets, setSkillsets] = useState([])
    const [responsibilities, setResponsibilities] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [currency2, setCurrency2] = useState(null)
    const [salaryMin, setSalaryMin] = useState(null)
    const [salaryMax, setSalaryMax] = useState(null)
    const [country, setCountry] = useState(null)
    const [experienceRequired, setExperienceRequired] = useState(null)
    const [education, setEducation] = useState([])
    const [workSetup, setWorkSetup] = useState([])
    const [employmentType, setEmploymentType] = useState([])
    const [benefits, setBenefits] = useState([])

    const [tempSkill, setTempSkill] = useState('')
    const [error_Skills, setError_Skills] = useState(null)

    // ERRORS
    const [errors, setErrors] = useState({
        jobTitle: null,
    })

    useEffect(() => {
        if (currency == 'PHP') {
            setCurrency2('PHP')
        } else if (currency == 'SGD') {
            setCurrency2('SGD')
        } else if (currency == 'USD') {
            setCurrency2('USD')
        } else {
            setCurrency2('PHP')
        }
    }, [])

    useEffect(() => {
        if (currency == 'PHP') {
            setCurrency2('PHP')
        } else if (currency == 'SGD') {
            setCurrency2('SGD')
        } else if (currency == 'USD') {
            setCurrency2('USD')
        } else {
            setCurrency2('PHP')
        }
    }, [currency])
    const [jobFunctionError, setJobFunctionError] = useState(false)
    const [skillsError, setSkillsError] = useState(false)
    const [responsibilitiesError, setResponsibilitiesError] = useState(false)
    const [currencyError, setCurrencyError] = useState(false)
    const [salaryMinerror, setSalaryMinError] = useState(false)
    const [salaryMaxerror, setSalaryMaxError] = useState(false)
    const [workSetupError, setWorkSetupError] = useState(false)
    const [startDateError, setStartDateError] = useState(false)
    const [employmentTypeError, setEmploymentTypeError] = useState(false)

    const validate = () => {
        let isValid = []
        if (!jobTitle) {
            setErrors({ ...errors, jobTitle: 'Required' })
            isValid.push(false)
        } else {
            setErrors({ ...errors, jobTitle: false })
            isValid.push(true)
        }
        if (!jobFunction) {
            setJobFunctionError(true)
            isValid.push(false)
        } else {
            setJobFunctionError(false)
            isValid.push(true)
        }
        if (skills.length < 1) {
            setSkillsError(true)
            isValid.push(false)
        } else {
            setSkillsError(false)
            isValid.push(true)
        }
        if (!responsibilities) {
            setResponsibilitiesError(true)
            isValid.push(false)
        } else {
            setResponsibilitiesError(false)
            isValid.push(true)
        }
        if (startDate < moment().format('YYYY-MM-DD')) {
            setStartDateError(true)
            isValid.push(false)
        } else {
            setStartDateError(false)
            isValid.push(true)
        }
        if (!currency2) {
            setCurrencyError(true)
            isValid.push(false)
        } else {
            setCurrencyError(false)
            isValid.push(true)
        }
        if (!salaryMin) {
            setSalaryMinError(true)
            isValid.push(false)
        } else {
            setSalaryMinError(false)
            isValid.push(true)
        }
        if (!salaryMax) {
            setSalaryMaxError(true)

            isValid.push(false)
        } else {
            setSalaryMaxError(false)
            isValid.push(true)
        }
        if (workSetup.length < 1) {
            setWorkSetupError(true)
            isValid.push(false)
        } else {
            setWorkSetupError(false)
            isValid.push(true)
        }
        if (employmentType.length < 1) {
            setEmploymentTypeError(true)
            isValid.push(false)
        } else {
            setEmploymentTypeError(false)
            isValid.push(true)
        }
        return isValid.includes(false) ? false : true
    }
    // GET JOB FUNCTIONS LIST
    useEffect(() => {
        configRequest.getJobFunctions().then((response) => {
            setJobFunctions(response?.data?.data || [])
        })
    }, [])

    const addCommas = (num) =>
        num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, '')

    const removeComma = (num) => {
        let copy = num?.toString()
        let num1
        if (copy?.indexOf(',') != -1) {
            num1 = parseFloat(num?.replace(/,/g, ''))
            return num1
        } else {
            return parseFloat(copy)
        }
    }

    const handleSalaryMin = (event) =>
        setSalaryMin(addCommas(removeNonNumeric(event.target.value)))

    const handleSalaryMax = (event) =>
        setSalaryMax(addCommas(removeNonNumeric(event.target.value)))

    // CREATE JOB FUNCTION
    const createJob = async (val) => {
        let sMin = removeComma(salaryMin)
        let sMax = removeComma(salaryMax)
        const valid = val === 'isDraft' ? true : validate()
        if (valid) {
            const expiration = moment().add(6, 'months')
            let experience = experienceRequired?.split('-')
            let newStartDate = new Date(startDate)

            let payload = {
                totalExperienceMin: experienceRequired ? parseInt(experience[0]): null,
                totalExperienceMax: experienceRequired ? parseInt(experience[1]) : null,
                salaryCurrency: currency2,
                salaryMin: sMin,
                salaryMax: sMax,
                isDraft: val !== 'isPublish' ? true : false,
                isPublished: val !== 'isPublish' ? false : true,
                status: 'active',
                education,
                jobExpiry: expiration,
                expectedStartDate: newStartDate,
                jobTitle,
                technicalSkills: skills,
                employmentType,
                jobFunction,
                responsibilities,
                workSetup,
                perksAndBenefits: benefits,
            }
            try {
                const response = await jobsRequest.createJob(payload)
                if (response) {
                    console.log(response)
                    if (val === 'isEdit') {
                        console.log('edit')
                        const noNav = new URLSearchParams(location.search).get(
                            'noNav'
                        )
                        !noNav
                            ? navigate(`/jobs/edit?ref=other`, {
                                  state: { job: response.data },
                              })
                            : navigate(`/jobs/edit?ref=other&noNav=1`, {
                                  state: { job: response.data },
                              })
                    } else {
                        console.log('not edit')
                        navigate('/jobs')
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const addSkill = () => {
        if (!!tempSkill) {
            setSkills([...skills, tempSkill])

            setTempSkill('')
        } else {
            console.log('no skill entered')
        }
    }

    useEffect(() => {
        if (tempSkill.length === 20) {
            addSkill()
        }
        var input = document.getElementById('skillInputEditjob')

        if (tempSkill.length <= 20 && tempSkill.length >= 2) {
            // Execute a function when the user presses a key on the keyboard
            input.addEventListener('keyup', function (event) {
                // If the user presses the "Enter" key on the keyboard
                if (event.key === 'Enter') {
                    // Cancel the default action, if needed
                    event.preventDefault()
                    // Trigger the button element with a click
                    addSkill()
                }
            })
        } else {
            return
        }
    }, [tempSkill])

    const removeSkill = (idx) => {
        setSkills(skills.filter((item, index) => index !== idx))
    }

    const handleEducationCheck = (e) => {
        var educationCopy = [...education]
        if (e.target.checked) {
            educationCopy = [...education, e.target.value]
        } else {
            educationCopy.splice(education.indexOf(e.target.value), 1)
        }
        setEducation(educationCopy)
    }

    const handleWorkSetupCheck = (e) => {
        var workSetupCopy = [...workSetup]
        if (e.target.checked) {
            workSetupCopy = [...workSetup, e.target.value]
        } else {
            workSetupCopy.splice(workSetup.indexOf(e.target.value), 1)
        }
        setWorkSetup(workSetupCopy)
    }

    const handleEmploymentTypeCheck = (e) => {
        var employmentTypeCopy = [...employmentType]
        if (e.target.checked) {
            employmentTypeCopy = [...employmentType, e.target.value]
        } else {
            employmentTypeCopy.splice(employmentType.indexOf(e.target.value), 1)
        }
        setEmploymentType(employmentTypeCopy)
    }

    const handleBenefitsCheck = (e) => {
        var benefitsCopy = [...benefits]
        if (e.target.checked) {
            benefitsCopy = [...benefits, e.target.value]
        } else {
            benefitsCopy.splice(benefits.indexOf(e.target.value), 1)
        }
        setBenefits(benefitsCopy)
    }
    useEffect(() => {
        console.log(startDate)
    }, [startDate])
    return (
        <div className="create-job-bg" style={{ overFlowY: 'scroll' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="card mt-5 create-job-card">
                            <div className="card-body p-3 mb-md-5">
                                <h4 className="text-muted mb-3">
                                    <strong>Create a Job Post</strong>
                                </h4>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Job Title</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>
                                    <TextField
                                        type="text"
                                        required
                                        id="jobTitle"
                                        name="jobTitle"
                                        placeholder="E.g “Graphic Designer for Social Media Marketing”"
                                        // value={jobTitle}
                                        limit={30}
                                        defaultValue={jobTitle}
                                        onChange={(e) =>
                                            setJobTitle(e.target.value)
                                        }
                                        className="mb-2"
                                        error={errors.jobTitle}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Job Function</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>
                                    <select
                                        name=""
                                        id=""
                                        className={`form-select br-3 ${
                                            jobFunctionError && 'select-error'
                                        }`}
                                        onChange={(e) => {
                                            setJobFunction(e.target.value)
                                        }}
                                    >
                                        <option value="">
                                            Choose an industry
                                        </option>
                                        {jobFunctions.map((item) => {
                                            return (
                                                <option
                                                    value={item.name}
                                                    key={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    {jobFunctionError && (
                                        <span className="error-helper">
                                            Required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>
                                            What technical skills do you
                                            require?{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </strong>
                                        <small className="text-muted d-block">
                                            Here are the skills related to your
                                            selected industry. You can remove or
                                            add more if the skills you are
                                            looking for are not in the
                                            suggestions.
                                        </small>
                                    </label>
                                    <div
                                        className={`form-control d-flex flex-wrap ${
                                            error_Skills
                                                ? 'text-danger border error border-danger'
                                                : null
                                        } ${skillsError && 'border-danger'}`}
                                        id=""
                                    >
                                        {skills?.map((skill, index) => {
                                            return (
                                                <div
                                                    className="skills-in-box d-flex p-1"
                                                    key={`${skill}-${index}`}
                                                >
                                                    <span
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                        className="open-sans-body ps-1"
                                                    >
                                                        {skill}
                                                    </span>
                                                    <div
                                                        className="ms-2 me-1 pe-1 open-sans-body"
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                        role="button"
                                                        onClick={() =>
                                                            removeSkill(index)
                                                        }
                                                    >
                                                        x
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <textarea
                                            type="text"
                                            className={`flex-grow-1 skills-input `}
                                            onChange={(e) => {
                                                setTempSkill(e.target.value)
                                            }}
                                            value={tempSkill}
                                            id="skillInputEditjob"
                                            rows={5}
                                        ></textarea>
                                    </div>
                                    {skillsError && (
                                        <span className="error-helper">
                                            Required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Responsibilities</strong>{' '}
                                        <span className="required-tag">*</span>
                                        <small className="text-muted d-block">
                                            What does the day-to-day of this job
                                            looks like?
                                        </small>
                                    </label>
                                    <textarea
                                        name=""
                                        className={`form-control br-3 ${
                                            responsibilitiesError &&
                                            'border-danger'
                                        }`}
                                        id=""
                                        cols="30"
                                        rows="5"
                                        onChange={(e) => {
                                            setResponsibilities(e.target.value)
                                        }}
                                    ></textarea>
                                    {responsibilitiesError && (
                                        <span className="error-helper">
                                            Required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Estimated Start Date</strong>
                                    </label>
                                    <input
                                        type="date"
                                        className={`form-control disabled ${
                                            startDateError && 'border-danger'
                                        }`}
                                        onChange={(e) => {
                                            setStartDate(e.target.value)
                                        }}
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                    {startDateError && (
                                        <span className="error-helper">
                                            Invalid date
                                        </span>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Salary (Range)</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-2 mb-4 mb-md-0">
                                            <select
                                                name=""
                                                id=""
                                                className={`form-select br-3 ${
                                                    currencyError &&
                                                    'border-danger'
                                                }`}
                                                onChange={(e) => {
                                                    setCurrency2(e.target.value)
                                                }}
                                            >
                                                <option
                                                    value="PHP"
                                                    selected={
                                                        currency == 'PHP'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    PHP
                                                </option>
                                                <option
                                                    value="SGD"
                                                    selected={
                                                        currency == 'SGD'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    SGD
                                                </option>
                                                <option
                                                    value="USD"
                                                    selected={
                                                        currency == 'USD'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    USD
                                                </option>
                                            </select>
                                            {currencyError && (
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-md-5 mb-4 mb-md-0">
                                            <input
                                                type="text"
                                                className={`form-control br-3 ${
                                                    salaryMinerror &&
                                                    'border-danger'
                                                }`}
                                                placeholder="Minimum"
                                                value={salaryMin}
                                                onChange={handleSalaryMin}
                                            />

                                            {salaryMinerror && (
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-md-5">
                                            <input
                                                type="text"
                                                className={`form-control br-3 ${
                                                    salaryMaxerror &&
                                                    'border-danger'
                                                }`}
                                                placeholder="Maximum"
                                                value={salaryMax}
                                                onChange={handleSalaryMax}
                                            />

                                            {salaryMaxerror && (
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Country or Location</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control br-3"
                                        placeholder="E.g. “Makati, Philippines”"
                                        onChange={(e) => {
                                            setCountry(e.target.value)
                                        }}
                                    />
                                </div> */}
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Years of Experience</strong>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="noExp"
                                                    value="0-1"
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="noExp"
                                                >
                                                    0 - 1 year
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="1-3"
                                                    value="1-3"
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="1-3"
                                                >
                                                    1 - 3 years
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="3-5"
                                                    value="3-5"
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="3-5"
                                                >
                                                    3 - 5 years
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="5-7"
                                                    value="5-7"
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="5-7"
                                                >
                                                    5 - 7 years
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="experienceRequired"
                                                    id="7above"
                                                    value="7-100 "
                                                    onChange={(e) => {
                                                        setExperienceRequired(
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="7above"
                                                >
                                                    7 years and above
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Education</strong>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Doctorate (PhD)"
                                                    id="phd"
                                                    defaultChecked={education.includes(
                                                        'phd'
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="phd"
                                                >
                                                    Doctorate (PhD)
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Master's Degree"
                                                    id="masters"
                                                    defaultChecked={education.includes(
                                                        'masters'
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="masters"
                                                >
                                                    Master’s Degree
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Bachelor's Degree"
                                                    id="bachelors"
                                                    defaultChecked={education.includes(
                                                        'bachelors'
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="bachelors"
                                                >
                                                    Bachelor’s Degree
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Diploma"
                                                    id="diploma"
                                                    defaultChecked={education.includes(
                                                        'diploma'
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="diploma"
                                                >
                                                    Diploma
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="High School"
                                                    id="highschool"
                                                    defaultChecked={education.includes(
                                                        'highschool'
                                                    )}
                                                    onChange={(e) => {
                                                        handleEducationCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="highschool"
                                                >
                                                    High School
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Work Setup</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>

                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="On-site"
                                                    id="on-site"
                                                    defaultChecked={workSetup.includes(
                                                        'on-site'
                                                    )}
                                                    onChange={(e) => {
                                                        handleWorkSetupCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="on-site"
                                                >
                                                    On-Site
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Remote"
                                                    id="remote"
                                                    defaultChecked={workSetup.includes(
                                                        'remote'
                                                    )}
                                                    onChange={(e) => {
                                                        handleWorkSetupCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="remote"
                                                >
                                                    Remote
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Hybrid"
                                                    id="hybrid"
                                                    defaultChecked={workSetup.includes(
                                                        'hybrid'
                                                    )}
                                                    onChange={(e) => {
                                                        handleWorkSetupCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="hybrid"
                                                >
                                                    Hybrid
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {workSetupError && (
                                        <div>
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Employment Type</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Full-Time"
                                                    id="full-time"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="full-time"
                                                >
                                                    Full-Time
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Part-Time"
                                                    id="part-time"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="part-time"
                                                >
                                                    Part-Time
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Project-Based"
                                                    id="project-based"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="project-based"
                                                >
                                                    Project-Based
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Freelance"
                                                    id="freelance"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="freelance"
                                                >
                                                    Freelance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Internship"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                    id="intern"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="intern"
                                                >
                                                    Internship
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Highschool Internship"
                                                    id="highschool-intern"
                                                    onChange={(e) => {
                                                        handleEmploymentTypeCheck(
                                                            e
                                                        )
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="highschool-intern"
                                                >
                                                    Highschool Internship
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {employmentTypeError && (
                                        <div>
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Perks and Benefits</strong>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Meal Allowance"
                                                    id="meal"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="meal"
                                                >
                                                    Meal Allowance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Trainings & Seminars"
                                                    id="trainings"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="trainings"
                                                >
                                                    Trainings & Seminars
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Employee Stock Options"
                                                    id="stocks"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="stocks"
                                                >
                                                    Employee Stock Options
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Flexible Schedule"
                                                    id="flexible"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="flexible"
                                                >
                                                    Flexible Schedule
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Fuel Discount"
                                                    id="fuel"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="fuel"
                                                >
                                                    Fuel Discount
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Reimbursed Parking Fee"
                                                    id="reimburseParking"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="reimburseParking"
                                                >
                                                    Reimbursed Parking Fee
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Medical Insurance"
                                                    id="medical"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="medical"
                                                >
                                                    Medical Insurance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Gym Membership"
                                                    id="gym"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="gym"
                                                >
                                                    Gym Membership
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Life Insurance"
                                                    id="lifeInsurance"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="lifeInsurance"
                                                >
                                                    Life Insurance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="On-site Parking"
                                                    id="parking"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="parking"
                                                >
                                                    On-site Parking
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Promotion to Permanent Employee"
                                                    id="promotion"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="promotion"
                                                >
                                                    Promotion to Permanent
                                                    Employee
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Paid Time-Out"
                                                    id="paidOut"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="paidOut"
                                                >
                                                    Paid Time-Out
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Internet Allowance"
                                                    id="internet"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="internet"
                                                >
                                                    Internet Allowance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Government Mandated Benefits"
                                                    id="govertmentBenefits"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="benefigovertmentBenefitsts"
                                                >
                                                    Government Mandated Benefits
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Transportation Allowance"
                                                    id="transport"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="transport"
                                                >
                                                    Transportation Allowance
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Promotion to Permanent
                                                    Employee"
                                                    id="permanent"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="permanent"
                                                >
                                                    Promotion to Permanent
                                                    Employee
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="Company Car"
                                                    id="companyCar"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="companyCar"
                                                >
                                                    Company Car
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="More"
                                                    id="more"
                                                    onChange={(e) => {
                                                        handleBenefitsCheck(e)
                                                    }}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    htmlFor="more"
                                                >
                                                    And more!
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="d-flex flex-column flex-md-row">
                                        <button
                                            className="btn btn-primary w-100 mb-2 mb-md-0 text-center"
                                            onClick={() => {
                                                createJob('isPublish')
                                            }}
                                        >
                                            Create Job Post
                                        </button>
                                        <button
                                            className="btn btn-outline-primary w-100 mb-2 mb-md-0 ms-0 ms-md-2 text-center"
                                            onClick={() => createJob('isDraft')}
                                        >
                                            Save as Draft
                                        </button>
                                        {/* <button className="btn btn-outline-primary w-100 text-center">
                                            Preview Job Post
                                        </button> */}
                                    </div>
                                </div>
                                <div>
                                    <a
                                        className="text-info hand"
                                        onClick={() => {
                                            navigate('/jobs')
                                        }}
                                    >
                                        Discard this job post.
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
    }
}

export default connect(mapStateToProps, null)(CreateJob)
