/* * * * * * * * * * * * * * * *
 *  ACTION TYPE naming convention
 *  VERB + '_REQUEST'
 *  VERB + '_SUCCESS'
 *  VERB + '_FAILURE'
 *
 *  ACTION naming convention
 *  verb : type
 * * * * * * * * * * * * * * * */
const userActions = {
    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

    SET_PROMOTIONAL_MODAL: 'SET_PROMOTIONAL_MODAL',
    SET_PROMOTIONAL_BANNER: 'SET_PROMOTIONAL_BANNER',

    CONFIRM_PHONE_CODE_REQUEST: 'CONFIRM_PHONE_CODE_REQUEST',
    CONFIRM_PHONE_CODE_SUCCESS: 'CONFIRM_PHONE_CODE_SUCCESS',
    CONFIRM_PHONE_CODE_FAILURE: 'CONFIRM_PHONE_CODE_FAILURE',

    VERIFY_GOOGLE_TOKEN_REQUEST: 'VERIFY_GOOGLE_TOKEN_REQUEST',
    VERIFY_GOOGLE_TOKEN_SUCCESS: 'VERIFY_GOOGLE_TOKEN_SUCCESS',
    VERIFY_GOOGLE_TOKEN_FAILURE: 'VERIFY_GOOGLE_TOKEN_FAILURE',

    VERIFY_APPLE_TOKEN_REQUEST: 'VERIFY_APPLE_TOKEN_REQUEST',
    VERIFY_APPLE_TOKEN_SUCCESS: 'VERIFY_APPLE_TOKEN_SUCCESS',
    VERIFY_APPLE_TOKEN_FAILURE: 'VERIFY_APPLE_TOKEN_FAILURE',

    VERIFICATION_FAILURE: 'VERIFICATION_FAILURE',
    RESET_VERIFICATION: 'RESET_VERIFICATION',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    GET_APPLICANT_DETAILS_REQUEST: 'GET_APPLICANT_DETAILS_REQUEST',
    GET_APPLICANT_DETAILS_SUCCESS: 'GET_APPLICANT_DETAILS_SUCCESS',
    GET_APPLICANT_DETAILS_FAILURE: 'GET_APPLICANT_DETAILS_FAILURE',

    RENEW_ACCESS_TOKEN_REQUEST: 'RENEW_ACCESS_TOKEN_REQUEST',
    RENEW_ACCESS_TOKEN_SUCCESS: 'RENEW_ACCESS_TOKEN_SUCCESS',
    RENEW_ACCESS_TOKEN_FAILURE: 'RENEW_ACCESS_TOKEN_FAILURE',

    GET_EMPLOYER_DASHBOARD_REQUEST: 'GET_EMPLOYER_DASHBOARD_REQUEST',
    GET_EMPLOYER_DASHBOARD_SUCCESS: 'GET_EMPLOYER_DASHBOARD_SUCCESS',
    GET_EMPLOYER_DASHBOARD_FAILURE: 'GET_EMPLOYER_DASHBOARD_FAILURE',

    GET_APPLICANT_APPLIED_JOBS_REQUEST: 'GET_APPLICANT_APPLIED_JOBS_REQUEST',
    GET_APPLICANT_APPLIED_JOBS_SUCCESS: 'GET_APPLICANT_APPLIED_JOBS_SUCCESS',
    GET_APPLICANT_APPLIED_JOBS_FAILURE: 'GET_APPLICANT_APPLIED_JOBS_FAILURE',

    GET_APPLICANT_ACTIVE_JOBS_REQUEST: 'GET_APPLICANT_ACTIVE_JOBS_REQUEST',
    GET_APPLICANT_ACTIVE_JOBS_SUCCESS: 'GET_APPLICANT_ACTIVE_JOBS_SUCCESS',
    GET_APPLICANT_ACTIVE_JOBS_FAILURE: 'GET_APPLICANT_ACTIVE_JOBS_FAILURE',

    GET_APPLICANT_SHORTLISTED_JOBS_REQUEST:
        'GET_APPLICANT_SHORTLISTED_JOBS_REQUEST',
    GET_APPLICANT_SHORTLISTED_JOBS_SUCCESS:
        'GET_APPLICANT_SHORTLISTED_JOBS_SUCCESS',
    GET_APPLICANT_SHORTLISTED_JOBS_FAILURE:
        'GET_APPLICANT_SHORTLISTED_JOBS_FAILURE',

    GET_APPLICANT_REJECTED_JOBS_REQUEST: 'GET_APPLICANT_REJECTED_JOBS_REQUEST',
    GET_APPLICANT_REJECTED_JOBS_SUCCESS: 'GET_APPLICANT_REJECTED_JOBS_SUCCESS',
    GET_APPLICANT_REJECTED_JOBS_FAILURE: 'GET_APPLICANT_REJECTED_JOBS_FAILURE',

    GET_APPLICANT_SAVED_JOBS_REQUEST: 'GET_APPLICANT_SAVED_JOBS_REQUEST',
    GET_APPLICANT_SAVED_JOBS_SUCCESS: 'GET_APPLICANT_SAVED_JOBS_SUCCESS',
    GET_APPLICANT_SAVED_JOBS_FAILURE: 'GET_APPLICANT_SAVED_JOBS_FAILURE',

    CHECK_PAYMENT_STATUS_PREFERRED_REQUEST:
        'CHECK_PAYMENT_STATUS_PREFERRED1_REQUEST',
    CHECK_PAYMENT_STATUS_PREFERRED_SUCCESS:
        'CHECK_PAYMENT_STATUS_PREFERRED1_SUCCESS',
    CHECK_PAYMENT_STATUS_PREFERRED_FAILURE:
        'CHECK_PAYMENT_STATUS_PREFERRED1_FAILURE',

    CHECK_PAYMENT_STATUS_PRIORITY_REQUEST:
        'CHECK_PAYMENT_STATUS_PRIORITY_REQUEST',
    CHECK_PAYMENT_STATUS_PRIORITY_SUCCESS:
        'CHECK_PAYMENT_STATUS_PRIORITY_SUCCESS',
    CHECK_PAYMENT_STATUS_PRIORITY_FAILURE:
        'CHECK_PAYMENT_STATUS_PRIORITY_FAILURE',

    UPDATE_APPLICANT_REQUEST: 'UPDATE_APPLICANT_REQUEST',
    UPDATE_APPLICANT_SUCCESS: 'UPDATE_APPLICANT_SUCCESS',
    UPDATE_APPLICANT_FAILURE: 'UPDATE_APPLICANT__FAILURE',

    UPDATE_EMPLOYER_REQUEST: 'UPDATE_EMPLOYER_REQUEST',
    UPDATE_EMPLOYER_SUCCESS: 'UPDATE_EMPLOYER_SUCCESS',
    UPDATE_EMPLOYER_FAILURE: 'UPDATE_EMPLOYER_FAILURE',

    GET_PAYMENTS_REQUEST: 'GET_PAYMENTS_REQUEST',
    GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',
    GET_PAYMENTS_FAILURE: 'GET_PAYMENTS_FAILURE',

    GET_APPLICANT_COMPLETION_REQUEST: 'GET_APPLICANT_COMPLETION_REQUEST',
    GET_APPLICANT_COMPLETION_SUCCESS: 'GET_APPLICANT_COMPLETION_SUCCESS',
    GET_APPLICANT_COMPLETION_FAILURE: 'GET_APPLICANT_COMPLETION_FAILURE',

    SET_CHAT_ROOMS: 'SET_CHAT_ROOMS',

    SOFT_RESET: 'SOFT_RESET',

    CREATE_EMPLOYER_SUCCESS: 'CREATE_EMPLOTER_SUCCESS',

    SET_DO_NOT_SHOW_TIPS: 'SET_DO_NOT_SHOW_TIPS',

    RESET_USER_ERROR: 'RESET_USER_ERROR',

    SET_TOKEN: 'SET_TOKEN',

    SET_IS_GOOGLE_SIGNUP: 'SET_IS_GOOGLE_SIGNUP',

    GET_ACTIVE_SUBSCRIPTION: 'GET_ACTIVE_SUBSCRIPTION',
    SET_ACTIVE_SUBSCRIPTION: 'SET_ACTIVE_SUBSCRIPTION',
    createEmployerSuccess: () => ({
        type: userActions.CREATE_EMPLOYER_SUCCESS,
    }),

    softReset: () => ({
        type: userActions.SOFT_RESET,
    }),

    setToken: (data) => ({
        type: userActions.SET_TOKEN,
        payload: data,
    }),

    signIn: (data) => ({
        type: userActions.SIGN_IN_REQUEST,
        payload: data,
    }),

    getUser: () => ({
        type: userActions.GET_USER_REQUEST,
    }),

    setPromotionalVisible: (value) => ({
        type: userActions.SET_PROMOTIONAL_MODAL,
        payload: value
    }),

    setPromotionalBannerVisible: (value) => ({
        type: userActions.SET_PROMOTIONAL_BANNER,
        payload: value
    }),

    updateUser: (user) => ({
        type: userActions.UPDATE_USER_REQUEST,
        payload: user,
    }),

    updateApplicant: (user) => ({
        type: userActions.UPDATE_APPLICANT_REQUEST,
        payload: user,
    }),

    getApplicantCompletion: () => ({
        type: userActions.GET_APPLICANT_COMPLETION_REQUEST
    }),

    updateEmployer: (user) => ({
        type: userActions.UPDATE_EMPLOYER_REQUEST,
        payload: user,
    }),

    confirmPhoneCode: (data) => ({
        type: userActions.CONFIRM_PHONE_CODE_REQUEST,
        payload: data,
    }),

    verifyGoogleToken: (data) => ({
        type: userActions.VERIFY_GOOGLE_TOKEN_REQUEST,
        payload: data,
    }),

    verifyAppleToken: (data) => ({
        type: userActions.VERIFY_APPLE_TOKEN_REQUEST,
        payload: data,
    }),

    signUp: (user) => ({
        type: userActions.SIGN_UP_REQUEST,
        payload: user,
    }),

    changePassword: (user) => ({
        type: userActions.CHANGE_PASSWORD_REQUEST,
        payload: user,
    }),

    resetPassword: (user) => ({
        type: userActions.RESET_PASSWORD_REQUEST,
        payload: user,
    }),

    getApplicantDetails: (applicantId) => ({
        type: userActions.GET_APPLICANT_DETAILS_REQUEST,
        payload: applicantId,
    }),

    renewAccessToken: (user) => ({
        type: userActions.RENEW_ACCESS_TOKEN_REQUEST,
        payload: user,
    }),

    getEmployerDashboardDetails: () => ({
        type: userActions.GET_EMPLOYER_DASHBOARD_REQUEST,
    }),

    getApplicantAppliedJobs: () => ({
        type: userActions.GET_APPLICANT_APPLIED_JOBS_REQUEST,
    }),

    getApplicantActiveJobs: () => ({
        type: userActions.GET_APPLICANT_ACTIVE_JOBS_REQUEST,
    }),

    getApplicantShortlistedJobs: () => ({
        type: userActions.GET_APPLICANT_SHORTLISTED_JOBS_REQUEST,
    }),

    getApplicantRejectedJobs: () => ({
        type: userActions.GET_APPLICANT_REJECTED_JOBS_REQUEST,
    }),

    getApplicantSavedJobs: () => ({
        type: userActions.GET_APPLICANT_SAVED_JOBS_REQUEST,
    }),

    checkPaymentStatusPreferred: (data) => ({
        type: userActions.CHECK_PAYMENT_STATUS_PREFERRED_REQUEST,
        payload: data,
    }),

    checkPaymentStatusPriority: (data) => ({
        type: userActions.CHECK_PAYMENT_STATUS_PRIORITY_REQUEST,
        payload: data,
    }),

    getPayments: (data) => ({
        type: userActions.GET_PAYMENTS_REQUEST,
        payload: data,
    }),

    resetVerification: () => ({
        type: userActions.RESET_VERIFICATION,
    }),

    setChatRooms: (data) => ({
        type: userActions.SET_CHAT_ROOMS,
        payload: data,
    }),

    resetUserError: () => ({
        type: userActions.RESET_USER_ERROR,
    }),

    setDoNotShowTips: (status) => ({
        type: userActions.SET_DO_NOT_SHOW_TIPS,
        payload: status,
    }),

    setGoogleSignup: (status) => ({
        type: userActions.SET_IS_GOOGLE_SIGNUP,
        payload: status,
    }),

    getActiveSubscription: () => ({
        type: userActions.GET_ACTIVE_SUBSCRIPTION,
    }),

    setActiveSubscription: (payload) => ({
        type: userActions.SET_ACTIVE_SUBSCRIPTION,
        payload,
    }),
}

export default userActions
