import { Box, Chip, Grid } from "@material-ui/core";
import moment from "moment/moment";
import { connect } from "react-redux";
import appliedDateIcon from "../../../assets/icons/icon-v2_applied date.svg";
import educationIcon from "../../../assets/icons/icon-v2_education.svg";
import locationIcon from "../../../assets/icons/icon-v2_location.svg";
import salaryIcon from "../../../assets/icons/icon-v2_salary.svg";
import workHistoryIcon from "../../../assets/icons/icon-v2_work history.svg";
import workShiftIcon from "../../../assets/icons/icon-v2_work shift.svg";
import diamondIcon from "../../../assets/icons/icon_diamond.svg";
import workSetupIcon from "../../../assets/icons/workSetupIcon.svg";
//interviews icon imports
import startDateIcon from "../../../assets/icons/icon-v2_start date.svg";
import workHoursIcon from "../../../assets/icons/icon-v2_work hours.svg";
//end-interviews
import { useState } from "react";
import { Link } from "react-router-dom";

import JobDetailsModal from "../../../components/modals/JobDetailsModal";
import JobluTypography from "../../../components/Typography";
import { app_styles } from "../../../general.styles";
import { profilePlaceholder_Employer } from "../../../utils/constants";
import {
  formatDateOnlyv3,
  getInterviewTime,
  getMeetingProviderDetails,
  getSwipeActionLabel,
  getVideoCallLink,
  thousandSeparator,
  titleCase,
} from "../../../utils/helpers";
import { card_styles } from "./styles";
const JobCardList = ({
  job,
  showAppliedDate = true,
  showEmployerAction,
  isInterviews,
  ...props
}) => {
  const { currency } = props;
  const styles = card_styles(props);
  const app_classes = app_styles(props);
  const [view_job, setViewJob] = useState(null);

  return (
    <Grid
      // component={Card}
      container
      className={styles.card_container}
      spacing={2}
    >
      <Grid item sm={12} md={1}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <img
            src={job?.employer?.profileUrl || profilePlaceholder_Employer}
            className={`${styles.job_image} ${app_classes.general.centered_x}`}
            alt=""
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <JobluTypography.H5 semiBold primary>
          {job?.jobTitle}
        </JobluTypography.H5>
        <JobluTypography.Body>
          {job?.employer?.companyName}
        </JobluTypography.Body>
        <Box className={app_classes.display.display_flex}>
          {/* location */}
          <img
            src={locationIcon}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: 5 }}
            className={app_classes.general.color_secondary}
          />
          <JobluTypography.Body>{job?.location}</JobluTypography.Body>
        </Box>
        {/* work setup */}
        <Box className={app_classes.display.display_flex}>
          <img
            src={workSetupIcon}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: 5 }}
            className={app_classes.general.color_secondary}
          />
          <JobluTypography.Body>
            {job?.workSetup?.join(", ") || "N/A"}
          </JobluTypography.Body>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={2}>
        {/* work shift  */}
        <Box className={app_classes.display.display_flex}>
          <img
            src={workShiftIcon}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: 5 }}
            className={app_classes.general.color_secondary}
          />
          <JobluTypography.Body>
            {job?.employmentType?.join(", ") || "N/A"}
          </JobluTypography.Body>
        </Box>
        {/* education */}
        <Box className={app_classes.display.display_flex}>
          <img
            src={educationIcon}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: 5 }}
            className={app_classes.general.color_secondary}
          />
          <JobluTypography.Body>
            {job?.educationAndExperience || "N/A"}
          </JobluTypography.Body>
        </Box>
        {/* work history  */}
        <Box className={app_classes.display.display_flex}>
          <img
            src={workHistoryIcon}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: 5 }}
            className={app_classes.general.color_secondary}
          />
          <JobluTypography.Body>
            {job?.totalExperienceMin &&
              job?.totalExperienceMin !== 0 &&
              `${job?.totalExperienceMin} - `}
            {job?.totalExperienceMax} Years
          </JobluTypography.Body>
        </Box>
        {/* salary */}
        <Box className={app_classes.display.display_flex}>
          <img
            src={salaryIcon}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: 5 }}
            className={app_classes.general.color_secondary}
          />
          <JobluTypography.Body>
            {currency}{" "}
            {job?.salaryMin &&
              job?.salaryMax&&
              `${thousandSeparator(job?.salaryMin)} - `}
            {thousandSeparator(job?.salaryMax)}
          </JobluTypography.Body>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Box sx={{ display: "flex" }}>
          <img
            src={diamondIcon}
            alt=""
            style={{ width: "20px", height: "23px", marginRight: 5 }}
            className={app_classes.general.color_secondary}
          />
          <JobluTypography.H5 semiBold primary>
            Perks and Benefits
          </JobluTypography.H5>
        </Box>

        <Box>
          {job?.perksAndBenefits?.map((perks) => {
            return (
              <Chip
                label={perks}
                color="secondary"
                style={{ margin: 1 }}
                //clickable
              />
            );
          })}
        </Box>
      </Grid>
      {!isInterviews &&
        showEmployerAction &&
        job?.employer?.action === "superLike" && (
          <Grid item xs={12} sm={12} md={2} container justifyContent="flex-end">
            <Grid item>
              <Chip
                className={styles.action_chip}
                label={getSwipeActionLabel(job?.employer?.action)}
              />
            </Grid>
          </Grid>
        )}

      {isInterviews && (
        <Grid item xs={12} sm={12} md={2} container justifyContent="flex-end">
          <Grid item>
            <Box>
              <img
                src={startDateIcon}
                alt=""
                style={{ width: "20px", height: "20px", marginRight: 5 }}
                className={`${app_classes.general.color_secondary} ${app_classes.general.centered_y}`}
              />
              <JobluTypography.Body>
                {formatDateOnlyv3(job?.matchInterview?.data?.start?.dateTime)}
              </JobluTypography.Body>
            </Box>
            <Box style={{ display: "flex" }}>
              <img
                src={workHoursIcon}
                alt=""
                style={{ width: "20px", height: "20px", marginRight: 5 }}
                className={`${app_classes.general.color_secondary} ${app_classes.general.centered_y}`}
              />
              <Box>
                <JobluTypography.Body
                  className={app_classes.general.centered_y}
                >
                  {getInterviewTime(
                    job?.matchInterview?.data?.start,
                    job?.matchInterview?.data?.end
                  )}
                </JobluTypography.Body>
              </Box>
            </Box>
            <Box>
              <img
                src={
                  getMeetingProviderDetails(job?.matchInterview?.provider).img
                }
                alt=""
                style={{ width: "20px", height: "20px", marginRight: 5 }}
                className={app_classes.general.color_secondary}
              />
              <JobluTypography.Body>
                <Link target="_blank" to={getVideoCallLink(job.matchInterview)}>
                  {titleCase(
                    getMeetingProviderDetails(job?.matchInterview?.provider)
                      .name,
                    true
                  )}
                </Link>
              </JobluTypography.Body>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} container justifyContent="flex-end">
        <Grid
          item
          xs={12}
          md={5}
          container
          justifyContent="space-between"
          spacing={1}
        >
          <Grid item xs={12} md={6}>
            <JobluTypography.Body>
              <span
                role="button"
                onClick={() => {
                  if (job?.matchJob) {
                    setViewJob({
                      ...job?.matchJob,
                      employer: job?.matchEmployer,
                    });
                  } else {
                    setViewJob(job);
                  }
                }}
              >
                See more{" "}
              </span>
            </JobluTypography.Body>
          </Grid>
          {showAppliedDate && (
            <Grid item xs={12} md={6}>
              <Box className={app_classes.display.display_flex}>
                <img
                  src={appliedDateIcon}
                  alt=""
                  style={{ width: "23px", height: "23px", marginRight: 5 }}
                  className={app_classes.general.color_secondary}
                />
                <JobluTypography.Body>
                  Applied: {moment(job?.createdAt).format("MMM D, YYYY")}
                </JobluTypography.Body>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>

      <JobDetailsModal
        job={view_job}
        open={Boolean(view_job)}
        setOpen={setViewJob}
      />
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    currency: state.Generals.currency,
  };
};
export default connect(mapStateToProps, null)(JobCardList);
