import { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import ArchiveIcon from '../../assets/img/matches/archiveIcon.png'
import CustomTooltip from '../../components/reusables/CustomTooltip'
import SelectJobPost from '../../components/reusables/SelectJobPost'
import { jobApplicantRequest, jobMatchRequest } from '../../service/requests'
import { errorSwipeActions } from '../../utils/constants'
import ExploreModal from '../explore/components/ExploreModal'
import Applicants from './components/Applicants'
import './styles.css'

function Matches(props) {
    const PAGE_LIMIT = 3
    const navigate = useNavigate()
    const [selectedJobPost, setSelectedJobPost] = useState('')
    const [currentTab, setCurrentTab] = useState('Applied')
    const [appliedApplicants, setAppliedApplicants] = useState([])
    const [shortListedApplicants, setShortListedApplicants] = useState([])
    const [archivedApplicants, setArchivedApplicants] = useState([])
    const [interviewApplicants, setInterviewApplicants] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const tabs = ['Applied', 'Shortlisted', 'Interviews']

    const [jobTitle, setJobTitle] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)
    const [matchImages, setMatchImages] = useState(null)
    const [applicantName, setApplicantName] = useState(null)
    const [search_keyword, setSearchKeyword] = useState('')
    const [pagination_loading, setPaginationLoading] = useState(false)
    const [pageOffsets, setPageOffsets] = useState({
        Applied: 1,
        Shortlisted: 1,
        Interviews: 1,
    })
    const [hasMoreData, setHasMoreData] = useState({
        Applied: false,
        Shortlisted: false,
        Interviews: false,
    })

    const [totalCounts, setTotalCounts] = useState({
        Applied: 1,
        Shortlisted: 1,
        Interviews: 1,
    })

    useEffect(() => {
        if (location?.state?.tab) {
            setCurrentTab(location.state.tab)
        }
    }, [location])

    useEffect(() => {
        const jobId = new URLSearchParams(location.search).get('job')
        const jobIdFromLocation = location?.state?.data?.data?.jobId
        if (!!jobId) {
            setSelectedJobPost(jobId)
        } else if (!!jobIdFromLocation) {
            setSelectedJobPost(jobIdFromLocation)
        }
    }, [location])

    const getApplicants = (search = null, append = undefined) => {
        if (!!selectedJobPost) {
            !append ? setIsLoading(true) : setPaginationLoading(true)
            let payload = { limit: PAGE_LIMIT }
            if (search) payload.search = search

            try {
                if (currentTab === 'Applied') {
                    payload.offset = !!append ? pageOffsets.Applied : 1
                    jobApplicantRequest
                        .getJobApplicants_Applied(selectedJobPost, payload)
                        .then((res) => {
                            setTotalCounts({
                                ...totalCounts,
                                Applied: res?.data?.totalCount,
                            })
                            if (
                                appliedApplicants.length < res.data?.totalCount
                            ) {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Applied: true,
                                })
                            } else {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Applied: false,
                                })
                            }
                            if (!!append) {
                                setAppliedApplicants([
                                    ...appliedApplicants,
                                    ...res?.data?.data,
                                ])
                            } else {
                                setAppliedApplicants(res?.data?.data)
                            }
                            setTimeout(() => {
                                setIsLoading(false)
                                setPaginationLoading(false)
                            }, 1000)
                        })
                }
                if (currentTab === 'Shortlisted') {
                    payload.offset = !!append ? pageOffsets.Shortlisted : 1

                    console.log('payload shortlisted', payload)
                    jobApplicantRequest
                        .getJobApplicants_Shortlisted(selectedJobPost, payload)
                        .then((res) => {
                            setTotalCounts({
                                ...totalCounts,
                                Shortlisted: res?.data?.totalCount,
                            })
                            if (
                                shortListedApplicants.length <
                                    res.data?.totalCount &&
                                pagination_loading === false
                            ) {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Shortlisted: true,
                                })
                            } else {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Shortlisted: false,
                                })
                            }
                            if (!!append) {
                                setShortListedApplicants([
                                    ...shortListedApplicants,
                                    ...res?.data?.data,
                                ])
                            } else {
                                setShortListedApplicants(res?.data?.data)
                            }
                            setTimeout(() => {
                                setIsLoading(false)
                                setPaginationLoading(false)
                            }, 1500)
                        })
                }
                if (currentTab === 'Interviews') {
                    payload.offset = !!append ? pageOffsets.Interviews : 1

                    jobApplicantRequest
                        .getJobApplicants_Interview(selectedJobPost, payload)
                        .then((res) => {
                            setTotalCounts({
                                ...totalCounts,
                                Interviews: res?.data?.totalCount,
                            })
                            if (
                                interviewApplicants.length <
                                    res.data?.totalCount &&
                                pagination_loading === false
                            ) {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Interviews: true,
                                })
                            } else {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Interviews: false,
                                })
                            }
                            if (!!append) {
                                setInterviewApplicants([
                                    ...interviewApplicants,
                                    ...res?.data?.data,
                                ])
                            } else {
                                setInterviewApplicants(res?.data?.data)
                            }
                            setTimeout(() => {
                                setIsLoading(false)
                                setPaginationLoading(false)
                            }, 1000)
                        })
                }
                if (currentTab === 'Archived') {
                    jobApplicantRequest
                        .getJobApplicants_Rejected(selectedJobPost, payload)
                        .then((res) => {
                            setArchivedApplicants(res?.data?.data)
                            setIsLoading(false)
                        })
                }
            } catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        getApplicants()
    }, [selectedJobPost, currentTab])

    const swipeAction = (payload) => {
        const { job, applicant, action, itemId } = payload
        let applicant_item = document.getElementById(`${itemId}`)

        jobMatchRequest
            .employerSwipe({
                jobId: job._id,
                applicantId: applicant.userId,
                action: action,
            })
            .then((res) => {
                console.log(res.data)
                if (
                    res.data.message ===
                    errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfMonthlyLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfSuperLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message === errorSwipeActions.LIKE_NOT_ALLOWED
                ) {
                    setModalVariant('upgradeAccount')
                    setModalOpen(true)
                } else {
                    if (action === 'not_interested') {
                        applicant_item.classList.add('slide-out-left')
                    } else if (action === 'superLike') {
                        applicant_item.classList.add('slide-out-top')
                    } else if (action === 'like') {
                        applicant_item.classList.add('slide-out-right')
                    }
                    setTimeout(() => {
                        getApplicants()
                    }, 500)
                }

                if (res.data.matchStatus === 'match') {
                    // alert('MATCHED!!')
                    const images = {
                        employer: res.data.profileUrl_employer,
                        applicant: res.data.profileUrl_applicant,
                    }

                    setMatchImages(images)
                    setModalVariant('matchModal')
                    setModalOpen(true)
                    setApplicantName(
                        applicant.firstName + ' ' + applicant.lastName
                    )
                    setJobTitle(job.jobTitle)
                }
                // navigation.goBack()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    let typingTimer
    let doneInterval = 2000

    const search = () => {
        setIsLoading(true)
        clearTimeout(typingTimer)
        typingTimer = setTimeout(
            () => getApplicants(search_keyword),
            doneInterval
        )
    }
    const handleSearchApplicants = (val) => {
        setSearchKeyword(val)
    }

    useEffect(() => {
        search()
    }, [search_keyword])

    const reset_scroll_changes = (tab) => {
        setAppliedApplicants([])
        setShortListedApplicants([])
        setInterviewApplicants([])
        // if (tab === 'Archived')
        //     data = archivedApplicants
        setPageOffsets({
            Applied: 1,
            Shortlisted: 1,
            Interviews: 1,
        })
        setHasMoreData({
            Applied: false,
            Shortlisted: false,
            Interviews: false,
        })
    }
    useEffect(() => {
        console.log('hasMoreData', hasMoreData)
        console.log('pageOffsets', pageOffsets)
        console.log('totalCounts', totalCounts)
    }, [hasMoreData, pageOffsets, totalCounts])

    const handleLoadMore = (tab) => {
        const pages = Math.ceil(totalCounts[tab] / PAGE_LIMIT)
        console.log('pages', pages)
        const total_pages = !isNaN(pages) && isFinite(pages) ? pages : 1
        const does_not_exceed = pageOffsets[tab] + 1 <= total_pages
        console.log(
            'pages',
            total_pages,
            'next offset => ',
            pageOffsets[tab] + 1
        )
        if (
            hasMoreData[tab] &&
            !pagination_loading &&
            !isLoading &&
            does_not_exceed
        ) {
            setPageOffsets({
                ...pageOffsets,
                [tab]: pageOffsets[tab] + 1,
            })
        } else {
            setHasMoreData({
                ...hasMoreData,
                [tab]: false,
            })
        }
    }

    useEffect(() => {
        if (interviewApplicants?.length >= totalCounts?.Interviews) {
            setHasMoreData((hasMoreData) => ({
                ...hasMoreData,
                Interviews: false,
            }))
        }
        if (appliedApplicants?.length >= totalCounts?.Applied) {
            setHasMoreData((hasMoreData) => ({
                ...hasMoreData,
                Applied: false,
            }))
        }
        if (shortListedApplicants?.length >= totalCounts?.Shortlisted) {
            setHasMoreData((hasMoreData) => ({
                ...hasMoreData,
                Shortlisted: false,
            }))
        }
    }, [
        interviewApplicants,
        appliedApplicants,
        shortListedApplicants,
        totalCounts,
    ])

    useEffect(() => {
        !pagination_loading && getApplicants(search_keyword, true)
    }, [pageOffsets])

    return (
        <>
            <div className="row justify-content-center p-0 mx-0">
                <div className="border-bottom ">
                    <div className="container ">
                        <div className="justify-content-between align-items-center row">
                            <div className="col-sm-12 col-md-3 my-3">
                                <h5 className="text-body fw500 mb-2 ">
                                    Applicants
                                </h5>
                            </div>
                            {/* <div className="col-sm-12 col-md-3 text-right pb-3"></div> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-8 d-flex my-3 px-3 px-sm-0 ">
                    <div
                        role="button"
                        onClick={() => {
                            navigate(-1)
                        }}
                        className="w-100 p-3"
                        style={{ fontSize: 16 }}
                    >
                        <CustomTooltip title="Go back" placement="left">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-arrow-left"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                />
                            </svg>
                        </CustomTooltip>{' '}
                        Go back
                    </div>
                </div>
                <div className="col-12 col-md-8 d-flex my-3 px-3 px-sm-0 justify-content-between align-items-center">
                    <SelectJobPost
                        onChange={(value) => setSelectedJobPost(value)}
                        value={selectedJobPost}
                        placeholder="Select job post to view applicants"
                    />
                    <div>
                        <img
                            src={ArchiveIcon}
                            alt=""
                            className="hand"
                            onClick={() => {
                                navigate(`/archived?job=${selectedJobPost}`, {
                                    state: {
                                        tab: 'Archived',
                                    },
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-8 border mt-2 mb-5 ">
                    <div className="mt-3">
                        <ul
                            className="nav nav-pills nav-fill my-3 m-0"
                            id="myTab"
                            role="tablist"
                        >
                            {tabs.map((tab) => (
                                <li
                                    className="nav-item"
                                    role="presentation"
                                    key={tab}
                                >
                                    <button
                                        className={`nav-link ${
                                            tab === currentTab && 'active'
                                        }`}
                                        data-bs-toggle="tab"
                                        data-bs-target="#active"
                                        type="button"
                                        role="tab"
                                        aria-controls="active"
                                        aria-selected="true"
                                        onClick={() => {
                                            reset_scroll_changes(tab)
                                            setCurrentTab(tab)
                                        }}
                                    >
                                        {tab}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="mt-1 mx-1">
                        <Tabs activeKey={currentTab} className="border-none">
                            {tabs.map((tab) => {
                                let data = appliedApplicants
                                if (tab === 'Applied') data = appliedApplicants
                                if (tab === 'Shortlisted')
                                    data = shortListedApplicants
                                if (tab === 'Interviews')
                                    data = interviewApplicants
                                if (tab === 'Archived')
                                    data = archivedApplicants
                                return (
                                    <Tab
                                        eventKey={tab}
                                        key={tab}
                                        className="test"
                                    >
                                        <div className="row">
                                            <div className="my-2 col-12 col-sm-6 col-md-4">
                                                <div class="input-group">
                                                    <span
                                                        class="input-group-text"
                                                        id="basic-addon1"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            class="bi bi-search"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                        </svg>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Search"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={(e) => {
                                                            handleSearchApplicants(
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <div className="d-flex py-5">
                                                <div
                                                    className="spinner-border text-primary m-auto"
                                                    role="status"
                                                >
                                                    <span class="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <Applicants
                                                    applicants={data}
                                                    selectedJob={
                                                        selectedJobPost
                                                    }
                                                    tab={tab}
                                                    getApplicants={
                                                        getApplicants
                                                    }
                                                    isLoading={
                                                        isLoading ||
                                                        pagination_loading
                                                    }
                                                    swipeAction={swipeAction}
                                                    hasMoreItems={false}
                                                    // loadMoreItems={() =>
                                                    //     handleLoadMore(tab)
                                                    // }
                                                />
                                                {hasMoreData?.[tab] && (
                                                    <div className="d-flex justify-content-center py-3">
                                                        <button
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => {
                                                                handleLoadMore(
                                                                    tab
                                                                )
                                                            }}
                                                        >
                                                            {' '}
                                                            Load more
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </div>
                </div>
                <ExploreModal
                    showModal={modalOpen}
                    variant={modalVariant}
                    matchImages={matchImages}
                    setModalVariant={setModalVariant}
                    setShowModal={setModalOpen}
                    applicantName={applicantName}
                    jobTitle={jobTitle}
                />
            </div>
        </>
    )
}
export default Matches
