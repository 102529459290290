import { Box, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import JobluTypography from "../../../../components/Typography";
import SelectJobFunction from "../../../../components/reusables/SelectJobFunction";
import CustomGooglePlaces from "../../../../components/select/google-places";
import styles from "../styles";
import { InpageBanner, LargeBanner, MultiBanner, SmallBanner, VignetteBanner } from "../../../../components/adbanner";

const Sidebarfilter = ({
  handleSearch,
  onChange,
  currency,
  filterBtnRef,
  handleClear,
  filters,
}) => {
  const _location = useLocation();
  const locationParam = new URLSearchParams(_location.search).get("location");
  const searchParam = new URLSearchParams(_location.search).get("search");
  const jobFunctionParam = new URLSearchParams(_location.search).get(
    "jobFunction"
  );
  const workSetupParam = new URLSearchParams(_location.search).get("workSetup");
  const workPreferenceParam = new URLSearchParams(_location.search).get(
    "workPreference"
  );
  const salaryCurrencyParam = new URLSearchParams(_location.search).get(
    "currency"
  );
  const yearsOfExpParam = new URLSearchParams(_location.search).get(
    "yearsOfExp"
  );
  const salaryMinParam = new URLSearchParams(_location.search).get("salaryMin");
  const salaryMaxParam = new URLSearchParams(_location.search).get("salaryMax");

  const [jobFunction, setJobFunction] = useState("All Job Functions");
  const [location, setLocation] = useState(locationParam || "All Countries");

  const [queries, setQueries] = useState({
    jobFunction:
      jobFunctionParam || filters?.jobFunction || "All Job Functions",
    keyword: searchParam || filters?.keyword || null,
    workPreference:
      workPreferenceParam?.split(",") || filters?.workPreference || [],
    workSetup: workSetupParam?.split(",") || filters?.workSetup || [],
    location: locationParam || filters?.location || "All Countries",
    salaryMax: salaryMaxParam || filters?.salaryMax || null,
    salaryMin: salaryMinParam || filters?.salaryMin || null,
    salaryCurrency:
      salaryCurrencyParam || filters?.salaryCurrency || currency || "PHP",
    yearsOfExp: yearsOfExpParam || filters?.yearsOfExp || null,
    salaryRange: null,
  });

  const handleFilter = () => {
    handleSearch && handleSearch(queries);
  };

  const clearFilters = () => {
    setQueries({
      jobFunction: "All Job Functions",
      keyword: "",
      workPreference: [],
      workSetup: [],
      location: "All Countries",
      salaryMax: "",
      salaryMin: "",
      salaryCurrency: currency,
      yearsOfExp: "",
      salaryRange: "",
    });
    setJobFunction("All Job Functions");
    setLocation("All Countries");
    handleSearch({
      jobFunction: "All Job Functions",
      keyword: "",
      workPreference: [],
      workSetup: [],
      location: "All Countries",
      salaryMax: "",
      salaryMin: "",
      salaryCurrency: currency,
      yearsOfExp: "",
      salaryRange: "",
    });
    handleClear();
  };

  useEffect(() => {
    onChange && onChange(queries);
  }, [queries]);

  return (
    <Box
      style={{
        borderRadius: 5,
        padding: "20px",
        border: "1px solid #C9CCCE",
        background: "#fff",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFilter(null, false);
        }}
      >
        <Box style={styles.side_bar_box}>
          <JobluTypography.Body primary style={{ marginBottom: 5 }} semiBold>
            Search by keyword
          </JobluTypography.Body>
          <Box style={styles.side_bar_box}>
            <TextField
              placeholder={`Type job title, skills, company`}
              onChange={(e) => {
                setQueries({
                  ...queries,
                  keyword: e.target.value,
                });
              }}
              value={filters?.keyword}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Box>
          <JobluTypography.Body semiBold primary>
            Job Function
          </JobluTypography.Body>
          {/* <MuiSelectJobFunction
          value={jobFunction}
          onChange={(val) => {
            console.log(val);
            setQueries({
              ...queries,
              jobFunction: val,
            });
            setJobFunction(val);
          }}
        /> */}
          <SelectJobFunction
            value={filters?.jobFunction || "All Job Functions"}
            onChange={(val) => {
              setQueries({
                ...queries,
                jobFunction: val,
              });
              setJobFunction(val);
            }}
          />
        </Box>
        <JobluTypography.Body semiBold primary>
          Location
        </JobluTypography.Body>
        <Box style={styles.side_bar_box}>
          {/* 
        <SelectCountry2
          noCellCode
          value={filters?.location}
          grouped
          onChange={(val) => {
            setQueries({
              ...queries,
              location: val,
            });
            setLocation(val);
          }} */}

          <CustomGooglePlaces
            value={filters?.location}
            onChange={(place) => {
              setQueries({
                ...queries,
                location: place?.location,
              });
              setLocation(place?.location);
            }}
            onClear={() => {
              setQueries({
                ...queries,
                location: null,
              });
              setLocation(null);
            }}
          />
        </Box>

        <Box>
          <div className="mb-3">
            <div className="row">
              <div className="col-12">
                <JobluTypography.Body semiBold primary>
                  Salary Range
                </JobluTypography.Body>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-between">
                <select
                  name=""
                  id=""
                  className="form-select me-2"
                  onChange={(e) =>
                    setQueries({
                      ...queries,
                      salaryCurrency: e.target.value,
                    })
                  }
                  value={filters?.salaryCurrency}
                >
                  <option
                    value="PHP"
                    selected={currency === "PHP" ? true : false}
                  >
                    PHP
                  </option>
                  <option
                    value="SGD"
                    selected={currency === "SGD" ? true : false}
                  >
                    SGD
                  </option>
                  <option
                    value="USD"
                    selected={currency === "USD" ? true : false}
                  >
                    USD
                  </option>
                </select>
                <NumericFormat
                  thousandSeparator
                  type="text"
                  placeholder="Min"
                  className="form-control"
                  onValueChange={(formatted, val) => {
                    setQueries({
                      ...queries,
                      salaryMin: formatted?.formattedValue,
                    });
                  }}
                  value={filters?.salaryMin}
                />
                <span className="mx-1">-</span>
                <NumericFormat
                  thousandSeparator
                  type="text"
                  placeholder="Max"
                  className="form-control"
                  onValueChange={(formatted, val) => {
                    setQueries({
                      ...queries,
                      salaryMax: formatted?.formattedValue,
                    });
                  }}
                  value={filters?.salaryMax}
                />
              </div>
            </div>
          </div>
        </Box>
        <div className="mb-3">
          <JobluTypography.Body semiBold primary style={{ fontWeight: 600 }}>
            Experience Required
          </JobluTypography.Body>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="yearsOfExp"
              id="0-1"
              value="0-1"
              checked={filters?.yearsOfExp === "0-1"}
              onChange={(e) => {
                setQueries({ ...queries, yearsOfExp: e.target.value });
              }}
              defaultChecked={filters?.yearsOfExp === "0-1"}
            />
            <label class="form-check-label" for="0-1">
              0-1 Year
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="yearsOfExp"
              id="1-3"
              value="1-3"
              onChange={(e) => {
                setQueries({
                  ...queries,
                  yearsOfExp: e.target.value,
                });
              }}
              checked={filters?.yearsOfExp === "1-3"}
              defaultChecked={filters?.yearsOfExp === "1-3"}
            />
            <label class="form-check-label" for="1-3">
              1-3 Years
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="yearsOfExp"
              id="3-5"
              value="3-5"
              checked={queries?.yearsOfExp === "3-5"}
              onChange={(e) => {
                setQueries({
                  ...queries,
                  yearsOfExp: e.target.value,
                });
              }}
              defaultChecked={filters?.yearsOfExp === "3-5"}
            />
            <label class="form-check-label" for="3-5">
              3-5 Years
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="yearsOfExp"
              id="5-7"
              value="5-7"
              checked={filters?.yearsOfExp === "5-7"}
              defaultChecked={filters?.yearsOfExp === "5-7"}
              onChange={(e) => {
                setQueries({
                  ...queries,
                  yearsOfExp: e.target.value,
                });
              }}
            />
            <label class="form-check-label" for="5-7">
              5-7 Years
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="yearsOfExp"
              id="7"
              value="7-99"
              checked={queries?.yearsOfExp === "7-99"}
              onChange={(e) => {
                setQueries({
                  ...queries,
                  yearsOfExp: e.target.value,
                });
              }}
            />
            <label class="form-check-label" for="7">
              7+ Years
            </label>
          </div>
        </div>

        <div className="mb-3">
          <JobluTypography.Body semiBold primary>
            Work Setup
          </JobluTypography.Body>
          {/* WORK SETUP */}
          <div className="">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value="On-Site"
                name="workSetup"
                id="On-Site"
                checked={filters?.workSetup?.includes("On-Site")}
                onChange={(e) => {
                  console.log(e.target.value)
                  let q = queries;
                  console.log(q)
                  if (q.workSetup?.includes(e.target.value)) {
                    q.workSetup.splice(
                      queries.workSetup.indexOf(e.target.value),
                      1
                    );
                  } else {
                    q?.workSetup?.push(e.target.value);
                  }
                  setQueries({ ...q });
                }}
              />
              <label
                className="form-check-label open-sans ms-3"
                htmlFor="On-Site"
              >
                On-Site
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value="Remote"
                name="workSetup"
                id="Remote"
                checked={filters?.workSetup?.includes("Remote")}
                onChange={(e) => {
                  console.log(e.target.value)
                  let q = queries;
                  if (q.workSetup?.includes(e.target.value)) {
                    q.workSetup.splice(
                      queries.workSetup.indexOf(e.target.value),
                      1
                    );
                  } else {
                    q.workSetup.push(e.target.value);
                  }
                  setQueries({ ...q });
                }}
              />
              <label
                className="form-check-label open-sans ms-3"
                htmlFor="Remote"
              >
                Remote
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value="Hybrid"
                name="workSetup"
                checked={filters?.workSetup?.includes("Hybrid")}
                id="Hybrid"
                onChange={(e) => {
                  console.log(e.target.value)
                  let q = queries;
                  if (q.workSetup?.includes(e.target.value)) {
                    q.workSetup.splice(
                      queries.workSetup.indexOf(e.target.value),
                      1
                    );
                  } else {
                    q.workSetup.push(e.target.value);
                  }
                  setQueries({ ...q });
                }}
              />
              <label
                className="form-check-label open-sans ms-3"
                htmlFor="Hybrid"
              >
                Hybrid
              </label>
            </div>
          </div>
        </div>
        <Box style={styles.side_bar_box}>
          <JobluTypography.Body semiBold primary>
            Employment Type
          </JobluTypography.Body>
          {/* WORK PREFERENCE */}
          <div className="">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value="Full-Time"
                name="workPreference"
                checked={filters?.workPreference?.includes("Full-Time")}
                id="Full-Time"
                onChange={(e) => {
                  let q = queries;
                  if (q.workPreference?.includes(e.target.value)) {
                    q.workPreference.splice(
                      queries.workPreference.indexOf(e.target.value),
                      1
                    );
                  } else {
                    q.workPreference.push(e.target.value);
                  }
                  setQueries({ ...q });
                }}
              />
              <label
                className="form-check-label open-sans ms-3"
                htmlFor="Full-Time"
              >
                Full-Time
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value="Part-Time"
                name="workPreference"
                checked={filters?.workPreference?.includes("Part-Time")}
                id="Part-Time"
                onChange={(e) => {
                  let q = queries;
                  if (q.workPreference?.includes(e.target.value)) {
                    q.workPreference.splice(
                      queries.workPreference.indexOf(e.target.value),
                      1
                    );
                  } else {
                    q.workPreference.push(e.target.value);
                  }
                  setQueries({ ...q });
                }}
              />
              <label
                className="form-check-label open-sans ms-3"
                htmlFor="Part-Time"
              >
                Part-Time
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value="Project-Based"
                name="workPreference"
                checked={filters?.workPreference?.includes("Project-Based")}
                id="Project-Based"
                onChange={(e) => {
                  let q = queries;
                  if (q.workPreference?.includes(e.target.value)) {
                    q.workPreference.splice(
                      queries.workPreference.indexOf(e.target.value),
                      1
                    );
                  } else {
                    q.workPreference.push(e.target.value);
                  }
                  setQueries({ ...q });
                }}
              />
              <label
                className="form-check-label open-sans ms-3"
                htmlFor="Project-Based"
              >
                Project-Based
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value="Internship"
                name="workPreference"
                checked={filters?.workPreference?.includes("Internship")}
                id="Internship"
                onChange={(e) => {
                  let q = queries;
                  if (q.workPreference?.includes(e.target.value)) {
                    q.workPreference.splice(
                      queries.workPreference.indexOf(e.target.value),
                      1
                    );
                  } else {
                    q.workPreference.push(e.target.value);
                  }
                  setQueries({ ...q });
                }}
              />
              <label
                className="form-check-label open-sans ms-3"
                htmlFor="Internship"
              >
                Internship
              </label>
            </div>
          </div>
        </Box>
        <div className="d-grid" style={{ marginBottom: 10 }}>
          <Button
            color="inherit"
            variant="contained"
            onClick={() => clearFilters()}
            style={{ textTransform: "none", fontFamily: "Open Sans" }}
          >
            Clear Filters
          </Button>
        </div>
        <div className="d-grid">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{ textTransform: "none", fontFamily: "Open Sans" }}
            ref={filterBtnRef}
          >
            Search Jobs
          </Button>
        </div>
      </form>
      <div style={{marginTop: 30}}></div>
      {/* <SmallBanner/>
      <SmallBanner/> */}
      {/* <MultiBanner/> */}
      <InpageBanner/>
      <VignetteBanner/>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    currency: state.Generals.currency,
  };
};
export default connect(mapStateToProps, null)(Sidebarfilter);
