import { Snackbar } from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import userAction from "../src/redux/user/actions";
import Header from "./components/header/Header";
import ProfileCompletionBanner from "./components/header/ProfileCompletion";
import rootAction from "./redux/action.js";
import appActions from "./redux/app/actions";
import jobfairActions from "./redux/job-fair/jobfairActions";
import privateRoutes from "./routes/private-routes";
import publicRoutes from "./routes/public-routes";
import { generalSettingsRequest, jobFairRequest, userRequest } from "./service/requests";
import NotFound from "./views/not-found";
import MetaTags from 'react-meta-tags'

function App(props) {
  const {
    User,
    getUser,
    setCurrencyList,
    setShowTutorial,
    setChatRooms,
    setToken,
    AppState,
    softReset,
    setJobFairs,
    JobFairs,
    setShowBanner,
    setShowAlert,
  } = props;
  const {
    isCreatingEmployer,
    isSignedIn,
    user,
    chatRooms,
    isSigningIn,
    isGoogleSignup,
    error,
    isFetching,
    isFetched,
  } = User;

  const { showAlert } = AppState;
  const [routes, setRoutes] = useState(privateRoutes);
  const location = useLocation();
  const navigate = useNavigate();
  const [noNav, setNoNav] = useState(false);

  




  useEffect(() => {
    if (!isSignedIn && isGoogleSignup) {
      setRoutes(publicRoutes);
      setTimeout(() => {
        if (location.pathname === "/") {
          navigate("/signup");
        }
      }, 500);
    } else if (!isCreatingEmployer && !!isSignedIn && !isGoogleSignup) {
      setRoutes(privateRoutes);
      if (
        location.pathname.includes("/register") ||
        location.pathname.includes("/login") ||
        location.pathname.includes("/board")
      ) {
        if (
          user?.portfolioAndFiles?.length < 1 &&
          user?.softSkills?.length < 1
        ) {
          navigate(`/create-profile`, { state: { ...location?.state } });
        }
        if (location.state?.refId) {
          navigate(`/job/${location.state?.refId}`);
        } else {
          navigate("/dashboard");
        }
      }
      if (isFetched) {
        const companyUsername = location?.pathname.replace(/[^a-zA-Z0-9_]/g, '');
        userRequest
        .getEmployerDetailsP(companyUsername)
        .then(() => {
          navigate(`/company-showcase/${companyUsername}?tab=jobs`)
        })
        .catch(() => {})
        
        if (location.pathname.includes("/create-profile")) {
          if (
            user?.portfolioAndFiles?.length > 0 ||
            user?.softSkills?.length > 0
          ) {
            navigate("/dashboard");
            return;
          }
        } else {
          if (
            user?.portfolioAndFiles?.length < 1 &&
            user?.softSkills?.length < 1
          ) {
            navigate("/create-profile", { state: { ...location?.state } });
          } else {
            getUser();
            getJobFairs();
            setTimeout(() => {
              if (location.pathname === "/" || location.pathname === "/login") {
                navigate("/dashboard");
              }
            }, 500);
            return;
          }
        }
        
      } else {
        getUser();
      }
    } else {
      setRoutes(publicRoutes);
      // console.log("pppp", location.pathname);
      if (
        location.pathname === "/password-reset" ||
        location.pathname.includes("/register") ||
        location.pathname.includes("/showcase") ||
        location.pathname.includes("/job/") ||
        location.pathname.includes("/company-showcase") ||
        location.pathname.includes("/signup") ||
        location.pathname === "/verify" ||
        location.pathname === "/downloadprofilepdf" ||
        location.pathname === "/job-fair" ||
        location.pathname === "/signup/applicant" ||
        location.pathname === "/board" ||
        location.pathname === "/login"
      ) {
        // navigate(location.pathname);
      } else {
        const companyUsername = location?.pathname.replace(/[^a-zA-Z0-9_]/g, '');
        userRequest
        .getEmployerDetailsP(companyUsername)
        .then(() => {
          navigate(`/company-showcase/${companyUsername}?tab=jobs`)
        })
        .catch(() => {
          if (location.pathname !== "/dashboard") {
            setTimeout(() => {
              navigate("/board");
            }, 500);
          } else {
            if (!isSignedIn) {
              setTimeout(() => {
                redirectToLogin();
              }, 500);
            }
          }
        })
      }
    }
  }, [isSignedIn, isGoogleSignup, isFetched]);
  

  const redirectToLogin = async () => {
    const source = new URLSearchParams(location.search).get("source");
    const code = new URLSearchParams(location.search).get("influencerCode");
    const refId = new URLSearchParams(location.search).get("refId");
    let url = "/login";
    if (refId) {
      url = url + "?refId=" + refId;
    }
    if (code) {
      url = url + `${refId ? "&" : "?"}influencerCode=` + code;
    }
    if (source) {
      url = url + `${refId || code ? "&" : "?"}source=` + source;
    }
    navigate(url);
  };

  useEffect(() => {
    if (
      !!JobFairs.signedInJobFair &&
      !location.pathname.includes("/register")
    ) {
      navigate(`/job-fair/dashboard/${JobFairs.signedInJobFair}`);
    }
  }, [JobFairs]);

  useEffect(() => {
    if (Boolean(showAlert)) {
      setTimeout(() => {
        setShowAlert(false);
      }, showAlert?.duration || 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    const isNoNav = new URLSearchParams(location.search).get("noNav");
    // if (location.pathname !== '/dashboard') {
    const root = document.getElementById("root");
    root.classList.remove("overflow-hidden");
    // }
    isNoNav ? setNoNav(true) : setNoNav(false);
  }, [location]);

  useEffect(() => {
    softReset();
  }, []);

  useEffect(() => {
    try {
      generalSettingsRequest.getCurrencies().then((res) => {
        if (res) {
          let data = res.data;
          let currencies = {};
          data.currencyTypes?.forEach((item) => {
            currencies[item.baseCurrency] = {
              currencies: item.currencies,
            };
          });
          setCurrencyList(currencies);
        } else {
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getJobFairs = () => {
    try {
      jobFairRequest.getOngoingJobFairs().then((res) => {
        if (res) {
          setJobFairs(res?.data);
          res?.data?.totalCount > 0
            ? setShowBanner(true)
            : setShowBanner(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };


  const MetaTag = () => {
    return (
      <MetaTags>
        <title>Joblu For Job Seekers</title>
        <meta name="title" content="Joblu for Job Seekers: Sign in to apply for jobs" />
        <meta name="description"
            content="Whether you’re looking for internships or full-time opportunities, we got you covered. Sign in to explore 5000+ global job opportunities." />
      </MetaTags>
    )
  }

  return (
    <>
      {
        location.pathname?.includes('/board') || (location.pathname?.includes('/job/') && location?.pathname !== '/jobs') || location.pathname?.includes('/company-showcase')
        ? null
        : <MetaTag/>
      }
      <div
        className={`mx-0 position-relative ${
          location.pathname !== "/login" &&
          location.pathname !== "/signup" &&
          location.pathname !== "/create-job" &&
          location.pathname !== "/jobs/edit" &&
          location.pathname !== "/profile-wizard" &&
          location.pathname !== "/pricing" &&
          !location.pathname.includes("/showcase") &&
          !location.pathname === "/signup/applicant"
            ? "mb50"
            : ""
        }`}
        id="app-main"
        // style={{ marginBottom: 50 }}
      >
        {!noNav ||
          (location.pathname !== "/create-profile" && (
            <ProfileCompletionBanner />
          ))}
        {/* {!!isSignedIn && location.pathname !== "/create-profile" && (
          <>
            <Announcement />
          </>
        )} */}
        {
          !!isSignedIn &&
            location.pathname !== "/login" &&
            location.pathname !== "/signup" &&
            location.pathname !== "/dashboard-public" &&
            // location.pathname !== "/create-profile" &&
            // location.pathname !== "/create-profile" &&
            !location.pathname.includes("/job-fair/register") && (
              // !location?.state?.fromCreate && (
              <>
                <Header />
              </>
            )
          // )
        }
        <div>
          <Routes>
            {routes.map((route) => (
              <Route
                path={route.path}
                key={route.name}
                element={route?.component}
                index={route?.index}
              >
                {route?.nested_routes?.map((nested) => {
                  return (
                    <Route
                      key={nested?.name}
                      path={nested?.path}
                      element={nested?.component}
                      index={nested?.index}
                    />
                  );
                })}
              </Route>
            ))}
            {!!isSignedIn && !!isSigningIn && (
              <>
                <Route path="*" element={<NotFound />} key="Not Found" />
              </>
            )}
          </Routes>
        </div>
      </div>
      <Snackbar
        open={Boolean(showAlert)}
        autoHideDuration={showAlert?.duration || 3000}
        anchorOrigin={{
          vertical: showAlert?.position || "bottom",
          horizontal: "right",
        }}
      >
        {showAlert && (
          <Alert
            variant="filled"
            severity={showAlert?.type}
            sx={{ width: "100%" }}
          >
            {showAlert?.message}
          </Alert>
        )}
      </Snackbar>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    User: state.User,
    AppState: state.Generals,
    JobFairs: state.JobFairs,
  };
};
const mapDispatchToProps = {
  signOut: rootAction.signOut,
  getUser: userAction.getUser,
  setCurrencyList: appActions.setCurrenyList,
  setShowTutorial: appActions.setShowTutorial,
  setChatRooms: userAction.setChatRooms,
  setToken: userAction.setToken,
  softReset: userAction.softReset,
  setJobFairs: jobfairActions.setJobFairs,
  setShowBanner: jobfairActions.setShowBanner,
  setShowAlert: appActions.showAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
