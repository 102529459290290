import { useEffect, useState } from "react";
import DefaultImg from "../../assets/img/defaultImg.png";
import DefaultProfileBg from "../../assets/img/companyShowcase/profileBg.png";
import ValuesIcon from "../../assets/img/companyShowcase/valuesIcon.png";
import MissionIcon from "../../assets/img/companyShowcase/missionIcon.png";
import WorkSetupIcon from "../../assets/img/companyShowcase/workSetupIcon.png";
import MembersIcon from "../../assets/img/companyShowcase/membersIcon.png";
import VisionIcon from "../../assets/img/companyShowcase/visionIcon.png";
import profilePlayIcon from "../../assets/img/companyShowcase/profilePlayIcon.png";
import EmptyImg from "../../assets/img/companyShowcase/CompanyShowCaseEmpty.png";
import { company_showcase_styles } from "./styles";
import VideoThumbnail from "react-video-thumbnail";
import Carousel from "react-grid-carousel";
import JobluVideoPlayer from "../explore/components/VideoPlayer";
import TextField from "../../components/inputs/CustomTextField";
import { useLocation, useParams } from "react-router-dom";
import { userRequest } from "../../service/requests";
import { createURL, formatString } from "../../utils/helpers";
import "./style.css";
import { Box, CircularProgress, Dialog, Grid } from "@material-ui/core";
import NotFound from "../not-found";
import { app_styles } from "../../general.styles";
import PublicHeader from "../../components/header/PublicHeader";
import Footer from "../../components/footer/Footer";
import GridCard from "../explore-public/jobseeker-explore/components/GridCard";
import JobluTypography from "../../components/Typography";
import GridLoader from "../explore-public/jobseeker-explore/components/loaders/GridLoader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "../../assets/img/closeIcon.png";
import useWindowDimensions from "../../utils/hooks";
import ReactPlayer from "react-player";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { InpageBanner, LargeBanner, MediumBanner, MultiBanner, NativeBanner, SmallBanner, VignetteBanner } from "../../components/adbanner";

const CompanyShowcasePublic = (props) => {
  const params = useParams();
  const app_classes = app_styles(props);
  const employerId = params.empId;
  const { width } = useWindowDimensions();

  const [employerProfiles, setEmployerProfiles] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employerJobs, setEmployerJobs] = useState([]);
  const [jobsLoading, setJobsLoading] = useState(false);
  const _location = useLocation();
  const tab = new URLSearchParams(_location.search).get("tab");
  const [limit, setLimit] = useState(12);
  const [offset, setOffset] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [empId, setEmpId] = useState(null);
  useEffect(() => {
    userRequest
      .getEmployerDetailsP(employerId)
      .then((res) => {
        console.log("TEST:::", res?.data);
        setEmployerProfiles(res?.data?.employerProfiles);
        setEmpId(res?.data?.employerProfiles?.userId);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [employerId]);


  const addView = (ip) => {
    userRequest.addView({
      logData: {
        id: ip,
        link: `https://jobs.joblu.io/company-showcase/${employerProfiles?.userName}`
      },
      id: employerProfiles?.userName,
      type: "COMPANY"
    })
    .then(res => console.log(res))
    .catch(err => console.log(err))
  }

  useEffect(() => {
    if(employerProfiles){
      axios.get("https://geolocation-db.com/json/")
      .then(res => {
        addView(res?.data?.IPv4)
      })
      .catch(e => {
        addView(Math.floor(Math.random() * 900000) + 100000)
      })
    }
  }, [employerProfiles])

  useEffect(() => {
    if (empId) getJobs();
  }, [empId]);

  const getJobs = (page) => {
    console.log(page);
    let payload = {
      limit,
      offset: page || offset,
      keyword: null,
    };

    setJobsLoading(true);
    userRequest.getEmployerJobs(empId, payload).then((res) => {
      setEmployerJobs(res.data.data);
      setTotalCount(res.data.totalCount);
      setTimeout(() => {
        setJobsLoading(false);
      }, 1000);
    });
  };

  const [searchText, setSearchText] = useState(null);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let payload = {
        limit,
        offset: 1,
        keyword: searchText || null,
      };

   
      setJobsLoading(true);
      userRequest.getEmployerJobs(empId, payload).then((res) => {
        // console.log("emp jobs 123: ", res.data);
        setEmployerJobs(res.data.data);
        setTotalCount(res.data.totalCount);
        setTimeout(() => {
          setJobsLoading(false);
        }, 1000);
      }).catch(()=>{
        setJobsLoading(false)
      });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const nextPage = () => {
    let page = offset + 1;
    setOffset(page);
    console.log("next", page);
    setTimeout(() => {
      getJobs(page);
    }, 500);
  };
  const prevPage = () => {
    let page = offset - 1;
    setOffset(page);
    console.log("prev", page);
    setTimeout(() => {
      getJobs(page);
    }, 500);
  };

  const [companyCulture, setCompanyCulture] = useState(
    employerProfiles?.companyCulture
  );
  const [vidFromHeart, setVidFromHeart] = useState(
    employerProfiles?.vidFromHeart
  );
  const [employeeReview, setEmployeeReview] = useState([
    employerProfiles?.employeeReview,
  ]);

  const company_showcase_classes = company_showcase_styles(props);
  const [currentVideoLink, setCurrentVideoLink] = useState(null);
  const [playVideo, setPlayVideo] = useState(null);
  const handlePlayVideo = () => {
    setPlayVideo(!playVideo);
  };
  const playVid = (link) => {
    setCurrentVideoLink(link);
    handlePlayVideo();
  };

  useEffect(() => {
    let copy = [];
    console.log(employerProfiles?.companyCulture);
    employerProfiles?.companyCulture?.map((item) => {
      console.log(item);
      if (!!item?.featured) {
        const isExternalVideo =
          (item?.link &&
            createURL(item?.link)?.hostname === "www.youtube.com") ||
          (item?.link && createURL(item?.link)?.hostname === "vimeo.com");

        const isVideo =
          item?.link?.split(".")?.pop() === "mp4" || isExternalVideo;
        item.isVideo = isVideo;
        copy.push(item);
      }
    });

    setCompanyCulture(copy);
  }, [employerProfiles]);

  const [activeTab, setActiveTab] = useState(tab || "profile");

  // const job = {
  //   profileUrl: DefaultImg,
  //   jobTitle: "Software Developer",
  //   companyName: "Company ABC",
  //   location: "Philippines",
  //   workSetup: ["Work from home"],
  //   employmentType: ["Full-time"],
  //   totalExperienceMin: 1,
  //   totalExperienceMax: 3,
  //   technicalSkills: [
  //     "Adobe Photoshop",
  //     "ReactJS",
  //     "React Native",
  //     "HTML",
  //     "CSS",
  //   ],
  // };

  const [currentImg, setCurrentImg] = useState(null);
  const [imgOpen, setImgOpen] = useState(false);
  const [imgSize, setImgSize] = useState("md");

  const MetaTag = () => {
    const companyName = employerProfiles?.companyName;
    if (companyName) {
      return (
        <MetaTags>
          <title>{`${companyName} Careers, Culture and Job Openings`}</title>
          <meta
            name="description"
            content={`Check out the Culture, Careers, and Job Openings of ${companyName} through Joblu.`}
          />
          <meta
            property="og:title"
            content={`${companyName} Careers, Culture and Job Openings`}
          />
          <meta
            property="og:description"
            content={`Check out the Culture, Careers, and Job Openings of ${companyName} through Joblu.`}
          />
          <meta
            property="og:image"
            content="https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/joblu-meta-img.jpeg"
          />
        </MetaTags>
      );
    } else {
      return (
        <MetaTags>
          <title>{`Company Careers, Culture and Job Openings`}</title>
          <meta
            name="description"
            content={`Check out the Culture, Careers, and Job Openings through Joblu.`}
          />
          <meta
            property="og:title"
            content={`Careers, Culture and Job Openings`}
          />
          <meta
            property="og:description"
            content={`Check out the Culture, Careers, and Job Openings through Joblu.`}
          />
          <meta
            property="og:image"
            content="https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/joblu-meta-img.jpeg"
          />
        </MetaTags>
      );
    }
  };

  const [coverHeight, setCoverHeight] = useState(null);
  const [coverWidth, setCoverWidth] = useState(null);

  if (loading) {
    return (
      <>
        <Box
          className={`${app_classes.height.vh100} ${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center} ${app_classes.flexBox.justify_content_center}`}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      </>
    );
  } else {
    if (employerProfiles) {
      return (
        <>
          <MetaTag />
          <Dialog
            fullWidth
            maxWidth={imgSize}
            open={imgOpen}
            onClose={() => {
              setImgOpen(false);
            }}
          >
            <Box style={{ position: "relative" }}>
              <Box style={{ position: "absolute", right: 10, top: 10 }}>
                <img
                  src={CloseIcon}
                  width={42}
                  className={`${app_classes.cursor.pointer}`}
                  onClick={() => {
                    setImgOpen(false);
                  }}
                />
              </Box>
              <img src={currentImg || null} style={{ width: "100%" }} />
            </Box>
          </Dialog>
          <PublicHeader />
          <div className="row justify-content-center my-5 w-100 gx-0">
            <div className="col-md-9">
              <div className=" pb-5">
                <div className="text-center">
                  <div
                    className="profile-banner"
                    style={{
                      // height: 300,
                      borderRadius: 15,
                      // backgroundImage: `url(${
                      //   employerProfiles?.coverUrl || DefaultProfileBg
                      // })`,
                    }}
                  >
                    <img
                      src={employerProfiles?.coverUrl || DefaultProfileBg}
                      style={{
                        width: "100%",
                        // maxHeight: 350,
                        maxHeight: coverHeight > 500 ? 350 : "auto",
                        objectFit: `${
                          coverHeight > 350 || coverWidth > 900
                            ? width < 640
                              ? "contain"
                              : "cover"
                            : "contain"
                        }`,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setImgSize("xl");
                        setCurrentImg(
                          employerProfiles?.coverUrl || DefaultProfileBg
                        );
                        setImgOpen(true);
                      }}
                      onLoad={(e) => {
                        setCoverHeight(e.target.naturalHeight);
                        setCoverWidth(e.target.naturalWidth);
                      }}
                    />
                  </div>
                  <img
                    className="rounded-circle mb-3"
                    style={{
                      height: 200,
                      width: 200,
                      objectFit: "cover",
                      border: "10px solid #caccce",
                      marginTop: "-100px",
                      background: "#FFFFFF",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setImgSize("sm");
                      setCurrentImg(employerProfiles?.profileUrl || DefaultImg);
                      setImgOpen(true);
                    }}
                    src={employerProfiles?.profileUrl || DefaultImg}
                    alt=""
                  />
                  <h2 className="mb-3">{employerProfiles?.companyName}</h2>
                  <h6 className="text-muted mb-3">
                    {employerProfiles?.country}
                  </h6>
                  {employerProfiles?.tagline && (
                    <>
                      <h6 className="text-muted mb-3">
                        "{employerProfiles?.tagline}"
                      </h6>
                    </>
                  )}
                </div>
                {/* <LargeBanner/> */}
                {/* <MultiBanner/> */}
                <InpageBanner/>
                <VignetteBanner/>
                <div className="px-5 mt-5">
                  <Box
                    className={`${app_classes.display.display_flex} ${app_classes.margin.b3}`}
                  >
                    <Box
                      className={`${app_classes.padding.a3} ${app_classes.cursor.pointer}`}
                      onClick={() => {
                        setActiveTab("profile");
                      }}
                      style={{
                        borderBottom:
                          activeTab == "profile" && "3px solid #002E5D",
                      }}
                    >
                      <JobluTypography.Body
                        semiBold
                        style={{ fontSize: 14, fontWeight: 600 }}
                        className={`${
                          activeTab == "profile"
                            ? app_classes.text.primary
                            : app_classes.text.muted
                        }`}
                      >
                        Profile
                      </JobluTypography.Body>
                    </Box>
                    <Box
                      className={`${app_classes.padding.a3} ${app_classes.cursor.pointer}`}
                      onClick={() => {
                        setActiveTab("culture");
                      }}
                      style={{
                        borderBottom:
                          activeTab == "culture" && "3px solid #002E5D",
                      }}
                    >
                      <JobluTypography.Body
                        semiBold
                        style={{ fontSize: 14, fontWeight: 600 }}
                        className={`${
                          activeTab == "culture"
                            ? app_classes.text.primary
                            : app_classes.text.muted
                        }`}
                      >
                        Our Culture
                      </JobluTypography.Body>
                    </Box>
                    <Box
                      className={`${app_classes.padding.a3} ${app_classes.cursor.pointer}`}
                      onClick={() => {
                        setActiveTab("jobs");
                      }}
                      style={{
                        borderBottom:
                          activeTab == "jobs" && "3px solid #002E5D",
                      }}
                    >
                      <JobluTypography.Body
                        semiBold
                        style={{ fontSize: 14, fontWeight: 600 }}
                        className={`${
                          activeTab == "jobs"
                            ? app_classes.text.primary
                            : app_classes.text.muted
                        }`}
                      >
                        Jobs
                      </JobluTypography.Body>
                    </Box>
                  </Box>
                  {/* PROFILE TAB */}
                  {activeTab == "profile" && (
                    <>
                      {/* <Box style={{ marginBottom: 70 }}>
                        <Box>
                          <h4 className="text-primary mb-5">Featured Jobs</h4>
                        </Box>
                        <Grid container spacing={2}>
                          {employerJobs?.slice(0, 4)?.map((job) => {
                            return (
                              <Grid item xs={12} sm={3} lg={3}>
                                {!jobsLoading ? (
                                  <>
                                    <GridCard
                                      job={job}
                                      publicJob={true}
                                      isFromProfile={true}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <GridLoader />
                                  </>
                                )}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box> */}
                      <div className="row mb-5">
                        <div
                          className={`${
                            employerProfiles?.vidFromHeart?.length > 0 &&
                            employerProfiles?.vidFromHeart?.filter(
                              (item) => item?.featured === true
                            ).length > 0
                              ? "col-md-4 col-sm-12 mb-3 mb-md-0"
                              : "d-none"
                          }`}
                        >
                          {employerProfiles?.vidFromHeart?.length > 0 &&
                            employerProfiles?.vidFromHeart?.filter(
                              (item) => item?.featured === true
                            ).length > 0 && (
                              <>
                                <div>
                                  {employerProfiles?.vidFromHeart?.map(
                                    (item) => {
                                      if (item.featured) {
                                        return (
                                          <>
                                            <div
                                              className="w-100 empProf position-relative"
                                              // style={{ height: "300px" }}
                                            >
                                              {item?.link && (
                                                <div className="h-100">
                                                  <ReactPlayer
                                                    url={item?.link}
                                                    width={"100%"}
                                                    height={"100%"}
                                                  />
                                                  {/* <VideoThumbnail
                                                    videoUrl={item.link}
                                                  /> */}
                                                </div>
                                              )}
                                              <div
                                                className="position-absolute"
                                                style={{
                                                  backgroundColor: "#0000004D",
                                                  top: 0,
                                                  left: 0,
                                                  right: 0,
                                                  bottom: 0,
                                                }}
                                              ></div>
                                              <img
                                                src={profilePlayIcon}
                                                alt=""
                                                className="position-absolute hand"
                                                width={70}
                                                onClick={() => {
                                                  playVid(item.link);
                                                }}
                                                style={{
                                                  left: "50%",
                                                  top: "50%",
                                                  transform:
                                                    "translate(-50%, -50%)",
                                                }}
                                              />
                                            </div>
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                              </>
                            )}
                        </div>
                        <div
                          className={`${
                            employerProfiles?.vidFromHeart?.length > 0 &&
                            employerProfiles?.vidFromHeart?.filter(
                              (item) => item?.featured === true
                            ).length > 0
                              ? "col-md-8 col-sm-12"
                              : "col-md-12"
                          } `}
                        >
                          <h4 className="mb-2 text-primary">About Us</h4>
                          {employerProfiles?.companyOverview ? (
                            <>
                              <h6
                                className=""
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                {employerProfiles?.companyOverview}
                              </h6>
                            </>
                          ) : (
                            <>
                              <h6
                                className="text-center text-muted"
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                {employerProfiles?.companyName} has yet to share
                                their company story.
                              </h6>
                            </>
                          )}
                        </div>
                      </div>
                      {(employerProfiles?.companySize ||
                        employerProfiles?.workSetup ||
                        employerProfiles?.yearEstablished) && (
                        <>
                          {" "}
                          <div
                            className="row mb-5 justify-content-center p-5"
                            style={{ background: "#F6F9FB" }}
                          >
                            {employerProfiles?.companySize && (
                              <div className="col-12 mb-3 mb-md-0 col-md-4 d-flex justify-content-center  flex-column flex-md-row align-items-center">
                                <div className="me-0 me-md-3">
                                  <img
                                    src={MembersIcon}
                                    style={{ width: 40 }}
                                    alt=""
                                  />
                                </div>
                                <div className="text-center text-md-start">
                                  <h4>
                                    {employerProfiles?.companySize || "N/A"}
                                  </h4>
                                  <h6>Team Members</h6>
                                </div>
                              </div>
                            )}

                            {employerProfiles?.workSetup && (
                              <div className="col-12 mb-3 mb-md-0 col-md-4 d-flex justify-content-center flex-column flex-md-row align-items-center">
                                <div className="me-0 me-md-3">
                                  <img
                                    src={WorkSetupIcon}
                                    style={{ width: 40 }}
                                    alt=""
                                  />
                                </div>
                                <div className="text-center text-md-start">
                                  <h4>
                                    {employerProfiles?.workSetup || "N/A"}
                                  </h4>
                                  <h6>Work Setup</h6>
                                </div>
                              </div>
                            )}
                            {employerProfiles?.yearEstablished && (
                              <div className="col-12 col-md-4 d-flex justify-content-center  flex-column flex-md-row align-items-center">
                                <div className="me-0 me-md-3">
                                  <img
                                    src={MembersIcon}
                                    style={{ width: 40 }}
                                    alt=""
                                  />
                                </div>
                                <div className="text-center text-md-start">
                                  <h4>
                                    {employerProfiles?.yearEstablished || "N/A"}
                                  </h4>
                                  <h6>Year Established</h6>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      <div className="mb-5">
                        <h4 className="text-primary mb-5">
                          Our Mission and Values
                        </h4>

                        <div className="mb-5">
                          <div className="mb-4 d-flex align-items-center">
                            <div className="me-3">
                              <img
                                src={MissionIcon}
                                className="profile-icon"
                                width={40}
                                alt=""
                              />
                            </div>
                            <div>
                              <p>Our</p>
                              <h4 className="mb-0">Mission Statement</h4>
                            </div>
                          </div>
                          <div>
                            {employerProfiles?.companyMission ? (
                              <>
                                {" "}
                                <h6 style={{ whiteSpace: "pre-wrap" }}>
                                  {employerProfiles?.companyMission}
                                </h6>
                              </>
                            ) : (
                              <>
                                <h6
                                  className="text-center text-muted"
                                  style={{ whiteSpace: "pre-wrap" }}
                                >
                                  {employerProfiles?.companyName} will reveal
                                  their mission shortly.
                                </h6>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="mb-5">
                          <div className="mb-4 d-flex align-items-center">
                            <div className="me-3">
                              <img
                                src={VisionIcon}
                                className="profile-icon"
                                width={40}
                                alt=""
                              />
                            </div>
                            <div>
                              <p>Our</p>
                              <h4 className="mb-0">Vision</h4>
                            </div>
                          </div>
                          <div>
                            {employerProfiles?.companyVision ? (
                              <h6 style={{ whiteSpace: "pre-wrap" }}>
                                {employerProfiles?.companyVision}
                              </h6>
                            ) : (
                              <h6
                                className="text-center text-muted"
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                {employerProfiles?.companyName} will reveal
                                their mission shortly.
                              </h6>
                            )}
                          </div>
                        </div>
                        {employerProfiles?.companyValues && (
                          <>
                            <div className="mb-5">
                              <div className="mb-4 d-flex align-items-center">
                                <div className="me-3">
                                  <img
                                    src={ValuesIcon}
                                    className="profile-icon"
                                    width={40}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <p>Our</p>
                                  <h4 className="mb-0">Values</h4>
                                </div>
                              </div>
                              <div>
                                {employerProfiles?.companyValues ? (
                                  <h6 style={{ whiteSpace: "pre-wrap" }}>
                                    {employerProfiles?.companyValues}
                                  </h6>
                                ) : (
                                  <h6
                                    className="text-muted text-center"
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    {employerProfiles?.companyName} will show
                                    their value, soon!
                                  </h6>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {/* CULTURE TAB */}
                  {activeTab == "culture" && (
                    <>
                      {/* COMPANY CULTURE */}
                      <Box>
                        {/* <h4 className="text-primary mb-4">Our Culture</h4> */}
                        <br></br>
                      </Box>
                      <h6 className="mb-5" style={{ whiteSpace: "pre-wrap" }}>
                        {employerProfiles?.cultureDescription}
                      </h6>
                      {companyCulture.length > 0 ||
                      employerProfiles?.employeeReview?.length > 0 ||
                      employerProfiles?.cultureDescription ? (
                        <>
                          {companyCulture?.length > 0 && (
                            <>
                              <div className="mb-5">
                                <div className="row">
                                  <div className="col-12 col-md-12 row gx-3">
                                    {companyCulture?.map((item, index) => {
                                      return (
                                        <div className="col-4 col-md-4 mb-3">
                                          {companyCulture[index]?.isVideo ? (
                                            <>
                                              <div className="w-100 compShowcase position-relative h-100">
                                                {companyCulture[index]
                                                  ?.link && (
                                                  <div className="h-100">
                                                    <ReactPlayer
                                                      url={
                                                        companyCulture[index]
                                                          ?.link
                                                      }
                                                      width={"100%"}
                                                      height={"100%"}
                                                    />
                                                    {/* <VideoThumbnail
                                                      videoUrl={
                                                        companyCulture[index]
                                                          ?.link
                                                      }
                                                    /> */}
                                                  </div>
                                                )}
                                                <div
                                                  className="position-absolute"
                                                  style={{
                                                    backgroundColor:
                                                      "#0000004D",
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                  }}
                                                ></div>
                                                <img
                                                  src={profilePlayIcon}
                                                  alt=""
                                                  className="position-absolute hand"
                                                  width={70}
                                                  onClick={() => {
                                                    playVid(
                                                      companyCulture[index]
                                                        ?.link
                                                    );
                                                  }}
                                                  style={{
                                                    left: "50%",
                                                    top: "50%",
                                                    transform:
                                                      "translate(-50%, -50%)",
                                                  }}
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            <img
                                              src={companyCulture[index]?.link}
                                              className="img-fluid rounded-3 h-100 hand"
                                              onClick={() => {
                                                setImgSize("md");
                                                setCurrentImg(
                                                  companyCulture[index]?.link
                                                );
                                                setImgOpen(true);
                                              }}
                                              style={{
                                                objectFit: "cover",
                                                backgroundPosition:
                                                  "center center",
                                              }}
                                              alt=""
                                            />
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {employerProfiles?.employeeReview?.length > 0 &&
                          employerProfiles?.employeeReview?.filter(
                            (e) => e.featured === true
                          ).length > 0 ? (
                            <>
                              <div className="col-12  mt-5">
                                <h5 className="text-primary">What it's like</h5>
                                <h1 className="mb-5">Working with us</h1>

                                <Carousel cols={3} rows={1} gap={10} loop>
                                  {employerProfiles?.employeeReview?.map(
                                    (review) => {
                                      if (!!review.featured) {
                                        return (
                                          <Carousel.Item>
                                            {!!review.featured && (
                                              <>
                                                <div
                                                  className="h-100"
                                                  style={{
                                                    borderRadius: 15,
                                                    background: "#F6F9FB",
                                                  }}
                                                >
                                                  <div className="card-body h-100 d-flex justify-content-between flex-column p-5">
                                                    <div>
                                                      <span
                                                        style={{
                                                          fontSize: 14,
                                                        }}
                                                      >
                                                        {review.review}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <p
                                                        className="text-end"
                                                        style={{
                                                          color: "#002E5D",
                                                          fontSize: 20,
                                                        }}
                                                      >
                                                        <strong>
                                                          {review.name}
                                                        </strong>
                                                      </p>
                                                      <p
                                                        className="text-end text-info"
                                                        style={{
                                                          color: "#009CDE",
                                                          fontSize: 18,
                                                        }}
                                                      >
                                                        {review.jobTitle}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </Carousel.Item>
                                        );
                                      }
                                    }
                                  )}
                                </Carousel>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <Box
                            className={`${app_classes.margin.y5} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.flexBox.align_items_center}`}
                          >
                            <Box className={`${app_classes.text.center}`}>
                              <img
                                src={EmptyImg}
                                width={350}
                                className={`${app_classes.margin.y4}`}
                              />
                              <JobluTypography.H4
                                className={`${app_classes.text.muted}`}
                              >
                                Nothing to see here at the moment
                              </JobluTypography.H4>
                            </Box>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                  {/* JOBS TAB */}
                  {activeTab == "jobs" && (
                    <>
                      <div className="mb-5 d-flex flex-column">
                        <div className="mb-3">
                          <h4 className="text-primary mb-0">Featured Jobs</h4>
                        </div>
                        <div>
                          <Grid>
                            <Grid item xs={12} md={5}>
                            <TextField
                              className="w-100"
                              type="text"
                              id="search"
                              name="search"
                              placeholder="Search Jobs"
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                          </Grid>
                          </Grid>
                        </div>
                      </div>
                      {/* <LargeBanner/> */}
                      <div style={{marginBottom: 30}}></div>
                      {employerJobs?.length > 0 ? (
                        <>
                          {" "}
                          <Grid container spacing={2}>
                            {employerJobs?.map((job) => {
                              return (
                                <>
                                  {!jobsLoading ? (
                                    <>
                                      <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <GridCard
                                          job={job}
                                          publicJob={true}
                                          isFromProfile={true}
                                        />
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          lg={3}
                                          container
                                          spacing={0}
                                        >
                                          <GridLoader
                                            count={1}
                                            width={"100%"}
                                          />
                                        </Grid>
                                      </>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </Grid>
                          {Math.ceil(totalCount / limit) > 1 && (
                            <>
                              <Box
                                className={`${app_classes.text.center} ${app_classes.margin.t4}`}
                              >
                                <Box
                                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center} ${app_classes.flexBox.justify_content_center}`}
                                >
                                  {offset > 1 && (
                                    <>
                                      <Box
                                        className={`${app_classes.margin.r1}`}
                                      >
                                        <ArrowBackIcon
                                          className={`${app_classes.cursor.pointer}`}
                                          onClick={() => {
                                            prevPage();
                                          }}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  <JobluTypography.H6
                                    semiBold
                                    primary
                                    className={`${app_classes.padding.p2}`}
                                  >
                                    {offset}
                                  </JobluTypography.H6>
                                  <Box className={`${app_classes.margin.x1}`}>
                                    <JobluTypography.H6>of</JobluTypography.H6>
                                  </Box>
                                  <JobluTypography.H6
                                    semiBold
                                    primary
                                    className={`${app_classes.padding.p2}`}
                                  >
                                    {Math.ceil(totalCount / limit)}
                                  </JobluTypography.H6>
                                  {offset < Math.ceil(totalCount / limit) && (
                                    <>
                                      <Box
                                        className={`${app_classes.margin.l1}`}
                                      >
                                        <ArrowForwardIcon
                                          className={`${app_classes.cursor.pointer}`}
                                          onClick={() => {
                                            nextPage();
                                          }}
                                        />
                                      </Box>
                                    </>
                                  )}
                                </Box>
                              </Box>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Box
                            className={`${app_classes.margin.y5} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.flexBox.align_items_center}`}
                          >
                            <Box className={`${app_classes.text.center}`}>
                              <img
                                src={EmptyImg}
                                width={350}
                                className={`${app_classes.margin.y4}`}
                              />
                              <JobluTypography.H4
                                className={`${app_classes.text.muted}`}
                              >
                                Nothing to see here at the moment
                              </JobluTypography.H4>
                            </Box>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <JobluVideoPlayer
            src={currentVideoLink}
            play={playVideo}
            setPlayVideo={setPlayVideo}
            vidHeight={700}
            mWidth={width}
            culture={true}
          />
        </>
      );
    } else {
      return (
        <>
          <MetaTag />
          <NotFound />
        </>
      );
    }
  }
};

export default CompanyShowcasePublic;
