import { TourProvider, useTour } from '@reactour/tour'
import disableScroll from 'disable-scroll'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import helpIcon from '../../assets//joblu-2.0/img/new-branding/help-icon.svg'
import filterIcon from '../../assets/img/filterIcon.svg'
import multiplefair from '../../assets/multipleJobfairBadge.png'
import appActions from '../../redux/app/actions'
import jobfairActions from '../../redux/job-fair/jobfairActions'
import userActions from '../../redux/user/actions'
import {
    jobApplicantRequest,
    jobFairRequest,
    jobMatchRequest,
    jobsRequest,
    userRequest
} from '../../service/requests'
import { errorSwipeActions } from '../../utils/constants'
import useWindowDimensions from '../../utils/hooks'
import { JobFairBanner } from '../job-fair/Banners'
import { LeaveModal } from '../job-fair/JobFairModals'
import { SubscriptionPopup } from '../likes/components/ApplicantItem'
import ExpandedExplore from './components/ExpandedExplore'
import ExploreModal from './components/ExploreModal'
import ShrinkedExplore from './components/ShrinkedExplore'
import WelcomeTourModal from './components/WelcomeTourModal'
import './slide.css'
import tourSteps, { doArrow } from './tour-steps'
import ExploreV2 from './v2/Explorev2'

export function TourButtons({ isLast }) {
    const { setIsOpen, setCurrentStep, currentStep, enableBody, steps } =
        useTour()
    const handleNext = () => {
        setCurrentStep(currentStep + 1)
        disableScroll.off()
        setTimeout(() => {
            if (!isLast) {
                disableScroll.on()
            }
        }, 1000)
        if (currentStep === steps.length - 1) {
            enableBody()
            setIsOpen(false)
        }
    }
    const handlePrev = () => {
        setCurrentStep(currentStep - 1)
        disableScroll.off()
        setTimeout(() => {
            if (!isLast) disableScroll.on()
        }, 1000)
    }

    return (
        <>
            <div className="d-flex justify-content-between mt-3">
                <div className="w-100 d-flex">
                    <div className="d-flex my-auto">
                        {Array.from(Array(tourSteps.length)).map(
                            (row, index) => {
                                return (
                                    <div
                                        className="border"
                                        style={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            marginRight: 2,
                                            background:
                                                index <= currentStep
                                                    ? '#000'
                                                    : '#f2f2f2',
                                        }}
                                    >
                                        {' '}
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
                <div className="d-flex ">
                    {currentStep !== 0 && (
                        <button
                            className="btn me-1"
                            onClick={() => handlePrev()}
                        >
                            Prev
                        </button>
                    )}
                    <button
                        className="btn btn-primary"
                        onClick={() => handleNext()}
                    >
                        {isLast ? 'Finish' : 'Next'}
                    </button>
                </div>
            </div>
        </>
    )
}
export const defaultActions = {
    right: 'like',
    left: 'not_interested',
    top: 'superLike',
}

export const SLIDE_CLASSES = {
    SLIDE_LEFT: 'slide-out-left',
    SLIDE_RIGHT: 'slide-out-right',
    SLIDE_TOP: 'slide-out-top',
}

function ExploreViews(props) {
    const {
        expanded,
        setExpanded,
        appState,
        tourOpen,
        setShowTutorial,
        filters,
        setFilters,
        handleFilter,
        setDoNotShowTips,
        User,
        isFirstTimeUser,
    } = props
    const tour = useTour()
    const { setIsOpen, isOpen, currentStep, steps } = tour
    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)

    useEffect(() => {
        setIsOpen(tourOpen)
    }, [tourOpen, appState.showTutorial])

    const { width } = useWindowDimensions()

    const toggleTutorial = () => {
        setShowTutorial(true)
        setExpanded(false)
    }
    useEffect(() => {
        if (currentStep === steps.length) {
            if (isFirstTimeUser) {
                setDoNotShowTips(true)
                //update doNotShowTips from db
                let payload = {
                    userId: User?.user?.userId,
                    data: {
                        doNotShowTips: true,
                    },
                }
                try {
                    userRequest.updateUserStatus(payload).then((res) => {
                        //updated sucessfully
                    })
                } catch (err) {
                    console.log(err)
                }
            }
            setTimeout(() => {
                window.location.reload()
            }, 500)
        }
    }, [currentStep])
    return (
        <>
            {/* FOR MOBILE */}
            <div
                className={`d-flex  my-3 d-md-none align-items-center px-4 ${
                    appState.hideTriggerTutorial
                        ? 'justify-content-end'
                        : 'justify-content-between'
                }`}
            >
                {!appState.hideTriggerTutorial && (
                    <div>
                        <img
                            src={helpIcon}
                            style={{ width: 35, height: 35 }}
                            alt=""
                            onClick={() => {
                                toggleTutorial()
                            }}
                            className={width < 640 && 'explore-tour-step-3'}
                        />
                    </div>
                )}
                <div>
                    <img
                        src={filterIcon}
                        alt=""
                        onClick={() => {
                            setModalOpen(true)
                            setModalVariant('exploreFilter')
                        }}
                    />
                </div>
            </div>
            {!expanded ? (
                <ShrinkedExplore {...props} />
            ) : (
                <ExpandedExplore {...props} />
            )}
            <ExploreModal
                showModal={modalOpen}
                variant={modalVariant}
                setModalVariant={setModalVariant}
                setShowModal={setModalOpen}
                setFilters={setFilters}
                filters={filters}
                handleFilter={handleFilter}
            />
        </>
    )
}

function TourButton({ setTourOpen, setIsNoJobs }) {
    const tour = useTour()
    return (
        <p
            role="button"
            className="text-center text-decoration-underline open-sans-body w-100"
            onClick={() => {
                tour.setIsOpen(true)
                setIsNoJobs(false)
                setTourOpen(true)
            }}
        >
            Explore Tutorials
        </p>
    )
}
function Explore(props) {
    const {
        User,
        AppState,
        setShowTutorial,
        setDoNotShowTips,
        setHideTutorial,
        JobFairs,
        setSignedInJobFair,
        setShowBanner,
    } = props

    const [jobSeekers, setJobSeekers] = useState([])

    const [expanded, setExpanded] = useState(false)
    const [tourOpen, setTourOpen] = useState(false)
    const [noJobs, setIsNoJobs] = useState(false)
    const [jobsList, setJobsList] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)
    const [modalHeaderTitle, setModalHeaderTitle] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const screen = document.getElementById('root')
    const location = useLocation()
    const [isFirstTimeUser, setFirstTimeUser] = useState(false)

    const [jobTitle, setJobTitle] = useState(null)
    const [matchImages, setMatchImages] = useState(null)
    const [applicantName, setApplicantName] = useState(null)
    const { isMobile } = useWindowDimensions()
    const [jobTitleQuery, setJobTitleQuery] = useState(null)

    const [jobfairData, setJobFairData] = useState(null)
    const [showbackToDashboard, setShowBackToDashboad] = useState(false)

    const [currPage, setCurrPage] = useState(null)

    useEffect(() => {
        screen?.classList?.add('overflow-hidden')
    }, [])

    useEffect(() => {
        if (!!AppState.showTutorial) {
            setExpanded(false)
        }
    }, [AppState.showTutorial])

    useEffect(() => {
        if (User?.login_response?.doNotShowTips === false) {
            setFirstTimeUser(true)
        }
    }, [User])

    const { jobfair_id } = useParams()

    useEffect(() => {
        if (jobfair_id && !!JobFairs.jobfairs) {
            const jf_data = _.find(JobFairs?.jobfairs.data, { _id: jobfair_id })
            console.log(jf_data)
            setJobFairData(jf_data)
        }
    }, [jobfair_id, JobFairs])

    useEffect(() => {
        if (!!jobfairData && !!Object.keys(jobfairData).length) getJobSeekers()
    }, [jobfairData])

    useEffect(() => {
        if (isFirstTimeUser) {
            setTourOpen(true)
        }
    }, [isFirstTimeUser])

    const [page, setPage] = useState(1)

    const [fetching, setIsFetching] = useState(false)

    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(20)
    const [totalData, setTotalData] = useState(null)
    const [offset, setOffset] = useState('1')
    const [limit, setLimit] = useState('100')

    const { currency } = AppState

    const [currentList, setCurrentList] = useState([])
    // Get current posts

    // const currentList = jobSeekers.slice(indexOfFirstPost, indexOfLastPost)

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    // useEffect(() => {
    //     console.log('current page', currentPage)
    //     let group = _.chunk(_.uniqBy(jobSeekers, 'id'), postsPerPage)

    //     setCurrentList(group)
    // }, [currentPage])

    useEffect(() => {
        let group = _.chunk(_.uniqBy(jobSeekers, 'id'), postsPerPage)
        setCurrentList(group)
    }, [jobSeekers])

    useEffect(() => {
        console.log('currentlist', currentList)
    }, [currentList])

    const [salaryRange, setSalaryRange] = useState(null)
    const [salaryMin, setSalaryMin] = useState(null)
    const [salaryMax, setSalaryMax] = useState(null)
    const [salaryCurrency, setSalaryCurrency] = useState(null)

    const [yearsOfExp, setYearsOfExp] = useState(null)

    const [filters, setFilters] = useState({
        jobId: null,
        jobFunction: null,
        location: null,
        jobTitle: null,
        offset: null,
        limit: null,
        salaryRange,
        salaryCurrency,
        yearsOfExp,
    })

    useEffect(() => {
        if (currency == 'PHP') {
            setSalaryCurrency('PHP')
        } else if (currency == 'SGD') {
            setSalaryCurrency('SGD')
        } else if (currency == 'USD') {
            setSalaryCurrency('USD')
        } else {
            setSalaryCurrency('PHP')
        }
    }, [])
    
    useEffect(() => {
        if (currency == 'PHP') {
            setSalaryCurrency('PHP')
        } else if (currency == 'SGD') {
            setSalaryCurrency('SGD')
        } else if (currency == 'USD') {
            setSalaryCurrency('USD')
        } else {
            setSalaryCurrency('PHP')
        }
    }, [currency])

    useEffect(() => {
        const jobId = new URLSearchParams(location.search).get('job')
        if (jobId) {
            setFilters({
                ...filters,
                jobId,
            })
        }
    }, [location])

    const navigate = useNavigate()

    useEffect(() => {
        if (jobSeekers?.length === 0) {
            setHideTutorial(true)
        } else {
            setHideTutorial(false)
        }
    }, [jobSeekers])

    const getJobSeekers = (payload, changeJobOnly = null) => {
        if (!!jobfairData && Object.keys(jobfairData).length > 0) {
            try {
                jobFairRequest
                    .getJobSeekers(jobfairData?._id, payload)
                    .then((res) => {
                        console.log('jobfair applicants => ', res)
                        setTotalData(res.data.totalCount)
                        let data = res?.data?.data || []
                        if (changeJobOnly) {
                            const lastJS = jobSeekers[jobSeekers.length - 1]
                            data = _.filter(
                                data,
                                (obj) => obj?._id !== lastJS?._id
                            )
                            if (!!lastJS) data = [...data, lastJS]
                        } else {
                            //do nothing
                        }
                        data = _.uniqBy(data, 'userId')
                        if (!changeJobOnly) {
                            setPage(1)
                            setJobSeekers(data)
                        }
                        setIsLoading(false)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            } catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        } else {
            jobApplicantRequest
                .getJobSeekers(payload)
                .then((resp) => {
                    console.log('job applicants => ', resp.data)
                    setTotalData(resp.data.totalCount)
                    let data = resp?.data?.data || []
                    if (changeJobOnly) {
                        const lastJS = jobSeekers[jobSeekers.length - 1]
                        data = _.filter(data, (obj) => obj?._id !== lastJS?._id)
                        if (!!lastJS) data = [...data, lastJS]
                    } else {
                        //do nothing
                    }
                    data = _.uniqBy(data, 'userId')
                    if (!changeJobOnly) {
                        setPage(1)
                        setJobSeekers(data)
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                })
        }
    }

    const loadMore = () => {
        setCurrentPage(1)
        let filter = { ...filters }
        let page = currPage ? currPage + 1 : offset
        page = parseInt(page) + 1
        setCurrPage(currPage ? currPage + 1 : 1)
        console.log(page)
        filter.offset = page.toString()
        filter.limit = '100'
        delete filter.jobId
        console.log(currentList)
        getJobSeekers(filter, false)
    }

    const onSwipeFulfilled = (data, index, dir, new_class, type, itemIndex) => {
        //perform api call here
        const jobId = filters.jobId
        const card = document.getElementById(`card-${type}-${index}`)
        console.log('catd', card, 'index', itemIndex)
        if (!!jobsList.length === false) {
            if (dir === defaultActions.right || dir === defaultActions.top) {
                setModalHeaderTitle('')
                setModalVariant('noJobPosts')
            } else {
                card.classList.add(new_class)
                let seekers = currentList
                seekers[currentPage - 1].splice(itemIndex, 1)

                setTimeout(() => {
                    card.classList.add('d-none')
                    setCurrentList([...seekers])
                }, 500)
            }
        } else {
            if (!!jobId && data !== 'advertisement') {
                jobMatchRequest
                    .employerSwipe({
                        jobId: filters.jobId,
                        applicantId: data.user._id,
                        action: dir,
                    })
                    .then((res) => {
                        console.log(res.data)
                        if (
                            res.data.message ===
                            errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
                        ) {
                            setModalVariant('outOfLikes')
                            setModalOpen(true)
                            setModalHeaderTitle('')
                            // setSwipeLimitTitle('You have run out of likes today!')
                            // setSwipeLimitText('Go back tomorrow to start liking again.')
                            // setShowSwipeLimit(true)
                        } else if (
                            res.data.message ===
                            errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
                        ) {
                            setModalVariant('outOfMonthlyLikes')
                            setModalOpen(true)
                            setModalHeaderTitle('')
                            // alert(res.data.message)
                            // setSwipeLimitTitle(
                            //     'You have run out of likes for this month!'
                            // )
                            // setSwipeLimitText('You can like again next month.')
                            // setShowSwipeLimit(true)
                        } else if (
                            res.data.message ===
                            errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
                        ) {
                            setModalVariant('outOfSuperLikes')
                            setModalOpen(true)
                            setModalHeaderTitle('')
                            // alert(res.data.message)
                            // setSwipeLimitTitle('You have run out of superlikes!')
                            // setSwipeLimitText('You can superlike again next month.')
                            // setShowSwipeLimit(true)
                        } else if (
                            res.data.message ===
                            errorSwipeActions.LIKE_NOT_ALLOWED
                        ) {
                            setModalVariant('upgradeAccount')
                            setModalOpen(true)
                            setModalHeaderTitle('')
                            // alert(res.data.message)
                            // setSwipeLimitTitle(
                            //     'Please upgrade account to start swiping!'
                            // )
                            // setSwipeLimitText(
                            //     'You are currently using a FREE account. Upgrade now to like this applicant.'
                            // )
                            // setShowSwipeLimit(true)
                        } else {
                            card.classList.add(new_class)
                            let seekers = currentList
                            seekers[currentPage - 1].splice(itemIndex, 1)

                            setTimeout(() => {
                                card.classList.add('d-none')
                                setCurrentList([...seekers])
                            }, 500)
                        }

                        if (res.data.matchStatus === 'match') {
                            // alert('MATCHED!!')
                            console.log(res.data)
                            const images = {
                                employer: res.data.profileUrl_employer,
                                applicant: res.data.profileUrl_applicant,
                            }

                            setMatchImages(images)
                            setModalVariant('matchModal')
                            setModalOpen(true)
                            setApplicantName(
                                data.firstName + ' ' + data.lastName
                            )
                            setJobTitle(filters.jobTitle)
                        }
                        // navigation.goBack()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                if (
                    dir === defaultActions.right ||
                    dir === defaultActions.top
                ) {
                    setModalVariant('noJobsSelected')
                    setModalHeaderTitle('Select Job Post')
                } else {
                    console.log('normal swiping')
                    card.classList.add(new_class)
                    let seekers = currentList
                    seekers[currentPage - 1].splice(itemIndex, 1)

                    setTimeout(() => {
                        card.classList.add('d-none')
                        setCurrentList([...seekers])
                    }, 500)
                }
            }
        }
    }

    useEffect(() => {
        if (modalVariant !== null) {
            setModalOpen(true)
        }
    }, [modalVariant])

    const disableBody = () => disableScroll.on()

    const enableBody = () => {
        setShowTutorial(false)
        setTourOpen(false)
        disableScroll.off()
    }

    const addCommas = (num) =>
        num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, '')

    const removeComma = (num) => {
        let copy = num?.toString()
        let num1
        if (copy?.indexOf(',') != -1) {
            num1 = parseFloat(num?.replace(/,/g, ''))
            return num1
        } else {
            return parseFloat(copy)
        }
    }

    const handleSalaryMin = (event) =>
        setSalaryMin(addCommas(removeNonNumeric(event.target.value)))

    const handleSalaryMax = (event) =>
        setSalaryMax(addCommas(removeNonNumeric(event.target.value)))

    const handleFilter = (event, changeJobOnly) => {
        let sMin = removeComma(salaryMin)
        let sMax = removeComma(salaryMax)

        setPage(1)
        setCurrentPage(1)
        setIsLoading(true)
        let filter = { ...filters }
        filter.jobTitle = jobTitleQuery ? jobTitleQuery : null
        filter.employmentType = workPref.length > 0 ? workPref.join(',') : null
        filter.workSetup = workSetup.length > 0 ? workSetup.join(',') : null
        filter.yearsOfExp = yearsOfExp ? yearsOfExp : null
        if (salaryMin && salaryMax && salaryCurrency) {
            filter.salaryRange =
               sMin?.toString() + '-' + sMax?.toString()
            filter.salaryCurrency = salaryCurrency
        }
        let payload = {
            ...filters,
        }
        if (!filter.jobId) {
            delete payload.jobId
            delete filter.jobId
        }
        console.log(filter)
        getJobSeekers(filter, changeJobOnly)
    }

    useEffect(() => {
        try {
            jobsRequest.getJobsForLists().then((res) => {
                setJobsList(res?.data?.jobs || [])
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {
        if (filters.jobId) handleFilter(null, true)
    }, [filters.jobId])

    useEffect(() => {
        let payload = {
            limit: '100',
            offset,
        }
        if (!jobfair_id) getJobSeekers(payload)
    }, [])

    const [workPref, setWorkPref] = useState([])
    const [workSetup, setWorkSetup] = useState([])

    const handleSetWorkPref = (e) => {
        var workPrefCopy = [...workPref]
        if (e.target.checked) {
            workPrefCopy = [...workPref, e.target.value]
        } else {
            workPrefCopy.splice(workPref.indexOf(e.target.value), 1)
        }

        setWorkPref(workPrefCopy)
    }

    const handleWorkSetup = (e) => {
        var workSetupCopy = [...workSetup]
        if (e.target.checked) {
            workSetupCopy = [...workSetup, e.target.value]
        } else {
            workSetupCopy.splice(workSetup.indexOf(e.target.value), 1)
        }
        setWorkSetup(workSetupCopy)
    }

    const handleYearsOfExp = (e) => {
        let copy = filters
    }

    return (
        <>
            <div className="row d-flex justify-content-center mx-0 position-relative">
                {/* SINGLE EVENTS */}
                {!jobfairData && !isMobile && (
                    <JobFairBanner
                        jobfairs={JobFairs?.jobfairs}
                        show={
                            !!JobFairs?.jobfairs?.totalCount > 0 &&
                            !!JobFairs.showBanner
                        }
                        setShowBanner={setShowBanner}
                    />
                )}
                {/* MULTIPLE EVENTS */}

                <div className="row d-flex justify-content-center mx-0 px-0">
                    <TourProvider
                        steps={_.map(tourSteps, (obj, index) => {
                            return {
                                ...obj,
                                position: isMobile && index !== 3 && 'center',
                            }
                        })}
                        afterOpen={disableBody}
                        beforeClose={enableBody}
                        showBadge={false}
                        showPrevNextButtons={false}
                        showNavigation={false}
                        position="left"
                        showCloseButton={true}
                        onClickClose={enableBody}
                        disableInteraction={true}
                        enableBody={enableBody}
                        onClickMask={() => {}}
                        className=""
                        styles={
                            !isMobile
                                ? {
                                      popover: (base, state) => {
                                          return {
                                              ...base,
                                              borderRadius: 10,
                                              ...doArrow(
                                                  state.position,
                                                  state.verticalAlign,
                                                  state.horizontalAlign
                                              ),
                                          }
                                      },
                                  }
                                : {
                                      popover: (base, state) => {
                                          return { ...base, borderRadius: 10 }
                                      },
                                  }
                        }
                    >
                        {jobfairData && (
                            <div
                                className="col-12 row shadow-sm d-flex justify-content-center px-0 py-3 mx-0"
                                style={{
                                    background: jobfairData
                                        ? jobfairData?.brandColor
                                        : null,
                                }}
                            >
                                <div className="col-12 col-md-9 row m-0">
                                    <div className="row p-0 m-0 p-0">
                                        {jobfairData && (
                                            <div
                                                className="col-12 col-md-3 text-light d-flex"
                                                role="button"
                                                onClick={() => {
                                                    setShowBackToDashboad(
                                                        !showbackToDashboard
                                                    )
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-arrow-left my-auto me-2 "
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                                    />
                                                </svg>
                                                <p className="text-light my-auto">
                                                    {' '}
                                                    Back to Dashboard
                                                </p>
                                            </div>
                                        )}

                                        {/* <div className="col-md-4 col-sm-12 mb-2 mb-md-0 ps-0">
                                    <div className="" id="explore-tour-step-2">
                                        <SelectJobPost
                                            onChange={(value, item) => {
                                                console.log(value, item)
                                                setFilters({
                                                    ...filters,
                                                    jobId: item?.value,
                                                    jobTitle: item?.name,
                                                })
                                            }}
                                            value={filters?.jobId}
                                            placeholder="Select job post to view applicants"
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${
                                        jobfairData
                                            ? 'col-md-5'
                                            : 'col-md-8 col-sm-12 '
                                    } d-flex justify-content-end p-0`}
                                >
                                    <CreateJobButton />
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <ExploreViews
                        jobSeekers={jobSeekers}
                        setJobSeekers={setJobSeekers}
                        setExpanded={setExpanded}
                        expanded={expanded}
                        onSwipeFulfilled={onSwipeFulfilled}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setFilters={setFilters}
                        filters={filters}
                        handleFilter={handleFilter}
                        appState={AppState}
                        tourOpen={tourOpen}
                        setShowTutorial={setShowTutorial}
                        getJobSeekers={getJobSeekers}
                        isFirstTimeUser={isFirstTimeUser}
                        User={User}
                        setDoNotShowTips={setDoNotShowTips}
                    /> */}
                        <ExploreV2
                            currency={currency}
                            setSalaryCurrency={setSalaryCurrency}
                            setSalaryMin={setSalaryMin}
                            setSalaryMax={setSalaryMax}
                            setYearsOfExp={setYearsOfExp}
                            handleWorkSetup={handleWorkSetup}
                            handleSetWorkPref={handleSetWorkPref}
                            workPref={workPref}
                            setJobTitleQuery={setJobTitleQuery}
                            jobSeekers={jobSeekers}
                            setJobSeekers={setJobSeekers}
                            setExpanded={setExpanded}
                            expanded={expanded}
                            onSwipeFulfilled={onSwipeFulfilled}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setFilters={setFilters}
                            filters={filters}
                            handleFilter={handleFilter}
                            appState={AppState}
                            tourOpen={tourOpen}
                            setShowTutorial={setShowTutorial}
                            getJobSeekers={getJobSeekers}
                            isFirstTimeUser={isFirstTimeUser}
                            User={User}
                            setDoNotShowTips={setDoNotShowTips}
                            postsPerPage={postsPerPage}
                            totalData={jobSeekers.length}
                            paginate={paginate}
                            setPage={setPage}
                            currentList={currentList}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            loadMore={loadMore}
                            handleSalaryMin={handleSalaryMin}
                            handleSalaryMax={handleSalaryMax}
                            salaryMin={salaryMin}
                            salaryMax={salaryMax}
                        />

                        {!!noJobs && !isLoading && (
                            <div className="first-job-container">
                                <div className="lets-make-your-first-job d-flex">
                                    <div className="m-auto d-flex flex-column">
                                        <h2 className="poppins text-center">
                                            Let’s make your first <br />
                                            job post
                                        </h2>
                                        <button
                                            onClick={() =>
                                                navigate('/create-job')
                                            }
                                            className="btn btn-primary mx-auto mb-2"
                                        >
                                            + Post a job
                                        </button>
                                        <TourButton
                                            setTourOpen={setTourOpen}
                                            setIsNoJobs={setIsNoJobs}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </TourProvider>
                </div>

                <ExploreModal
                    showModal={modalOpen && modalVariant !== 'upgradeAccount'}
                    variant={modalVariant}
                    setModalVariant={setModalVariant}
                    setShowModal={setModalOpen}
                    headerTitle={modalHeaderTitle}
                    filters={filters}
                    setFilters={setFilters}
                    handleFilter={handleFilter}
                />

                {modalVariant === 'upgradeAccount' && (
                    <SubscriptionPopup
                        open={modalOpen}
                        setOpen={() => setModalVariant(false)}
                    />
                )}
                <WelcomeTourModal
                    show={AppState.showTutorial}
                    setTourOpen={setTourOpen}
                    tourOpen={tourOpen}
                />
                <LeaveModal
                    show={showbackToDashboard}
                    setSignedInJobFair={setSignedInJobFair}
                />
                {isMobile &&
                    !!JobFairs.jobfairs?.data &&
                    !!JobFairs.showBanner && (
                        <div className="floating-action-button">
                            <img
                                alt=""
                                style={{ width: '100%' }}
                                src={
                                    JobFairs.jobfairs.totalCount > 1
                                        ? multiplefair
                                        : JobFairs.jobfairs?.data[0]?.logo
                                }
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    console.log(JobFairs)
                                    JobFairs.jobfairs.totalCount !== 0 &&
                                    JobFairs.jobfairs?.totalCount === 1
                                        ? navigate(
                                              `/job-fair/register/${JobFairs.jobfairs?.data[0]?._id}`
                                          )
                                        : navigate('/job-fair/events')
                                }}
                            />
                            <div
                                className="position-absolute top-0"
                                style={{ right: 1 }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="white"
                                    className="my-auto bi bi-x ms-5 bg-dark"
                                    viewBox="0 0 16 16"
                                    role="button"
                                    onClick={() => {
                                        setShowBanner(false)
                                    }}
                                    style={{ borderRadius: '50%' }}
                                >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                    )}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
        JobFairs: state.JobFairs,
    }
}

const mapDispatchToProps = {
    setShowTutorial: appActions.setShowTutorial,
    setDoNotShowTips: userActions.setDoNotShowTips,
    setHideTutorial: appActions.setHideTutorial,
    setSignedInJobFair: jobfairActions.setSignIn,
    setShowBanner: jobfairActions.setShowBanner,
}
export default connect(mapStateToProps, mapDispatchToProps)(Explore)
