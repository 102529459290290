import { Box, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotificationItem from "../../components/notification";
import PageTitle from "../../components/page-title";
import { app_styles } from "../../general.styles";
import notificationActions from "../../redux/notifications/action";
import userAction from "../../redux/user/actions";
import { userRequest } from "../../service/requests";
import { notification_styles } from "./style";

//todo: @ced fix the styling

const Notifications = (props) => {
  const app_classes = app_styles(props);
  const notification_classes = notification_styles(props);
  const { User, AppState, getNotifications, Notifications } = props;
  const { notificationsList, isFetching, isFetched } = Notifications;
  const [isGettingMoredata, setIsGettingMoreData] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getNotifications();
  }, []);

  const readAll = () => {
    userRequest
      .readAllNotifications()
      .then((res) => {
        getNotifications();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToNotification = (data) => {
    userRequest
      .readNotification({
        notificationId: data._id,
        isRead: true,
      })
      .then((response) => {
        getNotifications();
      })
      .catch((err) => {
        console.log(err);
      });

    if (data.type === "jobprocess") {
      //go to jobs page
      navigate("/jobs", {
        state: { data },
      });
    } else if (data.type === "match") {
      // jobsRequest.getSpecificJob({jobId: data?.data?.jobId})
      // .then(response => {
      //   console.log(response?.data)
      // // go to job match or go to specific job view
      // })
      // .catch(e => {
      //     // go to job match or go to specific job view
      // })
      navigate("/jobs", {
        state: { index: 1, data },
      });
    } else if (data.type === "superlike" || data.type === "like") {
      // go to likes
      navigate("/jobs", {
        state: { index: 1, data },
      });
    } else if (data.type === "recommendation") {
      navigate("/recommendations");
    }
  };

  const getMoreNotifications = () => {
    if (!isGettingMoredata) {
      // console.log(notificationsList.totalCount)
      // console.log(notificationsList.data?.length)
      const totalCount = notificationsList?.totalCount;
      const currentCount = notificationsList?.data?.length;
      const currentPage = notificationsList?.page;
      // console.log('PAGE:::',currentPage)
      const limit = notificationsList?.limit;
      if (totalCount > currentCount && currentCount < 100) {
        setIsGettingMoreData(true);
        getNotifications({ offset: currentPage + 1 });
        setTimeout(() => {
          setIsGettingMoreData(false);
        }, 1000);
      }
    }
  };

  return (
    <>
      <PageTitle pageTitle="Notifications" />
      <Box>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            {!!notificationsList?.data?.length && (
              <div className="col-12" onClick={() => readAll()}>
                <div className="d-flex justify-content-end">
                  <p role="button" className="text-end m-0">
                    Mark All As Read
                  </p>
                </div>
              </div>
            )}
            {notificationsList?.data?.map((item) => {
              return (
                <NotificationItem
                  data={item}
                  action={() => goToNotification(item)}
                />
              );
            })}
            {notificationsList?.data?.length < notificationsList?.totalCount &&
              notificationsList?.data?.length < 100 && (
                <>
                  {!!notificationsList?.data?.length && (
                    <div className="text-center">
                      <button
                        className="btn btn-primary my-3 text-center"
                        onClick={() => getMoreNotifications()}
                      >
                        {isGettingMoredata && (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Load More
                      </button>
                    </div>
                  )}
                </>
              )}
            {!notificationsList?.data?.length && (
              <div className="mt-5 ">
                <h6 role="button" className="text-center">
                  No notifications
                </h6>
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
    AppState: state.Generals,
    Notifications: state.Notifications,
  };
};

const mapDispatchToProps = {
  getNotifications: notificationActions.getNotifications,
  getSubscription: userAction.getActiveSubscription,
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
