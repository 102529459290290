import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { computeProfileCompletion } from '../../utils/helpers'

function ProfileCompletionBanner(props) {
    const { User } = props
    const [profilePercentage, setProfilePercentage] = useState(40)
    const location = useLocation()

    useEffect(() => {
        if (Object.keys(User.user || {}).length > 0) {
            const percent = computeProfileCompletion(User?.user)
            setProfilePercentage(percent)
        } else {
            setProfilePercentage(0)
        }
    }, [User?.user])

    if (
        (!User.isSignedIn && !User.isSigningin) ||
        location.pathname === '/edit-profile' ||
        Object.keys(User?.user || {}).length === 0
    ) {
        return null
    }

    return (
        <div
            className={`${
                profilePercentage === 100 && 'd-none'
            } w-100 bg-primary p-2`}
        >
            <p className="text-center text-light">
                Your company profile is {profilePercentage}% complete!
                Candidates are more likely to apply to your jobs if your profile
                is complete.{' '}
                <a href="/edit-profile" className="text-light">
                    Click here
                </a>
                .
            </p>
        </div>
    )
}
const mapStateToProps = (state) => {
    return { User: state.User }
}
const mapDispatchToProps = {}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileCompletionBanner)
