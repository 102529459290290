import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { a11yProps, CustomTabPanel } from "../../components/tab";
import { app_styles } from "../../general.styles";
import jobMatchAction from "../../redux/job-matches/action";
import LikedMe from "./components/LikedMe";
import MyLikedJobs from "./components/MyLikedJobs";
import { likes_styles } from "./styles";

const Likes = (props) => {
  const { User, JobMatches, getMyLikedCandidates, getLikedMyJobs } = props;

  const app_classes = app_styles(props);
  const likes_classes = likes_styles(props);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (e, val) => {
    setSelectedTab(val);
  };

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} sm={11}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          centered
          indicatorColor="primary"
          className={likes_classes.tabs}
        >
          <Tab
            className={likes_classes.tab}
            label="My Liked Jobs"
            {...a11yProps(0)}
          />
          <Tab
            className={likes_classes.tab}
            label="Liked Me "
            {...a11yProps(1)}
          />
        </Tabs>
        <Box>
          <CustomTabPanel
            value={selectedTab}
            index={0}
            className={app_classes.width.w100}
          >
            <MyLikedJobs />
          </CustomTabPanel>
          <CustomTabPanel
            value={selectedTab}
            index={1}
            className={app_classes.width.w100}
          >
            <LikedMe />
          </CustomTabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
    JobMatches: state.JobMatches,
  };
};
const mapDispatchToProps = {
  getLikedMyJobs: jobMatchAction.getLikedMyJobs,
  getMyLikedCandidates: jobMatchAction.getMyLikedCandidates,
};
export default connect(mapStateToProps, mapDispatchToProps)(Likes);
