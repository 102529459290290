import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import JobCardList from "../../../components/card/job-card";
import JobluTypography from "../../../components/Typography";
import { app_styles } from "../../../general.styles";
import { applicantRequests } from "../../../service/requests";
const LikedMe = () => {
  const [jobs, setJobs] = useState([]);

  const getLikedMe = () => {
    applicantRequests.jobs.liked_me().then((res) => {
      console.log("likedme jobs", res.data);
      setJobs(res.data.data);
    });
  };
  useEffect(() => {
    getLikedMe();
  }, []);
  const app_classes = app_styles();
  return (
    <Grid container className={app_classes.width.w100}>
      <Grid item xs={12}>
        {jobs?.length > 0 ? (
          jobs?.map((job, index) => {
            return (
              <JobCardList
                job={{ ...job?.matchJob, employer: job?.matchEmployer }}
                showEmployerAction
              />
            );
          })
        ) : (
          <Grid>
            <JobluTypography.H6 style={{ textAlign: "center" }}>
              No jobs{" "}
            </JobluTypography.H6>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LikedMe;
