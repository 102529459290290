import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import JobluTypography from "../../components/Typography";
import Footer from "../../components/footer/Footer";
import GoogleReCaptcha from "../../components/google-captcha";
import PublicHeader from "../../components/header/PublicHeader";
import TextFieldV1 from "../../components/inputs/CustomTextField";
import ErrorMessage from "../../components/reusables/ErrorMessage";
import Loading from "../../components/reusables/Loading";
import SelectCountry2 from "../../components/reusables/SelectCountry2";
import SelectJobFunction from "../../components/reusables/SelectJobFunction";
import SuccessMessage from "../../components/reusables/SuccessMessage";
import CustomGooglePlaces from "../../components/select/google-places";
import { isSupported, messaging } from "../../firebase";
import { app_styles } from "../../general.styles";
import userAction from "../../redux/user/actions";
import { userRequest } from "../../service/requests";
import { creds } from "../../utils/constants";
import useWindowDimensions from "../../utils/hooks";
import { validateEmail } from "../../utils/validators";
import ExploreModal from "../explore/components/ExploreModal";
import { login_styles } from "./styles";
import "./styles.css";
import { trimPhone } from "../../utils/helpers";
function Login(props) {
  const { width } = useWindowDimensions();
  const { signIn, signUp, User, verifyGoogleToken, resetUserError } = props;
  const params = useParams();
  // const refId = params.refId;
  const _location = useLocation();
  const app_classes = app_styles(props);
  const login_classes = login_styles(props);
  const [deviceToken, setDeviceToken] = useState(null);
  const {
    isGoogleSignup,
    error,
    isSignedIn,
    isSigningIn,
    isRegistering,
    isRegistered,
  } = User;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [googleResponse, setGoogleResponse] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState(null);
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const source = new URLSearchParams(_location.search).get("source");
  const code = new URLSearchParams(_location.search).get("influencerCode");
  const refId = new URLSearchParams(_location.search).get("refId");
  const [showForm, setShowForm] = useState("login");
  const redirectedFrom = new URLSearchParams(_location?.search).get(
    "redirectedFrom"
  );

  //public jobs
  const [referrers, setReferrers] = useState({});

  useEffect(() => {
    try {
      isSupported &&
        Notification.requestPermission().then(() => {
          return messaging.getToken().then((token) => setDeviceToken(token));
        });
    } catch (e) {
      if (error instanceof TypeError) {
        if (isSupported)
          Notification.requestPermission(() => {
            messaging.getToken().then((token) => setDeviceToken(token));
          });
      } else {
        throw e;
      }
    }
  }, []);

  useEffect(() => {
    const _SIGNUP = "signup";
    const _LOGIN = "login";
    if (redirectedFrom === _SIGNUP || redirectedFrom === _LOGIN) {
      setShowForm("signup");
    } else {
      setShowForm("login");
    }
  }, [redirectedFrom]);

  const handleLogin = (e) => {
    const isValid = validateEmail(email);
    if (!!email) {
      if (!isValid) setErrors({ ...errors, email: "Email not valid!" });
    }

    if (!password) {
      setErrors({ ...errors, password: "Please enter your password" });
    } else {
      setErrors({ ...errors, password: null });
    }

    if (email.length === 0) {
      setErrors({ ...errors, email: "Please enter your email" });
    }

    if (!!password && isValid) {
      setErrors({ password: null, email: null });
      if (isSupported)
        if (Notification.permission !== "granted") {
          signIn({ email: email?.trim(), password, ...referrers });
        } else {
          messaging
            ? messaging.getToken().then((token) => {
                signIn({
                  email: email?.trim(),
                  password,
                  deviceToken: token,
                  ...referrers,
                });
              })
            : signIn({ email: email?.trim(), password, ...referrers });
        }
      else signIn({ email: email?.trim(), password, ...referrers });
    }
  };

  // useEffect(() => {
  //   const listener = (event) => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       event.preventDefault();
  //       if (verifiedCaptcha) handleLogin();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, [handleLogin]);

  const handleGoogleLogin = async (data) => {
    let payload;
    if (isSupported) {
      if (Notification.permission !== "granted") {
        payload = {
          email: data?.profileObj?.email,
          idToken: data?.tokenId,
          action: "login",
          role: "APPLICANT",
          platform: "web",
        };
      } else {
        await messaging.getToken().then((token) => {
          payload = {
            email: data?.profileObj?.email,
            idToken: data?.tokenId,
            action: "login",
            role: "APPLICANT",
            platform: "web",
          };
        });
      }
    } else {
      payload = {
        email: data?.profileObj?.email,
        idToken: data?.tokenId,
        action: "login",
        role: "APPLICANT",
        platform: "web",
      };
    }
    if (payload?.deviceToken) {
      setGoogleResponse({ ...data, token: payload?.deviceToken });
    } else {
      setGoogleResponse(data);
    }
    verifyGoogleToken(payload);
  };

  useEffect(() => {
    resetUserError();
    if (location?.state?.verify) {
      setIsVerified(true);
    }
  }, []);

  useEffect(() => {
    console.log("isGoogleSignup", isGoogleSignup);
    if (!!googleResponse && !isSignedIn && !isSigningIn && isGoogleSignup) {
      // setTimeout(() => {
      //   let url = "/signup";
      //   if (referrers?.refId) {
      //     url = url + "?refId=" + referrers?.refId;
      //   }
      //   if (referrers?.influencerCode) {
      //     url =
      //       url +
      //       `${referrers?.refId ? "&" : "?"}influencerCode=` +
      //       referrers?.influencerCode;
      //   }
      //   if (referrers?.source) {
      //     url =
      //       url +
      //       `${
      //         referrers?.refId || referrers?.influencerCode ? "&" : "?"
      //       }source=` +
      //       referrers?.source;
      //   }
      //   navigate(url, {
      //     state: { isFromLogin: true, refId: referrers?.refId, googleResponse },
      //   });
      // }, 1000);
      setShowForm("signup");
    }
  }, [googleResponse, isSignedIn, isSigningIn, isGoogleSignup]);

  useEffect(() => {
    const refId = new URLSearchParams(_location.search).get(
      "refId" || location?.state?.refId
    );
    const influencerCode = new URLSearchParams(_location.search).get(
      "influencerCode"
    );

    const source = new URLSearchParams(_location.search).get("source");

    if (refId || influencerCode || source) {
      if (influencerCode) {
        setAuthDetails({
          ...auth_details,
          influencerCode: influencerCode,
        });
      }
      setShowForm("signup");
    }

    setReferrers({ refId, source, influencerCode });
  }, [_location]);

  useEffect(() => {
    console.log("this state is updating");
    if (isRegistered) {
      console.log("registered is updated");
      signInUser();
    } else {
      //
      console.log("registered is updated");
    }
  }, [isRegistered, isRegistering]);

  const handleBackToLogin = async () => {
    let url = "/login";
    // console.log(refId, code, source);
    // if (refId) {
    //   url = url + "?refId=" + refId;
    // }
    // if (code) {
    //   url = url + `${refId ? "&" : "?"}influencerCode=` + code;
    // }
    // if (source) {
    //   url = url + `${refId || code ? "&" : "?"}source=` + source;
    // }
    navigate(url);
  };

  const signInUser = () => {
    if (googleResponse) {
      console.log("user will be logged in");
      //sign in via google
      let payload = {
        email: googleResponse?.profileObj?.email,
        role: "APPLICANT",
        //google
        action: "login",
        platform: "web",
        idToken: googleResponse?.tokenId,
      };
      verifyGoogleToken(payload);
    } else {
      if (isSupported) {
        if (Notification.permission !== "granted") {
          signIn({
            email: auth_details?.email?.trim(),
            password: auth_details?.password,
          });
        } else {
          messaging
            ? messaging.getToken().then((token) => {
                signIn({
                  email: auth_details?.email?.trim(),
                  password: auth_details?.password,
                  deviceToken: token,
                });
              })
            : signIn({
                email: auth_details?.email?.trim(),
                password: auth_details?.password,
              });
        }
      } else
        signIn({
          email: auth_details?.email?.trim(),
          password: auth_details?.password,
        });
    }
  };
  const [auth_details, setAuthDetails] = useState({ subscribeToEmail: true });
  const [isEmailRegistered, setIsEmailRegistered] = useState(false);
  const [error_fields, setErrorFields] = useState({
    email: null,
    password: null,
    confirm_password: null,
    firstName: null,
    lastName: null,
    dob: null,
    currentLocation: null,
    countryCode: null,
    phoneNumber: null,
    jobFunction: null,
    influencerCode: null,
  });

  const is_valid = async () => {
    // // let required_fields = [
    // //   "email",
    // //   "password",
    // //   "confirm_password",
    // //   "firstName",
    // //   "lastName",
    // //   "dob",
    // //   "currentLocation",
    // //   "countryCode",
    // //   "phoneNumber",
    // //   "jobFunction",
    // //   "agreeToTerms",
    // // ];

    // if (googleResponse) {
    //   required_fields.splice(required_fields.indexOf("password"), 1);
    //   required_fields.splice(required_fields.indexOf("confirm_password"), 1);
    // }
    let valids = [];
    let errors2 = error_fields;

    if (auth_details.email) {
      if (validateEmail(auth_details.email)) {
        const email_valid = await validateEmail(auth_details.email);
        if (email_valid === false) {
          errors2 = {
            ...errors2,
            email: "Invalid email address.",
          };
        } else {
          userRequest
            .validateEmail({ email: auth_details.email })
            .then(() => {
              valids.push(true);
              errors2 = { ...errors2, email: null };
            })
            .catch(() => {
              console.log("err");
              valids.push(false);
              errors2 = {
                ...error_fields,
                email: "Email already exists",
              };
            });
        }
      } else {
        console.log("err");
        valids.push(false);
        errors2 = { ...errors2, email: "Invalid email address" };
      }
    } else {
      console.log("err");
      valids.push(false);
      errors2 = { ...errors2, email: "Email address is required" };
    }

    if (!googleResponse) {
      if (
        !!auth_details.password === false ||
        auth_details.password?.length < 8
      ) {
        errors2 = {
          ...errors2,
          password: "Password must contain atleast 8 characters",
        };
        valids.push(false);
      } else {
        errors2 = { ...errors2, password: null };
        valids.push(true);
      }
      if (auth_details.confirm_password !== auth_details.password) {
        errors2 = { ...errors2, confirm_password: "Passwords do not match!" };
        valids.push(false);
      } else {
        errors2 = {
          ...errors2,
          confirm_password: null,
        };
        valids.push(true);
      }
    }
    if (!auth_details.firstName) {
      errors2 = { ...errors2, firstName: "First name is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        firstName: null,
      };
      valids.push(true);
    }

    if (!auth_details.lastName) {
      errors2 = { ...errors2, lastName: "Last name is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        lastName: null,
      };
      valids.push(true);
    }

    if (!auth_details.dob) {
      errors2 = { ...errors2, dob: "Date of birth is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        dob: null,
      };
      valids.push(true);
    }

    if (!auth_details.currentLocation) {
      errors2 = { ...errors2, currentLocation: "Location is required" };
      valids.push(false);
    } else {
      if (!auth_details?.city || !auth_details?.region) {
        errors2 = {
          ...errors2,
          currentLocation: "Kindly include your city or region",
        };
        valids.push(false);
      } else {
        errors2 = {
          ...errors2,
          currentLocation: null,
        };
      }
      valids.push(true);
    }

    if (!auth_details.countryCode) {
      errors2 = { ...errors2, countryCode: "Country code is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        countryCode: null,
      };
      valids.push(true);
    }

    if (!auth_details.phoneNumber) {
      errors2 = { ...errors2, phoneNumber: "Phone number is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        phoneNumber: null,
      };
      valids.push(true);
    }

    if (!auth_details.jobFunction) {
      errors2 = { ...errors2, jobFunction: "Job function is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        jobFunction: null,
      };
      valids.push(true);
    }

    if (!!auth_details.influencerCode) {
      let influencerCode = auth_details.influencerCode;
      await userRequest
        .validateInfluencerCode({ influencerCode })
        .then((res) => {
          console.log("inf resp", res.data);
          if (res.data?.valid === true) {
            errors2 = {
              ...errors2,
              influencerCode: null,
            };
            valids.push(true);
          } else {
            errors2 = {
              ...errors2,
              influencerCode: res.data?.error?.message?.split(":")[1],
            };
            valids.push(false);
          }
        });
    } else if (!auth_details.influencerCode) {
      errors2 = {
        ...errors2,
        influencerCode: null,
      };
      valids.push(true);
    }

    console.log(errors2);
    setErrorFields(errors2);
    // for (let key of required_fields) {
    //   if (!auth_details[key]) {
    //     errors[key] = `${key} is required`;
    //   } else {
    //     errors[key] = null;
    //     if (key === "email") {
    //       const email_valid = await validateEmail(auth_details[key]);
    //       if (email_valid === false) {
    //         errors["email"] = "Not a valid email address!";
    //       } else {
    //         userRequest
    //           .validateEmail({ email: auth_details.email })
    //           .then(() => {
    //             errors["email"] = null;
    //           })
    //           .catch(() => {
    //             errors["email"] = "Email already exists";
    //           });
    //       }
    //     }
    //     if (key === "confirm_password") {
    //       if (auth_details["confirm_password"] !== auth_details["password"]) {
    //         errors["confirm_password"] = "Password did not matched!";
    //       } else {
    //         errors["confirm_password"] = null;
    //       }
    //     }
    //   }
    // }
    // if (auth_details?.influencerCode) {
    //   await userRequest
    //     .validateInfluencerCode({
    //       influencerCode: auth_details?.influencerCode,
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       if (res.data?.valid) {
    //         errors["influencerCode"] = null;
    //       } else {
    //         errors["influencerCode"] = "Invalid Code";
    //       }
    //     })
    //     .catch(() => {
    //       errors["influencerCode"] = "Invalid Code";
    //     });
    // }
    // console.log(errors);
    // setErrorFields(errors);

    // for (let key of Object.keys(errors)) {
    //   if (errors[key] === null) {
    //     //ok
    //   } else {
    //     valids.push(false);
    //   }
    // }
    // console.log(valids);
    if (valids.includes(false)) return false;

    return true;
  };

  const countryCodes = require("country-codes-list");
  const myCountryCodesObject = countryCodes.customList(
    "countryCode",
    "{countryNameEn}:+{countryCallingCode}"
  );

  const [countryCodesList, setCountryCodesList] = useState(
    Object.values(myCountryCodesObject).map((item) => {
      var temp = item.split(":");
      return {
        label: `${temp[0]} (${temp[1]})`,
        value: item,
      };
    })
  );
  useEffect(() => {
    if (showForm === "signup" && auth_details?.email) {
      userRequest
        .validateEmail({ email: auth_details.email })
        .then(() => {
          setErrorFields({ ...error_fields, email: null });
        })
        .catch(() => {
          setErrorFields({ ...error_fields, email: "Email already exists" });
        });
    }
  }, [auth_details?.email]);

  const handleTextFieldChange = (event) => {
    const { name, value } = event?.target;
    setAuthDetails({
      ...auth_details,
      [name]: name == "phoneNumber" ? value? trimPhone(value) : null : value,
    });
    console.log(auth_details.phoneNumber)
  };

  const handleSignup = async () => {
    console.log("data paylaod", auth_details);
    const isValid = await is_valid();

    if (isValid) {
      //submit
      let payload = {
        userRole: "APPLICANT",
        email: auth_details.email?.trim(),
        password: auth_details.password,
        firstName: auth_details.firstName,
        lastName: auth_details.lastName,
        dob: auth_details.dob,
        currentLocation: auth_details.currentLocation,
        country: auth_details?.country,
        region: auth_details?.region,
        city: auth_details?.city,
        phoneNumber: `${
          auth_details.countryCode?.split(":")?.[1]
        }${auth_details.phoneNumber?.trim()}`,
        jobFunction: auth_details.jobFunction,
        subscribeToEmail: auth_details?.subscribeToEmail,
        source: source,
        leadAdsData: refId ? { referenceId: refId } : undefined,
      };

      if (auth_details?.influencerCode) {
        payload["influencerCode"] = auth_details?.influencerCode;
      }

      if (googleResponse) {
        //google
        payload = {
          ...payload,
          action: "register",
          platform: "web",
          idToken: googleResponse?.tokenId,
          role: "APPLICANT",
        };

        delete payload.password;

        console.log("google payload", payload);
        verifyGoogleToken(payload);
      } else {
        signUp(payload);
      }
    } else {
      console.log("invalid");
    }
  };

  const onSubmitLogin = () => {
      if (auth_details.email) {
        if (validateEmail(auth_details.email)) {
          if (!isEmailRegistered) {
            setErrors({
              ...errors,
              email: null,
            });
            userRequest
              .validateEmail(auth_details)
              .then((res) => {
                // setShowAuthModal("signup");
                setShowForm("signup");
              })
              .catch((err) => {
                console.log("err", err);
                setIsEmailRegistered(true);
              });
          } else {
            signIn({
              email: auth_details.email?.trim(),
              password: auth_details?.password,
            });
          }
        } else {
          console.log("failed");
          setErrors({
            ...errors,
            email: "Invalid Email Address",
          });
        }
      } else {
        setErrors({
          ...errors,
          email: "Email Address is required",
        });
      }
    
  }

  return (
    <div className={`row  p-0 m-0 `}>
      <PublicHeader
        isLogin
        setModalOpen={setModalOpen}
        setModalVariant={setModalVariant}
        showForm={showForm}
      />

      <Grid
        container
        justifyContent={"center"}
        style={{ minHeight: "100vh" }}
        spacing={width < 640 ? 2 : 0}
        className={`${app_classes.padding.a0}`}
      >
        <Grid
          item
          xs={6}
          className={`${login_classes.login_bg} d-none d-md-block`}
        ></Grid>
        <Grid item xs={11} md={6} className={`${app_classes.height.h100}`}>
          <Box className={`${app_classes.height.h100}`}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={`${app_classes.height.h100}`}
            >
              <Grid item xs={11} md={showForm == "login" ? 8 : 11}>
                {showForm == "login" ? (
                  <>
                  <form className="p-3" onSubmit={(e) => {
                    e.preventDefault();
                    onSubmitLogin()
                    }} noValidate={false}>
                    <Box
                      className={`${app_classes.margin.b2} ${app_classes.text.center}`}
                    >
                      <JobluTypography.H2 primary style={{ fontWeight: 600 }}>
                        Ready to get hired?
                        <br /> Login now!
                      </JobluTypography.H2>
                    </Box>
                    <Box className={`${app_classes.margin.b2}`}>
                      {!!error && <ErrorMessage show>{error}</ErrorMessage>}
                      {isVerified && (
                        <SuccessMessage show>
                          <p>
                            Verification completed! <br /> Congratulations! You
                            may login now.
                            <br />
                          </p>
                        </SuccessMessage>
                      )}
                    </Box>
                    <Box className={`${app_classes.margin.b3}`}>
                      <Box>
                        <JobluTypography.Body
                          semiBold
                          className={`${app_classes.text.left} `}
                          primary
                          style={{ fontWeight: 700 }}
                        >
                          Email Address
                        </JobluTypography.Body>
                      </Box>
                      <TextFieldV1
                        size="small"
                        variant="outlined"
                        placeholder="Email Address"
                        fullWidth
                        onChange={(e) => {
                          setAuthDetails({
                            ...auth_details,
                            email: e.target.value,
                          });
                        }}
                        error={errors.email}
                        // helperText={errors?.email && "Invalid Email Address"}
                        text
                        name="email"
                      />
                    </Box>
                    {isEmailRegistered && (
                      <Box className={`${app_classes.margin.b3}`}>
                        <Box className={`${app_classes.margin.b1}`}>
                          <JobluTypography.Body
                            semiBold
                            className={`${app_classes.text.left} `}
                            primary
                            style={{ fontWeight: 700 }}
                          >
                            Password
                          </JobluTypography.Body>
                        </Box>

                        <TextFieldV1
                          type="password"
                          required
                          placeholder="Password"
                          className="mb-4"
                          id="password"
                          name="password"
                          onChange={(e) => {
                            setAuthDetails({
                              ...auth_details,
                              password: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    )}

                    <Box>
                      {isSigningIn ? (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: 20,
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                          style={{ textTransform: "none", fontWeight: 700 }}
                          // onClick={() => {
                          //   if (auth_details.email) {
                          //     if (validateEmail(auth_details.email)) {
                          //       if (!isEmailRegistered) {
                          //         setErrors({
                          //           ...errors,
                          //           email: null,
                          //         });
                          //         userRequest
                          //           .validateEmail(auth_details)
                          //           .then((res) => {
                          //             // setShowAuthModal("signup");
                          //             setShowForm("signup");
                          //           })
                          //           .catch((err) => {
                          //             console.log("err", err);
                          //             setIsEmailRegistered(true);
                          //           });
                          //       } else {
                          //         signIn({
                          //           email: auth_details.email?.trim(),
                          //           password: auth_details?.password,
                          //         });
                          //       }
                          //     } else {
                          //       console.log("failed");
                          //       setErrors({
                          //         ...errors,
                          //         email: "Invalid Email Address",
                          //       });
                          //     }
                          //   } else {
                          //     setErrors({
                          //       ...errors,
                          //       email: "Email Address is required",
                          //     });
                          //   }
                          // }}
                        >
                          {isEmailRegistered ? "Login" : "Continue"}
                        </Button>
                      )}
                    </Box>
                    <Box
                      className={`${app_classes.margin.y1} ${app_classes.text.center}`}
                    >
                      <JobluTypography.Body primary style={{ fontWeight: 700 }}>
                        OR
                      </JobluTypography.Body>
                    </Box>
                    <Box
                      className={`${app_classes.margin.b2} ${app_classes.text.center}`}
                      style={{ border: "1px solid #002E5D", borderRadius: 3 }}
                    >
                      <GoogleLogin
                        className="shadow-none"
                        clientId={creds.GOOGLE_CLIENT_ID}
                        onSuccess={(data) => {
                          handleGoogleLogin(data);
                          setAuthDetails({
                            ...auth_details,
                            firstName: data?.profileObj?.givenName,
                            lastName: data?.profileObj?.familyName,
                            email: data?.profileObj?.email,
                          });
                        }}
                        buttonText={
                          <span style={{ color: "#002E5D", fontWeight: 700 }}>
                            Sign in with Google
                          </span>
                        }
                      />
                    </Box>

                    <div className="d-flex justify-content-center justify-content-md-center">
                      <div className="text-center text-md-center">
                        <a
                          href={"/password-reset"}
                          className={`${app_classes.text.secondary}`}
                          style={{
                            fontFamily: "Open Sans",
                            fontSize: 14,
                            lineHeight: "24px",
                          }}
                        >
                          Forgot Password?
                        </a>
                        <span className={`${app_classes.text.secondary}`}>
                          |
                        </span>
                        <a
                          // href={"/password-reset"}
                          className={`${app_classes.text.secondary} ${app_classes.cursor.pointer}`}
                          style={{
                            fontFamily: "Open Sans",
                            fontSize: 14,
                            lineHeight: "24px",
                          }}
                          onClick={() => {
                            navigate("/login?redirectedFrom=login");
                            // setShowForm("signup");
                          }}
                        >
                          Create an Account
                        </a>
                      </div>
                    </div>
                    </form>
                  </>
                ) : (
                  <>
                    <Box
                      className={`${width > 640 && app_classes.padding.a5} ${
                        width < 640 && app_classes.padding.y3
                      }`}
                    >
                      <Box className={`${app_classes.text.center}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H2
                            primary
                            style={{ fontWeight: 600 }}
                          >
                            Don’t have an account yet? Build your profile now —
                            it's quick and easy, we promise!
                          </JobluTypography.H2>
                        </Box>
                      </Box>
                      <Box>
                        <JobluTypography.H6
                          primary
                          semiBold
                          style={{ fontWeight: 600, marginBottom: 20 }}
                        >
                          Login Information
                        </JobluTypography.H6>
                        <Grid container spacing={2}>
                          <Grid xs={12} item>
                            <Box>
                              <Box>
                                <JobluTypography.Body
                                  semiBold
                                  className={`${app_classes.text.left} `}
                                  style={{ fontWeight: 600 }}
                                  primary
                                >
                                  Email Address
                                </JobluTypography.Body>
                              </Box>
                              <TextFieldV1
                                placeholder="Email Address"
                                fullWidth
                                name="email"
                                onChange={(e) => {
                                  handleTextFieldChange(e);
                                }}
                                error={error_fields?.email}
                                // helperText="Invalid Email Address"
                                value={auth_details?.email}
                                readOnly={googleResponse?.profileObj?.email}
                              />
                            </Box>
                          </Grid>
                          {!googleResponse && (
                            <Grid item xs={12} sm={6}>
                              <JobluTypography.Body
                                semiBold
                                className={`${app_classes.text.left} `}
                                style={{ fontWeight: 600 }}
                                primary
                              >
                                Password
                              </JobluTypography.Body>

                              <TextFieldV1
                                placeholder="Password"
                                name="password"
                                onChange={(e) => {
                                  handleTextFieldChange(e);
                                }}
                                error={error_fields?.password}
                                type="password"
                                value={auth_details?.password}
                              />
                            </Grid>
                          )}
                          {!googleResponse && (
                            <Grid item xs={12} sm={6}>
                              <JobluTypography.Body
                                semiBold
                                className={`${app_classes.text.left} `}
                                style={{ fontWeight: 600 }}
                                primary
                              >
                                Confirm Password
                              </JobluTypography.Body>

                              <TextFieldV1
                                placeholder="Confirm Password"
                                name="confirm_password"
                                onChange={(e) => {
                                  handleTextFieldChange(e);
                                }}
                                error={error_fields?.confirm_password}
                                type="password"
                                value={auth_details?.confirm_password}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <JobluTypography.H6
                              primary
                              semiBold
                              style={{ fontWeight: 600, marginTop: 20 }}
                            >
                              Personal Information
                            </JobluTypography.H6>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <JobluTypography.Body
                              semiBold
                              className={`${app_classes.text.left} `}
                              style={{ fontWeight: 600 }}
                              primary
                            >
                              First Name
                            </JobluTypography.Body>

                            <TextFieldV1
                              placeholder="Juan"
                              name="firstName"
                              onChange={(e) => {
                                handleTextFieldChange(e);
                              }}
                              error={error_fields?.firstName}
                              type="text"
                              value={auth_details?.firstName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <JobluTypography.Body
                              semiBold
                              className={`${app_classes.text.left} `}
                              style={{ fontWeight: 600 }}
                              primary
                            >
                              Last Name
                            </JobluTypography.Body>

                            <TextFieldV1
                              placeholder="Dela Cruz"
                              name="lastName"
                              onChange={(e) => {
                                handleTextFieldChange(e);
                              }}
                              error={error_fields?.lastName}
                              type="text"
                              value={auth_details?.lastName}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <JobluTypography.Body
                              semiBold
                              className={`${app_classes.text.left} `}
                              style={{ fontWeight: 600 }}
                              primary
                            >
                              Birthday
                            </JobluTypography.Body>

                            <TextFieldV1
                              placeholder="MM/DD/YYYY"
                              name="dob"
                              onChange={(e) => {
                                handleTextFieldChange(e);
                              }}
                              error={error_fields?.dob}
                              type="date"
                              value={auth_details?.dob}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <JobluTypography.Body
                              semiBold
                              className={`${app_classes.text.left} `}
                              style={{ fontWeight: 600 }}
                              primary
                            >
                              Current Location
                            </JobluTypography.Body>

                            <CustomGooglePlaces
                              error={error_fields?.currentLocation}
                              value={auth_details?.currentLocation}
                              onChange={(loc) => {
                                let countryCode;
                                if (loc?.countryValue) {
                                  try {
                                    const result = countryCodesList?.find(
                                      (i) =>
                                        i?.value?.split(":")[0] ===
                                        loc?.countryValue
                                    );
                                    if (result) {
                                      countryCode = result.value;
                                    }
                                  } catch (e) {}
                                }
                                setAuthDetails({
                                  ...auth_details,
                                  currentLocation: loc?.location,
                                  region: loc?.region,
                                  city: loc?.city,
                                  country: loc?.countryValue,
                                  countryCode: countryCode || undefined,
                                });
                              }}
                            />
                            {!!error_fields?.currentLocation && (
                              <span className="error-helper mt-0">
                                {error_fields?.currentLocation}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <JobluTypography.Body
                              semiBold
                              className={`${app_classes.text.left} `}
                              style={{ fontWeight: 600 }}
                              primary
                            >
                              Country Code
                            </JobluTypography.Body>

                            <SelectCountry2
                              name="countryCode"
                              onChange={(e) => {
                                console.log(e);
                                setAuthDetails({
                                  ...auth_details,
                                  countryCode: e,
                                });
                              }}
                              noAll
                              value={auth_details?.countryCode}
                              error={error_fields?.countryCode}
                              placeholder="Country Code"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <JobluTypography.Body
                              semiBold
                              className={`${app_classes.text.left} `}
                              style={{ fontWeight: 600 }}
                              primary
                            >
                              Mobile Number
                            </JobluTypography.Body>
                            <TextFieldV1
                              onChange={handleTextFieldChange}
                              name="phoneNumber"
                              maxLength="10"
                              placeholder="9061234567"
                              value={auth_details?.phoneNumber}
                              error={error_fields?.phoneNumber}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <JobluTypography.Body
                              semiBold
                              className={`${app_classes.text.left} `}
                              style={{ fontWeight: 600 }}
                              primary
                            >
                              Job Function
                            </JobluTypography.Body>
                            <SelectJobFunction
                              onChange={(e) => {
                                console.log(e);
                                setAuthDetails({
                                  ...auth_details,
                                  jobFunction: e,
                                });
                              }}
                              noAll
                              value={auth_details?.jobFunction}
                              name="jobFunction"
                              error={error_fields?.jobFunction}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <JobluTypography.Body
                              semiBold
                              className={`${app_classes.text.left} `}
                              style={{ fontWeight: 600 }}
                              primary
                            >
                              Do you have an Influencer Code? Enter it here!{" "}
                              <span style={{ color: "gray" }}>(optional)</span>
                            </JobluTypography.Body>
                            <TextFieldV1
                              name="influencerCode"
                              onChange={handleTextFieldChange}
                              error={error_fields?.influencerCode}
                              value={auth_details?.influencerCode}
                              placeholder="Influencer Code"
                              disabled={code && !error_fields?.influencerCode}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              className={`${app_classes.display.display_flex}`}
                            >
                              <Checkbox
                                onChange={() => {
                                  setAuthDetails({
                                    ...auth_details,
                                    agreeToTerms: !auth_details?.agreeToTerms,
                                  });
                                }}
                              />
                              <JobluTypography.Body
                                semiBold
                                className={`${app_classes.text.left} `}
                                style={{
                                  fontWeight: 400,
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                  fontSize: 16,
                                  color: error_fields?.agreeToTerms && "red",
                                }}
                              >
                                I agree to the Joblu{" "}
                                <Link
                                  component="span"
                                  color="secondary"
                                  to={"https://joblu.io/terms-of-use/"}
                                  target="_blank"
                                >
                                  {" "}
                                  Terms of Use{" "}
                                </Link>{" "}
                                and{" "}
                                <Link
                                  component="span"
                                  color="secondary"
                                  to={"https://joblu.io/privacy-policy/"}
                                  target="_blank"
                                >
                                  Privacy Policy{" "}
                                </Link>
                                .
                              </JobluTypography.Body>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              className={`${app_classes.display.display_flex}`}
                            >
                              <Checkbox
                                onChange={() => {
                                  setAuthDetails({
                                    ...auth_details,
                                    subscribeToEmail:
                                      !auth_details?.subscribeToEmail,
                                  });
                                }}
                                checked={auth_details?.subscribeToEmail}
                              />
                              <JobluTypography.Body
                                semiBold
                                className={`${app_classes.text.left} `}
                                style={{
                                  fontWeight: 400,
                                  // marginTop: "auto",
                                  // marginBottom: "auto",
                                  fontSize: 16,
                                }}
                              >
                                Subscribe to our newsletter and receive the
                                latest news and trends delivered right to your
                                inbox!
                              </JobluTypography.Body>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={!auth_details?.agreeToTerms}
                              fullWidth
                              style={{ textTransform: "none", fontWeight: 700 }}
                              onClick={() => !isRegistering && handleSignup()}
                              endIcon={
                                isRegistering && (
                                  <CircularProgress color="inherit" size={18} />
                                )
                              }
                            >
                              {googleResponse ? (
                                <span>
                                  Create Account <br />{" "}
                                  <span
                                    style={{ fontSize: 12, fontWeight: 400 }}
                                  >
                                    ( {googleResponse?.profileObj?.email} )
                                  </span>{" "}
                                </span>
                              ) : (
                                "Create Account"
                              )}
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              style={{
                                border: "1px solid #002E5D",
                                borderRadius: 3,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <GoogleLogin
                                clientId={creds.GOOGLE_CLIENT_ID}
                                className="shadow-none"
                                buttonText={
                                  <span
                                    style={{
                                      color: "#002E5D",
                                      fontWeight: 700,
                                    }}
                                  >
                                    {googleResponse
                                      ? "Choose a different account"
                                      : " Sign up with Google"}
                                  </span>
                                }
                                onSuccess={(data) => {
                                  setGoogleResponse(data);
                                  // console.log(data);
                                  setAuthDetails({
                                    ...auth_details,
                                    firstName: data?.profileObj?.givenName,
                                    lastName: data?.profileObj?.familyName,
                                    email: data?.profileObj?.email,
                                  });
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              style={{ textTransform: "none", fontWeight: 600 }}
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setShowForm("login");
                                handleBackToLogin();
                              }}
                            >
                              <ArrowBackIcon
                                className={`${app_classes.margin.r1}`}
                              />{" "}
                              Back to Login
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Footer />
      <div
        className="modal fade"
        id="signupModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-sm">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h6 className="mb-3">How do you want to sign up?</h6>
              <span href={"#"} className="btn btn-primary mb-3">
                Sign up with email
              </span>
              <small>
                By signing up, you agree to the Joblu
                <br />
                <a
                  href="https://joblu.io/terms-of-use/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="https://joblu.io/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </small>
              <p className="mb-3">Already have an account?</p>
              <a>Sign in here</a>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={!!User.isSigningIn}
        centered
        contentClassName="bg-transparent border-0"
      >
        <Loading show color="primary" />
      </Modal>
      <ExploreModal
        showModal={modalOpen}
        variant={modalVariant}
        setModalVariant={setModalVariant}
        setShowModal={setModalOpen}
        handleGoogleLogin={handleGoogleLogin}
        referrers={referrers}
      />
      <GoogleReCaptcha handleVerify={(token) => setVerifiedCaptcha(true)} />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    User: state.User,
  };
};
const mapDispatchToProps = {
  signIn: userAction.signIn,
  signUp: userAction.signUp,
  verifyGoogleToken: userAction.verifyGoogleToken,
  resetUserError: userAction.resetUserError,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
