import { all, put, takeEvery } from "redux-saga/effects";
import {
  paymentRequests,
  paymentsRequest,
  userRequest,
} from "../../service/requests";
import generalActions from "../app/actions";
import { default as action, default as userActions } from "./actions";
function* signInRequest({ payload }) {
  console.log(payload);
  try {
    const response = yield userRequest.signIn(payload);
    if (response) {
      const userType = response?.data?.role;
      if (userType === "APPLICANT") {
        yield put({
          type: action.SIGN_IN_SUCCESS,
          payload: response.data,
        });
      } else {
        if (userType === "EMPLOYER")
          yield put({
            type: action.SIGN_IN_FAILURE,
            payload: "Only Job Seeker accounts can access this portal.",
          });
        else {
          yield put({
            type: action.SIGN_IN_FAILURE,
            payload: "Only Employer accounts can access this portal.",
          });
        }
      }
    } else {
      yield put({
        type: action.SIGN_IN_FAILURE,
        payload: "Login Failed.",
      });
    }
  } catch (error) {
    console.log("signInRequest : error : ", error);
    console.log(error.response.data);
    if (error.response.data.message === "401003: Email is not verified") {
      yield put({
        type: action.VERIFICATION_FAILURE,
        payload: "Verification failed. Please verify your email.",
      });
    } else {
      if (error.response.status === 404) {
        yield put({
          type: action.SIGN_IN_FAILURE,
          payload:
            "It looks like that email address doesn't belong to a Joblu account. Please try again.",
        });
      } else if (error.response.status === 401) {
        yield put({
          type: action.SIGN_IN_FAILURE,
          payload: "Oops! That's not the right password. Please try again.",
        });
      } else {
        yield put({
          type: action.SIGN_IN_FAILURE,
          payload: "Login Failed",
        });
      }
    }
  }
}

function* confirmPhoneCodeRequest({ payload }) {
  try {
    const response = yield userRequest.confirmPhoneCode(payload);
    if (response) {
      //  console.log(response.data)
      yield put({
        type: action.SIGN_IN_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.SIGN_IN_FAILURE,
        payload: "OTP_FAILED",
      });
    }
  } catch (error) {
    console.log("confirmPhoneCodeRequest : error : ", error);
    // console.log(error.response.data)
    yield put({
      type: action.SIGN_IN_FAILURE,
      payload: "OTP_FAILED",
    });
  }
}

function* verifyGoogleToken({ payload }) {
  try {
    let response;
    if (payload?.action === "login") {
      response = yield userRequest.verifyGoogleLogin(payload);
    } else {
      if (payload?.role === "APPLICANT")
        response = yield userRequest.verifyGoogleRegisterApplicant(payload);
      else response = yield userRequest.verifyGoogleRegisterEmployer(payload);
    }
    console.log("google verify response", response);
    if (response) {
      if (response?.data?.role !== "APPLICANT" && payload?.action === "login") {
        yield put({
          type: action.VERIFY_GOOGLE_TOKEN_FAILURE,
          payload: "Only jobseeker accounts can access this portal.",
        });
      } else if (
        response?.data?.role === "APPLICANT" &&
        payload.action === "register"
      ) {
        yield put({
          type: generalActions.SHOW_SIGNUP_SUCCESS,
          // payload: response.data,
        });
        yield put({
          type: action.SIGN_UP_SUCCESS,
          payload: response.data,
        });
      } else {
        yield put({
          type: action.SIGN_IN_SUCCESS,
          payload: response.data,
        });
        yield put({
          type: action.SET_IS_GOOGLE_SIGNUP,
          payload: false,
        });
      }
    } else {
      console.log("failed here");
      yield put({
        type: action.VERIFY_GOOGLE_TOKEN_FAILURE,
        payload: "Verification Failed!.",
      });
    }
  } catch (error) {
    if (error.response.data.statusCode === 400) {
      if (error.response.data.message === "404001: User not found") {
        yield put({
          type: action.VERIFY_GOOGLE_TOKEN_FAILURE,
          payload: "",
        });
        yield put({
          type: action.SET_IS_GOOGLE_SIGNUP,
          payload: true,
        });
        return false;
      }
      if (typeof error.response.data.message === "object") {
        if (
          error.response.data.message.indexOf(
            "inviteCode should not be empty"
          ) !== -1
        ) {
          yield put({
            type: action.VERIFY_GOOGLE_TOKEN_FAILURE,
            payload: "Invite code is required to register. Kindly request one!",
          });
        } else {
          yield put({
            type: action.VERIFY_GOOGLE_TOKEN_FAILURE,
            payload: "Verification failed!",
          });
        }
      } else {
        if (payload.action === "register")
          yield put({
            type: action.VERIFY_GOOGLE_TOKEN_FAILURE,
            payload: "This email is not yet registered. Kindly register first!",
          });
        else {
          yield put({
            type: action.VERIFY_GOOGLE_TOKEN_FAILURE,
            payload: "Verification failed!",
          });
        }
      }
    }
    if (error?.response?.status === 409) {
      yield put({
        type: action.VERIFY_GOOGLE_TOKEN_FAILURE,
        payload: "Email already exists",
      });
    } else
      yield put({
        type: action.VERIFY_GOOGLE_TOKEN_FAILURE,
        payload: "Verification failed!",
      });
  }
}

function* verifyAppleToken({ payload }) {
  try {
    let response;
    if (payload.action === "login")
      response = yield userRequest.verifyAppleLogin(payload);
    else {
      if (payload.role === "APPLICANT") {
        response = yield userRequest.verifyAppleRegisterApplicant(payload);
      } else response = yield userRequest.verifyAppleRegisterEmployer(payload);
    }
    if (response) {
      console.log(response.data);
      yield put({
        type: action.SIGN_IN_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.VERIFY_APPLE_TOKEN_FAILURE,
        payload: "Verification Failed!.",
      });
    }
  } catch (error) {
    console.log("verifyAppleToken : error : ", error);
    console.log(error.response.data);
    if (error.response.data.statusCode === 400) {
      if (typeof error.response.data.message === "object")
        if (
          error.response.data.message.indexOf(
            "inviteCode should not be empty"
          ) !== -1
        ) {
          yield put({
            type: action.VERIFY_APPLE_TOKEN_FAILURE,
            payload: "Invite code is required to register. Kindly request one!",
          });
        } else {
          yield put({
            type: action.VERIFY_APPLE_TOKEN_FAILURE,
            payload: "Verification failed!",
          });
        }
      else {
        if (payload.action === "register")
          yield put({
            type: action.VERIFY_APPLE_TOKEN_FAILURE,
            payload: "This email is not yet registered. Kindly register first!",
          });
        else {
          yield put({
            type: action.VERIFY_APPLE_TOKEN_FAILURE,
            payload: "Verification failed!",
          });
        }
      }
    } else
      yield put({
        type: action.VERIFY_APPLE_TOKEN_FAILURE,
        payload: "Verification failed!",
      });
  }
}

function* getUserRequest({ payload }) {
  try {
    console.log("fetching user data..");
    const response = yield userRequest.getUser({}, payload);

    if (response) {
      const data = response.data;
      yield put({
        type: action.GET_USER_SUCCESS,
        payload:
          data.applicantProfiles === undefined
            ? data.employerProfiles
            : data.applicantProfiles,
      });
    } else {
      yield put({
        type: action.GET_USER_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log("getUserRequest : error : ", error);
  }
}

function* getApplicantCompletion() {
  try {
    const response = yield userRequest.getApplicantCompletion();

    if (response) {
      const data = response.data;
      console.log("APPLICANT INFO DATA");
      console.log(data);
      yield put({
        type: action.GET_APPLICANT_COMPLETION_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: action.GET_APPLICANT_COMPLETION_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log("getApplicantCompletion : error : ", error);
  }
}

function* signUpRequest({ payload }) {
  console.log(payload);
  try {
    const response = yield userRequest.signUp(payload);

    if (response) {
      yield put({
        type: action.SIGN_UP_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: generalActions.SHOW_SIGNUP_SUCCESS,
      });
    } else {
      yield put({
        type: action.SIGN_UP_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    if (
      error.response.data.message ===
      "409001: User with this email already exists"
    ) {
      setTimeout(() => {
        alert("User with this email already exists");
      }, 500);
    } else if (
      error.response.data.message === "409005: User name already exists."
    ) {
      setTimeout(() => {
        alert("Username already exists. Try a different one.");
      }, 500);
    } else {
      setTimeout(() => {
        alert("Sign Up failed. Kindly check all fields.");
      }, 500);
    }
    yield put({
      type: action.SIGN_UP_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("signUpRequest : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* changePasswordRequest({ payload }) {
  try {
    const response = yield userRequest.changePassword(payload);

    if (response) {
      yield put({
        type: action.CHANGE_PASSWORD_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.CHANGE_PASSWORD_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log("changePasswordRequest : error : ", error);
  }
}

function* updateUserRequest({ payload }) {
  try {
    const response = yield userRequest.updateUser(payload);
    if (response) {
      const data = response.data;
      yield put({
        type: action.UPDATE_USER_SUCCESS,
        payload:
          data.applicantProfiles === undefined
            ? data.employerProfiles
            : data.applicantProfiles,
      });
    } else {
      yield put({
        type: action.UPDATE_USER_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log("updateUserRequest : error : ", error);
    yield put({
      type: action.UPDATE_USER_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("Update User : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* resetPasswordRequest({ payload }) {
  try {
    const response = yield userRequest.resetPassword(payload);

    if (response) {
      yield put({
        type: action.RESET_PASSWORD_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.RESET_PASSWORD_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log("resetPasswordRequest : error : ", error);
  }
}

function* getApplicantDetails({ payload }) {
  try {
    const response = yield userRequest.getApplicantDetails(payload);

    if (response) {
      yield put({
        type: action.GET_APPLICANT_DETAILS_REQUEST,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_APPLICANT_DETAILS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log("getApplicantDetails : error : ", error);
  }
}

function* renewAccessToken({ payload }) {
  try {
    const response = yield userRequest.renewAccessToken(payload);

    if (response) {
      yield put({
        type: action.RENEW_ACCESS_TOKEN_REQUEST,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.RENEW_ACCESS_TOKEN_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log("renewAccessToken : error : ", error);
  }
}

function* getEmployerDashboardDetails() {
  try {
    const response = yield userRequest.getEmployerDashboardDetails();
    if (response) {
      yield put({
        type: action.GET_EMPLOYER_DASHBOARD_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_EMPLOYER_DASHBOARD_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_EMPLOYER_DASHBOARD_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("getEmployerDashboardDetails : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* getApplicantAppliedJobs() {
  try {
    const response = yield userRequest.getApplicantsAppliedJobs();
    if (response) {
      yield put({
        type: action.GET_APPLICANT_APPLIED_JOBS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_APPLICANT_APPLIED_JOBS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_APPLICANT_APPLIED_JOBS_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("getApplicantAppliedJobs : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* getApplicantActiveJobs() {
  try {
    const response = yield userRequest.getApplicantsActiveJobs();
    if (response) {
      yield put({
        type: action.GET_APPLICANT_ACTIVE_JOBS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_APPLICANT_ACTIVE_JOBS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_APPLICANT_ACTIVE_JOBS_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("getApplicantActiveJobs : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* getApplicantShortlistedJobs() {
  try {
    const response = yield userRequest.getApplicantsShortlistedJobs();
    if (response) {
      yield put({
        type: action.GET_APPLICANT_SHORTLISTED_JOBS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_APPLICANT_SHORTLISTED_JOBS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_APPLICANT_SHORTLISTED_JOBS_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("getApplicantShortlistedJobs : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* getApplicantRejectedJobs() {
  try {
    const response = yield userRequest.getApplicantsRejectedJobs();
    if (response) {
      yield put({
        type: action.GET_APPLICANT_REJECTED_JOBS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_APPLICANT_REJECTED_JOBS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_APPLICANT_REJECTED_JOBS_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("getApplicantRejectedJobs : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* getApplicantSavedJobs() {
  try {
    const response = yield userRequest.getApplicantsSavedJobs();
    if (response) {
      yield put({
        type: action.GET_APPLICANT_SAVED_JOBS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_APPLICANT_SAVED_JOBS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_APPLICANT_SAVED_JOBS_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("getApplicantSavedJobs : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* checkPaymentStatusPriority({ payload }) {
  try {
    const response = yield paymentsRequest.checkPayment({
      paymentType: payload.paymentType,
      applicantId: payload.applicantId,
    });
    if (response) {
      yield put({
        type: action.CHECK_PAYMENT_STATUS_PRIORITY_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.CHECK_PAYMENT_STATUS_PRIORITY_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.CHECK_PAYMENT_STATUS_PRIORITY_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("checkPaymentStatusPriority : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* checkPaymentStatusPreferred({ payload }) {
  try {
    const response = yield paymentsRequest.checkPayment({
      paymentType: payload.paymentType,
      applicantId: payload.applicantId,
    });
    if (response) {
      yield put({
        type: action.CHECK_PAYMENT_STATUS_PREFERRED_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.CHECK_PAYMENT_STATUS_PREFERRED_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.CHECK_PAYMENT_STATUS_PREFERRED_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("checkPaymentStatusPreferred : error : ", errorMessage);
    // alert(error.response.data.message);
  }
}

function* updateApplicantRequest({ payload }) {
  try {
    const response = yield userRequest.updateApplicant(payload);
    if (response) {
      const data = response.data;
      yield put({
        type: action.UPDATE_APPLICANT_SUCCESS,
        payload: data.profile,
      });
    } else {
      yield put({
        type: action.UPDATE_APPLICANT_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log("updateUserRequest : error : ", error);
    yield put({
      type: action.UPDATE_APPLICANT_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("Update User : error : ", errorMessage);
    console.log(error.response.data);
  }
}

function* getPaymentsRequest({ payload }) {
  try {
    const response = yield paymentsRequest.getPayments({}, payload);

    if (response) {
      const data = response.data;
      yield put({
        type: action.GET_PAYMENTS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: action.GET_PAYMENTS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_PAYMENTS_FAILURE,
      payload: {},
    });
    console.log("getUserRequest : error : ", error);
  }
}

function* updateEmployerRequest({ payload }) {
  try {
    const response = yield userRequest.updateEmployer(payload);
    if (response) {
      const data = response.data;
      yield put({
        type: action.UPDATE_EMPLOYER_SUCCESS,
        payload: data.profile,
      });
    } else {
      yield put({
        type: action.UPDATE_EMPLOYER_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log("updateUserRequest : error : ", error);
    console.log(error.response.data);
    yield put({
      type: action.UPDATE_EMPLOYER_FAILURE,
      payload: {},
    });
    let errorMessage =
      error.response.status + ":" + error.response.data.message;
    console.log("Update User : error : ", errorMessage);
  }
}

function* getActiveSubscription() {
  try {
    const res = yield paymentRequests.getActiveSubscription();
    if (res) {
      yield put({
        type: userActions.SET_ACTIVE_SUBSCRIPTION,
        payload: res.data,
      });
    } else {
      console.log("error fetching subscription details.");
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(action.SIGN_IN_REQUEST, signInRequest)]);
  yield all([
    takeEvery(action.CONFIRM_PHONE_CODE_REQUEST, confirmPhoneCodeRequest),
  ]);
  yield all([takeEvery(action.VERIFY_GOOGLE_TOKEN_REQUEST, verifyGoogleToken)]);
  yield all([takeEvery(action.VERIFY_APPLE_TOKEN_REQUEST, verifyAppleToken)]);
  yield all([takeEvery(action.GET_USER_REQUEST, getUserRequest)]);
  yield all([
    takeEvery(action.GET_APPLICANT_COMPLETION_REQUEST, getApplicantCompletion),
  ]);
  yield all([takeEvery(action.SIGN_UP_REQUEST, signUpRequest)]);
  yield all([takeEvery(action.UPDATE_USER_REQUEST, updateUserRequest)]);
  yield all([takeEvery(action.CHANGE_PASSWORD_REQUEST, changePasswordRequest)]);
  yield all([takeEvery(action.RESET_PASSWORD_REQUEST, resetPasswordRequest)]);
  yield all([
    takeEvery(action.GET_APPLICANT_DETAILS_REQUEST, getApplicantDetails),
  ]);
  yield all([takeEvery(action.RENEW_ACCESS_TOKEN_REQUEST, renewAccessToken)]);
  yield all([
    takeEvery(
      action.GET_EMPLOYER_DASHBOARD_REQUEST,
      getEmployerDashboardDetails
    ),
  ]);
  yield all([
    takeEvery(
      action.GET_APPLICANT_APPLIED_JOBS_REQUEST,
      getApplicantAppliedJobs
    ),
  ]);
  yield all([
    takeEvery(action.GET_APPLICANT_ACTIVE_JOBS_REQUEST, getApplicantActiveJobs),
  ]);
  yield all([
    takeEvery(
      action.GET_APPLICANT_SHORTLISTED_JOBS_REQUEST,
      getApplicantShortlistedJobs
    ),
  ]);
  yield all([
    takeEvery(
      action.GET_APPLICANT_REJECTED_JOBS_REQUEST,
      getApplicantRejectedJobs
    ),
  ]);
  yield all([
    takeEvery(action.GET_APPLICANT_SAVED_JOBS_REQUEST, getApplicantSavedJobs),
  ]);
  yield all([
    takeEvery(
      action.CHECK_PAYMENT_STATUS_PRIORITY_REQUEST,
      checkPaymentStatusPriority
    ),
  ]);
  yield all([
    takeEvery(
      action.CHECK_PAYMENT_STATUS_PREFERRED_REQUEST,
      checkPaymentStatusPreferred
    ),
  ]);
  yield all([
    takeEvery(action.UPDATE_APPLICANT_REQUEST, updateApplicantRequest),
  ]);
  yield all([takeEvery(action.GET_PAYMENTS_REQUEST, getPaymentsRequest)]);
  yield all([takeEvery(action.UPDATE_EMPLOYER_REQUEST, updateEmployerRequest)]);

  yield all([takeEvery(action.GET_ACTIVE_SUBSCRIPTION, getActiveSubscription)]);
}
