import { Box, Button, Grid } from "@material-ui/core";
import JobluTypography from "../../components/Typography";
import { app_styles } from "../../general.styles";
import NotFoundImg from "../../assets/img/404.png";
import { useNavigate } from "react-router-dom";
const NotFound = (props) => {
    const app_classes = app_styles(props);
    const navigate = useNavigate();
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box
            // className="vh-100 d-flex justify-content-center align-items-center flex-column"
            className={`${app_classes.height.vh100} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.flexBox.align_items_center} ${app_classes.flexBox.flex_direction_column}`}
          >
            <Box className="mb-4">
              <JobluTypography.H2 bold primary>
                Oops, something went wrong
              </JobluTypography.H2>
            </Box>
            <Box className="mb-4">
              <img src={NotFoundImg} />
            </Box>
            <Box className="mb-3">
              <JobluTypography.H5 primary>
                The page you’re trying to access does not exist or has been
                taken down.
              </JobluTypography.H5>
            </Box>
            <Box className="mb-4">
              <JobluTypography.H5 primary>
                If you think this is a mistake, please let us know.
              </JobluTypography.H5>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={() => {
                    navigate(-1);
                }}
              >
                Go Back
              </Button>
            </Box>
            {/* <Box>
              <Button
                variant="text"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Contact Us
              </Button>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default NotFound;
