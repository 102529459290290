import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";


export default function SignupHook(props){

    const navigate = useNavigate();
    const location = useLocation();

    const code = new URLSearchParams(location?.search).get("influencerCode");
    const source = new URLSearchParams(location?.search).get("source");
    const refId = new URLSearchParams(location?.search).get("refId");

    useEffect(() => {

        let url = "/login?redirectedFrom=signup";

        if (refId) {
          url = url + "&refId=" + refId;
        }
        if (code) {
          url = url + `&influencerCode=` + code;
        }
        if (source) {
          url = url + `&source=` + source;
        }
        navigate(url);
    }, [location])

    return(
        <></>
    );
}