import jobIcon from '../../../assets/img/jobIcon.svg'
import locationIcon from '../../../assets/img/locationIcon.svg'
import ElevatorPitch from '../../../components/reusables/elevator-pitch/ElevatorPitch'
import { defaultSwipeActions } from '../../../utils/constants'
import { formatDateOnly, titleCase } from '../../../utils/helpers'
import './styles.css'

const RecommendationItem = (props) => {
    const { handleView, hasSwipeActions, data, swipeAction, itemId } = props
    return (
        <div
            className="row border-top border-bottom py-3 applicant-card"
            id={itemId}
        >
            <div className="col-md-6 col-sm-12 d-flex">
                <div
                    className="text-center position-relative"
                    style={{ height: '100px' }}
                >
                    <ElevatorPitch
                        jobseeker={data?.applicant}
                        width={100}
                        height={100}
                        hasSwipeActions={!hasSwipeActions}
                    />
                </div>
                <div className="ms-3">
                    <h5 className="text-primary mb-0">
                        {
                            data?.applicant?.anonymous
                            ? 'Job Seeker'
                            : titleCase(`${data?.applicant?.firstName} ${data?.applicant?.lastName}`)
                        }
                    </h5>
                    {data?.applicant?.preferredTitle && (
                        <p className="small text-muted mb-0">
                            <strong>
                                {data?.applicant?.preferredTitle}
                            </strong>
                        </p>
                    )}
                    <p className="text-wrap mb-0">{titleCase(data?.job?.jobTitle)}</p>
                    <p className="text-muted text-wrap mb-0">
                        {data?.applicant?.jobFunction}
                    </p>
                    <div className="mt-3 d-flex flex-column flex-md-row mb-3 mb-md-0">
                        <div className="d-flex align-items-center me-4">
                            <div>
                                <img
                                    src={locationIcon}
                                    className="h-24 me-2"
                                    alt=""
                                />
                            </div>
                            <div className="">
                                <p className="small mb-0">
                                    Based in{' '}
                                    {data?.applicant?.currentLocation}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            {data?.applicant?.totalExperienceMin ===
                                undefined ||
                            data?.applicant?.totalExperienceMin ===
                                null ? (
                                <>
                                    <div>
                                        <img
                                            src={jobIcon}
                                            className="h-24 me-2"
                                            alt=""
                                        />
                                    </div>
                                    <div className="">
                                        <p className="small mb-0">
                                            No work experience
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <img
                                            src={jobIcon}
                                            className="h-24 me-2"
                                            alt=""
                                        />
                                    </div>
                                    <div className="">
                                        <p className="small mb-0">
                                            {data?.applicant
                                                ?.totalExperienceMin ===
                                                undefined ||
                                            data?.applicant
                                                ?.totalExperienceMin === null
                                                ? 'No Work Experience'
                                                : data?.applicant
                                                      ?.totalExperienceMin ===
                                                  data?.applicant
                                                      ?.totalExperienceMax
                                                ? `${data?.applicant?.totalExperienceMin} Years of Experience`
                                                : data?.applicant
                                                      ?.totalExperienceMin ===
                                                  20
                                                ? `More than ${data?.applicant?.totalExperienceMin} Years of Experience`
                                                : `${data?.applicant?.totalExperienceMin} - ${data?.applicant?.totalExperienceMax} Years of Experience`}
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="text-center text-md-end mt-2 mt-md-0 d-grid d-md-block">
                    {data?.employer?.action === 'superLike' && (
                        <span className="me-3 mb-2 mb-md-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-star-fill me-2 text-warning"
                                viewBox="0 0 16 16"
                            >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            Super Liked
                        </span>
                    )}
                    <button
                        className="btn btn-primary text-center mb-2"
                        onClick={handleView}
                    >
                        View Profile
                    </button>
                </div>
                {hasSwipeActions ? (
                    <div className="text-center text-md-end d-grid d-md-block">
                        <button
                            onClick={() =>
                                swipeAction({
                                    job: data?.job,
                                    applicant: data?.applicant,
                                    action: defaultSwipeActions.left,
                                    itemId: itemId,
                                })
                            }
                            className="btn btn-outline-primary  mb-2 mb-md-0 me-0 me-md-2 text-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-x"
                                viewBox="0 0 16 16"
                            >
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                            Dismiss
                        </button>
                        <button
                            onClick={() =>
                                swipeAction({
                                    job: data?.job,
                                    applicant: data?.applicant,
                                    action: defaultSwipeActions.top,
                                    itemId: itemId,
                                })
                            }
                            className="btn btn-outline-warning mb-2 mb-md-0 text-black me-0 me-md-2 text-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-star me-2"
                                viewBox="0 0 16 16"
                            >
                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            </svg>
                            Super Like
                        </button>
                        <button
                            onClick={() =>
                                swipeAction({
                                    job: data?.job,
                                    applicant: data?.applicant,
                                    action: defaultSwipeActions.right,
                                    itemId: itemId,
                                })
                            }
                            className="btn btn-outline-primary mb-2 mb-md-0 text-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-heart me-2"
                                viewBox="0 0 16 16"
                            >
                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                            </svg>
                            Like
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
export default RecommendationItem
