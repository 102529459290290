import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";

import VideoThumbnail from "react-video-thumbnail";
import PlayCircleIcon from "@material-ui/icons/PlayCircleFilled";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import _, { debounce } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import AsyncSelect from "react-select/async";
import validator from "validator";
import DefaultImg from "../../assets/img/defaultImg.png";
import UploadIcon2 from "../../assets/img/editProfile/UploadIcon2.png";
import DeleteIconRed from "../../assets/img/editProfile/deleteIconRed.png";
import EditIcon from "../../assets/img/editProfile/editIcon.png";
import EducationIcon from "../../assets/img/editProfile/educationIcon.png";
import EducationIconM from "../../assets/img/editProfile/educationIconM.png";
import EmploymentIcon from "../../assets/img/editProfile/employmentIcon.png";
import employmentTypeIconM from "../../assets/img/editProfile/employmentTypeIconM.png";
import ExperienceIcon from "../../assets/img/editProfile/experienceIcon.png";
import ExperienceIconM from "../../assets/img/editProfile/experienceIconM.png";
import LocationIconM from "../../assets/img/editProfile/locationIconM.png";
import PhotoEditIcon from "../../assets/img/editProfile/photoEditIcon.png";
import SoftSkillsIcon from "../../assets/img/editProfile/softSkillsIcon.png";
import TechnicalSkillsIcon from "../../assets/img/editProfile/technicalSkillsIcon.png";
import ValuesIcon from "../../assets/img/editProfile/valuesIcon.png";
import WorkSetupIconM from "../../assets/img/editProfile/workSetupIconM.png";
import JobluTypography from "../../components/Typography";
import { MUICustomTextField as CustomTextField } from "../../components/inputs/CustomTextField";
import SelectJobFunction from "../../components/reusables/SelectJobFunction";
import CustomGooglePlaces from "../../components/select/google-places";
import { app_styles } from "../../general.styles";
import appActions from "../../redux/app/actions";
import userActions from "../../redux/user/actions";
import { configRequest, userRequest } from "../../service/requests";
import {
  formatDateRange2,
  getCountryCodeFromNumber,
  getYearDifference,
  getYearsOfExpCategory,
  trimPhone,
} from "../../utils/helpers";
import useWindowDimensions from "../../utils/hooks";
import { skills_masterlist } from "../../utils/skills";
import JobluVideoPlayer from "../explore/components/VideoPlayer";
import { edit_profile_styles } from "./styles";

import ReactSelect from "react-select";
import CustomTextFieldV1 from "../../components/inputs/CustomTextField";
import ProfilePreview from "../../components/profile-preview";
import { Buffer } from "buffer";
import PlayIcon from "../../assets/img/video-playback.png";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./canvasPreview";
import Close from "@material-ui/icons/Close";
import ReactPlayer from "react-player";

const EditProfile = (props) => {
  const { setShowAlert, getUser, currency, User } = props;
  const { user, isFetching, isFetched } = User;
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const app_classes = app_styles();
  const edit_profile_classes = edit_profile_styles();
  const [current_user, setCurrentUser] = useState(props?.User?.user);
  const [employmentStatus, setEmploymentStatus] = useState(null);
  const [employmentType, setEmploymentType] = useState([]);
  const [workSetup, setWorkSetup] = useState([]);
  const [values, setValues] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [isSearcingSkills, setIsSearchingSkill] = useState(false);
  const [applicantCompletion, setApplicantCompletion] = useState(0);
  const [openPreview, setOpenPreview] = useState(false);

  // CROP
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef("");
  const imgRef = useRef("");
  const hiddenAnchorRef = useRef("");
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1);
  const [uploading, setUploading] = useState(false);

  const [showProfilePicModal, setShowProfilePicModal] = useState(false);

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const handleSetProfileUrl = (file) => {
    if (completedCrop) {
      // create a canvas element to draw the cropped image
      const canvas = document.createElement("canvas");

      // get the image element
      const image = imgRef.current;

      // draw the image on the canvas
      if (image) {
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        if (ctx) {
          ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = "high";

          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
          );
        }

        const base64Image = canvas.toDataURL("image/png"); // can be changed to jpeg/jpg etc

        console.log(base64Image);
        if (base64Image) {
          // @ts-ignore
          const fileType = base64Image.split(";")[0].split(":")[1];

          const buffer = Buffer.from(
            base64Image.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          );
          const file = new File([buffer], "profile-pic", {
            type: fileType,
          });
          // setProfileURl(file)
          let payload = new FormData();
          payload.append("document", file);
          setUploading(true);
          userRequest
            .uploadFile(payload)
            .then((res) => {
              console.log("res profile pic", res.data);
              setCurrentUser({
                ...current_user,
                profileUrl: res.data?.documents,
              });
              setShowProfilePicModal(false);
              setUploading(false);
            })
            .catch(() => {
              setShowProfilePicModal(false);
              setUploading(false);
            });
        }
      }
    }
    // setProfileUrl(file)
  };

  const handleUploadProfileURL = () => {
    document.getElementById("upload-pic")?.click();
    // document.getElementById('profileUrl-input')?.click()
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const [cropping, setCropping] = useState(false);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCropping(true);
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        setCropping(false);
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [completedCrop, scale, rotate]);
  // END OF CROP

  const handleOpen = () => {
    const is_valid = validate();
    if (is_valid) {
      handleSaveProfile(true);
    } else {
      setShowAlert({
        message: "Please fill out all required fields!",
        type: "error",
      });
    }
  };
  const handleClose = () => setOpenPreview(false);
  const [scroll, setScroll] = useState("body");
  const [maxWidth, setMaxWidth] = useState("lg");
  const [skill_list, setSkill_List] = useState([
    ...skills_masterlist.sort((a, b) => (a.value < b.value ? -1 : 1)),
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const _location = useLocation();

  // console.log("edit profile/location", _location);
  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    console.log("current updated", current_user);
    userRequest.getApplicantCompletion().then((res) => {
      // console.log("res.data", res.data);
      setApplicantCompletion(res?.data?.completion);
    });
  }, [current_user]);

  const [errors, setErros] = useState({});
  const required_fields = [
    "firstName",
    "lastName",
    "currentLocation",
    "employmentStatus",
    "employmentType",
    "phoneNumber",
    "workSetup",
    "salaryMin",
    "salaryMax",
    "importantForYou",
    "jobFunction",
    "preferredTitle",
    "skills",
    "softSkills",
  ];

  const validate = () => {
    let errs = {};

    required_fields.forEach((key) => {
      if (!current_user[key] || current_user[key]?.length === 0) {
        errs[key] = "Required";
      }

      if (!current_user?.city || !current_user?.region) {
        errs["currentLocation"] = "Kindly include your city or region.";
      }
    });

    setErros(errs);
    console.log("errors are here => ", errs);
    return Object.keys(errs)?.length > 0 ? false : true;
  };

  const [salaryMinError, setSalaryMinError] = useState(false);
  const [salaryMinError2, setSalaryMinError2] = useState(false);
  const [salaryMaxError, setSalaryMaxError] = useState(false);
  const [salaryRangeError, setSalaryRangeError] = useState(false);

  const validateSalary = () => {
    let valid = [];
    if (!current_user?.salaryMin && current_user?.salaryMin != 0) {
      setSalaryMinError(true);
      valid.push(false);
    } else {
      if (current_user?.salaryMin < 1) {
        setSalaryMinError2(true);
        valid.push(false);
      } else {
        setSalaryMinError2(false);
        valid.push(true);
      }
      setSalaryMinError(false);
      valid.push(true);
    }

    if (!current_user?.salaryMax) {
      setSalaryMaxError(true);
      valid.push(false);
    } else {
      setSalaryMaxError(false);
      valid.push(true);
    }

    if (current_user?.salaryMax < current_user?.salaryMin) {
      setSalaryMinError2(true);
      valid.push(false);
    } else {
      setSalaryMinError2(false);
      valid.push(true);
    }

    return valid.includes(false) ? false : true;
  };

  const handleSaveProfile = (preview = false) => {
    const is_valid = validate();

    setIsSubmitting(true);
    if (is_valid) {
      const is_valid_salary = validateSalary();
      if (is_valid_salary) {
        console.log("hehehe 3", phoneNumber);
        let phone = phoneNumber
          ? phoneNumber.includes("+")
            ? phoneNumber
            : "+" + phoneNumber
          : current_user?.phoneNumber;

        if (country_code && phoneNumber) {
          let temp = country_code?.split(":");
          console.log("hehehe 1", `${temp[1]}${phoneNumber}`);
          phone = `${temp[1]}${phoneNumber}`;
          console.log("hehehe 5", temp[1], current_user.phoneNumber);
        }
        console.log("hehehe 4", current_user.phoneNumber);

        console.log("hehehe", current_user);
        userRequest
          .updateApplicant({
            ...current_user,
            phoneNumber: phone,
            // elevatorPitch: undefined,
            salaryCurrency: current_user?.salaryCurrency || currency,
          })
          .then(() => {
            props.getUser();
            if (_location?.state?.fromCreate || preview) {
              setOpenPreview(true);
            }
          })
          .finally(() => {
            setShowAlert({ message: "Profile updated", type: "success" });
            setIsSubmitting(false);
          });
      } else {
        setShowAlert({
          message: "Please check all required fields!",
          type: "error",
        });
        setIsSubmitting(false);
      }
    } else {
      setShowAlert({
        message: "Please fill out all required fields!",
        type: "error",
      });
      setIsSubmitting(false);
    }
  };

  const [skillsCopy2, setSkillsCopy2] = useState(
    current_user?.skills?.map((item) => {
      return {
        label: item,
        value: item,
      };
    })
  );

  const handleChange = (selectedOption) => {
    let skillsCopy = [];
    selectedOption.forEach((item) => {
      skillsCopy.push(item.value);
    });
    setCurrentUser({ ...current_user, skills: skillsCopy });
  };

  const loadOptions = debounce((inputValue, callback) => {
    if (!!inputValue) {
      configRequest
        .searchSkills({ search: inputValue })
        .then((res) => {
          //console.log(res.data);
          const selected = current_user?.skills?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          const searched = res?.data?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          let combined;
          if (searched?.length === 0) {
            combined = _.uniqBy(
              [...selected, { label: inputValue, value: inputValue }],
              function (e) {
                return e.value;
              }
            );
          } else {
            combined = _.uniqBy([...selected, ...searched], function (e) {
              return e.value;
            });
          }
          callback(combined.sort((a, b) => (a.value < b.value ? -1 : 1)));
          setIsSearchingSkill(false);
        })
        .catch((err) => {
          console.log(err);
          setIsSearchingSkill(false);
        });
    } else {
      const selected = current_user?.skills?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      const combined = _.uniqBy([...selected, ...skill_list], function (e) {
        return e.value;
      });
      if (current_user?.skills?.length > 0) {
        callback(combined.sort((a, b) => (a.value < b.value ? -1 : 1)));
      } else {
        callback(skill_list);
      }
      setIsSearchingSkill(false);
    }
  }, 1000);

  const countries = [
    {
      value: "Philippines",
      label: "Philippines",
    },
    {
      value: "Singapore",
      label: "Singapore",
    },
    {
      value: "Malaysia",
      label: "Malaysia",
    },
  ];

  const currencies = [
    {
      value: "PHP",
      label: "PHP",
    },
    {
      value: "SGD",
      label: "SGD",
    },
    {
      value: "USD",
      label: "USD",
    },
  ];

  const employmentStatusList = [
    {
      value: "Actively Looking",
      label: "Actively Looking",
    },
    {
      value: "Employed",
      label: "Employed",
    },
    {
      value: "Exploring My Options",
      label: "Exploring My Options",
    },
  ];

  const pronouns = [
    {
      value: "He/Him",
      label: "He/Him",
    },
    {
      value: "She/Her",
      label: "She/Her",
    },
    {
      value: "They/Them",
      label: "They/Them",
    },
    {
      value: "I'd rather not say",
      label: "I'd rather not say",
    },
  ];

  const employmentTypeList = [
    {
      value: "Full-Time",
      label: "Full-Time",
    },
    {
      value: "Part-Time",
      label: "Part-Time",
    },
    {
      value: "Freelance",
      label: "Freelance",
    },
    {
      value: "Project-Based",
      label: "Project-Based",
    },
    {
      value: "Internship",
      label: "Internship",
    },
    {
      value: "High School Internship",
      label: "High School Internship",
    },
  ];

  const workSetupList = [
    {
      value: "On-Site",
      label: "On-Site",
    },
    {
      value: "Hybrid",
      label: "Hybrid",
    },
    {
      value: "Remote",
      label: "Remote",
    },
  ];

  const valuesList = [
    {
      value: "Mentoring",
      label: "Mentoring",
    },
    {
      value: "Accountability",
      label: "Accountability",
    },
    {
      value: "Making An Impact",
      label: "Making An Impact",
    },
    {
      value: "Work From Anywhere",
      label: "Work From Anywhere",
    },
    {
      value: "Financial Stability",
      label: "Financial Stability",
    },
    {
      value: "Creative Collaboration",
      label: "Creative Collaboration",
    },
    {
      value: "Inclusivity & Diversity",
      label: "Inclusivity & Diversity",
    },
    {
      value: "Exciting Challenges",
      label: "Exciting Challenges",
    },
    {
      value: "Work Hard Play Hard",
      label: "Work Hard Play Hard",
    },
  ];

  const softSkillsList = [
    { label: "Analytical", value: "Analytical" },
    { label: "Flexible", value: "Flexible" },
    { label: "Resourceful", value: "Resourceful" },
    { label: "Hustler", value: "Hustler" },
    { label: "Creative Genius", value: "Creative Genius" },
    { label: "Problem Solver", value: "Problem Solver" },
    { label: "Team Player", value: "Team Player" },
    { label: "Sole Warrior", value: "Sole Warrior" },
    { label: "Jack Of All Trades", value: "Jack Of All Trades" },
    { label: "Self-Starter", value: "Self-Starter" },
    { label: "People Person", value: "People Person" },
    { label: "Detail Oriented", value: "Detail Oriented" },
    { label: "Process Driven", value: "Process Driven" },
    { label: "Persistent", value: "Persistent" },
    { label: "Service Oriented", value: "Service Oriented" },
    { label: "Leader", value: "Leader" },
  ];

  const education_list = [
    {
      label: "No Formal Education",
      value: "No Formal Education",
    },
    { label: "Primary", value: "Primary" },
    { label: "Secondary", value: "Secondary" },
    {
      label: "Tertiary/Vocational Course/ITE/ITI",
      value: "Tertiary/Vocational Course/ITE/ITI",
    },
    { label: "Diploma", value: "Diploma" },
    { label: "Under Graduate", value: "Under Graduate" },
    { label: "Bachelor's Degree", value: "Bachelor's Degree" },
    {
      label: "Master's Degree Holder/Other Higher Degree",
      value: "Master's Degree Holder/Other Higher Degree",
    },
  ];

  const handleClick = (value) => {
    console.log(value);
  };

  const handleEmploymentStatus = (value) => {
    setEmploymentStatus(value);
  };

  const handleEmploymentType = (value) => {
    var employmentTypeCopy = [...employmentType];
    if (!employmentTypeCopy.includes(value)) {
      if (employmentType.length < 2) {
        employmentTypeCopy = [...employmentType, value];
      }
    } else {
      employmentTypeCopy.splice(employmentType?.indexOf(value), 1);
    }
    setEmploymentType(employmentTypeCopy);
  };

  const handleWorkSetup = (value) => {
    var workSetupCopy = [...workSetup];
    if (!workSetupCopy.includes(value)) {
      if (workSetup.length < 2) {
        workSetupCopy = [...workSetup, value];
      }
    } else {
      workSetupCopy.splice(workSetup?.indexOf(value), 1);
    }
    setWorkSetup(workSetupCopy);
  };

  const handleValues = (value) => {
    var valuesCopy = [...values];
    if (!valuesCopy.includes(value)) {
      if (values.length < 3) {
        valuesCopy = [...values, value];
      }
    } else {
      valuesCopy.splice(values?.indexOf(value), 1);
    }
    setValues(valuesCopy);
  };

  const handleSetSoftSkills = (value) => {
    var softSkillsCopy = [...softSkills];
    if (!softSkillsCopy.includes(value)) {
      if (softSkills.length < 10) {
        softSkillsCopy = [...softSkills, value];
      }
    } else {
      softSkillsCopy.splice(softSkills?.indexOf(value), 1);
    }
    setSoftSkills(softSkillsCopy);
  };

  const [showEducationModal, setEducationModal] = useState(false);
  const handleOpenEducation = (type) => setEducationModal(type);
  const handleCloseEducation = () => {
    const clearErrors = {
      ducAchievements: null,
      educCurrentStudy: null,
      educEndDate: null,
      educField: null,
      educName: null,
      educStartDate: null,
      educationType: null,
    };
    setErros({ ...errors, ...clearErrors });
    setEducationModal(false);
    setTempEducation({
      ducAchievements: null,
      educCurrentStudy: null,
      educEndDate: null,
      educField: null,
      educName: null,
      educStartDate: null,
      educationType: null,
    });
  };

  const [showExpModal, setExpModal] = useState(false);
  const handleOpenExpModal = (type) => setExpModal(type);
  const handleCloseExpModal = () => {
    const clearErrors = {
      companyName: null,
      jobTitle: null,
      location: null,
      responsibilities: null,
      startDate: null,
      endDate: null,
    };
    setErros({ ...errors, ...clearErrors });
    setExpModal(false);
    setTempExp({
      companyName: null,
      jobTitle: null,
      location: null,
      responsibilities: null,
      achievements: "",
      startDate: null,
      endDate: null,
      expWorkingHere: null,
    });
  };

  const [showPortfolioModal, setPortfolioModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (openPreview) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openPreview]);

  const handleOpenPortfolioModal = (type) => setPortfolioModal(type);
  const handleClosePortfolioModal = () => {
    setPortfolioModal(false);
    setTempLink({
      date: moment().format("MM/DD/YYYY"),
      link: null,
      title: null,
    });
  };

  const handleOpenFileModal = (type) => setShowFileModal(type);

  const [currentVid, setCurrentVid] = useState(
    current_user?.elevatorPitch || null
  );

  const handleCloseFileModal = () => {
    setShowFileModal(false);
    setTempLink({
      date: moment().format("MM/DD/YYYY"),
      link: null,
      title: null,
      file: null,
    });
    uploadPortfolioRef.current.value = "";
  };

  useEffect(() => {
    console.log("user => ", props?.User?.user);
    if (props?.User?.user?.softSkills) {
      // remove skills that are outdated from list
      let softSkills = [];
      softSkillsList.forEach((s_skills) => {
        if (props?.User?.user?.softSkills?.includes(s_skills.value)) {
          softSkills.push(s_skills.value);
        }
      });

      setCurrentUser({
        ...current_user,
        softSkills,
      });
    }
  }, []);

  useEffect(() => {
    if (isFetched) {
      console.log("user => ", props?.User?.user);
      if (props?.User?.user?.softSkills) {
        // remove skills that are outdated from list
        let softSkills = [];
        softSkillsList.forEach((s_skills) => {
          if (props?.User?.user?.softSkills?.includes(s_skills.value)) {
            softSkills.push(s_skills.value);
          }
        });

        setCurrentUser({
          ...user,
          softSkills,
        });
      }
    }
  }, [isFetching]);

  const [tempLink, setTempLink] = useState({
    date: moment().format("MM/DD/YYYY"),
    link: null,
    title: null,
  });

  const [tempFiles, setTempFiles] = useState({
    date: moment().format("MM/DD/YYYY"),
    link: null,
    title: null,
    file: null,
  });

  const [tempEducation, setTempEducation] = useState({
    educAchievements: null,
    educCurrentStudy: null,
    educEndDate: null,
    educField: null,
    educName: null,
    educStartDate: null,
    educationType: null,
  });

  const [tempExp, setTempExp] = useState({
    companyName: null,
    jobTitle: null,
    location: null,
    responsibilities: null,
    achievements: "",
    startDate: null,
    endDate: null,
    expWorkingHere: false,
  });

  const [currentVideoLink, setCurrentVideoLink] = useState(null);
  const [playVideo, setPlayVideo] = useState(null);
  const handlePlayVideo = () => {
    setPlayVideo(!playVideo);
  };
  const playVid = (link) => {
    setCurrentVideoLink(link);
    handlePlayVideo();
  };

  const uploadPortfolioRef = useRef();
  const uploadReel = useRef();
  const uploadProfilePicRef = useRef();
  const [tempProfilePic, setTempProfilePic] = useState(null);

  const getYearsOfExp = (item) => {
    if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
      const sorted =
        item?.experienceHistory &&
        item?.experienceHistory?.sort(function (a, b) {
          return new Date(a.startDate) - new Date(b.startDate);
        });
      const startDate = new Date(sorted[0]?.startDate);
      const diff = getYearDifference(startDate);
      return getYearsOfExpCategory(diff);
    } else {
      return null;
    }
  };

  const handleRecordJobreel = () => {
    setTimeout(function () {
      window.location = `https://onelink.to/fsapmr`;
    }, 300);

    window.location = `joblu-app://`;
  };

  const location = useLocation();

  const countryCodes = require("country-codes-list");

  const myCountryCodesObject = countryCodes.customList(
    "countryCode",
    "{countryNameEn}:+{countryCallingCode}"
  );

  const [countryCodesList, setCountryCodesList] = useState(
    Object.values(myCountryCodesObject).map((item) => {
      var temp = item.split(":");
      return {
        label: `${temp[0]} (${temp[1]})`,
        value: item,
      };
    })
  );

  const ccLength = current_user?.phoneNumber
    ? current_user?.phoneNumber?.length - 10
    : 0;

  const [country_code, setCountry_Code] = useState(
    current_user?.phoneNumber
      ? getCountryCodeFromNumber(
          countryCodesList,
          current_user?.phoneNumber?.substr(0, ccLength)
        )
      : null
  );

  const [tempCC, setTempCC] = useState(
    current_user?.phoneNumber
      ? {
          value: current_user?.phoneNumber
            ? getCountryCodeFromNumber(
                countryCodesList,
                current_user?.phoneNumber?.substr(0, ccLength)
              )
            : null,
          label: current_user?.phoneNumber
            ? getCountryCodeFromNumber(
                countryCodesList,
                current_user?.phoneNumber?.substr(0, ccLength)
              )
            : null,
        }
      : null
  );

  const [keyCounter, setKeyCounter] = useState(0);

  useEffect(() => {
    let temp = {
      value: current_user?.phoneNumber
        ? getCountryCodeFromNumber(
            countryCodesList,
            current_user?.phoneNumber?.substr(0, ccLength)
          )
        : null,
      label: current_user?.phoneNumber
        ? getCountryCodeFromNumber(
            countryCodesList,
            current_user?.phoneNumber?.substr(0, ccLength)
          )
        : null,
    };

    console.log("asdasd", temp);
    setTempCC(temp);
  }, [country_code]);
  const [error_CountryCode, setError_CountryCode] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState(
    current_user.phoneNumber
      ? current_user?.phoneNumber?.substr(
          ccLength,
          current_user?.phoneNumber?.length
        )
      : null
  );
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);

  const handleUploadVideo = () => {
    document.getElementById("upload-vid")?.click();
  };

  const [videoLoading, setVideoLoading] = useState(false);
  return (
    <>
      <Dialog
        open={showProfilePicModal}
        fullWidth
        maxWidth={"sm"}
        style={{ zIndex: "9999999" }}
      >
        <Box style={{ padding: "3rem" }}>
          <Box style={{ position: "absolute", right: 10, top: 10 }}>
            <Close
              onClick={() => {
                setShowProfilePicModal(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box style={{ marginBottom: "1.5rem", textAlign: "center" }}>
            {!!imgSrc ? (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            ) : (
              <>
                <img
                  src={current_user?.profileUrl || DefaultImg}
                  width={"100%"}
                  alt=""
                />
              </>
            )}
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop?.width,
                height: completedCrop?.height,
                display: "none",
              }}
            />
            <input
              id="upload-pic"
              type="file"
              accept="image/*"
              hidden
              onChange={onSelectFile}
            />
          </Box>
          <Box style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Button
              onClick={() => {
                handleUploadProfileURL();
              }}
              variant="contained"
              color="primary"
              style={{ textTransform: "none", width: 200 }}
            >
              Upload Photo
            </Button>{" "}
          </Box>
          {!!imgSrc && (
            <Box style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  previewCanvasRef?.current?.toBlob((blob) => {
                    console.log(blobUrlRef.current);
                    if (!blob) {
                      throw new Error("Failed to create blob");
                    }
                    if (blobUrlRef.current) {
                      URL.revokeObjectURL(blobUrlRef.current);
                    }
                    blobUrlRef.current = URL.createObjectURL(blob);
                  });
                  var e = previewCanvasRef?.current?.toDataURL("image/jpeg");
                  handleSetProfileUrl(e);
                  // setShowProfilePicModal(false);
                }}
                variant="contained"
                color="primary"
                style={{ textTransform: "none", width: 200 }}
                disabled={cropping}
              >
                Set Profile Photo
              </Button>{" "}
            </Box>
          )}
        </Box>
      </Dialog>
      {/* <input
        accept="image/png, image/jpeg, image/jpg"
        hidden
        ref={uploadProfilePicRef}
        onChange={(e) => {
          //setTempProfilePic(e.target.files[0]);
          let payload = new FormData();
          payload.append("document", e.target.files[0]);
          userRequest.uploadFile(payload).then((res) => {
            console.log("res profile pic", res.data);
            setCurrentUser({
              ...current_user,
              profileUrl: res.data?.documents,
            });
          });
        }}
        type="file"
      /> */}
      <div>
        <Dialog
          open={openPreview}
          onClose={handleClose}
          scroll={"body"}
          maxWidth={maxWidth}
          fullWidth
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "hidden!important",
            },
          }}
        >
          <Box>
            <Grid container spacing={0}>
              <Grid item xs={12} className={`${app_classes?.margin.b3}`}>
                <Card
                  variant="outlined"
                  className={`${app_classes.padding.a4} ${app_classes.border.border_radius_10} ${app_classes.text.center} ${app_classes.margin.b2}`}
                >
                  <Box className={`${app_classes.text.right}`}>
                    <CloseIcon
                      className={`${app_classes.cursor.pointer}`}
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </Box>
                  <Box className={`${app_classes.margin.b2}`}>
                    <JobluTypography.H2
                      bold
                      primary
                      className={`${app_classes.text}`}
                    >
                      Profile Preview
                    </JobluTypography.H2>
                  </Box>
                  <Box className={`${app_classes.margin.b2}`}>
                    <JobluTypography.H6
                      primary
                      className={`${app_classes.text}`}
                    >
                      This is how employers will see your Joblu profile.
                    </JobluTypography.H6>
                  </Box>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        className={`${app_classes.button.primary_outline}`}
                        onClick={handleClose}
                      >
                        Go back and add changes
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className={`${app_classes.button.primary}`}
                        onClick={() => {
                          if (location?.state?.refId) {
                            navigate(`/job/${location?.state?.refId}`);
                          } else {
                            handleSaveProfile();
                            handleClose();
                            navigate("/dashboard");
                          }
                        }}
                      >
                        Let's explore jobs!
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <ProfilePreview current_user={current_user} borderRadius />
              </Grid>
              <Grid item xs={12} md={4} style={{ display: "none" }}>
                <Card
                  style={{
                    height: 470,
                  }}
                  variant="outlined"
                  className={`${app_classes.padding.a3} ${app_classes.border.border_radius_10} ${app_classes.position.relative} ${app_classes.overflow.hidden}`}
                >
                  <Box
                    style={{}}
                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center} ${app_classes.text.center} ${edit_profile_classes.see_more}`}
                  >
                    <Box className={`${app_classes.width.w100} `}>
                      <JobluTypography.Body semiBold primary>
                        See More
                      </JobluTypography.Body>
                    </Box>
                  </Box>
                  <Box>
                    <img
                      src={current_user?.profileUrl || DefaultImg}
                      className={`${edit_profile_classes.default_img} ${app_classes.margin.b1}`}
                      style={{
                        width: 80,
                        height: 80,
                        objectFit: "cover",
                        background: "#FFFFFF",
                      }}
                    />
                  </Box>
                  <Box>
                    <JobluTypography.H6
                      primary
                      semiBold
                      className={`${app_classes.text}`}
                      onclick={() => {
                        console.log(current_user);
                      }}
                    >
                      {current_user?.firstName} {current_user?.lastName}
                    </JobluTypography.H6>
                  </Box>
                  <Box className={`${app_classes.margin.b3}`}>
                    <JobluTypography.Body secondary>
                      {current_user?.jobFunction}
                    </JobluTypography.Body>
                  </Box>
                  <Box className={`${app_classes.margin.b3}`}>
                    <Box>
                      <JobluTypography.Caption>
                        <img src={LocationIconM} width={16} />{" "}
                        {current_user?.country}
                      </JobluTypography.Caption>
                    </Box>
                    {current_user?.workSetup?.length > 0 && (
                      <Box>
                        <JobluTypography.Caption>
                          <img src={WorkSetupIconM} width={16} />{" "}
                          {current_user?.workSetup?.join(", ")}
                        </JobluTypography.Caption>
                      </Box>
                    )}
                    {current_user?.employmentType?.length > 0 && (
                      <Box>
                        <JobluTypography.Caption>
                          <img src={employmentTypeIconM} width={16} />{" "}
                          {current_user?.employmentType?.join(", ")}
                        </JobluTypography.Caption>
                      </Box>
                    )}
                    {current_user?.educationHistory?.length > 0 && (
                      <Box>
                        <JobluTypography.Caption>
                          <img src={EducationIconM} width={16} />{" "}
                          {current_user?.educationHistory
                            ?.sort(function (a, b) {
                              return (
                                new Date(b.educStartDate) -
                                new Date(a.educStartDate)
                              );
                            })
                            .map((educ, index) => {
                              if (index === 0) {
                                {
                                  return educ?.educationType;
                                }
                              }
                            })}
                          {/* {current_user?.educationHistory[0]?.educati÷onType} */}
                        </JobluTypography.Caption>
                      </Box>
                    )}
                    {getYearsOfExp(current_user) &&
                      getYearsOfExp(current_user) !== null && (
                        <Box>
                          <JobluTypography.Caption>
                            <img src={ExperienceIconM} width={16} />{" "}
                            {`${getYearsOfExp(
                              current_user
                            )} Years of Experience`}
                          </JobluTypography.Caption>
                        </Box>
                      )}
                  </Box>
                  {current_user?.skills?.length > 0 && (
                    <>
                      <Box className={`${app_classes.margin.b2}`}>
                        <JobluTypography.Body semiBold primary>
                          <img src={TechnicalSkillsIcon} width={16} /> Technical
                          Skills
                        </JobluTypography.Body>
                      </Box>
                      <Box>
                        {current_user?.skills?.map((item) => {
                          return (
                            <Chip
                              label={item}
                              className={`${edit_profile_classes.chip_active}`}
                            />
                          );
                        })}
                      </Box>
                    </>
                  )}
                </Card>
              </Grid>
              <Grid item xs={12} md={8} style={{ display: "none" }}>
                <Card
                  variant="outlined"
                  className={`${app_classes.padding.a3} ${app_classes.border.border_radius_10}`}
                >
                  <Box
                    className={`${app_classes.display.display_flex} ${
                      app_classes.flexBox.justify_content_between
                    } ${app_classes.border.border_all} ${
                      app_classes.padding.a2
                    } ${app_classes.margin.b3} ${
                      width < 640
                        ? app_classes.flexBox.flex_direction_column
                        : app_classes.flexBox.flex_direction_row
                    }`}
                  >
                    <Box className={`${app_classes.display.display_flex}`}>
                      <Box className={`${app_classes.margin.r3}`}>
                        <img
                          src={current_user?.profileUrl || DefaultImg}
                          className={`${edit_profile_classes.default_img}`}
                          style={{
                            width: 96,
                            height: 96,
                            objectFit: "cover",
                            background: "#FFFFFF",
                          }}
                          alt=""
                        />
                      </Box>
                      <Box>
                        <Box>
                          <JobluTypography.H4 primary bold>
                            {current_user?.firstName} {current_user?.lastName}
                          </JobluTypography.H4>
                        </Box>
                        <Box>
                          <JobluTypography.Body secondary>
                            {current_user?.preferredTitle}
                          </JobluTypography.Body>
                        </Box>
                        <Box>
                          <JobluTypography.Body secondary>
                            {current_user?.jobFunction}
                          </JobluTypography.Body>
                        </Box>
                        <Box>
                          <JobluTypography.Body secondary>
                            {current_user?.country}
                          </JobluTypography.Body>
                        </Box>
                      </Box>
                    </Box>
                    {current_user?.elevatorPitch && (
                      <>
                        <Box
                          className={`${
                            width < 640 && app_classes.text.center
                          } ${width < 640 && app_classes.margin.t3}`}
                        >
                          <Chip
                            className={`${edit_profile_classes.jobreel_button}`}
                            onClick={() => {
                              playVid(current_user?.elevatorPitch);
                            }}
                            icon={<PlayCircleIcon color="#FFFFFF" />}
                            label="JobReel"
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                  {/* EXPERIENCE */}
                  {current_user?.experienceHistory?.length > 0 && (
                    <>
                      <Box
                        className={`${app_classes.border.border_all} ${app_classes.padding.a2} ${app_classes.margin.b3} ${app_classes.display.display_flex}`}
                      >
                        <Box className={`${app_classes.margin.r3}`}>
                          <img src={ExperienceIcon} width={24} />
                        </Box>
                        <Box className={`${app_classes.width.w100}`}>
                          <Box className={`${app_classes.margin.b2}`}>
                            <JobluTypography.H6 semiBold primary>
                              EXPERIENCE
                            </JobluTypography.H6>
                          </Box>
                          {/* LOOP STARTS HERE */}
                          {current_user?.experienceHistory
                            ?.sort(function (a, b) {
                              return (
                                new Date(b.startDate) - new Date(a.startDate)
                              );
                            })
                            .map((exp) => {
                              return (
                                <>
                                  <Box>
                                    <Box className={`${app_classes.margin.b2}`}>
                                      <Box>
                                        <JobluTypography.Body primary bold>
                                          {exp?.companyName}
                                        </JobluTypography.Body>
                                      </Box>
                                      <Box>
                                        <JobluTypography.Caption>
                                          {exp?.jobTitle}
                                        </JobluTypography.Caption>
                                      </Box>
                                      <Box>
                                        <JobluTypography.Caption>
                                          {exp?.location}
                                        </JobluTypography.Caption>
                                      </Box>
                                      <Box>
                                        <JobluTypography.Caption>
                                          {moment(exp.startDate).format(
                                            "MMM YYYY"
                                          )}{" "}
                                          {!!exp?.endDate &&
                                          !exp?.expWorkingHere
                                            ? `- ${moment(exp.endDate).format(
                                                "MMM YYYY"
                                              )}`
                                            : `- Present`}
                                        </JobluTypography.Caption>
                                      </Box>
                                    </Box>
                                    {exp?.responsibilities && (
                                      <>
                                        <Box>
                                          <JobluTypography.Caption
                                            primary
                                            semiBold
                                          >
                                            Responsibilities
                                          </JobluTypography.Caption>
                                        </Box>
                                        <Box
                                          className={`${app_classes.margin.b2}`}
                                        >
                                          <JobluTypography.Caption>
                                            {exp?.responsibilities}
                                          </JobluTypography.Caption>
                                        </Box>
                                      </>
                                    )}

                                    {exp?.achievements && (
                                      <>
                                        <Box>
                                          <JobluTypography.Caption
                                            primary
                                            semiBold
                                          >
                                            Achievements
                                          </JobluTypography.Caption>
                                        </Box>
                                        <Box>
                                          <JobluTypography.Caption>
                                            {exp?.achievements}
                                          </JobluTypography.Caption>
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                  {current_user?.experienceHistory?.length >
                                    1 && <hr />}
                                </>
                              );
                            })}

                          {/* LOOP ENDS HERE */}
                        </Box>
                      </Box>
                    </>
                  )}
                  {/* END OF EXPERIENCE */}
                  {/* EDUCATION */}
                  {current_user?.educationHistory?.length > 0 && (
                    <>
                      <Box
                        className={`${app_classes.border.border_all} ${app_classes.padding.a2} ${app_classes.margin.b3} ${app_classes.display.display_flex} `}
                      >
                        <Box className={`${app_classes.margin.r3}`}>
                          <img src={EducationIcon} width={24} />
                        </Box>
                        <Box className={`${app_classes.width.w100}`}>
                          <Box className={`${app_classes.margin.b2}`}>
                            <JobluTypography.H6 semiBold primary>
                              EDUCATION
                            </JobluTypography.H6>
                          </Box>
                          {/* LOOP STARTS HERE */}
                          {current_user?.educationHistory?.map((educ) => {
                            return (
                              <>
                                <Box className={``}>
                                  <Box className={`${app_classes.margin.b2}`}>
                                    <Box>
                                      <JobluTypography.Body primary bold>
                                        {educ?.educName}
                                      </JobluTypography.Body>
                                    </Box>
                                    <Box>
                                      <JobluTypography.Caption>
                                        {educ?.educationType}
                                      </JobluTypography.Caption>
                                    </Box>
                                    <Box>
                                      <JobluTypography.Caption>
                                        {educ?.educField}
                                      </JobluTypography.Caption>
                                    </Box>
                                    <Box>
                                      <JobluTypography.Caption>
                                        {moment(educ.educStartDate).format(
                                          "MMM YYYY"
                                        )}{" "}
                                        {!!educ?.educEndDate &&
                                        !educ.currentStudy
                                          ? `- ${moment(
                                              educ.educEndDate
                                            ).format("MMM YYYY")}`
                                          : `- Preset`}
                                      </JobluTypography.Caption>
                                    </Box>
                                  </Box>
                                  {educ?.educAchievements && (
                                    <>
                                      <Box>
                                        <JobluTypography.Caption
                                          primary
                                          semiBold
                                        >
                                          Achievements
                                        </JobluTypography.Caption>
                                      </Box>
                                      <Box>
                                        <JobluTypography.Caption>
                                          {educ?.educAchievements}
                                        </JobluTypography.Caption>
                                      </Box>
                                    </>
                                  )}
                                </Box>
                                {current_user?.educationHistory?.length > 1 && (
                                  <hr className={`${app_classes.width.w100}`} />
                                )}
                              </>
                            );
                          })}

                          {/* LOOP ENDS HERE */}
                        </Box>
                      </Box>
                    </>
                  )}
                  {/* END OF EDUCATION */}
                  {/* TECHNICAL SKILLS */}
                  {current_user?.skills?.length > 0 && (
                    <>
                      <Box
                        className={`${app_classes.border.border_all} ${app_classes.padding.a2} ${app_classes.margin.b3} ${app_classes.display.display_flex}`}
                      >
                        <Box className={`${app_classes.margin.r3}`}>
                          <img src={TechnicalSkillsIcon} width={24} />
                        </Box>
                        <Box>
                          <Box className={`${app_classes.margin.b2}`}>
                            <JobluTypography.H6 semiBold primary>
                              Technical Skills
                            </JobluTypography.H6>
                          </Box>
                          {/* LOOP STARTS HERE */}
                          <Box>
                            {current_user?.skills?.map((item) => {
                              return (
                                <Chip
                                  label={item}
                                  className={`${edit_profile_classes.chip_active}`}
                                />
                              );
                            })}
                          </Box>
                          {/* LOOP ENDS HERE */}
                        </Box>
                      </Box>
                    </>
                  )}
                  {/* END OF TECHNICAL SKILLS */}
                  {/* SOFT SKILLS */}
                  {current_user?.softSkills?.length > 0 && (
                    <>
                      <Box
                        className={`${app_classes.border.border_all} ${app_classes.padding.a2} ${app_classes.margin.b3} ${app_classes.display.display_flex}`}
                      >
                        <Box className={`${app_classes.margin.r3}`}>
                          <img src={SoftSkillsIcon} width={24} />
                        </Box>
                        <Box>
                          <Box className={`${app_classes.margin.b2}`}>
                            <JobluTypography.H6 semiBold primary>
                              Soft Skills
                            </JobluTypography.H6>
                          </Box>
                          {/* LOOP STARTS HERE */}
                          <Box>
                            {current_user?.softSkills?.map((item) => {
                              return (
                                <Chip
                                  label={item}
                                  className={`${edit_profile_classes.chip_active}`}
                                />
                              );
                            })}
                          </Box>
                          {/* LOOP ENDS HERE */}
                        </Box>
                      </Box>{" "}
                    </>
                  )}
                  {/* END OF SOFT SKILLS */}
                  {/* VALUES*/}
                  {current_user?.importantForYou?.length > 0 && (
                    <>
                      <Box
                        className={`${app_classes.border.border_all} ${app_classes.padding.a2} ${app_classes.margin.b3} ${app_classes.display.display_flex}`}
                      >
                        <Box className={`${app_classes.margin.r3}`}>
                          <img src={ValuesIcon} width={24} />
                        </Box>
                        <Box>
                          <Box className={`${app_classes.margin.b2}`}>
                            <JobluTypography.H6 semiBold primary>
                              What I Value Most
                            </JobluTypography.H6>
                          </Box>
                          {/* LOOP STARTS HERE */}
                          <Box>
                            {current_user?.importantForYou?.map((item) => {
                              return (
                                <Chip
                                  label={item}
                                  className={`${edit_profile_classes.chip_active}`}
                                />
                              );
                            })}
                          </Box>
                          {/* LOOP ENDS HERE */}
                        </Box>
                      </Box>
                    </>
                  )}
                  {/* END OF VALUES */}
                  <Box
                    className={`${app_classes.border.border_all} ${app_classes.padding.a2} ${app_classes.margin.b3} ${app_classes.display.display_flex}`}
                  >
                    <Box className={`${app_classes.margin.r3}`}>
                      <img src={EmploymentIcon} width={24} />
                    </Box>
                    <Box>
                      {current_user?.employmentStatus && (
                        <>
                          <Box className={`${app_classes.margin.b2}`}>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.H6 semiBold primary>
                                Employment Status
                              </JobluTypography.H6>
                            </Box>
                            <Box>
                              <Chip
                                label={`${current_user?.employmentStatus}`}
                                className={`${edit_profile_classes.chip_active}`}
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                      {current_user?.employmentType?.length > 0 && (
                        <>
                          <Box className={`${app_classes.margin.b2}`}>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.H6 semiBold primary>
                                EmploymentType
                              </JobluTypography.H6>
                            </Box>
                            <Box>
                              {current_user?.employmentType?.map((item) => {
                                return (
                                  <Chip
                                    label={`${item}`}
                                    className={`${edit_profile_classes.chip_active}`}
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                        </>
                      )}
                      {current_user?.workSetup?.length > 0 && (
                        <>
                          <Box>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.H6 semiBold primary>
                                Work Setup
                              </JobluTypography.H6>
                            </Box>
                            <Box>
                              {current_user?.workSetup?.map((item) => {
                                return (
                                  <Chip
                                    label={`${item}`}
                                    className={`${edit_profile_classes.chip_active}`}
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </div>

      <Dialog
        open={!!showEducationModal}
        onClose={handleCloseEducation}
        scroll={`body`}
        maxWidth="sm"
        fullWidth
      >
        <Box
          className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          style={{ minWidth: 400 }}
        >
          <JobluTypography.H6 align="center">
            {showEducationModal} Education
          </JobluTypography.H6>
        </Box>
        <Box className={`${app_classes.padding.a3}`}>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              School or Name of Institute *
            </JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              placeholder='Ex. "University of the Philippines"'
              fullWidth
              onChange={(e) => {
                setTempEducation({
                  ...tempEducation,
                  educName: e.target.value,
                });
              }}
              value={tempEducation?.educName}
              error={!!errors?.educName}
              helperText={errors?.educName}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Degree *</JobluTypography.Body>

            <FormControl fullWidth size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tempEducation?.educationType}
                onChange={(e) => {
                  setTempEducation({
                    ...tempEducation,
                    educationType: e.target.value,
                  });
                }}
                error={!!errors?.educationType}
                helperText={errors?.educationType}
                variant="outlined"
              >
                {education_list?.map((item) => {
                  return <MenuItem value={item?.value}>{item?.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
            {!!errors?.educationType && (
              <span
                className="mt-0"
                style={{
                  fontFamily: "Open sans",
                  color: "#f44336",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
              >
                {errors?.educationType}
              </span>
            )}
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              Major or Field of Study *
            </JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              placeholder='Ex. "BS Accountancy"'
              fullWidth
              onChange={(e) => {
                setTempEducation({
                  ...tempEducation,
                  educField: e.target.value,
                });
              }}
              value={tempEducation?.educField}
              error={!!errors?.educField}
              helperText={errors?.educField}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <JobluTypography.Body semiBold>
                  Start Date *
                </JobluTypography.Body>
                <CustomTextFieldV1
                  fullWidth
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setTempEducation({
                      ...tempEducation,
                      educStartDate: moment(e.target.value).format(
                        "MM/DD/YYYY"
                      ),
                    });
                  }}
                  defaultValue={moment(tempEducation?.educStartDate).format(
                    "YYYY-MM-DD"
                  )}
                  error={!!errors?.educStartDate}
                  helperText={errors?.educStartDate}
                />
              </Grid>
              {!tempEducation?.educCurrentStudy && (
                <Grid item xs={6}>
                  <JobluTypography.Body semiBold>
                    End Date *
                  </JobluTypography.Body>
                  <CustomTextFieldV1
                    fullWidth
                    type="date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setTempEducation({
                        ...tempEducation,
                        educEndDate: moment(e.target.value).format(
                          "MM/DD/YYYY"
                        ),
                      });
                    }}
                    defaultValue={moment(tempEducation?.educEndDate).format(
                      "YYYY-MM-DD"
                    )}
                    error={!!errors?.educEndDate}
                    helperText={errors?.educEndDate}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempEducation?.educCurrentStudy}
                    onChange={() => {
                      setTempEducation({
                        ...tempEducation,
                        educCurrentStudy: !tempEducation?.educCurrentStudy,
                        educEndDate: null,
                      });
                    }}
                  />
                }
                label="I’m currently studying here."
              />
            </FormGroup>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              Academic Achievement(s)
            </JobluTypography.Body>
            <Box>
              <CustomTextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                fullWidth
                onChange={(e) => {
                  setTempEducation({
                    ...tempEducation,
                    educAchievements: e.target.value,
                  });
                }}
                variant="outlined"
                value={tempEducation?.educAchievements}
              />
            </Box>
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_right}`}
          >
            <Box className={`${app_classes.margin.r1}`}>
              <Button
                variant="outlined"
                className={`${app_classes.button.primary_outline} `}
                onClick={handleCloseEducation}
              >
                Cancel
              </Button>
            </Box>
            <Button
              variant="contained"
              className={`${app_classes.button.primary}`}
              onClick={() => {
                let educs = current_user?.educationHistory;

                const validateEduc = () => {
                  const educErrors = {};
                  const educ_requireds = [
                    //   { key: "educAchievements", error_message: "Achievements is required" },
                    // { educCurrentStudy: null },
                    {
                      key: "educationType",
                      error_message: "Degree is required",
                    },
                    {
                      key: "educField",
                      error_message: "Field of study is required",
                    },

                    {
                      key: "educName",
                      error_message: "Institution/School is required",
                    },

                    {
                      key: "educStartDate",
                      error_message: "Start date is required",
                    },
                    {
                      key: "educEndDate",
                      error_message: "End Date is required",
                    },
                  ];

                  for (let item of educ_requireds) {
                    const { key, error_message } = item;
                    if (
                      tempEducation?.educCurrentStudy &&
                      key === "educEndDate"
                    ) {
                      //endDate is not required anymore
                    } else {
                      if (!tempEducation[key]) {
                        // setShowAlert({
                        //   message: `${error_message}`,
                        //   type: "warning",
                        // });
                        educErrors[key] = item.error_message;
                        // return false;
                      } else {
                        if (key === "educStartDate") {
                          if (
                            moment(tempEducation?.educStartDate)?.isAfter(
                              tempEducation?.educEndDate
                            )
                          ) {
                            educErrors["educStartDate"] =
                              "Start date is ahead from end date";
                          }
                        }
                      }
                    }
                  }
                  setErros(educErrors);
                  if (Object.keys(educErrors)?.length > 0) {
                    return false;
                  }
                  return true;
                };

                const is_valid = validateEduc();
                if (is_valid) {
                  if (showEducationModal === "Edit") {
                    educs[tempEducation?.index] = tempEducation;
                  }
                  if (showEducationModal === "Add") {
                    educs.push(tempEducation);
                  }
                  handleCloseEducation();
                  setCurrentUser({ ...current_user, educationHistory: educs });
                }
              }}
            >
              {showEducationModal === "Add" ? "Add" : "Save"}
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={showExpModal}
        onClose={handleCloseExpModal}
        scroll={`body`}
        maxWidth="sm"
        fullWidth
      >
        <Box
          className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          style={{ minWidth: 400 }}
        >
          <JobluTypography.H6 align="center">
            {showExpModal} Experience
          </JobluTypography.H6>
        </Box>
        <Box className={`${app_classes.padding.a3}`}>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Company Name *</JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              placeholder='Ex. "ACME Corp"'
              fullWidth
              onChange={(e) => {
                setTempExp({
                  ...tempExp,
                  companyName: e.target.value,
                });
              }}
              value={tempExp?.companyName}
              error={!!errors?.companyName}
              helperText={errors?.companyName}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Role *</JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              placeholder='Ex. "Accountant, Bookkeeper, Front-End Developer"'
              fullWidth
              onChange={(e) => {
                setTempExp({
                  ...tempExp,
                  jobTitle: e.target.value,
                });
              }}
              value={tempExp?.jobTitle}
              error={!!errors?.jobTitle}
              helperText={errors?.jobTitle}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Location *</JobluTypography.Body>
            {/* <TextField
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setTempExp({
                  ...tempExp,
                  location: e.target.value,
                });
              }}
              value={tempExp?.location}
            /> */}
            <CustomGooglePlaces
              onChange={(data) => {
                if (data) {
                  console.log(data);
                  setTempExp({
                    ...tempExp,
                    location: data?.location,
                  });
                }
              }}
              value={tempExp?.location}
              error={!!errors?.location}
              helperText={errors?.location}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <JobluTypography.Body semiBold>
                  Start Date *
                </JobluTypography.Body>
                <CustomTextFieldV1
                  fullWidth
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setTempExp({
                      ...tempExp,
                      startDate: moment(e.target.value).format("MM/DD/YYYY"),
                    });
                  }}
                  variant="outlined"
                  defaultValue={moment(tempExp?.startDate).format("YYYY-MM-DD")}
                  error={!!errors?.startDate}
                  helperText={errors?.startDate}
                />
              </Grid>
              {!tempExp?.expWorkingHere && (
                <Grid item xs={6}>
                  <JobluTypography.Body semiBold>
                    End Date *
                  </JobluTypography.Body>
                  <CustomTextFieldV1
                    fullWidth
                    type="date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setTempExp({
                        ...tempExp,
                        endDate: moment(e.target.value).format("MM/DD/YYYY"),
                      });
                    }}
                    defaultValue={
                      moment(tempExp?.endDate).format("YYYY-MM-DD") || null
                    }
                    variant="outlined"
                    error={!!errors?.endDate}
                    helperText={errors?.endDate}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempExp?.expWorkingHere}
                    onChange={(e) => {
                      setTempExp({
                        ...tempExp,
                        expWorkingHere: !tempExp?.expWorkingHere,
                        endDate: null,
                      });
                    }}
                  />
                }
                label="Still Employed by this company"
              />
            </FormGroup>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              Responsibilities *
            </JobluTypography.Body>
            <Box>
              <CustomTextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                fullWidth
                onChange={(e) => {
                  setTempExp({
                    ...tempExp,
                    responsibilities: e.target.value,
                  });
                }}
                variant="outlined"
                value={tempExp?.responsibilities}
                error={!!errors?.responsibilities}
                helperText={errors?.responsibilities}
              />
            </Box>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Achievements</JobluTypography.Body>
            <Box>
              <CustomTextField
                id="outlined-multiline-static"
                multiline
                rows={2}
                fullWidth
                onChange={(e) => {
                  setTempExp({
                    ...tempExp,
                    achievements: e.target.value,
                  });
                }}
                variant="outlined"
                value={tempExp?.achievements}
              />
            </Box>
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_right}`}
          >
            <Button
              variant="contained"
              className={`${app_classes.button.primary}`}
              onClick={() => {
                const expErrors = {};
                let exps = current_user?.experienceHistory;
                const exp_requireds = [
                  {
                    key: "companyName",
                    error_message: "Company Name is required",
                  },
                  { key: "jobTitle", error_message: "Role is required" },

                  { key: "location", error_message: "Location is required" },
                  {
                    key: "responsibilities",
                    error_message: "Responsibilities is required",
                  },
                  // {
                  //   key: "achievements",
                  //   error_message: "Achievements is required",
                  // },
                  { key: "startDate", error_message: "Start date is required" },

                  { key: "endDate", error_message: "End date is required" },
                ];
                const validateExp = () => {
                  for (let item of exp_requireds) {
                    const { key, error_message } = item;
                    if (tempExp?.expWorkingHere && key === "endDate") {
                      //endDate is not required anymore
                    } else {
                      if (!tempExp[key]) {
                        // setShowAlert({
                        //   message: `${error_message}`,
                        //   type: "warning",
                        // });
                        expErrors[key] = item.error_message;
                        // return false;
                      } else {
                        if (key === "startDate") {
                          if (
                            moment(tempExp?.startDate)?.isAfter(
                              tempExp?.endDate
                            )
                          ) {
                            expErrors["startDate"] =
                              "Start date is ahead from End date";
                          }
                        }
                      }
                    }
                  }
                  setErros(expErrors);
                  if (Object?.keys(expErrors)?.length > 0) {
                    return false;
                  }
                  return true;
                };

                const is_valid = validateExp();
                if (is_valid) {
                  if (showExpModal === "Add") {
                    exps.push(tempExp);
                  }
                  if (showExpModal === "Edit") {
                    exps[tempExp?.index] = tempExp;
                  }
                  setCurrentUser({
                    ...current_user,
                    experienceHistory: exps,
                  });
                  handleCloseExpModal();
                }
              }}
            >
              {showExpModal === "Add" ? "Add" : "Save"}
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={!!showPortfolioModal}
        onClose={handleClosePortfolioModal}
        scroll={`body`}
      >
        <Box
          className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          style={{ minWidth: 400 }}
        >
          <JobluTypography.H6 align="center">
            {showPortfolioModal} Link
          </JobluTypography.H6>
        </Box>
        <Box className={`${app_classes.padding.a3}`}>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              Portfolio Name *
            </JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              fullWidth
              value={tempLink?.title}
              onChange={(e) => {
                setTempLink({
                  ...tempLink,
                  title: e.target.value,
                });
              }}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              Add Link to your portfolio *
            </JobluTypography.Body>
            <CustomTextField
              value={tempLink?.link}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setTempLink({
                  ...tempLink,
                  link: e.target.value,
                });
              }}
            />
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_right} ${app_classes.margin.b3}`}
          >
            <Button
              variant="contained"
              className={`${app_classes.button.primary}`}
              onClick={() => {
                let portfolios = current_user?.links;
                const validateLink = () => {
                  const links_requireds = [
                    {
                      key: "title",
                      error_message: "Portfolio name is required",
                    },

                    {
                      key: "link",
                      error_message: "Link is required",
                    },
                  ];

                  for (let item of links_requireds) {
                    const { key, error_message } = item;

                    if (!tempLink[key]) {
                      setShowAlert({
                        message: `${error_message}`,
                        type: "warning",
                      });

                      return false;
                    }
                  }

                  if (tempLink?.link) {
                    const valid = validator.isURL(tempLink?.link);

                    if (!valid) {
                      setShowAlert({
                        message: `Link is not a valid URL`,
                        type: "warning",
                      });
                      return false;
                    }
                  }

                  return true;
                };

                const is_valid = validateLink();
                if (is_valid) {
                  if (showPortfolioModal === "Edit") {
                    portfolios[tempLink?.index] = tempLink;
                  }
                  if (showPortfolioModal === "Add") {
                    portfolios.push(tempLink);
                  }
                  setCurrentUser({
                    ...current_user,
                    links: portfolios,
                  });
                  handleClosePortfolioModal();
                }
              }}
            >
              {showPortfolioModal === "Add" ? "Add" : "Save"}
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={!!showFileModal}
        onClose={handleCloseFileModal}
        scroll={`body`}
      >
        <Box
          className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          style={{ minWidth: 400 }}
        >
          <JobluTypography.H6 align="center">
            {showFileModal} File
          </JobluTypography.H6>
        </Box>
        <Box className={`${app_classes.padding.a3}`}>
          <Box className={`${app_classes.margin.b1}`}>
            <JobluTypography.Body semiBold>
              Name this file *
            </JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              fullWidth
              value={tempFiles?.title}
              onChange={(e) => {
                setTempFiles({
                  ...tempFiles,
                  title: e.target.value,
                });
              }}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Caption>
              {tempFiles?.file?.name}
            </JobluTypography.Caption>
          </Box>
          {/* <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              Add Link to your portfolio *
            </JobluTypography.Body>
            <TextField
              value={tempLink?.link}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setTempLink({
                  ...tempLink,
                  link: e.target.value,
                });
              }}
            />
          </Box> */}
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_right} ${app_classes.margin.b3}`}
          >
            <Button
              variant="contained"
              // className={`${app_classes.button.primary}`}
              color="primary"
              disabled={!tempFiles?.title}
              style={{ textTransform: "none" }}
              onClick={async () => {
                let portfolios = current_user?.portfolioAndFiles;

                if (showFileModal === "Edit") {
                  setCurrentUser({
                    ...current_user,
                    portfolioAndFiles: portfolios,
                  });
                  handleCloseFileModal();
                }
                if (showFileModal === "Add") {
                  let payload = new FormData();
                  payload.append("document", tempFiles?.file);
                  userRequest.uploadFile(payload).then((res) => {
                    console.log("upload res", res.data);

                    portfolios.push({
                      ...tempFiles,
                      link: res.data?.documents,
                    });
                    setCurrentUser({
                      ...current_user,
                      portfolioAndFiles: portfolios,
                    });
                    handleCloseFileModal();
                  });
                }
              }}
            >
              {showFileModal === "Add" ? "Add" : "Save"}
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Box
        sx={{ overflowY: "auto" }}
        className={`${
          _location?.state?.fromCreate
            ? app_classes.height.vh100
            : app_classes.height.vh92
        }`}
        // } ${edit_profile_classes.edit_profile_bg}`}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            xs={11}
            md={8}
            // lg={6}
            lg={10}
            className={`${app_classes.padding.y2}`}
          >
            {/* <Card
              // variant="outlined"
              className={`${app_classes.padding.a5} ${app_classes.border.border_radius_10}`}
            > */}
            <Box style={{ textAlign: "right", marginBottom: "1rem" }}>
              <Button
                variant="contained"
                className={`${app_classes.button.primary}`}
                onClick={() => {
                  handleSaveProfile();
                }}
                disabled={isSubmitting}
                endIcon={
                  isSubmitting && (
                    <CircularProgress color="secondary" size={18} />
                  )
                }
              >
                {_location?.state?.fromCreate
                  ? "Save Profile and Preview"
                  : isSubmitting
                  ? "Saving"
                  : "Save Profile"}
              </Button>
            </Box>
            <Box
              className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between}`}
            >
              <Box>
                <JobluTypography.H4
                  primary
                  semiBold
                  onClick={() => {
                    console.log(current_user);
                  }}
                >
                  Profile Level:{" "}
                  <span className={app_classes.text.secondary}>
                    {applicantCompletion}%
                  </span>
                </JobluTypography.H4>
              </Box>
              <Box className={`${app_classes.text.right}`}>
                <VisibilityOutlinedIcon
                  className={`${app_classes.cursor.pointer}`}
                  onClick={() => {
                    handleOpen();
                  }}
                />
              </Box>
            </Box>
            <Box>
              <JobluTypography.H5 primary semiBold>
                Personal Information
              </JobluTypography.H5>
            </Box>
            <Box>
              <JobluTypography.Body>
                Adding more information will make you stand out to employers.
              </JobluTypography.Body>
            </Box>
            <Box
              className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.margin.y3}`}
            >
              <Box style={{ position: "relative" }}>
                <img
                  src={current_user?.profileUrl || DefaultImg}
                  style={{ objectFit: "cover", background: "#FFFFFF" }}
                  className={`${edit_profile_classes.default_img}`}
                  alt=""
                />
                <Box
                  style={{
                    position: "absolute",
                    right: "6px",
                    bottom: "6px",
                  }}
                  className={`${app_classes.cursor.pointer}`}
                >
                  <img
                    src={PhotoEditIcon}
                    className={`${app_classes.cursor.pointer}`}
                    width={40}
                    onClick={(e) => {
                      setImgSrc(null);
                      setShowProfilePicModal(true);
                      // uploadProfilePicRef?.current?.click();
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.Body semiBold>First Name *</JobluTypography.Body>
              <CustomTextField
                size="small"
                variant="outlined"
                fullWidth
                value={current_user?.firstName}
                onChange={(e) => {
                  setCurrentUser({
                    ...current_user,
                    firstName: e.target.value,
                  });
                }}
                error={!!errors?.firstName}
                helperText={errors?.firstName}
              />
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.Body semiBold>Last Name *</JobluTypography.Body>
              <CustomTextField
                size="small"
                variant="outlined"
                fullWidth
                value={current_user?.lastName}
                onChange={(e) => {
                  setCurrentUser({
                    ...current_user,
                    lastName: e.target.value,
                  });
                }}
                error={!!errors?.lastName}
                helperText={errors?.lastName}
              />
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <JobluTypography.Body semiBold>
                    Current Location *
                  </JobluTypography.Body>
                  <CustomGooglePlaces
                    onChange={(data) => {
                      console.log(data);
                      setCurrentUser({
                        ...current_user,
                        currentLocation: data?.location,
                        country: data?.countryValue,
                        city: data?.city,
                        region: data?.region,
                      });
                    }}
                    error={errors?.currentLocation}
                    helperText={errors?.currentLocation}
                    value={current_user?.currentLocation}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  {tempCC && (
                    <>
                      <JobluTypography.Body
                        semiBold
                        onClick={() => {
                          console.log(tempCC);
                        }}
                      >
                        Country Code *
                      </JobluTypography.Body>
                      <ReactSelect
                        className="basic-single w-100 me-2"
                        classNamePrefix="select"
                        defaultValue={tempCC}
                        isSearchable={isSearchable}
                        name="country-code"
                        onChange={(e) => {
                          console.log("zxczxczxczx", e.value);
                          setCountry_Code(e.value);
                        }}
                        options={countryCodesList}
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <JobluTypography.Body semiBold>
                    Mobile Number *
                  </JobluTypography.Body>
                  <CustomTextField
                    type="number"
                    size="small"
                    variant="outlined"
                    fullWidth
                    maxLength="10"
                    value={phoneNumber}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setPhoneNumber(
                          e.target.value ? trimPhone(e.target.value) : null
                        );
                      }
                    }}
                    error={!!errors?.phoneNumber}
                    helperText={errors?.phoneNumber}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className={`${app_classes.margin.b3}`}></Box>
            <Box className={`${app_classes.margin.b5}`}>
              <JobluTypography.Body semiBold>
                Bio{" "}
                <span className={`${app_classes.text.secondary}`}>
                  - optional
                </span>
              </JobluTypography.Body>
              <CustomTextField
                size="small"
                variant="outlined"
                fullWidth
                multiline
                value={current_user?.bio}
                rows={10}
                onChange={(e) => {
                  setCurrentUser({
                    ...current_user,
                    bio: e.target.value,
                  });
                }}
              />
            </Box>

            {/* JOB REEL */}
            <Box
              className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.margin.b3}`}
            >
              <Box>
                <Box>
                  <JobluTypography.H5 primary semiBold>
                    JobReel
                  </JobluTypography.H5>
                </Box>
              </Box>
              <Box>
                <JobluTypography.Body
                  secondary
                  semiBold
                  className={`${app_classes.cursor.pointer}`}
                >
                  Increase your chance of getting hired!
                </JobluTypography.Body>
              </Box>
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <Box>
                <JobluTypography.Caption
                  className={`${app_classes.text.light}`}
                >
                  ● Make sure your audio and video are working properly.
                </JobluTypography.Caption>
              </Box>
              <Box>
                <JobluTypography.Caption
                  className={`${app_classes.text.light}`}
                >
                  ● Dress properly like you would in a job interview.
                </JobluTypography.Caption>
              </Box>
              <Box>
                <JobluTypography.Caption
                  className={`${app_classes.text.light}`}
                >
                  ● Have good lighting so employers can see your face properly.
                </JobluTypography.Caption>
              </Box>
              {/* <Box>
                <JobluTypography.Caption
                  className={`${app_classes.text.light}`}
                >
                  ● There will be prompts to help you when you are not sure what
                  to say..
                </JobluTypography.Caption>
              </Box>
              <Box>
                <JobluTypography.Caption
                  className={`${app_classes.text.light}`}
                >
                  ● If you are not ready, you can do this later on your profile
                  settings..
                </JobluTypography.Caption>
              </Box> */}
            </Box>
            <Box
              className={`${app_classes.margin.b3} ${app_classes.text.center}`}
            >
              {videoLoading ? (
                <>
                  <Box style={{ height: "500px", width: "100%" }}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box>
                        <Box>
                          <CircularProgress />
                        </Box>
                        <Box>
                          <JobluTypography.H6 primary>
                            Uploading JobReel
                          </JobluTypography.H6>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {current_user?.elevatorPitch ? (
                    <Grid container justifyContent="center">
                      <Grid item xs={5} height={500}>
                        <>
                          <div className="w-100 compShowcase position-relative h-100">
                            <div className="h-100">
                              <ReactPlayer
                                key={keyCounter}
                                url={`${
                                  current_user?.elevatorPitch
                                }?counter=${Math.floor(
                                  100000 + Math.random() * 900000
                                )}`}
                                width={"100%"}
                                height={"100%"}
                              />
                              {/* <VideoThumbnail
                                snapshotAtTime={4}
                                videoUrl={current_user?.elevatorPitch}
                              /> */}
                            </div>
                            <div
                              className="position-absolute"
                              style={{
                                backgroundColor: "#0000004D",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                              }}
                            ></div>
                            <img
                              src={PlayIcon}
                              alt=""
                              className="position-absolute hand"
                              width={70}
                              onClick={() => {
                                console.log(current_user.elevatorPitch);
                                playVid(current_user?.elevatorPitch);
                              }}
                              style={{
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          </div>
                        </>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container justifyContent="center">
                      <Grid item xs={5}>
                        {/* <VideoCameraFront
                        style={{ fontSize: 100, color: "#E9EBEC" }}
                      /> */}
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Box>
            <Box
              className={`${app_classes.text.center} ${app_classes.margin.b1}`}
            >
              <input
                accept="video/mp4,video/x-m4v,video/*"
                hidden
                ref={uploadReel}
                onChange={(e) => {
                  let file = e.target.files[0];
                  if (file) {
                    let payload = new FormData();
                    payload.append("document", file);

                    // console.log("payload", payload);
                    // for (const [name, value] of payload.entries()) {
                    //   console.log(name, value);
                    // }

                    setCurrentUser({
                      ...current_user,
                      elevatorPitch: null,
                    });

                    setVideoLoading(true);
                    userRequest.uploadVideo(payload).then((res) => {
                      console.log("NEW VIDEO LINK", res.data);
                      setCurrentUser({
                        ...current_user,
                        elevatorPitch: res.data?.documents,
                      });
                      setCurrentVid(res?.data?.documents);
                      setKeyCounter(keyCounter + 1);

                      setVideoLoading(false);
                    });
                  }
                }}
                type="file"
              />
              <Button
                variant="contained"
                className={`${app_classes.button.primary}`}
                onClick={() => {
                  uploadReel?.current?.click();
                }}
              >
                Upload JobReel
              </Button>
            </Box>
            <Box
              className={`${app_classes.text.center} ${app_classes.margin.b3}`}
            >
              {/* <JobluTypography.Body className={`${app_classes.text.light}`}>
                This button will redirect you to our app where you can record
                your JobReel
              </JobluTypography.Body> */}
            </Box>
            {/* END OF JOB REEL */}
            <Box
              className={`${app_classes.margin.t3} ${app_classes.margin.b1}`}
            >
              <JobluTypography.H5 primary semiBold>
                Preferences
              </JobluTypography.H5>
            </Box>
            {/* EMPLOYMENT STATUS */}
            <Box className={`${app_classes.margin.b3}`}>
              <Box className={`${app_classes.margin.b2}`}>
                <JobluTypography.Body
                  semiBold
                  style={{ color: errors?.employmentStatus && "red" }}
                >
                  Employment Status *
                </JobluTypography.Body>
              </Box>
              <Box>
                {employmentStatusList?.map((item) => {
                  return (
                    <Chip
                      label={item.label}
                      className={`${
                        current_user?.employmentStatus === item.value
                          ? edit_profile_classes.chip_active
                          : edit_profile_classes.chip_inactive
                      }`}
                      onClick={() => {
                        handleEmploymentStatus(item.value);
                        setCurrentUser({
                          ...current_user,
                          employmentStatus: item.value,
                        });
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            {/* END OF EMPLOYMENT STATUS */}
            {/* EMPLOYMENT TYPE */}
            <Box
              className={`${app_classes.margin.b3}`}
              style={{ color: errors?.employmentType && "red" }}
            >
              <Box>
                <JobluTypography.Body semiBold>
                  Employment Type*
                </JobluTypography.Body>
              </Box>
              <Box className={`${app_classes.margin.b2}`}>
                <JobluTypography.Caption>
                  Choose up to two. ({current_user?.employmentType?.length}/2)
                </JobluTypography.Caption>
              </Box>
              <Box>
                {employmentTypeList?.map((item, i_index) => {
                  return (
                    <Chip
                      label={item.label}
                      className={`${
                        current_user?.employmentType?.includes(item.label)
                          ? edit_profile_classes.chip_active
                          : edit_profile_classes.chip_inactive
                      } `}
                      disabled={
                        !current_user?.employmentType?.includes(item.value) &&
                        current_user?.employmentType?.length >= 2
                      }
                      onClick={() => {
                        let employmentType = current_user?.employmentType || [];
                        if (
                          current_user?.employmentType?.includes(
                            item.value?.trim()
                          )
                        ) {
                          employmentType.splice(i_index, 1);
                        } else {
                          employmentType.push(item?.value);
                        }

                        setCurrentUser({
                          ...current_user,
                          employmentType,
                        });
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            {/* END OF EMPLOYMENT TYPE */}
            {/* WORK SETUP */}
            <Box className={`${app_classes.margin.b3}`}>
              <Box>
                <JobluTypography.Body
                  semiBold
                  style={{ color: errors?.workSetup && "red" }}
                >
                  Work Setup *
                </JobluTypography.Body>
              </Box>
              <Box className={`${app_classes.margin.b2}`}>
                <JobluTypography.Caption>
                  Choose up to two. ({current_user?.workSetup?.length}/2)
                </JobluTypography.Caption>
              </Box>
              <Box>
                {workSetupList?.map((item, i_index) => {
                  return (
                    <Chip
                      label={item.label}
                      className={`${
                        current_user?.workSetup?.includes(item.label)
                          ? edit_profile_classes.chip_active
                          : edit_profile_classes.chip_inactive
                      } `}
                      disabled={
                        !current_user?.workSetup?.includes(item.label) &&
                        current_user?.workSetup?.length >= 2
                      }
                      onClick={() => {
                        let workSetup = current_user?.workSetup;
                        if (
                          current_user?.workSetup?.includes(item.label?.trim())
                        ) {
                          workSetup.splice(workSetup.indexOf(item.label), 1);
                        } else {
                          workSetup.push(item?.label);
                        }
                        setCurrentUser({
                          ...current_user,
                          workSetup,
                        });
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            {/* END OF WORK SETUP */}
            {/* SALARY RANGE */}
            <Box
              className={`${app_classes.margin.t3} ${app_classes.margin.b1}`}
            >
              <JobluTypography.Body semiBold>
                Salary Range *
              </JobluTypography.Body>
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    variant="outlined"
                    fullWidth
                    size="small"
                    defaultValue={current_user?.salaryCurrency || currency}
                    value={current_user?.salaryCurrency || currency}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setCurrentUser({
                        ...current_user,
                        salaryCurrency: e.target.value,
                      });
                    }}
                    style={{ padding: 0 }}
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <NumericFormat
                    thousandSeparator
                    customInput={CustomTextField}
                    value={current_user?.salaryMin}
                    placeholder="Minimum salary"
                    onValueChange={({ formattedValue, value }) => {
                      setCurrentUser({
                        ...current_user,
                        salaryMin: parseFloat(value),
                      });
                    }}
                    error={
                      salaryMinError || salaryMinError2 || !!errors?.salaryMin
                    }
                    helperText={errors?.salaryMin}
                    size="small"
                    fullWidth
                    variant="outlined"
                  />
                  {salaryMinError && (
                    <>
                      <Box>
                        <JobluTypography.Caption
                          style={{ color: "red", fontSize: 10 }}
                        >
                          Minimum salary is required.
                        </JobluTypography.Caption>
                      </Box>
                    </>
                  )}
                  {salaryMinError2 && (
                    <>
                      <Box>
                        <JobluTypography.Caption
                          style={{ color: "red", fontSize: 10 }}
                        >
                          Minimum salary is greater than max salary provided.
                        </JobluTypography.Caption>
                      </Box>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={5}>
                  <NumericFormat
                    variant="outlined"
                    thousandSeparator
                    customInput={CustomTextField}
                    value={current_user?.salaryMax}
                    placeholder="Maximum salary"
                    onValueChange={({ formattedValue, value }) => {
                      setCurrentUser({
                        ...current_user,
                        salaryMax: parseFloat(value),
                      });
                    }}
                    error={!!errors?.salaryMax || salaryMaxError}
                    helperText={errors?.salaryMax}
                    size="small"
                    fullWidth
                  />
                  {salaryMaxError && (
                    <>
                      <Box>
                        <JobluTypography.Caption
                          style={{ color: "red", fontSize: 10 }}
                        >
                          Maximum salary is required.
                        </JobluTypography.Caption>
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
            {/* END OF SALARY RANGE */}
            {/* VALUES*/}
            <Box className={`${app_classes.margin.b3}`}>
              <Box>
                <JobluTypography.Body
                  semiBold
                  style={{ color: errors?.importantForYou && "red" }}
                >
                  What do you value most *
                </JobluTypography.Body>
              </Box>
              <Box className={`${app_classes.margin.b2}`}>
                <JobluTypography.Caption>
                  Choose up to three. ({current_user?.importantForYou?.length}
                  /3)
                </JobluTypography.Caption>
              </Box>
              <Box>
                {valuesList?.map((item) => {
                  return (
                    <Chip
                      label={item.label}
                      className={`${
                        current_user?.importantForYou?.includes(item.value)
                          ? edit_profile_classes.chip_active
                          : edit_profile_classes.chip_inactive
                      } `}
                      disabled={
                        !current_user?.importantForYou?.includes(item.value) &&
                        current_user?.importantForYou?.length >= 3
                      }
                      onClick={() => {
                        let importantForYou = current_user?.importantForYou;
                        if (
                          current_user?.importantForYou?.includes(
                            item.value?.trim()
                          )
                        ) {
                          importantForYou.splice(
                            importantForYou.indexOf(item.value),
                            1
                          );
                        } else {
                          importantForYou.push(item?.value);
                        }
                        setCurrentUser({
                          ...current_user,
                          importantForYou,
                        });
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            {/* VALUES */}

            <Box
              className={`${app_classes.margin.b3}`}
              style={{ position: "relative" }}
            >
              <JobluTypography.Body
                semiBold
                style={{ color: errors?.jobFunction && "red" }}
              >
                Industry *
              </JobluTypography.Body>
              {/* <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={current_user?.industry}
            
                  error={!!errors?.industry}
                  helperText={errors?.industry}
                /> */}

              <SelectJobFunction
                noAll
                onChange={(val) => {
                  setCurrentUser({
                    ...current_user,
                    jobFunction: val,
                  });
                }}
                value={current_user?.jobFunction}
                error={!!errors?.jobFunction}
                helperText={errors?.jobFunction}
              />
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.Body semiBold>
                Desired Role *
              </JobluTypography.Body>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                value={current_user?.preferredTitle}
                onChange={(e) => {
                  setCurrentUser({
                    ...current_user,
                    preferredTitle: e.target.value,
                  });
                }}
                error={!!errors?.preferredTitle}
                helperText={errors?.preferredTitle}
              />
            </Box>
            {/* EXP */}
            <Box className={`${app_classes.margin.b3}`}>
              <Box
                className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.margin.b2}`}
              >
                <Box>
                  <JobluTypography.H5 primary semiBold>
                    Experience
                  </JobluTypography.H5>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    className={`${app_classes.button.primary}`}
                    onClick={() => handleOpenExpModal("Add")}
                  >
                    Add
                  </Button>
                </Box>
              </Box>

              <Box>
                {/* EXPERIENCE LIST */}
                <Box>
                  {current_user?.experienceHistory?.length === 0 && (
                    <Box
                      className={`${edit_profile_classes.bg_light} ${app_classes.padding.a2} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.margin.b1}`}
                    >
                      <JobluTypography.Caption>
                        No experience information provided
                      </JobluTypography.Caption>
                    </Box>
                  )}
                  {current_user?.experienceHistory?.length > 0 &&
                    current_user?.experienceHistory
                      ?.sort(function (a, b) {
                        return new Date(b.startDate) - new Date(a.startDate);
                      })
                      .map((exp, exp_index) => {
                        return (
                          <Box
                            className={`${edit_profile_classes.bg_light} ${app_classes.padding.a2} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.margin.b1}`}
                          >
                            <Box>
                              <Box>
                                <JobluTypography.Body semiBold>
                                  {exp?.companyName} |{" "}
                                  {/* {formatDateOnlyv3(exp.startDate)} -{" "}
                                  {!exp?.expWorkingHere
                                    ? exp?.endDate ? formatDateOnlyv3(exp?.endDate) : "Present"
                                    : "Present"} */}
                                  {formatDateRange2(
                                    exp?.startDate,
                                    exp?.endDate
                                  )}
                                </JobluTypography.Body>
                              </Box>
                              <Box>
                                <JobluTypography.Caption>
                                  {exp?.jobTitle}
                                </JobluTypography.Caption>
                              </Box>
                              <Box className={`${app_classes.margin.b2}`}>
                                <Box className={`${app_classes.margin.b2}`}>
                                  <JobluTypography.Caption>
                                    {exp?.location}
                                  </JobluTypography.Caption>
                                </Box>
                                {exp?.responsibilities && (
                                  <>
                                    <Box>
                                      <JobluTypography.Caption>
                                        Responsibilities
                                      </JobluTypography.Caption>
                                    </Box>
                                    <Box>
                                      <JobluTypography.Caption>
                                        {exp?.responsibilities}
                                      </JobluTypography.Caption>
                                    </Box>
                                  </>
                                )}
                              </Box>
                              {exp?.achievements && (
                                <Box className={`${app_classes.margin.b2}`}>
                                  <Box>
                                    <JobluTypography.Caption>
                                      Achievements
                                    </JobluTypography.Caption>
                                  </Box>

                                  <Box>
                                    <JobluTypography.Caption>
                                      {exp?.achievements}
                                    </JobluTypography.Caption>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                            <Box
                              className={` ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.flexBox.flex_direction_column}`}
                            >
                              <Box>
                                <img
                                  src={EditIcon}
                                  style={{ width: 14 }}
                                  className={`${app_classes.cursor.pointer}`}
                                  onClick={() => {
                                    handleOpenExpModal("Edit");
                                    setTempExp({ ...exp, index: exp_index });
                                  }}
                                  alt=""
                                />
                              </Box>

                              <Box>
                                <img
                                  alt=""
                                  src={DeleteIconRed}
                                  style={{ width: 14 }}
                                  className={`${app_classes.cursor.pointer}`}
                                  onClick={() => {
                                    let exps = current_user?.experienceHistory;
                                    exps.splice(exp_index, 1);
                                    setCurrentUser({
                                      ...current_user,
                                      experienceHistory: exps,
                                    });
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                </Box>
              </Box>
            </Box>
            {/* END OF EXP LIST */}
            {/* SKILLS */}
            <Box className={`${app_classes.margin.b3}`}>
              <Box>
                <JobluTypography.H5 primary semiBold>
                  Skills
                </JobluTypography.H5>
              </Box>
              <Box>
                <Box className={`${app_classes.margin.b3}`}>
                  <Box>
                    <JobluTypography.Body
                      semiBold
                      style={{ color: errors?.skills && "red" }}
                    >
                      Technical Skills *
                    </JobluTypography.Body>
                  </Box>
                  <Box className={`${app_classes.margin.b2}`}>
                    <JobluTypography.Caption>
                      Search for any skills you have. You can select up to 10
                      skills ({current_user?.skills?.length}/10).
                    </JobluTypography.Caption>
                  </Box>
                  <AsyncSelect
                    className=""
                    onChange={handleChange}
                    isMulti
                    defaultValue={skillsCopy2}
                    cacheOptions
                    defaultOptions
                    placeholder="Search technical skills..."
                    loadOptions={loadOptions}
                    isOptionDisabled={() => current_user?.skills?.length >= 10}
                    isLoading={isSearcingSkills}
                  />
                </Box>{" "}
                <Box>
                  {/* {current_user?.skills?.map((item, skills_index) => {
                      return (
                        <Chip
                          label={item}
                          className={`${edit_profile_classes.chip_active}`}
                          onClick={() => {
                            handleSetSoftSkills(item.value);
                          }}
                          onDelete={() => {
                            let skills_cp = current_user?.skills;

                            skills_cp?.splice(skills_index, 1);
                            setCurrentUser({
                              ...current_user,
                              skills: skills_cp,
                            });
                          }}
                        />
                      );
                    })} */}
                </Box>
                <Box>
                  <Box className={`${app_classes.margin.b3}`}>
                    <Box>
                      <JobluTypography.Body
                        semiBold
                        style={{ color: errors?.softSkills && "red" }}
                      >
                        Soft Skills *
                      </JobluTypography.Body>
                    </Box>
                    <Box className={`${app_classes.margin.b2}`}>
                      <JobluTypography.Caption>
                        Choose up to 10 soft skills you want to highlight. (
                        {current_user?.softSkills?.length}/10)
                      </JobluTypography.Caption>
                    </Box>
                    <Box>
                      {softSkillsList?.map((item) => {
                        return (
                          <Chip
                            label={item.label}
                            className={`${
                              current_user?.softSkills?.includes(item.value)
                                ? edit_profile_classes.chip_active
                                : edit_profile_classes.chip_inactive
                            }`}
                            disabled={
                              !current_user?.softSkills?.includes(item.value) &&
                              current_user?.softSkills?.length >= 10
                            }
                            onClick={() => {
                              let sk = current_user?.softSkills;
                              if (sk.includes(item.value)) {
                                sk.splice(sk.indexOf(item.value), 1);
                              } else {
                                sk.push(item.value);
                              }
                              console.log(sk);
                              setCurrentUser({
                                ...current_user,
                                softSkills: sk,
                              });
                            }}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* END OF SKILLS */}

            {/* EDUCATION */}
            <Box className={`${app_classes.margin.b3}`}>
              <Box
                className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between}`}
              >
                <Box>
                  <Box>
                    <JobluTypography.H5 primary semiBold>
                      Education
                    </JobluTypography.H5>
                  </Box>
                  <Box className={`${app_classes.margin.b2}`}>
                    {/* <JobluTypography.Caption>
                        Please provide up to three (3/3) educational backgrounds
                        below. You can add more later on your profile settings.
                        This section is optional.
                      </JobluTypography.Caption> */}
                  </Box>
                </Box>
                {current_user?.educationHistory?.length < 3 && (
                  <Box>
                    <Button
                      variant="contained"
                      className={`${app_classes.button.primary}`}
                      onClick={() => {
                        handleOpenEducation("Add");
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <Box style={{ display: "none" }}>
                  <Box className={`${app_classes.margin.b3}`}>
                    <JobluTypography.Body semiBold>
                      Degree *
                    </JobluTypography.Body>
                    <TextField size="small" variant="outlined" fullWidth />
                  </Box>
                  <Box className={`${app_classes.margin.b3}`}>
                    <JobluTypography.Body semiBold>
                      Major of Field of Study *
                    </JobluTypography.Body>
                    <TextField size="small" variant="outlined" fullWidth />
                  </Box>
                  <Box className={`${app_classes.margin.b3}`}>
                    <JobluTypography.Body semiBold>
                      School or Name of Institute *
                    </JobluTypography.Body>
                    <TextField size="small" variant="outlined" fullWidth />
                  </Box>
                  <Box className={`${app_classes.margin.b3}`}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <JobluTypography.Body semiBold>
                          Start Date *
                        </JobluTypography.Body>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker label="Basic date picker" />
                          </DemoContainer>
                        </LocalizationProvider> */}
                      </Grid>
                      <Grid item xs={6}>
                        <JobluTypography.Body semiBold>
                          End Date *
                        </JobluTypography.Body>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker label="Basic date picker" style={{width:'100%'}}/>
                          </DemoContainer>
                        </LocalizationProvider> */}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={`${app_classes.margin.b3}`}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="I’m currently studying here."
                      />
                    </FormGroup>
                  </Box>
                  <Box className={`${app_classes.margin.b3}`}>
                    <JobluTypography.Body semiBold>
                      Academic Achievement(s)
                    </JobluTypography.Body>
                    <Box>
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                  <Box
                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_right} ${app_classes.margin.b3}`}
                  >
                    <Button variant="contained" color="primary">
                      Add
                    </Button>
                  </Box>
                </Box>
                {/* EDUCATION LIST */}
                <Box>
                  {current_user?.educationHistory?.length === 0 && (
                    <Box
                      className={`${edit_profile_classes.bg_light} ${app_classes.padding.a2} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.margin.b1}`}
                    >
                      <Box>
                        <JobluTypography.Caption>
                          No education information provided
                        </JobluTypography.Caption>
                      </Box>
                    </Box>
                  )}
                  {current_user?.educationHistory?.length > 0 &&
                    current_user?.educationHistory
                      ?.sort(function (a, b) {
                        return (
                          new Date(b.educStartDate) - new Date(a.educStartDate)
                        );
                      })
                      ?.map((educ, educ_index) => {
                        return (
                          <Box
                            className={`${edit_profile_classes.bg_light} ${app_classes.padding.a2} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.margin.b1}`}
                          >
                            <Box>
                              <Box>
                                <JobluTypography.Body semiBold>
                                  {educ?.educName} |{" "}
                                  {/* {formatDateOnlyv3(educ?.educStartDate)} -{" "}
                                    {educ?.educCurrentStudy
                                      ? "Present"
                                      : formatDateOnlyv3(educ?.educEndDate)} */}
                                  {formatDateRange2(
                                    educ?.educStartDate,
                                    educ?.educEndDate
                                  )}
                                </JobluTypography.Body>
                              </Box>
                              <Box>
                                <JobluTypography.Caption>
                                  {educ?.educationType}
                                </JobluTypography.Caption>
                              </Box>
                              <Box>
                                <JobluTypography.Caption>
                                  {educ?.educField}
                                </JobluTypography.Caption>
                              </Box>
                              {educ?.educAchievements && (
                                <Box
                                  className={`${app_classes.margin.b2} ${app_classes.margin.t2}`}
                                >
                                  <Box>
                                    <JobluTypography.Caption>
                                      Achievements
                                    </JobluTypography.Caption>
                                  </Box>
                                  <Box>
                                    <JobluTypography.Caption
                                      style={{ whiteSpace: "pre-wrap" }}
                                    >
                                      {educ?.educAchievements}
                                    </JobluTypography.Caption>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                            <Box
                              className={` ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.flexBox.flex_direction_column}`}
                            >
                              <Box>
                                <img
                                  src={EditIcon}
                                  style={{ width: 14 }}
                                  className={`${app_classes.cursor.pointer}`}
                                  alt=""
                                  onClick={() => {
                                    handleOpenEducation("Edit");
                                    setTempEducation({
                                      ...educ,
                                      index: educ_index,
                                    });
                                  }}
                                />
                              </Box>

                              <Box>
                                <img
                                  src={DeleteIconRed}
                                  style={{ width: 14 }}
                                  className={`${app_classes.cursor.pointer}`}
                                  alt=""
                                  onClick={() => {
                                    let educs = current_user?.educationHistory;
                                    educs.splice(educ_index, 1);

                                    setCurrentUser({
                                      ...current_user,
                                      educationHistory: educs,
                                    });
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                </Box>
              </Box>
            </Box>
            {/* END OF EDUCATION LIST */}
            <Box
              className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between}`}
            >
              <Box>
                <Box>
                  <JobluTypography.H5 primary semiBold>
                    Resume and Portfolio
                  </JobluTypography.H5>
                </Box>
                <Box className={`${app_classes.margin.b2}`}>
                  <JobluTypography.Body>
                    This section is optional. You can either add a link or
                    upload a file.
                  </JobluTypography.Body>
                </Box>
              </Box>
              <Box>
                <JobluTypography.Body
                  secondary
                  semiBold
                  className={`${app_classes.cursor.pointer}`}
                  onClick={() => handleOpenPortfolioModal("Add")}
                >
                  Add Link
                </JobluTypography.Body>
              </Box>
            </Box>

            {/* PORTFOLIO */}
            <Box>
              {current_user?.links?.length === 0 &&
                current_user?.portfolioAndFiles?.length === 0 && (
                  <Box
                    className={`${edit_profile_classes.bg_light} ${app_classes.padding.a2} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.margin.b1}`}
                  >
                    <Box>
                      <JobluTypography.Caption
                        className={`${app_classes.display.display_block}`}
                      >
                        No resume or portfolio provided
                      </JobluTypography.Caption>
                    </Box>
                  </Box>
                )}
              <Box>
                {" "}
                {current_user?.links?.length > 0 &&
                  current_user?.links?.map((file, file_index) => {
                    return (
                      <Box
                        className={`${edit_profile_classes.bg_light} ${app_classes.padding.a2} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.margin.b1}`}
                      >
                        <Box>
                          <Box>
                            <JobluTypography.Body
                              className={`${app_classes.display.display_block}`}
                            >
                              {file?.title}
                            </JobluTypography.Body>
                          </Box>
                          <Box s>
                            <JobluTypography.Caption
                              className={`${app_classes.display.display_block}`}
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                wordWrap: "break-word",
                              }}
                              onClick={() => {
                                window.open(file?.link, "_blank");
                              }}
                            >
                              {file?.link?.length > 70
                                ? `${file?.link?.slice(0, 70)}...`
                                : file?.link}
                            </JobluTypography.Caption>
                          </Box>
                        </Box>
                        <Box
                          className={` ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.flexBox.flex_direction_column} 
                      `}
                        >
                          <Box>
                            <img
                              src={EditIcon}
                              style={{ width: 14 }}
                              className={`${app_classes.cursor.pointer}`}
                              alt=""
                              onClick={() => {
                                setTempLink({
                                  ...file,
                                  index: file_index,
                                });
                                handleOpenPortfolioModal("Edit");
                              }}
                            />
                          </Box>

                          <Box>
                            <img
                              src={DeleteIconRed}
                              style={{ width: 14 }}
                              className={`${app_classes.cursor.pointer}`}
                              alt=""
                              onClick={() => {
                                let p_files = current_user.links;
                                p_files.splice(file_index, 1);
                                setCurrentUser({
                                  ...current_user,
                                  links: p_files,
                                });
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>

              {current_user?.portfolioAndFiles?.map((file, file_index) => {
                return (
                  <Box
                    className={`${edit_profile_classes.bg_light} ${app_classes.padding.a2} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.margin.b1}`}
                  >
                    <Box>
                      {/* <Box>
                          <JobluTypography.Body
                            className={`${app_classes.display.display_block}`}
                          >
                            {file?.title}
                          </JobluTypography.Body>
                        </Box> */}
                      <Box>
                        <JobluTypography.Caption
                          className={`${app_classes.display.display_block}`}
                          style={{
                            color: "#009CDE",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(file.link, "_blank");
                          }}
                        >
                          {file?.title}
                        </JobluTypography.Caption>
                      </Box>
                    </Box>
                    <Box
                      className={` ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.flexBox.flex_direction_column} 
                      `}
                    >
                      <Box>
                        <img
                          src={EditIcon}
                          style={{ width: 14 }}
                          className={`${app_classes.cursor.pointer}`}
                          alt=""
                          onClick={() => {
                            setTempFiles({
                              ...file,
                              index: file_index,
                            });
                            handleOpenFileModal("Edit");
                          }}
                        />
                      </Box>

                      <Box>
                        <img
                          src={DeleteIconRed}
                          style={{ width: 14 }}
                          className={`${app_classes.cursor.pointer}`}
                          alt=""
                          onClick={() => {
                            let p_files = current_user.portfolioAndFiles;
                            p_files.splice(file_index, 1);
                            setCurrentUser({
                              ...current_user,
                              portfolioAndFiles: p_files,
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box
              className={`${app_classes.border.border_all} ${app_classes.border.border_radius_5} ${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center} ${app_classes.flexBox.justify_content_center}  ${app_classes.flexBox.flex_direction_column} ${app_classes.padding.a4} ${app_classes.margin.b3}`}
            >
              <Box className={`${app_classes.margin.b3}`}>
                <img src={UploadIcon2} width={45} />
              </Box>
              <input
                ref={uploadPortfolioRef}
                accept="image/*, application/pdf, .docx"
                type="file"
                hidden
                onChange={(e) => {
                  setTempFiles({ ...tempFiles, file: e.target.files[0] });
                  setShowFileModal("Add");
                }}
              />
              <Box className={`${app_classes.margin.b3}`}>
                <JobluTypography.Body>
                  Upload your CV, portfolio or work samples in PDF format
                </JobluTypography.Body>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  className={`${app_classes.button.primary_outline}`}
                  onClick={() => {
                    console.log(uploadPortfolioRef);
                    uploadPortfolioRef?.current?.click();
                  }}
                >
                  Upload File
                </Button>
              </Box>
            </Box>
            {/* END OF PORTFOLIO */}
            <Box>
              <Box>
                <Box className={`${app_classes.margin.b3}`}>
                  <JobluTypography.H5 primary semiBold>
                    Equal Opportunities
                  </JobluTypography.H5>
                </Box>
                <Box>
                  <JobluTypography.Body semiBold>Pronouns</JobluTypography.Body>
                  <TextField
                    id="outlined-select-currency"
                    select
                    fullWidth
                    size="small"
                    value={current_user?.pronoun}
                    onChange={(e) => {
                      setCurrentUser({
                        ...current_user,
                        pronoun: e.target.value,
                      });
                    }}
                    variant="outlined"
                  >
                    <MenuItem selected value="Select option" disabled>
                      Select option
                    </MenuItem>
                    {pronouns?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box className={`${app_classes.margin.b3}`}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!current_user?.showPronouns}
                          onChange={(e) => {
                            setCurrentUser({
                              ...current_user,
                              showPronouns: !current_user?.showPronouns,
                            });
                          }}
                        />
                      }
                      label={
                        <JobluTypography.Body>
                          Don't display on my profile
                        </JobluTypography.Body>
                      }
                    />
                  </FormGroup>
                </Box>
                <Box>
                  <JobluTypography.Body semiBold>
                    Do you have any disabilities?
                  </JobluTypography.Body>
                </Box>
                <Box>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        checked={current_user?.showDisabilities}
                        onClick={(e) => {
                          setCurrentUser({
                            ...current_user,
                            showDisabilities: true,
                          });
                        }}
                        // value={true}
                        control={<Radio />}
                        value="Yes, I have (or previously had) a disability"
                        label={
                          <JobluTypography.Body>
                            Yes, I have (or previously had) a disability
                          </JobluTypography.Body>
                        }
                      />
                      <FormControlLabel
                        checked={!current_user?.showDisabilities}
                        onClick={(e) => {
                          setCurrentUser({
                            ...current_user,
                            showDisabilities: false,
                          });
                        }}
                        // value={false}
                        value="No, or prefer not to say"
                        control={<Radio />}
                        label={
                          <JobluTypography.Body>
                            No, or prefer not to say
                          </JobluTypography.Body>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    className={`${app_classes.button.primary_outline}`}
                    onClick={handleOpen}
                  >
                    Preview Profile
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${app_classes.button.primary}`}
                    onClick={() => {
                      handleSaveProfile();
                    }}
                    disabled={isSubmitting}
                    endIcon={
                      isSubmitting && (
                        <CircularProgress color="secondary" size={18} />
                      )
                    }
                  >
                    {_location?.state?.fromCreate
                      ? "Save Profile and Preview"
                      : isSubmitting
                      ? "Saving"
                      : "Save Profile"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {/* </Card> */}
          </Grid>
        </Grid>
      </Box>
      <JobluVideoPlayer
        src={currentVideoLink}
        play={playVideo}
        setPlayVideo={setPlayVideo}
        vidHeight={700}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
    currency: state.Generals.currency,
  };
};

const mapDispatchToProps = {
  getUser: userActions.getUser,
  setShowAlert: appActions?.showAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
