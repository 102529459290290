import { useState } from 'react'
import { connect } from 'react-redux'
import DismissIcon from '../../../../../assets/img/editProfile/dismissIcon.png'
import LikeIcon from '../../../../../assets/img/editProfile/likeIcon.png'
import SuperLikeIcon from '../../../../../assets/img/editProfile/superLikeIcon.png'
import educationIcon from '../../../../../assets/img/explore/educationIcon.svg'
import employmentTypeIcon from '../../../../../assets/img/explore/employmentTypeIcon.png'
import experienceIcon from '../../../../../assets/img/explore/experienceIcon.svg'
import locationIcon from '../../../../../assets/img/explore/locationIcon.svg'
import playIcon from '../../../../../assets/img/explore/playIcon.svg'
import recentExpIcon from '../../../../../assets/img/explore/recentExpIcon.png'
import salaryIcon from '../../../../../assets/img/explore/salaryIcon.svg'
import skillsIcon from '../../../../../assets/img/explore/skillsIcon.png'
import softSkillIcon from '../../../../../assets/img/explore/softSkillIcon.png'
import workSetupIcon from '../../../../../assets/img/explore/workSetupIcon.png'
import TilePatternBg from '../../../../../assets/img/tile-pattern-bg.png'
import {
    profilePlaceholder_Applicant,
    salaryRanges,
} from '../../../../../utils/constants'
import {
    formatMoney,
    getYearDifference,
    getYearsOfExpCategory,
    titleCase,
} from '../../../../../utils/helpers'
import JobluVideoPlayer from '../../../components/VideoPlayer'
import { defaultActions, SLIDE_CLASSES } from '../../../Explore'
import { ProfileModal } from '../ViewProfile/ProfileModal'

const ExploreGridViewCard2 = ({
    seeker,
    setIsViewProfile,
    setJobseeker,
    onSwipeFulfilled,
    cardID,
    explore,
    AppState,
    className,
    id,
    itemIndex,
}) => {
    const handleViewProfile = () => {
        setJobseeker(seeker)
        setIsViewProfile(true)
    }

    const handleAction = (data, dir, new_class, type) => {
        onSwipeFulfilled(data, cardID, dir, new_class, 'grid', itemIndex)
        handleCloseProfileModal()
    }

    const [showProfileModal, setShowProfileModal] = useState(false)

    const handleCloseProfileModal = () => setShowProfileModal(false)
    const handleShowProfileModal = () => setShowProfileModal(true)

    const [playVideo, setPlayVideo] = useState(false)
    const [showPitch, setShowPitch] = useState(true)
    const handlePlayVideo = () => {
        setPlayVideo(!playVideo)
    }
    const { currencyList, currency } = AppState
    const currencyOptions = {
        currencyList,
        currency,
    }

    const getYearsOfExp = (item) => {
        if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
            const sorted =
                item?.experienceHistory &&
                item?.experienceHistory?.sort(function (a, b) {
                    return new Date(a.startDate) - new Date(b.startDate)
                })
            const startDate = new Date(sorted[0]?.startDate)
            const diff = getYearDifference(startDate)
            return getYearsOfExpCategory(diff)
        } else {
            return null
        }
    }

    return (
        <div className={className} id={id} style={{ paddingTop: 10 }}>
            <ProfileModal
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                handleCloseProfileModal={handleCloseProfileModal}
                handleShowProfileModal={handleShowProfileModal}
                seeker={seeker}
                handleAction={handleAction}
                explore={true}
                hasSwipeActions={true}
            />
            <div
                className="card shadow mb-2 position-relative "
                style={{
                    height: 431,
                    borderRadius: 20,
                    overflow: 'hidden',
                }}
            >
                <div className="pt-0 h-100">
                    <div className="w-100 p-3">
                        <div className="mb-3">
                            <div className="px-2">
                                <div
                                    className="position-relative"
                                    style={{ width: 80 }}
                                >
                                    <img
                                        src={
                                            seeker?.profileUrl ||
                                            profilePlaceholder_Applicant
                                        }
                                        style={{
                                            width: 80,
                                            height: 80,
                                        }}
                                        alt="profile"
                                        className="rounded-circle mb-3"
                                    />
                                    {seeker?.elevatorPitch && (
                                        <>
                                            <div
                                                className="position-absolute"
                                                style={{ bottom: 6, right: -6 }}
                                            >
                                                <img
                                                    src={playIcon}
                                                    alt=""
                                                    className="hand"
                                                    onClick={handlePlayVideo}
                                                />
                                            </div>
                                            <JobluVideoPlayer
                                                src={seeker?.elevatorPitch}
                                                play={playVideo}
                                                setPlayVideo={setPlayVideo}
                                            />
                                        </>
                                    )}
                                </div>
                                <h6 className="text-primary text-capitalize">
                                    <strong>{seeker?.firstName}</strong>
                                </h6>
                                <small className="text-primary">
                                    {seeker.preferredTitle}
                                </small>
                                {seeker.educationHistory.length > 0 && (
                                    <>
                                        <p className="small">
                                            <img
                                                src={educationIcon}
                                                alt=""
                                                className="me-2"
                                                style={{ width: 22 }}
                                            />{' '}
                                             {
                                                seeker?.educationHistory?.sort(function(a,b)
                                                {
                                                    return new Date(b.educStartDate) - new Date(a.educStartDate);
                                                })[0]?.educName
        
                                            }
                                        </p>
                                    </>
                                )}
                                {seeker?.currentLocation && (
                                    <>
                                        <p className="small">
                                            <img
                                                src={locationIcon}
                                                alt=""
                                                className="me-2"
                                                style={{ width: 22 }}
                                            />{' '}
                                            {seeker?.currentLocation}
                                        </p>
                                    </>
                                )}
                                {getYearsOfExp(seeker) &&
                                    getYearsOfExp(seeker) !== null && (
                                        <p className="small">
                                            <img
                                                src={experienceIcon}
                                                alt=""
                                                className="me-2"
                                                style={{ width: 22 }}
                                            />{' '}
                                            {`${getYearsOfExp(
                                                seeker
                                            )} Years of Experience`}
                                        </p>
                                    )}

                                {seeker?.salaryMin &&
                                    seeker?.salaryMin !== null && (
                                        <p className="small">
                                            <img
                                                src={salaryIcon}
                                                alt=""
                                                className="me-2"
                                                style={{ width: 22 }}
                                            />{' '}
                                            {seeker?.salaryMin === 1 &&
                                            seeker?.salaryMax === 1
                                                ? salaryRanges.COMPETIIVE_SALARY
                                                : seeker?.salaryMin === 2 &&
                                                  seeker?.salaryMax === 2
                                                ? salaryRanges.INDUSTRY_STANDARD
                                                : `${formatMoney(
                                                      seeker?.salaryMin,
                                                      currencyOptions,
                                                      seeker?.salaryCurrency ||
                                                          'SGD'
                                                  )} - ${formatMoney(
                                                      seeker?.salaryMax,
                                                      currencyOptions,
                                                      seeker?.salaryCurrency ||
                                                          'SGD'
                                                  )}`}
                                        </p>
                                    )}
                            </div>
                        </div>
                        {seeker?.skills?.length > 0 && (
                            <>
                                <div className="mb-3">
                                    <div className="d-flex">
                                        <div className="me-1">
                                            <img
                                                src={skillsIcon}
                                                alt=""
                                                style={{ width: '24px' }}
                                            />
                                        </div>
                                        <div>
                                            <p
                                                className="mb-2 text-primary"
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Technical Skills
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        {seeker?.skills
                                            ?.slice(0, 5)
                                            .map((skill) => {
                                                return (
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                '#009CDE',
                                                            borderRadius:
                                                                '12px',
                                                            padding: '0 8px',
                                                            color: '#ffffff',
                                                            fontSize: 10,
                                                        }}
                                                        className="me-2 mb-1 text-nowrap"
                                                    >
                                                        {skill}
                                                    </span>
                                                )
                                            })}
                                        {seeker?.skills.length > 5 && (
                                            <>
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            '#009CDE',
                                                        borderRadius: '12px',
                                                        padding: '0 16px',
                                                        color: '#ffffff',
                                                        fontSize: 12,
                                                    }}
                                                    className="me-2 mb-1 text-nowrap"
                                                >
                                                    +{' '}
                                                    {seeker?.skills.length - 5}{' '}
                                                    more
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {seeker?.softSkills.length > 0 && (
                            <>
                                <div className="mb-3">
                                    <div className="d-flex ">
                                        <div className="me-3">
                                            <img
                                                src={softSkillIcon}
                                                alt=""
                                                style={{
                                                    width: '24px',
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <h6 className="mb-2 text-primary">
                                                Soft Skills
                                            </h6>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex flex-wrap">
                                            {seeker?.softSkills
                                                ?.slice(0, 5)
                                                .map((skill) => {
                                                    return (
                                                        <span
                                                            style={{
                                                                backgroundColor:
                                                                    '#009CDE',
                                                                borderRadius:
                                                                    '12px',
                                                                padding:
                                                                    '0 16px',
                                                                color: '#ffffff',
                                                                fontSize: 12,
                                                            }}
                                                            className="me-2 mb-1 text-nowrap"
                                                        >
                                                            {skill}
                                                        </span>
                                                    )
                                                })}
                                            {seeker?.softSkills.length > 5 && (
                                                <>
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                '#009CDE',
                                                            borderRadius:
                                                                '12px',
                                                            padding: '0 16px',
                                                            color: '#ffffff',
                                                            fontSize: 12,
                                                        }}
                                                        className="me-2 mb-1 text-nowrap"
                                                    >
                                                        +{' '}
                                                        {seeker?.softSkills
                                                            .length - 5}{' '}
                                                        more
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {seeker?.experienceHistory.length > 0 && (
                            <>
                                <div className="d-flex">
                                    <div className="me-3">
                                        <img
                                            src={recentExpIcon}
                                            alt=""
                                            style={{ width: '24px' }}
                                        />
                                    </div>
                                    <div>
                                        <h6 className="mb-2 text-primary">
                                            Most Recent Experience
                                        </h6>
                                        <div>
                                            <p>
                                                <strong>
                                                    {
                                                        seeker
                                                            ?.experienceHistory[0]
                                                            ?.companyName
                                                    }
                                                </strong>
                                            </p>
                                            <p>
                                                {
                                                    seeker?.experienceHistory[0]
                                                        ?.jobTitle
                                                }
                                            </p>
                                            <p>
                                                {
                                                    seeker?.experienceHistory[0]
                                                        ?.location
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div
                    className="position-absolute text-center"
                    style={{
                        bottom: '84px',
                        left: 0,
                        // top: 0,
                        right: 0,
                        height: '60px',
                        background:
                            'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 54%, rgba(255,255,255,0.25253851540616246) 100%)',
                    }}
                >
                    <p
                        className="mt-4 hand"
                        onClick={() => {
                            handleShowProfileModal()
                        }}
                    >
                        <strong>See more</strong>
                    </p>
                </div>
                <div
                    className="position-absolute py-3 w-100 border-top"
                    style={{ bottom: 0, background: '#fff' }}
                >
                    <div className="d-flex  h-100">
                        <div className="d-flex my-auto w-100  px-3 justify-content-evenly">
                            <div>
                                <img
                                    src={DismissIcon}
                                    alt="icon"
                                    role="button"
                                    onClick={() =>
                                        handleAction(
                                            seeker,
                                            defaultActions.left,
                                            SLIDE_CLASSES.SLIDE_LEFT
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <img
                                    src={SuperLikeIcon}
                                    alt="icon"
                                    role="button"
                                    onClick={() =>
                                        handleAction(
                                            seeker,
                                            defaultActions.top,
                                            SLIDE_CLASSES.SLIDE_TOP
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <img
                                    src={LikeIcon}
                                    alt="icon"
                                    role="button"
                                    onClick={() =>
                                        handleAction(
                                            seeker,
                                            defaultActions.right,
                                            SLIDE_CLASSES.SLIDE_RIGHT
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="card shadow-sm  mb-2 d-none"
                style={{ height: 431, borderRadius: 12, overflow: 'hidden' }}
            >
                <div className="pt-0 h-100">
                    <div
                        className="w-100  p-3"
                        style={{
                            background: `url(${TilePatternBg})`,
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="d-flex justify-content-center">
                            <img
                                src={
                                    seeker?.profileUrl ||
                                    profilePlaceholder_Applicant
                                }
                                style={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: 5,
                                }}
                                alt="profile"
                                className="mb-3"
                            />
                        </div>
                        <h6
                            className="poppins mb-0 mt-0 text-center fw-bold text-primary hand"
                            onClick={() => {
                                // handleViewProfile()
                                handleShowProfileModal()
                            }}
                        >
                            {titleCase(`${seeker?.firstName}`)}
                        </h6>
                        <p className="fs-14 open-sans fw-bold text-center text-primary text-capitalize">
                            <strong>{`${seeker?.preferredTitle}`}</strong>{' '}
                        </p>
                    </div>
                    <div className="w-100 mb-4 p-2">
                        <div className="d-flex">
                            <div>
                                <img src={skillsIcon} alt="" className="me-2" />
                            </div>
                            <p
                                className="fs-12 open-sans text-muted fw-bold"
                                style={{ lineHeight: '24px' }}
                            >
                                <span className="text-dark" fw-bold>
                                    Skills:
                                </span>{' '}
                                {`: ${seeker?.skills?.join(', ')}`}
                                {/* {seeker?.skills?.length > 5 && (
                                <span className="ms-3 link">
                                    {seeker?.skills?.length - 5} more
                                </span>
                            )} */}
                            </p>
                        </div>
                        {seeker?.workSetup?.length > 0 && (
                            <div className="d-flex">
                                <div>
                                    <img
                                        src={workSetupIcon}
                                        alt=""
                                        className="me-2"
                                    />
                                </div>
                                <p
                                    className="fs-12 open-sans text-muted fw-bold"
                                    style={{ lineHeight: '24px' }}
                                >
                                    <span className="text-dark" fw-bold>
                                        Work Setup:
                                    </span>{' '}
                                    {`: ${seeker?.workSetup?.join(', ')}`}
                                </p>
                            </div>
                        )}
                        {seeker?.employmentType?.length > 0 && (
                            <div className="d-flex">
                                <div>
                                    <img
                                        src={employmentTypeIcon}
                                        alt=""
                                        className="me-2"
                                    />
                                </div>
                                <p
                                    className="fs-12 open-sans text-muted fw-bold"
                                    style={{ lineHeight: '24px' }}
                                >
                                    <span className="text-dark" fw-bold>
                                        Employment Type:
                                    </span>{' '}
                                    {`: ${seeker?.employmentType?.join(', ')}`}
                                </p>
                            </div>
                        )}
                    </div>
                    <div
                        className="position-absolute py-3 w-100 border-top blur-content"
                        style={{ bottom: 0, background: '#fff' }}
                    >
                        <div className="d-flex  h-100 blur-content">
                            <div className="d-flex my-auto w-100  px-3 justify-content-evenly blur-content">
                                <div>
                                    <img
                                        src={DismissIcon}
                                        alt="icon"
                                        role="button"
                                        onClick={() =>
                                            handleAction(
                                                seeker,
                                                defaultActions.left,
                                                SLIDE_CLASSES.SLIDE_LEFT
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <img
                                        src={SuperLikeIcon}
                                        alt="icon"
                                        role="button"
                                        onClick={() =>
                                            handleAction(
                                                seeker,
                                                defaultActions.top,
                                                SLIDE_CLASSES.SLIDE_TOP
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <img
                                        src={LikeIcon}
                                        alt="icon"
                                        role="button"
                                        onClick={() =>
                                            handleAction(
                                                seeker,
                                                defaultActions.right,
                                                SLIDE_CLASSES.SLIDE_RIGHT
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { AppState: state.Generals }
}

export default connect(mapStateToProps, null)(ExploreGridViewCard2)
