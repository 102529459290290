import { makeStyles } from "@material-ui/core/styles";
import LoginBackground from "../../assets/img/LoginBackground.jpg";

export const login_styles = makeStyles((theme) => ({
  login_bg: {
    background: `url('${LoginBackground}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  input: {
    display: "none",
  },
  btn_upload: {
    backgroundColor: '#E4ECF3!important',
    color: '#009CDE!important',
    width: '100%!important',
    textTransform: 'none'
  }
}));
