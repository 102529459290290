const styles = {
  grid_card: {
    height: "431px",
    borderRadius: "20px",
    position: "relative",
  },
  side_bar_box: {
    marginBottom: "12px",
  },
};

export default styles;
