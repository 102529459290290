import { Box, Grid } from "@material-ui/core";
import { app_styles } from "../../general.styles";
import JobluTypography from "../Typography";

const PageTitle = (props) => {
  const { pageTitle } = props;
  const app_classes = app_styles(props);
  return (
    <>
      <Box
        className={`${app_classes.border.border_bottom} ${pageTitle !== 'Settings' && app_classes.margin.b3}`}
      >
        <Grid
          container
          justifyContent="center"
          className={`${app_classes.padding.y3} `}
        >
          <Grid item xs={10}>
            <JobluTypography.H6>{pageTitle}</JobluTypography.H6>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PageTitle;
