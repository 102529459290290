import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/reusables/Loading'
import userActions from '../../redux/user/actions'
import { userRequest } from '../../service/requests'

import './style.css'

function CompanyReview(props) {
    const navigate = useNavigate()
    const { User, getUser } = props
    const [employerData, setEmployerData] = useState(null)
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const [reviewDetails, setReviewDetails] = useState({
        name: '',
        jobTitle: '',
        review: '',
    })

    useEffect(() => {
        if (params?.id) {
            userRequest.getShowcaseEmployerData(params.id).then((res) => {
                setEmployerData(res?.data)
            })
        }
    }, [params])

    const handleSubmit = () => {
        setLoading(true)
        userRequest
            .submitEmployerReview(params?.id, reviewDetails)
            .then((res) => {
                window.location.replace('/')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleViewMobile = () => {
        window.location = `joblu-app://${window.location.pathname}`
    }

    return (
        <div className="promote-bg align-items-center d-flex" style={{ overFlow: 'scroll' }}>
            <div className="container">
                <div className="row position-relative">
                    <div className="col-md-10 offset-md-1">
                        <div className="card create-job-card">
                            <div className="card-body p-3 p-md-5">
                                <h4 className="text-muted mb-3">
                                    <strong>Company Review</strong>
                                </h4>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>
                                            Share your experience working for
                                            &nbsp;
                                            {employerData?.companyName}
                                        </strong>
                                    </label>
                                    <textarea
                                        name=""
                                        className="form-control br-3"
                                        id=""
                                        cols="30"
                                        rows="5"
                                        onChange={(e) => {
                                            setReviewDetails({
                                                ...reviewDetails,
                                                review: e.target.value,
                                            })
                                        }}
                                    ></textarea>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Your Name</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control br-3"
                                        placeholder="Jhon Louix Alconaba"
                                        onChange={(e) => {
                                            setReviewDetails({
                                                ...reviewDetails,
                                                name: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Job Title</strong>{' '}
                                        <span className="required-tag">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control br-3"
                                        placeholder="UI/UX Designer"
                                        onChange={(e) => {
                                            setReviewDetails({
                                                ...reviewDetails,
                                                jobTitle: e.target.value,
                                            })
                                        }}
                                    />
                                </div>

                                <div className='col-md-12'>
                                    <button
                                        className="btn btn-primary text-center me-3 mb-2"
                                        disabled={
                                            !reviewDetails.jobTitle ||
                                            !reviewDetails.name ||
                                            !reviewDetails.review
                                        }
                                        onClick={handleSubmit}
                                    >
                                        Submit Company Review
                                    </button>
                                    <button
                                        className="btn btn-primary text-center me-3 mb-2"
                                        onClick={handleViewMobile}
                                    >
                                        Review on Mobile
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={loading}
                centered
                contentClassName="bg-transparent border-0"
            >
                <Loading show color="primary" />
            </Modal>
        </div>
    )
}
const mapDispatchToProps = {
    getUser: userActions.getUser,
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyReview)
