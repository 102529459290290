import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import helpIcon2 from '../../../assets/img/helpIcon2.svg'
import moreVerticalIcon from '../../../assets/img/moreVerticalIcon.svg'
import CustomAlert from '../../../components/reusables/Alert/Alert'
import CustomTooltip from '../../../components/reusables/CustomTooltip'
import Stepper from '../../../components/reusables/Stepper'
import { jobsRequest } from '../../../service/requests'
import { readableDate, titleCase } from '../../../utils/helpers'
import useWindowDimensions from '../../../utils/hooks'
import '../styles.css'
import JobsListCard from './JobsListCard'
import AppliedIcon from '../../../assets/img/my-jobs/appliedIcon.png'
import ArchiveIcon from '../../../assets/img/my-jobs/archiveIcon.png'
import ShortlistedIcon from '../../../assets/img/my-jobs/shortlistedIcon.png'
import InterviewsIcon from '../../../assets/img/my-jobs/interviewsIcon.png'
import ChevDownIcon from '../../../assets/img/my-jobs/chevDownIcon.png'
export const JobModal = ({ showModal, setShowModal, handleProceedModal }) => {
    return (
        <Modal
            show={!!showModal}
            onHide={() => setShowModal(null)}
            className="p-0"
            centered
        >
            <div className="bg-primary py-3 mx-0">
                <h5 className="text-center text-light my-0">
                    {getContent(showModal)?.title}
                </h5>
            </div>
            <div className="p-4">
                <h6 className="text-center">{getContent(showModal)?.body}</h6>
                <div className="d-flex justify-content-center mt-4">
                    <button
                        className="btn btn-outline-primary me-2"
                        onClick={() => setShowModal(null)}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => handleProceedModal()}
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </Modal>
    )
}
export function getContent(selectedjob) {
    const { data, status } = selectedjob
    switch (status) {
        case 'active':
            return {
                title: 'Update Job Status',
                body: 'Are you sure you want to activate this job post?',
                alert: 'Job has been activated',
            }
        case 'closed':
            return {
                title: 'Update Job Status',
                body: "Are you sure you want to close this job post? Don't worry, you can resume it later, if needed.",
                alert: 'Job closed successfully.',
            }
        case 'paused':
            return {
                title: 'Update Job Status',
                body: "Are you sure you want to pause this job post? Don't worry, you can resume it later, if needed.",
                alert: 'Job paused successfully!',
            }
        case 'repost':
            return {
                title: 'Repost Job',
                body: `You are about to re-post this job: ${data?.jobTitle}.
                    This job will now be duplicated and made available
                    to candidates for application.`,
                alert: 'Job successfully reposted!',
            }
        case 'archived':
            return {
                title: 'Delete Job',
                body: `You’re about to delete this job post. Continue?`,
                alert: 'Job successfully moved to archive',
            }
        default:
            return null
    }
}
function DataTable(props) {
    const {
        data,
        totalPage,
        setJobsPage,
        getJobs,
        tab,
        sortBy,
        setSortBy,
        sortingType,
        setSortingType,
        loading,
        setShowPromt,
        handleShowPrompt
    } = props
    const [showArchiveAlert, setShowArchiveAlert] = useState(null)
    const [showModal, setShowModal] = useState(null)
    const [showAlert, setShowAlert] = useState(null)
    const navigate = useNavigate()
    const onArchive = (id) => {
        setShowArchiveAlert(true)
    }

    const onArchiveYes = () => {
        setShowArchiveAlert(false)
        handleSetStatus(showArchiveAlert, 'archived')
    }

    const onArchiveNo = () => {
        setShowArchiveAlert(false)
    }

    const handleSetStatus = (data, status) => {
        console.log(data)
        console.log(status)
        setShowModal({ data, status })
    }

    const { width } = useWindowDimensions()

    const handleProceedModal = () => {
        try {
            if (!!showModal) {
                let payload = showModal?.data
                let status = showModal?.status
                delete payload.updatedAt
                if (status === 'repost') {
                    delete payload.createdAt
                    delete payload.status
                    jobsRequest
                        .createJob(payload)
                        .then((res) => {
                            getJobs()
                            setShowAlert(`${getContent(showModal)?.alert}`)
                        })
                        .catch((err) => {
                            console.log('ERROR when reposting job', err)
                        })
                        .finally(() => {
                            setShowModal(null)
                            setTimeout(() => {
                                setShowAlert(null)
                            }, 5000)
                        })
                } else {
                    payload = {
                        ...payload,
                        status,
                    }
                    jobsRequest
                        .updateJob(payload.id, { ...payload })
                        .then((res) => {
                            getJobs()
                            setShowAlert(`${getContent(showModal)?.alert}`)
                        })
                        .finally(() => {
                            setShowModal(null)
                            setTimeout(() => {
                                setShowAlert(null)
                            }, 3000)
                        })
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleSort = (sort_by) => {
        setSortingType(sortingType === 'asc' ? 'desc' : 'asc')
        setSortBy(sort_by)
    }

    return (
        <>
            <CustomAlert
                show={!!showAlert}
                autoHide
                body={showAlert}
                position="bottom-end"
            />

            {width < 640 ? (
                <>
                    {data.map((row, index) => {
                        return (
                            <JobsListCard
                                row={row}
                                tab={tab}
                                handleSetStatus={handleSetStatus}
                            />
                        )
                    })}
                    <div className="mt-2">
                        <Stepper
                            steps={totalPage}
                            onChange={(step) => setJobsPage(step + 1)}
                            id={tab}
                            loading={loading}
                        />
                    </div>
                </>
            ) : (
                <div
                    className="table-responsive bg-white"
                    style={{ minHeight: '65vh' }}
                >
                    <table className="w-100 table table-hover">
                        <thead className="table-head">
                            {tab == 'drafts' ? (
                                <>
                                    <tr>
                                        <th
                                         className="text-start">Job Title</th>
                                        <th
                                         className="text-start">Updated</th>
                                        <th
                                         className="text-start">Status</th>
                                        <th
                                         className="text-start">Action</th>
                                    </tr>
                                </>
                            ) : (
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-start"
                                        role="button"
                                        onClick={() => {
                                            handleSort('jobTitle')
                                        }}
                                    >
                                        Job Title
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center"
                                        role="button"
                                        onClick={() => {
                                            handleSort('applicants')
                                        }}
                                    >
                                        Updated
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center"
                                        role="button"
                                        onClick={() => {
                                            handleSort('applicants')
                                        }}
                                    >
                                        <img
                                            src={AppliedIcon}
                                            className="me-1"
                                            alt=""
                                        />
                                        Applicants
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center"
                                        role="button"
                                        onClick={() => {
                                            handleSort('applicants')
                                        }}
                                    >
                                        <img
                                            src={ShortlistedIcon}
                                            className="me-1"
                                            alt=""
                                        />
                                        Shortlisted
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center"
                                        role="button"
                                        onClick={() => {
                                            handleSort('applicants')
                                        }}
                                    >
                                        <img
                                            src={InterviewsIcon}
                                            className="me-1"
                                            alt=""
                                        />
                                        Interviews
                                    </th>
                                    {/* <th
                                    scope="col"
                                    className="text-center"
                                    role="button"
                                    onClick={() => {
                                        handleSort('applicants')
                                    }}
                                >
                                    Applicants
                                </th>
                                <th
                                    scope="col"
                                    className="text-start text-nowrap"
                                    role="button"
                                    onClick={() => {
                                        handleSort('jobExpiry')
                                    }}
                                >
                                    Expires On{' '}
                                    <CustomTooltip title="Your job post will remain active on Joblu until this date.">
                                        <img
                                            src={helpIcon2}
                                            style={{
                                                width: 15,
                                                height: 16,
                                                top: -10,
                                            }}
                                            alt=""
                                        />
                                    </CustomTooltip>
                                </th> */}
                                    <th scope="col" className="text-center">
                                        Status{' '}
                                        <CustomTooltip title="This status indicates if you are actively seeking for candidates">
                                            <img
                                                src={helpIcon2}
                                                style={{
                                                    width: 15,
                                                    height: 16,
                                                    top: -10,
                                                }}
                                                alt=""
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Tooltip on top"
                                            />
                                        </CustomTooltip>
                                    </th>
                                    <th scope="col" className="text-center">
                                        Actions
                                    </th>
                                </tr>
                            )}
                        </thead>
                        <tbody>
                            {data.map((row, index) => {
                                if (tab === 'drafts') {
                                    return (
                                        <>
                                            <tr key={row?._id} className="">
                                                <td>
                                                    {' '}
                                                    <p className="table-job-title m-0 text-nowrap link">
                                                        {titleCase(
                                                            row?.jobTitle
                                                        )}
                                                    </p>
                                                    <p className="table-job-title-caption m-0">
                                                        <span className="fs-10 text-muted">
                                                            Created at{' '}
                                                            {readableDate(
                                                                row?.createdAt
                                                            )}
                                                        </span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="mt-2 cpointer">
                                                        {readableDate(
                                                            row?.updatedAt
                                                        )}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="mt-2 cpointer">
                                                        Draft
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                id="actionBtn"
                                                            >
                                                                <img
                                                                    src={
                                                                        moreVerticalIcon
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                        width: 4.16,
                                                                        height: 20,
                                                                        marginTop: 10,
                                                                    }}
                                                                    className="m-auto"
                                                                />
                                                            </button>
                                                            <ul
                                                                className="dropdown-menu text-start"
                                                                aria-labelledby="actionBtn"
                                                                style={{
                                                                    right: 50,
                                                                }}
                                                            >
                                                                <li
                                                                    className="dropdown-item"
                                                                    role="button"
                                                                    onClick={() => {
                                                                        navigate(
                                                                            'edit',
                                                                            {
                                                                                state: {
                                                                                    job: row,
                                                                                },
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    Edit
                                                                </li>
                                                                <li
                                                                    className="dropdown-item"
                                                                    role="button"
                                                                    onClick={() => {
                                                                        handleShowPrompt(
                                                                            row, index
                                                                        )
                                                                    }}
                                                                >
                                                                    Delete
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                                return (
                                    <tr key={row?._id} className="">
                                        <td
                                            onClick={() => {
                                                navigate(
                                                    `/matches?job=${row?.id}`,
                                                    {
                                                        state: {
                                                            tab: 'Applied',
                                                        },
                                                    }
                                                )
                                            }}
                                            role="button"
                                        >
                                            <p className="table-job-title m-0 text-nowrap link">
                                                {titleCase(row?.jobTitle)}
                                            </p>
                                            <p className="table-job-title-caption m-0">
                                                <span className="fs-10 text-muted">
                                                    Created at{' '}
                                                    {readableDate(
                                                        row?.createdAt
                                                    )}
                                                </span>
                                            </p>
                                            {/* <p className="table-job-title-caption m-0">
                                                <span className="fs-10 text-muted">
                                                    Updated at{' '}
                                                    {readableDate(
                                                        row?.updatedAt
                                                    )}
                                                </span>
                                            </p> */}
                                        </td>
                                        <td
                                            onClick={() => {
                                                navigate(
                                                    `/matches?job=${row?.id}`,
                                                    {
                                                        state: {
                                                            tab: 'Applied',
                                                        },
                                                    }
                                                )
                                            }}
                                            role="button"
                                            className="text-center  table-tr-default "
                                        >
                                            <p className="mt-2 cpointer">
                                                {readableDate(row?.updatedAt)}
                                            </p>
                                        </td>
                                        <td className="text-center  table-tr-default ">
                                            <p
                                                className="mt-2 cpointer text-info"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    navigate(
                                                        `/matches?job=${row?.id}`,
                                                        {
                                                            state: {
                                                                tab: 'Applied',
                                                            },
                                                        }
                                                    )
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            row?.applicants >
                                                                0 && 800,

                                                        color: '#009CDE',
                                                    }}
                                                >
                                                    {' '}
                                                    {row?.applicants}
                                                </span>
                                            </p>
                                        </td>
                                        <td className="text-center  table-tr-default ">
                                            <p
                                                className="mt-2 cpointer text-info"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    navigate(
                                                        `/matches?job=${row?.id}`,
                                                        {
                                                            state: {
                                                                tab: 'Shortlisted',
                                                            },
                                                        }
                                                    )
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            row?.shortlisted >
                                                                0 && 800,

                                                        color: '#009CDE',
                                                    }}
                                                >
                                                    {row?.shortlisted}
                                                </span>
                                            </p>
                                        </td>
                                        <td className="text-center  table-tr-default ">
                                            <p
                                                className="mt-2 cpointer"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    navigate(
                                                        `/matches?job=${row?.id}`,
                                                        {
                                                            state: {
                                                                tab: 'Interviews',
                                                            },
                                                        }
                                                    )
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            row?.interviews >
                                                                0 && 800,
                                                        color: '#009CDE',
                                                    }}
                                                >
                                                    {row?.interviews}
                                                </span>
                                            </p>
                                        </td>
                                        {/* <td className="text-center  table-tr-default ">
                                            <p
                                                className="mt-2 cpointer"
                                                onClick={() => {
                                                    row?.jobApplicants
                                                        ?.applicants?.length >=
                                                        0 &&
                                                        navigate(
                                                            `/matches?job=${row?.id}`
                                                        )
                                                }}
                                            >
                                                {row?.jobApplicants?.applicants
                                                    ?.length || 0}
                                            </p>
                                        </td>
                                        <td className="text-start my-auto table-tr-default">
                                            <p className="mt-2">
                                                {moment(row?.jobExpiry).format(
                                                    'MMM DD,  YYYY'
                                                )}
                                            </p>
                                        </td> */}
                                        <td className="text-center table-tr-default ">
                                            <div className="d-flex justify-content-center">
                                                <div className="dropdown text-center">
                                                    <button
                                                        className={`btn text-capitalize btn-outline-secondary btn-drop  ${
                                                            tab !== 'active'
                                                                ? 'text-center'
                                                                : 'justify-content-between d-flex'
                                                        } `}
                                                        id="statusBtn"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                        style={{ width: 150 }}
                                                        disabled={
                                                            tab === 'archived'
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            let action = ''
                                                            if (
                                                                tab !== 'active'
                                                            ) {
                                                                if (
                                                                    tab ===
                                                                    'paused'
                                                                )
                                                                    action =
                                                                        'active'
                                                                if (
                                                                    tab ===
                                                                    'closed'
                                                                )
                                                                    action =
                                                                        'repost'
                                                                handleSetStatus(
                                                                    row,
                                                                    action
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        <div>{row?.status}</div>
                                                        {tab == 'active' && (
                                                            <div>
                                                                <img
                                                                    src={
                                                                        ChevDownIcon
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                        )}
                                                    </button>
                                                    {tab !== 'paused' &&
                                                        tab !== 'closed' && (
                                                            <ul
                                                                className="dropdown-menu text-center"
                                                                aria-labelledby="statusBtn"
                                                                style={{
                                                                    right: 50,
                                                                }}
                                                            >
                                                                {tab ===
                                                                    'active' && (
                                                                    <>
                                                                        <li
                                                                            className={`dropdown-item ${
                                                                                row.status ===
                                                                                    'active' &&
                                                                                'bg-primary text-light'
                                                                            }`}
                                                                            role="button"
                                                                            onClick={() =>
                                                                                handleSetStatus(
                                                                                    row,
                                                                                    'active'
                                                                                )
                                                                            }
                                                                        >
                                                                            Active
                                                                        </li>
                                                                        <li
                                                                            className={`dropdown-item ${
                                                                                row.status ===
                                                                                    'paused' &&
                                                                                'bg-primary text-light'
                                                                            }`}
                                                                            role="button"
                                                                            onClick={() =>
                                                                                handleSetStatus(
                                                                                    row,
                                                                                    'paused'
                                                                                )
                                                                            }
                                                                        >
                                                                            Pause
                                                                        </li>
                                                                        <li
                                                                            className={`dropdown-item ${
                                                                                row.status ===
                                                                                    'closed' &&
                                                                                'bg-primary text-light'
                                                                            }`}
                                                                            role="button"
                                                                            onClick={() =>
                                                                                handleSetStatus(
                                                                                    row,
                                                                                    'closed'
                                                                                )
                                                                            }
                                                                        >
                                                                            Close
                                                                        </li>
                                                                        {/* <li
                                                            className={`dropdown-item ${
                                                                row.status ===
                                                                    'archived' &&
                                                                'bg-primary text-light'
                                                            }`}
                                                            role="button"
                                                            onClick={() =>
                                                                setShowArchiveAlert(
                                                                    row
                                                                )
                                                            }
                                                        >
                                                            Archive
                                                        </li> */}
                                                                    </>
                                                                )}
                                                            </ul>
                                                        )}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {tab !== 'archived' && (
                                                <div className="dropdown">
                                                    <button
                                                        className="btn"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                        id="actionBtn"
                                                    >
                                                        <img
                                                            src={
                                                                moreVerticalIcon
                                                            }
                                                            alt=""
                                                            style={{
                                                                width: 4.16,
                                                                height: 20,
                                                                marginTop: 10,
                                                            }}
                                                            className="m-auto"
                                                        />
                                                    </button>
                                                    <ul
                                                        className="dropdown-menu text-start"
                                                        aria-labelledby="actionBtn"
                                                        style={{ right: 50 }}
                                                    >
                                                        <li
                                                            className="dropdown-item"
                                                            role="button"
                                                            onClick={() => {
                                                                navigate(
                                                                    `${row.id}`,
                                                                    {
                                                                        state: {
                                                                            job: row,
                                                                        },
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            Public View
                                                        </li>
                                                        <li
                                                            className="dropdown-item"
                                                            role="button"
                                                            onClick={() =>
                                                                navigate(
                                                                    'edit',
                                                                    {
                                                                        state: {
                                                                            job: row,
                                                                        },
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Edit
                                                        </li>
                                                        <li
                                                            className="dropdown-item"
                                                            role="button"
                                                            onClick={() => {
                                                                handleSetStatus(
                                                                    row,
                                                                    'archived'
                                                                )
                                                            }}
                                                        >
                                                            Delete
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <div
                        className={`${
                            totalPage > 1 ? 'd-flex' : 'd-none'
                        } w-100 justify-content-center mt-3`}
                    >
                        <div className="" style={{ maxWidth: 400 }}>
                            <Stepper
                                steps={totalPage}
                                onChange={(step) => setJobsPage(step + 1)}
                                id={tab}
                            />
                        </div>
                    </div>
                </div>
            )}
            {!!showModal && (
                <JobModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    handleProceedModal={handleProceedModal}
                />
            )}
        </>
    )
}

export default DataTable
