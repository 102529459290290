import registerImg from '../../../assets/img/register/registerImg.png'
import img2 from '../../../assets/img/register/img2.png'
import horizontalLogo from '../../../assets/img/logo-h.png'
import LogoWTag from '../../../assets/img/logo-w-tag.svg'
import img1 from '../../../assets/img/jobseeker-register/img1.png'
export const LeftCol = () => {
    return (
        <>
            <div className="d-flex align-items-center flex-column justify-content-center h-100 my-4 my-md-0 mt-0">
                <div className="text-center mt-5 ">
                    <img src={LogoWTag} alt="" className="mb-4 mt-md-0" />
                    <div className="d-none d-md-block">
                        <h1 className="text-primary">
                            Meet your <strong>best new employee</strong> here
                        </h1>
                    </div>
                    <div className="d-block d-md-none">
                        <h5 className="text-primary">
                            Meet your <strong>best new employee</strong> here
                        </h5>
                    </div>
                    <div className="d-none d-md-block text-center">
                        <img
                            src={img2}
                            alt=""
                            className="mt-3"
                            style={{
                                maxWidth: '496px',
                                maxHeight: '496px',
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
