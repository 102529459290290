import axios from "axios";
import { STRIPE_CLIENT_TOKEN } from "../utils/constants";
import api, { APPLICANTS_API } from "./api";
import axiosInstance from "./axios";

let cancelToken;
let source;

export const processParams = (api, payload) => {
  if (payload !== undefined) {
    let url = api;
    // if (payload?.limit) {
    //   payload["limit"] = 20;
    // }
    // if (!payload?.offset) {
    //   payload["offset"] = 1;
    // }
    for (const key in payload) {
      var separator = url.indexOf("?") !== -1 ? "&" : "?";
      if (!!payload[key]) {
        //checks for the truthy condition of the options[key]
        if (typeof payload[key] === "string")
          payload[key] = payload[key].trim(); //you can also add conditions for the keys which you want to trim.
        url += separator + key + "=" + payload[key];
      }
    }
    console.log("url => ", url);
    return url;
  } else {
    return `${api}`;
  }
};

export const cancelRequest = () => source.cancel();
export const multipleRequest = (request) => axios.all(request);
export const userRequest = {
  signIn: (data) => axiosInstance.post(api.USER_SIGN_IN, data),
  signOut: (data) => axiosInstance.post(api.USER_SIGN_OUT, data),
  signUp: (data) => axiosInstance.post(api.USER_SIGN_UP, data),
  phoneAuth: (data) => axiosInstance.post(api.USER_PHONE_AUTH, data),
  confirmPhoneCode: (data) =>
    axiosInstance.post(api.USER_CONFIRM_PHONE_CODE, data),
  retrieveAppleEmail: (data) =>
    axiosInstance.post(api.USER_RETRIEVE_APPLE_EMAIL, data),
  createApplicant: (data) => axiosInstance.post(api.CREATE_APPLICANT, data),
  createEmployer: (data) => axiosInstance.post(api.CREATE_EMPLOYER, data),
  getUser: () => axiosInstance.get(api.GET_USER),
  getApplicantCompletion: () => axiosInstance.get(api.GET_APPLICANT_COMPLETION),
  getEmployerDetailsP: (id) =>
    axiosInstance.get(`${api.GET_EMPLOYER_DETAILS}/${id}`),
  getSpecificUser: (data) =>
    axiosInstance.get(`${api.GET_SPECIFIC_USER}/${data?.id}`),
  updateUser: (data) =>
    axiosInstance.patch(`${api.UPDATE_USER}/${data.userId}`, data),

  resetPassword: (data) => axiosInstance.post(api.RESET_PASSWORD, data),
  addView: (data) => axiosInstance.post(api.ADDVIEW, data),
  changePassword: (data) => axiosInstance.post(api.CHANGE_PASSWORD, data),
  changeResetPassword: (data) =>
    axiosInstance.post(api.CHANGE_RESET_PASSWORD, data),
  getApplicantDetails: (userId) => {
    const response = axiosInstance.get(`/users/${userId}`);
    response.catch((e) => {
      console.log("Inside axiox error ");
      console.log(e);
    });
    return response;
  },
  renewAccessToken: async (data) => {
    const response = await axiosInstance.post(api.RENEW_ACCESS_TOKEN, {
      token: data.refreshToken,
    });
    data.token = response.data.accessToken;
    data.refreshToken = response.data.refreshToken;
    return data;
  },
  getEmployerDashboardDetails: () => axiosInstance.get(api.EMPLOYER_DASHBOARD),
  getApplicantsAppliedJobs: () => axiosInstance.get(api.GET_APPLIED_JOBS),
  getApplicantsActiveJobs: () => axiosInstance.get(api.GET_APPLIED_ACTIVE_JOBS),
  getEmployerJobs: (id,payload) => axiosInstance.get(processParams(`${api.GET_EMPLOYER_JOBS}/${id}`, payload)),
  getApplicantsShortlistedJobs: () =>
    axiosInstance.get(api.GET_APPLIED_SHORTLISTED_JOBS),
  getApplicantsRejectedJobs: () =>
    axiosInstance.get(api.GET_APPLIED_REJECTED_JOBS),
  getApplicantsSavedJobs: () => axiosInstance.get(api.GET_SAVED_JOBS),
  getCurrentJobApplicationStatus: (jobId) => {
    return axiosInstance.get(`users/jobs/${jobId}/application/status`);
  },
  acceptJobOffer: (jobId) => {
    return axiosInstance.post(`users/jobs/${jobId}/acceptOffer`);
  },
  uploadFile: (file) => {
    return axiosInstance.post("users/upload", file, {
      headers: {
        "Content-Type": "multipart/form-data; ",
      },
    });
  },
  uploadVideo: (file) => {
    return axiosInstance.post("users/upload/video", file, {
      headers: {
        "Content-Type": "multipart/form-data; ",
      },
    });
  },
  companyCultureUploadFile: (file, type) => {
    return axiosInstance.post(`users/upload/companyculture/${type}`, file, {
      headers: {
        "Content-Type": "multipart/form-data; ",
      },
    });
  },
  uploadVideo: (file) => {
    return axiosInstance.post("users/upload/video", file, {
      headers: {
        "Content-Type": "multipart/form-data; ",
      },
    });
  },
  uploadVidFromheart: (id, formdata) => {
    return axiosInstance.post(`/users/upload/vidfromheart/${id}`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data; ",
      },
    });
  },
  updateApplicant: (data) => axiosInstance.patch(api.UPDATE_APPLICANT, data),
  updateEmployer: (data) => axiosInstance.patch(api.UPDATE_EMPLOYER, data),
  sendDeviceToken: (data) => axiosInstance.post(api.SEND_TOKEN, data),
  sendNotification: (data) => axiosInstance.post(api.SEND_NOTIFICATION, data),
  verifyEmail: (data) => axiosInstance.post(api.USER_VERIFY_EMAIL, data),
  resendVerificationEmail: (data) =>
    axiosInstance.post(api.RESEND_VERIFICATION_EMAIL, data),
  checkPhoneNo: (data) =>
    axiosInstance.get(`users/checkphone/${data.phoneNumber}`),
  validateInviteCode: (data) =>
    axiosInstance.get(`invite-code/${data.inviteCode}`),
  requestInviteCode: (data) =>
    axiosInstance.post(api.REQUEST_INVITE_CODE, data),
  validateInfluencerCode: (data) =>
    axiosInstance.get(`influencer-code/${data.influencerCode}`),
  sendContactUs: (data) => axiosInstance.post(api.CONTACT_US, data),
  getNotifications: (data) => {
    if (!data) {
      return axiosInstance.get(`${api.GET_NOTIFICATIONS}?limit=10&offset=1`);
    } else {
      return axiosInstance.get(
        `${api.GET_NOTIFICATIONS}?limit=10&offset=${data?.offset}`
      );
    }
  },
  readNotification: (data) =>
    axiosInstance.patch(api.UPDATE_NOTIFICATION, data),
  readAllNotifications: (data) =>
    axiosInstance.patch(api.READ_ALL_NOTIFICATIONS),
  updateUserStatus: (data) =>
    axiosInstance.patch(`/users/${data.userId}/status`, data.data),
  downloadProfile: (data) => {
    return axiosInstance.get(`${api.DOWNLOAD_PROFILE}/${data.userId}`);
  },

  //apple google
  verifyGoogleLogin: (data) =>
    axiosInstance.post(api.USER_VERIFY_GOOGLE_LOGIN, data),
  verifyAppleLogin: (data) =>
    axiosInstance.post(api.USER_VERIFY_APPLE_LOGIN, data),
  verifyGoogleRegisterApplicant: (data) =>
    axiosInstance.post(api.USER_VERIFY_GOOGLE_REGISTER_APPLICANT, data),
  verifyGoogleRegisterEmployer: (data) =>
    axiosInstance.post(api.USER_VERIFY_GOOGLE_REGISTER_EMPLOYER, data),
  verifyAppleRegisterApplicant: (data) =>
    axiosInstance.post(api.USER_VERIFY_APPLE_REGISTER_APPLICANT, data),
  verifyAppleRegisterEmployer: (data) =>
    axiosInstance.post(api.USER_VERIFY_APPLE_REGISTER_EMPLOYER, data),

  getShowcaseLinks: (data) => axiosInstance.get(api.GET_SHOWCASE_LINKS),
  getShowcaseEmployerData: (id) =>
    axiosInstance.get(`${api.GET_SHOWCASE_EMPLOYER_DATA}/${id}`),
  submitEmployerReview: (id, payload) =>
    axiosInstance.post(`${api.SUBMIT_EMPLOYER_REVIEW}/${id}`, payload),
  submitVideFromHeartLink: (id, payload) =>
    axiosInstance.post(`${api.SUBMIT_VIDEO_FROM_HEART}/${id}`, payload),
  validateEmail: (data) => {
    return axiosInstance.post(`${api.VALIDATE_EMAIL}/${data?.email}`);
  },
  generateLinkedinProfile: (data) =>
    axiosInstance.post(api.GENERATE_LINKEDIN_PROFILE, data),
  generateCvProfile: (data) =>
    axiosInstance.post(api.GENERATE_CV_PROFILE, data),
};

export const recommendationsRequest = {
  getRecommendationRef: () => axiosInstance.get(api.GET_RECOMMENDATION_REF),
  getRecommendationList: (data) =>
    axiosInstance.post(api.GET_RECOMMENDATION_LIST, data),
};

export const jobsRequest = {
  unmatchJob: (payload) => {
    console.log(payload);
    return axiosInstance.post(`${api.UNMATCH_JOB}`, payload);
  },
  getJobs: () => axiosInstance.get(api.GET_JOBS),
  getSpecificJob: (payload) =>
    axiosInstance.get(`${api.GET_SPECIFIC_JOB}/${payload.jobId}`),
  getJobIds: () => axiosInstance.get(api.GET_JOB_IDS),
  getJobsForLists: () => axiosInstance.get(api.GET_JOBS_FOR_LISTS),

  getJobDetails: (id) => axiosInstance.get(`${api.GET_JOB_DETAILS}/${id}`),
  getJobDetailsByRefId: (refId) =>
    axiosInstance.get(`${api.GET_JOB_DETAILS_BY_REF_ID}/${refId}`),
  getMatchStatus: (payload) =>
    axiosInstance.post(`${api.GET_MATCH_STATUS}`, payload),
  getPublishedJobs: (payload) => {
    if (payload !== undefined) {
      let url = api.GET_PUBLISHED_JOBS;
      payload["limit"] = 20;
      if (!payload?.offset) {
        payload["offset"] = 1;
      }
      for (const key in payload) {
        var separator = url.indexOf("?") !== -1 ? "&" : "?";
        if (!!payload[key]) {
          //checks for the truthy condition of the options[key]
          console.log(payload[key]);
          if (typeof payload[key] === "string")
            payload[key] = payload[key].trim(); //you can also add conditions for the keys which you want to trim.
          url += separator + key + "=" + payload[key];
        }
      }
      // console.log(url)
      return axiosInstance.get(url);
    } else {
      return axiosInstance.get(`${api.GET_PUBLISHED_JOBS}?limit=20&offset=1`);
    }
  },
  getArchivedJobs: (payload) => {
    if (payload !== undefined) {
      let url = api.GET_ARCHIVED_JOBS;
      payload["limit"] = 20;
      if (!payload?.offset) {
        payload["offset"] = 1;
      }
      for (const key in payload) {
        var separator = url.indexOf("?") !== -1 ? "&" : "?";
        if (!!payload[key]) {
          //checks for the truthy condition of the options[key]
          console.log(payload[key]);
          if (typeof payload[key] === "string")
            payload[key] = payload[key].trim(); //you can also add conditions for the keys which you want to trim.
          url += separator + key + "=" + payload[key];
        }
      }
      return axiosInstance.get(url);
    } else {
      return axiosInstance.get(`${api.GET_ARCHIVED_JOBS}?limit=20&offset=1`);
    }
  },
  getDraftJobs: (payload) =>
    axiosInstance.get(processParams(api.GET_DRAFT_JOBS, payload)),
  getPausedJobs: (payload) => {
    if (payload !== undefined) {
      let url = api.GET_PAUSED_JOBS;
      payload["limit"] = 20;
      if (!payload?.offset) {
        payload["offset"] = 1;
      }
      for (const key in payload) {
        var separator = url.indexOf("?") !== -1 ? "&" : "?";
        if (!!payload[key]) {
          //checks for the truthy condition of the options[key]
          console.log(payload[key]);
          if (typeof payload[key] === "string")
            payload[key] = payload[key].trim(); //you can also add conditions for the keys which you want to trim.
          url += separator + key + "=" + payload[key];
        }
      }
      return axiosInstance.get(url);
    } else {
      return axiosInstance.get(`${api.GET_PAUSED_JOBS}?limit=20&offset=1`);
    }
  },
  getClosedJobs: (payload) => {
    if (payload !== undefined) {
      let url = api.GET_CLOSED_JOBS;
      payload["limit"] = 20;
      if (!payload?.offset) {
        payload["offset"] = 1;
      }
      for (const key in payload) {
        var separator = url.indexOf("?") !== -1 ? "&" : "?";
        if (!!payload[key]) {
          //checks for the truthy condition of the options[key]
          console.log(payload[key]);
          if (typeof payload[key] === "string")
            payload[key] = payload[key].trim(); //you can also add conditions for the keys which you want to trim.
          url += separator + key + "=" + payload[key];
        }
      }
      return axiosInstance.get(url);
    } else {
      return axiosInstance.get(`${api.GET_CLOSED_JOBS}?limit=20&offset=1`);
    }
  },

  createJob: async (payload) =>
    await axiosInstance.post(api.CREATE_JOB, payload),
  updateJob: (id, payload) => axiosInstance.patch(`jobs/${id}`, payload),
  getRecommendedJobs: (payload) =>
    axiosInstance.get(processParams(api.GET_RECOMMENDED_JOBS, payload)),
  getPublicRecommendedJobs: (payload) =>
    axiosInstance.get(processParams(api.GET_RECOMMENDED_JOBS_PUBLIC, payload)),
  applyJob: (id, payload) => axiosInstance.post(`jobs/${id}/apply`, payload),
  searchJob: (payload) => axiosInstance.post(api.SEARCH_JOB, payload),
};

export const jobApplicantRequest = {
  getJobApplicants: (id) => {
    if (id !== null) {
      return axiosInstance.get(`/jobs/${id}/applicants`);
    }
    return null;
  },
  getJobApplicants_Applied: (id, payload) => {
    if (!!id) {
      return axiosInstance.get(
        processParams(`/job-match/employer/applied/${id}`, payload)
      );
      // return axiosInstance.get(`/jobs/${id.jobId}/applicants/applied/${id.jobId}`)
    }
    return null;
  },
  getJobApplicants_Shortlisted: (id, payload) => {
    if (id !== null) {
      return axiosInstance.get(
        processParams(`/job-match/employer/shortlisted/${id}`, payload)
      );
      // return axiosInstance.get(`/jobs/${id.jobId}/applicants/shortlisted`)
    }
    return null;
  },
  getJobApplicants_Interview: (id, paylaod) => {
    if (id !== null) {
      return axiosInstance.get(
        processParams(`/job-match/employer/scheduled_interview/${id}`, paylaod)
      );
      // return axiosInstance.get(`/jobs/${id.jobId}/applicants/shortlisted`)
    }
    return null;
  },
  getJobApplicants_Rejected: (id) => {
    if (id !== null) {
      return axiosInstance.get(`/job-match/employer/rejected/${id.jobId}`);
      // return axiosInstance.get(`/jobs/${id.jobId}/applicants/rejected`)
    }
    return null;
  },
  getJobApplicants_Archived: (id) => {
    if (id !== null) {
      return axiosInstance.get(`/job-match/employer/archived/${id.jobId}`);
      // return axiosInstance.get(`/jobs/${id.jobId}/applicants/rejected`)
    }
    return null;
  },
  jobProcessing: async (jobId, applicantId, payload) => {
    // console.log({
    //   jobId,
    //   applicantId,
    //   payload
    // })
    return axiosInstance.post(
      `jobs/${jobId}/applicants/${applicantId}`,
      payload
    );
  },
  getApplicantsJobStatus: (jobId, applicantId) => {
    return axiosInstance.get(`jobs/${jobId}/applicants/${applicantId}`);
  },
  getJobSeekers: (payload) => {
    // console.log(payload)
    if (payload !== undefined) {
      if (payload.jobId === undefined) {
        var url = api.GET_JOB_SEEKERS;
        for (const key in payload) {
          var separator = url.indexOf("?") !== -1 ? "&" : "?";
          if (!!payload[key]) {
            //checks for the truthy condition of the options[key]
            payload[key] = payload[key].trim(); //you can also add conditions for the keys which you want to trim.
            url += separator + key + "=" + payload[key];
          }
        }
        console.log(url);
        return axiosInstance.get(url);
      } else {
        const newObj = {
          industry: payload.industry,
          location: payload.location,
          jobFunction: payload.jobFunction,
        };
        var url = `${api.GET_JOB_SEEKERS}?jobId=${payload.jobId}`;
        delete payload.jobId;
        for (const key in payload) {
          var separator = url.indexOf("?") !== -1 ? "&" : "?";
          if (!!payload[key]) {
            //checks for the truthy condition of the options[key]
            payload[key] = payload[key].trim(); //you can also add conditions for the keys which you want to trim.
            url += separator + key + "=" + payload[key];
          }
        }
        console.log(url);
        return axiosInstance.get(url);
      }
    } else {
      return axiosInstance.get(api.GET_JOB_SEEKERS);
    }
  },
};

export const reviewRatingRequest = {
  rateAndReviewEmployer: (data) =>
    axiosInstance.post(api.RATE_AND_REVIEW, data),
  getRatingsAndReviewsEmployer: (data) =>
    axiosInstance.get(`${api.RATE_AND_REVIEW}?employerId=${data.employerId}`),
};

export const paymentsRequest = {
  processPayment: (data) => axiosInstance.post(api.PROCESS_PAYMENT, data),
  checkPayment: (data) => {
    return axiosInstance.get(
      `${api.CHECK_PAYMENT_STATUS}/${data.applicantId}?paymentType=${data.paymentType}`
    );
  },
  getPayments: (data) => {
    return axiosInstance.get(api.GET_PAYMENTS);
  },
  cancelPayment: (data) => {
    return axiosInstance.patch(`payments/${data.id}`, data);
  },
};

export const stripeRequest = {
  confirmPayment: (data) =>
    axios({
      url: "https://api.stripe.com/v1/tokens",
      method: "post",
      headers: {
        // Use the correct MIME type for your server
        Accept: "application/json",
        // Use the correct Content Type to send data to Stripe
        "Content-Type": "application/x-www-form-urlencoded",
        // Use the Stripe publishable key as Bearer
        Authorization: `Bearer ${STRIPE_CLIENT_TOKEN}`,
      },
      data: Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&"),
    }),
};

export const configRequest = {
  getIndustries: (data) => axiosInstance.get(api.GET_INDUSTRIES),
  getJobFunctions: (data) => axiosInstance.get(api.GET_JOB_FUNCTIONS),
  searchSkills: (data) =>
    axiosInstance.get(`${api.SEARCH_SKILLS}/${data?.search}`),
};

export const jobMatchRequest = {
  getJobMatches: () => {
    return axiosInstance.get(api.GET_JOB_MATCHES);
  },
  getMyLikedCandidates: (data) => {
    if (!data) {
      return axiosInstance.get(`${api.GET_LIKED_CANDIDATES}?limit=10&offset=1`);
    } else {
      return axiosInstance.get(
        `${api.GET_LIKED_CANDIDATES}?limit=10&offset=${data?.offset}`
      );
    }
  },
  getLikedMyJobs: (data) => {
    if (!data) {
      return axiosInstance.get(`${api.GET_LIKED_MY_JOBS}?limit=10&offset=1`);
    } else {
      return axiosInstance.get(
        `${api.GET_LIKED_MY_JOBS}?limit=10&offset=${data?.offset}`
      );
    }
  },
  employerSwipe: (data) => axiosInstance.post(api.EMPLOYER_SWIPE, data),
  updateJobMatch: (jobmatch_id, status) =>
    axiosInstance.post(api.UPDATE_JOB_MATCH, {
      jobMatchId: jobmatch_id,
      applicationStatus: status,
    }),
};

export const jobFairRequest = {
  getOngoingJobFairs: () => {
    return axiosInstance.get(api.GET_ONGOING_JOBFAIRS);
  },
  getUpcomingJobFairs: () => {
    return axiosInstance.get(api.GET_UPCOMING_JOBFAIRS);
  },
  getActiveJobFairs: () => {
    return axiosInstance.get(api.GET_ACTIVE_JOBFAIRS);
  },
  registerAndEnterJobFair: (data) => {
    return axiosInstance.post(api.REGISTER_AND_ENTER_JOBFAIR, data);
  },
  sendJobFairInterest: (data) => {
    return axiosInstance.post(api.SEND_JOBFAIR_INTEREST, data);
  },
  getJobSeekers: (id, payload) => {
    if (payload !== undefined) {
      if (payload.jobId === undefined) {
        var url = `${api.GET_JOB_SEEKERS_JOBFAIR}/${id}`;
        for (const key in payload) {
          var separator = url.indexOf("?") !== -1 ? "&" : "?";
          if (!!payload[key]) {
            //checks for the truthy condition of the options[key]
            payload[key] = payload[key].trim(); //you can also add conditions for the keys which you want to trim.
            url += separator + key + "=" + payload[key];
          }
        }
        console.log(url);
        return axiosInstance.get(url);
      } else {
        const newObj = {
          industry: payload.industry,
          location: payload.location,
          jobFunction: payload.jobFunction,
          limit: payload.limit,
          offset: payload.offset,
        };
        var url = `${api.GET_JOB_SEEKERS_JOBFAIR}/${id}?jobId=${payload.jobId}`;
        for (const key in newObj) {
          var separator = url.indexOf("?") !== -1 ? "&" : "?";
          if (!!newObj[key]) {
            //checks for the truthy condition of the options[key]
            newObj[key] = newObj[key].trim(); //you can also add conditions for the keys which you want to trim.
            url += separator + key + "=" + newObj[key];
          }
        }
        console.log(url);
        return axiosInstance.get(url);
      }
    } else {
      return axiosInstance.get(`${api.GET_JOB_SEEKERS_JOBFAIR}/${id}`);
    }
  },
  getRecommendedJobs: (id, payload) => {
    if (payload !== undefined) {
      var url = `${api.GET_RECOMMENDER_JOBS_JOBFAIR}/${id}`;

      for (const key in payload) {
        var separator = url.indexOf("?") !== -1 ? "&" : "?";
        if (!!payload[key]) {
          //checks for the truthy condition of the options[key]
          payload[key] = payload[key].trim(); //you can also add conditions for the keys which you want to trim.
          url += separator + key + "=" + payload[key];
        }
      }
      console.log(url);
      return axiosInstance.get(url);
    } else {
      return axiosInstance.get(`${api.GET_RECOMMENDER_JOBS_JOBFAIR}/${id}`);
    }
  },
};

export const generalSettingsRequest = {
  getCurrencies: () => {
    return axiosInstance.get(api.GET_CURRENCY);
  },
};

export const paymentRequests = {
  getPlans: () => axiosInstance.get(api.GET_SUBSCRIPTIONS_LIST),
  subscribeToPlan: (payload) =>
    axiosInstance.post(api.GET_PAYMENT_INTENT, payload),
  getActiveSubscription: () => axiosInstance.get(api.GET_ACTIVE_SUBSCRIPTION),
  cancelSubscription: (payload) =>
    axiosInstance.post(api.CANCEL_SUBSCRIPTION, payload),
};

export const calendarRequest = {
  getCalendarAuthUrls: async () => {
    return axiosInstance.get(api.CALENDAR_AUTH_URLS);
  },
  getCalendarConnections: async () => {
    return axiosInstance.get(api.CALENDAR_CONNECTIONS);
  },
  connectCalendarIntegration: async (payload) => {
    return axiosInstance.post(api.CALENDAR_CONNECT_INTEGRATION, payload);
  },
  removeCalendarIntegration: async (provider) => {
    return axiosInstance.delete(
      `${api.CALENDAR_REMOVE_INTERGATION}/${provider}`
    );
  },
  cancelInterviewEvent: async (id) => {
    return axiosInstance.delete(`${api.CANCEL_EVENT}/${id}`);
  },
  createEvent: async (payload) => {
    return axiosInstance.post(api.CREATE_EVENT, payload);
  },
  getAvailableProvider: (payload) => {
    return axiosInstance.get(api.CALENDAR_PROVIDER_AVAILABILITY);
  },
};

export const applicantRequests = {
  jobs: {
    applied: (payload) =>
      axiosInstance.get(processParams(APPLICANTS_API.jobs.applied, payload)),
    shortlisted: (payload) =>
      axiosInstance.get(
        processParams(APPLICANTS_API.jobs.shortlisted, payload)
      ),
    archived: (payload) =>
      axiosInstance.get(processParams(APPLICANTS_API.jobs.archived, payload)),
    hired: (payload) =>
      axiosInstance.get(processParams(APPLICANTS_API.jobs.hired, payload)),
    interviews: (payload) =>
      axiosInstance.get(processParams(APPLICANTS_API.jobs.interviews, payload)),
    liked: (payload) =>
      axiosInstance.get(processParams(APPLICANTS_API.jobs.liked_job, payload)),
    liked_me: (payload) =>
      axiosInstance.get(processParams(APPLICANTS_API.jobs.liked_me, payload)),
    actions: {
      like: (jobId, employerId) =>
        // LIKE DATA
        // jobId: objectId
        // employerId: objectId
        // action: like || not_interested
        axiosInstance.post(APPLICANTS_API.jobs.actions.like_or_dismiss, {
          jobId,
          employerId,
          action: "like",
        }),
      dismiss: (jobId, employerId) =>
        // LIKE DATA
        // jobId: objectId
        // employerId: objectId
        // action: like || not_interested
        axiosInstance.post(APPLICANTS_API.jobs.actions.like_or_dismiss, {
          jobId,
          employerId,
          action: "not_interested",
        }),

      superlike: (jobId, employerId) =>
        // SUPERLIKE DATA
        // jobId: objectId
        // employerId: objectId
        // action: superLike
        axiosInstance.post(APPLICANTS_API.jobs.actions.superlike, {
          jobId,
          employerId,
          action: "superLike", //override action payload
        }),
    },
  },
};
