import { makeStyles } from "@material-ui/core/styles";
import CreateProfileBg from "../../assets/img/createProfile/createProfileBg.png";
import CompleteProfileBg from "../../assets/img/createProfile/createProfileImg2.png";

export const profile_preview_styles = makeStyles((theme) => ({
  card_bg: {
    background: `url('${CreateProfileBg}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "norepeat",
  },
  card_bg2: {
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url('${CreateProfileBg}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "norepeat",
  },
  modal_img: {
    height: 107,
    width: 107,
  },
  complete_profile_bg: {
    background: `url('${CompleteProfileBg}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  checked: {
    "&:checked": {
      backgroundColor: "#CCEBF8",
      color: "#000",
    },
  },
  white_button: {
    backgroundColor: "#FFF!important",
  },
  chip_active: {
    backgroundColor: "#009CDE!important",
    color: "#FFFFFF!important",
    marginRight: "0.5rem!important",
    fontSize: 12,
    marginBottom: "0.5rem!important",
    fontFamily: "Open Sans!important",
  },
  chip_inactive: {
    backgroundColor: "#E9EBEC!important",
    color: "#5D656D!important",
    marginRight: "0.5rem!important",
    fontSize: 12,
    marginBottom: "0.5rem!important",
    fontFamily: "Open Sans!important",
  },
  btn_white: {
    backgroundColor: '#FFFFFF!important',
    color: '#002E5D!important'
  },
  tabs: {
    "&:hover": {
      backgroundColor: "#FFFFFF!important",
    },
  }
}));
