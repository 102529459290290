import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import jobIcon from '../../../assets/img/jobIcon.svg'
// import locationIcon from '../../../assets/img/locationIcon.svg'
import DismissIcon from '../../../assets/img/editProfile/dismissIcon.png'
import LikeIcon from '../../../assets/img/editProfile/likeIcon.png'
import SuperLikeIcon from '../../../assets/img/editProfile/superLikeIcon.png'
import educationIcon from '../../../assets/img/explore/educationIcon.svg'
import experienceIcon from '../../../assets/img/explore/experienceIcon.svg'
import locationIcon from '../../../assets/img/explore/locationIcon.svg'
import playIcon from '../../../assets/img/explore/playIcon.svg'
import salaryIcon from '../../../assets/img/explore/salaryIcon.svg'
import skillsIcon from '../../../assets/img/explore/skillsIcon.png'
import DeleteIcon from '../../../assets/img/likes/deleteIcon.png'
import PlusIcon from '../../../assets/img/likes/plusIcon.png'
import UnionIcon from '../../../assets/img/likes/unionIcon.png'
import subModalImg from '../../../assets/img/pricing-page/sub-modal.svg'
import ElevatorPitch from '../../../components/reusables/elevator-pitch/ElevatorPitch'
import {
    defaultSwipeActions,
    JobApplication_Status,
    profilePlaceholder_Applicant,
    salaryRanges,
} from '../../../utils/constants'
import {
    formatDateOnly,
    formatMoney,
    generateRandomChars,
    getYearDifference,
    getYearsOfExpCategory,
    titleCase,
} from '../../../utils/helpers'
import useWindowDimensions from '../../../utils/hooks'
import JobluVideoPlayer from '../../explore/components/VideoPlayer'
import { ProfileModal } from '../../explore/v2/components/ViewProfile/ProfileModal'
import './styles.css'

export const SubscriptionPopup = ({ open, setOpen }) => {
    const navigate = useNavigate()

    return (
        <Modal show={open} centered onHide={() => setOpen()}>
            <div className="">
                <div className="d-flex">
                    <img
                        src={subModalImg}
                        alt=""
                        className="mx-auto"
                        style={{ width: '100%' }}
                    />
                </div>
                <div className="px-3 pb-2">
                    <h5 className="fw-bold mt-0 poppins text-center">
                        Subscribe to Joblu Premium <br /> to view full profile.
                    </h5>
                    <br />
                    <p className="open-sans text-center">
                        Save thousands in recruitment cost <br /> and source the
                        right candidate the fast, fund, and fabulous way!
                    </p>
                    <br />
                    <button
                        className="btn btn-primary text-center w-100 mt-4 mb-4"
                        onClick={() => {
                            navigate('/pricing')
                        }}
                    >
                        Go Premium Now, Get 50% OFF!
                    </button>
                </div>
            </div>
        </Modal>
    )
}
const ApplicantItem = (props) => {
    const {
        handleView,
        hasSwipeActions,
        data,
        swipeAction,
        itemId,
        subscription,
        isLikedMe,
        AppState,
        getApplicants,
    } = props

    const hasSubs = props?.User?.user?.subscriptionType === 'PREMIUM'

    const [showSubscription, setShowSubcription] = useState(false)

    const [playVideo, setPlayVideo] = useState(false)
    const [showPitch, setShowPitch] = useState(true)
    const handlePlayVideo = () => {
        console.log('playvuidoe')
        setPlayVideo(!playVideo)
    }

    const { currencyList, currency } = AppState
    const currencyOptions = {
        currencyList,
        currency,
    }

    useEffect(() => {
        if (document.getElementsByClassName('prc')) {
            var items = document.getElementsByClassName('prc')
            for (var i = 0; i < items.length; i++) {
                items[i].addEventListener(
                    'contextmenu',
                    function (e) {
                        // do something here...
                        e.preventDefault()
                    },
                    false
                )
                items[i].onselectstart = disableselect
            }
            // document.getElementsByClassName('prc').onselectstart = disableselect
        }
    }, [])
    const disableselect = (e) => {
        return false
    }

    const playVid = () => {
        handlePlayVideo()
    }

    const getYearsOfExp = (item) => {
        if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
            const sorted =
                item?.experienceHistory &&
                item?.experienceHistory?.sort(function (a, b) {
                    return new Date(a.startDate) - new Date(b.startDate)
                })
            const startDate = new Date(sorted[0]?.startDate)
            const diff = getYearDifference(startDate)
            return getYearsOfExpCategory(diff)
        } else {
            return null
        }
    }

    const updateJobMatch = (status) => {
        console.log(data, status)
        // jobMatchRequest
        //     .updateJobMatch(data?._id, status)
        //     .then((res) => {
        //         console.log(res.data)
        //     })
        //     .finally(() => {
        //         getApplicants && getApplicants()
        //     })
    }

    const [showProfileModal, setShowProfileModal] = useState(false)

    const handleCloseProfileModal = () => setShowProfileModal(false)
    const handleShowProfileModal = () => setShowProfileModal(true)

    const { width } = useWindowDimensions()
    return (
        <>
            <ProfileModal
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                handleCloseProfileModal={handleCloseProfileModal}
                handleShowProfileModal={handleShowProfileModal}
                seeker={data?.matchApplicant}
                swipeAction={swipeAction}
                setShowSubcription={setShowSubcription}
                hasSubs={hasSubs}
                data={data}
                defaultSwipeActions={defaultSwipeActions}
                itemId={itemId}
                hasSwipeActions={hasSwipeActions}
                //  explore={true}
            />
            <div className="row border-bottom py-3 applicant-card" id={itemId}>
                <div
                    className={`col-md-8 d-flex flex-md-row flex-column ${
                        !hasSubs && isLikedMe && ''
                    }`}
                >
                    <div
                        className={`me-4 ${!hasSubs && isLikedMe && ''}`}
                        style={{ flexBasis: '15%' }}
                    >
                        <div
                            className="position-relative"
                            style={{ width: 80, height: 80 }}
                        >
                            <img
                                src={
                                    data?.matchApplicant?.profileUrl ||
                                    profilePlaceholder_Applicant
                                }
                                style={{
                                    width: 80,
                                    height: 80,

                                background: '#FFFFFF'
                                }}
                                alt="profile"
                                className="rounded-circle mb-3"
                            />
                            {data?.matchApplicant?.elevatorPitch && (
                                <>
                                    <div
                                        className="position-absolute"
                                        style={{ bottom: -6, right: -6 }}
                                    >
                                        <img
                                            src={playIcon}
                                            alt=""
                                            className="hand"
                                            onClick={() => {
                                                playVid()
                                            }}
                                        />
                                    </div>
                                    <JobluVideoPlayer
                                        src={
                                            data?.matchApplicant?.elevatorPitch
                                        }
                                        play={playVideo}
                                        setPlayVideo={setPlayVideo}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="me-4" style={{ flexBasis: '35%' }}>
                        <div className={!hasSubs && isLikedMe && ''}>
                            <h5 className="text-primary mb-0">
                                {data?.matchApplicant?.anonymous &&
                                !hasSwipeActions
                                    ? 'Job Seeker'
                                    : titleCase(
                                          `${data?.matchApplicant?.firstName} ${data?.matchApplicant?.lastName}`
                                      )}
                            </h5>
                            {/* {isLikedMe ? (
                                <>
                                    {hasSubs ? (
                                        <h5 className="text-primary mb-0">
                                            {data?.matchApplicant?.anonymous &&
                                            !hasSwipeActions
                                                ? 'Job Seeker'
                                                : titleCase(
                                                      `${data?.matchApplicant?.firstName} ${data?.matchApplicant?.lastName}`
                                                  )}
                                        </h5>
                                    ) : (
                                        <h5 class="prc">
                                            {`${generateRandomChars(
                                                data?.matchApplicant?.firstName
                                                    .length
                                            )} ${generateRandomChars(
                                                data?.matchApplicant?.lastName
                                                    .length
                                            )}`}
                                        </h5>
                                    )}
                                </>
                            ) : (
                                <h5 className="text-primary mb-0">
                                    {data?.matchApplicant?.anonymous &&
                                    !hasSwipeActions
                                        ? 'Job Seeker'
                                        : titleCase(
                                              `${data?.matchApplicant?.firstName} ${data?.matchApplicant?.lastName}`
                                          )}
                                </h5>
                            )} */}
                        </div>
                        <div className="mb-3 mb-md-1">
                            <p className="small text-muted mt-2 mb-0">
                                Added: {formatDateOnly(data?.createdAt)}
                            </p>
                            <p className="text-wrap mb-0">
                                {titleCase(data?.matchJob?.jobTitle)}
                            </p>
                            <p className="text-muted text-wrap mb-0">
                                {data?.matchApplicant?.jobFunction}
                            </p>
                        </div>
                        {data?.matchApplicant?.educationHistory?.length > 0 && (
                            <>
                                <p className="small">
                                    <img
                                        src={educationIcon}
                                        alt=""
                                        className="me-2"
                                        style={{ width: 22 }}
                                    />{' '}
                                            {
                                                data?.matchApplicant?.educationHistory?.sort(function(a,b)
                                                {
                                                    return new Date(b.educStartDate) - new Date(a.educStartDate);
                                                })[0]?.educName
        
                                            }
                                </p>
                            </>
                        )}
                        {data?.matchApplicant?.currentLocation && (
                            <>
                                <p className="small">
                                    <img
                                        src={locationIcon}
                                        alt=""
                                        className="me-2"
                                        style={{ width: 22 }}
                                    />{' '}
                                    {data?.matchApplicant?.currentLocation}
                                </p>
                            </>
                        )}
                        {getYearsOfExp(data?.matchApplicant) &&
                            getYearsOfExp(data?.matchApplicant) !== null && (
                                <p className="small">
                                    <img
                                        src={experienceIcon}
                                        alt=""
                                        className="me-2"
                                        style={{ width: 22 }}
                                    />{' '}
                                    {`${getYearsOfExp(
                                        data?.matchApplicant
                                    )} Years of Experience`}
                                </p>
                            )}
                        {data?.matchApplicant.salaryMin &&
                            data?.matchApplicant.salaryMin !== null && (
                                <p className="small">
                                    <img
                                        src={salaryIcon}
                                        alt=""
                                        className="me-2"
                                        style={{ width: 22 }}
                                    />{' '}
                                    {data?.matchApplicant.salaryMin === 1 &&
                                    data?.matchApplicant.salaryMax === 1
                                        ? salaryRanges.COMPETIIVE_SALARY
                                        : data?.matchApplicant.salaryMin ===
                                              2 &&
                                          data?.matchApplicant.salaryMax === 2
                                        ? salaryRanges.INDUSTRY_STANDARD
                                        : `${formatMoney(
                                              data?.matchApplicant.salaryMin,
                                              currencyOptions,
                                              data?.matchApplicant
                                                  .salaryCurrency || 'SGD'
                                          )} - ${formatMoney(
                                              data?.matchApplicant.salaryMax,
                                              currencyOptions,
                                              data?.matchApplicant
                                                  .salaryCurrency || 'SGD'
                                          )}`}
                                </p>
                            )}
                    </div>
                    <div style={{ flexBasis: '60%' }} className="mt-3 mt-md-0">
                        {data?.matchApplicant?.skills?.length > 0 && (
                            <>
                                <div className="mb-3">
                                    <div className="d-flex">
                                        <div className="me-3">
                                            <img
                                                src={skillsIcon}
                                                alt=""
                                                style={{ width: '24px' }}
                                            />
                                        </div>
                                        <div>
                                            <h6 className="mb-2 text-primary">
                                                Technical Skills
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        {data?.matchApplicant?.skills
                                            ?.slice(0, 5)
                                            .map((skill) => {
                                                return (
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                '#009CDE',
                                                            borderRadius:
                                                                '12px',
                                                            padding: '0 16px',
                                                            color: '#ffffff',
                                                            fontSize: 12,
                                                        }}
                                                        className="me-2 mb-1 text-nowrap"
                                                    >
                                                        {skill}
                                                    </span>
                                                )
                                            })}
                                        {data?.matchApplicant?.skills.length >
                                            5 && (
                                            <>
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            '#009CDE',
                                                        borderRadius: '12px',
                                                        padding: '0 16px',
                                                        color: '#ffffff',
                                                        fontSize: 12,
                                                    }}
                                                    className="me-2 mb-1 text-nowrap"
                                                >
                                                    +{' '}
                                                    {data?.matchApplicant
                                                        ?.skills.length -
                                                        5}{' '}
                                                    more
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <span
                                        className="hand"
                                        onClick={() => {
                                            if (!hasSubs) {
                                                // setShowSubcription(true)
                                                handleShowProfileModal()
                                            } else {
                                                handleShowProfileModal()
                                            }
                                        }}
                                        style={{
                                            color: '#009CDE!important',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        <small>See More</small>
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="text-center text-md-end mt-2 mt-md-0 d-grid d-md-block">
                        {data?.employer?.action === 'superLike' && (
                            <>
                                <span className="me-3 mb-2 mb-md-0">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-star-fill me-2 text-warning"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                    </svg>
                                    Super Liked
                                </span>
                            </>
                        )}
                        {/* <button
                            className="btn btn-primary text-center mb-2"
                            onClick={() => {
                                if (!hasSubs) {
                                    setShowSubcription(true)
                                    // handleView()
                                } else {
                                    handleView()
                                }
                            }}
                        >
                            View Profile
                        </button> */}
                    </div>
                    <div className="">
                        {hasSwipeActions ? (
                            <div className="text-center text-md-end d-md-block">
                                <img
                                    src={DismissIcon}
                                    alt="icon"
                                    role="button"
                                    onClick={() => {
                                        swipeAction({
                                            job: data?.matchJob,
                                            applicant: data?.matchApplicant,
                                            action: defaultSwipeActions.left,
                                            itemId: itemId,
                                        })
                                        // if (!hasSubs) {
                                        //     setShowSubcription(true)
                                        // } else {
                                        //     swipeAction({
                                        //         job: data?.matchJob,
                                        //         applicant: data?.matchApplicant,
                                        //         action: defaultSwipeActions.left,
                                        //         itemId: itemId,
                                        //     })
                                        // }
                                    }}
                                />
                               
                                <img
                                    src={SuperLikeIcon}
                                    alt="icon"
                                    role="button"
                                    className='mx-3'
                                    onClick={() => {
                                        swipeAction({
                                            job: data?.matchJob,
                                            applicant: data?.matchApplicant,
                                            action: defaultSwipeActions.right,
                                            itemId: itemId,
                                        })
                                        // if (!hasSubs) {
                                        //     setShowSubcription(true)
                                        // } else {
                                        //     swipeAction({
                                        //         job: data?.matchJob,
                                        //         applicant: data?.matchApplicant,
                                        //         action: defaultSwipeActions.right,
                                        //         itemId: itemId,
                                        //     })
                                        // }
                                    }}
                                />
                                 <img
                                    src={LikeIcon}
                                    alt="icon"
                                    role="button"
                                    className=""
                                    onClick={() => {
                                        swipeAction({
                                            job: data?.matchJob,
                                            applicant: data?.matchApplicant,
                                            action: defaultSwipeActions.top,
                                            itemId: itemId,
                                        })
                                        // if (!hasSubs) {
                                        //     setShowSubcription(true)
                                        // } else {
                                        //     swipeAction({
                                        //         job: data?.matchJob,
                                        //         applicant: data?.matchApplicant,
                                        //         action: defaultSwipeActions.top,
                                        //         itemId: itemId,
                                        //     })
                                        // }
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="text-end d-flex flex-column d-none">
                        {hasSwipeActions ? (
                            <div className="mb-2">
                                <button
                                    className="btn btn-primary text-nowrap"
                                    style={{ width: 250 }}
                                >
                                    <img src={UnionIcon} className="me-2" s />{' '}
                                    Schedule Interview
                                </button>
                            </div>
                        ) : (
                            <div className="mb-2">
                                <button
                                    className="btn btn-primary text-nowrap"
                                    style={{ width: 250 }}
                                    onClick={() => {
                                        updateJobMatch(
                                            JobApplication_Status.SHORTLISTED
                                        )
                                    }}
                                >
                                    <img src={PlusIcon} className="me-2" s />{' '}
                                    Shortlist Job Seeker{' '}
                                </button>
                            </div>
                        )}
                        <div>
                            <button
                                className="btn btn-outline-danger text-nowrap"
                                style={{ width: 250 }}
                                onClick={() => {
                                    updateJobMatch(
                                        JobApplication_Status.ARCHIVED
                                    )
                                }}
                            >
                                <img src={DeleteIcon} className="me-2" s />{' '}
                                Archive Candidate
                            </button>
                        </div>
                    </div>
                </div>

                {/* REMOVED */}
                <div
                    className={`col-md-6 col-sm-12 d-flex d-none ${
                        !hasSubs && isLikedMe && ''
                    }`}
                >
                    <div
                        className="text-center position-relative "
                        style={{ height: '100px' }}
                    >
                        <div className={!hasSubs && isLikedMe && ''}>
                            <ElevatorPitch
                                jobseeker={data?.matchApplicant}
                                width={100}
                                height={100}
                                hasSwipeActions={hasSwipeActions}
                                hasSubs={hasSubs}
                                isLikedMe={isLikedMe}
                                job={data}
                            />
                        </div>
                    </div>
                    <div className="ms-3">
                        <div className={!hasSubs && isLikedMe && ''}>
                            {isLikedMe ? (
                                <>
                                    {hasSubs ? (
                                        <h5 className="text-primary mb-0">
                                            {data?.matchApplicant?.anonymous &&
                                            !hasSwipeActions
                                                ? 'Job Seeker'
                                                : titleCase(
                                                      `${data?.matchApplicant?.firstName} ${data?.matchApplicant?.lastName}`
                                                  )}
                                        </h5>
                                    ) : (
                                        <h5 class="prc">
                                            {`${generateRandomChars(
                                                data?.matchApplicant?.firstName
                                                    .length
                                            )} ${generateRandomChars(
                                                data?.matchApplicant?.lastName
                                                    .length
                                            )}`}
                                        </h5>
                                    )}
                                </>
                            ) : (
                                <h5 className="text-primary mb-0">
                                    {data?.matchApplicant?.anonymous &&
                                    !hasSwipeActions
                                        ? 'Job Seeker'
                                        : titleCase(
                                              `${data?.matchApplicant?.firstName} ${data?.matchApplicant?.lastName}`
                                          )}
                                </h5>
                            )}
                        </div>
                        {data?.matchApplicant?.preferredTitle && (
                            <p className="small text-muted mb-0">
                                <strong>
                                    {data?.matchApplicant?.preferredTitle}
                                </strong>
                            </p>
                        )}
                        <p className="small text-muted mt-2 mb-0">
                            Added: {formatDateOnly(data?.createdAt)}
                        </p>
                        <p className="text-wrap mb-0">
                            {titleCase(data?.matchJob?.jobTitle)}
                        </p>
                        <p className="text-muted text-wrap mb-0">
                            {data?.matchApplicant?.jobFunction}
                        </p>
                        <div className="mt-3 d-flex flex-column flex-md-row mb-3 mb-md-0">
                            <div className="d-flex align-items-center me-4">
                                <div>
                                    <img
                                        src={locationIcon}
                                        className="h-24 me-2"
                                        alt=""
                                    />
                                </div>
                                <div className="">
                                    <p className="small mb-0">
                                        Based in{' '}
                                        {data?.matchApplicant?.currentLocation}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                {data?.matchApplicant?.totalExperienceMin ===
                                    undefined ||
                                data?.matchApplicant?.totalExperienceMin ===
                                    null ? (
                                    <>
                                        <div>
                                            <img
                                                src={jobIcon}
                                                className="h-24 me-2"
                                                alt=""
                                            />
                                        </div>
                                        <div className="">
                                            <p className="small mb-0">
                                                No work experience
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <img
                                                src={jobIcon}
                                                className="h-24 me-2"
                                                alt=""
                                            />
                                        </div>
                                        <div className="">
                                            <p className="small mb-0">
                                                {data?.matchApplicant
                                                    ?.totalExperienceMin ===
                                                    undefined ||
                                                data?.matchApplicant
                                                    ?.totalExperienceMin ===
                                                    null
                                                    ? 'No Work Experience'
                                                    : data?.matchApplicant
                                                          ?.totalExperienceMin ===
                                                      data?.matchApplicant
                                                          ?.totalExperienceMax
                                                    ? `${data?.matchApplicant?.totalExperienceMin} Years of Experience`
                                                    : data?.matchApplicant
                                                          ?.totalExperienceMin ===
                                                      20
                                                    ? `More than ${data?.matchApplicant?.totalExperienceMin} Years of Experience`
                                                    : `${data?.matchApplicant?.totalExperienceMin} - ${data?.matchApplicant?.totalExperienceMax} Years of Experience`}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 d-none">
                    <div className="text-center text-md-end mt-2 mt-md-0 d-grid d-md-block">
                        {data?.employer?.action === 'superLike' && (
                            <span className="me-3 mb-2 mb-md-0">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-star-fill me-2 text-warning"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                                Super Liked
                            </span>
                        )}
                        {/* <button
                            className="btn btn-primary text-center mb-2"
                            onClick={() => {
                                if (!hasSubs) {
                                    setShowSubcription(true)
                                    // handleView()
                                } else {
                                    handleView()
                                }
                            }}
                        >
                            View Profile
                        </button> */}
                    </div>
                    {hasSwipeActions ? (
                        <div className="text-center text-md-end d-grid d-md-block">
                            <button
                                onClick={() => {
                                    swipeAction({
                                        job: data?.matchJob,
                                        applicant: data?.matchApplicant,
                                        action: defaultSwipeActions.left,
                                        itemId: itemId,
                                    })
                                    // if (!hasSubs) {
                                    //     setShowSubcription(true)
                                    // } else {
                                    //     swipeAction({
                                    //         job: data?.matchJob,
                                    //         applicant: data?.matchApplicant,
                                    //         action: defaultSwipeActions.left,
                                    //         itemId: itemId,
                                    //     })
                                    // }
                                }}
                                className="btn btn-outline-primary  mb-2 mb-md-0 me-0 me-md-2 text-center"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                                Dismiss
                            </button>
                            <button
                                onClick={() => {
                                    swipeAction({
                                        job: data?.matchJob,
                                        applicant: data?.matchApplicant,
                                        action: defaultSwipeActions.top,
                                        itemId: itemId,
                                    })
                                    // if (!hasSubs) {
                                    //     setShowSubcription(true)
                                    // } else {
                                    //     swipeAction({
                                    //         job: data?.matchJob,
                                    //         applicant: data?.matchApplicant,
                                    //         action: defaultSwipeActions.top,
                                    //         itemId: itemId,
                                    //     })
                                    // }
                                }}
                                className="btn btn-outline-warning mb-2 mb-md-0 text-black me-0 me-md-2 text-center"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-star me-2"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                </svg>
                                Super Like
                            </button>
                            <button
                                onClick={() => {
                                    swipeAction({
                                        job: data?.matchJob,
                                        applicant: data?.matchApplicant,
                                        action: defaultSwipeActions.right,
                                        itemId: itemId,
                                    })
                                    // if (!hasSubs) {
                                    //     setShowSubcription(true)
                                    // } else {
                                    //     swipeAction({
                                    //         job: data?.matchJob,
                                    //         applicant: data?.matchApplicant,
                                    //         action: defaultSwipeActions.right,
                                    //         itemId: itemId,
                                    //     })
                                    // }
                                }}
                                className="btn btn-outline-primary mb-2 mb-md-0 text-center"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-heart me-2"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                </svg>
                                Like Back
                            </button>
                        </div>
                    ) : null}
                </div>
                <SubscriptionPopup
                    open={showSubscription}
                    setOpen={() => {
                        setShowSubcription(!showSubscription)
                    }}
                />
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
    }
}
export default connect(mapStateToProps, null)(ApplicantItem)
