import { Box, Dialog, Grid } from "@material-ui/core";
import { useState } from "react";
import AnnouncementImg from "../../assets/img/announcement/AnnouncementImg_1.png";
import AnnouncementImg2 from "../../assets/img/announcement/AnnouncementImg_2.png";
import { app_styles } from "../../general.styles";
import JobluTypography from "../Typography";
import Lu from "../../assets/img/announcement/lu.png";
import GcashWhite from "../../assets/img/announcement/GcashWhite1.png";
import { Close } from "@material-ui/icons";
import useWindowDimensions from "../../utils/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import userActions from "../../redux/user/actions";

const Announcement = (props) => {
  const { User, setPromotionalVisible, setPromotionalBannerVisible } = props;
  const app_classes = app_styles(props);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(User?.isPromotionalVisible && !location?.state?.fromCreate);
  const handleShow = () => setOpen(false);
  const handleClose = () => {
    console.log('test')
    setOpen(false);
    setPromotionalVisible(false);
    const body = document.getElementsByTagName('body')[0]
    body.classList.add('overflow-auto')
    console.log(body)
  };
  const [showBanner, setShowBanner] = useState(User?.isPromotionalBannerVisible && !location?.state?.fromCreate);
  const handleCloseBanner = () => {
    setShowBanner(false)
    setPromotionalBannerVisible(false)
  }
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        style={{ borderRadius: 30 }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        onClose={handleClose}
      >
        <Box style={{ borderRadius: 30, position: "relative" }}>
          {User?.user?.softSkills?.length < 1 ? (
            <>
              <img
                onClick={() => {
                  navigate("/edit-profile");
                  setPromotionalVisible(false);
                }}
                src={AnnouncementImg}
                style={{ width: "100%" }}
                className={`${app_classes.cursor.pointer}`}
              />
            </>
          ) : (
            <>
              <img
                onClick={handleClose}
                src={AnnouncementImg2}
                style={{ width: "100%" }}
                className={`${app_classes.cursor.pointer}`}
              />
            </>
          )}

          <Close
            onClick={handleClose}
            className={`${app_classes.cursor.pointer}`}
            fontSize={"large"}
            style={{ position: "absolute", right: 10, top: 10, color: "#FFF" }}
          />
        </Box>
      </Dialog>
      {showBanner && (
        <>
          <Grid
            container
            justifyContent="center"
            className={`${app_classes.text.white} ${app_classes.width.w100}`}
            style={{ backgroundColor: "#009CDE" }}
          >
            {width > 640 && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  style={{ position: "relative" }}
                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_bottom}`}
                >
                  <img
                    src={Lu}
                    className={`${app_classes.margin.l3}`}
                    style={{ position: "absolute", bottom: 0 }}
                    width={90}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <Box className={`${app_classes.padding.y1}`}>
                <Box className={`${app_classes.text.center}`}>
                  {width < 640 && (
                    <>
                      <Box style={{ float: "right" }}>
                        {" "}
                        <Close
                          className={`${app_classes.cursor.pointer}`}
                          onClick={() => {
                            setShowBanner(false);
                          }}
                        />
                      </Box>
                    </>
                  )}
                  <JobluTypography.H6>
                    Complete your profile, like 5 job posts, and enjoy a{" "}
                    <span style={{ color: "#EBCFA3" }}>₱100 GCash reward!</span>
                  </JobluTypography.H6>
                </Box>
                <Box className={`${app_classes.text.center}`}>
                  <JobluTypography.Body style={{ fontWeight: 600 }}>
                    Hurry! Only limited vouchers are up for grabs.{" "}
                    {User?.user?.softSkills?.length < 1 && (
                      <>
                        <span
                          style={{ color: "#EBCFA3" }}
                          className={`${app_classes.cursor.pointer}`}
                          onClick={() => {
                            navigate("/edit-profile");
                          }}
                        >
                          Click Here!
                        </span>
                      </>
                    )}
                  </JobluTypography.Body>
                </Box>
              </Box>
            </Grid>
            {width > 640 && (
              <>
                <Grid item xs={12} sm={3} >
                  <Grid container className={`${app_classes.height.h100}`}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center} ${app_classes.height.h100}`}>
                      <Box >
                        <img src={GcashWhite} style={{ width: "136px" }} />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_right} ${app_classes.flexBox.align_items_center} ${app_classes.height.h100}`}>
                        <Close
                          className={`${app_classes.cursor.pointer} ${app_classes.margin.r1} `}
                          onClick={handleCloseBanner}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    className={`${app_classes.display.display_flex} ${app_classes.width.w100} ${app_classes.height.h100} ${app_classes.flexBox.align_items_center}`}
                  ></Box>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
  };
};

const mapDispatchToProps = {
  setPromotionalVisible: userActions.setPromotionalVisible,
  setPromotionalBannerVisible: userActions.setPromotionalBannerVisible,
};
export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
