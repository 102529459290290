import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  Tooltip,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import SendIcon from "@material-ui/icons/Send";
import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultProfileBg from "../../assets/img/companyShowcase/profileBg.png";
import DefaultImg from "../../assets/img/defaultImg.png";
import JobluH from "../../assets/img/joblu-h.png";
import EducationIcon from "../../assets/img/jobs/EducationIcon.png";
import EmploymentTypeIcon from "../../assets/img/jobs/EmploymentTypeIcon.png";
import ExperienceIcon from "../../assets/img/jobs/ExperienceIcon.png";
import IndustryIcon from "../../assets/img/jobs/IndustryIcon.png";
import LocationIcon from "../../assets/img/jobs/LocationIcon.png";
import SalaryIcon from "../../assets/img/jobs/SalaryIcon.png";
import WorkSetupIcon from "../../assets/img/jobs/WorkSetupIcon.png";
import ShareArrow from "../../assets/img/jobs/shareArrow.png";
import JobluTypography from "../../components/Typography";
import Footer from "../../components/footer/Footer";
import PublicHeader from "../../components/header/PublicHeader";
import TextFieldV1 from "../../components/inputs/CustomTextField";
import { modal_styles } from "../../components/modals/styles";
import SelectCountry2 from "../../components/reusables/SelectCountry2";
import SelectJobFunction from "../../components/reusables/SelectJobFunction";
import CustomGooglePlaces from "../../components/select/google-places";
import { isSupported, messaging } from "../../firebase";
import { app_styles } from "../../general.styles";
import userActions from "../../redux/user/actions";
import { jobsRequest, userRequest } from "../../service/requests";
import { creds, salaryRanges } from "../../utils/constants";
import { formatMoney, formatString, openNewConnect, replaceSpaces } from "../../utils/helpers";
import useWindowDimensions from "../../utils/hooks";
import { validateEmail } from "../../utils/validators";
import NotFound from "../not-found";
import moment from 'moment';
import axios from 'axios';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { InpageBanner, LargeBanner, MediumBanner, MultiBanner, OnclickBanner, SmallBanner, SquareBanner, VignetteBanner } from "../../components/adbanner";

const ViewJobPublic = (props) => {
  const { AppState, signIn, signUp, User, verifyGoogleToken } = props;
  const {
    isRegistering,
    isSigningIn,
    isSignedIn,
    isGoogleSignup,
    isRegistered,
  } = User;



  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const params = useParams();
  const refId = params.refId;
  const _location = useLocation();
  const app_classes = app_styles(props);
  const modal_classes = modal_styles();
  const [job, setJob] = useState(null);
  const [employerDetails, setEmployerDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmailRegistered, setIsEmailRegistered] = useState(false);
  const influencerCode = new URLSearchParams(_location.search).get(
    "influencerCode"
  );

  const [errors, setErrors] = useState({
    email: null,
  });
  const source = new URLSearchParams(_location.search).get("source");
  const { currencyList, currency } = AppState;
  const [country, setCountry] = useState(null);
  const currencyOptions = {
    currencyList,
    currency,
  };
  const [showAuthModal, setShowAuthModal] = useState(null);
  const [auth_details, setAuthDetails] = useState({ subscribeToEmail: true });
  const [googleResponse, setGoogleResponse] = useState(null);
  const [error_fields, setErrorFields] = useState({
    email: null,
    password: null,
    confirm_password: null,
    firstName: null,
    lastName: null,
    dob: null,
    currentLocation: null,
    countryCode: null,
    phoneNumber: null,
    jobFunction: null,
    influencerCode: null,
  });
  const is_valid = async () => {
    // let required_fields = [
    //   "email",
    //   "password",
    //   "confirm_password",
    //   "firstName",
    //   "lastName",
    //   "dob",
    //   "currentLocation",
    //   "countryCode",
    //   "phoneNumber",
    //   "jobFunction",
    //   "agreeToTerms",
    // ];

    // if (googleResponse) {
    //   required_fields.splice(required_fields.indexOf("password"), 1);
    //   required_fields.splice(required_fields.indexOf("confirm_password"), 1);
    // }
    // let errors = {};
    // for (let key of required_fields) {
    //   if (!auth_details[key]) {
    //     errors[key] = "Required";
    //   } else {
    //     errors[key] = null;
    //     if (key === "email") {
    //       const email_valid = await validateEmail(auth_details[key]);
    //       if (email_valid === false) {
    //         errors["email"] = "Not a valid email address!";
    //       } else {
    //         userRequest
    //           .validateEmail({ email: auth_details.email })
    //           .then(() => {
    //             errors["email"] = null;
    //           })
    //           .catch(() => {
    //             errors["email"] = "Email already exists";
    //           });
    //       }
    //     }
    //     if (key === "confirm_password") {
    //       if (auth_details["confirm_password"] !== auth_details["password"]) {
    //         errors["confirm_password"] = "Password did not matched!";
    //       } else {
    //         errors["confirm_password"] = null;
    //       }
    //     }
    //   }
    // }
    // if (auth_details?.influencerCode) {
    //   await userRequest
    //     .validateInfluencerCode({
    //       influencerCode: auth_details?.influencerCode,
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       if (res.data?.valid) {
    //         errors["influencerCode"] = null;
    //       } else {
    //         errors["influencerCode"] = "Invalid Code";
    //       }
    //     })
    //     .catch(() => {
    //       errors["influencerCode"] = "Invalid Code";
    //     });
    // }
    // setErrorFields(errors);
    // let valids = [];
    // for (let key of Object.keys(errors)) {
    //   if (errors[key] === null) {
    //     //ok
    //   } else {
    //     valids.push(false);
    //   }
    // }

    let valids = [];
    let errors2 = error_fields;

    if (auth_details.email) {
      if (validateEmail(auth_details.email)) {
        const email_valid = await validateEmail(auth_details.email?.trim());
        if (email_valid === false) {
          errors2 = {
            ...errors2,
            email: "Invalid email address.",
          };
        } else {
          userRequest
            .validateEmail({ email: auth_details.email?.trim() })
            .then(() => {
              valids.push(true);
              errors2 = { ...errors2, email: null };
            })
            .catch(() => {
              console.log("err");
              valids.push(false);
              errors2 = {
                ...error_fields,
                email: "Email already exists",
              };
            });
        }
      } else {
        console.log("err");
        valids.push(false);
        errors2 = { ...errors2, email: "Invalid email address" };
      }
    } else {
      console.log("err");
      valids.push(false);
      errors2 = { ...errors2, email: "Email address is required" };
    }

    if (!googleResponse) {
      if (
        !!auth_details.password === false ||
        auth_details.password?.length < 8
      ) {
        errors2 = {
          ...errors2,
          password: "Password must contain atleast 8 characters",
        };
        valids.push(false);
      } else {
        errors2 = { ...errors2, password: null };
        valids.push(true);
      }

      if (auth_details.confirm_password !== auth_details.password) {
        errors2 = { ...errors2, confirm_password: "Passwords do not match!" };
        valids.push(false);
      } else {
        errors2 = {
          ...errors2,
          confirm_password: null,
        };
        valids.push(true);
      }
    }

    if (!auth_details.firstName) {
      errors2 = { ...errors2, firstName: "First name is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        firstName: null,
      };
      valids.push(true);
    }

    if (!auth_details.lastName) {
      errors2 = { ...errors2, lastName: "Last name is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        lastName: null,
      };
      valids.push(true);
    }

    if (!auth_details.dob) {
      errors2 = { ...errors2, dob: "Date of birth is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        dob: null,
      };
      valids.push(true);
    }

    if (!auth_details.currentLocation) {
      errors2 = { ...errors2, currentLocation: "Location is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        currentLocation: null,
      };
      valids.push(true);
    }

    if (!auth_details.countryCode) {
      errors2 = { ...errors2, countryCode: "Country code is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        countryCode: null,
      };
      valids.push(true);
    }

    if (!auth_details.phoneNumber) {
      errors2 = { ...errors2, phoneNumber: "Phone number is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        phoneNumber: null,
      };
      valids.push(true);
    }

    if (!auth_details.jobFunction) {
      errors2 = { ...errors2, jobFunction: "Job function is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        jobFunction: null,
      };
      valids.push(true);
    }

    if (!!auth_details.influencerCode) {
      let influencerCode = auth_details.influencerCode;
      await userRequest
        .validateInfluencerCode({ influencerCode })
        .then((res) => {
          console.log("inf resp", res.data);
          if (res.data?.valid === true) {
            errors2 = {
              ...errors2,
              influencerCode: null,
            };
            valids.push(true);
          } else {
            errors2 = {
              ...errors2,
              influencerCode: res.data?.error?.message?.split(":")[1],
            };
            valids.push(false);
          }
        });
    } else if (!auth_details.influencerCode) {
      errors2 = {
        ...errors2,
        influencerCode: null,
      };
      valids.push(true);
    }

    setErrorFields(errors2);
    if (valids.includes(false)) return false;

    return true;
  };

  const addView = (ip) => {
    userRequest.addView({
      logData: {
        id: ip,
        link: `https://jobs.joblu.io/job/${job?.referenceId}`
      },
      id: job?.referenceId,
      type: "JOB"
    })
    .then(res => console.log(res))
    .catch(err => console.log(err))
  }

  useEffect(() => {
    if(job){
      axios.get("https://geolocation-db.com/json/")
      .then(res => {
        addView(res?.data?.IPv4)
      })
      .catch(e => {
        addView(Math.floor(Math.random() * 900000) + 100000)
      })
    }
  }, [job])

  useEffect(() => {
    console.log("error_fields", error_fields);
  }, [error_fields]);

  const countryCodes = require("country-codes-list");
  const myCountryCodesObject = countryCodes.customList(
    "countryCode",
    "{countryNameEn}:+{countryCallingCode}"
  );

  const [countryCodesList, setCountryCodesList] = useState(
    Object.values(myCountryCodesObject).map((item) => {
      var temp = item.split(":");
      return {
        label: `${temp[0]} (${temp[1]})`,
        value: item,
      };
    })
  );

  const handleSignup = async () => {
    console.log("data paylaod", auth_details);
    if (await is_valid()) {
      //submit
      let payload = {
        userRole: "APPLICANT",
        email: auth_details.email?.trim(),
        password: auth_details.password,
        firstName: auth_details.firstName,
        lastName: auth_details.lastName,
        dob: auth_details.dob,
        currentLocation: auth_details.currentLocation,
        country: auth_details?.country,
        region: auth_details?.region,
        city: auth_details?.city,
        phoneNumber: `${
          auth_details.countryCode?.split(":")?.[1]
        }${auth_details.phoneNumber?.trim()}`,
        jobFunction: auth_details.jobFunction,
        subscribeToEmail: auth_details?.subscribeToEmail,
        source: source,
        leadAdsData: refId ? { referenceId: refId } : undefined,
      };

      if (auth_details?.influencerCode) {
        payload["influencerCode"] = auth_details?.influencerCode;
      }

      if (googleResponse) {
        //google
        payload = {
          ...payload,
          action: "register",
          platform: "web",
          idToken: googleResponse?.tokenId,
          source: source,
          leadAdsData: refId ? { referenceId: refId } : undefined,
          role: "APPLICANT",
        };

        delete payload.password;

        console.log("google payload", payload);
        verifyGoogleToken(payload);
      } else {
        signUp(payload);
      }
    } else {
      console.log("invalid");
    }
  };

  useEffect(() => {
    console.log(refId);
    jobsRequest
      .getJobDetailsByRefId(refId)
      .then((res) => {
        setJob(res.data);
        setLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [refId]);

  useEffect(() => {
    console.log(job);
    if (job) {
      userRequest.getEmployerDetailsP(job?.employerId).then((res) => {
        console.log("employer", res?.data);
        setEmployerDetails(res?.data?.employerProfiles);
      });
    }
  }, [job]);

  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  // useEffect(() => {
  //   if (showAuthModal === "signup" && auth_details?.email) {
  //     userRequest
  //       .validateEmail({ email: auth_details.email })
  //       .then(() => {
  //         setErrorFields({ ...error_fields, email: null });
  //       })
  //       .catch(() => {
  //         setErrorFields({ ...error_fields, email: "Email already exists" });
  //       });
  //   }
  // }, [auth_details?.email]);

  const handleTextFieldChange = (event) => {
    const { name, value } = event?.target;
    setAuthDetails({
      ...auth_details,
      [name]: value,
    });
  };

  const handleGoogleLogin = async (data) => {
    let payload;
    if (isSupported) {
      if (Notification.permission !== "granted") {
        payload = {
          email: data?.profileObj?.email?.trim(),
          idToken: data?.tokenId,
          action: "login",
          role: "APPLICANT",
          platform: "web",
        };
      } else {
        await messaging.getToken().then((token) => {
          payload = {
            email: data?.profileObj?.email?.trim(),
            idToken: data?.tokenId,
            action: "login",
            role: "APPLICANT",
            platform: "web",
          };
        });
      }
    } else {
      payload = {
        email: data?.profileObj?.email?.trim(),
        idToken: data?.tokenId,
        action: "login",
        role: "APPLICANT",
        platform: "web",
      };
    }
    if (payload?.deviceToken) {
      setGoogleResponse({ ...data, token: payload?.deviceToken });
    } else {
      setGoogleResponse(data);
    }
    verifyGoogleToken(payload);
  };

  const location = useLocation();
  const [referrers, setReferrers] = useState();

  useEffect(() => {
    const refId = new URLSearchParams(location.search).get(
      "refId" || location?.state?.refId
    );
    const influencerCode = new URLSearchParams(location.search).get(
      "influencerCode"
    );
    const source = new URLSearchParams(location.search).get("source");

    setReferrers({ refId, source, influencerCode });
  }, [location]);

  useEffect(() => {
    if (referrers?.influencerCode) {
      setAuthDetails({
        ...auth_details,
        influencerCode: referrers?.influencerCode,
      });
    }
  }, [referrers]);

  const closeAuthModal = () => {
    setShowAuthModal(null);
    setGoogleResponse(null);
    setAuthDetails({});
  };
  useEffect(() => {
    console.log("isGoogleSignup", isGoogleSignup);
    console.log(googleResponse);
    if (!!googleResponse && !isSignedIn && !isSigningIn && isGoogleSignup) {
      // setTimeout(() => {
      //   let url = "/signup";
      //   if (referrers?.refId) {
      //     url = url + "?refId=" + referrers?.refId;
      //   }
      //   if (referrers?.influencerCode) {
      //     url =
      //       url +
      //       `${referrers?.refId ? "&" : "?"}influencerCode=` +
      //       referrers?.influencerCode;
      //   }
      //   if (referrers?.source) {
      //     url =
      //       url +
      //       `${
      //         referrers?.refId || referrers?.influencerCode ? "&" : "?"
      //       }source=` +
      //       referrers?.source;
      //   }
      //   navigate(url, {
      //     state: { isFromLogin: true, refId: referrers?.refId, googleResponse },
      //   });
      // }, 1000);
      setShowAuthModal("signup");
    }
  }, [googleResponse, isSignedIn, isSigningIn, isGoogleSignup]);

  useEffect(() => {
    console.log("this state is updating");
    if (isRegistered) {
      console.log("registered is updated");
      signInUser();
    } else {
      //
      console.log("registered is updated");
    }
  }, [isRegistered, isRegistering]);

  const signInUser = () => {
    if (googleResponse) {
      console.log("user will be logged in");
      //sign in via google
      let payload = {
        email: googleResponse?.profileObj?.email?.trim(),
        role: "APPLICANT",
        //google
        action: "login",
        platform: "web",
        idToken: googleResponse?.tokenId,
      };
      verifyGoogleToken(payload);
    } else {
      if (isSupported) {
        if (Notification.permission !== "granted") {
          signIn({
            email: auth_details?.email?.trim(),
            password: auth_details?.password,
          });
        } else {
          messaging
            ? messaging.getToken().then((token) => {
                signIn({
                  email: auth_details?.email?.trim(),
                  password: auth_details?.password,
                  deviceToken: token,
                });
              })
            : signIn({
                email: auth_details?.email?.trim(),
                password: auth_details?.password,
              });
        }
      } else
        signIn({
          email: auth_details?.email?.trim(),
          password: auth_details?.password,
        });
    }
  };

  useEffect(() => {
    if (showAuthModal === "signup") {
      if (!auth_details.email) {
        setErrorFields({ ...error_fields, email: null });
      } else {
        const delayDebounceFn = setTimeout(() => {
          userRequest
            .validateEmail(auth_details)
            .then((res) => {
              setErrorFields({ ...error_fields, email: null });
            })
            .catch((e) => {
              setErrorFields({
                ...error_fields,
                email: "Email already exists",
              });
              // setErrorFields({ email: "Email already exists" });
            });
        }, 500);
        return () => clearTimeout(delayDebounceFn);
      }
    }
  }, [auth_details?.email]);

  const [showShareModal, setShowShareModal] = useState(false);
  const [jobLink, setJobLink] = useState(null);
  const [jobCopied, setJobCopied] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(jobLink);
    setJobCopied(true);
    setTimeout(() => {
      setJobCopied(false);
    }, 2000);
  };

  const [_search, setSearch] = useState(null);
  const search = new URLSearchParams(_location.search).get("search");

  useEffect(() => {
    setSearch(search);
  }, [search]);

  const MetaTag = () => {
    const jobTitle = formatString(job?.jobTitle);
    const companyName = job?.companyName
      ? job?.companyName
      : job?.employer?.companyName;
    const dateNow = moment().format("MMMM YYYY");

    if(job){
      return (
        <MetaTags>
          <title>{`${jobTitle} Job Opening at ${companyName} | Joblu`}</title>
          <meta
            name="description"
            content={`Apply for the ${jobTitle} in ${companyName} through Joblu. ${employerDetails?.companyOverview?.substring(
              0,
              50
            )}`}
          />
          <meta
            property="og:title"
            content={`${jobTitle} Job Opening at ${companyName} | Joblu`}
          />
        </MetaTags>
      );
    }
    else{
      return (
        <MetaTags>
          <title>{`Job Openings at Joblu | ${dateNow}`}</title>
          <meta
            name="description"
            content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
          />
          <meta
            property="og:title"
            content={`IT, Customer Service, Accounting Job Openings at Joblu | ${dateNow}`}
          />
          <meta
            property="og:description"
            content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
          />
          <meta property="og:image"
            content="https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/joblu-meta-img.jpeg" />
      </MetaTags>
      );
    }
  };

  const MetaTag_ = () => {
    const dateNow = moment().format("MMMM YYYY");
    return (
      <MetaTags>
        <title>{`Job Openings at Joblu | ${dateNow}`}</title>
        <meta
          name="description"
          content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
        />
        <meta
          property="og:title"
          content={`IT, Customer Service, Accounting Job Openings at Joblu | ${dateNow}`}
        />
        <meta
          property="og:description"
          content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
        />
        <meta property="og:image"
          content="https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/joblu-meta-img.jpeg" />
    </MetaTags>
    )
  }


  const [coverHeight, setCoverHeight] = useState(null)
  const [coverWidth, setCoverWidth] = useState(null)

  
  if (loading) {
    return (
      <>
        <MetaTag_/>
        <Box
          className={`${app_classes.height.vh100} ${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center} ${app_classes.flexBox.justify_content_center}`}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      </>
    );
  } else {
    if (!job) {
      return (
        <>
        <MetaTag_/>
        <NotFound/>
        </>
      )
    }

    if (job?.status !== "active") {
      return (
        <>
        <MetaTag_/>
        <NotFound/>
        </>
      )
    }

    if (job?.isVerified !== true) {
      return (
        <>
        <MetaTag_/>
        <NotFound/>
        </>
      )
    }



    return (
      <>
        <MetaTag />
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
        >
          <Box className={`${app_classes.padding.a5}`}>
            <Box
              className={`${app_classes.text.center} ${app_classes.margin.b3}`}
            >
              <JobluTypography.H4 primary>
                Sharing {job?.jobTitle} Job
              </JobluTypography.H4>
            </Box>
            <Grid
              container
              spacing={2}
              style={{
                alignContent: "center",
                justifyContent: "center",
                marginBottom: 16,
              }}
            >
              <Grid item xs={2}>
                <FacebookShareButton url={jobLink}>
                  <FacebookIcon size={width < 640 ? 36 : 48} round />
                </FacebookShareButton>
              </Grid>
              <Grid item xs={2}>
                <LinkedinShareButton url={jobLink}>
                  <LinkedinIcon size={width < 640 ? 36 : 48} round />
                </LinkedinShareButton>
              </Grid>
              <Grid item xs={2}>
                <EmailShareButton url={jobLink}>
                  <EmailIcon size={width < 640 ? 36 : 48} round />
                </EmailShareButton>
              </Grid>
              <Grid item xs={2}>
                <WhatsappShareButton url={jobLink}>
                  <WhatsappIcon size={width < 640 ? 36 : 48} round />
                </WhatsappShareButton>
              </Grid>

              <Grid item xs={2}>
                <TwitterShareButton url={jobLink}>
                  <TwitterIcon size={width < 640 ? 36 : 48} round />
                </TwitterShareButton>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={9} xs={12}>
                <TextFieldV1 value={jobLink} disabled />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setJobCopied(false)}
                  open={jobCopied}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Link Copied"
                  placement="bottom"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      copyLink();
                    }}
                  >
                    Copy Link
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
        <PublicHeader
          influencerCode={influencerCode}
          source={source}
          isViewJob
          job={job}
          refId={refId}
        />
        <Grid
          container
          justifyContent={"center"}
          className={`${app_classes.margin.t6} ${app_classes.margin.b6}`}
        >
          <Grid item xs={11} sm={8}>
            <Card>
              <Grid container justifyContent="center">
                <Grid
                  xs={12}
                  style={{
                    // height: 300,
                    borderRadius: 15,
                    // backgroundImage: `url(${
                    //   employerProfiles?.coverUrl || DefaultProfileBg
                    // })`,
                  }}
                >
                     <img
                      src={employerDetails?.coverUrl || DefaultProfileBg}
                      style={{
                        width: "100%",
                        maxHeight: coverHeight > 500 ? 400 : 'auto',
                        minHeight: 150,
                        objectFit: `${coverHeight > 350 || coverWidth > 900 ? width <640 ? 'contain' : 'cover' : 'contain'}`,
                      }}
                      onLoad={(e)=>{
                        setCoverHeight(e.target.naturalHeight)
                        setCoverWidth(e.target.naturalWidth)
                      }}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box
                    className={`${app_classes.padding.a4}`}
                    style={{ marginTop: -120 }}
                  >
                    <Box className={`${app_classes.text.center}`}>
                      <Box className={`${app_classes.margin.b2}`}>
                        <img
                          alt=""
                          src={
                            job?.companyLogo
                              ? job?.companyLogo
                              : job?.employer?.profileUrl
                              ? job?.employer?.profileUrl
                              : DefaultImg
                          }
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                            border: "10px solid #C9CCCE",
                            objectFit: "cover",

                            background: "#FFFFFF",
                          }}
                        />
                      </Box>
                      <Box className={`${app_classes.margin.b1}`}>
                        <JobluTypography.H2
                          semiBold
                          primary
                          className={`${app_classes.text.capitalize}`}
                          onClick={() => {
                            console.log(job);
                          }}
                        >
                          {job?.jobTitle}
                        </JobluTypography.H2>
                      </Box>
                      <>
                        <Box className={`${app_classes.margin.b1}`}>
                          {!job?.companyName ? (
                            <>
                              {" "}
                              <Chip
                                color="primary"
                                label={`${
                                  job?.companyName
                                    ? job?.companyName
                                    : job?.employer?.companyName
                                }`}
                                // className={`${modal_classes.chip_main}`}
                                onClick={() => {
                                  if (!job?.companyName) {
                                    window.open(
                                      `/company-showcase/${
                                        job?.employer?.userName ||
                                        job?.employer?.userId
                                      }`
                                    );
                                  }
                                }}
                                deleteIcon={
                                  <KeyboardArrowRightIcon
                                    style={{ color: "#FFFFFF" }}
                                  />
                                }
                                onDelete={() => {
                                  window.open(
                                    `/company-showcase/${
                                      job?.employer?.userName ||
                                      job?.employer?.userId
                                    }`
                                  );
                                }}
                              ></Chip>
                            </>
                          ) : (
                            <>
                              <Chip
                                color="primary"
                                label={`${
                                  job?.companyName
                                    ? job?.companyName
                                    : job?.employer?.companyName
                                }`}
                                onClick={() => {
                                  if(job?.isNewConnect && job?.directCompanyLink){
                                    window.open(job?.directCompanyLink)
                                    return;
                                  }
                                }}
                                deleteIcon={
                                  <KeyboardArrowRightIcon
                                    style={{ color: "#FFFFFF" }}
                                  />
                                }
                                onDelete={() => {
                                  if(job?.isNewConnect && job?.directCompanyLink){
                                    window.open(job?.directCompanyLink)
                                    return;
                                  }
                                }}
                                // className={`${modal_classes.chip_main}`}
                              ></Chip>
                            </>
                          )}
                        </Box>
                      </>
                      <Box className={`${app_classes.margin.b1}`}>
                        <JobluTypography.Body
                          semiBold
                          className={`${app_classes.text.uppercase}`}
                        >
                          REFERENCE ID: {job?.referenceId}
                        </JobluTypography.Body>
                      </Box>
                      {job?.employer?.website && (
                        <>
                          <Box className={`${app_classes.margin.b2}`}>
                            <Link
                              target="_blank"
                              to={job?.employer?.website}
                              className={app_classes.margin.r1}
                            >
                              <JobluTypography.Body secondary semiBold>
                                Visit their website
                              </JobluTypography.Body>
                            </Link>
                          </Box>
                        </>
                      )}
                    </Box>

                    <Box
                      className={`${
                        !job?.companyName && app_classes.margin.b2
                      }`}
                    >
                      <Box>
                        <JobluTypography.H5 semiBold primary>
                          Responsibilities
                        </JobluTypography.H5>
                      </Box>
                      <Box>
                        <JobluTypography.Body
                          primary
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {job?.responsibilities}
                        </JobluTypography.Body>
                      </Box>
                    </Box>
                    {!job?.companyName && (
                      <>
                        <Box>
                          <Box>
                            <JobluTypography.H5
                              semiBold
                              primary
                              onClick={() => {
                                console.log(job?.employer);
                              }}
                            >
                              About {employerDetails?.companyName}
                            </JobluTypography.H5>
                          </Box>
                          <Box>
                            <JobluTypography.Body
                              primary
                              style={{ whiteSpace: "pre-wrap" }}
                            >
                              {employerDetails.companyOverview}
                            </JobluTypography.Body>
                          </Box>
                        </Box>
                      </>
                    )}
                    {/* <SquareBanner style={{marginTop: 20}}/> */}
                     <VignetteBanner/>
                     <InpageBanner/>
                     {/* <MultiBanner/> */}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  style={{ borderLeft: width > 640 && "1px solid #8080804D" }}
                >
                  <Box className={`${app_classes.padding.a4}`}>
                    <Box className={`${app_classes.margin.b3}`}>
                      <Grid container spacing={1} justifyContent="flex-end">
                        <>
                          {width > 640 && (
                            <>
                              <Grid item>
                                <Box>
                                  <Button
                                    variant="contained"
                                    size="large"
                                    style={{ borderRadius: 100 }}
                                    className={`${app_classes.button.primary} ${app_classes.margin.r1}`}
                                    onClick={() => {
                                      const newConnect = openNewConnect(job);
                                      if(newConnect) return;
                                      let url = "/login";
                                      if (job?.referenceId) {
                                        url =
                                          url + "?refId=" + job?.referenceId;
                                      }
                                      if (influencerCode) {
                                        url =
                                          url +
                                          `${
                                            job?.referenceId ? "&" : "?"
                                          }influencerCode=` +
                                          influencerCode;
                                      }
                                      if (source) {
                                        url =
                                          url +
                                          `${
                                            job?.referenceId || influencerCode
                                              ? "&"
                                              : "?"
                                          }source=` +
                                          source;
                                      }

                                      setShowAuthModal("login");
                                      // navigate(url, {
                                      //   state: {
                                      //     influencerCode: influencerCode,
                                      //     refId: job?.referenceId,
                                      //     source: source,
                                      //   },
                                      // });
                                    }}
                                  >
                                    Apply Now
                                    <SendIcon
                                      style={{ width: 16 }}
                                      className={`${app_classes.margin.l1}`}
                                    />
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="large"
                                    onClick={() => {
                                      setJobLink(
                                        `https://jobs.joblu.io/job/${
                                          job?.referenceId
                                        }/${replaceSpaces(job?.jobTitle)}`
                                      );
                                      setShowShareModal(true);
                                    }}
                                    style={{ borderRadius: 100 }}
                                    className={` ${app_classes.margin.r1} `}
                                  >
                                    Share
                                    <img
                                      src={ShareArrow}
                                      width={16}
                                      className={`${app_classes.margin.l1}`}
                                    />
                                  </Button>
                                </Box>
                              </Grid>
                            </>
                          )}

                          {/* <Grid item>
                          <img
                            src={DismissIcon}
                            className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                          />
                        </Grid>

                        <Grid item>
                          <img
                            src={LikeIcon}
                            className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                          />
                        </Grid> */}
                        </>
                        {/* <Grid item>
                      <img
                        src={ShareIcon}
                        className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={ExpandIcon}
                        className={`${modal_classes.action_button} ${app_classes.cursor.pointer}`}
                      />
                    </Grid> */}
                      </Grid>
                    </Box>
                    <Box>
                      <Box className={`${app_classes.margin.b3}`}>
                        {/* <MediumBanner fit={true}/> */}
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H5 semiBold primary>
                            Job Summary
                          </JobluTypography.H5>
                        </Box>
                        <>
                          <Box className={`${app_classes.margin.b3}`}>
                            <Box
                              className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                            >
                              <Box className={`${app_classes.margin.r2}`}>
                                <img
                                  src={LocationIcon}
                                  className={`${modal_classes.icons}`}
                                />
                              </Box>
                              <Box>
                                <Box>
                                  <JobluTypography.H6 semiBold primary>
                                    {job?.location}
                                  </JobluTypography.H6>
                                </Box>
                                <Box>
                                  <JobluTypography.Body primary>
                                    Location
                                  </JobluTypography.Body>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>

                        {job?.jobFunction && (
                          <>
                            <Box className={`${app_classes.margin.b3}`}>
                              <Box
                                className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                              >
                                <Box className={`${app_classes.margin.r2}`}>
                                  <img
                                    src={IndustryIcon}
                                    className={`${modal_classes.icons}`}
                                  />
                                </Box>
                                <Box>
                                  <Box>
                                    <JobluTypography.H6 semiBold primary>
                                      {job?.jobFunction}
                                    </JobluTypography.H6>
                                  </Box>
                                  <Box>
                                    <JobluTypography.Body primary>
                                      Industry
                                    </JobluTypography.Body>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box className={`${app_classes.margin.b3}`}>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H5 semiBold primary>
                            What We Offer
                          </JobluTypography.H5>
                        </Box>
                        {!!job?.salaryMin && job?.salaryMax && (
                          <>
                            <Box className={`${app_classes.margin.b3}`}>
                              <Box
                                className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                              >
                                <Box className={`${app_classes.margin.r2}`}>
                                  <img
                                    src={SalaryIcon}
                                    className={`${modal_classes.icons}`}
                                  />
                                </Box>
                                <Box>
                                  <Box>
                                    <JobluTypography.H6 semiBold primary>
                                      {job?.salaryMin === 1 &&
                                      job?.salaryMax === 1
                                        ? salaryRanges.COMPETIIVE_SALARY
                                        : job?.salaryMin === 2 &&
                                          job?.salaryMax === 2
                                        ? salaryRanges.INDUSTRY_STANDARD
                                        : `${formatMoney(
                                            job?.salaryMin,
                                            currencyOptions,
                                            job?.salaryCurrency || "SGD"
                                          )} - ${formatMoney(
                                            job?.salaryMax,
                                            currencyOptions,
                                            job?.salaryCurrency || "SGD"
                                          )}`}
                                    </JobluTypography.H6>
                                  </Box>
                                  <Box>
                                    <JobluTypography.Body primary>
                                      Compensation - Salary
                                    </JobluTypography.Body>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}

                        {job?.employmentType?.length > 0 &&
                          job?.employmentType != "TBD" && (
                            <>
                              <Box className={`${app_classes.margin.b3}`}>
                                <Box
                                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                >
                                  <Box className={`${app_classes.margin.r2}`}>
                                    <img
                                      src={EmploymentTypeIcon}
                                      className={`${modal_classes.icons}`}
                                    />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <JobluTypography.H6 semiBold primary>
                                        {job?.employmentType?.join(", ")}
                                      </JobluTypography.H6>
                                    </Box>
                                    <Box>
                                      <JobluTypography.Body primary>
                                        Employment Type
                                      </JobluTypography.Body>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </>
                          )}
                        {job?.workSetup?.length > 0 &&
                          job?.workSetup != "TBD" && (
                            <>
                              <Box>
                                <Box
                                  className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                >
                                  <Box className={`${app_classes.margin.r2}`}>
                                    <img
                                      src={WorkSetupIcon}
                                      className={`${modal_classes.icons}`}
                                    />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <JobluTypography.H6 semiBold primary>
                                        {job?.workSetup?.join(", ")}
                                      </JobluTypography.H6>
                                    </Box>
                                    <Box>
                                      <JobluTypography.Body primary>
                                        Work Setup
                                      </JobluTypography.Body>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </>
                          )}
                      </Box>
                      {job?.education?.length > 0 ||
                      !!job?.totalExperienceMin ||
                      (!!job?.totalExperienceMax &&
                        job?.totalExperienceMax != 0) ? (
                        <>
                          <Box className={`${app_classes.margin.b3}`}>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.H5 semiBold primary>
                                Requirements
                              </JobluTypography.H5>
                            </Box>
                            {job?.education?.length > 0 &&
                              job?.education != "TBD" && (
                                <>
                                  <Box className={`${app_classes.margin.b3}`}>
                                    <Box
                                      className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                    >
                                      <Box
                                        className={`${app_classes.margin.r2}`}
                                      >
                                        <img
                                          src={EducationIcon}
                                          className={`${modal_classes.icons}`}
                                        />
                                      </Box>
                                      <Box>
                                        <Box>
                                          <JobluTypography.H6 semiBold primary>
                                            {job?.education?.join(", ")}
                                          </JobluTypography.H6>
                                        </Box>
                                        <Box>
                                          <JobluTypography.Body primary>
                                            Education
                                          </JobluTypography.Body>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              )}

                            {job?.totalExperienceMin ||
                            (job?.totalExperienceMax &&
                              job?.totalExperienceMax !== 0) ? (
                              <>
                                <Box className={`${app_classes.margin.b2}`}>
                                  <Box
                                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                  >
                                    <Box className={`${app_classes.margin.r2}`}>
                                      <img
                                        src={ExperienceIcon}
                                        className={`${modal_classes.icons}`}
                                      />
                                    </Box>
                                    <Box>
                                      <Box>
                                        <JobluTypography.H6 semiBold primary>
                                          {job?.totalExperienceMin &&
                                            `${job?.totalExperienceMin}`}
                                          {job?.totalExperienceMax == "100"
                                            ? "+ Years"
                                            : `- ${job?.totalExperienceMax} Years`}
                                        </JobluTypography.H6>
                                      </Box>
                                      <Box>
                                        <JobluTypography.Body primary>
                                          Work Experience
                                        </JobluTypography.Body>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ) : null}
                          </Box>
                        </>
                      ) : null}

                      {job?.technicalSkills?.length > 0 && (
                        <>
                          <Box className={`${app_classes.margin.b3}`}>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.H5 semiBold primary>
                                Required Skills & Expertise
                              </JobluTypography.H5>
                            </Box>
                            <Box>
                              {job?.technicalSkills?.map((perks) => {
                                return (
                                  <Chip
                                    label={perks}
                                    color="secondary"
                                    className={`${modal_classes.chip}`}
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                        </>
                      )}
                      {job?.perksAndBenefits?.length > 0 && (
                        <>
                          <Box className={`${app_classes.margin.b3}`}>
                            <Box className={`${app_classes.margin.b2}`}>
                              <JobluTypography.H5 semiBold primary>
                                Perks and Benefits
                              </JobluTypography.H5>
                            </Box>
                            <Box>
                              {" "}
                              {job?.perksAndBenefits?.map((perks) => {
                                return (
                                  <Chip
                                    label={perks}
                                    color="secondary"
                                    className={`${modal_classes.chip}`}
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                        </>
                      )}
                      {width < 640 && (
                        <>
                          <Box className={`${app_classes.text.center}`}>
                            <Box>
                              <Button
                                variant="contained"
                                size="large"
                                className={`${app_classes.button.primary} ${app_classes.border.border_radius_16} ${app_classes.margin.b2}`}
                                onClick={() => {
                                  if(job?.isNewConnect && job?.jobDirectLink){
                                    window.location.href = job?.jobDirectLink
                                    return;
                                  }

                                  let url = "/login";
                                  if (job?.referenceId) {
                                    url = url + "?refId=" + job?.referenceId;
                                  }
                                  if (influencerCode) {
                                    url =
                                      url +
                                      `${
                                        job?.referenceId ? "&" : "?"
                                      }influencerCode=` +
                                      influencerCode;
                                  }
                                  if (source) {
                                    url =
                                      url +
                                      `${
                                        job?.referenceId || influencerCode
                                          ? "&"
                                          : "?"
                                      }source=` +
                                      source;
                                  }

                                  setShowAuthModal("login");
                                  // navigate(url, {
                                  //   state: {
                                  //     influencerCode: influencerCode,
                                  //     refId: job?.referenceId,
                                  //     source: source,
                                  //   },
                                  // });
                                }}
                                endIcon={
                                  <SendIcon
                                    className={`${app_classes.margin.l1}`}
                                  />
                                }
                              >
                                Apply Now{" "}
                              </Button>
                            </Box>
                            <Box>
                              <Button
                                variant="outlined"
                                color="secondary"
                                size="large"
                                onClick={() => {
                                  setJobLink(
                                    `https://jobs.joblu.io/job/${
                                      job?.referenceId
                                    }/${replaceSpaces(job?.jobTitle)}`
                                  );
                                  setShowShareModal(true);
                                }}
                                style={{ borderRadius: 100 }}
                                className={` ${app_classes.margin.r1} `}
                              >
                                Share
                                <img
                                  src={ShareArrow}
                                  width={16}
                                  className={`${app_classes.margin.l1}`}
                                />
                              </Button>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Footer />

        <Dialog
          open={showAuthModal === "login"}
          fullWidth
          maxWidth={"xs"}
          onClose={() => {
            closeAuthModal();
          }}
        >
          <Box
            className={`${app_classes.text.center}`}
            style={{ padding: "100px 30px" }}
          >
            <Box className={`${app_classes.margin.b3}`}>
              <img src={JobluH} width={229} />
            </Box>
            <Box className={`${app_classes.margin.b2}`}>
              <JobluTypography.H2 primary style={{ fontWeight: 600 }}>
                Ready to get hired?
                <br /> Login now!
              </JobluTypography.H2>
            </Box>
            <Box
              className={`${app_classes.margin.b3} ${app_classes.text.left}`}
            >
              <Box>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  primary
                  style={{ fontWeight: 700 }}
                >
                  Email Address
                </JobluTypography.Body>
              </Box>
              <TextFieldV1
                size="small"
                variant="outlined"
                placeholder="Email Address"
                fullWidth
                onChange={(e) => {
                  setAuthDetails({ ...auth_details, email: e.target.value });
                }}
                error={error_fields?.email}
                name="email"
              />
            </Box>
            {isEmailRegistered && (
              <Box className={`${app_classes.margin.b3}`}>
                <Box className={`${app_classes.margin.b1}`}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    primary
                    style={{ fontWeight: 700 }}
                  >
                    Password
                  </JobluTypography.Body>
                </Box>
                <TextFieldV1
                  type="password"
                  required
                  className="mb-4"
                  id="password"
                  name="password"
                  onChange={(e) => {
                    setAuthDetails({
                      ...auth_details,
                      password: e.target.value,
                    });
                  }}
                />
              </Box>
            )}

            <Box>
              {isSigningIn ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 20,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  style={{ textTransform: "none", fontWeight: 700 }}
                  onClick={() => {
                    if (auth_details.email) {
                      if (validateEmail(auth_details.email?.trim())) {
                        if (!isEmailRegistered) {
                          userRequest
                            .validateEmail(auth_details)
                            .then((res) => {
                              setShowAuthModal("signup");
                            })
                            .catch((err) => {
                              console.log("err", err);
                              setIsEmailRegistered(true);
                            });
                        } else {
                          signIn({
                            email: auth_details.email?.trim(),
                            password: auth_details?.password,
                          });
                        }
                      } else {
                        console.log("failed");
                        setErrorFields({
                          ...error_fields,
                          email: "Invalid Email Address",
                        });
                      }
                    } else {
                      setErrorFields({
                        ...error_fields,
                        email: "Email Address is required",
                      });
                    }
                  }}
                >
                  {isEmailRegistered ? "Login" : "Continue"}
                </Button>
              )}
            </Box>
            <Box className={`${app_classes.margin.y1}`}>
              <JobluTypography.Body primary style={{ fontWeight: 700 }}>
                OR
              </JobluTypography.Body>
            </Box>
            <Box
              className={`${app_classes.margin.b5}`}
              style={{ border: "1px solid #002E5D", borderRadius: 3 }}
            >
              <GoogleLogin
                className="shadow-none"
                clientId={creds.GOOGLE_CLIENT_ID}
                onSuccess={(data) => {
                  handleGoogleLogin(data);
                  setAuthDetails({
                    ...auth_details,
                    firstName: data?.profileObj?.givenName,
                    lastName: data?.profileObj?.familyName,
                    email: data?.profileObj?.email?.trim(),
                  });
                }}
                buttonText={
                  <span style={{ color: "#002E5D", fontWeight: 700 }}>
                    Sign in with Google
                  </span>
                }
              />
            </Box>
            <Box>
              <JobluTypography.Body style={{ fontWeight: 600 }} secondary>
                <span
                  role="button"
                  onClick={() => navigate("/password-reset")}
                  style={{ textDecoration: "underline" }}
                >
                  Forgot Password?
                </span>{" "}
                |{" "}
                <span
                  role="button"
                  onClick={() => setShowAuthModal("signup")}
                  style={{ textDecoration: "underline" }}
                >
                  Create an Account
                </span>
              </JobluTypography.Body>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          open={showAuthModal === "signup"}
          fullWidth
          maxWidth={"sm"}
          scroll="body"
          onClose={() => {
            closeAuthModal();
          }}
        >
          <Box className={`${app_classes.padding.a5}`}>
            <Box className={`${app_classes.text.center}`}>
              <Box className={`${app_classes.margin.b3}`}>
                <img src={JobluH} width={130} />
              </Box>
              <Box className={`${app_classes.margin.b2}`}>
                <JobluTypography.H2 primary style={{ fontWeight: 600 }}>
                  Sign Up
                </JobluTypography.H2>
              </Box>
            </Box>
            <Box>
              <JobluTypography.H6
                primary
                semiBold
                style={{ fontWeight: 600, marginBottom: 20 }}
              >
                Login Information
              </JobluTypography.H6>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Box>
                    <Box>
                      <JobluTypography.Body
                        semiBold
                        className={`${app_classes.text.left} `}
                        style={{ fontWeight: 600 }}
                        primary
                      >
                        Email Address
                      </JobluTypography.Body>
                    </Box>
                    <TextFieldV1
                      placeholder="Email Address"
                      fullWidth
                      name="email"
                      onChange={(e) => {
                        handleTextFieldChange(e);
                      }}
                      error={error_fields?.email}
                      value={auth_details?.email}
                      readOnly={googleResponse?.profileObj?.email}
                    />
                  </Box>
                </Grid>
                {!googleResponse && (
                  <Grid item xs={12} sm={6}>
                    <JobluTypography.Body
                      semiBold
                      className={`${app_classes.text.left} `}
                      style={{ fontWeight: 600 }}
                      primary
                    >
                      Password
                    </JobluTypography.Body>

                    <TextFieldV1
                      placeholder="Password"
                      name="password"
                      onChange={(e) => {
                        handleTextFieldChange(e);
                      }}
                      error={error_fields?.password}
                      type="password"
                      value={auth_details?.password}
                    />
                  </Grid>
                )}
                {!googleResponse && (
                  <Grid item xs={12} sm={6}>
                    <JobluTypography.Body
                      semiBold
                      className={`${app_classes.text.left} `}
                      style={{ fontWeight: 600 }}
                      primary
                    >
                      Confirm Password
                    </JobluTypography.Body>

                    <TextFieldV1
                      placeholder="Confirm Password"
                      name="confirm_password"
                      onChange={(e) => {
                        handleTextFieldChange(e);
                      }}
                      error={error_fields?.confirm_password}
                      type="password"
                      value={auth_details?.confirm_password}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <JobluTypography.H6
                    primary
                    semiBold
                    style={{ fontWeight: 600, marginTop: 20 }}
                  >
                    Personal Information
                  </JobluTypography.H6>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    First Name
                  </JobluTypography.Body>

                  <TextFieldV1
                    placeholder="Juan"
                    name="firstName"
                    onChange={(e) => {
                      handleTextFieldChange(e);
                    }}
                    error={error_fields?.firstName}
                    type="text"
                    value={auth_details?.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    Last Name
                  </JobluTypography.Body>

                  <TextFieldV1
                    placeholder="Dela Cruz"
                    name="lastName"
                    onChange={(e) => {
                      handleTextFieldChange(e);
                    }}
                    error={error_fields?.lastName}
                    type="text"
                    value={auth_details?.lastName}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    Birthday
                  </JobluTypography.Body>

                  <TextFieldV1
                    placeholder="MM/DD/YYYY"
                    name="dob"
                    onChange={(e) => {
                      handleTextFieldChange(e);
                    }}
                    error={error_fields?.dob}
                    type="date"
                    value={auth_details?.dob}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    Current Location
                  </JobluTypography.Body>

                  <CustomGooglePlaces
                    error={error_fields?.currentLocation}
                    value={auth_details?.currentLocation}
                    onChange={(loc) => {
                      let countryCode;
                      if (loc?.countryValue) {
                        try {
                          const result = countryCodesList?.find(
                            (i) => i?.value?.split(":")[0] === loc?.countryValue
                          );
                          if (result) {
                            countryCode = result.value;
                          }
                        } catch (e) {}
                      }
                      setAuthDetails({
                        ...auth_details,
                        currentLocation: loc?.location,
                        region: loc?.region,
                        city: loc?.city,
                        country: loc?.countryValue,
                        countryCode: countryCode || undefined,
                      });
                    }}
                  />
                  {!!error_fields?.currentLocation && (
                    <span className="error-helper mt-0">
                      {error_fields?.currentLocation}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    Country Code
                  </JobluTypography.Body>

                  <SelectCountry2
                    name="countryCode"
                    onChange={(e) => {
                      console.log(e);
                      setAuthDetails({ ...auth_details, countryCode: e });
                    }}
                    noAll
                    value={auth_details?.countryCode}
                    error={error_fields?.countryCode}
                    placeholder="Country Code"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    Mobile Number
                  </JobluTypography.Body>
                  <TextFieldV1
                    onChange={handleTextFieldChange}
                    name="phoneNumber"
                    placeholder="Mobile Number"
                    value={auth_details?.phoneNumber}
                    error={error_fields?.phoneNumber}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    Job Function
                  </JobluTypography.Body>
                  <SelectJobFunction
                    onChange={(e) => {
                      console.log(e);
                      setAuthDetails({ ...auth_details, jobFunction: e });
                    }}
                    noAll
                    value={auth_details?.jobFunction}
                    name="jobFunction"
                    error={error_fields?.jobFunction}
                  />
                </Grid>

                <Grid item xs={12}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    Do you have an Influencer Code? Enter it here!{" "}
                    <span style={{ color: "gray" }}>(optional)</span>
                  </JobluTypography.Body>
                  <TextFieldV1
                    name="influencerCode"
                    onChange={handleTextFieldChange}
                    error={error_fields?.influencerCode}
                    value={auth_details?.influencerCode}
                    placeholder="Influencer Code"
                    disabled={
                      referrers?.influencerCode && !error_fields?.influencerCode
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className={`${app_classes.display.display_flex}`}>
                    <Checkbox
                      onChange={() => {
                        setAuthDetails({
                          ...auth_details,
                          agreeToTerms: !auth_details?.agreeToTerms,
                        });
                      }}
                    />
                    <JobluTypography.Body
                      semiBold
                      className={`${app_classes.text.left} `}
                      style={{
                        fontWeight: 400,
                        marginTop: "auto",
                        marginBottom: "auto",
                        fontSize: 16,
                        color: error_fields?.agreeToTerms && "red",
                      }}
                    >
                      I agree to the Joblu{" "}
                      <Link
                        component="span"
                        color="secondary"
                        to={"https://joblu.io/terms-of-use/"}
                        target="_blank"
                      >
                        {" "}
                        Terms of Use{" "}
                      </Link>{" "}
                      and{" "}
                      <Link
                        component="span"
                        color="secondary"
                        to={"https://joblu.io/privacy-policy/"}
                        target="_blank"
                      >
                        Privacy Policy{" "}
                      </Link>
                      .
                    </JobluTypography.Body>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={`${app_classes.display.display_flex}`}>
                    <Checkbox
                      onChange={() => {
                        setAuthDetails({
                          ...auth_details,
                          subscribeToEmail: !auth_details?.subscribeToEmail,
                        });
                      }}
                      checked={auth_details?.subscribeToEmail}
                    />
                    <JobluTypography.Body
                      semiBold
                      className={`${app_classes.text.left} `}
                      style={{
                        fontWeight: 400,
                        // marginTop: "auto",
                        // marginBottom: "auto",
                        fontSize: 16,
                      }}
                    >
                      Subscribe to our newsletter and receive the latest news
                      and trends delivered right to your inbox!
                    </JobluTypography.Body>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!auth_details?.agreeToTerms}
                    fullWidth
                    style={{ textTransform: "none", fontWeight: 700 }}
                    onClick={() => !isRegistering && handleSignup()}
                    endIcon={
                      isRegistering && (
                        <CircularProgress color="inherit" size={18} />
                      )
                    }
                  >
                    {googleResponse ? (
                      <span>
                        Create an Account <br />{" "}
                        <span style={{ fontSize: 12, fontWeight: 400 }}>
                          ( {googleResponse?.profileObj?.email} )
                        </span>{" "}
                      </span>
                    ) : (
                      "Create an Account"
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    style={{
                      border: "1px solid #002E5D",
                      borderRadius: 3,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <GoogleLogin
                      clientId={creds.GOOGLE_CLIENT_ID}
                      className="shadow-none"
                      buttonText={
                        <span style={{ color: "#002E5D", fontWeight: 700 }}>
                          {googleResponse
                            ? "Choose a different account"
                            : " Sign up with Google"}
                        </span>
                      }
                      onSuccess={(data) => {
                        setGoogleResponse(data);
                        console.log(data);
                        setAuthDetails({
                          ...auth_details,
                          firstName: data?.profileObj?.givenName,
                          lastName: data?.profileObj?.familyName,
                          email: data?.profileObj?.email?.trim(),
                        });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    style={{ textTransform: "none", fontWeight: 600 }}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setShowAuthModal("login");
                    }}
                  >
                    <ArrowBackIcon className={`${app_classes.margin.r1}`} />
                    Back to Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return { AppState: state.Generals, User: state.User };
};
const mapDispatchToProps = {
  signIn: userActions.signIn,
  signUp: userActions.signUp,
  verifyGoogleToken: userActions.verifyGoogleToken,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewJobPublic);
