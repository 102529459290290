import { userRequest } from '../service/requests'

export const makeUploadRequest = (file, fileType) => {
    const data = new FormData()
    data.append('document', file, fileType)
    try {
        return userRequest
            .uploadFile(data)
            .then((response) => {
                console.log(response.data)
                return response.data.documents
            })
            .catch((err) => {
                console.log(err)
            })
    } catch (e) {
        console.log('error when uploading document', e.response)
    }
}
export const makeUploadCompanyCultureFile = (file, fileType, type) => {
    //type param =  "photo" | "video" to be appended to api endpoint
    const data = new FormData()
    data.append('document', file, fileType)
    try {
        return userRequest
            .companyCultureUploadFile(data, type)
            .then((response) => {
                console.log(response.data)
                return response.data.documents
            })
            .catch((err) => {
                console.log(err)
            })
    } catch (e) {
        console.log('error when uploading document', e.response)
    }
}
export const makeUploadCv = (file, fileType) => {
    return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('document', file, fileType)
        try {
            return userRequest
                .generateCvProfile(data)
                .then((response) => {
                    console.log(response.data)
                    console.log('resolved!')
                    resolve(response.data.documents)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err)
                })
        } catch (e) {
            console.log('error when uploading document', e.response)
            reject(e?.response)
        }
    })
   
}
