import action from "./actions";

export const initState = {
  isSignedIn: false,
  isSigningIn: false,
  isRegistered: false,
  isRegistering: false,
  isFetching: false,
  isFetched: false,
  isUpdating: false,
  isUpdated: false,
  isResetting: false,
  isReset: false,
  user: {},
  token: null,
  refreshToken: null,
  error: null,
  applicant: {},
  isAccessTokenExpired: true,
  employerDashboard: {},
  companyOverview: {},
  isCreatingEmployer: false,
  login_response: {},
  chatRooms: [],
  isGoogleSignup: false,
  subscription: {},
  completionPercentage: {},
  isFetchingCompletion: false,
  isFetchedCompletion: false,
  isPromotionalVisible: false,
  isPromotionaBannerVisible: false,
};

const userReducer = (state = initState, act) => {
  switch (act.type) {
    case action.CREATE_EMPLOYER_SUCCESS:
      return {
        ...state,
        isCreatingEmployer: false,
        isGoogleSignup: false,
      };
    case action.SIGN_IN_REQUEST:
      console.log("USER REDUCER :: SIGN_IN_REQUEST");
      if (state.isEmailVerified === null)
        return {
          ...state,
          isSigningIn: true,
          isSignedIn: false,
          error: null,
        };
      else
        return {
          ...state,
          isSigningIn: true,
          isSignedIn: false,
          isEmailVerified: null,
          error: null,
        };
    case action.SIGN_IN_SUCCESS:
      console.log("USER REDUCER :: SIGN_IN_SUCCESS");
      return {
        ...state,
        isSignedIn: true,
        isSigningIn: false,
        token: act.payload.accessToken,
        refreshToken: act.payload.refreshToken,
        isAccessTokenExpired: false,
        role: act.payload.role,
        login_response: act.payload,
        isPromotionalVisible: true,
        isPromotionalBannerVisible: true,
        error: null,
      };
    case action.SIGN_IN_FAILURE:
      console.log("USER REDUCER :: SIGN_IN_FAILURE");
      return {
        ...state,
        isSigningIn: false,
        isSignedIn: false,
        isRegistered: false,
        isRegistering: false,
        error: act.payload,
      };
    case action.VERIFICATION_FAILURE:
      console.log("USER REDUCER :: VERIFICATION_FAILURE");
      return {
        ...state,
        isSigningIn: false,
        isSignedIn: false,
        isRegistered: false,
        isRegistering: false,
        isEmailVerified: false,
        error: act.payload,
      };
    case action.GET_USER_REQUEST:
      console.log("USER REDUCER :: GET_USER_REQUEST");
      return {
        ...state,
        isFetching: true,
      };
    case action.GET_USER_SUCCESS:
      console.log("USER REDUCER :: GET_USER_SUCCESS");
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        user: act.payload,
      };
    case action.GET_USER_FAILURE:
      console.log("USER REDUCER :: GET_USER_FAILURE");
      return {
        ...state,
        isFetching: false,
      };
    case action.SIGN_UP_REQUEST:
      console.log("USER REDUCER :: SIGN_UP_REQUEST");
      return {
        ...state,
        isRegistering: true,
        isRegistered: false,
      };
    case action.SIGN_UP_SUCCESS:
      console.log("USER REDUCER :: SIGN_UP_SUCCESS");
      return {
        ...state,
        isRegistering: false,
        isRegistered: true,
        user: act.payload,
      };
    case action.SIGN_UP_FAILURE:
      console.log("USER REDUCER :: SIGN_UP_FAILURE");
      return {
        ...state,
        isRegistering: false,
        isRegistered: false,
      };
    case action.UPDATE_USER_REQUEST:
      console.log("USER REDUCER :: UPDATE_USER_REQUEST");
      return {
        ...state,
        isUpdating: true,
      };
    case action.UPDATE_USER_SUCCESS:
      console.log("USER REDUCER :: UPDATE_USER_SUCCESS");
      return {
        ...state,
        isUpdating: false,
        user: act.payload,
      };
    case action.UPDATE_USER_FAILURE:
      console.log("USER REDUCER :: UPDATE_USER_FAILURE");
      return {
        ...state,
        isUpdating: false,
      };
    case action.CHANGE_PASSWORD_REQUEST:
      console.log("USER REDUCER :: CHANGE_PASSWORD_REQUEST");
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case action.CHANGE_PASSWORD_SUCCESS:
      console.log("USER REDUCER :: CHANGE_PASSWORD_SUCCESS");
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
      };
    case action.CHANGE_PASSWORD_FAILURE:
      console.log("USER REDUCER :: CHANGE_PASSWORD_FAILURE");
      return {
        ...state,
        isUpdating: false,
      };
    case action.RESET_PASSWORD_REQUEST:
      console.log("USER REDUCER :: RESET_PASSWORD_REQUEST");
      return {
        ...state,
        isResetting: true,
        isReset: false,
      };
    case action.RESET_PASSWORD_SUCCESS:
      console.log("USER REDUCER :: RESET_PASSWORD_SUCCESS");
      return {
        ...state,
        isResetting: false,
        isReset: true,
      };
    case action.RESET_PASSWORD_FAILURE:
      console.log("USER REDUCER :: RESET_PASSWORD_FAILURE");
      return {
        ...state,
        isResetting: false,
      };
    case action.GET_APPLICANT_DETAILS_REQUEST:
      console.log("USER REDUCER :: GET_APPLICANT_DETAILS_REQUEST");
      return {
        ...state,
        isFetching: true,
      };
    case action.GET_APPLICANT_DETAILS_SUCCESS:
      console.log("USER REDUCER :: GET_APPLICANT_DETAILS_SUCCESS");
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        applicant: act.payload,
      };
    case action.GET_APPLICANT_DETAILS_FAILURE:
      console.log("USER REDUCER :: GET_APPLICANT_DETAILS_FAILURE");
      return {
        ...state,
        isFetching: false,
      };
    case action.RENEW_ACCESS_TOKEN_REQUEST:
      console.log("USER REDUCER :: RENEW_ACCESS_TOKEN_REQUEST");
      return {
        ...state,
        isFetching: true,
      };
    case action.RENEW_ACCESS_TOKEN_SUCCESS:
      console.log("USER REDUCER :: RENEW_ACCESS_TOKEN_SUCCESS");
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isAccessTokenExpired: false,
        user: act.payload,
      };
    case action.RENEW_ACCESS_TOKEN_FAILURE:
      console.log("USER REDUCER :: RENEW_ACCESS_TOKEN_FAILURE");
      return {
        ...state,
        isFetching: false,
        isAccessTokenExpired: true,
      };

    case action.GET_EMPLOYER_DASHBOARD_REQUEST:
      console.log("USER REDUCER :: GET_EMPLOYER_DASHBOARD_REQUEST");
      return {
        ...state,
        isFetching: true,
      };
    case action.GET_EMPLOYER_DASHBOARD_FAILURE:
      console.log("USER REDUCER :: GET_EMPLOYER_DASHBOARD_FAILURE");
      return {
        ...state,
        isFetching: false,
      };
    case action.GET_EMPLOYER_DASHBOARD_SUCCESS:
      console.log("USER REDUCER :: GET_EMPLOYER_DASHBOARD_SUCCESS");
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        employerDashboard: act.payload,
      };

    case action.GET_APPLICANT_APPLIED_JOBS_REQUEST:
      console.log("USER REDUCER :: GET_APPLICANT_APPLIED_JOBS_REQUEST");
      return {
        ...state,
        isFetching: true,
      };
    case action.GET_APPLICANT_APPLIED_JOBS_FAILURE:
      console.log("USER REDUCER :: GET_APPLICANT_APPLIED_JOBS_FAILURE");
      return {
        ...state,
        isFetching: false,
      };
    case action.GET_APPLICANT_APPLIED_JOBS_SUCCESS:
      console.log("USER REDUCER :: GET_APPLICANT_APPLIED_JOBS_SUCCESS");
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        applicantAppliedJobs: act.payload,
      };

    case action.GET_APPLICANT_ACTIVE_JOBS_REQUEST:
      console.log("USER REDUCER :: GET_APPLICANT_ACTIVE_JOBS_REQUEST");
      return {
        ...state,
        isFetching: true,
      };
    case action.GET_APPLICANT_ACTIVE_JOBS_FAILURE:
      console.log("USER REDUCER :: GET_APPLICANT_ACTIVE_JOBS_FAILURE");
      return {
        ...state,
        isFetching: false,
      };
    case action.GET_APPLICANT_ACTIVE_JOBS_SUCCESS:
      console.log("USER REDUCER :: GET_APPLICANT_ACTIVE_JOBS_SUCCESS");
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        applicantActiveJobs: act.payload,
      };
    case action.GET_APPLICANT_SHORTLISTED_JOBS_REQUEST:
      console.log("USER REDUCER :: GET_APPLICANT_SHORTLISTED_JOBS_REQUEST");
      return {
        ...state,
        isFetching: true,
      };
    case action.GET_APPLICANT_SHORTLISTED_JOBS_FAILURE:
      console.log("USER REDUCER :: GET_APPLICANT_SHORTLISTED_JOBS_FAILURE");
      return {
        ...state,
        isFetching: false,
      };
    case action.GET_APPLICANT_SHORTLISTED_JOBS_SUCCESS:
      console.log("USER REDUCER :: GET_APPLICANT_SHORTLISTED_JOBS_FAILURE");
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        applicantShortlistedJobs: act.payload,
      };
    case action.GET_APPLICANT_REJECTED_JOBS_REQUEST:
      console.log("USER REDUCER :: GET_APPLICANT_REJECTED_JOBS_REQUEST");
      return {
        ...state,
        isFetching: true,
      };
    case action.GET_APPLICANT_REJECTED_JOBS_FAILURE:
      console.log("USER REDUCER :: GET_APPLICANT_REJECTED_JOBS_FAILURE");
      return {
        ...state,
        isFetching: false,
      };
    case action.GET_APPLICANT_REJECTED_JOBS_SUCCESS:
      console.log("USER REDUCER :: GET_APPLICANT_REJECTED_JOBS_SUCCESS");
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        applicantRejectedJobs: act.payload,
      };

    case action.GET_APPLICANT_SAVED_JOBS_REQUEST:
      console.log("USER REDUCER :: GET_APPLICANT_SAVED_JOBS_REQUEST");
      return {
        ...state,
        isFetching: true,
      };
    case action.GET_APPLICANT_SAVED_JOBS_FAILURE:
      console.log("USER REDUCER :: GET_APPLICANT_SAVED_JOBS_FAILURE");
      return {
        ...state,
        isFetching: false,
      };
    case action.GET_APPLICANT_SAVED_JOBS_SUCCESS:
      console.log("USER REDUCER :: GET_APPLICANT_SAVED_JOBS_SUCCESS");
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        applicantSavedJobs: act.payload,
      };
    case action.CHECK_PAYMENT_STATUS_PREFERRED_REQUEST:
      console.log("USER REDUCER :: CHECK_PAYMENT_STATUS_PREFERRED_REQUEST");
      return {
        ...state,
        isCheckingPreferred: true,
        isCheckedPreferred: false,
      };
    case action.CHECK_PAYMENT_STATUS_PREFERRED_SUCCESS:
      console.log("USER REDUCER :: CHECK_PAYMENT_STATUS_PREFERRED_SUCCESS");
      return {
        ...state,
        isCheckingPreferred: false,
        isCheckedPreferred: true,
        paymentStatusPreferred: act.payload,
      };
    case action.CHECK_PAYMENT_STATUS_PREFERRED_FAILURE:
      console.log("USER REDUCER :: CHECK_PAYMENT_STATUS_PREFERRED_FAILURE");
      return {
        ...state,
        isCheckingPreferred: false,
        isCheckedPreferred: false,
      };
    case action.CHECK_PAYMENT_STATUS_PRIORITY_REQUEST:
      console.log("USER REDUCER :: CHECK_PAYMENT_STATUS_PRIORITY_REQUEST");
      return {
        ...state,
        isCheckingPriority: true,
        isCheckedPriority: false,
      };
    case action.CHECK_PAYMENT_STATUS_PRIORITY_SUCCESS:
      console.log("USER REDUCER :: CHECK_PAYMENT_STATUS_PRIORITY_SUCCESS");
      return {
        ...state,
        isCheckingPriority: false,
        isCheckedPriority: true,
        paymentStatusPriority: act.payload,
      };
    case action.CHECK_PAYMENT_STATUS_PRIORITY_FAILURE:
      console.log("USER REDUCER :: CHECK_PAYMENT_STATUS_PRIORITY_FAILURE");
      return {
        ...state,
        isCheckingPriority: false,
        isCheckedPriority: false,
      };
    case action.UPDATE_APPLICANT_REQUEST:
      console.log("USER REDUCER :: UPDATE_APPLICANT_REQUEST");
      return {
        ...state,
        isUpdating: true,
      };
    case action.UPDATE_APPLICANT_SUCCESS:
      console.log("USER REDUCER :: UPDATE_APPLICANT_SUCCESS");
      return {
        ...state,
        isUpdating: false,
        user: act.payload,
      };
    case action.UPDATE_APPLICANT_FAILURE:
      console.log("USER REDUCER :: UPDATE_APPLICANT_FAILURE");
      return {
        ...state,
        isUpdating: false,
      };

    case action.GET_PAYMENTS_REQUEST:
      console.log("USER REDUCER :: GET_PAYMENTS_REQUEST");
      return {
        ...state,
        isFetching_Payments: true,
      };
    case action.GET_PAYMENTS_SUCCESS:
      console.log("USER REDUCER :: GET_PAYMENTS_SUCCESS");
      return {
        ...state,
        isFetching_Payments: false,
        isFetched_Payments: true,
        payments: act.payload,
      };
    case action.GET_PAYMENTS_FAILURE:
      console.log("USER REDUCER :: GET_PAYMENTS_FAILURE");
      return {
        ...state,
        isFetching_Payments: false,
      };
    case action.UPDATE_EMPLOYER_REQUEST:
      console.log("USER REDUCER :: UPDATE_EMPLOYER_REQUEST");
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case action.UPDATE_EMPLOYER_SUCCESS:
      console.log("USER REDUCER :: UPDATE_EMPLOYER_SUCCESS");
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        user: act.payload,
      };
    case action.UPDATE_EMPLOYER_FAILURE:
      console.log("USER REDUCER :: UPDATE_EMPLOYER_FAILURE");
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
      };
    case action.RESET_VERIFICATION:
      console.log("USER REDUCER :: RESET_VERIFICATION");
      return {
        ...state,
        isEmailVerified: null,
      };
    case action.CONFIRM_PHONE_CODE_REQUEST:
      console.log("USER REDUCER :: CONFIRM_PHONE_CODE_REQUEST");
      return {
        ...state,
        isSigningIn: true,
        isSignedIn: false,
        error: null,
      };
    case action.VERIFY_GOOGLE_TOKEN_REQUEST:
      console.log("USER REDUCER :: VERIFY_GOOGLE_TOKEN_REQUEST");
      let isCreatingEmployer = false;
      if (act.payload?.isCreatingEmployer) isCreatingEmployer = true;
      return {
        ...state,
        isSigningIn: true,
        isSignedIn: false,
        error: null,
        isCreatingEmployer,
      };
    case action.VERIFY_GOOGLE_TOKEN_SUCCESS:
      console.log("USER REDUCER :: VERIFY_GOOGLE_TOKEN_SUCCESS");
      return {
        ...state,
        isSignedIn: true,
        isSigningIn: false,
        token: act.payload.accessToken,
        refreshToken: act.payload.refreshToken,
        isAccessTokenExpired: false,
        role: act.payload.role,
        error: null,
      };
    case action.VERIFY_GOOGLE_TOKEN_FAILURE:
      console.log("USER REDUCER :: VERIFY_GOOGLE_TOKEN_FAILURE");
      return {
        ...state,
        isSigningIn: false,
        isSignedIn: false,
        isRegistered: false,
        isRegistering: false,
        error: act.payload,
      };
    case action.VERIFY_APPLE_TOKEN_REQUEST:
      console.log("USER REDUCER :: VERIFY_APPLE_TOKEN_REQUEST");
      return {
        ...state,
        isSigningIn: true,
        isSignedIn: false,
        error: null,
      };
    case action.VERIFY_APPLE_TOKEN_SUCCESS:
      console.log("USER REDUCER :: VERIFY_APPLE_TOKEN_SUCCESS");
      return {
        ...state,
        isSignedIn: true,
        isSigningIn: false,
        token: act.payload.accessToken,
        refreshToken: act.payload.refreshToken,
        isAccessTokenExpired: false,
        role: act.payload.role,
        error: null,
      };
    case action.VERIFY_APPLE_TOKEN_FAILURE:
      console.log("USER REDUCER :: VERIFY_APPLE_TOKEN_FAILURE");
      return {
        ...state,
        isSigningIn: false,
        isSignedIn: false,
        isRegistered: false,
        isRegistering: false,
        error: act.payload,
      };
    case action.SET_CHAT_ROOMS:
      console.log("USER REDUCER :: SET_CHAT_ROOMS");
      return {
        ...state,
        chatRooms:
          act.payload === undefined || act.payload === null ? [] : act.payload,
      };
    case action.GET_APPLICANT_COMPLETION_REQUEST:
      console.log("USER REDUCER :: GET_APPLICANT_COMPLETION_REQUEST");
      return {
        ...state,
        isFetchingCompletion: true,
        isFetchedCompletion: false,
      };

    case action.GET_APPLICANT_COMPLETION_SUCCESS:
      console.log("USER REDUCER :: GET_APPLICANT_COMPLETION_SUCCESS");
      return {
        ...state,
        completionPercentage: act.payload,
        isFetchingCompletion: false,
        isFetchedCompletion: true,
      };
    case action.GET_APPLICANT_COMPLETION_FAILURE:
      console.log("USER REDUCER :: GET_APPLICANT_COMPLETION_FAILURE");
      return {
        ...state,
        isFetchingCompletion: false,
        isFetchedCompletion: false,
      };
    case action.SOFT_RESET:
      console.log("USER_REDUCER :: SOFT_RESET hard");
      return {
        ...state,
        isSigningIn: false,
        isRegistering: false,
        isUpdating: false,
        isFetching: false,
        isFetching_Payments: false,
        isGoogleSignup: false,
        isCreatingEmployer: false,
        error: null,
        chatRooms: [],
      };
    case action.RESET_USER_ERROR:
      console.log("USER_REDUCER :: SOFT_RESET");
      return {
        ...state,
        error: null,
      };
    case action.SET_DO_NOT_SHOW_TIPS:
      return {
        ...state,
        login_response: {
          ...state.login_response,
          doNotShowTips: act.payload,
        },
      };
    case action.SET_TOKEN:
      console.log("USER REDUCER :: SET_TOKEN");
      return {
        ...state,
        isSignedIn: true,
        isSigningIn: false,
        token: act.payload.accessToken,
        refreshToken: act.payload.accessToken,
        isAccessTokenExpired: false,
        role: act.payload.role,
        login_response: act.payload,
        error: null,
      };
    case action.SET_IS_GOOGLE_SIGNUP:
      return {
        ...state,
        isGoogleSignup: act.payload,
      };

    case action.SET_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        subscription: act.payload,
      };

    case action.SET_PROMOTIONAL_MODAL:
      return {
        ...state,
        isPromotionalVisible: act.payload,
      };

      case action.SET_PROMOTIONAL_BANNER:
      return {
        ...state,
        isPromotionalBannerVisible: act.payload,
      };

    default:
      return state;
  }
};
export default userReducer;
