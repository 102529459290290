import { Box, IconButton } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { app_styles } from "../../general.styles";

const PaginationV1 = ({
  current_page,
  setCurrentPage,
  totalCount,
  ...props
}) => {
  const app_classes = app_styles(props);
  return (
    <Box>
      {totalCount > 0 && (
        <Box className={app_classes.display.display_flex}>
          <Box>
            <IconButton
              disabled={current_page === 1}
              onClick={() => {
                setCurrentPage(current_page - 1);
              }}
              className={`${app_classes.cursor.pointer}`}
            >
              <ArrowBack />
            </IconButton>
          </Box>
          <Box className={app_classes.general.centered_y}>
            {current_page} of {Math.ceil(totalCount / 20)}
          </Box>
          <Box>
            <IconButton
              disabled={current_page === Math.ceil(totalCount / 20)}
              onClick={() => {
                setCurrentPage(current_page + 1);
              }}
              className={`${app_classes.cursor.pointer}`}
            >
              <ArrowForward />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PaginationV1;
