import { Box, Button, Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { app_styles } from "../../general.styles";
import JobluTypography from "../Typography";

function SwipeLimitModal(props) {
  const { limitOpen, setLimitOpen, limitMainText, limitSubText } = props;
  const app_classes = app_styles(props);
  return (
    <>
      <Dialog
        open={limitOpen}
        fullWidth
        maxWidth="xs"
        onClose={() => {
          setLimitOpen(false);
        }}
      >
        <Box className={`${app_classes.text.right}`}>
          <CloseIcon
            onClick={() => {
              setLimitOpen(false);
            }}
            className={`${app_classes.margin.r1} ${app_classes.margin.t1} ${app_classes.cursor.pointer} `}
          />
        </Box>
        <Box className={`${app_classes.padding.x3} ${app_classes.padding.b3}`}>
          <Box
            className={`${app_classes.text.center} ${app_classes.margin.b2}`}
          >
            <JobluTypography.H5>{limitMainText}</JobluTypography.H5>
          </Box>
          <Box
            className={`${app_classes.text.center} ${app_classes.margin.b2}`}
          >
            <JobluTypography.Body>{limitSubText}</JobluTypography.Body>
          </Box>
          <Box className={`${app_classes.text.center}`}>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none", margin: 5 }}
              onClick={() => {
                setLimitOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default SwipeLimitModal;
