import { Box, Card, Chip, Grid } from "@material-ui/core";
import { EventNoteOutlined } from "@material-ui/icons";
import { useState } from "react";
import { connect } from "react-redux";
import educationIcon from "../../../../assets/icons/EducationIcon.png";
import workShiftIcon from "../../../../assets/icons/EmploymentTypeIcon.png";
import workHistoryIcon from "../../../../assets/icons/ExperienceIcon.png";
import locationIcon from "../../../../assets/icons/LocationIcon.png";
import salaryIcon from "../../../../assets/icons/SalaryIcon.png";
import workSetupIcon from "../../../../assets/icons/WorkSetupIcon.png";
import JobDetailsModal from "../../../../components/modals/JobDetailsModal";
import JobluTypography from "../../../../components/Typography";
import { app_styles } from "../../../../general.styles";
import {
  getJobCompanyName,
  getJobCompanyProfileUrl,
  thousandSeparator,
} from "../../../../utils/helpers";
import JobseekerSwipeActions from "./JobseekerSwipeActions";

const SingleCard = ({ job, currency, card_index, onSwipe, ...props }) => {
  const app_classes = app_styles(props);
  const [view_job, setViewJob] = useState(null);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="flex-start"
      alignItems="flex-start"
      style={{ flexWrap: "nowrap", height: "100%" }}
      id={`card-class-${card_index}`}
    >
      <Grid xs={12} container spacing={1}>
        <Card
          elevation={0}
          style={{
            border: "1px solid #C9CCCE",
            borderRadius: "10px",
            height: "725px",
            position: "relative",
          }}
        >
          <Grid container style={{ padding: 50 }}>
            <Grid item xs={6} container alignContent="flex-start">
              <Grid item md={12} style={{ display: "flex" }}>
                <img
                  alt=""
                  src={getJobCompanyProfileUrl(job)}
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    marginRight: 20,
                  }}
                />
                <Box>
                  <JobluTypography.H5
                    primary
                    style={{ fontWeign: "bolder", lineHeight: "24px" }}
                  >
                    {job?.jobTitle}
                  </JobluTypography.H5>
                  <JobluTypography.Body semibold style={{ fontSize: 17 }}>
                    {getJobCompanyName(job)}
                  </JobluTypography.Body>
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box
                  style={{ marginTop: 20 }}
                  className={app_classes.display.display_flex}
                >
                  {/* location */}
                  <img
                    src={locationIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", marginRight: 5 }}
                    className={app_classes.general.color_secondary}
                  />
                  <JobluTypography.Body>{job?.location}</JobluTypography.Body>
                </Box>
                {/* work setup */}
                <Box className={app_classes.display.display_flex}>
                  <img
                    src={workSetupIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", marginRight: 5 }}
                    className={app_classes.general.color_secondary}
                  />
                  <JobluTypography.Body>
                    {job?.workSetup?.join(", ") || "N/A"}
                  </JobluTypography.Body>
                </Box>
                {/* work shift  */}
                <Box className={app_classes.display.display_flex}>
                  <img
                    src={workShiftIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", marginRight: 5 }}
                    className={app_classes.general.color_secondary}
                  />
                  <JobluTypography.Body>
                    {job?.employmentType?.join(", ") || "N/A"}
                  </JobluTypography.Body>
                </Box>
                {/* education */}
                <Box className={app_classes.display.display_flex}>
                  <img
                    src={educationIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", marginRight: 5 }}
                    className={app_classes.general.color_secondary}
                  />
                  <JobluTypography.Body>
                    {job?.educationAndExperience || "N/A"}
                  </JobluTypography.Body>
                </Box>
                {/* work history  */}
                <Box className={app_classes.display.display_flex}>
                  <img
                    src={workHistoryIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", marginRight: 5 }}
                    className={app_classes.general.color_secondary}
                  />
                  <JobluTypography.Body>
                    {job?.totalExperienceMin &&
                      job?.totalExperienceMin !== 0 &&
                      `${job?.totalExperienceMin} - `}
                    {job?.totalExperienceMax} Years
                  </JobluTypography.Body>
                </Box>
                {/* salary */}
                <Box className={app_classes.display.display_flex}>
                  <img
                    src={salaryIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", marginRight: 5 }}
                    className={app_classes.general.color_secondary}
                  />
                  <JobluTypography.Body>
                    {currency}{" "}
                    {job?.salaryMin &&
                      job?.salaryMin !== 0 &&
                      `${thousandSeparator(job?.salaryMin)} - `}
                    {thousandSeparator(job?.salaryMax)}
                  </JobluTypography.Body>
                </Box>
              </Grid>
              {job?.perksAndBenefits?.length > 0 && (
                <Grid xs={12}>
                  <JobluTypography.H6
                    semiBold
                    primary
                    style={{ marginTop: 20 }}
                  >
                    Perks and Benefits
                  </JobluTypography.H6>
                  <Box>
                    {job?.perksAndBenefits?.map((perks) => {
                      return (
                        <Chip
                          label={perks}
                          color="secondary"
                          style={{ margin: 1 }}
                        />
                      );
                    })}
                  </Box>
                </Grid>
              )}
              {job?.technicalSkills?.length > 0 && (
                <Grid xs={12}>
                  <JobluTypography.H6
                    semiBold
                    primary
                    style={{ marginTop: 20 }}
                  >
                    Technical Skills
                  </JobluTypography.H6>
                  <Box>
                    {job?.technicalSkills?.map((perks) => {
                      return (
                        <Chip
                          label={perks}
                          color="secondary"
                          style={{ margin: 1 }}
                        />
                      );
                    })}
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                className={`${app_classes.display.display_flex} ${app_classes.margin.b1}`}
              >
                <EventNoteOutlined
                  color="warning"
                  className={app_classes.margin.r2}
                />
                <JobluTypography.H6 semiBold primary>
                  Responsibilities
                </JobluTypography.H6>
              </Box>
              <JobluTypography.Body style={{ whiteSpace: "pre-wrap" }}>
                {job?.responsibilities}
              </JobluTypography.Body>
            </Grid>
          </Grid>

          <Box
            style={{
              width: "100%",
              position: "absolute",

              bottom: 0,
            }}
          >
            <Box
              style={{
                padding: "20px",
                width: "100%",
                textAlign: "center",

                background:
                  "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 54%, rgba(255,255,255,0.25253851540616246) 100%)",
              }}
            >
              <JobluTypography.Body semiBold>
                <span
                  onClick={() => {
                    setViewJob(job);
                  }}
                  role="button"
                  style={{ fontSize: 14 }}
                >
                  See more
                </span>
              </JobluTypography.Body>
            </Box>
            <Box
              style={{
                backgroundColor: "#fff",
                borderTop: "1px solid #C9CCCE",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <JobseekerSwipeActions
                job={job}
                onSwipe={onSwipe}
                card_index={card_index}
              />
            </Box>
          </Box>
        </Card>
      </Grid>
      <JobDetailsModal
        job={view_job}
        open={Boolean(view_job)}
        setOpen={setViewJob}
        isFromExplore
      />
    </Grid>
  );
};

const mapDispatchToProps = (state) => {
  return { currency: state.Generals.currency };
};

export default connect(mapDispatchToProps)(SingleCard);
