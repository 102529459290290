import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import _, { debounce } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { NumericFormat } from "react-number-format";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import ErrorImg from "../../assets/img/createProfile/ErrorImg.png";
import LuStar from "../../assets/img/createProfile/LuStar.jpg";
import ModalBg from "../../assets/img/createProfile/ModalBg.png";
import PlusIcon from "../../assets/img/createProfile/PlusIcon.png";
import UploadIcon from "../../assets/img/createProfile/UploadIcon.png";
import Lu from "../../assets/img/createProfile/lu.png";
import DefaultImg from "../../assets/img/defaultImg.png";
import DeleteIconRed from "../../assets/img/editProfile/deleteIconRed.png";
import EditIcon from "../../assets/img/editProfile/editIcon.png";
import JobluLogoH from "../../assets/img/joblu-h.png";
import JobluTypography from "../../components/Typography";
import CustomTextFieldV1, {
  MUICustomTextField as CustomTextField,
} from "../../components/inputs/CustomTextField";
import ProfilePreview from "../../components/profile-preview";
import SelectJobFunction from "../../components/reusables/SelectJobFunction";
import CustomGooglePlaces from "../../components/select/google-places";
import { app_styles } from "../../general.styles";
import userActions from "../../redux/user/actions";
import { configRequest, userRequest } from "../../service/requests";
import useWindowDimensions from "../../utils/hooks";
import {
  formatDateRange2,
  getYearDifference,
  getYearsOfExpCategory,
  isNullOrEmptySpaces,
} from "../../utils/helpers";
import { skills_masterlist } from "../../utils/skills";
import { makeUploadCv } from "../../utils/uploadFiles";
import { create_profile_styles } from "./styles";

const BUILD_TYPE = {
  linkedin: "LinkedIn profile",
  resume: "Resume",
};

const PageControl = (props) => {
  const { width } = useWindowDimensions();
  const {
    step,
    prevStep,
    handleSaveProfile,
    setDone,
    nextStep,
    app_classes,
    noSkip,
    noPrev,
  } = props;

  return (
    <>
      <Box className={`${app_classes.margin.b3} ${app_classes.margin.t3}`}>
        <hr style={{ color: "gray" }} />
      </Box>
      <Box
        className={`${app_classes.margin.b3} ${
          app_classes.display.display_flex
        } ${
          width < 640
            ? app_classes.flexBox.justify_content_center
            : app_classes.flexBox.justify_content_between
        }`}
      >
        <Box className={`${width < 640 && app_classes.display.display_none}`}>
          {noSkip === true ? null : (
            <Button
              variant="outlined"
              color="secondary"
              style={{ textTransform: "none" }}
              onClick={() => {
                nextStep("skip");
              }}
            >
              Skip
            </Button>
          )}
        </Box>
        <Box className={`${width < 640 && app_classes.display.display_flex} ${width < 640 && app_classes.flexBox.flex_direction_column}`}>
          {width < 640 && (
            <>
              {noSkip === true ? null : (
                <Button
                  className={`${app_classes.margin.r2} ${width< 640 && app_classes.margin.b2}`}
                  variant="outlined"
                  color="secondary"
                  style={{ textTransform: "none" }}
                  onClick={() => {
                    nextStep("skip");
                  }}
                  fullWidth={width < 640 ?true: false}
                >
                  Skip
                </Button>
              )}
            </>
          )}
          {noPrev === true
            ? null
            : step > 0 && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={`${app_classes.margin.r2} ${width< 640 && app_classes.margin.b2}`}
                    startIcon={<ArrowBackIcon />}
                    style={{ textTransform: "none" }}
                    onClick={prevStep}
                    fullWidth={width < 640 ?true: false}
                  >
                    Previous
                  </Button>
                </>
              )}

          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
            style={{ textTransform: "none" }}
            onClick={() => {
              if (step == 7) {
                nextStep(0);
                handleSaveProfile();
              } else {
                nextStep();
              }
            }}
            fullWidth={width < 640 ?true: false}
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};
const CreateProfile = (props) => {
  const navigate = useNavigate();
  const { User, getApplicantCompletion, getUser, currency } = props;
  const { width } = useWindowDimensions();
  const {
    user,
    completionPercentage,
    isFetchedCompletion,
    isFetchingCompletion,
    isFetching,
    isFetched,
  } = User;

  const app_classes = app_styles(props);
  const create_profile_classes = create_profile_styles(props);

  const [type, setType] = useState(null);
  const [open, setOpen] = useState(false);

  const [buildType, setBuildType] = useState(null);

  const [linkedinLink, setLinkedinLink] = useState(null);

  // ERRORS
  const [errors, setErrors] = useState({
    linkedin: null,
    cv: null,
  });

  const [cvFile, setCVFile] = useState();
  const [cvFileInfo, setCVFileInfo] = useState(null);

  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState("resume");
  const [done, setDone] = useState(false);
  const [showSteps, setShowSteps] = useState(false);

  const [showSelectModal, setShowSelectModal] = useState(true);
  const [showUploadCV, setShowUploadCV] = useState(false);
  const [showLinkedInModal, setShowLinkedInModal] = useState(false);

  const [isParseSuccess, setIsParseSuccess] = useState(false);

  const [profileLevel, setProfileLevel] = useState([
    { title: "Personal Information", type: "personalInfo", active: false },
    { title: "Profile Picture", type: "profilePicture", active: false },
    { title: "Resume", type: "resume", active: false },
    { title: "Portfolio", type: "portfolio", active: false },
    { title: "Preferences", type: "preferences", active: false },
    { title: "Experiences", type: "experiences", active: false },
    { title: "Education", type: "education", active: false },
    { title: "Jobreel", type: "jobreel", active: false },
    { title: "Skills", type: "skills", active: false },
  ]);

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("overflow-auto");
  });

  const employmentStatusList = [
    {
      value: "Actively Looking",
      label: "Actively Looking",
    },
    {
      value: "Employed",
      label: "Employed",
    },
    {
      value: "Exploring My Options",
      label: "Exploring My Options",
    },
  ];

  const employmentTypeList = [
    {
      value: "Full-Time",
      label: "Full-Time",
    },
    {
      value: "Part-Time",
      label: "Part-Time",
    },
    {
      value: "Freelance",
      label: "Freelance",
    },
    {
      value: "Project-Based",
      label: "Project-Based",
    },
    {
      value: "Internship",
      label: "Internship",
    },
    {
      value: "High School Internship",
      label: "High School Internship",
    },
  ];

  const workSetupList = [
    {
      value: "On-Site",
      label: "On-Site",
    },
    {
      value: "Hybrid",
      label: "Hybrid",
    },
    {
      value: "Remote",
      label: "Remote",
    },
  ];

  const education_list = [
    {
      label: "No Formal Education",
      value: "No Formal Education",
    },
    { label: "Primary", value: "Primary" },
    { label: "Secondary", value: "Secondary" },
    {
      label: "Tertiary/Vocational Course/ITE/ITI",
      value: "Tertiary/Vocational Course/ITE/ITI",
    },
    { label: "Diploma", value: "Diploma" },
    { label: "Under Graduate", value: "Under Graduate" },
    { label: "Bachelor's Degree", value: "Bachelor's Degree" },
    {
      label: "Master's Degree Holder/Other Higher Degree",
      value: "Master's Degree Holder/Other Higher Degree",
    },
  ];

  const currencies = [
    {
      value: "PHP",
      label: "PHP",
    },
    {
      value: "SGD",
      label: "SGD",
    },
    {
      value: "USD",
      label: "USD",
    },
  ];

  const valuesList = [
    {
      value: "Mentoring",
      label: "Mentoring",
    },
    {
      value: "Accountability",
      label: "Accountability",
    },
    {
      value: "Making An Impact",
      label: "Making An Impact",
    },
    {
      value: "Work From Anywhere",
      label: "Work From Anywhere",
    },
    {
      value: "Financial Stability",
      label: "Financial Stability",
    },
    {
      value: "Creative Collaboration",
      label: "Creative Collaboration",
    },
    {
      value: "Inclusivity & Diversity",
      label: "Inclusivity & Diversity",
    },
    {
      value: "Exciting Challenges",
      label: "Exciting Challenges",
    },
    {
      value: "Work Hard Play Hard",
      label: "Work Hard Play Hard",
    },
  ];
  const pronouns = [
    {
      value: "He/Him",
      label: "He/Him",
    },
    {
      value: "She/Her",
      label: "She/Her",
    },
    {
      value: "They/Them",
      label: "They/Them",
    },
    {
      value: "I'd rather not say",
      label: "I'd rather not say",
    },
  ];
  const softSkillsList = [
    { label: "Analytical", value: "Analytical" },
    { label: "Flexible", value: "Flexible" },
    { label: "Resourceful", value: "Resourceful" },
    { label: "Hustler", value: "Hustler" },
    { label: "Creative Genius", value: "Creative Genius" },
    { label: "Problem Solver", value: "Problem Solver" },
    { label: "Team Player", value: "Team Player" },
    { label: "Sole Warrior", value: "Sole Warrior" },
    { label: "Jack Of All Trades", value: "Jack Of All Trades" },
    { label: "Self-Starter", value: "Self-Starter" },
    { label: "People Person", value: "People Person" },
    { label: "Detail Oriented", value: "Detail Oriented" },
    { label: "Process Driven", value: "Process Driven" },
    { label: "Persistent", value: "Persistent" },
    { label: "Service Oriented", value: "Service Oriented" },
    { label: "Leader", value: "Leader" },
  ];

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (isFetchedCompletion && isParseSuccess && step < 2) {
      if (completionPercentage?.profileBuild) {
        const { profileBuild } = completionPercentage;
        console.log(profileBuild);
        const newProfileLevel = profileLevel?.map((item) => {
          console.log(item);
          item.active = profileBuild[item.type];
          return item;
        });
        setProfileLevel(newProfileLevel);
        if (showUploadCV) {
          console.log();
          console.log(user?.portfolioAndFiles[0]?.title === "My CV");
          console.log(profileBuild?.personalInfo);
          console.log(profileBuild?.experiences);
          console.log(profileBuild?.education);
          console.log(profileBuild?.resume);
          console.log(profileBuild?.skills);
          if (
            profileBuild?.resume &&
            user?.portfolioAndFiles[0]?.title === "My CV" &&
            profileBuild?.education
          ) {
            //show profile preview
            setStep(0);
            // setDone(true)
          } else {
            if (step > 0) {
              setStep(1);
            }
            else{
              setStep(1)
            }
          }
        } else if (showLinkedInModal) {
          if (
            profileBuild?.personalInfo &&
            profileBuild?.experiences &&
            profileBuild?.education &&
            user?.bio
          ) {
            //show profile preview
            setStep(0);
            // setDone(true)
          } else {
            if (step > 0) {
              setStep(1);
            }
            else{
              setStep(1)
            }
          }
        }
      }
    }
  }, [isFetchingCompletion]);

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setDone(true);
      setOpen(false);
    }, 2000);
  };

  const location = useLocation();
  console.log("create location", location);
  const continueToEdit = () => {
    navigate("/edit-profile", {
      state: { fromCreate: true, ...location?.state },
    });
  };

  const handleClose = () => {
    if (done) setOpen(false);
    else setOpen(true);
  };

  const clearErrors = () =>
    setErrors({
      linkedin: null,
      cv: null,
    });

  const [parseType, setParseType] = useState(null);
  const handleLinkedinParsing = () => {
    if (isNullOrEmptySpaces(linkedinLink) || !linkedinLink) {
      setErrors({ ...errors, linkedin: "Linkedin profile link is required." });
      return false;
    }

    if (!linkedinLink?.toLowerCase()?.includes("linkedin")) {
      setErrors({ ...errors, linkedin: "Linkedin Profile link is not valid." });
      return false;
    }

    clearErrors();

    // ADD LOADER

    setOpen(true);
    userRequest
      .generateLinkedinProfile({
        url: linkedinLink,
      })
      .then((response) => {
        console.log(response.data);
        setOpen(false);
        // setDone(true);
        getUser();
        setIsParseSuccess(true);
        // navigate('/edit-profile')
      })
      .catch((er) => {
        //add show error page
        setIsParseSuccess(false);
        setOpen(false);
        setErrorType("linkedin");
        setBuildType(BUILD_TYPE["linkedin"]);
        setError(true);
        console.log(er?.response);
      });
  };

  const handleCvParsing = () => {
    clearErrors();
    setParseType("cv");
    // ADD LOADER
    setOpen(true);
    makeUploadCv(cvFileInfo, "cvdocument")
      .then((response) => {
        // setDone(true);
        setOpen(false);
        getUser();
        setIsParseSuccess(true);
      })
      .catch((er) => {
        //add show error page
        setIsParseSuccess(false);
        setOpen(false);
        setBuildType(BUILD_TYPE["resume"]);
        setErrorType("resume");
        setError(true);
        setCVFileInfo(null);
      });
  };

  useEffect(() => {
    if (isFetched) {
      console.log("USER::::", user);
      getApplicantCompletion();
    }
  }, [isFetching]);

  const handleUploadFile = (file) => {
    setCVFileInfo(file);
    console.log(file);
  };

  const [empStatusError, setEmpStatusError] = useState(false);
  const [empTypeError, setEmpTypeError] = useState(false);
  const [workSetupError, setWorkSetupError] = useState(false);
  const [currencyError, setCurrencyError] = useState(false);
  const [salaryMinError, setSalaryMinError] = useState(false);
  const [salaryMinError2, setSalaryMinError2] = useState(false);
  const [salaryMaxError, setSalaryMaxError] = useState(false);
  const [salaryRangeError, setSalaryRangeError] = useState(false);
  const [jfError, setJfError] = useState(false);
  const [industryError, setIndustryError] = useState(false);
  const [valuesError, setValuesError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [skillsError, setSkillsError] = useState(false);
  const [softSkillsError, setSoftSkillsError] = useState(false);
  const validateStep3 = () => {
    let valid = [];
    if (!current_user?.salaryMin && current_user?.salaryMin != 0) {
      console.log("salaryMin is required");
      setSalaryMinError(true);
      valid.push(false);
    } else {
      if (current_user?.salaryMin < 1) {
        setSalaryMinError2(true);
        valid.push(false);
      } else {
        setSalaryMinError2(false);
        valid.push(true);
      }
      setSalaryMinError(false);
      valid.push(true);
    }

    if (!current_user?.salaryMax) {
      setSalaryMaxError(true);
      valid.push(false);
    } else {
      setSalaryMaxError(false);
      valid.push(true);
    }

    if (current_user?.salaryMax < current_user?.salaryMin) {
      setSalaryRangeError(true);
      valid.push(false);
    } else {
      setSalaryRangeError(false);
      valid.push(true);
    }

    if (!current_user?.employmentStatus) {
      setEmpStatusError(true);
      valid.push(false);
    } else {
      setEmpStatusError(false);
      valid.push(true);
    }
    if (current_user?.employmentType?.length < 1) {
      setEmpTypeError(true);
      valid.push(false);
    } else {
      setEmpTypeError(false);
      valid.push(true);
    }
    if (current_user?.workSetup?.length < 1) {
      setWorkSetupError(true);
      valid.push(false);
    } else {
      setWorkSetupError(false);
      valid.push(true);
    }
    if (current_user?.workSetup?.length < 1) {
      setValuesError(true);
      valid.push(false);
    } else {
      setValuesError(false);
      valid.push(true);
    }
    if (!current_user?.jobFunction) {
      setJfError(true);
      valid.push(false);
    } else {
      setJfError(false);
      valid.push(true);
    }
    if (!current_user?.preferredTitle) {
      setTitleError(true);
      valid.push(false);
    } else {
      setTitleError(false);
      valid.push(true);
    }

    return valid.includes(false) ? false : true;
  };

  const validateStep5 = () => {
    let valid = [];

    if (current_user?.skills?.length < 1) {
      setSkillsError(true);
      valid.push(false);
    } else {
      setSkillsError(false);
      valid.push(true);
    }

    if (current_user?.softSkills?.length < 1) {
      setSoftSkillsError(true);
      valid.push(false);
    } else {
      setSoftSkillsError(false);
      valid.push(true);
    }

    return valid.includes(false) ? false : true;
  };

  const handleSaveProfile = () => {
    userRequest
      .updateApplicant({
        ...current_user,
        salaryCurrency: current_user?.salaryCurrency || currency,
      })
      .then(() => {
        getUser();
        // if (_location?.state?.fromCreate) {
        //   setOpenPreview(true);
        // }
      })
      .finally(() => {
        // setShowAlert({ message: "Profile updated", type: "success" });
        // setIsSubmitting(false);
      });
  };

  const [step, setStep] = useState(null);

  const nextStep = (val) => {
    if (val == "skip") {
      // NO VALIDATION
      if (step < 8) {
        setStep(val);
      }
    } else {
      if (step == 3) {
        const is_valid_3 = validateStep3();
        if (is_valid_3) {
          setStep(val);
          handleSaveProfile();
        }
      } else if (step == 5) {
        const is_valid_5 = validateStep5();
        if (is_valid_5) {
          setStep(val);
          handleSaveProfile();
        }
      } else {
        setStep(val);
        handleSaveProfile();
      }
    }
  };
  const prevStep = () => {
    if (step >= 1) {
      setStep(step - 1);
    }
  };

  const addMoreDetails = () => {
    if (isParseSuccess) {
      setStep(1);
    } else {
      setStep(2);
    }
  };

  const [completed, setCompleted] = useState(false);
  const [buildingProfile, setBuildingProfile] = useState(false);

  const BuildProfile = () => {
    setBuildingProfile(true);
    setTimeout(() => {
      setParseType("cv");
      setBuildingProfile(false);
      setShowUploadCV(false);
      setShowLinkedInModal(false);
      setStep(0);
      // setErrorType('linkedin')
      // setError(true)
    }, 3000);
  };

  const [current_user, setCurrentUser] = useState(user || null);
  const [tempExp, setTempExp] = useState({
    companyName: null,
    jobTitle: null,
    location: null,
    responsibilities: null,
    achievements: "",
    startDate: null,
    endDate: null,
    expWorkingHere: false,
  });

  const [showExpModal, setExpModal] = useState(false);
  const [modalErrors, setModalErrors] = useState({});
  const handleOpenExpModal = (type) => setExpModal(type);
  const handleCloseExpModal = () => {
    const clearErrors = {
      companyName: null,
      jobTitle: null,
      location: null,
      responsibilities: null,
      startDate: null,
      endDate: null,
    };
    setModalErrors({ ...modalErrors, ...clearErrors });
    setExpModal(false);
    setTempExp({
      companyName: null,
      jobTitle: null,
      location: null,
      responsibilities: null,
      achievements: "",
      startDate: null,
      endDate: null,
      expWorkingHere: null,
    });
  };

  const [tempEducation, setTempEducation] = useState({
    educAchievements: null,
    educCurrentStudy: null,
    educEndDate: null,
    educField: null,
    educName: null,
    educStartDate: null,
    educationType: null,
  });

  const [showEducationModal, setEducationModal] = useState(false);
  const handleOpenEducation = (type) => setEducationModal(type);
  const handleCloseEducation = () => {
    const clearErrors = {
      ducAchievements: null,
      educCurrentStudy: null,
      educEndDate: null,
      educField: null,
      educName: null,
      educStartDate: null,
      educationType: null,
    };
    setModalErrors({ ...modalErrors, ...clearErrors });
    setEducationModal(false);
    setTempEducation({
      ducAchievements: null,
      educCurrentStudy: null,
      educEndDate: null,
      educField: null,
      educName: null,
      educStartDate: null,
      educationType: null,
    });
  };

  const [employmentStatus, setEmploymentStatus] = useState(null);
  const [employmentType, setEmploymentType] = useState([]);
  const [workSetup, setWorkSetup] = useState([]);
  const [values, setValues] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [isSearcingSkills, setIsSearchingSkill] = useState(false);
  const [applicantCompletion, setApplicantCompletion] = useState(0);
  const [openPreview, setOpenPreview] = useState(false);

  const handleEmploymentStatus = (value) => {
    setEmploymentStatus(value);
  };
  const [skill_list, setSkill_List] = useState([
    ...skills_masterlist.sort((a, b) => (a.value < b.value ? -1 : 1)),
  ]);
  const [skillsCopy2, setSkillsCopy2] = useState(
    current_user?.skills?.map((item) => {
      return {
        label: item,
        value: item,
      };
    })
  );

  const handleEmploymentType = (value) => {
    var employmentTypeCopy = [...employmentType];
    if (!employmentTypeCopy.includes(value)) {
      if (employmentType.length < 2) {
        employmentTypeCopy = [...employmentType, value];
      }
    } else {
      employmentTypeCopy.splice(employmentType?.indexOf(value), 1);
    }
    setEmploymentType(employmentTypeCopy);
  };

  const handleWorkSetup = (value) => {
    var workSetupCopy = [...workSetup];
    if (!workSetupCopy.includes(value)) {
      if (workSetup.length < 2) {
        workSetupCopy = [...workSetup, value];
      }
    } else {
      workSetupCopy.splice(workSetup?.indexOf(value), 1);
    }
    setWorkSetup(workSetupCopy);
  };

  const handleValues = (value) => {
    var valuesCopy = [...values];
    if (!valuesCopy.includes(value)) {
      if (values.length < 3) {
        valuesCopy = [...values, value];
      }
    } else {
      valuesCopy.splice(values?.indexOf(value), 1);
    }
    setValues(valuesCopy);
  };

  const handleSetSoftSkills = (value) => {
    var softSkillsCopy = [...softSkills];
    if (!softSkillsCopy.includes(value)) {
      if (softSkills.length < 10) {
        softSkillsCopy = [...softSkills, value];
      }
    } else {
      softSkillsCopy.splice(softSkills?.indexOf(value), 1);
    }
    setSoftSkills(softSkillsCopy);
  };

  const [showPortfolioModal, setPortfolioModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const descriptionElementRef = useRef(null);

  const handleOpenPortfolioModal = (type) => setPortfolioModal(type);
  const handleClosePortfolioModal = () => {
    setPortfolioModal(false);
    setTempLink({
      date: moment().format("MM/DD/YYYY"),
      link: null,
      title: null,
    });
  };

  const handleOpenFileModal = (type) => setShowFileModal(type);

  const handleCloseFileModal = () => {
    setShowFileModal(false);
    setTempLink({
      date: moment().format("MM/DD/YYYY"),
      link: null,
      title: null,
      file: null,
    });
    uploadPortfolioRef.current.value = "";
  };

  useEffect(() => {
    console.log("user => ", props?.User?.user);
    if (props?.User?.user?.softSkills) {
      // remove skills that are outdated from list
      let softSkills = [];
      softSkillsList.forEach((s_skills) => {
        if (props?.User?.user?.softSkills?.includes(s_skills.value)) {
          softSkills.push(s_skills.value);
        }
      });

      setCurrentUser({
        ...current_user,
        softSkills,
      });
    }
  }, []);

  const [tempLink, setTempLink] = useState({
    date: moment().format("MM/DD/YYYY"),
    link: null,
    title: null,
  });

  const [tempFiles, setTempFiles] = useState({
    date: moment().format("MM/DD/YYYY"),
    link: null,
    title: null,
    file: null,
  });

  const uploadPortfolioRef = useRef();
  const uploadProfilePicRef = useRef();
  const [tempProfilePic, setTempProfilePic] = useState(null);

  const getYearsOfExp = (item) => {
    if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
      const sorted =
        item?.experienceHistory &&
        item?.experienceHistory?.sort(function (a, b) {
          return new Date(a.startDate) - new Date(b.startDate);
        });
      const startDate = new Date(sorted[0]?.startDate);
      const diff = getYearDifference(startDate);
      return getYearsOfExpCategory(diff);
    } else {
      return null;
    }
  };

  const handleChange = (selectedOption) => {
    let skillsCopy = [];
    selectedOption.forEach((item) => {
      skillsCopy.push(item.value);
    });
    setCurrentUser({ ...current_user, skills: skillsCopy });
  };

  const loadOptions = debounce((inputValue, callback) => {
    if (!!inputValue) {
      configRequest
        .searchSkills({ search: inputValue })
        .then((res) => {
          //console.log(res.data);
          const selected = current_user?.skills?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          const searched = res?.data?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          let combined;
          if (searched?.length === 0) {
            combined = _.uniqBy(
              [...selected, { label: inputValue, value: inputValue }],
              function (e) {
                return e.value;
              }
            );
          } else {
            combined = _.uniqBy([...selected, ...searched], function (e) {
              return e.value;
            });
          }
          callback(combined.sort((a, b) => (a.value < b.value ? -1 : 1)));
          setIsSearchingSkill(false);
        })
        .catch((err) => {
          console.log(err);
          setIsSearchingSkill(false);
        });
    } else {
      const selected = current_user?.skills?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      const combined = _.uniqBy([...selected, ...skill_list], function (e) {
        return e.value;
      });
      if (current_user?.skills?.length > 0) {
        callback(combined.sort((a, b) => (a.value < b.value ? -1 : 1)));
      } else {
        callback(skill_list);
      }
      setIsSearchingSkill(false);
    }
  }, 1000);

  useEffect(() => {
    console.log(current_user);
  }, [current_user]);
  return (
    <>
      <input
        accept="image/*"
        hidden
        ref={uploadProfilePicRef}
        onChange={(e) => {
          //setTempProfilePic(e.target.files[0]);
          let payload = new FormData();
          payload.append("document", e.target.files[0]);
          userRequest.uploadFile(payload).then((res) => {
            console.log("res profile pic", res.data);
            setCurrentUser({
              ...current_user,
              profileUrl: res.data?.documents,
            });
          });
        }}
        type="file"
      />

      {/* EDUCATION MODAL */}
      <Dialog
        open={!!showEducationModal}
        onClose={handleCloseEducation}
        scroll={`body`}
        maxWidth="sm"
        fullWidth
      >
        <Box
          className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          style={{ minWidth: 400 }}
        >
          <JobluTypography.H6 align="center">
            {showEducationModal} Education
          </JobluTypography.H6>
        </Box>
        <Box className={`${app_classes.padding.a3}`}>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              School or Name of Institute *
            </JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              placeholder='Ex. "University of the Philippines"'
              fullWidth
              onChange={(e) => {
                setTempEducation({
                  ...tempEducation,
                  educName: e.target.value,
                });
              }}
              value={tempEducation?.educName}
              error={!!modalErrors?.educName}
              helperText={modalErrors?.educName}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Degree *</JobluTypography.Body>

            <FormControl fullWidth size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tempEducation?.educationType}
                onChange={(e) => {
                  setTempEducation({
                    ...tempEducation,
                    educationType: e.target.value,
                  });
                }}
                error={!!modalErrors?.educationType}
                helperText={modalErrors?.educationType}
                variant="outlined"
              >
                {education_list?.map((item) => {
                  return <MenuItem value={item?.value}>{item?.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
            {!!modalErrors?.educationType && (
              <span
                className="mt-0"
                style={{
                  fontFamily: "Open sans",
                  color: "#f44336",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
              >
                {modalErrors?.educationType}
              </span>
            )}
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              Major or Field of Study *
            </JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              placeholder='Ex. "BS Accountancy"'
              fullWidth
              onChange={(e) => {
                setTempEducation({
                  ...tempEducation,
                  educField: e.target.value,
                });
              }}
              value={tempEducation?.educField}
              error={!!modalErrors?.educField}
              helperText={modalErrors?.educField}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <JobluTypography.Body semiBold>
                  Start Date *
                </JobluTypography.Body>
                <CustomTextFieldV1
                  fullWidth
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setTempEducation({
                      ...tempEducation,
                      educStartDate: moment(e.target.value).format(
                        "MM/DD/YYYY"
                      ),
                    });
                  }}
                  defaultValue={moment(tempEducation?.educStartDate).format(
                    "YYYY-MM-DD"
                  )}
                  error={!!modalErrors?.educStartDate}
                  helperText={modalErrors?.educStartDate}
                />
              </Grid>
              {!tempEducation?.educCurrentStudy && (
                <Grid item xs={6}>
                  <JobluTypography.Body semiBold>
                    End Date *
                  </JobluTypography.Body>
                  <CustomTextFieldV1
                    fullWidth
                    type="date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setTempEducation({
                        ...tempEducation,
                        educEndDate: moment(e.target.value).format(
                          "MM/DD/YYYY"
                        ),
                      });
                    }}
                    defaultValue={moment(tempEducation?.educEndDate).format(
                      "YYYY-MM-DD"
                    )}
                    error={!!modalErrors?.educEndDate}
                    helperText={modalErrors?.educEndDate}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempEducation?.educCurrentStudy}
                    onChange={() => {
                      setTempEducation({
                        ...tempEducation,
                        educCurrentStudy: !tempEducation?.educCurrentStudy,
                        educEndDate: null,
                      });
                    }}
                  />
                }
                label="I’m currently studying here."
              />
            </FormGroup>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              Academic Achievement(s)
            </JobluTypography.Body>
            <Box>
              <CustomTextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                fullWidth
                onChange={(e) => {
                  setTempEducation({
                    ...tempEducation,
                    educAchievements: e.target.value,
                  });
                }}
                variant="outlined"
                value={tempEducation?.educAchievements}
              />
            </Box>
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_right}`}
          >
            <Box className={`${app_classes.margin.r1}`}>
              <Button
                variant="outlined"
                className={`${app_classes.button.primary_outline} `}
                onClick={handleCloseEducation}
              >
                Cancel
              </Button>
            </Box>
            <Button
              variant="contained"
              className={`${app_classes.button.primary}`}
              onClick={() => {
                let educs =
                  current_user?.educationHistory?.length > 0
                    ? current_user?.educationHistory
                    : [];

                const validateEduc = () => {
                  const educErrors = {};
                  const educ_requireds = [
                    //   { key: "educAchievements", error_message: "Achievements is required" },
                    // { educCurrentStudy: null },
                    {
                      key: "educationType",
                      error_message: "Degree is required",
                    },
                    {
                      key: "educField",
                      error_message: "Field of study is required",
                    },

                    {
                      key: "educName",
                      error_message: "Institution/School is required",
                    },

                    {
                      key: "educStartDate",
                      error_message: "Start date is required",
                    },
                    {
                      key: "educEndDate",
                      error_message: "End Date is required",
                    },
                  ];

                  for (let item of educ_requireds) {
                    const { key, error_message } = item;
                    if (
                      tempEducation?.educCurrentStudy &&
                      key === "educEndDate"
                    ) {
                      //endDate is not required anymore
                    } else {
                      if (!tempEducation[key]) {
                        // setShowAlert({
                        //   message: `${error_message}`,
                        //   type: "warning",
                        // });
                        educErrors[key] = item.error_message;
                        // return false;
                      } else {
                        if (key === "educStartDate") {
                          if (
                            moment(tempEducation?.educStartDate)?.isAfter(
                              tempEducation?.educEndDate
                            )
                          ) {
                            educErrors["educStartDate"] =
                              "Start date is ahead from end date";
                          }
                        }
                      }
                    }
                  }
                  setModalErrors(educErrors);
                  if (Object.keys(educErrors)?.length > 0) {
                    return false;
                  }
                  return true;
                };

                const is_valid = validateEduc();
                if (is_valid) {
                  if (showEducationModal === "Edit") {
                    educs[tempEducation?.index] = tempEducation;
                  }
                  if (showEducationModal === "Add") {
                    educs.push(tempEducation);
                  }
                  handleCloseEducation();
                  setCurrentUser({ ...current_user, educationHistory: educs });
                }
              }}
            >
              {showEducationModal === "Add" ? "Add" : "Save"}
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* EXPERIENCE MODAL */}
      <Dialog
        open={showExpModal}
        onClose={handleCloseExpModal}
        scroll={`body`}
        maxWidth="sm"
        fullWidth
      >
        <Box
          className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          style={{ minWidth: 400 }}
        >
          <JobluTypography.H6 align="center">
            {showExpModal} Experience
          </JobluTypography.H6>
        </Box>
        <Box className={`${app_classes.padding.a3}`}>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Company Name *</JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              placeholder='Ex. "ACME Corp"'
              fullWidth
              onChange={(e) => {
                setTempExp({
                  ...tempExp,
                  companyName: e.target.value,
                });
              }}
              value={tempExp?.companyName}
              error={!!modalErrors?.companyName}
              helperText={modalErrors?.companyName}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Role *</JobluTypography.Body>
            <CustomTextField
              size="small"
              variant="outlined"
              placeholder='Ex. "Accountant, Bookkeeper, Front-End Developer"'
              fullWidth
              onChange={(e) => {
                setTempExp({
                  ...tempExp,
                  jobTitle: e.target.value,
                });
              }}
              value={tempExp?.jobTitle}
              error={!!modalErrors?.jobTitle}
              helperText={modalErrors?.jobTitle}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Location *</JobluTypography.Body>
            {/* <TextField
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setTempExp({
                  ...tempExp,
                  location: e.target.value,
                });
              }}
              value={tempExp?.location}
            /> */}
            <CustomGooglePlaces
              onChange={(data) => {
                if (data) {
                  console.log(data);
                  setTempExp({
                    ...tempExp,
                    location: data?.location,
                  });
                }
              }}
              value={tempExp?.location}
              error={!!modalErrors?.location}
              helperText={modalErrors?.location}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <JobluTypography.Body semiBold>
                  Start Date *
                </JobluTypography.Body>
                <CustomTextFieldV1
                  fullWidth
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setTempExp({
                      ...tempExp,
                      startDate: moment(e.target.value).format("MM/DD/YYYY"),
                    });
                  }}
                  variant="outlined"
                  defaultValue={moment(tempExp?.startDate).format("YYYY-MM-DD")}
                  error={!!modalErrors?.startDate}
                  helperText={modalErrors?.startDate}
                />
              </Grid>
              {!tempExp?.expWorkingHere && (
                <Grid item xs={6}>
                  <JobluTypography.Body semiBold>
                    End Date *
                  </JobluTypography.Body>
                  <CustomTextFieldV1
                    fullWidth
                    type="date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setTempExp({
                        ...tempExp,
                        endDate: moment(e.target.value).format("MM/DD/YYYY"),
                      });
                    }}
                    defaultValue={
                      moment(tempExp?.endDate).format("YYYY-MM-DD") || null
                    }
                    variant="outlined"
                    error={!!modalErrors?.endDate}
                    helperText={modalErrors?.endDate}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempExp?.expWorkingHere}
                    onChange={(e) => {
                      setTempExp({
                        ...tempExp,
                        expWorkingHere: !tempExp?.expWorkingHere,
                        endDate: null,
                      });
                    }}
                  />
                }
                label="Still Employed by this company"
              />
            </FormGroup>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>
              Responsibilities *
            </JobluTypography.Body>
            <Box>
              <CustomTextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                fullWidth
                onChange={(e) => {
                  setTempExp({
                    ...tempExp,
                    responsibilities: e.target.value,
                  });
                }}
                variant="outlined"
                value={tempExp?.responsibilities}
                error={!!modalErrors?.responsibilities}
                helperText={modalErrors?.responsibilities}
              />
            </Box>
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.Body semiBold>Achievements</JobluTypography.Body>
            <Box>
              <CustomTextField
                id="outlined-multiline-static"
                multiline
                rows={2}
                fullWidth
                onChange={(e) => {
                  setTempExp({
                    ...tempExp,
                    achievements: e.target.value,
                  });
                }}
                variant="outlined"
                value={tempExp?.achievements}
              />
            </Box>
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_right}`}
          >
            <Button
              variant="outlined"
              className={`${app_classes.button.primary_outline} ${app_classes.margin.r1}`}
              onClick={handleCloseExpModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={`${app_classes.button.primary}`}
              onClick={() => {
                const expErrors = {};
                let exps =
                  current_user?.experienceHistory?.length > 0
                    ? current_user?.experienceHistory
                    : [];
                const exp_requireds = [
                  {
                    key: "companyName",
                    error_message: "Company Name is required",
                  },
                  { key: "jobTitle", error_message: "Role is required" },

                  { key: "location", error_message: "Location is required" },
                  {
                    key: "responsibilities",
                    error_message: "Responsibilities is required",
                  },
                  // {
                  //   key: "achievements",
                  //   error_message: "Achievements is required",
                  // },
                  { key: "startDate", error_message: "Start date is required" },

                  { key: "endDate", error_message: "End date is required" },
                ];

                console.log(exp_requireds);
                const validateExp = () => {
                  console.log("validate");
                  for (let item of exp_requireds) {
                    const { key, error_message } = item;
                    if (tempExp?.expWorkingHere && key === "endDate") {
                      //endDate is not required anymore
                    } else {
                      if (!tempExp[key]) {
                        // setShowAlert({
                        //   message: `${error_message}`,
                        //   type: "warning",
                        // });
                        expErrors[key] = item.error_message;
                        // return false;
                      } else {
                        if (key === "startDate") {
                          if (
                            moment(tempExp?.startDate)?.isAfter(
                              tempExp?.endDate
                            )
                          ) {
                            expErrors["startDate"] =
                              "Start date is ahead from End date";
                          }
                        }
                      }
                    }
                  }
                  console.log(expErrors);
                  setModalErrors(expErrors);
                  if (Object?.keys(expErrors)?.length > 0) {
                    return false;
                  }
                  return true;
                };

                const is_valid = validateExp();
                if (is_valid) {
                  if (showExpModal === "Add") {
                    exps.push(tempExp);
                  }
                  if (showExpModal === "Edit") {
                    exps[tempExp?.index] = tempExp;
                  }
                  setCurrentUser({
                    ...current_user,
                    experienceHistory: exps,
                  });
                  console.log(current_user);
                  handleCloseExpModal();
                }
              }}
            >
              {showExpModal === "Add" ? "Add" : "Save"}
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog open={done} fullWidth maxWidth="sm">
        <Box
          className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.flexBox.align_items_center} ${app_classes.padding.a5}`}
        >
          <Box className={`${app_classes.text.center}`}>
            <Box
              className={`${app_classes.text.center} ${app_classes.margin.b3}`}
            >
              <img src={LuStar} width={180} />
            </Box>
            <Box>
              <JobluTypography.H3 className={`${app_classes.text.warning}`}>
                You’re the best!
              </JobluTypography.H3>
            </Box>
            <Box className={`${app_classes.margin.b4}`}>
              <JobluTypography.H3 primary>
                Thank you for completing your profile.
              </JobluTypography.H3>
            </Box>
            <Box className={`${app_classes.margin.b4}`}>
              <JobluTypography.H6 primary>
                You're one step closer to landing your dream job. Now, let's get
                searching!
              </JobluTypography.H6>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Apply for Jobs
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <Dialog open={buildingProfile} fullWidth maxWidth="md">
        <Box
          className={`${app_classes.display.display_flex} ${
            app_classes.flexBox.justify_content_center
          } ${app_classes.flexBox.align_items_center} ${
            width < 640 ? app_classes.padding.a5 : app_classes.padding.a10
          }`}
        >
          <Box className={`${app_classes.text.center}`}>
            <Box className={`${app_classes.margin.b3}`}>
              <img src={Lu} width={110} />
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <CircularProgress />
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.H2 primary>
                Hang in there. We're building your profile.
              </JobluTypography.H2>
            </Box>
            <Box>
              <JobluTypography.H6 primary>
                Our AI is doing its thing to capture your information. Sit back,
                relax, and don’t close this app while we make sure everything is
                in place.
              </JobluTypography.H6>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {!error ? (
        <>
          {step == null && (
            <>
              <Box
                style={{ minHeight: "100vh", backgroundColor: "#E4ECF3" }}
                className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.flexBox.align_items_center}`}
              >
                <Grid container justifyContent="center">
                  {showSelectModal && (
                    <>
                      <Grid item xs={11} sm={8} md={8} lg={6} xl={5}>
                        <Box
                          style={{
                            background: `url('${ModalBg}')`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: "10px",
                          }}
                          className={`${
                            width < 640
                              ? app_classes.padding.a5
                              : app_classes.padding.a10
                          } ${app_classes.text.center} ${
                            app_classes.text.white
                          }`}
                        >
                          <Box className={`${app_classes.margin.b3}`}>
                            <JobluTypography.H1>
                              Get noticed!
                            </JobluTypography.H1>
                          </Box>
                          <Box className={`${app_classes.margin.b3}`}>
                            <JobluTypography.H3>
                              It only takes a second to create your profile
                            </JobluTypography.H3>
                          </Box>
                          <Box className={`${app_classes.margin.b3}`}>
                            <JobluTypography.H5>
                              Just paste your{" "}
                              <strong>LinkedIn profile link</strong> or
                              <strong> upload your resume</strong>. Let Joblu AI
                              do the rest.
                            </JobluTypography.H5>
                          </Box>
                          <Box className={`${app_classes.margin.b3}`}>
                            <Grid container spacing={2} justifyContent="center">
                              <Grid item xs={12} sm={6} md={4}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  style={{ textTransform: "none" }}
                                  fullWidth
                                  onClick={() => {
                                    setShowSelectModal(false);
                                    setShowUploadCV(true);
                                  }}
                                >
                                  Upload Resume
                                </Button>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  style={{ textTransform: "none" }}
                                  fullWidth
                                  onClick={() => {
                                    setShowSelectModal(false);
                                    setShowLinkedInModal(true);
                                  }}
                                >
                                  Enter LinkedIn URL
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box className={`${app_classes.margin.b3}`}>
                            <JobluTypography.H5>OR</JobluTypography.H5>
                          </Box>
                          <Box>
                            <Grid container justifyContent="center">
                              <Grid item xs={12} md={8}>
                                <Button
                                  className={`${create_profile_classes.btn_white}`}
                                  variant="contained"
                                  style={{ textTransform: "none" }}
                                  fullWidth
                                  onClick={() => {
                                    setStep(2);
                                  }}
                                >
                                  Manually build your profile
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}
                  {showUploadCV && (
                    <>
                      <Grid item xs={11} sm={8} md={7} lg={5} xl={5}>
                        <Box
                          className={`${
                            width < 640
                              ? app_classes.padding.a5
                              : app_classes.padding.a10
                          } } ${app_classes.text.center}`}
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "10px",
                            paddingTop: 50,
                            marginBottom: 20,
                          }}
                        >
                          <Box
                            style={{
                              justifyContent: "start",
                              display: "flex",
                              marginBottom: 20,
                            }}
                          >
                            <Button
                              color="primary"
                              startIcon={<ArrowBackIcon color="primary" />}
                              style={{ textTransform: "none" }}
                              onClick={() => {
                                setShowUploadCV(false);
                                setShowSelectModal(true);
                              }}
                            >
                              Go back
                            </Button>
                          </Box>
                          <Box className={`${app_classes.margin.b5}`}>
                            <img src={JobluLogoH} width={180} />
                          </Box>
                          <Box className={`${app_classes.margin.b5}`}>
                            <JobluTypography.H3 primary>
                              Already have a CV ready to go? Upload it and we'll
                              take care of the rest.
                            </JobluTypography.H3>
                          </Box>
                          <Box className={`${app_classes.margin.b5}`}>
                            <JobluTypography.H6 primary>
                              Just upload the file, and we will convert it into
                              a smart profile instantly.
                            </JobluTypography.H6>
                          </Box>
                          <Box>
                            <Box className={`${app_classes.margin.b5}`}>
                              <Grid container justifyContent="center">
                                <Grid item xs={12} sm={10}>
                                  <input
                                    accept="image/*, application/pdf, .docx"
                                    id="contained-button-file"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(e) =>
                                      handleUploadFile(e.target.files[0])
                                    }
                                  />
                                  <label
                                    htmlFor="contained-button-file"
                                    style={{ width: "100%" }}
                                  >
                                    <Button
                                      variant="outlined"
                                      fullWidth
                                      color="primary"
                                      component="span"
                                    >
                                      {cvFileInfo?.name || "Upload"}
                                    </Button>
                                  </label>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box>
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xs={12} sm={5}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={() => {
                                      setCVFileInfo(null);
                                    }}
                                  >
                                    Clear File
                                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ textTransform: "none" }}
                                    onClick={() => {
                                      handleCvParsing();
                                    }}
                                    disabled={!cvFileInfo}
                                  >
                                    Start Building
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}

                  {showLinkedInModal && (
                    <>
                      <Grid item xs={11} sm={8} md={7} lg={5} xl={5}>
                        <Box
                          className={`${
                            width < 640
                              ? app_classes.padding.a5
                              : app_classes.padding.a10
                          } ${app_classes.text.center}`}
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "10px",
                            paddingTop: 50,
                            marginBottom: 20,
                          }}
                        >
                          <Box
                            style={{
                              justifyContent: "start",
                              display: "flex",
                              marginBottom: 20,
                            }}
                          >
                            <Button
                              color="primary"
                              startIcon={<ArrowBackIcon color="primary" />}
                              style={{ textTransform: "none" }}
                              onClick={() => {
                                setShowSelectModal(true);
                                setShowLinkedInModal(false);
                              }}
                            >
                              Go back
                            </Button>
                          </Box>
                          <Box className={`${app_classes.margin.b5}`}>
                            <img src={JobluLogoH} width={180} />
                          </Box>
                          <Box>
                            <JobluTypography.H3 primary>
                              Short in time?
                            </JobluTypography.H3>
                          </Box>
                          <Box className={`${app_classes.margin.b5}`}>
                            <JobluTypography.H3 primary>
                              Use your LinkedIn profile to create your Joblu
                              account in just a few clicks.
                            </JobluTypography.H3>
                          </Box>
                          <Box className={`${app_classes.margin.b5}`}>
                            <JobluTypography.H6 primary>
                              Simply paste your LinkedIn profile link below to
                              automatically build your profile
                            </JobluTypography.H6>
                          </Box>
                          <Box>
                            <Box className={`${app_classes.margin.b5}`}>
                              <Grid container justifyContent="center">
                                <Grid item xs={12} sm={10}>
                                  <CustomTextField
                                    size="small"
                                    variant="outlined"
                                    placeholder="Enter LinkedIn Profile Link"
                                    fullWidth
                                    value={linkedinLink}
                                    onChange={(e) =>
                                      setLinkedinLink(e.target.value)
                                    }
                                    helperText={errors.linkedin}
                                    error={errors.linkedin}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box>
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xs={12} sm={5}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    style={{ textTransform: "none" }}
                                    onClick={() => {
                                      setLinkedinLink("");
                                    }}
                                  >
                                    Clear Fields
                                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => {
                                      handleLinkedinParsing();
                                    }}
                                    style={{ textTransform: "none" }}
                                    disabled={!linkedinLink}
                                  >
                                    Start Building
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </>
          )}

          <Box
            style={{
              backgroundColor: "#E4ECF3",
              display: `${step == null ? "none" : "block"}`,
            }}
          >
            <Dialog open={open} fullWidth maxWidth="xs">
              <Box
                // className={`${app_classes.modal.box}`}
                style={{ padding: 0, border: 0 }}
              >
                <Box
                  className={`${app_classes.backgroundColor.bg_primary} ${app_classes.text.white} ${app_classes.text.center}  ${app_classes.padding.a2}`}
                >
                  <JobluTypography.H5>Profile Building</JobluTypography.H5>
                </Box>
                <Box
                  className={`${app_classes.padding.a5} ${app_classes.text.center} `}
                >
                  <Box className={`${app_classes.margin.b3}`}>
                    <img
                      src={Lu}
                      className={`${create_profile_classes.modal_img}`}
                    />
                  </Box>
                  <Box>
                    <CircularProgress />
                  </Box>
                  <Box className={`${app_classes.margin.b3}`}>
                    <JobluTypography.H3 primary semiBold>
                      Almost there!
                    </JobluTypography.H3>
                  </Box>
                  <Box>
                    <JobluTypography.H5 secondary>
                      Sit back and relax while we gather your information and
                      build your profile. It may take up to 40 seconds.
                    </JobluTypography.H5>
                  </Box>
                </Box>
              </Box>
            </Dialog>

            <Box>
              <Grid
                container
                justifyContent="center"
                // alignItems="center"
                style={{ minHeight: "100vh" }}
              >
                <Grid item xs={10}>
                  {step == 0 && (
                    <>
                      <ProfilePreview
                        current_user={User?.user}
                        addMoreDetails={() => setStep(1)}
                        nextStep={nextStep}
                        isFromCreateProfile
                      />
                    </>
                  )}

                  {step === 1 && (
                    <>
                      <Box
                        style={{ borderRadius: "20px" }}
                        className={`${app_classes.backgroundColor.bg_white} ${app_classes.margin.y5} ${app_classes.padding.a5}`}
                      >
                        <Button
                          color="primary"
                          startIcon={<ArrowBackIcon color="primary" />}
                          style={{ textTransform: "none", marginBottom: 50 }}
                          onClick={() => {
                            setStep(null);
                          }}
                        >
                          Go back
                        </Button>
                        <Box className={`${app_classes.margin.b5}`}>
                          <JobluTypography.H4
                            className={`${app_classes.text.warning}`}
                          >
                            Step 1 of 7
                          </JobluTypography.H4>
                        </Box>
                        <Box className={`${app_classes.margin.b1}`}>
                          <JobluTypography.H4 primary>
                            Nicely, done! Your profile has been imported!
                          </JobluTypography.H4>
                        </Box>
                        <Box className={`${app_classes.margin.b4}`}>
                          <JobluTypography.H6 primary>
                            Adding more information will make you standout to
                            employers. Would you like to add more information?
                          </JobluTypography.H6>
                        </Box>
                        <Box>
                          <Grid container justifyContent="center">
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                backgroundColor: "#F6F9FB",
                                borderRadius: "16px",
                              }}
                              className={`${width < 640 ? app_classes.padding.a2 :app_classes.padding.a5}`}
                            >
                              <Box
                                className={`${app_classes.display.display_flex} ${width < 640 ? app_classes.flexBox.flex_direction_column: app_classes.flexBox.flex_direction_row} ${app_classes.flexBox.align_items_center} ${app_classes.margin.b4}`}
                              >
                                <Box>
                                  <Box
                                    width={88}
                                    className={`${app_classes.margin.r3}`}
                                  >
                                    <CircularProgressbarWithChildren
                                      value={completionPercentage?.completion}
                                      styles={buildStyles({
                                        pathColor: "#009CDE",
                                        // trailColor: "#009CDE",
                                      })}
                                    >
                                      {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                      <img
                                        style={{ maxWidth: "60%" }}
                                        src={Lu}
                                        alt="Lu"
                                      />
                                    </CircularProgressbarWithChildren>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box>
                                    <JobluTypography.H3>
                                      Profile Level:{" "}
                                      <span
                                        className={`${app_classes.text.secondary}`}
                                      >
                                        {`${completionPercentage?.completion}%`}
                                      </span>
                                    </JobluTypography.H3>
                                  </Box>
                                  <Box>
                                    <JobluTypography.H6>
                                      Here’s a checklist of things from your
                                      profile
                                    </JobluTypography.H6>
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <Box
                                  className={`${app_classes.display.display_flex} ${width < 640 && app_classes.flexBox.flex_direction_column} ${app_classes.flexBox.justify_content_center}`}
                                >
                                  <FormGroup
                                    className={`${app_classes.margin.r3}`}
                                  >
                                    {profileLevel
                                      ?.slice(0, 4)
                                      ?.map((item, k) => (
                                        <FormControlLabel
                                          className={`${app_classes.margin.b3}`}
                                          key={item.title}
                                          control={
                                            item.active === true ? (
                                              // <CheckBoxIcon />
                                              <CheckCircleIcon
                                                fontSize="small"
                                                color="secondary"
                                                className={`${app_classes.margin.r1}`}
                                              />
                                            ) : (
                                              <RadioButtonUncheckedRoundedIcon
                                                className={`${app_classes.margin.r1}`}
                                              />
                                            )
                                          }
                                          label={
                                            <JobluTypography.H6>
                                              {item.title}
                                            </JobluTypography.H6>
                                          }
                                        />
                                      ))}
                                  </FormGroup>
                                  <FormGroup>
                                    {profileLevel
                                      ?.slice(5, 9)
                                      ?.map((item, k) => (
                                        <FormControlLabel
                                          className={`${app_classes.margin.b3}`}
                                          key={item.title}
                                          control={
                                            item.active === true ? (
                                              // <CheckBoxIcon />
                                              <CheckCircleIcon
                                                fontSize="small"
                                                color="secondary"
                                                className={`${app_classes.margin.r1}`}
                                              />
                                            ) : (
                                              <RadioButtonUncheckedRoundedIcon
                                                className={`${app_classes.margin.r1}`}
                                              />
                                            )
                                          }
                                          label={
                                            <JobluTypography.H6>
                                              {item.title}
                                            </JobluTypography.H6>
                                          }
                                        />
                                      ))}
                                  </FormGroup>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs="12"
                              className={`${app_classes.text.center} ${app_classes.margin.t3}`}
                            >
                              <Box>
                                <Box>
                                  <JobluTypography.H6 primary>
                                    Your information has been imported from your{" "}
                                    {parseType == "cv" ? (
                                      <>CV</>
                                    ) : (
                                      <>LinkedIn</>
                                    )}
                                  </JobluTypography.H6>
                                </Box>

                                <Box>
                                  <Grid container justifyContent="center">
                                    <Grid
                                      item
                                      xs={8}
                                      className={`${app_classes.margin.t3} ${app_classes.margin.b3}`}
                                    >
                                      <Box>
                                        <hr />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                      <Box> 
                                        <Grid
                                          container
                                          spacing={2}
                                          justifyContent="center"
                                        >
                                          {!isParseSuccess ? null : (
                                            <Grid item xs={12} sm={4}>
                                              <Button
                                                color="secondary"
                                                variant="outlined"
                                                fullWidth
                                                style={{
                                                  textTransform: "none",
                                                }}
                                                onClick={() => {
                                                  navigate("/dashboard");
                                                }}
                                              >
                                                I'm okay for now
                                              </Button>
                                            </Grid>
                                          )}

                                          <Grid item xs={12} sm={4}>
                                            {" "}
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              fullWidth
                                              style={{ textTransform: "none" }}
                                              onClick={() => {
                                                setStep(2);
                                                setIsParseSuccess(true);
                                              }}
                                            >
                                              I want to impress employers{" "}
                                              <ArrowForwardIcon
                                                className={`${app_classes.margin.l1}`}
                                              />
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </>
                  )}

                  {step == 2 && (
                    <>
                      <Box
                        className={`${app_classes.backgroundColor.bg_white} ${
                          app_classes.margin.y5
                        } ${
                          width < 640
                            ? app_classes.padding.a5
                            : app_classes.padding.a10
                        }`}
                        style={{ paddingTop: 50, borderRadius: "20px" }}
                      >
                        {!isParseSuccess ? (
                          <Button
                            color="primary"
                            startIcon={<ArrowBackIcon color="primary" />}
                            style={{ textTransform: "none", marginBottom: 50 }}
                            onClick={() => {
                              setStep(null);
                            }}
                          >
                            Go back
                          </Button>
                        ) : null}
                        <Box className={`${app_classes.margin.b5}`}>
                          <JobluTypography.H4
                            className={`${app_classes.text.warning}`}
                          >
                            {isParseSuccess ? "Step 2 of 7" : "Step 1 of 6"}
                          </JobluTypography.H4>
                        </Box>
                        <Box className={`${app_classes.margin.b1}`}>
                          <JobluTypography.H4 primary>
                            Upload profile picture
                          </JobluTypography.H4>
                        </Box>
                        <Box className={`${app_classes.margin.b4}`}>
                          <JobluTypography.H6 primary>
                            A picture is worth a thousand words, and a great
                            profile picture can make all the difference.
                          </JobluTypography.H6>
                        </Box>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center}`}
                        ></Box>
                        <Box
                          className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.margin.y3}`}
                        >
                          <Box style={{ position: "relative" }}>
                            <img
                              src={current_user?.profileUrl || DefaultImg}
                              width={120}
                              height={120}
                              style={{
                                borderRadius: "50%",
                                objectFit: "cover",
                                background: '#FFFFFF'
                              }}
                            />
                            <Box
                              style={{
                                position: "absolute",
                                right: "-5px",
                                bottom: "0px",
                              }}
                              className={`${app_classes.cursor.pointer}`}
                            >
                              <img
                                src={UploadIcon}
                                width={44}
                                onClick={(e) => {
                                  uploadProfilePicRef?.current?.click();
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Box className={`${app_classes.margin.b1}`}>
                            <JobluTypography.H4 primary>
                              Tell us your story
                            </JobluTypography.H4>
                          </Box>
                          <Box className={`${app_classes.margin.b4}`}>
                            <JobluTypography.H6 primary>
                              We know you're more than just your CV. Add your
                              bio and let employers know what a catch you are.
                            </JobluTypography.H6>
                          </Box>
                          <CustomTextField
                            size="small"
                            variant="outlined"
                            placeholder="Type Here"
                            multiline
                            rows={8}
                            fullWidth
                            value={current_user?.bio}
                            onChange={(e) => {
                              setCurrentUser({
                                ...current_user,
                                bio: e?.target?.value,
                              });
                            }}
                          />
                        </Box>{" "}
                        {step !== null && step >= 2 && step <= 9 && (
                          <>
                            <PageControl
                              step={step}
                              prevStep={prevStep}
                              nextStep={() => nextStep(3)}
                              setDone={setDone}
                              app_classes={app_classes}
                              noPrev={!isParseSuccess}
                            />
                          </>
                        )}
                      </Box>
                    </>
                  )}

                  {step == 3 && (
                    <>
                      <Box
                        style={{ borderRadius: "20px" }}
                        className={`${app_classes.backgroundColor.bg_white} ${
                          app_classes.margin.y5
                        } ${
                          width < 640
                            ? app_classes.padding.a5
                            : app_classes.padding.a10
                        }`}
                      >
                        <Box className={`${app_classes.margin.b5}`}>
                          <JobluTypography.H4
                            className={`${app_classes.text.warning}`}
                          >
                            {isParseSuccess ? "Step 3 of 7" : "Step 2 of 6"}
                          </JobluTypography.H4>
                        </Box>
                        <Box className={`${app_classes.margin.b1}`}>
                          <JobluTypography.H4 primary>
                            Preferences
                          </JobluTypography.H4>
                        </Box>
                        <Box className={`${app_classes.margin.b5}`}>
                          <JobluTypography.H6 primary>
                            Tell us what you're looking for! Select your job
                            preferences so we can show you job listings that
                            match your interests.
                          </JobluTypography.H6>
                        </Box>
                        <Box className={`${app_classes.margin.b4}`}>
                          <Box className={`${app_classes.margin.b1}`}>
                            <JobluTypography.H6>
                              Employment Status *
                            </JobluTypography.H6>
                          </Box>
                          {employmentStatusList?.map((item) => {
                            return (
                              <Chip
                                label={item.label}
                                className={`${
                                  current_user?.employmentStatus === item.value
                                    ? create_profile_classes.chip_active
                                    : create_profile_classes.chip_inactive
                                }`}
                                onClick={() => {
                                  handleEmploymentStatus(item.value);
                                  setCurrentUser({
                                    ...current_user,
                                    employmentStatus: item.value,
                                  });
                                }}
                              />
                            );
                          })}
                          {empStatusError && (
                            <>
                              <Box>
                                <JobluTypography.Caption
                                  style={{ color: "red", fontSize: 10 }}
                                >
                                  Employment status is required.
                                </JobluTypography.Caption>
                              </Box>
                            </>
                          )}
                        </Box>
                        <Box className={`${app_classes.margin.b3}`}>
                          <Box
                            className={`${app_classes.margin.b1} ${
                              width > 640 && app_classes.display.display_flex
                            } ${
                              width < 640
                                ? app_classes.flexBox.flex_direction_column
                                : app_classes.flexBox.flex_direction_row
                            }  ${app_classes.flexBox.align_items_center}`}
                          >
                            <Box className={`${app_classes.margin.r1}`}>
                              <JobluTypography.H6>
                                Employment Type * -
                              </JobluTypography.H6>
                            </Box>
                            <JobluTypography.Body>
                              Choose up to two{" "}
                              {`${current_user?.employmentType?.length}`}/2
                            </JobluTypography.Body>
                          </Box>
                          {employmentTypeList?.map((item, i_index) => {
                            return (
                              <Chip
                                label={item.label}
                                className={`${
                                  current_user?.employmentType?.includes(
                                    item.label
                                  )
                                    ? create_profile_classes.chip_active
                                    : create_profile_classes.chip_inactive
                                } `}
                                onClick={() => {
                                  let employmentType =
                                    current_user?.employmentType || [];
                                  if (employmentType?.includes(item?.label)) {
                                    if (employmentType.length < 2) return false;

                                    employmentType = employmentType?.filter(
                                      (h) => h !== item?.label
                                    );
                                  } else {
                                    if (employmentType.length === 2)
                                      employmentType.shift();
                                    employmentType = employmentType?.concat([
                                      item?.label,
                                    ]);
                                  }
                                  setCurrentUser({
                                    ...current_user,
                                    employmentType,
                                  });
                                }}
                              />
                            );
                          })}
                          {empTypeError && (
                            <>
                              <Box>
                                <JobluTypography.Caption
                                  style={{ color: "red", fontSize: 10 }}
                                >
                                  Employment type is required.
                                </JobluTypography.Caption>
                              </Box>
                            </>
                          )}
                        </Box>
                        <Box className={`${app_classes.margin.b3}`}>
                          <Box
                            className={`${app_classes.margin.b1} ${
                              width > 640 && app_classes.display.display_flex
                            } ${app_classes.flexBox.align_items_center}`}
                          >
                            <Box className={`${app_classes.margin.r1}`}>
                              <JobluTypography.H6>
                                Work Setup * -
                              </JobluTypography.H6>
                            </Box>
                            <JobluTypography.Body>
                              Choose up to two (
                              {current_user?.workSetup?.length}/2)
                            </JobluTypography.Body>
                          </Box>
                          {workSetupList?.map((item, i_index) => {
                            return (
                              <Chip
                                label={item.label}
                                className={`${
                                  current_user?.workSetup?.includes(item.label)
                                    ? create_profile_classes.chip_active
                                    : create_profile_classes.chip_inactive
                                } `}
                                onClick={() => {
                                  let workSetup = current_user?.workSetup || [];
                                  if (workSetup?.includes(item?.label)) {
                                    if (workSetup.length < 2) return false;
                                    workSetup = workSetup?.filter(
                                      (h) => h !== item?.label
                                    );
                                  } else {
                                    if (workSetup?.length === 2)
                                      workSetup?.shift();
                                    workSetup = workSetup?.concat([
                                      item?.label,
                                    ]);
                                  }
                                  setCurrentUser({
                                    ...current_user,
                                    workSetup,
                                  });
                                }}
                              />
                            );
                          })}
                          {workSetupError && (
                            <>
                              <Box>
                                <JobluTypography.Caption
                                  style={{ color: "red", fontSize: 10 }}
                                >
                                  Work setup is required.
                                </JobluTypography.Caption>
                              </Box>
                            </>
                          )}
                        </Box>
                        <Box className={`${app_classes.margin.b3}`}>
                          <Box
                            className={`${app_classes.margin.b1} ${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                          >
                            <Box className={`${app_classes.margin.r1}`}>
                              <JobluTypography.H6>
                                Salary Range
                              </JobluTypography.H6>
                            </Box>
                          </Box>
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={2}>
                                <TextField
                                  select
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  defaultValue={
                                    current_user?.salaryCurrency || currency
                                  }
                                  value={current_user?.salaryCurrency}
                                  onChange={(e) => {
                                    setCurrentUser({
                                      ...current_user,
                                      salaryCurrency: e?.target?.value,
                                    });
                                  }}
                                >
                                  {currencies.map((item) => (
                                    <MenuItem
                                      key={item.label}
                                      value={item.value}
                                    >
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {currencyError && (
                                  <>
                                    <Box>
                                      <JobluTypography.Caption
                                        style={{ color: "red", fontSize: 10 }}
                                      >
                                        Currency is required.
                                      </JobluTypography.Caption>
                                    </Box>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <NumericFormat
                                  variant="outlined"
                                  thousandSeparator
                                  customInput={CustomTextField}
                                  value={current_user?.salaryMin}
                                  placeholder="Minimum salary"
                                  onValueChange={({
                                    formattedValue,
                                    value,
                                  }) => {
                                    setCurrentUser({
                                      ...current_user,
                                      salaryMin: parseFloat(value),
                                    });
                                  }}
                                  error={
                                    salaryMinError ||
                                    salaryMinError2 ||
                                    salaryRangeError
                                  }
                                  size="small"
                                  fullWidth
                                />
                                {salaryMinError && (
                                  <>
                                    <Box>
                                      <JobluTypography.Caption
                                        style={{ color: "red", fontSize: 10 }}
                                      >
                                        Minimum salary is required.
                                      </JobluTypography.Caption>
                                    </Box>
                                  </>
                                )}
                                {salaryMinError2 && (
                                  <>
                                    <Box>
                                      <JobluTypography.Caption
                                        style={{ color: "red", fontSize: 10 }}
                                      >
                                        Invalid minimum salary.
                                      </JobluTypography.Caption>
                                    </Box>
                                  </>
                                )}
                                {salaryRangeError && (
                                  <>
                                    <Box>
                                      <JobluTypography.Caption
                                        style={{ color: "red", fontSize: 10 }}
                                      >
                                        Minimum salary is greater than maximum
                                        salary provided.
                                      </JobluTypography.Caption>
                                    </Box>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <NumericFormat
                                  variant="outlined"
                                  thousandSeparator
                                  customInput={CustomTextField}
                                  value={current_user?.salaryMax}
                                  placeholder="Maximum salary"
                                  onValueChange={({
                                    formattedValue,
                                    value,
                                  }) => {
                                    setCurrentUser({
                                      ...current_user,
                                      salaryMax: parseFloat(value),
                                    });
                                  }}
                                  error={salaryMaxError || !!errors?.salaryMax}
                                  helperText={errors?.salaryMax}
                                  size="small"
                                  fullWidth
                                />
                                {salaryMaxError && (
                                  <>
                                    <Box>
                                      <JobluTypography.Caption
                                        style={{ color: "red", fontSize: 10 }}
                                      >
                                        Maximum salary is required.
                                      </JobluTypography.Caption>
                                    </Box>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <Box className={`${app_classes.margin.b3}`}>
                          <Box
                            className={`${app_classes.margin.b1} ${
                              width > 640 && app_classes.display.display_flex
                            } ${app_classes.flexBox.align_items_center}`}
                          >
                            <Box className={`${app_classes.margin.r1}`}>
                              <JobluTypography.H6>
                                What do you value the most? * -
                              </JobluTypography.H6>
                            </Box>
                            <JobluTypography.Body>
                              Choose up to two (
                              {current_user?.importantForYou?.length}/3)
                            </JobluTypography.Body>
                          </Box>
                          {valuesList?.map((item) => {
                            return (
                              <Chip
                                label={item.label}
                                className={`${
                                  current_user?.importantForYou?.includes(
                                    item.value
                                  )
                                    ? create_profile_classes.chip_active
                                    : create_profile_classes.chip_inactive
                                } `}
                                onClick={() => {
                                  let importantForYou =
                                    current_user?.importantForYou || [];
                                  if (importantForYou?.includes(item?.label)) {
                                    if (importantForYou?.length < 2)
                                      return false;
                                    importantForYou = importantForYou?.filter(
                                      (h) => h !== item?.label
                                    );
                                  } else {
                                    if (importantForYou?.length === 3)
                                      importantForYou?.shift();
                                    importantForYou = importantForYou?.concat([
                                      item?.label,
                                    ]);
                                  }
                                  setCurrentUser({
                                    ...current_user,
                                    importantForYou,
                                  });
                                }}
                              />
                            );
                          })}
                          {valuesError && (
                            <>
                              <Box>
                                <JobluTypography.Caption
                                  style={{ color: "red", fontSize: 10 }}
                                >
                                  Select at least 1 option.
                                </JobluTypography.Caption>
                              </Box>
                            </>
                          )}
                        </Box>
                        <Box className={`${app_classes.margin.b3}`}>
                          <Box className={`${app_classes.margin.b1}`}>
                            <JobluTypography.H6>Industry *</JobluTypography.H6>
                          </Box>
                          <SelectJobFunction
                            noAll
                            onChange={(val) => {
                              console.log(val);
                              setCurrentUser({
                                ...current_user,
                                jobFunction: val,
                              });
                            }}
                            value={current_user?.jobFunction}
                            error={jfError}
                            // helperText={errors?.jobFunction}
                          />
                          {jfError && (
                            <>
                              <Box>
                                <JobluTypography.Caption
                                  style={{ color: "red", fontSize: 10 }}
                                >
                                  Job function is required.
                                </JobluTypography.Caption>
                              </Box>
                            </>
                          )}
                        </Box>
                        <Box className={`${app_classes.margin.b5}`}>
                          <Box className={`${app_classes.margin.b1}`}>
                            <JobluTypography.H6>
                              Desired Role *
                            </JobluTypography.H6>
                          </Box>
                          <CustomTextField
                            size="small"
                            variant="outlined"
                            placeholder="Type Here"
                            fullWidth
                            value={current_user?.preferredTitle}
                            onChange={(e) => {
                              setCurrentUser({
                                ...current_user,
                                preferredTitle: e?.target?.value,
                              });
                            }}
                            error={titleError}
                          />
                          {titleError && (
                            <>
                              <Box>
                                <JobluTypography.Caption
                                  style={{ color: "red", fontSize: 10 }}
                                >
                                  Desired role is required.
                                </JobluTypography.Caption>
                              </Box>
                            </>
                          )}
                        </Box>
                        {step !== null && step >= 2 && step <= 9 && (
                          <>
                            <PageControl
                              step={step}
                              noSkip
                              prevStep={prevStep}
                              nextStep={() => nextStep(4)}
                              setDone={setDone}
                              app_classes={app_classes}
                            />
                          </>
                        )}
                      </Box>
                    </>
                  )}
                  {step == 4 && (
                    <>
                      <Box
                        style={{ borderRadius: "20px" }}
                        className={`${app_classes.backgroundColor.bg_white} ${
                          app_classes.margin.y5
                        } ${
                          width < 640
                            ? app_classes.padding.a5
                            : app_classes.padding.a10
                        }`}
                      >
                        <Box className={`${app_classes.margin.b5}`}>
                          <JobluTypography.H4
                            className={`${app_classes.text.warning}`}
                          >
                            {isParseSuccess ? "Step 4 of 7" : "Step 3 of 6"}
                          </JobluTypography.H4>
                        </Box>
                        <Box
                          className={`${app_classes.margin.b3} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between}`}
                        >
                          <Box>
                            <Box>
                              <JobluTypography.H4 primary>
                                Experience
                              </JobluTypography.H4>
                            </Box>
                            <Box>
                              <JobluTypography.H6 primary>
                                Employers want to know about your previous work
                                experience. Tell us about your past roles and
                                accomplishments.
                              </JobluTypography.H6>
                            </Box>
                          </Box>
                          <Box>
                            <img
                              src={PlusIcon}
                              width={24}
                              className={`${app_classes.cursor.pointer}`}
                              onClick={() => handleOpenExpModal("Add")}
                            />
                          </Box>
                        </Box>
                        {current_user?.experienceHistory?.length > 0 ? (
                          <>
                            {current_user?.experienceHistory
                              ?.sort(function (a, b) {
                                return (
                                  new Date(b.startDate) - new Date(a.startDate)
                                );
                              })
                              .map((exp, exp_index) => {
                                return (
                                  <>
                                    <Box
                                      className={`${
                                        current_user?.experienceHistory
                                          ?.length > 1 && app_classes.margin.b3
                                      } ${app_classes.display.display_flex} ${
                                        app_classes.flexBox
                                          .justify_content_between
                                      } ${
                                        app_classes.backgroundColor.bg_light_2
                                      } ${app_classes.padding.a3}`}
                                    >
                                      <Box className={``}>
                                        <Box>
                                          <JobluTypography.Body>
                                            <strong>{exp.companyName}</strong> |{" "}
                                            {formatDateRange2(
                                              exp?.startDate,
                                              exp?.endDate
                                            )}
                                          </JobluTypography.Body>
                                        </Box>
                                        <Box>
                                          <JobluTypography.Body>
                                            {exp?.jobTitle}
                                          </JobluTypography.Body>
                                        </Box>
                                        <Box>
                                          <JobluTypography.Body>
                                            {exp?.location}
                                          </JobluTypography.Body>
                                        </Box>
                                        {exp?.responsibilities && (
                                          <>
                                            <Box
                                              className={`${app_classes.margin.t2}`}
                                            >
                                              <JobluTypography.Body>
                                                Responsibilities:
                                              </JobluTypography.Body>
                                              <JobluTypography.Body
                                                style={{
                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                {exp?.responsibilities}
                                              </JobluTypography.Body>
                                            </Box>
                                          </>
                                        )}
                                        {exp?.achievements && (
                                          <>
                                            <Box
                                              className={`${app_classes.margin.t2}`}
                                            >
                                              <JobluTypography.Body>
                                                Achievements:
                                              </JobluTypography.Body>
                                              <JobluTypography.Body
                                                style={{
                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                {exp?.achievements}
                                              </JobluTypography.Body>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                      <Box
                                        className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.flexBox.flex_direction_column}`}
                                      >
                                        <Box>
                                          <img
                                            src={EditIcon}
                                            width={16}
                                            onClick={() => {
                                              handleOpenExpModal("Edit");
                                              setTempExp({
                                                ...exp,
                                                index: exp_index,
                                              });
                                            }}
                                            className={`${app_classes.cursor.pointer}`}
                                          />
                                        </Box>
                                        <Box>
                                          <img
                                            src={DeleteIconRed}
                                            width={16}
                                            onClick={() => {
                                              let exps =
                                                current_user?.experienceHistory;
                                              exps.splice(exp_index, 1);
                                              setCurrentUser({
                                                ...current_user,
                                                experienceHistory: exps,
                                              });
                                            }}
                                            className={`${app_classes.cursor.pointer}`}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <Box
                              className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.backgroundColor.bg_light_2} ${app_classes.padding.a3}`}
                            >
                              <JobluTypography.Caption>
                                No experience information provided
                              </JobluTypography.Caption>
                            </Box>
                          </>
                        )}

                        {step !== null && step >= 2 && step <= 9 && (
                          <>
                            <PageControl
                              step={step}
                              prevStep={prevStep}
                              nextStep={() => nextStep(5)}
                              setDone={setDone}
                              app_classes={app_classes}
                            />
                          </>
                        )}
                      </Box>
                    </>
                  )}
                  {step == 5 && (
                    <>
                      <Box
                        style={{ borderRadius: "20px" }}
                        className={`${app_classes.backgroundColor.bg_white} ${
                          app_classes.margin.y5
                        } ${
                          width < 640
                            ? app_classes.padding.a5
                            : app_classes.padding.a10
                        }`}
                      >
                        <Box className={`${app_classes.margin.b5}`}>
                          <JobluTypography.H4
                            className={`${app_classes.text.warning}`}
                          >
                            {isParseSuccess ? "Step 5 of 7" : "Step 4 of 6"}
                          </JobluTypography.H4>
                        </Box>
                        <Box className={`${app_classes.margin.b1}`}>
                          <JobluTypography.H4 primary>
                            Skills
                          </JobluTypography.H4>
                        </Box>
                        <Box className={`${app_classes.margin.b3}`}>
                          <JobluTypography.H6 primary>
                            Highlight your unique skills and what makes you
                            stand out. Let's show employers what you're made of!
                          </JobluTypography.H6>
                        </Box>
                        <Box className={`${app_classes.margin.b4}`}>
                          <JobluTypography.H6 primary>
                            Technical Skills
                          </JobluTypography.H6>
                          <JobluTypography.Body>
                            Search for any skills you have. You can select up to
                            10 skills ({current_user?.skills?.length}/10).
                          </JobluTypography.Body>
                          <Box>
                            <AsyncSelect
                              className=""
                              onChange={handleChange}
                              isMulti
                              defaultValue={skillsCopy2}
                              cacheOptions
                              defaultOptions
                              placeholder="Search technical skills..."
                              loadOptions={loadOptions}
                              isOptionDisabled={() =>
                                current_user?.skills?.length >= 10
                              }
                              isLoading={isSearcingSkills}
                            />
                            {skillsError && (
                              <>
                                <Box>
                                  <JobluTypography.Caption
                                    style={{ color: "red", fontSize: 10 }}
                                  >
                                    Technical Skills is required.
                                  </JobluTypography.Caption>
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            className={`${app_classes.margin.b1} ${
                              width > 640 && app_classes.display.display_flex
                            } ${app_classes.flexBox.align_items_center}`}
                          >
                            <Box className={`${app_classes.margin.r1}`}>
                              <JobluTypography.H6>
                                Soft Skills -
                              </JobluTypography.H6>
                            </Box>
                            <JobluTypography.Body>
                              Choose up to 10 soft skills you want to highlight.
                              ({`${current_user?.softSkills?.length}`}/10)
                            </JobluTypography.Body>
                          </Box>
                          {softSkillsList?.map((item) => {
                            return (
                              <Chip
                                label={item.label}
                                className={`${
                                  current_user?.softSkills.includes(item.value)
                                    ? create_profile_classes.chip_active
                                    : create_profile_classes.chip_inactive
                                }`}
                                onClick={() => {
                                  let sk = current_user?.softSkills || [];
                                  if (sk?.includes(item?.label)) {
                                    if (sk?.length < 2) return false;
                                    sk = sk?.filter((h) => h !== item?.label);
                                  } else {
                                    if (sk?.length === 10) sk?.shift();
                                    sk = sk?.concat([item?.label]);
                                  }
                                  setCurrentUser({
                                    ...current_user,
                                    softSkills: sk,
                                  });
                                }}
                              />
                            );
                          })}
                          {softSkillsError && (
                            <>
                              <Box>
                                <JobluTypography.Caption
                                  style={{ color: "red", fontSize: 10 }}
                                >
                                  Soft Skills is required.
                                </JobluTypography.Caption>
                              </Box>
                            </>
                          )}
                        </Box>
                        {step !== null && step >= 2 && step <= 9 && (
                          <>
                            <PageControl
                              step={step}
                              noSkip
                              prevStep={prevStep}
                              nextStep={() => nextStep(6)}
                              setDone={setDone}
                              app_classes={app_classes}
                            />
                          </>
                        )}
                      </Box>
                    </>
                  )}
                  {step == 6 && (
                    <>
                      <Box
                        style={{ borderRadius: "20px" }}
                        className={`${app_classes.backgroundColor.bg_white} ${
                          app_classes.margin.y5
                        } ${
                          width < 640
                            ? app_classes.padding.a5
                            : app_classes.padding.a10
                        }`}
                      >
                        <Box className={`${app_classes.margin.b5}`}>
                          <JobluTypography.H4
                            className={`${app_classes.text.warning}`}
                          >
                            {isParseSuccess ? "Step 6 of 7" : "Step 5 of 6"}
                          </JobluTypography.H4>
                        </Box>
                        <Box
                          className={`${app_classes.margin.b3} ${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between}`}
                        >
                          <Box>
                            <Box>
                              <JobluTypography.H4 primary>
                                Education
                              </JobluTypography.H4>
                            </Box>
                            <Box>
                              <JobluTypography.H6 primary>
                                Education is key to success. Share your academic
                                achievements with us and make your profile
                                shine.
                              </JobluTypography.H6>
                            </Box>
                          </Box>
                          <Box>
                            <img
                              src={PlusIcon}
                              width={24}
                              className={`${app_classes.cursor.pointer}`}
                              onClick={() => {
                                handleOpenEducation("Add");
                              }}
                            />
                          </Box>
                        </Box>
                        {current_user?.educationHistory?.length > 0 ? (
                          <>
                            {current_user?.educationHistory?.length > 0 &&
                              current_user?.educationHistory
                                ?.sort(function (a, b) {
                                  return (
                                    new Date(b.educStartDate) -
                                    new Date(a.educStartDate)
                                  );
                                })
                                ?.map((educ, educ_index) => {
                                  return (
                                    <Box
                                      className={`${
                                        current_user?.educationHistory?.length >
                                          1 && app_classes.margin.b3
                                      } ${app_classes.display.display_flex} ${
                                        app_classes.flexBox
                                          .justify_content_between
                                      } ${
                                        app_classes.backgroundColor.bg_light_2
                                      }  ${app_classes.padding.a3}`}
                                    >
                                      <Box>
                                        <Box>
                                          <JobluTypography.Body>
                                            <strong>{educ?.educName}</strong> |{" "}
                                            {formatDateRange2(
                                              educ?.educStartDate,
                                              educ?.educEndDate
                                            )}
                                          </JobluTypography.Body>
                                        </Box>
                                        <Box>
                                          <JobluTypography.Body>
                                            {educ?.educationType}
                                          </JobluTypography.Body>
                                        </Box>
                                        <Box>
                                          <JobluTypography.Body>
                                            {educ?.educField}
                                          </JobluTypography.Body>
                                        </Box>
                                        {educ?.educAchievements && (
                                          <>
                                            <Box
                                              className={`${app_classes.margin.t2}`}
                                            >
                                              <JobluTypography.Body>
                                                Achievements
                                              </JobluTypography.Body>
                                            </Box>
                                            <Box>
                                              <JobluTypography.Body
                                                style={{
                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                {educ?.educAchievements}
                                              </JobluTypography.Body>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                      <Box
                                        className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.flexBox.flex_direction_column}`}
                                      >
                                        <Box>
                                          <img
                                            src={EditIcon}
                                            width={16}
                                            onClick={() => {
                                              handleOpenEducation("Edit");
                                              setTempEducation({
                                                ...educ,
                                                index: educ_index,
                                              });
                                            }}
                                            className={`${app_classes.cursor.pointer}`}
                                          />
                                        </Box>
                                        <Box>
                                          <img
                                            src={DeleteIconRed}
                                            width={16}
                                            onClick={() => {
                                              let educs =
                                                current_user?.educationHistory;
                                              educs.splice(educ_index, 1);

                                              setCurrentUser({
                                                ...current_user,
                                                educationHistory: educs,
                                              });
                                            }}
                                            className={`${app_classes.cursor.pointer}`}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  );
                                })}
                          </>
                        ) : (
                          <>
                            <Box
                              className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_between} ${app_classes.backgroundColor.bg_light_2} ${app_classes.padding.a3}`}
                            >
                              <JobluTypography.Caption>
                                No education information provided
                              </JobluTypography.Caption>
                            </Box>
                          </>
                        )}

                        {step !== null && step >= 2 && step <= 9 && (
                          <>
                            <PageControl
                              step={step}
                              prevStep={prevStep}
                              nextStep={() => nextStep(7)}
                              setDone={setDone}
                              app_classes={app_classes}
                            />
                          </>
                        )}
                      </Box>
                    </>
                  )}
                  {step == 7 && (
                    <>
                      <Box
                        style={{ borderRadius: "20px" }}
                        className={`${app_classes.backgroundColor.bg_white} ${
                          app_classes.margin.y5
                        } ${
                          width < 640
                            ? app_classes.padding.a5
                            : app_classes.padding.a10
                        }`}
                      >
                        <Box className={`${app_classes.margin.b5}`}>
                          <JobluTypography.H4
                            className={`${app_classes.text.warning}`}
                            onClick={() => {
                              console.log(current_user);
                            }}
                          >
                            We like you just the way you are
                          </JobluTypography.H4>
                        </Box>
                        <Box className={`${app_classes.margin.b2}`}>
                          <JobluTypography.H6 primary>
                            At Joblu, we believe in promoting diversity, equity,
                            and inclusion in the workplace. Let us help you find
                            a job that aligns with your values.
                          </JobluTypography.H6>
                        </Box>
                        <Box className={`${app_classes.margin.b1}`}>
                          <JobluTypography.H4 primary>
                            Equal Opportunities
                          </JobluTypography.H4>
                        </Box>
                        <Box>
                          <JobluTypography.H6 primary>
                            Pronouns
                          </JobluTypography.H6>
                        </Box>
                        <Box>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                select
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => {
                                  setCurrentUser({
                                    ...current_user,
                                    pronoun: e.target.value,
                                  });
                                }}
                                value={current_user?.pronoun}
                              >
                                {pronouns?.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={!current_user?.showPronouns}
                                      name="checkedB"
                                      onChange={(e) => {
                                        setCurrentUser({
                                          ...current_user,
                                          showPronouns:
                                            !current_user?.showPronouns,
                                        });
                                      }}
                                    />
                                  }
                                  label={
                                    <JobluTypography.Body>
                                      Don’t display on my profile
                                    </JobluTypography.Body>
                                  }
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box className={`${app_classes.margin.t3}`}>
                          <JobluTypography.H6 primary>
                            Do you have any disabilities?
                          </JobluTypography.H6>
                        </Box>
                        <Box>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="position"
                              name="position"
                            >
                              <FormControlLabel
                                checked={current_user?.showDisabilities}
                                onClick={(e) => {
                                  setCurrentUser({
                                    ...current_user,
                                    showDisabilities: true,
                                  });
                                  console.log(current_user);
                                }}
                                value="Yes, I have (or previously had) a disability"
                                control={<Radio />}
                                label={
                                  <JobluTypography.Body>
                                    Yes, I have (or previously had) a disability
                                  </JobluTypography.Body>
                                }
                              />
                              <FormControlLabel
                                checked={!current_user?.showDisabilities}
                                onClick={(e) => {
                                  setCurrentUser({
                                    ...current_user,
                                    showDisabilities: false,
                                  });
                                  console.log(current_user);
                                }}
                                value="No, or prefer not to say"
                                control={<Radio />}
                                label={
                                  <JobluTypography.Body>
                                    No, or prefer not to say
                                  </JobluTypography.Body>
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        {step !== null && step >= 2 && step <= 9 && (
                          <>
                            <PageControl
                              step={step}
                              prevStep={prevStep}
                              nextStep={() => nextStep(0)}
                              setDone={setDone}
                              handleSaveProfile={handleSaveProfile}
                              app_classes={app_classes}
                            />
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            style={{ minHeight: "100vh", backgroundColor: "#E4ECF3" }}
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center} ${app_classes.flexBox.align_items_center}`}
          >
            <Grid container justifyContent="center">
              <Grid
                item
                xs={11}
                sm={8}
                style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
                className={`${
                  width < 640 ? app_classes.padding.a5 : app_classes.padding.a10
                } `}
              >
                <Box
                  className={`${app_classes.text.center} ${app_classes.margin.b5}`}
                >
                  <JobluTypography.H2 primary semiBold>
                    Oops, sorry...
                  </JobluTypography.H2>
                </Box>
                <Box
                  className={`${app_classes.text.center}  ${app_classes.margin.b3}`}
                >
                  <img src={ErrorImg} />
                </Box>
                {errorType == "linkedin" && (
                  <>
                    <Box
                      className={`${app_classes.text.center}  ${app_classes.margin.b3}`}
                    >
                      <JobluTypography.H5 primary>
                        It appears that your LinkedIn profile has insufficient
                        information.
                      </JobluTypography.H5>
                    </Box>
                    <Box
                      className={`${app_classes.text.center}  ${app_classes.margin.b3}`}
                    >
                      <JobluTypography.H5 primary>
                        We're as bummed as you are, but don't give up just yet!
                        You can still create a Joblu profile by uploading your
                        CV or the old-fashioned way.
                      </JobluTypography.H5>
                    </Box>
                    <Box
                      className={`${app_classes.text.center}  ${app_classes.margin.b3}`}
                    >
                      <JobluTypography.H5 primary>
                        It may take a few more clicks, but we promise it'll be
                        worth it.
                      </JobluTypography.H5>
                    </Box>
                  </>
                )}
                {errorType == "resume" && (
                  <>
                    <Box
                      className={`${app_classes.text.center}  ${app_classes.margin.b3}`}
                    >
                      <JobluTypography.H5 primary>
                        Looks like what you've uploaded is trying to tell us a
                        secret code that our system can't quite crack. Try
                        checking your internet connection or uploading your CV
                        in a different format. If all else fails, give it
                        another go in a few minutes.
                      </JobluTypography.H5>
                    </Box>
                    <Box
                      className={`${app_classes.text.center}  ${app_classes.margin.b3}`}
                    >
                      <JobluTypography.H5 primary>
                        If all else fails, give it another go in a few minutes.
                      </JobluTypography.H5>
                    </Box>
                  </>
                )}
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={3}>
                    {errorType == "linkedin" && (
                      <>
                        <Button
                          variant="outlined"
                          fullWidth
                          color="primary"
                          style={{ textTransform: "none" }}
                          onClick={() => {
                            setError(false);
                            setShowSelectModal(false);
                            setShowLinkedInModal(true);
                            setShowUploadCV(false);
                          }}
                        >
                          Re-enter Linkedin Profile URL
                        </Button>
                      </>
                    )}
                    {errorType == "resume" && (
                      <>
                        <Button
                          variant="outlined"
                          fullWidth
                          color="primary"
                          style={{ textTransform: "none" }}
                          onClick={() => {
                            setError(false);
                            setShowSelectModal(false);
                            setShowUploadCV(true);
                          }}
                        >
                          Re-upload CV
                        </Button>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {errorType == "linkedin" && (
                      <>
                        <Button
                          variant="outlined"
                          fullWidth
                          color="primary"
                          style={{ textTransform: "none" }}
                          onClick={() => {
                            setError(false);
                            setShowSelectModal(false);
                            setShowUploadCV(true);
                            setShowLinkedInModal(false);
                          }}
                        >
                          Upload CV
                        </Button>
                      </>
                    )}
                    {errorType == "resume" && (
                      <>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setError(false);
                            setShowSelectModal(false);
                            setShowLinkedInModal(true);
                            setShowUploadCV(false);
                          }}
                          fullWidth
                          color="primary"
                          style={{ textTransform: "none" }}
                        >
                          Enter LinkedIn Profile URL
                        </Button>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        setStep(2);
                      }}
                    >
                      Build profile manually
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  getApplicantCompletion: userActions.getApplicantCompletion,
  getUser: userActions.getUser,
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
    currency: state.Generals.currency,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfile);
