import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import TextField from '../../../components/inputs/CustomTextField'
import Loading from '../../../components/reusables/Loading'
import { validateEmail } from '../../../utils/validators'
import ExploreModal from '../../explore/components/ExploreModal'
import { userRequest } from '../../../service/requests'

const ForgotPassword = (props) => {

    const { navigate } = props

    const [email, setEmail] = useState('');
    const [error_Email, setError_Email] = useState(null)

    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)

    const [isResetting, setIsResetting] = useState(false);
    const [isResendingVerification, setIsResendingVerification] = useState(false)


    const resetPassword = () => {
        const isEmailError = email === undefined || email === null || email === ''
    
        if(isEmailError){
          setError_Email('Email is required')
          return
        }
    
    
        if(!validateEmail(email)){
          setError_Email('Invalid email address')
          return
        }
        else{
          setError_Email(null)
        }
    
        setIsResetting(true);
        userRequest
          .resetPassword({
            email: email,
          })
          .then((response) => {
            setModalOpen(true)
            setModalVariant('passwordResetModal')
            console.log(response.data)
            setIsResetting(false);
            setEmail('');
          })
          .catch((err) => {
            setIsResetting(false);
            console.log(err.response.data.message)
            if(err.response.data.message === '404001: User not found'){
              setError_Email('User not found')
            }
          });
      };

    const handleConfirmResetPassword = () => {
        navigate('/login')
    }
    

    return (
        <div className="card login-card">
            <div className="card-body text-center">
                <h4 className="card-title text-center mb-4">
                    Forgot your password?
                </h4>
                <p className="card-text mb-2 text-center">
                    Enter your email and we'll send you <br/> instructions to reset
                    your password.
                </p>
                <div className="form-group mt-3">
                    <TextField
                        type="email"
                        required
                        name="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="mb-2"
                        value={email}
                    />
                </div>
                <button
                    className="btn btn-primary mt-3"
                    style={{ width: 200 }}
                    onClick={() => resetPassword()}
                >
                    Send reset link
                </button>
                <p className="text-center mt-3">
                    <a href="/" className="text-primary">
                        <u>Back to Login</u>
                    </a>
                </p>
            </div>
            <Modal
                show={isResetting}
                centered
                contentClassName="bg-transparent border-0"
            >
                <Loading show color="primary" />
            </Modal>
            <ExploreModal
                showModal={modalOpen}
                variant={modalVariant}
                setModalVariant={setModalVariant}
                setShowModal={setModalOpen}
                handleConfirmResetPassword={handleConfirmResetPassword}
            />
        </div>
    )
}

export default ForgotPassword
