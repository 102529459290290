import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useGeoLocation from "react-ipgeolocation";
import { useNavigate } from "react-router-dom";
import checkIcon from "../../assets/img/pricing-page/check.svg";
import pricing_poster from "../../assets/img/pricing-page/prcing-poster.png";
import { paymentRequests } from "../../service/requests";
import { STRIPE_BASE_URL, STRIPE_SECRET } from "../../utils/constants";
import { thousandSeparator } from "../../utils/helpers";
import Loading from "../reusables/Loading";
import { pricing_list } from "./prcing-list";
import "./styles.css";

const price_buttons = {
  monthly12: {
    label: (price, curr) => (
      <div>
        {curr} {price} for 1 year billed monthly
      </div>
    ),
  },
  monthly6: {
    label: (price, curr) => (
      <div className="text-light">
        {curr} {price} for 6 months billed monthly{" "}
        <span className="font-accent-color">(Save 50%)</span>
      </div>
    ),
    best: true,
    badge: <div className="price-badge p-badge-gold">Best Value!</div>,
  },
  monthly: {
    label: (price, curr) => (
      <div>
        {curr} {price} - Monthly
      </div>
    ),
    badge: <div className="price-badge p-badge-blue">Special Offer</div>,
  },
};

export const SubscriptionButtons = () => {
  const navigate = useNavigate();
  const [subscriptionList, setList] = useState({});
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const geolocation = useGeoLocation();

  const getPricing = () => {
    paymentRequests
      .getPlans()
      .then((res) => {
        setList(_.reverse(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPricing();
  }, []);

  useEffect(() => {
    if (subscriptionList && Object.keys(subscriptionList).length > 0) {
      Object.keys(subscriptionList).forEach(async (key, index) => {
        await axios
          .get(`${STRIPE_BASE_URL}prices/${subscriptionList[key]}`, {
            headers: {
              Authorization: "Bearer " + STRIPE_SECRET, //the token is a variable which holds the token
            },
          })
          .then((res) => {
            let price = prices;
            price.push(res.data);
            price = _.uniqBy(price, "id");
            setPrices([...price]);
          });
      });
    }
  }, [subscriptionList]);

  const handleSelectPlan = (plan) => {
    let curr = "php";
    if (geolocation === "PH") curr = "php";
    if (geolocation === "SG") curr = "sgd";
    if (geolocation === "US") curr = "usd";
    if (plan) {
      setLoading(true);
      const payload = {
        priceId: plan.id,
        currency: curr,
        amount: plan?.unit_amount,
      };
      paymentRequests
        .subscribeToPlan(payload)
        .then((res) => {
          navigate("/plan/checkout", {
            state: {
              intent: {
                ...res.data,
                plan: plan,
              },
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="row justify-content-center mx-0 mt-3">
        {Object.keys(subscriptionList).length > 0 &&
          Object.keys(subscriptionList)

            .reverse()
            .map((key) => {
              const price = _.find(prices, {
                id: subscriptionList[key],
              });
              let curr = "php";
              if (geolocation === "PH") curr = "php";
              if (geolocation === "SG") curr = "sgd";
              if (geolocation === "US") curr = "usd";

              let per_month;
              if (price?.recurring) {
                const { interval, interval_count } = price?.recurring;
                let months = interval_count;
                if (interval === "year") {
                  months = interval_count * 12;
                }

                per_month = price.unit_amount / 100 / months;
                per_month = per_month.toFixed(0);
                per_month = thousandSeparator(per_month);
              }

              const isBest = price_buttons[key]?.best;
              return (
                <div className="col-12 col-md-4 my-1 px-1">
                  <div className="position-relative h-100">
                    {price_buttons[key].badge}
                    <button
                      className={`btn w-100 h-100 text-center fs-12 ${
                        isBest ? "btn-primary" : "btn-outline-primary "
                      }`}
                      style={{ minWidth: 300 }}
                      onClick={() => {
                        handleSelectPlan(price);
                      }}
                    >
                      {price_buttons[key].label(per_month, curr.toUpperCase())}
                    </button>
                  </div>
                </div>
              );
            })}
      </div>
      <Modal show={loading} centered contentClassName="bg-transparent border-0">
        <Loading show color="primary" />
      </Modal>
    </>
  );
};
const Pricing = ({ show }) => {
  // useEffect(() => {
  //     console.log(prices)
  // }, [prices])

  const handleCancel = () => {
    // console.log('closing')
    // setPaymentIntent(null)
  };

  return (
    <div className="bg-pricing" style={{ marginTop: -10 }}>
      <div className="row p-md-5 mt-2 justify-content-center mx-0">
        <div className="d-none d-sm-block col-md-6">
          <div className=" h-100 d-flex">
            <img
              src={pricing_poster}
              alt=""
              style={{
                width: "543px",
                height: "600px",
                objectFit: "contain",
              }}
              className="mx-auto mt-5"
            />
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="prices-paper px-4 py-5 my-2" id="price-container">
            <p className="poppins" style={{ color: "#5D656D" }}>
              Save thousands in recruitment cost and source the right candidate{" "}
              <br /> the fast, fun, and fabulous way!
            </p>

            {pricing_list.map((feat) => {
              return (
                <div
                  className={`row mx-0 feature-container p-2 mt-2 ${
                    feat?.border_gold && "border-gold"
                  }`}
                >
                  <div className="col-9">
                    <span
                      style={{
                        fontSize: 18,
                        marginRight: 5,
                      }}
                    >
                      {feat.icon}
                    </span>
                    {feat.label}
                  </div>

                  <div className="col-3 text-center">
                    <img src={checkIcon} alt="" />
                  </div>
                </div>
              );
            })}
            <hr />
            <SubscriptionButtons />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
