export const baseURL = process.env.REACT_APP_BASE_URL;
export const SECRET = process.env.REACT_APP_SECRET;
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
export const STRIPE_SECRET = process.env.REACT_APP_STRIPE_SECRET;
export const STRIPE_BASE_URL = "https://api.stripe.com/v1/";
export const BEARER = "Bearer ";
export const CONSTANT = "CONSTANT";
export const API_KEY = "1d049c1815e9467c98c8d2606350ba4b";
export const APP_VERSION = "4.2.0";
export const GOOGLE_PLAY_VERSION = "32.0";
export const APPSTORE_ID = "1568716553";
export const APPSTORE_PACKAGE_NAME = "com.joblu";
export const CURRENT_VERSION_DATE = "11/23/2021";

export const PAYPAL_CLIENT_TOKEN = "TOKEN_HERE";
export const PAYPAL_SANDBOX_TOKEN = "sandbox_d5n52bpx_4cyxgbvnmwndh94t";
export const STRIPE_CLIENT_TOKEN =
  "pk_live_51IuaLpLOeMtmhM3P9jw70UP6V7UFw9YO4znnQctwJOUjhoFfXuOKxea8rfM5cAPov7EWA8eLrrc094dv2QfOLrsu007FVVZOBd";

export const GUEST = {
  email: "guest.joblu@gmail.com",
  username: "guestJ0blU",
  password: "J0blu2021_guest",
};

export const paymentPrices = {
  priorityApplicant: 500,
  preferredApplicant1: 10,
  preferredApplicant2: 100,
};
export const paymentTypes = {
  AGENCY_FEE: "agency_fee",
  PRIORITY_APPLICANT_FEE: "priority_applicant",
  PREFERRED_APPLICANT_DETAILED_REPORT_FEE:
    "preferred_applicant_detailed_report_fee",
  PREFERRED_APPLICANT_ELIGIBILITY_REPORT_FEE:
    "preferred_applicant_eligibility_report_fee",
  SUBSCRIPTION_FEE: "subscription_fee",
};
export const config = {
  PROFILE_COMPLETION: 48,
};

export const creds = {
  GOOGLE_CLIENT_ID:
    "779889662046-b4v5btkp3807a8qiak2v9dlst7aurs0g.apps.googleusercontent.com",
  GOOGLE_RECAPTCHA_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY
};

export const profilePlaceholder_Applicant =
  "https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/profile.png";
export const profilePlaceholder_Employer =
"https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/profile.png";

export const defaultSwipeActions = {
  right: "like",
  left: "not_interested",
  top: "superLike",
};
export const salaryRange = [
  { label: "Less than symbol_ 40,000", value: " <40000" },
];

export const images = {
  JOBLU_ICON: `${process.env.REACT_APP_S3_BUCKET}/joblu-icon.png`,
};

export const salaryRanges = {
  COMPETIIVE_SALARY: process.env.REACT_APP_SALARY_RANGE_EXTRA_1,
  INDUSTRY_STANDARD: process.env.REACT_APP_SALARY_RANGE_EXTRA_2,
};

export const premium_features = [
  "Unlimited profile views ",
  "Unlimited chat access",
  "Free Candidate recommendations",
  "Company Culture showcase",
  "Unlimited Likes",
  "3 super likes/day",
  "Featured Employer cards",
  "Who’s liked your job",
  "Stealth mode",
  "1 Job Fair every month",
  "Undo swipe",
  "Unlimited Job Posts",
];

export const COMPANY_CULTURE_LENGTH = 10;

export const JobApplication_Status = {
  SHORTLISTED: "shortlisted",
  HIRED: "hired",
  ARCHIVED: "archived",
  APPLIED: "applied",
  RECONSIDERED: "reconsidered",
};

export const errorSwipeActions = {
  LIKE_NOT_ALLOWED: process.env.REACT_APP_LIKE_NOT_ALLOWED,
  DAILY_LIKE_LIMIT_EXCEEDED: process.env.REACT_APP_DAILY_LIKE_LIMIT_EXCEEDED,
  MONTHLY_LIKE_LIMIT_EXCEEDED: process.env.REACT_APP_MONTHLY_LIKE_LIMIT_EXCEEDED,
  SUPER_LIKE_LIMIT_EXCEEDED: process.env.REACT_APP_SUPER_LIKE_LIMIT_EXCEEDED,
  JOB_POST_LIMIT_EXCEEDED: process.env.REACT_APP_JOB_POST_LIMIT_EXCEEDED,
};
