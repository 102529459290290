import { makeStyles } from "@material-ui/core/styles";

export const card_styles = makeStyles((theme) => ({
  job_image: {
    width: "88px",
    height: "88px",
    borderRadius: "50%",
    border: "1px solid grey",
    objectFit: "fit",
  },
  card_container: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #C9CCCE",
    borderRadius: "5px",
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  action_chip: {
    backgroundColor: "#009CDE!important",
    color: "#FFFFFF!important",
    marginRight: "0.5rem!important",
    fontSize: 12,
    marginBottom: "0.5rem!important",
    fontFamily: "Open Sans!important",
  },
  action_chip_warning: {
    backgroundColor: "#D7A048!important",
    color: "#FFFFFF!important",
    fontSize: 12,
    marginBottom: "0.5rem!important",
    fontFamily: "Open Sans!important",
  },
  chip_outline: {
    borderColor: "#6E88A3",
    color: "#6E88A3",
    fontSize: 12,
  },
  chip_outline_seemore: {
    borderColor: "#27323C",
    color: "#27323C",
    fontSize: 12,
  },
}));
