import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import SelectCountry2 from '../../../../../components/reusables/SelectCountry2'
import TextField from '../../../../../components/inputs/CustomTextField'
import SelectJobFunction from '../../../../../components/reusables/SelectJobFunction'

const Filter = (props) => {
    const {
        show,
        setShow,
        handleClose,
        handleShow,
        checkbox_filters,
        filters,
        setFilters,
        handleFilter,
        handleSetWorkPref,
        handleWorkSetup,
        setJobTitleQuery,
        setSalaryMin,
        setSalaryMax,
        setSalaryCurrency,
        setYearsOfExp,
        currency,
        handleSalaryMin,
        handleSalaryMax,
        salaryMin,
        salaryMax,
    } = props

    const submitFilter = () => {
        handleFilter(null, false)
        handleClose()
    }
    return (
        <>
            <Modal show={show} centered onHide={handleClose}>
                <Modal.Body>
                    <div
                        className="col-sm-12 row justify-content-center py-2 p-0 mx-0"
                        style={{ background: '#fff' }}
                    >
                        <div className="col-sm-12 col-md-10 row mx-0 align-items-end">
                            <div className="col-sm-12 col-md-4 mb-3 mb-md-0">
                                <p>Choose an Industry*</p>
                                <SelectJobFunction
                                    value={filters?.jobFunction}
                                    onChange={(val) => {
                                        setFilters({
                                            ...filters,
                                            jobFunction: val,
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-3  mb-md-0">
                                <p>I’m looking for a/an*</p>
                                <TextField
                                    placeholder={`E.g. "Front-End Developer"`}
                                    onChange={(e) => {
                                        setJobTitleQuery(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="p-3 ">
                            <div className="mb-3">
                                <p className="text-primary">
                                    <strong>Work Preference</strong>
                                </p>
                                {/* WORK PREFERENCE */}
                                <div className="">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value="Full-Time"
                                            name="workPreference"
                                            id="Full-Time2"
                                            onChange={(e) => {
                                                handleSetWorkPref(e)
                                            }}
                                        />
                                        <label
                                            className="form-check-label open-sans ms-3"
                                            htmlFor="Full-Time2"
                                        >
                                            Full-Time
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value="Part-Time"
                                            name="workPreference"
                                            id="Part-Time2"
                                            onChange={(e) => {
                                                handleSetWorkPref(e)
                                            }}
                                        />
                                        <label
                                            className="form-check-label open-sans ms-3"
                                            htmlFor="Part-Time2"
                                        >
                                            Part-Time
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value="Project-Based"
                                            name="workPreference"
                                            id="Project-Based2"
                                            onChange={(e) => {
                                                handleSetWorkPref(e)
                                            }}
                                        />
                                        <label
                                            className="form-check-label open-sans ms-3"
                                            htmlFor="Project-Based2"
                                        >
                                            Project-Based
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value="Internship"
                                            name="workPreference"
                                            id="Internship2"
                                            onChange={(e) => {
                                                handleSetWorkPref(e)
                                            }}
                                        />
                                        <label
                                            className="form-check-label open-sans ms-3"
                                            htmlFor="Internship2"
                                        >
                                            Internship
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <p className="text-primary">
                                    <strong>Work Setup</strong>
                                </p>
                                {/* WORK SETUP */}
                                <div className="">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value="On-Site"
                                            name="workSetup"
                                            id="On-Site2"
                                            onChange={(e) => {
                                                handleWorkSetup(e)
                                            }}
                                        />
                                        <label
                                            className="form-check-label open-sans ms-3"
                                            htmlFor="On-Site2"
                                        >
                                            On-Site
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value="Remote"
                                            name="workSetup"
                                            id="Remote2"
                                            onChange={(e) => {
                                                handleWorkSetup(e)
                                            }}
                                        />
                                        <label
                                            className="form-check-label open-sans ms-3"
                                            htmlFor="Remote2"
                                        >
                                            Remote
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value="Hybrid"
                                            name="workSetup"
                                            id="Hybrid2"
                                            onChange={(e) => {
                                                handleWorkSetup(e)
                                            }}
                                        />
                                        <label
                                            className="form-check-label open-sans ms-3"
                                            htmlFor="Hybrid2"
                                        >
                                            Hybrid
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-12">
                                        <p className="text-primary">
                                            <strong>Salary Range</strong>
                                        </p>
                                        <select
                                            name=""
                                            id=""
                                            onChange={(e) =>
                                                setSalaryCurrency(
                                                    e.target.value
                                                )
                                            }
                                            className="form-select mb-2"
                                        >
                                            <option
                                                value="PHP"
                                                selected={
                                                    currency == 'PHP'
                                                        ? true
                                                        : false
                                                }
                                            >
                                                PHP
                                            </option>
                                            <option
                                                value="SGD"
                                                selected={
                                                    currency == 'SGD'
                                                        ? true
                                                        : false
                                                }
                                            >
                                                SGD
                                            </option>
                                            <option
                                                value="USD"
                                                selected={
                                                    currency == 'USD'
                                                        ? true
                                                        : false
                                                }
                                            >
                                                USD
                                            </option>
                                        </select>
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={handleSalaryMin}
                                            placeholder="Min"
                                            value={salaryMin}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            placeholder="Max"
                                            className="form-control"
                                            onChange={handleSalaryMax}
                                            value={salaryMax}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <p className="text-primary">
                                    <strong>Experience Required</strong>
                                </p>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="yearsOfExp2"
                                        id="0-1v2"
                                        value="0-1"
                                        onChange={(e) => {
                                            setYearsOfExp(e.target.value)
                                        }}
                                    />
                                    <label class="form-check-label" for="0-1v2">
                                        0-1 Year
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="yearsOfExp2"
                                        id="1-3v2"
                                        value="1-3"
                                        onChange={(e) => {
                                            setYearsOfExp(e.target.value)
                                        }}
                                    />
                                    <label class="form-check-label" for="1-3v2">
                                        1-3 Years
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="yearsOfExp2"
                                        id="3-5v2"
                                        value="3-5"
                                        onChange={(e) => {
                                            setYearsOfExp(e.target.value)
                                        }}
                                    />
                                    <label class="form-check-label" for="3-5v2">
                                        3-5 Years
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="yearsOfExp2"
                                        id="5-7v2"
                                        value="5-7"
                                        onChange={(e) => {
                                            setYearsOfExp(e.target.value)
                                        }}
                                    />
                                    <label class="form-check-label" for="5-7v2">
                                        5-7 Years
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="yearsOfExp2"
                                        id="7v2"
                                        value="7-99"
                                        onChange={(e) => {
                                            setYearsOfExp(e.target.value)
                                        }}
                                    />
                                    <label class="form-check-label" for="7v2">
                                        7+ Years
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3">
                                <p className="text-primary">
                                    <strong>Country or Location</strong>
                                </p>
                                <SelectCountry2
                                    onChange={(value) => {
                                        setFilters({
                                            ...filters,
                                            location: value,
                                        })
                                    }}
                                    value={filters?.location}
                                    noCellCode
                                    grouped
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <Button
                            variant="primary"
                            onClick={() => {
                                submitFilter()
                            }}
                        >
                            Explore
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Filter
