import { makeStyles } from "@material-ui/core/styles";

export const notification_styles = makeStyles((theme) => ({
  default_img: {
    borderRadius: "50%",
    border: "1px solid #8080804D",
    objectFit: "cover",
    height: 54,
    width: 54,
  },
}));
