import { Box } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { JobluIconsV2 } from "../../assets";
import educationIcon from "../../assets/icons/icon-v2_education.svg";
import locationIcon from "../../assets/icons/icon-v2_location.svg";
import salaryIcon from "../../assets/icons/icon-v2_salary.svg";
import workHistoryIcon from "../../assets/icons/icon-v2_work history.svg";
import workShiftIcon from "../../assets/icons/icon-v2_work shift.svg";
import diamondIcon from "../../assets/icons/icon_diamond.svg";
import workSetupIcon from "../../assets/icons/workSetupIcon.svg";
import DefaultImg from "../../assets/img/defaultImg.png";
import TechnicalSkillsIcon from "../../assets/img/editProfile/technicalSkillsIcon.png";
import IndustryIcon from "../../assets/img/matches/industryIcon.png";
import NoMsgImg from "../../assets/img/noMsgImg.png";
import FlatList from "../../components/flatlist/FlatList";
import CustomChip from "../../components/reusables/Chip";
import EmptyState from "../../components/reusables/EmptyState";
import JobluTypography from "../../components/Typography";
import { db } from "../../firebase";
import appActions from "../../redux/app/actions";
import userAction from "../../redux/user/actions";
import { jobsRequest, userRequest } from "../../service/requests";
import {
  profilePlaceholder_Applicant,
  salaryRanges,
} from "../../utils/constants";
import { convertFirebaseDate, formatMoney } from "../../utils/helpers";
import useWindowDimensions from "../../utils/hooks";
import { ProfileModal } from "../explore/v2/components/ViewProfile/ProfileModal";
import "./styles.css";
const GroupedMessages = ({
  messages,
  scrollToMessage,
  loading,
  user,
  isLast,
  groupId,
  isToday,
}) => {
  return messages.map((msg, index) => {
    const elmID = `chat-${index}-${groupId}`;
    const isMe = msg.user._id === user.userId;

    if (messages?.length === index + 1) {
      scrollToMessage(elmID);
    }
    return (
      <>
        <div
          className={`mt-3
                ${isMe ? "justify-content-end" : ""}
                ${loading ? "d-none" : "d-flex"}
            `}
          id={elmID}
          key={elmID}
        >
          <div
            className={`convo ${loading ? "" : "convo-expand"} ${
              isMe ? "sent-by-me" : "not-me"
            }`}
          >
            <p
              className={`open-sans fs-12 m-0`}
              style={{ wordBreak: "break-all" }}
            >
              {msg.text}
            </p>
          </div>
        </div>
        <p className={`open-sans mt-0 ${isMe && "text-end"}`}>
          <span style={{ fontSize: 12 }}>
            {/* {isToday && moment(msg.createdAt)} */}
            {isToday && moment(msg.createdAt).format("LT")}
          </span>
        </p>
      </>
    );
  });
};
export const Convo = (props) => {
  const {
    room,
    isChatBox,
    loading,
    setLoading,
    user,
    setShowInfo,
    showInfo,
    setSelectedRoom,
    isMobile,
  } = props;
  const [message, setMessages] = useState([]);
  const [applicantInfo, setApplicantInfo] = useState(null);
  const [inputText, setInputText] = useState("");

  const scrollToMessage = (id) => {
    // const cht = document.getElementById(id)
    // cht?.scrollIntoView()
    //setLoading(false)
  };

  useEffect(() => {
    if (room?.latestMessage?.lastSender === "employer") {
      db.collection("rooms")
        .doc(room.jobId)
        .collection("applicants")
        .doc(room._id)
        .update({
          latestMessage: {
            createdAt: room.latestMessage.createdAt,
            text: room.latestMessage.text,
            isRead: true,
          },
        });
    }
    const messagesListener = db
      .collection("messages")
      .doc(room.jobId)
      .collection("applicants")
      .doc(user.userId)
      .collection("threads")
      .orderBy("createdAt", "asc")
      .onSnapshot((querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => {
          const firebaseData = doc.data();

          const data = {
            _id: doc.id,
            text: firebaseData.text,
            createdAt: convertFirebaseDate(firebaseData.createdAt),
          };

          if (!firebaseData.system) {
            data.user = {
              ...firebaseData.user,
              avatar:
                firebaseData.user._id === user.userId
                  ? user.profileUrl
                  : firebaseData.user._id === room.employerId
                  ? room.employer_profileUrl
                  : undefined,
            };
          }
          return data;
        });
        const grouped = _.groupBy(messages, (obj) => {
          return moment(obj.createdAt).format("ddd MMM DD, YYYY");
        });
        setMessages(grouped);
      });

    // Stop listening for updates whenever the component unmount
    return () => messagesListener();
  }, [room]);

  const handleSend = async () => {
    const text = inputText;
    if (!text) {
      return;
    }
    const senderDetails = {
      _id: user.userId,
      email: user.email,
    };

    senderDetails.name = `${user.firstName} ${user.lastName}`;

    const messageDetails = {
      text,
      createdAt: new Date(),
      user: senderDetails,
    };

    await db
      .collection("messages")
      .doc(room.jobId)
      .collection("applicants")
      .doc(user.userId)
      .collection("threads")
      .add(messageDetails);

    await db
      .collection("rooms")
      .doc(room.jobId)
      .collection("applicants")
      .doc(user.userId)
      .set(
        {
          latestMessage: {
            text,
            createdAt: new Date(),
            isRead: false,
            lastSender: "applicant",
          },
        },
        { merge: true }
      );
    setSelectedRoom({
      ...room,
      latestMessage: {
        text,
        createdAt: new Date(),
        isRead: false,
        lastSender: "applicant",
      },
    });
    // console.log(chatRoomInfo)
    userRequest
      .sendNotification({
        title: `${user.firstName} ${user.lastName}`,
        content: text,
        receiverId: room.employerId,
        senderId: user.userId,
        type: "message",
        data: room,
      })
      .then((response) => {
        // console.log(response.data)
      });
    setInputText("");
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handleSend();
      }
    };
    document.addEventListener("keyup", listener);
    return () => {
      document.removeEventListener("keyup", listener);
    };
  }, [handleSend]);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      const objDiv = document.getElementById("convo-scroll");
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 1000);
  }, []);

  return (
    <div
      id="convoId"
      className="col border px-0 d-flex flex-column justify-content-around mx-0 position-relative"
    >
      {/* <EmptyState
                emptyImg={NoMsgImg}
                title={'See your conversations here.'}
            /> */}
      <div
        className={`d-flex p-1 align-items-center border-bottom mt-0 ${
          isMobile && "sticky-top bg-light"
        }`}
      >
        {isMobile && (
          <p className="me-2 h4" onClick={() => setSelectedRoom(null)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
          </p>
        )}
        <img
          style={{
            width: 50,
            height: 50,
            borderRadius: 5,
          }}
          src={room?.employer_profileUrl || profilePlaceholder_Applicant}
          alt=""
          className="m-auto"
        />
        <div className="w-100 px-3 d-flex">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between w-100">
              <p className="open-sans semi-bold-600 primary my-0">
                {room?.employerName}
              </p>
            </div>
            <div>
              <p className="open-sans my-0" style={{ fontSize: 12 }}>
                {room?.roomName}
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex px-2">
          <img
            style={{
              width: 25,
              height: 25,
              // borderRadius: 5,
            }}
            src={JobluIconsV2.toggleMenu}
            alt=""
            className="m-auto"
            role="button"
            onClick={() => setShowInfo(!showInfo)}
          />
        </div>
        {/* <div className="py-1 px-3 border-bottom">
                <button className="btn p-0 fs-12">
                    Review Job Post
                </button>
            </div> */}
      </div>
      <div className="d-flex flex-column justify-content-end h-100">
        {/* CONVERSATION CONTAINER */}
        <div
          className="flex-fill justify-content-end px-3 pb-4"
          style={{
            overflowY: "scroll",
            maxHeight: isChatBox ? 300 : 635,
            paddingTop: 15,
            paddingBottom: 15,
          }}
          id="convo-scroll"
        >
          {/* <div style={{ height: '60vh' }}>
                <Loading show={loading} />
            </div> */}

          {Object.keys(message).map((key, index) => {
            const isLast = message.length - 1 === index;
            const groupId = key.trim();
            const isToday =
              moment(key).format("DD/MM/YYY") === moment().format("DD/MM/YYY");
            return (
              <div key={key} className="mt-5">
                <p className="text-center">{isToday ? "Today" : key}</p>
                <GroupedMessages
                  messages={message[key]}
                  scrollToMessage={scrollToMessage}
                  loading={loading}
                  user={user}
                  isLast={isLast}
                  groupId={groupId}
                  isToday={isToday}
                />
              </div>
            );
          })}
        </div>
        {/* END CONVERSATION CONTAINER */}

        {/* BOTTOM ACTIONS */}
        <div
          className={`border-top d-flex p-2 w-100 ${
            isMobile && "position-fixed bottom-0"
          }`}
        >
          <input
            type="text"
            className="form-control flex-fill open-sans"
            id="inputPassword2"
            placeholder="Type here..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
          <button
            onClick={() => handleSend()}
            className="btn btn-primary open-sans"
          >
            Send
          </button>
        </div>
        {/* END BOTTOM ACTIONS */}
      </div>
    </div>
  );
};

const Messages = (props) => {
  const { User, setChatRooms, setShowNav, AppState } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { chatRooms } = User;
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(true);

  const [limitRoom, setLimitRoom] = useState(10);
  const [rooms, setRooms] = useState([]);
  const [totalRooms, setTotalRooms] = useState(chatRooms?.length || 9999);

  const [searchRoom, setSearchRoom] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);

  const [showProfile, setShowProfile] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setRooms(chatRooms?.filter((item, index) => index < limitRoom));
  }, [chatRooms]);

  useEffect(() => {
    let threadId = document.getElementById("threadId");
    let convoId = document.getElementById("convoId");

    if (isMobile) {
      if (showInfo) {
        convoId?.classList?.add("d-none");
        threadId?.classList?.add("d-none");
      } else {
        convoId?.classList?.remove("d-none");
        if (selectedRoom) {
          threadId?.classList?.add("d-none");
        } else if (!selectedRoom) {
          threadId?.classList?.remove("d-none");
        }
      }
    }
    // else {
    //     threadId?.classList?.remove('d-none')
    // }
  }, [selectedRoom, isMobile, selectedUser, showInfo]);

  const { currencyList, currency } = AppState;
  const currencyOptions = {
    currencyList,
    currency,
  };

  useEffect(() => {
    isMobile && !!selectedRoom ? setShowNav(false) : setShowNav(true);
  }, [isMobile, selectedRoom]);

  useEffect(() => {
    if (width < 640) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFilterFunction(searchRoom);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchRoom]);

  useEffect(() => {
    const data = location?.state?.data;
    console.log(data);
    if (data) {
      const { jobId } = data;
      const chatRoom = _.find(chatRooms, {
        jobId,
      });
      if (chatRoom) setSelectedRoom(chatRoom);
    }
    console.log(data);
  }, [location]);

  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (searchRoom) {
      const newData = chatRooms.filter(function (item) {
        const jobTitleData = item?.roomName?.toUpperCase();
        const itemData = item.employerName
          ? item.employerName.toUpperCase()
          : "".toUpperCase();
        const textData = searchRoom.toUpperCase();

        return (
          itemData.indexOf(textData) > -1 || jobTitleData.indexOf(textData) > -1
        );
      });
      setTotalRooms(newData?.length);
      setRooms(newData?.filter((item, index) => index <= limitRoom));
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setLimitRoom(10);
      if (!selectedJob) {
        setTotalRooms(chatRooms?.length);
        setRooms(chatRooms?.filter((item, index) => index <= limitRoom));
      } else {
        const newData = chatRooms?.filter(
          (item) =>
            item?.roomName?.toUpperCase() === selectedJob?.name?.toUpperCase()
        );
        setTotalRooms(newData?.length);
        setRooms(newData?.filter((item, index) => index <= limitRoom));
      }
    }
  };

  const renderItem = (room, index) => {
    const isSelected = selectedRoom === room;
    let storedDate;
    let dateTimeAgo;
    try {
      dateTimeAgo = moment(
        convertFirebaseDate(room?.latestMessage?.createdAt)
      ).fromNow();
    } catch (e) {
      console.log(e);
      console.log("error computing timestamp");
    }
    return (
      <div
        className={`d-flex mb-2 border-bottom pb-2 px-1 ${
          room?.latestMessage.lastSender !== "employer"
            ? !room.latestMessage.isRead
              ? "bold"
              : null
            : null
        }`}
        key={index}
        role="button"
        style={{
          background: isSelected ? "#f2f2f2" : "",
        }}
        onClick={() => setSelectedRoom(room)}
      >
        <div className="d-flex">
          <img
            style={{
              width: 50,
              height: 50,
              borderRadius: 5,
            }}
            src={room?.employer_profileUrl || profilePlaceholder_Applicant}
            alt=""
            className="m-auto"
          />
        </div>
        <div className="w-100 h-100 ps-1 py-1">
          <div className="d-flex justify-content-between w-100 mt-1 my-0 pe-1">
            <span
              className="open-sans semi-bold-600 primary my-0"
              style={{ fontSize: 14 }}
            >
              {room?.employerName}
            </span>
            <span
              className={`open-sans-body my-0 ${
                room?.latestMessage.lastSender !== "applicant"
                  ? !room.latestMessage.isRead
                    ? "semi-bold-600 text-black"
                    : "text-muted"
                  : null
              }`}
              style={{ fontSize: 12 }}
            >
              {dateTimeAgo}
            </span>
          </div>
          <div
            className={`py-0 d-flex justify-content-between ${
              room?.latestMessage.lastSender !== "applicant"
                ? !room.latestMessage.isRead
                  ? "semi-bold-600"
                  : null
                : null
            }`}
          >
            <span className="open-sans my-0 py-0" style={{ fontSize: 13 }}>
              {room?.roomName}
            </span>
            {room?.latestMessage.lastSender !== "applicant" ? (
              !room.latestMessage.isRead ? (
                <span
                  className="bg-warning mt-2"
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 100,
                  }}
                ></span>
              ) : null
            ) : null}
          </div>
          <div className="w-100">
            <div
              className={`open-sans my-0  ${
                room?.latestMessage.lastSender !== "applicant"
                  ? !room.latestMessage.isRead
                    ? "semi-bold-600 text-black"
                    : "text-muted"
                  : null
              }`}
              style={{
                fontSize: 10,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "wrap",
              }}
            >
              {room?.latestMessage?.text.length > 50
                ? `${room?.latestMessage?.text.substring(0, 50)}...`
                : room?.latestMessage?.text}
            </div>
            {/* {room?.latestMessage.lastSender !== 'employer' ? (
                            !room.latestMessage.isRead ? (
                                <span>UNREAD</span>
                            ) : null
                        ) : null} */}
          </div>
        </div>
      </div>
    );
  };

  const getMoreRooms = () => {
    const newLimit = limitRoom + 10;
    setLimitRoom(newLimit);
    if (!selectedJob) {
      setRooms(chatRooms?.filter((item, index) => index < newLimit));
    } else {
      if (searchRoom) searchFilterFunction();
      else {
        const text = selectedJob?.name;
        const newData = chatRooms.filter(function (item) {
          const jobTitleData = item?.roomName?.toUpperCase();
          const textData = text.toUpperCase();
          // console.log(itemData,textData)
          return jobTitleData === textData;
        });
        setTotalRooms(newData?.length);
        setRooms(newData?.filter((item, index) => index <= newLimit));
      }
    }
  };

  const [employerDetails, setEmployerDetails] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  useEffect(() => {
    try {
      userRequest.getApplicantDetails(selectedRoom.applicantId).then((res) => {
        if (res) {
          const applicant = res?.data?.applicantProfiles;
          // console.log(applicant);
          setSelectedUser(applicant);
        }
      });

      userRequest.getEmployerDetailsP(selectedRoom?.employerId).then((res) => {
        console.log("employer", res?.data);
        setEmployerDetails(res?.data?.employerProfiles);
      });

      jobsRequest.getJobDetails(selectedRoom?.jobId).then((res) => {
        console.log("job", res?.data);
        setJobDetails(res?.data);
      });

      console.log(selectedRoom);
      //   const payload = {
      //     jobId: selectedRoom?.jobId,
      //   };
      //   jobsRequest.getSpecificJob(payload).then((res) => {
      //     console.log(res);
      //   });
    } catch (err) {
      console.log(err);
    }
  }, [selectedRoom]);

  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleCloseProfileModal = () => setShowProfileModal(false);
  const handleShowProfileModal = () => setShowProfileModal(true);

  const handleViewProfile = () => {
    handleShowProfileModal();
    // console.log('view')
    // setShowProfile(!showProfile)
    //
  };
  return (
    <div className="row justify-content-center mx-0" id="message-container">
      {/* <Profile
                selectedProfile={selectedUser}
                showProfile={showProfile}
                setShowProfile={setShowProfile}
                setShowInfo={setShowInfo}
            /> */}
      <ProfileModal
        showProfileModal={showProfileModal}
        setShowProfileModal={setShowProfileModal}
        handleCloseProfileModal={handleCloseProfileModal}
        handleShowProfileModal={handleShowProfileModal}
        seeker={selectedUser}
      />

      <div
        className="col-12 col-sm-11 row mt-sm-3 mx-0 px-0 border mb-5"
        style={{ borderRadius: 5, minHeight: 750 }}
      >
        <div
          id="threadId"
          className="convo-container col-md-3 col-sm-12 border px-0 mx-0"
        >
          <div className="px-2">
            <div className="input-group my-2">
              {/* <span className="input-group-text" id="basic-addon1">
                            @
                        </span> */}
              <input
                type="text"
                className="form-control px-1 open-sans"
                placeholder="Search message"
                aria-label="location"
                aria-describedby="basic-addon1"
                onChange={(e) => setSearchRoom(e?.target.value)}
              />
            </div>
          </div>
          <div className="w-100 mt-3">
            <FlatList
              renderItem={renderItem}
              data={rooms || []}
              list="message"
              // loadMoreItems={() => setLimitRoom(limitRoom + 10)}
              // hasMoreItems={() => false}
            />
            {rooms?.length < totalRooms && (
              <div className="text-center">
                <button
                  className="btn btn-primary my-3 text-center"
                  onClick={() => getMoreRooms()}
                >
                  {/* {loadingLikedCandidates && (
                                     <span
                                         className="spinner-border spinner-border-sm me-2"
                                         role="status"
                                         aria-hidden="true"
                                     ></span>
                                 )} */}
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>

        {chatRooms?.length === 0 && !isMobile && (
          <div className="col">
            <EmptyState
              emptyImg={NoMsgImg}
              title={"See your conversations here."}
              link={"/dashboard"}
              linkType={"link"}
              linkTitle={"Browse jobs"}
            />
          </div>
        )}
        {!!chatRooms?.length && selectedRoom && (
          <Convo
            isMobile={isMobile}
            room={selectedRoom}
            loading={loading}
            setLoading={setLoading}
            user={User.user}
            setShowInfo={setShowInfo}
            showInfo={showInfo}
            setSelectedRoom={setSelectedRoom}
          />
        )}
        {!!chatRooms?.length && !selectedRoom && (
          <div className="col">
            <EmptyState
              emptyImg={NoMsgImg}
              title={"See your conversations here."}
              // link={'/dashboard'}
              // linkType={'link'}
              // linkTitle={'Find candidates'}
            />
          </div>
        )}

        <div
          className={`info-container col border-top border-bottom border-end ${
            showInfo ? "contact-info-expanded" : "contact-info-shrinked"
          }`}
          style={{
            maxWidth: !isMobile ? 300 : "100%",
            overflowY: "auto",
            maxHeight: 750,
          }}
        >
          <div style={{ width: "100%" }}>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <img
                  className={` my-auto contact-profile-img me-2 ${
                    showInfo ? "contact-profile-img-expand" : ""
                  }`}
                  src={
                    jobDetails?.companyLogo
                      ? jobDetails?.companyLogo
                      : employerDetails?.profileUrl
                      ? employerDetails?.profileUrl
                      : DefaultImg
                  }
                  alt=""
                  role="button"
                  style={{ objectFit: "cover", background: "#FFFFFF" }}
                  onClick={() => setShowInfo(!showInfo)}
                />
                <div>
                  <p className="open-sans mx-1">
                    <strong>{jobDetails?.jobTitle}</strong>
                  </p>
                  <p className="open-sans mx-1">
                    <strong>
                      <small>
                        {jobDetails?.companyName ||
                          employerDetails?.companyName}
                      </small>
                    </strong>
                  </p>
                  {/* <p className="open-sans mx-1">
                    <small>{selectedUser?.jobFunction}</small>
                  </p> */}
                </div>
              </div>

              <div>
                <img
                  style={{
                    width: 19,
                    height: 19,
                    borderRadius: 5,
                  }}
                  src={JobluIconsV2.closeIcon}
                  alt=""
                  role="button"
                  onClick={() => setShowInfo(!showInfo)}
                />
              </div>
            </div>
            <div className="my-1">
              <button
                className="text-center btn btn-outline-primary w-100 my-2"
                onClick={() => {
                  // handleViewProfile();
                  window?.open(`/company-showcase/${selectedRoom.employerId}`);
                }}
              >
                View Company Profile
              </button>
              <hr />
              <div className="py-1">
                {jobDetails?.location && (
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <div className="me-2 ">
                        <img src={locationIcon} alt="" />
                      </div>
                      <div>
                        <p className="open-sans-body mb-0">
                          {jobDetails?.location}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {jobDetails?.workSetup?.length > 0 && (
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <div className="me-2 ">
                        <img src={workSetupIcon} alt="" />
                      </div>
                      <div>
                        <p className="open-sans-body mb-0">
                          {jobDetails?.workSetup?.join(", ")}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {jobDetails?.employmentType?.length > 0 && (
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <div className="me-2 ">
                        <img src={workShiftIcon} alt="" />
                      </div>
                      <div>
                        <p className="open-sans-body mb-0">
                          {jobDetails?.employmentType?.join(", ")}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                {jobDetails?.education?.length > 0 && (
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <div className="me-2 ">
                        <img src={educationIcon} alt="" />
                      </div>
                      <div>
                        <p className="open-sans-body mb-0">
                          {jobDetails?.education?.join(", ")}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {jobDetails?.totalExperienceMin !== 0 && (
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <div className="me-2 ">
                        <img src={workHistoryIcon} alt="" />
                      </div>
                      <div>
                        <p className="open-sans-body mb-0">
                          {" "}
                          {jobDetails?.totalExperienceMin &&
                            jobDetails?.totalExperienceMin !== 0 &&
                            `${jobDetails?.totalExperienceMin}`}
                          {jobDetails?.totalExperienceMax == "100"
                            ? "+ Years"
                            : `- ${jobDetails?.totalExperienceMax} Years`}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {jobDetails?.salaryMin && jobDetails?.salaryMax && (
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <div className="me-2 ">
                        <img src={salaryIcon} alt="" />
                      </div>
                      <div>
                        <p className="open-sans-body mb-0">
                          {jobDetails?.salaryMin === 1 &&
                          jobDetails?.salaryMax === 1
                            ? salaryRanges.COMPETIIVE_SALARY
                            : jobDetails?.salaryMin === 2 &&
                              jobDetails?.salaryMax === 2
                            ? salaryRanges.INDUSTRY_STANDARD
                            : `${formatMoney(
                                jobDetails?.salaryMin,
                                currencyOptions,
                                jobDetails?.salaryCurrency || "SGD"
                              )} - ${formatMoney(
                                jobDetails?.salaryMax,
                                currencyOptions,
                                jobDetails?.salaryCurrency || "SGD"
                              )}`}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                {jobDetails?.jobFunction && (
                  <>
                    <div className="d-flex align-items-center mb-4">
                      <div className="me-2 ">
                        <img src={IndustryIcon} alt="" width={16} />
                      </div>
                      <div>
                        <p className="open-sans-body mb-0">
                          {" "}
                          {jobDetails?.jobFunction}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                {jobDetails?.perksAndBenefits?.length > 0 && (
                  <>
                    <div className="d-flex align-items-start mb-4">
                      <div className="me-2 ">
                        <img src={diamondIcon} alt="" width={16} />
                      </div>
                      <div>
                        <Box>
                          <JobluTypography.Body primary semiBold>
                            Perks and Benefits
                          </JobluTypography.Body>
                        </Box>
                        <Box>
                          <CustomChip data={jobDetails?.perksAndBenefits} />
                        </Box>
                      </div>
                    </div>
                  </>
                )}

                {jobDetails?.technicalSkills?.length > 0 && (
                  <>
                    <div className="d-flex align-items-start mb-4">
                      <div className="me-2 ">
                        <img src={TechnicalSkillsIcon} alt="" width={16} />
                      </div>
                      <div>
                        <Box>
                          <JobluTypography.Body primary semiBold>
                            Technical Skills
                          </JobluTypography.Body>
                        </Box>
                        <Box>
                          <CustomChip data={jobDetails?.technicalSkills} />
                        </Box>
                      </div>
                    </div>
                  </>
                )}

                {jobDetails?.responsibilities && (
                  <>
                    <div className="d-flex align-items-start mb-4">
                      <div className="me-2 ">
                        <img src={diamondIcon} alt="" width={16} />
                      </div>
                      <div>
                        <Box>
                          <JobluTypography.Body primary semiBold>
                            Responsibilities
                          </JobluTypography.Body>
                        </Box>
                        <Box>
                          <JobluTypography.Caption
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {jobDetails.responsibilities}
                          </JobluTypography.Caption>
                        </Box>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
    AppState: state.Generals,
  };
};
const mapDispatchToProps = {
  setChatRooms: userAction.setChatRooms,
  setShowNav: appActions.setShowMainNav,
};
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
