import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoJobs from "../../../../assets/img/matches/NoJobs.png";
import PaginationV1 from "../../../../components/pagination/PaginationV1";
import JobluTypography from "../../../../components/Typography";
import { app_styles } from "../../../../general.styles";
import { applicantRequests } from "../../../../service/requests";
import JobCardList from "../card";
const ApplicantArchivedJobs = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orig_list, setOrigList] = useState([]);
  const [applied_jobs, setAppliedJobs] = useState([]);
  const [current_page, setCurrentPage] = useState(1);

  const app_classes = app_styles();
  const [searchText, setSearchText] = useState("");

  const getArchivedApplicants = (keyword, page = 1) => {
    setLoading(true);
    let payload = {
      search: keyword,
      limit: 20,
      offset: page,
    };
    applicantRequests.jobs
      .archived(payload)
      .then((res) => {
        console.log("applicant applied jobs", res.data);
        if (page > 1) {
          setAppliedJobs({
            data: [...applied_jobs?.data, ...res.data?.data],
            limit: res.data.limit,
            page: res.data.page,
            totalCount: res.data.totalCount,
          });
        } else {
          setAppliedJobs(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        if (searchText) {
          setCurrentPage(1);
        }
      });

    // applicantRequests.jobs.archived().then((res) => {
    //   console.log("arcvied", res.data);
    //   // setAppliedJobs(res.data);
    //   // if (!keyword) setOrigList(res.data.data);
    //   setLoading(false);
    // });
  };

  useEffect(() => {
    getArchivedApplicants();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getArchivedApplicants(searchText);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    if (current_page > 1) {
      getArchivedApplicants(searchText, current_page);
    }
  }, [current_page]);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid xs={12} container justifyContent="space-between">
          <Grid xs={10} sm={4} className={`${app_classes.margin.b2}`}>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!loading ? (
            <>
              {applied_jobs?.data?.length > 0 && !loading ? (
                _.chunk(applied_jobs?.data, 20)[current_page - 1]?.map(
                  (job, index) => {
                    return (
                      <JobCardList
                        job={job}
                        key={job._id}
                        isApplied
                        getArchivedApplicants={getArchivedApplicants}
                      />
                    );
                  }
                )
              ) : (
                <Grid
                  className={`${app_classes.margin.t5} ${app_classes.margin.b5}`}
                >
                  <Box
                    className={`${app_classes.text.center} ${app_classes.margin.b2}`}
                  >
                    <img src={NoJobs} width={200} />
                  </Box>
                  <Box className={`${app_classes.margin.b2}`}>
                    <JobluTypography.H6
                      className={`${app_classes.text.center} `}
                    >
                      Find your next match job!
                    </JobluTypography.H6>
                  </Box>
                  <Box>
                    <JobluTypography.Body
                      className={`${app_classes.text.center} `}
                    >
                      One swipe could lead to your dream job.
                    </JobluTypography.Body>
                  </Box>
                  <Box className={`${app_classes.margin.b1}`}>
                    <JobluTypography.Body
                      className={`${app_classes.text.center} `}
                    >
                      Click explore and keep swiping.
                    </JobluTypography.Body>
                  </Box>
                  <Box className={app_classes.text.center}>
                    <Button
                      variant="outlined"
                      className={`${app_classes.button.primary_outline}`}
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      Explore
                    </Button>
                  </Box>
                </Grid>
              )}
            </>
          ) : (
            <>
              <JobluTypography.H6 style={{ textAlign: "center" }}>
                <CircularProgress />
              </JobluTypography.H6>
            </>
          )}
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <PaginationV1
            current_page={current_page}
            setCurrentPage={setCurrentPage}
            totalCount={applied_jobs?.totalCount}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ApplicantArchivedJobs;
