import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Search } from "@material-ui/icons";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";
import NoJobs from "../../../../assets/img/matches/NoJobs.png";
import JobluTypography from "../../../../components/Typography";
import { app_styles } from "../../../../general.styles";
import { applicantRequests } from "../../../../service/requests";
import ListLoader from "../../../explore/jobseeker-explore/components/loaders/ListLoader";
import JobCardList from "../card";

const ApplicantInterviewJobs = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const [orig_list, setOrigList] = useState([]);
  const [jobs, setJobs] = useState([]);

  const app_classes = app_styles();
  const [searchText, setSearchText] = useState("");
  const [current_page, setCurrentPage] = useState(1);

  const getApplicantInterviewJobs = (options, append) => {
    const page = options?.offset || 1;
    if (!append) setLoading(true);
    let payload = {
      search: searchText,
      limit: 20,
      offset: page,
    };
    applicantRequests.jobs
      .interviews(payload)
      .then((res) => {
        console.log("applicant interviews jobs", res.data);
        if (append && page > 1) {
          setJobs({
            data: [...jobs?.data, ...res.data?.data],
            limit: res.data.limit,
            page: res.data.page,
            totalCount: res.data.totalCount,
          });
        } else {
          setJobs(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        if (searchText) {
          setCurrentPage(1);
        }
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getApplicantInterviewJobs(searchText);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    if (current_page > 1) {
      getApplicantInterviewJobs(searchText, current_page);
    }
  }, [current_page]);

  return (
    <Grid container className={app_classes.width.w100}>
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
        className={`${app_classes.margin.b2}`}
      >
        <Grid xs={12} sm={4}>
          <TextField
            size="small"
            // Autofocus on textfield

            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!loading ? (
          <>
            <Box style={{ height: "60vh", overflowY: "auto" }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={(page) => {
                  console.log("page", page);
                  getApplicantInterviewJobs({ offset: page + 1 }, true);
                }}
                // hasMore={jobs?.totalCount === 20}
                hasMore={Math.ceil(jobs?.totalCount / jobs?.data?.length) > 1}
                loader={
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    spacing={2}
                  >
                    <ListLoader count={1} />
                  </Grid>
                }
                useWindow={false}
              >
                {jobs?.data?.length > 0 ? (
                  jobs?.data?.map((job, index) => {
                    return (
                      <JobCardList
                        job={job}
                        key={job._id}
                        showEmployerAction
                        isInterviews
                      />
                    );
                  })
                ) : (
                  <Grid
                    className={`${app_classes.margin.t5} ${app_classes.margin.b5}`}
                  >
                    <Box
                      className={`${app_classes.text.center} ${app_classes.margin.b2}`}
                    >
                      <img src={NoJobs} width={200} />
                    </Box>
                    <Box className={`${app_classes.margin.b2}`}>
                      <JobluTypography.H6
                        className={`${app_classes.text.center} `}
                      >
                        Find your next match job!
                      </JobluTypography.H6>
                    </Box>
                    <Box>
                      <JobluTypography.Body
                        className={`${app_classes.text.center} `}
                      >
                        One swipe could lead to your dream job.
                      </JobluTypography.Body>
                    </Box>
                    <Box className={`${app_classes.margin.b1}`}>
                      <JobluTypography.Body
                        className={`${app_classes.text.center} `}
                      >
                        Click explore and keep swiping.
                      </JobluTypography.Body>
                    </Box>
                    <Box className={app_classes.text.center}>
                      <Button
                        variant="outlined"
                        className={`${app_classes.button.primary_outline}`}
                        onClick={() => {
                          navigate("/dashboard");
                        }}
                      >
                        Explore
                      </Button>
                    </Box>
                  </Grid>
                )}
              </InfiniteScroll>
            </Box>
          </>
        ) : (
          <>
            <>
              <JobluTypography.H6 style={{ textAlign: "center" }}>
                <CircularProgress />
              </JobluTypography.H6>
            </>
          </>
        )}
      </Grid>
      {/* <Grid item xs={12} container justifyContent="center">
        <PaginationV1
          current_page={current_page}
          setCurrentPage={setCurrentPage}
          totalCount={jobs?.totalCount}
        />
      </Grid> */}
    </Grid>
  );
};

export default ApplicantInterviewJobs;
