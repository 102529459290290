import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Apps,
  AppsOutlined,
  Close,
  FormatListBulleted,
  FormatListBulletedOutlined,
} from "@material-ui/icons";
import TuneIcon from "@material-ui/icons/Tune";
import FlatList from "flatlist-react";
import _ from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import DefaultImg from "../../../assets/img/defaultImg.png";
import emptyImg from "../../../assets/img/explore/emptyImg.svg";
import JobluTypography from "../../../components/Typography";
import AuthModal from "../../../components/auth/auth-modals/AuthModal";
import PublicHeader from "../../../components/header/PublicHeader";
import SwipeLimitModal from "../../../components/modals/SwipeLimitsModal";
import { app_styles } from "../../../general.styles";
import appActions from "../../../redux/app/actions";
import userActions from "../../../redux/user/actions";
import {
  applicantRequests,
  jobsRequest,
  processParams,
  userRequest,
} from "../../../service/requests";
import { errorSwipeActions } from "../../../utils/constants";
import { formatString, removeCommas } from "../../../utils/helpers";
import useWindowDimensions from "../../../utils/hooks";
import SpinProgress from "../../../views/explore/jobseeker-explore/components/loaders/SpinProgress";
import JobsCardGrid from "./components/GridCard";
import ListCard from "./components/ListCard";
import Sidebarfilter from "./components/SidebarFilter";
import SingleCard from "./components/SingleCard";
import GridLoader from "./components/loaders/GridLoader";
import ListLoader from "./components/loaders/ListLoader";
import { LargeBanner, MediumBanner, NativeBanner, SmallBanner } from "../../../components/adbanner";

export const SWIPE_ACTIONS = {
  LIKE: "like",
  DISMISS: "not_interested",
  SUPERLIKE: "superLike",
};

const JobSeekerExplorePublic = (props) => {
  const { User, showAlert, signOut } = props;
  const app_classes = app_styles(props);
  const { width } = useWindowDimensions();
  const [explore_filters, setExploreFilters] = useState({});
  const [view_type, setViewType] = useState("grid");
  const [showSwipeLimit, setShowSwipeLimit] = useState(false);
  const [swipeLimitTitle, setSwipeLimitTitle] = useState(null);
  const [swipeLimitText, setSwipeLimitText] = useState(null);
  const [cards_loading, setCardsLoading] = useState(true);

  const [empImg, setEmpImg] = useState(null);
  const [appImg, setAppImg] = useState(null);
  const [matchOpen, setMatchOpen] = useState(false);
  const [showAdjustFilters, setShowAdjustFilters] = useState(false);

  const [limitOpen, setLimitOpen] = useState(false);
  const [limitMainText, setLimitMainText] = useState(null);
  const [limitSubText, setLimitSubText] = useState(null);

  const [metaInfo, setMetaInfo] = useState(null);

  const navigate = useNavigate();

  const [jobActionData, setJobActionData] = useState({
    action: null,
    job: null,
  });
  const [jobs, setJobs] = useState({
    data: [],
    totalCount: 0,
    limit: null,
    page: null,
  });

  const handleLogout = () => {
    try {
      userRequest
        .signOut({ token: User?.token })
        .then((res) => {
          signOut();
        })
        .finally(() => {
          setTimeout(() => {
            navigate("/login");
          }, 500);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const [filters, setFilters] = useState({ salaryCurrency: "PHP" });
  const [infinitScrollerLoading, setInfiniteScrollerLoading] = useState(false);
  const [current_page, setCurrentPage] = useState(1);

  const [params] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    let filter_from_url = {};
    if (!!params.get("search")) {
      filter_from_url["keyword"] = params.get("search");
    }
    if (!!params.get("jobFunction"))
      filter_from_url["jobFunction"] = params.get("jobFunction");
    if (!!params.get("location"))
      filter_from_url["location"] = params.get("location");
    if (!!params.get("salaryMin"))
      filter_from_url["salaryMin"] = params.get("salaryMin");

    if (!!params.get("salaryMax"))
      filter_from_url["salaryMax"] = params.get("salaryMax");

    if (!!params.get("currency"))
      filter_from_url["salaryCurrency"] = params.get("currency");
    if (!!params.get("yearsOfExp"))
      filter_from_url["yearsOfExp"] = params.get("yearsOfExp");
    if (!!params.get("workSetup"))
      filter_from_url["workSetup"] = params.get("workSetup")?.split(",");
    if (!!params.get("workPreference"))
      filter_from_url["workPreference"] = params
        .get("workPreference")
        ?.split(",");

    console.log("filter_from_url", filter_from_url);
    const payload = { ...filters, ...filter_from_url };
    setFilters(payload);
    getJobs(payload);
  }, [params]);


  useEffect(() => {
      cards_loading &&
      setMetaInfo(filters);
  }, [filters])

  const getJobs = (data, append = false) => {
    const card = document.getElementById("scroll-top");
    !append ? setCardsLoading(true) : setInfiniteScrollerLoading(true);

    if (!append && (filters?.keyword || filters?.jobFunction)) {
      setMetaInfo(filters);
    }

    setShowAdjustFilters(false);
    if (data) {
      let payload = { limit: 24 };

      Object.keys(data).forEach((key) => {
        if (data[key] !== null) {
          if (typeof data[key] !== "object") {
            payload[key] = data[key];
          } else {
            if (data[key]?.length > 0) {
              payload[key] = data[key]?.join(",");
            }
          }
        }
      });
      jobsRequest
        .getPublicRecommendedJobs(payload)
        .then((res) => {
          if (res.data?.data?.length < 100) {
            setShowAdjustFilters(true);
          }
          if (append) {
            let total = [...jobs?.data, ...res?.data?.data];
            if (total?.length > 96) {
              // jobs_list = jobs_list.slice(jobs_list?.length - 5);
              setJobs(res.data);
              card?.scrollIntoView(true);
              window.scroll(0, 0);
              showAlert({
                type: "info",
                message:
                  "We have loaded new jobs from the top to optimize your job search",
                duration: 7000,
                position: "top",
              });
            } else {
              total = _.uniqBy(total, "referenceId");

              setJobs({ ...res.data, data: total });
            }
          } else {
            card?.scrollIntoView(true);
            window.scroll(0, 0);
            setJobs(res.data);
          }
        })
        .finally(() => {
          setTimeout(() => {
            setCardsLoading(false);
            setInfiniteScrollerLoading(false);
          }, 3000);
        });
    } else {
      let payload = { ...filters, offset: 1, limit: 24 };
      jobsRequest
        .getPublicRecommendedJobs(payload)
        .then((res) => {
          console.log("data", res.data);
          if (res.data?.data?.length < 96) {
            setShowAdjustFilters(true);
          }
          let jobs_list = [...jobs?.data, ...res.data?.data];
          jobs_list = _.uniqBy(jobs_list, "_id");
          if (append) {
            setJobs({ ...res.data, data: jobs_list });
          } else {
            setJobs(res.data);
            card?.scrollIntoView(true);
            window.scroll(0, 0);
          }
        })
        .finally(() => {
          setTimeout(() => {
            setCardsLoading(false);
            setInfiniteScrollerLoading(false);
          }, 2000);
        });
    }
  };

  const handleFilter = (data) => {
    if (data?.location === "All Countries") {
      data.location = null;
    }
    if (data?.salaryMin && data?.salaryMax) {
      data.salaryRange = `${removeCommas(data?.salaryMin)}-${removeCommas(
        data?.salaryMax
      )}`;
    }
    if (data?.jobFunction === "All Job Functions") {
      data.jobFunction = null;
    }
    setFilters(data);
    setCurrentPage(1);
    getJobs(data);
  };

  useEffect(() => {
    if (!params) {
      getJobs();
    }
  }, []);

  const [jobData, setJobData] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [empDetails, setEmpDetails] = useState(null);
  const onSwipeEnd = (job, card_index, new_class, matchData, employer) => {
    //perform card removal

    setJobData(matchData);
    setJobDetails(job);
    setEmpDetails(employer);
    if (matchData.matchStatus == "match") {
      setAppImg(matchData.profileUrl_applicant);
      setTimeout(() => {
        setMatchOpen(true);
      }, 500);
    }
    const card = document.getElementById(`card-class-${card_index}`);
    card.classList.add(new_class);

    setTimeout(() => {
      let copy = jobs.data;
      copy.splice(card_index, 1);
      setJobs({
        ...jobs,
        data: [...copy],
      });
    }, 800);
  };

  useEffect(() => {
    console.log(infinitScrollerLoading);
    console.log(current_page);
  }, [infinitScrollerLoading, current_page]);

  const [showEmailVerifiedModal, setShowVerifyEmailModal] = useState(false);
  const [show_auth_modal, setShowAuthModal] = useState(null);

  const handleSwipe = (job) => {
    // THIS FUNCTION WILL SHOW THE AUTH MODAL
    let payload = {};
    const all_params = new URLSearchParams(location?.search);
    all_params.forEach((value, key) => {
      console.log(key, value);
      payload[key] = value;
    });

    const processed_params = processParams("", payload);
    navigate(`/board${processed_params}`, {
      state: { refId: job?.referenceId },
    });
    setShowAuthModal({
      job,
      type: "login",
    });
  };

  const handleSwipeJob = (job, action, card_index, new_class, employer) => {
    const jobId = job._id;
    const employerId = job.employer.userId;
    let swipeActionRequest;
    if (User?.user?.isEmailVerified || action === SWIPE_ACTIONS.DISMISS) {
      if (action === SWIPE_ACTIONS.SUPERLIKE) {
        swipeActionRequest = applicantRequests.jobs.actions.superlike(
          jobId,
          employerId
        );
      }
      if (action === SWIPE_ACTIONS.LIKE) {
        swipeActionRequest = applicantRequests.jobs.actions.like(
          jobId,
          employerId
        );
      }
      if (action === SWIPE_ACTIONS.DISMISS) {
        swipeActionRequest = applicantRequests.jobs.actions.dismiss(
          jobId,
          employerId
        );
      }
      swipeActionRequest
        .then((res) => {
          if (res.data?.firstTimeSwipe) {
            if (action === SWIPE_ACTIONS.LIKE) {
              setJobActionData({
                action: "liked",
                job,
                message: (
                  <>
                    Now let’s just wait for the employer’s <br /> response.
                  </>
                ),
              });
            } else if (action === SWIPE_ACTIONS.DISMISS) {
              setJobActionData({
                action: "dismissed",
                job,
                message: (
                  <>
                    This job will be dismissed and it won’t appear in your feed
                    anymore.
                  </>
                ),
              });
            }
          }

          if (
            res.data.message === errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
          ) {
            setLimitMainText("You have run out of likes today!");
            setLimitSubText(
              "That was fast! But don't worry, you can like again tomorrow."
            );
            setLimitOpen(true);
          } else if (
            res.data.message === errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
          ) {
            setLimitMainText("You have run out of likes for this month!");
            setLimitSubText("The great news is you can like again next month!");
            setLimitOpen(true);
          } else if (
            res.data.message === errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
          ) {
            setLimitMainText("You have run out of Super Likes!");
            setLimitSubText(
              "That was fast! But don't worry, you can Super Like again tomorrow."
            );
            setLimitOpen(true);
          } else if (res.data.message === errorSwipeActions.LIKE_NOT_ALLOWED) {
            setLimitMainText("Please upgrade account to start swiping!");
            setLimitSubText(
              "You are currently using a FREE account. Upgrade now to like this applicant."
            );
            setLimitOpen(true);
          } else {
            // //FIRST ACTION FOR SUPERLIKE note: jobseeker has no superlike action
            // if (action === "superLike" && (message === "" || !message)) {
            //   setJobInfo(data);
            //   console.log("ACTIONSUPERLIKE");
            //   setMessageForSuperlike("");
            //   setIsVisibleSuperLikeMessage(true);
            //   resolve(true);
            //   return;
            // }
            // if (action === "superLike") {
            //   console.log("swipe action successful");
            //   setIsVisibleSuperLikeMessage(false);
            //   setMessageForSuperlike("");
            //   setTimeout(() => {
            //     setIsVisibleSuperLikeSucess(true);
            //   }, 100);
            //   return;
            // }
            // getJobMatches();
            // navigation.goBack();
            if (res.data.matchStatus === "match") {
              const images = {
                employer: res.data.profileUrl_employer,
                applicant: res.data.profileUrl_applicant,
              };
              // setTimeout(() => {
              //   navigation.navigate("MatchScreen", {
              //     type: "applicant",
              //     applicant_data: user,
              //     job_data: job,
              //     images: images,
              //   });
              // }, 500);
            }
            onSwipeEnd(job, card_index, new_class, res.data, employer);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setShowVerifyEmailModal(true);
    }
  };
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const filterBtnRef = useRef();

  const handleGoToMessage = (data) => {
    navigate("/messages", {
      state: { data },
    });
  };

  const handleClear = () => {
    setCardsLoading(true);
    setInfiniteScrollerLoading(true);
    setCurrentPage(1);
    setJobs({
      ...jobs,
      data: _.take(jobs?.data, 20),
    });
    setTimeout(() => {
      setCardsLoading(false);
      setInfiniteScrollerLoading(false);
    }, 1000);
  };

  const loadMore = () => {
    if (infinitScrollerLoading) {
    } else {
      if (current_page * 24 < jobs.totalCount) {
        setCurrentPage(current_page + 1);
        let data = filters;
        if (data?.location === "All Countries") {
          data.location = null;
        }
        if (data?.salaryMin && data?.salaryMax) {
          data.salaryRange = `${removeCommas(data?.salaryMin)}-${removeCommas(
            data?.salaryMax
          )}`;
        } else {
          data.salaryCurrency = null;
          data.salaryMin = null;
          data.salaryMax = null;
        }
        if (data?.jobFunction === "All Job Functions") {
          data.jobFunction = null;
        }
        getJobs({ ...data, offset: current_page + 1 }, true);
      }
    }
  };

  const [_search, setSearch] = useState(null);
  const search = new URLSearchParams(location.search).get("search");

  useEffect(() => {
    setSearch(search);
  }, [search]);

  const MetaTag = () => {
    let formatted = formatString(metaInfo?.keyword);
    const dateNow = moment().format("MMMM YYYY");

    if (metaInfo?.jobFunction && !metaInfo?.keyword) {
      formatted = metaInfo?.jobFunction;
    }

    if(metaInfo){
      return (
        <MetaTags>
          <title>{`${formatted} Job Openings at Joblu | ${dateNow}`}</title>
          <meta
            name="description"
            content={`Apply for the ${formatted} Job Openings through Joblu.`}
          />
          <meta
            property="og:title"
            content={`${formatted} Job Openings at Joblu | ${dateNow}`}
          />
           <meta
            property="og:description"
            content={`Apply for the ${formatted} Job Openings through Joblu.`}
          />
          <meta property="og:image"
            content="https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/joblu-meta-img.jpeg" />
        </MetaTags>
      );
    }
    else{
      return (
        <MetaTags>
          <title>{`Job Openings at Joblu | ${dateNow}`}</title>
          <meta
            name="description"
            content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
          />
          <meta
            property="og:title"
            content={`IT, Customer Service, Accounting Job Openings at Joblu | ${dateNow}`}
          />
          <meta
            property="og:description"
            content={`Apply for IT, Customer Service, Accounting Job Openings through Joblu.`}
          />
          <meta property="og:image"
            content="https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/joblu-meta-img.jpeg" />
        </MetaTags>
      );
    }
  };

  const scrollerRef = useRef();
  return (
    <Box>
       <MetaTag />
      <SwipeLimitModal
        limitOpen={limitOpen}
        setLimitOpen={setLimitOpen}
        limitMainText={limitMainText}
        limitSubText={limitSubText}
      />
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={matchOpen}
        onClose={() => {
          setMatchOpen(false);
        }}
        style={{ backgroundColor: "#002E5D73" }}
      >
        <Box
          className={`${app_classes.padding.a4} ${app_classes.text.center} ${app_classes.backgroundColor.bg_white}`}
        >
          <Box className={`${app_classes.text.right} ${app_classes}`}>
            <Close
              className={`${app_classes.cursor.pointer}`}
              onClick={() => {
                setMatchOpen(false);
              }}
            />
          </Box>
          <Box className={`${app_classes.margin.b3}`}>
            <JobluTypography.H3 primary>It's A Match!</JobluTypography.H3>
          </Box>
          <Box
            className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center}`}
          >
            <Box className={`${app_classes.margin.r1}`}>
              <img
                src={User?.user?.profileUrl || DefaultImg}
                width={90}
                height={90}
                style={{ objectFit: "cover" }}
              />
            </Box>
            <Box className={`${app_classes.margin.b3}`}>
              {" "}
              <img
                src={appImg || DefaultImg}
                width={90}
                height={90}
                style={{ objectFit: "cover" }}
              />
            </Box>
          </Box>
          <Box>
            <Box className={`${app_classes.margin.b3}`}>
              <JobluTypography.Body style={{ fontSize: 14 }}>
                <strong>{empDetails?.companyName}</strong> has shortlisted you
                for the position of <strong>{jobDetails?.jobTitle}</strong>. You
                can now message them and start your application process!
              </JobluTypography.Body>
            </Box>
            <Box>
              <Button
                variant="container"
                className={`${app_classes.button.primary}`}
                onClick={() => {
                  handleGoToMessage(jobData);
                }}
              >
                Start Chatting
              </Button>
            </Box>
            <Box>
              <Button
                variant="text"
                onClick={() => {
                  setMatchOpen(false);
                }}
              >
                <JobluTypography.Body
                  primary
                  className={`${app_classes.text.capitalize}`}
                >
                  Keep Swiping
                </JobluTypography.Body>
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={showEmailVerifiedModal} maxWidth="xs">
        <Box
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <IconButton onClick={() => setShowVerifyEmailModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <Paper style={{ padding: 20, paddingTop: 0 }}>
          <JobluTypography.H6 align="center">
            Your email is not yet verified
          </JobluTypography.H6>
          <JobluTypography.Body
            align="center"
            style={{ fontSize: 14, marginTop: 20 }}
          >
            Click on the link in your email. If you don't see the email in your
            inbox, please check your promotions or spam folder.
          </JobluTypography.Body>
          <JobluTypography.Body
            align="center"
            style={{ fontSize: 14, marginTop: 20 }}
          >
            Once verified, login to your account and enjoy swiping.
          </JobluTypography.Body>

          <Grid container style={{ marginTop: 20 }} spacing={1}>
            <Grid xs={12} sm={6} item>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                style={{ textTransform: "none", margin: 5 }}
                onClick={() => {
                  setShowVerifyEmailModal(false);
                  handleLogout();
                }}
              >
                I have verified my email
              </Button>
            </Grid>
            <Grid xs={12} sm={6} item>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                style={{ textTransform: "none", margin: 5 }}
                onClick={() => {
                  userRequest
                    .resendVerificationEmail({ email: User?.user?.email })
                    .then((res) => {
                      console.log("resend response", res.data);
                      if (res.data?.queued === true) {
                        showAlert({
                          message:
                            "We have sent the verification link to your email.",
                        });
                        setTimeout(() => {
                          setShowVerifyEmailModal(false);
                        }, 1000);
                      }
                    })
                    .catch(() => {
                      showAlert({
                        message: "An error occured please try again later",
                        type: "error",
                      });
                      setShowVerifyEmailModal(false);
                    });
                }}
              >
                Resend verification link
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
      <PublicHeader />
      <Grid container justifyContent="center" style={{ marginBottom: 40 }}>
        <Drawer
          anchor="left"
          open={openFilterDrawer}
          onClose={() => setOpenFilterDrawer(false)}
          PaperProps={{ style: { width: "80vw" } }}
        >
          <Sidebarfilter
            handleSearch={handleFilter}
            filterBtnRef={filterBtnRef}
            onChange={setFilters}
            filters={filters}
            handleClear={handleClear}
          />
        </Drawer>
        <Grid
          item
          sm={11}
          container
          justifyContent="center"
          spacing={3}
          style={{ marginTop: 20 }}
          alignItems="flex-start"
        >
          <Grid
            item
            md={3}
            style={{ paddingTop: 4, position: "relative" }}
            className={app_classes.display.xs_none}
          >
            <Box
              style={{
                position: "fixed",
                maxWidth: "20%",
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <Sidebarfilter
                handleSearch={handleFilter}
                filterBtnRef={filterBtnRef}
                onChange={setFilters}
                handleClear={handleClear}
                filters={filters}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={11}
            md={9}
            container
            spacing={2}
            justifyContent="center"
          >
            <Grid xs={12}>
              <Box
                style={{
                  background: "#fff",
                  width: width < 640 ? "90%" : "68%",
                  paddingTop: 120,
                  position: "fixed",
                  top: 0,
                  zIndex: 999,
                }}
              ></Box>
              <Card
                elevation={0}
                style={{
                  padding: "10px",
                  border: "1px solid #C9CCCE",
                  borderRadius: "5px",
                  marginBottom: 10,
                  position: "fixed",
                  alignSelf: "start",
                  width: width < 640 ? "90%" : "68%",
                  zIndex: 999,
                }}
              >
                <Box className={app_classes.display.xs_flex_between}>
                  <Box className={app_classes.display.sm_none}>
                    <Tooltip title="Filters">
                      <IconButton
                        onClick={() => {
                          setOpenFilterDrawer(!openFilterDrawer);
                        }}
                      >
                        <TuneIcon style={{ fontSize: 25 }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    {/* <Tooltip title="Single Card View">
                    <IconButton onClick={() => setViewType("single")}>
                      {view_type === "single" ? (
                        <CropDin
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        />
                      ) : (
                        <CropDin />
                      )}
                    </IconButton>
                  </Tooltip> */}
                    {width > 640 && (
                      <>
                        <Tooltip title="Grid View">
                          <IconButton
                            onClick={() => {
                              setCardsLoading(true);
                              setInfiniteScrollerLoading(true);
                              setCurrentPage(1);
                              setJobs({
                                ...jobs,
                                data: _.take(jobs?.data, 20),
                              });

                              setViewType("grid");
                              setTimeout(() => {
                                setCardsLoading(false);
                                setInfiniteScrollerLoading(false);
                              }, 1000);
                            }}
                          >
                            {view_type === "grid" ? (
                              <Apps color="primary" />
                            ) : (
                              <AppsOutlined />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="List View">
                          <IconButton
                            onClick={() => {
                              setCardsLoading(true);
                              setInfiniteScrollerLoading(true);
                              setCurrentPage(1);
                              setJobs({
                                ...jobs,
                                data: _.take(jobs?.data, 20),
                              });
                              setViewType("list");
                              setTimeout(() => {
                                setCardsLoading(false);
                                setInfiniteScrollerLoading(false);
                              }, 1000);
                            }}
                          >
                            {view_type === "list" ? (
                              <FormatListBulleted color="primary" />
                            ) : (
                              <FormatListBulletedOutlined />
                            )}
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item container style={{ height: "100%", paddingTop: 80 }}>
              <div id="scroll-top" style={{ padding: 10, width: "100%" }}></div>
              <InfiniteScroll
                pageStart={1}
                loadMore={(page) => {
                  if (jobs?.data?.length != 0) loadMore();
                }}
                hasMore={
                  current_page * 24 < jobs.totalCount &&
                  jobs?.data?.length !== 0
                }
                threshold={10}
                loader={<SpinProgress />}
                useWindow={true}
                ref={scrollerRef}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="flex-start"
                  alignContent="flex-start"
                  spacing={2}
                >
                  {view_type === "grid" && jobs?.data?.length > 0 && (
                    <>
                      {!cards_loading ? (
                        <FlatList
                          list={jobs?.data}
                          renderItem={(job, job_index) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={3}
                              key={job?._id}
                              style={{ minWidth: "260px", maxWidth: "300px" }}
                              id={`card-class-${job_index}`}
                            >
                              <JobsCardGrid
                                job={job}
                                onSwipe={handleSwipe}
                                card_index={job_index}
                                publicJob={true}
                              />
                            </Grid>
                          )}
                          renderWhenEmpty={() => <div></div>}
                        />
                      ) : (
                        <GridLoader />
                      )}
                    </>
                  )}
                  {view_type === "list" && (
                    <>
                      {!cards_loading ? (
                        <FlatList
                          list={jobs?.data}
                          renderItem={(job, job_index) => (
                            <Grid
                              item
                              xs={12}
                              key={job?._id}
                              style={{ minWidth: "260px" }}
                            >
                              {width < 640 ? (
                                <>
                                  <JobsCardGrid
                                    job={job}
                                    onSwipe={handleSwipe}
                                    card_index={job_index}
                                    publicJob={true}
                                  />
                                </>
                              ) : (
                                <>
                                  <ListCard
                                    job={job}
                                    onSwipe={handleSwipe}
                                    card_index={job_index}
                                    publicJob={true}
                                  />
                                </>
                              )}
                            </Grid>
                          )}
                          renderWhenEmpty={() => <div></div>}
                        />
                      ) : (
                        <ListLoader />
                      )}
                    </>
                  )}

                  {view_type === "single" && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        width: "100%",
                        padding: 0,
                      }}
                    >
                      <SingleCard
                        job={jobs?.data[0]}
                        onSwipe={handleSwipe}
                        card_index={0}
                      />
                    </Grid>
                  )}
                </Grid>
              </InfiniteScroll>
              <>
                {!cards_loading && jobs.data.length === 0 && (
                  <Grid
                    xs={12}
                    style={{ height: "70vh" }}
                    className={` ${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center} ${app_classes.flexBox.justify_content_center}`}
                  >
                    <Box style={{ textAlign: "center" }}>
                      <img src={emptyImg} alt="" className="mb-3 text-center" />
                      <h4 className="text-center">
                        It looks like you already exhausted <br /> all the great
                        matches for your search
                      </h4>
                      <p>
                        Don’t worry! We got a lot of opportunities
                        <br />
                        in store for you. Try adjusting the filters to see more
                        jobs.
                      </p>
                    </Box>
                  </Grid>
                )}
              </>
              {/* {!cards_loading &&
                current_page === Math.ceil(jobs.totalCount / 20) &&
                !showAdjustFilters && (
                  <Grid xs={12}>
                    <Box style={{ textAlign: "center", marginTop: 20 }}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          console.log(filterBtnRef);
                          filterBtnRef.current?.click();
                        }}
                      >
                        Load More
                      </Button>
                    </Box>
                  </Grid>
                )} */}
            </Grid>
            {/* <Grid
              xs={12}
              justifyContent="center"
              container
              style={{ marginTop: 20 }}
            >
              <Grid item>
                {jobs?.data?.length > 0 && view_type !== "single" && (
                  <Box className={app_classes.display.display_flex}>
                    <Box>
                      <IconButton
                        disabled={current_page === 1}
                        onClick={() => {
                          setCurrentPage(current_page - 1);
                        }}
                      >
                        <ArrowBack />
                      </IconButton>
                    </Box>
                    <Box className={app_classes.general.centered_y}>
                      {current_page} of {Math.ceil(jobs.totalCount / 20)}
                    </Box>
                    <Box>
                      <IconButton
                        disabled={
                          current_page === Math.ceil(jobs.totalCount / 20)
                        }
                        onClick={() => {
                          setCurrentPage(current_page + 1);
                        }}
                      >
                        <ArrowForward />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        <Dialog open={!!jobActionData.job} maxWidth="xs" fullWidth>
          <Box
            className={`${app_classes.general.bg_primary} ${app_classes.padding.a3}`}
          >
            <JobluTypography.H6 align="center">
              You {jobActionData?.action} a Job
            </JobluTypography.H6>
          </Box>
          <DialogContent>
            <Box className={app_classes.padding.a3}>
              <Box
                className={app_classes.display.display_flex}
                style={{ justifyContent: "center" }}
              >
                <img
                  style={{ width: "90px", height: "90px", objectFit: "cover" }}
                  alt=""
                  src={
                    jobActionData?.job?.companyLogo
                      ? jobActionData?.job?.companyLogo
                      : jobActionData?.job?.employer?.profileUrl
                      ? jobActionData?.job?.employer?.profileUrl
                      : DefaultImg
                  }
                />
              </Box>
              <JobluTypography.H6
                align="center"
                style={{ marginTop: 20 }}
                semiBold
                primary
              >
                {jobActionData?.job?.jobTitle}
              </JobluTypography.H6>

              <JobluTypography.Body
                style={{ width: "100%", textAlign: "center" }}
              >
                {jobActionData?.job?.companyName ||
                  jobActionData?.job?.employer?.companyName}
              </JobluTypography.Body>
              <JobluTypography.Body
                style={{ textAlign: "center", marginTop: 20 }}
              >
                {jobActionData?.message}
              </JobluTypography.Body>
              <Box
                className={`${app_classes.display.display_flex} ${app_classes.margin.t3}`}
              >
                <Box className={app_classes.general.centered_x}>
                  <Button
                    style={{ textTransform: "none", minWidth: 200 }}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setJobActionData({
                        action: null,
                        job: null,
                      });
                    }}
                  >
                    Okay
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Grid>
      {/* <Box className="sticky-bottom" style={{ marginTop: "100vh" }}>
        <Footer />
      </Box> */}
      <AuthModal
        type={show_auth_modal?.type}
        onClose={() => {
          setShowAuthModal(null);
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return { AppState: state.Generals, User: state.User };
};
const mapDispatchToProps = {
  signIn: userActions.signIn,
  signUp: userActions.signUp,
  verifyGoogleToken: userActions.verifyGoogleToken,
  showAlert: appActions.showAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobSeekerExplorePublic);
