import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import notificationActions from '../../redux/notifications/action'
import { userRequest, recommendationsRequest, jobMatchRequest } from '../../service/requests'
import { profilePlaceholder_Applicant } from '../../utils/constants'
import './styles.css'
import RecommendationItem from './components/RecommendationItem'
import FlatList from '../../components/flatlist/FlatList'
import Profile from '../../components/reusables/Profile/Profile'
import { errorSwipeActions } from '../../utils/constants'
import ExploreModal from '../explore/components/ExploreModal'


function Recommendations(props) {
    const { User } = props
    const [isGettingMoredata, setIsGettingMoreData] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [loadMoreData, setLoadMoreData] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)


    const [recommendations, setRecommendations] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)
    const [matchImages, setMatchImages] = useState(null)
    const [applicantName, setApplicantName] = useState(null)

    const [jobTitle, setJobTitle] = useState(null)

    const navigate = useNavigate()

    const viewProfile = (item) => {
        setSelectedUser(item)
        setShowProfile(!showProfile)
        document.getElementsByTagName('BODY')[0].style.overflow = 'hidden'
    }



    const getRecommendations = () => {
        setIsFetching(true)
        recommendationsRequest.getRecommendationRef()
        .then((res) => {
            const data = res?.data?.data
            console.log(res?.data?.totalCount)
            recommendationsRequest.getRecommendationList({
                applicants: data?.map(item => {
                    return item.applicantId
                })
            })
            .then(response => {
                // // console.log(response?.data)
                // setRecommendations(response?.data?.data)
                const processed = data?.map(item => {
                    const temp = response?.data?.data?.find(obj => obj.userId === item?.applicantId)
                    return {
                        ...item,
                        applicant: temp,
                    }
                })
                setRecommendations(processed)
            })
    
        })
        .catch(e => {
            console.log(e)
            setIsFetching(false)
        })
        .finally(() => {
            setTimeout(() => {
                setIsFetching(false)
            }, 1000)
        })
    }

    useEffect(() => {
        getRecommendations()
     }, [])


    const renderRecommendations = (item, index) => {
        console.log(item)
        return (
            <RecommendationItem
                key={index}
                handleView={() => viewProfile(item?.applicant)}
                data={item}
                itemId={index}
                hasSwipeActions
                swipeAction={(payload) => swipeAction(payload)}
            />
        )
    }


    const swipeAction = (payload) => {
        console.log(payload)
        const { job, applicant, action, itemId } = payload
        let applicant_item = document.getElementById(`${itemId}`)
        jobMatchRequest
            .employerSwipe({
                jobId: job._id,
                applicantId: applicant.userId,
                action: action,
            })
            .then((res) => {
                console.log(res.data)
                if (
                    res.data.message ===
                    errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfLikes')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle('You have run out of likes today!')
                    // setSwipeLimitText('Go back tomorrow to start liking again.')
                    // setShowSwipeLimit(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfMonthlyLikes')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle(
                    //     'You have run out of likes for this month!'
                    // )
                    // setSwipeLimitText('You can like again next month.')
                    // setShowSwipeLimit(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfSuperLikes')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle('You have run out of superlikes!')
                    // setSwipeLimitText('You can superlike again next month.')
                    // setShowSwipeLimit(true)
                } else if (
                    res.data.message === errorSwipeActions.LIKE_NOT_ALLOWED
                ) {
                    setModalVariant('upgradeAccount')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle(
                    //     'Please upgrade account to start swiping!'
                    // )
                    // setSwipeLimitText(
                    //     'You are currently using a FREE account. Upgrade now to like this applicant.'
                    // )
                    // setShowSwipeLimit(true)
                } else {
                    if (action === 'not_interested') {
                        applicant_item.classList.add('slide-out-left')
                    } else if (action === 'superLike') {
                        applicant_item.classList.add('slide-out-top')
                    } else if (action === 'like') {
                        applicant_item.classList.add('slide-out-right')
                    }
                    setTimeout(() => {
                        getRecommendations()
                    }, 500)
                }

                if (res.data.matchStatus === 'match') {
                    // alert('MATCHED!!')
                    const images = {
                        employer: res.data.profileUrl_employer,
                        applicant: res.data.profileUrl_applicant,
                    }

                    setMatchImages(images)
                    setModalVariant('matchModal')
                    setModalOpen(true)
                    setApplicantName(
                        applicant.firstName + ' ' + applicant.lastName
                    )
                    setJobTitle(job.jobTitle)
                }
                // navigation.goBack()
            })
            .catch((err) => {
                console.log(err)
            })
    }



    return (
        <div className="row justify-content-center mx-0">
            <Profile
                showProfile={showProfile}
                setShowProfile={setShowProfile}
                selectedProfile={selectedUser}
                currentTab={'likes'}
            />
            <div className="border-bottom ">
                <div className="container ">
                    <div className="justify-content-between align-items-center row">
                        <div className="col-sm-12 col-md-3 my-3">
                            <h5 className="text-body fw500 mb-2 ">Recommendations</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-9">
                <FlatList
                    renderItem={renderRecommendations}
                    data={recommendations?.filter((item => item?.applicant !== undefined))}
                    loadMoreItems={() => console.log('test')}
                    // hasMoreItems={loadMoreLikedMe}
                    list={'recommendations'}
                />
                {/* {notificationsList?.data?.length <
                    notificationsList?.totalCount &&
                    notificationsList?.data?.length < 100 && (
                        <>
                            {!!notificationsList?.data?.length && (
                                <div className="text-center">
                                    <button
                                        className="btn btn-primary my-3 text-center"
                                        onClick={() => getMoreNotifications()}
                                    >
                                        {isGettingMoredata && (
                                            <span
                                                className="spinner-border spinner-border-sm me-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                        Load More
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                {!notificationsList?.data?.length && (
                    <div className="mt-5 ">
                        <h6 role="button" className="text-center">
                            No notifications
                        </h6>
                    </div>
                )} */}
            </div>
            <ExploreModal
                showModal={modalOpen}
                variant={modalVariant}
                matchImages={matchImages}
                setModalVariant={setModalVariant}
                setShowModal={setModalOpen}
                applicantName={applicantName}
                jobTitle={jobTitle}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
        Notifications: state.Notifications,
    }
}

const mapDispatchToProps = {
    getNotifications: notificationActions.getNotifications,
}

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations)
