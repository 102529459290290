import { makeStyles } from "@material-ui/core/styles";

export const card_styles = makeStyles((theme) => ({
  job_image: {
    width: "88px",
    height: "88px",
    borderRadius: "50%",
    border: "1px solid grey",
  },
  card_container: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #C9CCCE",
    borderRadius: "5px",
    width: "100%",
    padding: 20,
    marginBottom: 5,
  },
  action_chip: {
    backgroundColor: theme.palette.warning.main,
  },
}));
