import { Typography } from "@material-ui/core";
const styles = {
  h1: {
    fontSize: "48px",
    lineHeight: "58px",
    fontFamily: "Poppins",
  },
  h2: {
    fontSize: "36px",
    lineHeight: "43px",
    fontFamily: "Poppins",
  },
  h3: {
    fontSize: "30px",
    lineHeight: "39px",
    fontFamily: "Poppins",
  },
  h4: {
    fontSize: "30px",
    lineHeight: "39px",
    fontFamily: "Poppins",
  },
  h5: {
    fontSize: "24px",
    lineHeight: "30px",
    fontFamily: "Poppins",
  },
  h6: {
    fontSize: "18px!important",
    lineHeight: "22px",
    fontFamily: "Poppins",
  },

  body: {
    fontSize: "12px",
    lineHeight: "24px",
    fontFamily: "Open Sans",
  },

  caption: {
    fontSize: "12px",
    lineHeight: "24px",
    fontFamily: "Open Sans",
  },
};
const JobluTypography = {
  H1: ({ children, semiBold, bold, primary, secondary, style, ...rest }) => (
    <Typography
      style={{
        ...styles.h1,
        fontWeight: 400,
        color: primary ? "#002E5D" : secondary ? "#009CDE" : "inherit",

        ...style,
      }}
      variant="h1"
      {...rest}
    >
      {children}
    </Typography>
  ),
  H2: ({ children, semiBold, bold, primary, secondary, style, ...rest }) => (
    <Typography
      style={{
        ...styles.h2,
        fontWeight: 400,
        color: primary ? "#002E5D" : secondary ? "#009CDE" : "inherit",
        ...style,
      }}
      variant="h2"
      {...rest}
    >
      {children}
    </Typography>
  ),
  H3: ({ children, semiBold, bold, primary, secondary, style, ...rest }) => (
    <Typography
      style={{
        ...styles.h3,
        fontWeight: 400,
        color: primary ? "#002E5D" : secondary ? "#009CDE" : "inherit",
        ...style,
      }}
      variant="h3"
      {...rest}
    >
      {children}
    </Typography>
  ),
  H4: ({ children, semiBold, bold, primary, secondary, style, ...rest }) => (
    <Typography
      style={{
        ...styles.h4,
        fontWeight: 400,
        color: primary ? "#002E5D" : secondary ? "#009CDE" : "inherit",
        ...style,
      }}
      variant="h4"
      {...rest}
    >
      {children}
    </Typography>
  ),
  H5: ({ children, semiBold, bold, primary, secondary, style, ...rest }) => (
    <Typography
      style={{
        ...styles.h5,
        fontWeight: 400,
        color: primary ? "#002E5D" : secondary ? "#009CDE" : "inherit",
        ...style,
      }}
      variant="h5"
      {...rest}
    >
      {children}
    </Typography>
  ),
  H6: ({ children, semiBold, bold, primary, secondary, style, ...rest }) => (
    <Typography
      style={{
        ...styles.h6,
        fontWeight: 400,
        color: primary ? "#002E5D" : secondary ? "#009CDE" : "inherit",
        ...style,
      }}
      variant="h6"
      {...rest}
    >
      {children}
    </Typography>
  ),

  Body: ({ children, semiBold, bold, primary, secondary, style, ...rest }) => (
    <Typography
      style={{
        ...styles.body,
        fontWeight: semiBold ? 600 : 400,
        color: primary ? "#002E5D" : secondary ? "#009CDE" : "inherit",
        ...style,
      }}
      variant="body1"
      {...rest}
    >
      {children}
    </Typography>
  ),

  Caption: ({
    children,
    semiBold,
    bold,
    primary,
    secondary,
    style,
    link,
    ...rest
  }) => (
    <Typography
      style={{
        ...styles.caption,
        fontWeight: 400,
        color: primary ? "#002E5D" : secondary ? "#009CDE" : "#6E88A3",
        cursor: link && "pointer",
        ...style,
      }}
      variant="body2"
      {...rest}
    >
      {children}
    </Typography>
  ),
};

export default JobluTypography;
