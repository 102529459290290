import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Grid,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import JobluH from "../../../assets/img/joblu-h.png";
import { isSupported, messaging } from "../../../firebase";
import { app_styles } from "../../../general.styles";
import userActions from "../../../redux/user/actions";
import { jobsRequest, userRequest } from "../../../service/requests";
import { creds } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/hooks";
import { validateEmail } from "../../../utils/validators";
import JobluTypography from "../../Typography";
import TextFieldV1 from "../../inputs/CustomTextField";
import { modal_styles } from "../../modals/styles";
import SelectCountry2 from "../../reusables/SelectCountry2";
import SelectJobFunction from "../../reusables/SelectJobFunction";
import CustomGooglePlaces from "../../select/google-places";

const AuthModal = (props) => {
  const { AppState, signIn, signUp, User, verifyGoogleToken, type, onClose } =
    props;
  const {
    isRegistering,
    isSigningIn,
    isSignedIn,
    isGoogleSignup,
    isRegistered,
  } = User;

  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const params = useParams();
  const refId = params.refId;
  const _location = useLocation();
  const app_classes = app_styles(props);
  const modal_classes = modal_styles();
  const [job, setJob] = useState(null);
  const [employerDetails, setEmployerDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmailRegistered, setIsEmailRegistered] = useState(false);
  const influencerCode = new URLSearchParams(_location.search).get(
    "influencerCode"
  );

  const [errors, setErrors] = useState({
    email: null,
  });
  const source = new URLSearchParams(_location.search).get("source");
  const { currencyList, currency } = AppState;
  const [country, setCountry] = useState(null);
  const currencyOptions = {
    currencyList,
    currency,
  };
  const [showAuthModal, setShowAuthModal] = useState(type);
  const [auth_details, setAuthDetails] = useState({ subscribeToEmail: true });
  const [googleResponse, setGoogleResponse] = useState(null);

  useEffect(() => {
    setShowAuthModal(type);
  }, [type]);

  const [error_fields, setErrorFields] = useState({
    email: null,
    password: null,
    confirm_password: null,
    firstName: null,
    lastName: null,
    dob: null,
    currentLocation: null,
    countryCode: null,
    phoneNumber: null,
    jobFunction: null,
    influencerCode: null,
  });
  const is_valid = async () => {
    // let required_fields = [
    //   "email",
    //   "password",
    //   "confirm_password",
    //   "firstName",
    //   "lastName",
    //   "dob",
    //   "currentLocation",
    //   "countryCode",
    //   "phoneNumber",
    //   "jobFunction",
    //   "agreeToTerms",
    // ];

    // if (googleResponse) {
    //   required_fields.splice(required_fields.indexOf("password"), 1);
    //   required_fields.splice(required_fields.indexOf("confirm_password"), 1);
    // }
    // let errors = {};
    // for (let key of required_fields) {
    //   if (!auth_details[key]) {
    //     errors[key] = "Required";
    //   } else {
    //     errors[key] = null;
    //     if (key === "email") {
    //       const email_valid = await validateEmail(auth_details[key]);
    //       if (email_valid === false) {
    //         errors["email"] = "Not a valid email address!";
    //       } else {
    //         userRequest
    //           .validateEmail({ email: auth_details.email })
    //           .then(() => {
    //             errors["email"] = null;
    //           })
    //           .catch(() => {
    //             errors["email"] = "Email already exists";
    //           });
    //       }
    //     }
    //     if (key === "confirm_password") {
    //       if (auth_details["confirm_password"] !== auth_details["password"]) {
    //         errors["confirm_password"] = "Password did not matched!";
    //       } else {
    //         errors["confirm_password"] = null;
    //       }
    //     }
    //   }
    // }
    // if (auth_details?.influencerCode) {
    //   await userRequest
    //     .validateInfluencerCode({
    //       influencerCode: auth_details?.influencerCode,
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       if (res.data?.valid) {
    //         errors["influencerCode"] = null;
    //       } else {
    //         errors["influencerCode"] = "Invalid Code";
    //       }
    //     })
    //     .catch(() => {
    //       errors["influencerCode"] = "Invalid Code";
    //     });
    // }
    // setErrorFields(errors);
    // let valids = [];
    // for (let key of Object.keys(errors)) {
    //   if (errors[key] === null) {
    //     //ok
    //   } else {
    //     valids.push(false);
    //   }
    // }

    let valids = [];
    let errors2 = error_fields;

    if (auth_details.email) {
      if (validateEmail(auth_details.email)) {
        const email_valid = await validateEmail(auth_details.email?.trim());
        if (email_valid === false) {
          errors2 = {
            ...errors2,
            email: "Invalid email address.",
          };
        } else {
          userRequest
            .validateEmail({ email: auth_details.email?.trim() })
            .then(() => {
              valids.push(true);
              errors2 = { ...errors2, email: null };
            })
            .catch(() => {
              console.log("err");
              valids.push(false);
              errors2 = {
                ...error_fields,
                email: "Email already exists",
              };
            });
        }
      } else {
        console.log("err");
        valids.push(false);
        errors2 = { ...errors2, email: "Invalid email address" };
      }
    } else {
      console.log("err");
      valids.push(false);
      errors2 = { ...errors2, email: "Email address is required" };
    }

    if (!googleResponse) {
      if (
        !!auth_details.password === false ||
        auth_details.password?.length < 8
      ) {
        errors2 = {
          ...errors2,
          password: "Password must contain atleast 8 characters",
        };
        valids.push(false);
      } else {
        errors2 = { ...errors2, password: null };
        valids.push(true);
      }

      if (auth_details.confirm_password !== auth_details.password) {
        errors2 = { ...errors2, confirm_password: "Passwords do not match!" };
        valids.push(false);
      } else {
        errors2 = {
          ...errors2,
          confirm_password: null,
        };
        valids.push(true);
      }
    }

    if (!auth_details.firstName) {
      errors2 = { ...errors2, firstName: "First name is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        firstName: null,
      };
      valids.push(true);
    }

    if (!auth_details.lastName) {
      errors2 = { ...errors2, lastName: "Last name is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        lastName: null,
      };
      valids.push(true);
    }

    if (!auth_details.dob) {
      errors2 = { ...errors2, dob: "Date of birth is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        dob: null,
      };
      valids.push(true);
    }

    if (!auth_details.currentLocation) {
      errors2 = { ...errors2, currentLocation: "Location is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        currentLocation: null,
      };
      valids.push(true);
    }

    if (!auth_details.countryCode) {
      errors2 = { ...errors2, countryCode: "Country code is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        countryCode: null,
      };
      valids.push(true);
    }

    if (!auth_details.phoneNumber) {
      errors2 = { ...errors2, phoneNumber: "Phone number is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        phoneNumber: null,
      };
      valids.push(true);
    }

    if (!auth_details.jobFunction) {
      errors2 = { ...errors2, jobFunction: "Job function is required" };
      valids.push(false);
    } else {
      errors2 = {
        ...errors2,
        jobFunction: null,
      };
      valids.push(true);
    }

    if (!!auth_details.influencerCode) {
      let influencerCode = auth_details.influencerCode;
      await userRequest
        .validateInfluencerCode({ influencerCode })
        .then((res) => {
          console.log("inf resp", res.data);
          if (res.data?.valid === true) {
            errors2 = {
              ...errors2,
              influencerCode: null,
            };
            valids.push(true);
          } else {
            errors2 = {
              ...errors2,
              influencerCode: res.data?.error?.message?.split(":")[1],
            };
            valids.push(false);
          }
        });
    } else if (!auth_details.influencerCode) {
      errors2 = {
        ...errors2,
        influencerCode: null,
      };
      valids.push(true);
    }

    setErrorFields(errors2);
    if (valids.includes(false)) return false;

    return true;
  };
  useEffect(() => {
    console.log("error_fields", error_fields);
  }, [error_fields]);

  const countryCodes = require("country-codes-list");
  const myCountryCodesObject = countryCodes.customList(
    "countryCode",
    "{countryNameEn}:+{countryCallingCode}"
  );

  const [countryCodesList, setCountryCodesList] = useState(
    Object.values(myCountryCodesObject).map((item) => {
      var temp = item.split(":");
      return {
        label: `${temp[0]} (${temp[1]})`,
        value: item,
      };
    })
  );

  const handleSignup = async () => {
    console.log("data paylaod", auth_details);
    if (await is_valid()) {
      //submit
      let payload = {
        userRole: "APPLICANT",
        email: auth_details.email?.trim(),
        password: auth_details.password,
        firstName: auth_details.firstName,
        lastName: auth_details.lastName,
        dob: auth_details.dob,
        currentLocation: auth_details.currentLocation,
        country: auth_details?.country,
        region: auth_details?.region,
        city: auth_details?.city,
        phoneNumber: `${
          auth_details.countryCode?.split(":")?.[1]
        }${auth_details.phoneNumber?.trim()}`,
        jobFunction: auth_details.jobFunction,
        subscribeToEmail: auth_details?.subscribeToEmail,
        source: source,
        leadAdsData: refId
          ? { referenceId: refId || location?.state?.refId }
          : undefined,
      };

      if (auth_details?.influencerCode) {
        payload["influencerCode"] = auth_details?.influencerCode;
      }

      if (googleResponse) {
        //google
        payload = {
          ...payload,
          action: "register",
          platform: "web",
          idToken: googleResponse?.tokenId,
          source: source,
          leadAdsData: refId ? { referenceId: refId } : undefined,
          role: "APPLICANT",
        };

        delete payload.password;

        console.log("google payload", payload);
        verifyGoogleToken(payload);
      } else {
        signUp(payload);
      }
    } else {
      console.log("invalid");
    }
  };

  useEffect(() => {
    console.log(refId);
    jobsRequest
      .getJobDetailsByRefId(refId)
      .then((res) => {
        setJob(res.data);
        setLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [refId]);

  useEffect(() => {
    console.log(job);
    if (job) {
      userRequest.getEmployerDetailsP(job?.employerId).then((res) => {
        console.log("employer", res?.data);
        setEmployerDetails(res?.data?.employerProfiles);
      });
    }
  }, [job]);

  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  // useEffect(() => {
  //   if (showAuthModal === "signup" && auth_details?.email) {
  //     userRequest
  //       .validateEmail({ email: auth_details.email })
  //       .then(() => {
  //         setErrorFields({ ...error_fields, email: null });
  //       })
  //       .catch(() => {
  //         setErrorFields({ ...error_fields, email: "Email already exists" });
  //       });
  //   }
  // }, [auth_details?.email]);

  const handleTextFieldChange = (event) => {
    const { name, value } = event?.target;
    setAuthDetails({
      ...auth_details,
      [name]: value,
    });
  };

  const handleGoogleLogin = async (data) => {
    let payload;
    if (isSupported) {
      if (Notification.permission !== "granted") {
        payload = {
          email: data?.profileObj?.email?.trim(),
          idToken: data?.tokenId,
          action: "login",
          role: "APPLICANT",
          platform: "web",
        };
      } else {
        await messaging.getToken().then((token) => {
          payload = {
            email: data?.profileObj?.email?.trim(),
            idToken: data?.tokenId,
            action: "login",
            role: "APPLICANT",
            platform: "web",
          };
        });
      }
    } else {
      payload = {
        email: data?.profileObj?.email?.trim(),
        idToken: data?.tokenId,
        action: "login",
        role: "APPLICANT",
        platform: "web",
      };
    }
    if (payload?.deviceToken) {
      setGoogleResponse({ ...data, token: payload?.deviceToken });
    } else {
      setGoogleResponse(data);
    }
    verifyGoogleToken(payload);
  };

  const location = useLocation();
  const [referrers, setReferrers] = useState();

  useEffect(() => {
    const refId = new URLSearchParams(location.search).get(
      "refId" || location?.state?.refId
    );
    const influencerCode = new URLSearchParams(location.search).get(
      "influencerCode"
    );
    const source = new URLSearchParams(location.search).get("source");

    setReferrers({ refId, source, influencerCode });
  }, [location]);

  useEffect(() => {
    if (referrers?.influencerCode) {
      setAuthDetails({
        ...auth_details,
        influencerCode: referrers?.influencerCode,
      });
    }
  }, [referrers]);

  const closeAuthModal = () => {
    setShowAuthModal(null);
    setGoogleResponse(null);
    setAuthDetails({});
    onClose && onClose();
  };
  useEffect(() => {
    console.log("isGoogleSignup", isGoogleSignup);
    console.log(googleResponse);
    if (!!googleResponse && !isSignedIn && !isSigningIn && isGoogleSignup) {
      // setTimeout(() => {
      //   let url = "/signup";
      //   if (referrers?.refId) {
      //     url = url + "?refId=" + referrers?.refId;
      //   }
      //   if (referrers?.influencerCode) {
      //     url =
      //       url +
      //       `${referrers?.refId ? "&" : "?"}influencerCode=` +
      //       referrers?.influencerCode;
      //   }
      //   if (referrers?.source) {
      //     url =
      //       url +
      //       `${
      //         referrers?.refId || referrers?.influencerCode ? "&" : "?"
      //       }source=` +
      //       referrers?.source;
      //   }
      //   navigate(url, {
      //     state: { isFromLogin: true, refId: referrers?.refId, googleResponse },
      //   });
      // }, 1000);
      setShowAuthModal("signup");
    }
  }, [googleResponse, isSignedIn, isSigningIn, isGoogleSignup]);

  useEffect(() => {
    console.log("this state is updating");
    if (isRegistered) {
      console.log("registered is updated");
      signInUser();
    } else {
      //
      console.log("registered is updated");
    }
  }, [isRegistered, isRegistering]);

  const signInUser = () => {
    if (googleResponse) {
      console.log("user will be logged in");
      //sign in via google
      let payload = {
        email: googleResponse?.profileObj?.email?.trim(),
        role: "APPLICANT",
        //google
        action: "login",
        platform: "web",
        idToken: googleResponse?.tokenId,
      };
      verifyGoogleToken(payload);
    } else {
      if (isSupported) {
        if (Notification.permission !== "granted") {
          signIn({
            email: auth_details?.email?.trim(),
            password: auth_details?.password,
          });
        } else {
          messaging
            ? messaging.getToken().then((token) => {
                signIn({
                  email: auth_details?.email?.trim(),
                  password: auth_details?.password,
                  deviceToken: token,
                });
              })
            : signIn({
                email: auth_details?.email?.trim(),
                password: auth_details?.password,
              });
        }
      } else
        signIn({
          email: auth_details?.email?.trim(),
          password: auth_details?.password,
        });
    }
  };

  useEffect(() => {
    if (showAuthModal === "signup") {
      if (!auth_details.email) {
        setErrorFields({ ...error_fields, email: null });
      } else {
        const delayDebounceFn = setTimeout(() => {
          userRequest
            .validateEmail(auth_details)
            .then((res) => {
              setErrorFields({ ...error_fields, email: null });
            })
            .catch((e) => {
              setErrorFields({
                ...error_fields,
                email: "Email already exists",
              });
              // setErrorFields({ email: "Email already exists" });
            });
        }, 500);
        return () => clearTimeout(delayDebounceFn);
      }
    }
  }, [auth_details?.email]);

  return (
    <>
      <Dialog
        open={showAuthModal === "login"}
        fullWidth
        maxWidth={"xs"}
        onClose={() => {
          closeAuthModal();
        }}
      >
        <Box
          className={`${app_classes.text.center}`}
          style={{ padding: "100px 30px" }}
        >
          <Box className={`${app_classes.margin.b3}`}>
            <img src={JobluH} width={229} />
          </Box>
          <Box className={`${app_classes.margin.b2}`}>
            <JobluTypography.H2 primary style={{ fontWeight: 600 }}>
              Ready to get hired?
              <br /> Login now!
            </JobluTypography.H2>
          </Box>
          <Box className={`${app_classes.margin.b3} ${app_classes.text.left}`}>
            <Box>
              <JobluTypography.Body
                semiBold
                className={`${app_classes.text.left} `}
                primary
                style={{ fontWeight: 700 }}
              >
                Email Address
              </JobluTypography.Body>
            </Box>
            <TextFieldV1
              size="small"
              variant="outlined"
              placeholder="Email Address"
              fullWidth
              onChange={(e) => {
                setAuthDetails({ ...auth_details, email: e.target.value });
                setIsEmailRegistered(false);
              }}
              error={error_fields?.email}
              name="email"
            />
          </Box>
          {isEmailRegistered && (
            <Box className={`${app_classes.margin.b3}`}>
              <Box className={`${app_classes.margin.b1}`}>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  primary
                  style={{ fontWeight: 700 }}
                >
                  Password
                </JobluTypography.Body>
              </Box>
              <TextFieldV1
                type="password"
                required
                className="mb-4"
                id="password"
                name="password"
                onChange={(e) => {
                  setAuthDetails({
                    ...auth_details,
                    password: e.target.value,
                  });
                }}
              />
            </Box>
          )}

          <Box>
            {isSigningIn ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 20,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Button
                color="primary"
                variant="contained"
                fullWidth
                style={{ textTransform: "none", fontWeight: 700 }}
                onClick={() => {
                  if (auth_details.email) {
                    if (validateEmail(auth_details.email?.trim())) {
                      if (!isEmailRegistered) {
                        userRequest
                          .validateEmail(auth_details)
                          .then((res) => {
                            setShowAuthModal("signup");
                          })
                          .catch((err) => {
                            console.log("err", err);
                            setIsEmailRegistered(true);
                          });
                      } else {
                        signIn({
                          email: auth_details.email?.trim(),
                          password: auth_details?.password,
                        });
                      }
                    } else {
                      console.log("failed");
                      setErrorFields({
                        ...error_fields,
                        email: "Invalid Email Address",
                      });
                    }
                  } else {
                    setErrorFields({
                      ...error_fields,
                      email: "Email Address is required",
                    });
                  }
                }}
              >
                {isEmailRegistered ? "Login" : "Continue"}
              </Button>
            )}
          </Box>
          <Box className={`${app_classes.margin.y1}`}>
            <JobluTypography.Body primary style={{ fontWeight: 700 }}>
              OR
            </JobluTypography.Body>
          </Box>
          <Box
            className={`${app_classes.margin.b5}`}
            style={{ border: "1px solid #002E5D", borderRadius: 3 }}
          >
            <GoogleLogin
              className="shadow-none"
              clientId={creds.GOOGLE_CLIENT_ID}
              onSuccess={(data) => {
                handleGoogleLogin(data);
                setAuthDetails({
                  ...auth_details,
                  firstName: data?.profileObj?.givenName,
                  lastName: data?.profileObj?.familyName,
                  email: data?.profileObj?.email?.trim(),
                });
              }}
              buttonText={
                <span style={{ color: "#002E5D", fontWeight: 700 }}>
                  Sign in with Google
                </span>
              }
            />
          </Box>
          <Box>
            <JobluTypography.Body style={{ fontWeight: 600 }} secondary>
              <span
                role="button"
                onClick={() => navigate("/password-reset")}
                style={{ textDecoration: "underline" }}
              >
                Forgot Password?
              </span>{" "}
              |{" "}
              <span
                role="button"
                onClick={() => setShowAuthModal("signup")}
                style={{ textDecoration: "underline" }}
              >
                Create an Account
              </span>
            </JobluTypography.Body>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={showAuthModal === "signup"}
        fullWidth
        maxWidth={"sm"}
        scroll="body"
        onClose={() => {
          closeAuthModal();
        }}
      >
        <Box className={`${app_classes.padding.a5}`}>
          <Box className={`${app_classes.text.center}`}>
            <Box className={`${app_classes.margin.b3}`}>
              <img src={JobluH} width={130} />
            </Box>
            <Box className={`${app_classes.margin.b2}`}>
              <JobluTypography.H2 primary style={{ fontWeight: 600 }}>
                Sign Up
              </JobluTypography.H2>
            </Box>
          </Box>
          <Box>
            <JobluTypography.H6
              primary
              semiBold
              style={{ fontWeight: 600, marginBottom: 20 }}
            >
              Login Information
            </JobluTypography.H6>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Box>
                  <Box>
                    <JobluTypography.Body
                      semiBold
                      className={`${app_classes.text.left} `}
                      style={{ fontWeight: 600 }}
                      primary
                    >
                      Email Address
                    </JobluTypography.Body>
                  </Box>
                  <TextFieldV1
                    placeholder="Email Address"
                    fullWidth
                    name="email"
                    onChange={(e) => {
                      handleTextFieldChange(e);
                    }}
                    error={error_fields?.email}
                    value={auth_details?.email}
                    readOnly={googleResponse?.profileObj?.email}
                  />
                </Box>
              </Grid>
              {!googleResponse && (
                <Grid item xs={12} sm={6}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    Password
                  </JobluTypography.Body>

                  <TextFieldV1
                    placeholder="Password"
                    name="password"
                    onChange={(e) => {
                      handleTextFieldChange(e);
                    }}
                    error={error_fields?.password}
                    type="password"
                    value={auth_details?.password}
                  />
                </Grid>
              )}
              {!googleResponse && (
                <Grid item xs={12} sm={6}>
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{ fontWeight: 600 }}
                    primary
                  >
                    Confirm Password
                  </JobluTypography.Body>

                  <TextFieldV1
                    placeholder="Confirm Password"
                    name="confirm_password"
                    onChange={(e) => {
                      handleTextFieldChange(e);
                    }}
                    error={error_fields?.confirm_password}
                    type="password"
                    value={auth_details?.confirm_password}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <JobluTypography.H6
                  primary
                  semiBold
                  style={{ fontWeight: 600, marginTop: 20 }}
                >
                  Personal Information
                </JobluTypography.H6>
              </Grid>
              <Grid item xs={12} sm={6}>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  style={{ fontWeight: 600 }}
                  primary
                >
                  First Name
                </JobluTypography.Body>

                <TextFieldV1
                  placeholder="Juan"
                  name="firstName"
                  onChange={(e) => {
                    handleTextFieldChange(e);
                  }}
                  error={error_fields?.firstName}
                  type="text"
                  value={auth_details?.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  style={{ fontWeight: 600 }}
                  primary
                >
                  Last Name
                </JobluTypography.Body>

                <TextFieldV1
                  placeholder="Dela Cruz"
                  name="lastName"
                  onChange={(e) => {
                    handleTextFieldChange(e);
                  }}
                  error={error_fields?.lastName}
                  type="text"
                  value={auth_details?.lastName}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  style={{ fontWeight: 600 }}
                  primary
                >
                  Birthday
                </JobluTypography.Body>

                <TextFieldV1
                  placeholder="MM/DD/YYYY"
                  name="dob"
                  onChange={(e) => {
                    handleTextFieldChange(e);
                  }}
                  error={error_fields?.dob}
                  type="date"
                  value={auth_details?.dob}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  style={{ fontWeight: 600 }}
                  primary
                >
                  Current Location
                </JobluTypography.Body>

                <CustomGooglePlaces
                  error={error_fields?.currentLocation}
                  value={auth_details?.currentLocation}
                  onChange={(loc) => {
                    let countryCode;
                    if (loc?.countryValue) {
                      try {
                        const result = countryCodesList?.find(
                          (i) => i?.value?.split(":")[0] === loc?.countryValue
                        );
                        if (result) {
                          countryCode = result.value;
                        }
                      } catch (e) {}
                    }
                    setAuthDetails({
                      ...auth_details,
                      currentLocation: loc?.location,
                      region: loc?.region,
                      city: loc?.city,
                      country: loc?.countryValue,
                      countryCode: countryCode || undefined,
                    });
                  }}
                />
                {!!error_fields?.currentLocation && (
                  <span className="error-helper mt-0">
                    {error_fields?.currentLocation}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  style={{ fontWeight: 600 }}
                  primary
                >
                  Country Code
                </JobluTypography.Body>

                <SelectCountry2
                  name="countryCode"
                  onChange={(e) => {
                    console.log(e);
                    setAuthDetails({ ...auth_details, countryCode: e });
                  }}
                  noAll
                  value={auth_details?.countryCode}
                  error={error_fields?.countryCode}
                  placeholder="Country Code"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  style={{ fontWeight: 600 }}
                  primary
                >
                  Mobile Number
                </JobluTypography.Body>
                <TextFieldV1
                  onChange={handleTextFieldChange}
                  name="phoneNumber"
                  placeholder="Mobile Number"
                  value={auth_details?.phoneNumber}
                  error={error_fields?.phoneNumber}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  style={{ fontWeight: 600 }}
                  primary
                >
                  Job Function
                </JobluTypography.Body>
                <SelectJobFunction
                  onChange={(e) => {
                    console.log(e);
                    setAuthDetails({ ...auth_details, jobFunction: e });
                  }}
                  noAll
                  value={auth_details?.jobFunction}
                  name="jobFunction"
                  error={error_fields?.jobFunction}
                />
              </Grid>

              <Grid item xs={12}>
                <JobluTypography.Body
                  semiBold
                  className={`${app_classes.text.left} `}
                  style={{ fontWeight: 600 }}
                  primary
                >
                  Do you have an Influencer Code? Enter it here!{" "}
                  <span style={{ color: "gray" }}>(optional)</span>
                </JobluTypography.Body>
                <TextFieldV1
                  name="influencerCode"
                  onChange={handleTextFieldChange}
                  error={error_fields?.influencerCode}
                  value={auth_details?.influencerCode}
                  placeholder="Influencer Code"
                  disabled={
                    referrers?.influencerCode && !error_fields?.influencerCode
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Box className={`${app_classes.display.display_flex}`}>
                  <Checkbox
                    onChange={() => {
                      setAuthDetails({
                        ...auth_details,
                        agreeToTerms: !auth_details?.agreeToTerms,
                      });
                    }}
                  />
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{
                      fontWeight: 400,
                      marginTop: "auto",
                      marginBottom: "auto",
                      fontSize: 16,
                      color: error_fields?.agreeToTerms && "red",
                    }}
                  >
                    I agree to the Joblu{" "}
                    <Link
                      component="span"
                      color="secondary"
                      to={"https://joblu.io/terms-of-use/"}
                      target="_blank"
                    >
                      {" "}
                      Terms of Use{" "}
                    </Link>{" "}
                    and{" "}
                    <Link
                      component="span"
                      color="secondary"
                      to={"https://joblu.io/privacy-policy/"}
                      target="_blank"
                    >
                      Privacy Policy{" "}
                    </Link>
                    .
                  </JobluTypography.Body>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={`${app_classes.display.display_flex}`}>
                  <Checkbox
                    onChange={() => {
                      setAuthDetails({
                        ...auth_details,
                        subscribeToEmail: !auth_details?.subscribeToEmail,
                      });
                    }}
                    checked={auth_details?.subscribeToEmail}
                  />
                  <JobluTypography.Body
                    semiBold
                    className={`${app_classes.text.left} `}
                    style={{
                      fontWeight: 400,
                      // marginTop: "auto",
                      // marginBottom: "auto",
                      fontSize: 16,
                    }}
                  >
                    Subscribe to our newsletter and receive the latest news and
                    trends delivered right to your inbox!
                  </JobluTypography.Body>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!auth_details?.agreeToTerms}
                  fullWidth
                  style={{ textTransform: "none", fontWeight: 700 }}
                  onClick={() => !isRegistering && handleSignup()}
                  endIcon={
                    isRegistering && (
                      <CircularProgress color="inherit" size={18} />
                    )
                  }
                >
                  {googleResponse ? (
                    <span>
                      Create an Account <br />{" "}
                      <span style={{ fontSize: 12, fontWeight: 400 }}>
                        ( {googleResponse?.profileObj?.email} )
                      </span>{" "}
                    </span>
                  ) : (
                    "Create an Account"
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box
                  style={{
                    border: "1px solid #002E5D",
                    borderRadius: 3,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <GoogleLogin
                    clientId={creds.GOOGLE_CLIENT_ID}
                    className="shadow-none"
                    buttonText={
                      <span style={{ color: "#002E5D", fontWeight: 700 }}>
                        {googleResponse
                          ? "Choose a different account"
                          : " Sign up with Google"}
                      </span>
                    }
                    onSuccess={(data) => {
                      setGoogleResponse(data);
                      console.log(data);
                      setAuthDetails({
                        ...auth_details,
                        firstName: data?.profileObj?.givenName,
                        lastName: data?.profileObj?.familyName,
                        email: data?.profileObj?.email?.trim(),
                      });
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  style={{ textTransform: "none", fontWeight: 600 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setShowAuthModal("login");
                  }}
                >
                  <ArrowBackIcon className={`${app_classes.margin.r1}`} />
                  Back to Login
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return { AppState: state.Generals, User: state.User };
};
const mapDispatchToProps = {
  signIn: userActions.signIn,
  signUp: userActions.signUp,
  verifyGoogleToken: userActions.verifyGoogleToken,
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
