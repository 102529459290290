import validator from 'validator'
export const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
}

export const validateLength = (string) => {
    return !!string.length > 0
}

export const validateURL = (str) => {
    return validator.isURL(str)
}
